/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserCourses } from "../../../Services/CourseService";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

function Index() {
    const dispatch = useDispatch();

    dispatch(setHeaderTranparentAction(false));

    const [userCourses, setUserCourses] = useState();
    useEffect(() => {
        getUserCourses(0, 10000).then((res) => {
            console.log(res);
            setUserCourses(res.usercourses.items);
        });
        //dispatch(getUserCoursesAction(0, 10000))
    }, []);

    return (
        <div className="container">
            <h4> Khóa học của tôi</h4>
            <div className="section-small">
                <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                    {userCourses != undefined ? (
                        userCourses.map((item, i) => (
                            <div key={i}>
                                <Link to={`/usercoursedetails/${item.id}`}>
                                    <div className="course-card">
                                        <div className="course-card-thumbnail ">
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatCourses)} />
                                            {/* <span className="play-button-trigger" /> */}
                                        </div>
                                        <div className="course-card-body">
                                            <div className="course-card-info">
                                                <div>
                                                    {item.categories.length > 0
                                                        ? item.categories.map((category, index) => <span className="catagroy">{category.name}</span>)
                                                        : "-"}
                                                </div>
                                                <div>
                                                    <i className="icon-feather-bookmark icon-small" />
                                                </div>
                                            </div>
                                            <h4>{item.name}</h4>
                                            <p>{Common.formatName(item.subDescription, 100)}</p>
                                            <div className="course-progressbar mt-3">
                                                <div className="course-progressbar-filler" style={{ width: item.percent + "%" }}></div>
                                            </div>
                                            <div className="course-card-footer">
                                                <h5>
                                                    {" "}
                                                    <i className="icon-feather-film" /> {item.countLesson} Bài học
                                                </h5>
                                                <h5>
                                                    {" "}
                                                    <i className="icon-feather-clock" /> {item.period} Giờ{" "}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    ) : (
                        <h3 className="kt-portlet__head-title">...</h3>
                    )}
                </div>
            </div>

            {/* <ul className="uk-pagination uk-flex-center uk-margin-medium">
                {
                    (() => {
                        let tempArray = [];
                        for (let index = 0; index < totalPage; index++) {
                            tempArray.push(
                                <li onClick={() => getPage(index, limit)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                    <span>{index + 1}</span>
                                </li>
                            )
                        }
                        return (
                            <Fragment>
                                {tempArray}
                            </Fragment>
                        )
                    })()
                }


            </ul> */}
        </div>
    );
}

export default Index;
