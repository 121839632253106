import { Observer } from "../../../../../../Utils/Ob";
import React from "react";
import "./styles.less";
import MaxQuizDetail from "../../../Quiz/maxcoach/QuizDetail";
import { useMount } from "react-use";

function QuizDetail(props) {
  useMount(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{ padding: 8, height: "calc(100vh - 85px)", position: "relative" }}>
      <div className={["right-side", "quizz"].join(" ")}>
        <MaxQuizDetail
          id={props.match.params.id}
          autostart={window.location.href.includes("autostart")}
        />
      </div>
    </div>
  );
}

export default Observer(QuizDetail);
