import { styled } from "../../../../../../../Utils/stitches.config";

export const StyledMemberName = styled("span", {

  display: "inline-block",
  whiteSpace:"normal",
  color: "var(--primary-text-color)",
});

export const StyledMemberPoint = styled("div", {
  display: "inline-block",
  marginTop: 10,
  color: "#316fea",
  background: "rgba(49, 111, 234, 0.2)",
  borderRadius: 16,
  paddingLeft: 8,
  paddingRight: 8,
  span: {
    fontWeight: "bold",
  },
});
