export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const updateNotification = (data) => ({
    type: UPDATE_NOTIFICATION,
    data,
});

const initialState = {
    notifications: [],
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: action.data,
            };

        default:
            return state;
    }
}
