/* eslint-disable */
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/vi";
import Common from "../../../../Utils/Common";
import { useDispatch, useSelector } from "react-redux";
import { getProductAction } from "../../../Actions/Product";
import { getProduct } from "../../../Services/ProductService";
import { getUserAction } from "../../../Actions/User";
import { getUserCoursePreviewAction } from "../../../Actions/Course";
import Rating from "../../ComponentCommon/Box/Rate";
import Configuration from "../../../../Config/Config";
import { createOrderAction } from "../../../Actions/Order";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

function Index(props) {
    const [ratingId, setRatingId] = useState();
    const [totalQuiz, setTotalQuiz] = useState(0);
    const [totalSlide, setTotalSlide] = useState(0);
    const [totalVideo, setTotalVideo] = useState(0);

    const user = useSelector((state) => state.Book.infoUser);

  //  const product = useSelector((state) => state.Product.product);
  const [product,setProduct] = useState(); //useSelector((state) => state.Product.product);
    const author = useSelector((state) => state.User.user);
    const userCourse = useSelector((state) => state.Course.usercourse);

    // const [rating, setRating] = useState({});
    const [stars, setStars] = useState([1, 2, 3, 4, 5]);
    const [averageScore, setAverageScore] = useState(0);
    // const tempRating = useSelector(state => state.Rating.rating);

    // const [vote, setVote] = useState(null);
    const dispatch = useDispatch();

    //Layout
    dispatch(setHeaderTranparentAction(true));

    const createOrder = () => {
        var items = [
            {
                Id: Common.guid(),
                TargetId: product.id,
                quantity: 1,
            },
        ];

        var data = {
            Id: Common.guid(),
            MerchantId: localStorage.getItem(Configuration.merchantId),
            TargetId: localStorage.getItem(Configuration.merchantId),
            CustomerId: localStorage.getItem(Configuration.userId),
            Note: "",
            OrderItems: items,
            CreatedDate: Common.getCurrentDateTime(),
            CreatedBy: localStorage.getItem(Configuration.userId),
        };
        createOrderAction(data);
    };

    useEffect(() => {
        //dispatch(getProductAction(props.match.params.id));
        getProduct(props.match.params.id).then(res => setProduct(res.product));
    }, []);
    useEffect(() => {
        if (userCourse.id != undefined && userCourse.courseLessons != undefined) {
            userCourse.courseLessons.forEach((element) => {
                setTotalQuiz(totalQuiz + element.quizs.length);
                setTotalSlide(totalSlide + element.slides.length);
                setTotalVideo(totalVideo + element.videos.length);
            });
        }
    }, [userCourse]);

    useEffect(() => {
        if (product?.id != undefined) {
            setRatingId(product.ratingId);
            dispatch(getUserCoursePreviewAction(product.courseId));

            if (product.authors.length > 0) {
                dispatch(getUserAction(product.authors[0].id));
            } else {
                dispatch(getUserAction("00000000-0000-0000-0000-000000000000"));
            }

            dispatch(setBreadcrumbAction(product.name));
        }
    }, [product]);

    return (
        <Fragment>
            {product != undefined && (
                <Fragment>
                    <div className="course-details-wrapper topic-1 uk-light pt-5">
                        <div className="container p-sm-0">
                            <div uk-grid="true">
                                <div className="uk-width-2-3@m">
                                    <div className="course-details">
                                        <h1> {product.name}</h1>
                                        <p> {product.subDescription} </p>
                                        {ratingId != undefined && ratingId != "00000000-0000-0000-0000-000000000000" && (
                                            <div className="course-details-info mt-4">
                                                <ul>
                                                    <li>
                                                        <div className="star-rating">
                                                            <span className="avg"> {averageScore} </span>
                                                            {stars.map((star, indexStar) => {
                                                                if (indexStar < averageScore) {
                                                                    return <span className="star" />;
                                                                } else {
                                                                    return <span className="star empty" />;
                                                                }
                                                            })}
                                                        </div>
                                                    </li>
                                                    {/* <li> <i className="icon-feather-users"></i> 1200 Enerolled </li> */}
                                                </ul>
                                            </div>
                                        )}

                                        <div className="course-details-info">
                                            <ul>
                                                {product.authors != undefined &&
                                                    product.authors.map((item, i) => (
                                                        <li>
                                                            {" "}
                                                            Giảng viên <a href="javascript:;"> {item.name} </a>{" "}
                                                        </li>
                                                    ))}

                                                <li> Tạo ngày {Common.formatDateTime(product.createdDate, "dd-mm-yyyy")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <nav className="responsive-tab style-5">
                                        <ul uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                                            <li>
                                                <a href="#">Thông tin</a>
                                            </li>
                                            <li>
                                                <a href="#">Chương trình</a>
                                            </li>
                                            <li>
                                                <a href="#">FAQ</a>
                                            </li>
                                            <li>
                                                <a href="#">Giảng viên</a>
                                            </li>

                                            {ratingId != undefined && ratingId != "00000000-0000-0000-0000-000000000000" && (
                                                <li>
                                                    <a href="#">Đánh giá</a>
                                                </li>
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="uk-grid-large mt-4" uk-grid="true">
                            <div className="uk-width-2-3@m">
                                <ul id="course-intro-tab" className="uk-switcher mt-4">
                                    {/* course description */}
                                    <li className="course-description-content">
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: product.description,
                                            }}
                                        />
                                    </li>
                                    <li className="course-description-content">
                                        <ul className="course-curriculum" uk-accordion="multiple: true">
                                            {userCourse.id != undefined &&
                                                userCourse.courseLessons != undefined &&
                                                userCourse.courseLessons.map((item, index) => (
                                                    <li className={index == 0 ? "uk-open" : ""}>
                                                        <a className="uk-accordion-title" href="#">
                                                            {" "}
                                                            {item.name}{" "}
                                                        </a>
                                                        <div className="uk-accordion-content">
                                                            <ul className="course-curriculum-list">
                                                                {item.slides.map((slide, indexSlide) => (
                                                                    <li>
                                                                        {" "}
                                                                        {slide.name} <span> slide </span>
                                                                    </li>
                                                                ))}
                                                                {item.videos.map((video, indexVideo) => (
                                                                    <li>
                                                                        {" "}
                                                                        {video.name} <span> video </span>
                                                                    </li>
                                                                ))}

                                                                {item.quizs.map((quiz, indexQuiz) => (
                                                                    <li>
                                                                        {" "}
                                                                        {quiz.name} <span> quiz </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </li>
                                    <li className="course-description-content">
                                        <h4 className="my-4">Faq</h4>
                                        <ul className="course-faq" uk-accordion="true">
                                            <li className="uk-open">
                                                <a className="uk-accordion-title" href="#">
                                                    {" "}
                                                    Học online là gì?{" "}
                                                </a>
                                                <div className="uk-accordion-content">
                                                    <p>
                                                        {" "}
                                                        Học online hay còn gọi là học trực tuyến, đây là phương pháp trao đổi, tiếp cận nội dung, kiến thức trên
                                                        các thiết bị điện tử như điện thoại thông minh, laptop, máy tính bảng,… được trang bị kết nối internet.{" "}
                                                        <br />
                                                        So với phương pháp học truyền thống, học online mang tính vượt trội hơn bởi chúng mang lại sự tương tác,
                                                        kết nối đa dạng giữa người học và người dạy. Giáo viên và người học có thể tương tác với nhau thông qua
                                                        các tính năng, ứng dụng được tích hợp sẵn như email, chat, diễn đàn trực tuyến, hội thảo,…
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <a className="uk-accordion-title" href="#">
                                                    {" "}
                                                    Cách mua khóa học trực tuyến?
                                                </a>
                                                <div className="uk-accordion-content">
                                                    <p>
                                                        {" "}
                                                        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4) development
                                                        environment. By the end of this guide, you`ll know how to set up and develop C++ Projects in UE4. This
                                                        guide shows you how to create a new Unreal Engine project, add a new C++ class to it, compile the
                                                        project, and add an instance of a new class to your level. By the time you reach the end of this guide,
                                                        you`ll be able to see your programmed Actor floating above a table in the level.{" "}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <a className="uk-accordion-title" href="#">
                                                    {" "}
                                                    Các câu hỏi thường gặp.{" "}
                                                </a>
                                                <div className="uk-accordion-content">
                                                    <p>
                                                        {" "}
                                                        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4) development
                                                        environment. By the end of this guide, you`ll know how to set up and develop C++ Projects in UE4. This
                                                        guide shows you how to create a new Unreal Engine project, add a new C++ class to it, compile the
                                                        project, and add an instance of a new class to your level. By the time you reach the end of this guide,
                                                        you`ll be able to see your programmed Actor floating above a table in the level.{" "}
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <a className="uk-accordion-title" href="#">
                                                    {" "}
                                                    Baa là gì?{" "}
                                                </a>
                                                <div className="uk-accordion-content">
                                                    <p>
                                                        {" "}
                                                        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4) development
                                                        environment. By the end of this guide, you`ll know how to set up and develop C++ Projects in UE4. This
                                                        guide shows you how to create a new Unreal Engine project, add a new C++ class to it, compile the
                                                        project, and add an instance of a new class to your level. By the time you reach the end of this guide,
                                                        you`ll be able to see your programmed Actor floating above a table in the level.{" "}
                                                    </p>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="course-description-content">
                                        {author.id != undefined && author.id != "00000000-0000-0000-0000-000000000000" && (
                                            <Fragment>
                                                <h4> Giảng viên chính </h4>
                                                <div className="user-details-card">
                                                    <div className="user-details-card-avatar">
                                                        <img src={Common.getImageThumbnail(author.images, "?mode=crop&width=60&height=60")} alt={author.name} />
                                                        {/* <img src="https://www.kafkas.edu.tr/dosyalar/ashmyo/image/default-user.png" alt="" /> */}
                                                    </div>
                                                    <div className="user-details-card-name">
                                                        {author.name}{" "}
                                                        <span>
                                                            {" "}
                                                            Giảng viên
                                                            {author.categories != undefined &&
                                                                author.categories.map((cate, indexCate) => <span>{cate.name}</span>)}
                                                            <span>
                                                                <Moment locale="vi" fromNow>
                                                                    {author.createdDate}
                                                                </Moment>{" "}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <article className="uk-article">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: author.description,
                                                        }}
                                                    />
                                                </article>
                                            </Fragment>
                                        )}
                                    </li>
                                    {/* course Reviews*/}
                                    <li className="course-description-content">
                                        <Rating id={props.match.params.id} ratingId={ratingId} setAverageScore={setAverageScore} setStars={setStars} />
                                    </li>
                                </ul>
                            </div>
                            <div className="uk-width-1-3@m">
                                <div className="course-card-trailer" uk-sticky="top: 10 ;offset:95 ; media: @m ; bottom:true">
                                    <div className="course-thumbnail" onClick={() => createOrder()}>
                                        <img src={Common.getImageThumbnail(product.images, "?mode=crop&width=1280&height=720")} alt />

                                        {/* <a className="play-button-trigger show" href="#trailer-modal" uk-toggle="true"> </a> */}
                                    </div>

                                    <div className="p-3">
                                        <p className="my-3 text-center">
                                            <span className="uk-h1"> {Common.formatMoney(product.price, 0, 3)} </span>
                                            {/* <s className="uk-h4 text-muted"> $19.99 </s>
                                        <s className="uk-h6 ml-1 text-muted"> $32.99 </s> */}
                                        </p>
                                        <div className="uk-child-width-1-2 uk-grid-small mb-4" uk-grid="true">
                                            <div>
                                                <a
                                                    href="javascript:void(0)"
                                                    onClick={() => createOrder()}
                                                    className="uk-width-1-1 btn btn-default transition-3d-hover">
                                                    {" "}
                                                    <i className="uil-play" /> Mua ngay{" "}
                                                </a>
                                            </div>
                                        </div>
                                        <p className="uk-text-bold"> Khóa học bao gồm </p>
                                        <div className="uk-child-width-1-2 uk-grid-small" uk-grid="true">
                                            <div>
                                                <span>
                                                    <i className="uil-youtube-alt" /> {totalVideo} clip
                                                </span>
                                            </div>

                                            <div>
                                                <span>
                                                    {" "}
                                                    <i className="uil-file-alt" /> {totalSlide} slide{" "}
                                                </span>
                                            </div>
                                            <div className="uk-grid-margin">
                                                <span>
                                                    {" "}
                                                    <i className="uil-clock-five"></i> {totalQuiz} quiz{" "}
                                                </span>
                                            </div>
                                            <div>
                                                <span>
                                                    {" "}
                                                    <i className="uil-award" /> Chứng nhận{" "}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
}

export default Index;
