/* eslint-disable */
import React, { Component } from "react";
import { Switch, withRouter, BrowserRouter } from "react-router-dom";

import Dashboard from "../Components/Layout/Dashboard/Index";
import CourseDetail from "../Components/Layout/Course/Details";
import CourseDetail1 from "../Components/Layout/Course/maxcoach/Detail";
import Course from "../Components/Layout/Course/Index";
import Course2 from "../Components/Layout/Course/maxcoach/Courses";
import UserCourse from "../Components/Layout/Course/UserCourse";
import UserCourse2 from "../Components/Layout/Course/maxcoach/UserCourse";

import UserCourseDetails from "../Components/Layout/Course/UserCourseDetails";
import UserCourseDetails1 from "../Components/Layout/Course/maxcoach/UserCourseDetails";
import Book from "../Components/Layout/Books/Index";
import BookDetail from "../Components/Layout/Books/Details";

import Calendar from "../Components/Layout/Calendar/Index";
import Order from "../Components/Layout/Order/Index";
import AffiliateOrder from "../Components/Layout/Order/ListAffiliate";
import OrderDetail from "../Components/Layout/Order/Details";

import ProductMerchant from "../Components/Layout/ProductMerchant/Index";
import Product from "../Components/Layout/Product/Index";
import ProductDetail from "../Components/Layout/Product/TempDetails";
import Blog from "../Components/Layout/Blog/Index";
import Blog2 from "../Components/Layout/Blog/maxcoach/Blog";
import BlogAuthor from "../Components/Layout/BlogAuthor/Index";
import BlogAuthor2 from "../Components/Layout/BlogAuthor/maxcoach/BlogAuthor";
import BlogDetail from "../Components/Layout/Blog/Details";
import BlogDetail2 from "../Components/Layout/Blog/maxcoach/Detail";
import Video from "../Components/Layout/Video/Index";
import VideoDetail from "../Components/Layout/Video/Details";
import Slide from "../Components/Layout/Slide/Index";
import SildeDetail from "../Components/Layout/Slide/Details";
import { loadAllArticle, loadAllCategoryArticle, updateInfoUser } from "../Reducers/Book";

import { setUser } from "../Reducers/User";

import { setInforAction } from "../Reducers/Merchant";
import { connect } from "react-redux";
import { QueryGraphQL } from "../Queries/GraphQl";
import BookRead from "../Components/Layout/Books/ReadBook";
//import ProfileEdit from '../Components/Layout/User Account/Profile/ProfileEdit';
import ProfileEdit from "../Components/Layout/User Account/ProfileUpdate";

import Common from "../../Utils/Common";
import Configuration from "../../Config/Config";
import ChangePassword from "../Components/Layout/User Account/ChangePassword/ChangePassword";
import Affiliate from "../Components/Layout/User Account/Affiliate";
import Home from "../Components/Layout/Home/maxcoach/Home2";
import { PrivateRoute } from "./PrivateRoute";
import Profile from "../Components/Layout/User Account/Profile/maxcoach/Profile";
import { Redirect } from "react-router-dom";

// import Quiz from '../Components/Layout/Quiz/QuizDetail';
import ListQuiz from "../Components/Layout/Quiz/ListQuiz";
import QuizDetail from "../Components/Layout/Quiz/QuizDetail";
import QuizDetail1 from "../Components/Layout/Course/maxcoach/UserCourseDetails/quizz";
import Drawing from "../Components/Layout/Drawing/index";

import LoginPage from "../Components/Layout/Login/LoginPage";
import QuizTransaction from "../Components/Layout/QuizTransaction/Index";
import SignUp from "../Components/Layout/Login/SignUp";
import RecoveryPassword from "../Components/Layout/Login/RecoveryPassword";
import Header from "../Components/Layout/Header/HeaderHook";
import Footer from "../Components/Layout/Footer/Footer";
import Aside from "../Components/Layout/NavigationAside/Aside";
import { AppRoute } from "./AppRoute";
import QuizVideoDetail from "../Components/Layout/QuizVideo/QuizDetail";
import MaxCoachLayout, { EmptyLayout } from "./Layout/maxcoach";
import AutoTheming from "../Components/ComponentCommon/AutoTheming";
import MaxcoachProductsPage from "../Components/Layout/Product/maxcoach/Products";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "urql";
import graphQLClient from "../../Utils/graphQLClient";
import MaxcoachProductDetailPage from "../Components/Layout/Product/maxcoach/ProductDetail";
import MaxcoachVideoDetail from "../Components/Layout/Video/maxcoach/Detail";
import MaxcoachSlideDetail from "../Components/Layout/Slide/maxcoach/Detail/index.js";
import MaxcoachCalendarPage from "../Components/Layout/Calendar/maxcoach/Main";
import NotificationsListPage from "../Components/Layout/Header/components/maxcoach/NotificationsListPage";
import MaxcoachProfileEditPage from "../Components/Layout/User Account/Profile/maxcoach/ProfileEdit";
import MaxcoachPersonalDetailsPage from "../Components/Layout/User Account/Profile/maxcoach/PersonalDetailsEdit";
import MaxcoachBooksPage from "../Components/Layout/Books/maxcoach/Books";
import MaxcoachReadBook from "../Components/Layout/Books/maxcoach/Read";
import MaxcoachProductDetailPage2 from "../Components/Layout/Product/maxcoach/ProductDetail/designed";
import MaxcoachLoginPage from "../Components/Layout/Login/maxcoach/Login";
import MaxcoachForgotPasswordPage from "../Components/Layout/Login/maxcoach/Login/forgot";
import MaxcoachRegisterPage from "../Components/Layout/Login/maxcoach/Login/register";
import MaxcoachViodRegisterPage from "../Components/Layout/Login/maxcoach/Login/ViodRegister";
import PublicBlog from "../Components/Layout/Blog/maxcoach/PublicBlog";
import PurchaseDetail from "../Components/Layout/Purchase/Detail";
import SurveyPage from "../Components/Layout/Survey/index.tsx";
import ZoomPage from "../Components/Layout/Zoom";
import Tracker from "../../Services/tracker";
import ProductPurchasePage from "../Components/Layout/ProductPurchase";
import ProductBasic from "../Components/Layout/ProductBasic";
import ProductBasicDetail from "../Components/Layout/ProductBasic/Detail";
import Event from "../Components/Layout/Event";
import EventDetail from "../Components/Layout/Event/Detail";
import Contest from "../Components/Layout/Contest";
import ContestDetail from "../Components/Layout/Contest/Detail";
import ProductExchangePoint from "../Components/Layout/ProductExchangePoint";
import DetailProductExchangePoint from "../Components/Layout/ProductExchangePoint/Detail";

import WorkCompany from "../Components/Layout/Work/WorkCompany";
import WorkExperience from "../Components/Layout/Work/WorkExperience";
import Board from "../Components/Layout/Board/Experiences";
import Preview from "../Components/Layout/Cv/Preview";
import Certificate from "../Components/Layout/Certificate/Certificate.tsx";
import Education from "../Components/Layout/Education";
import ProfessionalQualification from "../Components/Layout/User Account/Profile/maxcoach/ProfessionalQualification";
import ProfessionalMembership from  "../Components/Layout/User Account/Profile/maxcoach/ProfessionalMembership"
import Award from "../Components/Layout/User Account/Profile/maxcoach/Award"
import Contact from "../Components/Layout/User Account/Profile/maxcoach/Contact"
import NotFound from "../Components/Layout/Error/NotFound";
import PersonalDetailsEdit from "../Components/Layout/User Account/Profile/maxcoach/PersonalDetailsEdit";
import Preference from "../Components/Layout/Board/Preference";
import BoardOpening from "../Components/Layout/Board/BoardOpening";
import Cart from "../Components/Layout/Cart";
import Wishlist from "../Components/Layout/Wishlist";


const queryClient = new QueryClient();

var url_string = window.location.href;
var url = new URL(url_string);
var token = url.searchParams.get("tokenId");
var strReturnUrl = url.searchParams.get("returnUrl");
if (token != null && strReturnUrl != null) {
  Common.setCookie(Configuration.tokenId, token);
  Common.setCookie(Configuration.returnUrl, strReturnUrl);
  window.location.href = "/";
}

const { themes } = window;

const MainLayout = (props) => (
  <div>
    <Header />
    <Aside />
    <div className="page-content">
      {props.children}
      <Footer />
    </div>
  </div>
);

const CourseLayout = (props) => <div>{props.children}</div>;

const CourseLayout2 = (props) => (
  <div>
    <Aside />
    <div className="page-content">{props.children}</div>
  </div>
);

class AppMain extends Component {
  constructor() {
    super();
    this.state = {
      isValid: "",
      merchantId:"",
    };
  }
  componentWillMount() {
    var self = this;
    // console.log(window);
    console.log(window.config);
    //console.log(localStorage.getItem(window.config.localStorageTokenId));

    if (localStorage.getItem(window.config.localStorageTokenId) !== null) {
      QueryGraphQL.getDataByToken(localStorage.getItem(window.config.localStorageTokenId)).then(
        (data) => {
          if (data.userInformation.item != null) {
            this.state.merchantId = data.userInformation.item.merchantId;
            console.log(data.userInformation.item);
            self.props.setUser(data.userInformation.item);
            //self.props.updateInfoUser(data.userInformation.item);
            self.props.setInforAction(window.Merchant.data);
            console.log(data.userInformation.item);
            localStorage.setItem(Configuration.userId, data.userInformation.item.id);
            Tracker.instance().start(data.userInformation.item);
            // localStorage.setItem(Configuration.languageId, data.userInformation.item.languageId);
            // localStorage.setItem(Configuration.merchantId, data.userInformation.item.merchantId);
          } else {
            localStorage.removeItem(window.config.localStorageTokenId);
          }
        }
      );
    }

    localStorage.setItem("history", window.location.href);
  }

  componentWillUnmount() {
    Tracker.instance().stop();
  }

  render() {
    const AutoLayout = themes.switch({
      [themes.default]: MainLayout,
      [themes.maxcoach]: MaxCoachLayout,
    });

    return (
      <QueryClientProvider client={queryClient}>
        <Provider value={graphQLClient}>
          <BrowserRouter>
            <AutoTheming />
            <Switch>
              {/* <Route exact path="/" component={Home} /> */}
              {/*<PrivateRoute*/}
              {/*  exact*/}
              {/*  path="/"*/}
              {/*  // component={themes.switch({*/}
              {/*  //   [themes.default]: Dashboard,*/}
              {/*  //   [themes.maxcoach]: MaxcoachProductsPage,*/}
              {/*  // })}*/}
              {/*  // layout={AutoLayout}*/}
              {/*>*/}
              {/*  <Redirect to="/usercourse" />*/}
              {/*</PrivateRoute>*/}
     
              <PrivateRoute
                exact
                path="/"
                // component={ this.state.merchantId == "82523b33-b892-4364-b00b-3108074f2e86" ?themes.switch({
                //   [themes.default]: Home,
                //   [themes.maxcoach]: Home,
                // }):themes.switch({
                //   [themes.default]: MaxcoachPersonalDetailsPage,
                //   [themes.maxcoach]: MaxcoachPersonalDetailsPage,
                // })}
                  component={ themes.switch({
                    [themes.default]: Home,
                    [themes.maxcoach]: Home,
                  })}
                layout={AutoLayout}
              />

              <PrivateRoute path="/home" component={Home} layout={AutoLayout} />
              <PrivateRoute path="/dashboard" component={Dashboard} layout={AutoLayout} />
              <PrivateRoute path="/event" exact component={Event} layout={AutoLayout} />
              <PrivateRoute path="/event/:id" component={EventDetail} layout={AutoLayout} />
              <PrivateRoute
                path="/product-purchase"
                component={ProductPurchasePage}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/product-basic"
                exact
                component={ProductBasic}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/product-basic/:id"
                exact
                component={ProductBasicDetail}
                layout={AutoLayout}
              />
              <PrivateRoute
                exact
                path="/course/:id/:name"
                component={themes.switch({
                  [themes.default]: CourseDetail,
                  [themes.maxcoach]: CourseDetail1,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                exact
                path="/course"
                component={themes.switch({
                  [themes.default]: Course,
                  [themes.maxcoach]: Course2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                exact
                path="/usercourse"
                component={themes.switch({
                  [themes.default]: UserCourse,
                  [themes.maxcoach]: UserCourse2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                exact
                path="/UserCourseDetails/:id"
                component={themes.switch({
                  [themes.default]: UserCourseDetails,
                  [themes.maxcoach]: UserCourseDetails1,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                exact
                path="/user-course-details/:id"
                component={themes.switch({
                  [themes.default]: UserCourseDetails,
                  [themes.maxcoach]: UserCourseDetails1,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/video/:id/:name"
                component={themes.switch({
                  [themes.default]: VideoDetail,
                  [themes.maxcoach]: MaxcoachVideoDetail,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/video" component={Video} layout={AutoLayout} />
              <PrivateRoute
                path="/blog/:id/:name"
                component={themes.switch({
                  [themes.default]: BlogDetail,
                  [themes.maxcoach]: BlogDetail2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/author-blog/:id/:name"
                component={themes.switch({
                  [themes.default]: BlogDetail,
                  [themes.maxcoach]: BlogDetail2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/blog"
                component={themes.switch({
                  [themes.default]: Blog,
                  [themes.maxcoach]: Blog2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/accessible-blog" component={PublicBlog} layout={AutoLayout} />
              <PrivateRoute
                path="/BlogAuthor"
                component={themes.switch({
                  [themes.default]: BlogAuthor,
                  [themes.maxcoach]: BlogAuthor2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/book/:id/:name" component={BookDetail} layout={AutoLayout} />
              <PrivateRoute
                path="/book"
                component={themes.switch({
                  [themes.default]: Book,
                  [themes.maxcoach]: MaxcoachBooksPage,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/order/:id/:name" component={OrderDetail} layout={AutoLayout} />
              <PrivateRoute path="/order" component={Order} layout={AutoLayout} />
              <PrivateRoute path="/affiliateorder" component={AffiliateOrder} layout={AutoLayout} />

              <PrivateRoute path="/drawing" component={Drawing} layout={AutoLayout} />

              <PrivateRoute
                path="/slide/:id/:name"
                component={themes.switch({
                  [themes.default]: SildeDetail,
                  [themes.maxcoach]: MaxcoachSlideDetail,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/slide" component={Slide} layout={AutoLayout} />
              <PrivateRoute
                path="/product/:id/:name"
                component={themes.switch({
                  [themes.default]: ProductDetail,
                  [themes.maxcoach]: MaxcoachProductDetailPage2,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/product"
                component={themes.switch({
                  [themes.default]: Product,
                  [themes.maxcoach]: MaxcoachProductsPage,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/productmerchant"
                component={ProductMerchant}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/bookRead"
                component={themes.switch({
                  [themes.default]: BookRead,
                  [themes.maxcoach]: MaxcoachReadBook,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/profile-edit"
                component={themes.switch({
                  [themes.default]: ProfileEdit,
                  [themes.maxcoach]: MaxcoachProfileEditPage,
                })}
                layout={AutoLayout}
              />
               <PrivateRoute
                path="/personal-details-edit"
                component={themes.switch({
                  [themes.default]: ProfileEdit,
                  [themes.maxcoach]: MaxcoachPersonalDetailsPage,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/profile" exact component={Profile} layout={AutoLayout} />
              <PrivateRoute
                path="/change-password"
                component={ChangePassword}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/profile/qualification"
                exact
                component={ProfessionalQualification}
                layout={AutoLayout}
              />
               <PrivateRoute
                path="/profile/membership"
                exact
                component={ProfessionalMembership}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/profile/award"
                exact
                component={Award}
                layout={AutoLayout}
              />
                <PrivateRoute
                path="/profile/contact"
                exact
                component={Contact}
                layout={AutoLayout}
              />
              <PrivateRoute path="/affiliate" component={Affiliate} layout={AutoLayout} />
              <PrivateRoute
                path="/notifications-list"
                component={NotificationsListPage}
                layout={AutoLayout}
              />

              <PrivateRoute
                path="/quiz/:id/:name"
                component={themes.switch({
                  [themes.default]: QuizDetail,
                  [themes.maxcoach]: QuizDetail1,
                })}
                layout={AutoLayout}
              />
              <PrivateRoute path="/quiz" component={ListQuiz} layout={AutoLayout} />
              <PrivateRoute path="/survey/:id" component={SurveyPage} layout={AutoLayout} />
              <PrivateRoute path="/zoom/:id" component={ZoomPage} layout={AutoLayout} />
              <PrivateRoute
                path="/quiztransaction"
                component={QuizTransaction}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/quiz-video/:id/:name"
                component={QuizVideoDetail}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/calendar"
                component={themes.switch({
                  [themes.default]: Calendar,
                  [themes.maxcoach]: MaxcoachCalendarPage,
                })}
                layout={AutoLayout}
              />
              <AppRoute path="/login" component={MaxcoachLoginPage} layout={EmptyLayout} />
              <AppRoute path="/signup" component={MaxcoachRegisterPage} layout={EmptyLayout} />
              <AppRoute
                path="/SingUpViod"
                component={MaxcoachViodRegisterPage}
                layout={EmptyLayout}
              />
              <AppRoute
                path="/RecoveryPassword"
                component={MaxcoachForgotPasswordPage}
                layout={EmptyLayout}
              />

              <PrivateRoute
                path="/purchase-detail/:id"
                component={PurchaseDetail}
                layout={AutoLayout}
              />
              <PrivateRoute path="/contest" exact component={Contest} layout={AutoLayout} />
              <PrivateRoute
                path="/contest/:id"
                exact
                component={ContestDetail}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/product-exchange-point"
                exact
                component={ProductExchangePoint}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/product-exchange-point/:id"
                exact
                component={DetailProductExchangePoint}
                layout={AutoLayout}
              />

              <PrivateRoute
                path="/work/company"
                exact
                component={WorkCompany}
                layout={AutoLayout}
              />
              <PrivateRoute
                path="/work/experience"
                exact
                component={WorkExperience}
                layout={AutoLayout}
              />
              <PrivateRoute path="/board/experience" exact component={Board} layout={AutoLayout} />
              <PrivateRoute path="/board/preference" exact component={Preference} layout={AutoLayout} />
              <PrivateRoute path="/board-open/list" exact component={BoardOpening} layout={AutoLayout} />
              <PrivateRoute path="/cv/preview" exact component={Preview} layout={AutoLayout} />
              <PrivateRoute path="/certificate" exact component={Certificate} layout={AutoLayout} />
              <PrivateRoute path="/education" exact component={Education} layout={AutoLayout} />
              <PrivateRoute path="/shopping-cart" exact component={Cart} layout={AutoLayout} />
              <PrivateRoute path="/wishlist" exact component={Wishlist} layout={AutoLayout} />

              <AppRoute path="*" exact component={NotFound} layout={EmptyLayout} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    );
  }
}
const mapStateToProp = () => ({});
const mapDispatchToProps = {
  loadAllArticle,
  loadAllCategoryArticle,
  updateInfoUser,
  setInforAction,
  setUser,
};
// export default <AppMain />;
export default withRouter(
  connect(mapStateToProp, mapDispatchToProps)((props) => <AppMain {...props} />)
);
