import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Empty, Divider, Modal, Upload, message } from "antd";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import Configuration from "../../../../Config/Config";
import DNModal from "../DnStyles/Modal";
import _ from "lodash";
import { exec } from "../../../../Utils/dn";
import axios from "axios";
import { getCommonStorage } from "../../../../Config/gql";
import { v4 } from "uuid";
import { useQuery } from "urql";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { QueryCertificate } from "./gql";

const Certificate = () => {
  const [certificateForm] = Form.useForm();
  const [id] = useState(v4());
  const [editId, setEditId] = useState("");

  const [images, setImages] = useState<any>([]);
  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const handleChangeFile = async (e: any) => {
    const { tokenId } = getCommonStorage();
    const formData: any = new FormData();
    const file = e.file;
    console.log(file,"38")
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    formData.append("UploadedFile", file);
    formData.append("token", tokenId);
    console.log(localStorage.getItem("tokenId"), "line 64");
    try {
      await axios
        .post("https://apicommand.izzi.asia/File/UploadFile", formData)
        .then((data) => setImages([...data.data.Data]));
      if (e.onSuccess) {
        message.success(`${e.file.name} Tải lên file thành công.`);
      } else {
        message.error(`${e.file.name} File cần có định dạng ảnh .`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const uploadButton = (
    <div>
      {images.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  useEffect(() => {
    console.log(images, "line 79");
  }, [images]);
  const [queryCertificate, refetchCertificate] = useQuery({
    query: QueryCertificate,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 128,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (formData: any) => {
    setState({ loading: true });

    exec(
      "Article",
      state.isEdit ? "UpdateCertificateArticle" : "CreateCertificateArticle",
      {
        Name: formData.name,
        Id: state.isEdit ? editId : v4(),
        SubDescription: formData.subDescription,

        ImageId: images[0].Id,
      },
      ["merchantId", "languageId", "actionBy"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchCertificate();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };
  const _onClickEdit = (certificate: any) => {
    setEditId(certificate.id);
    certificateForm.setFieldsValue({
      name: certificate.name,
      subDescription: certificate.subDescription,
    });
    setImages([
      ...certificate.files.map((file: any) => ({ Name: file.name, Path: file.path, Id: file.id })),
    ]);
    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec(
          "Article",
          "DeleteArticlesVersion01",
          {
            Ids: [id],
            Id: v4(),
          },
          ["actionBy"]
        ).finally(refetchCertificate);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };

  const certificates = queryCertificate.data?.data?.items;
  useEffect(() => {
    console.log(images, "line 163");
  }, [images]);
  return (
    <Fragment>
      <BasePage title={"Danh sách chứng chỉ của tôi"} hideTitle={true}>
        <Card
          title={"Danh sách chứng chỉ của tôi"}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                certificateForm.resetFields();
                setImages({ dataImg: [] });
                setState({ isModalVisible: true, isEdit: false });
              }}>
              Thêm chứng chỉ
            </Button>
          }
          loading={queryCertificate.fetching}>
          {certificates && certificates?.length === 0 && (
            <Empty
              description={
                <span>
                  Chưa có chứng chỉ nào
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    Thêm
                  </a>
                </span>
              }
            />
          )}
          {certificates?.length > 0 &&
            certificates?.map((certificate: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        Tên chứng chỉ
                      </Col>
                      <Col span={20}>{certificate.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        Mô tả
                      </Col>
                      <Col span={20}>{certificate.subDescription}</Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Button type={"link"} onClick={() => _onClickEdit(certificate)}>
                        Sửa
                      </Button>
                      <Button type={"link"} danger={true} onClick={() => _remove(certificate.id)}>
                        Xóa
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
      </BasePage>
      <DNModal
        title={state.isEdit ? "Chỉnh sửa" : "Chứng chỉ"}
        visible={state.isModalVisible}
        onCancel={() => {
          setState({ isModalVisible: false });
        }}
        okButtonProps={{
          form: id,
          htmlType: "submit",
        }}
        confirmLoading={state.loading}>
        <Form id={id} form={certificateForm} onFinish={_onFinish} layout={"vertical"}>
          <Form.Item name={"name"} rules={[{ required: true, message: "Vui lòng nhập " }]}>
            <Input placeholder={"Tên chứng chỉ"} />
          </Form.Item>
          <Form.Item name={"subDescription"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Input.TextArea placeholder={"Mô tả"} />
          </Form.Item>

          <Form.Item name="images" rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Upload
              accept="image/*"
              showUploadList={false}
              listType="picture-card"
              className="avatar-uploader"
              customRequest={handleChangeFile}>
              {images.length > 0 ? (
                <img
                  src={Configuration.imageUrl + images[0].Path}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Form>
      </DNModal>
    </Fragment>
  );
};

export default Certificate;
