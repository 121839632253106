import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getCommonStorage } from "../../../../Config/gql";
import Common, { sweetAlertLoading } from "../../../../Utils/Common";
import { exec } from "../../../../Utils/dn";
import { getCartAction } from "../../../Actions/Cart";
import { getCart, handleCheckout } from "../../../Services/CartService";
import { BasePage } from "../../ComponentCommon/BasePage";
import { updateCartItem } from "../../../Actions/Cart";
import { Link } from "react-router-dom";

const Cart = (props: any) => {
  const [refetch, setRefetch] = useState(false);
  const { merchantId, userId, createdBy } = getCommonStorage();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (refetch) {
      getCart()
        .then((data) => dispatch(getCartAction(data)))
        .finally(() => setRefetch(false));
    }
  }, [refetch]);

  const dispatchAsync = (type: string, id: string, prevQuantity: number) => {
    return new Promise((resolve, reject) => {
      dispatch(updateCartItem({ type, id }));
      if (type === "inc") {
        resolve(prevQuantity + 1);
      }
      if (type === "dec") {
        if (prevQuantity === 1) {
          resolve(1);
        }
        resolve(prevQuantity - 1);
      }
    });
  };
  const handleUpdateItemCart = (id: string, quantity: number) => {
    exec("ShoppingCart", "UpdateShoppingCartItem", {
      Id: localStorage.getItem("shoppingCartId"),
      ShoppingCartItem: {
        Id: id,
        Quantity: quantity,
      },
      ModifiedDate: Common.getCurrentDateTime(),
      ModifiedBy: userId,
    });
  };
  const handleDeleteItemCart = (id: string) => {
    sweetAlertLoading(
      "Deleting item ...",
      exec("ShoppingCart", "DeleteShoppingCartItem", {
        Id: localStorage.getItem("shoppingCartId"),
        InventoryId: merchantId,
        ShoppingCartItemId: id,
        CustomerId: userId,
        ModifiedDate: Common.getCurrentDateTime(),
        ModifiedBy: userId,
        SubscribeId: localStorage.getItem("shoppingCartId") + id,
      })
        .then(() => {
          Swal.hideLoading();
          Swal.fire({
            text: t("Delete item success"),
            icon: "success",
          });
        })
        .then(() => setRefetch(true))
    );
  };

  const calCulateGrandTotal = () => {
    const result = props.cart.reduce((acc: any, item: any) => {
      acc += item.quantity * item.targetPrice;
      return acc;
    }, 0);
    return result;
  };
  return (
    <BasePage title={t("Cart")}>
      {props.cart.length > 0 ? (
        <div className="cart-section section section-padding-bottom">
          <div className="container faq-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="cart-table table-responsive max-mb-30">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pro-thumbnail">{t("Image")}</th>
                        <th className="pro-title">{t("Product")}</th>
                        <th className="pro-price">{t("Price")}</th>
                        <th className="pro-quantity">{t("Quantity")}</th>
                        <th className="pro-subtotal">{t("SubTotal")}</th>
                        <th className="pro-remove">{t("Remove")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.cart.map((item: any) => {
                        return (
                          <tr key={item.id}>
                            <td className="pro-thumbnail">
                              <a href="#">
                                <img
                                  src={Common.getImageThumbnail(
                                    props.cart.targetImagePath,
                                    "?Mode=crop&width=480&height=298"
                                  )}
                                  alt="Product"
                                />
                              </a>
                            </td>
                            <td className="pro-title">
                              <a href="#">{item.targetName}</a>
                            </td>
                            <td className="pro-price">
                              <span>{Common.formatMoney(item.targetPrice)}</span>
                            </td>
                            <td className="pro-quantity">
                              <div className="pro-qty">
                                <button
                                  onClick={() =>
                                    dispatchAsync("dec", item.id, item.quantity).then((data: any) =>
                                      handleUpdateItemCart(item.id, data)
                                    )
                                  }
                                  className="dec qtybtn">
                                  -
                                </button>
                                <input readOnly type="number" value={item.quantity} />
                                <button
                                  onClick={() =>
                                    dispatchAsync("inc", item.id, item.quantity).then((data: any) =>
                                      handleUpdateItemCart(item.id, data)
                                    )
                                  }
                                  className="inc qtybtn">
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="pro-subtotal">
                              <span>{Common.formatMoney(item.targetPrice * item.quantity)}</span>
                            </td>
                            <td className="pro-remove">
                              <button
                                className="border-none"
                                onClick={() => handleDeleteItemCart(item.id)}>
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      {/*  <tr>
                      <td className="pro-thumbnail">
                        <a href="#">
                          <img
                            src="assets/images/products/shopping-cart/product-1.jpg"
                            alt="Product"
                          />
                        </a>
                      </td>
                      <td className="pro-title">
                        <a href="#">Early Field Experience: Teach Well</a>
                      </td>
                      <td className="pro-price">
                        <span>$59.00</span>
                      </td>
                      <td className="pro-quantity">
                        <div className="pro-qty">
                          <button className="dec qtybtn">-</button>
                          <input type="number" value="1" />
                          <button className="inc qtybtn">+</button>
                        </div>
                      </td>
                      <td className="pro-subtotal">
                        <span>$59.00</span>
                      </td>
                      <td className="pro-remove">
                        <a href="#">
                          <i className="far fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="pro-thumbnail">
                        <a href="#">
                          <img
                            src="assets/images/products/shopping-cart/product-2.jpg"
                            alt="Product"
                          />
                        </a>
                      </td>
                      <td className="pro-title">
                        <a href="#">Online Student: Strategies for Effective Learning</a>
                      </td>
                      <td className="pro-price">
                        <span>$67.00</span>
                      </td>
                      <td className="pro-quantity">
                        <div className="pro-qty">
                          <button className="dec qtybtn">-</button>
                          <input type="number" value="1" />
                          <button className="inc qtybtn">+</button>
                        </div>
                      </td>
                      <td className="pro-subtotal">
                        <span>$67.00</span>
                      </td>
                      <td className="pro-remove">
                        <a href="#">
                          <i className="far fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="pro-thumbnail">
                        <a href="#">
                          <img
                            src="assets/images/products/shopping-cart/product-3.jpg"
                            alt="Product"
                          />
                        </a>
                      </td>
                      <td className="pro-title">
                        <a href="#">Principles of Business Administration</a>
                      </td>
                      <td className="pro-price">
                        <span>$52.00</span>
                      </td>
                      <td className="pro-quantity">
                        <div className="pro-qty">
                          <button className="dec qtybtn">-</button>
                          <input type="number" value="1" />
                          <button className="inc qtybtn">+</button>
                        </div>
                      </td>
                      <td className="pro-subtotal">
                        <span>$52.00</span>
                      </td>
                      <td className="pro-remove">
                        <a href="#">
                          <i className="far fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>

                <div className="row max-mb-n30">
                  <div className="col-lg-6 col-12 max-mb-30"></div>

                  <div className="col-lg-6 col-12 max-mb-30 d-flex">
                    <div className="cart-summary">
                      <div className="cart-summary-wrap">
                        <h4>{t("Cart Summary")}</h4>

                        <h2>
                          {t("Grand Total")}{" "}
                          <span>{Common.formatMoney(calCulateGrandTotal())}</span>
                        </h2>
                      </div>
                      <div className="cart-summary-button">
                        <button
                          onClick={() => handleCheckout(localStorage.getItem("shoppingCartId"))}
                          className="w-100 btn btn-primary btn-hover-secondary">
                          {t("Checkout")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="cart-empty-content">
              <span className="icon">
                <i className="fal fa-shopping-cart"></i>
              </span>
              <h3 className="title">Your cart is currently empty.</h3>
              <p>You may check out all the available products and buy some in the shop.</p>
              <Link to="/product-basic" className="btn btn-primary btn-hover-secondary">
                Return to shop
              </Link>
            </div>
          </div>
        </div>
      )}
    </BasePage>
  );
};
const mapStateToProps = (state: any) => {
  return {
    cart: state.Cart.cart,
  };
};
export default connect(mapStateToProps)(Cart);
