import { styled } from "../../../../../../Utils/stitches.config";
import {
  borderColor,
  borderInputColor,
  primaryColor,
  primaryTextColor,
} from "../../../../../../Utils/themeConfig";

export const StyledContainer = styled("div", {
  margin: "auto",
  maxWidth: 564,
  // height: "calc(100vh - 40px)",
});
export const StyledFormBox = styled("form", {
  display: "block",
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  color: primaryTextColor,
  margin: 24,
  marginTop: 0,
  background: "#fff",
});
export const StyledFormHeader = styled("div", {
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "bold",
  textAlign: "center",
  padding: "24px auto",
  borderBottom: "1px solid " + borderColor,
});
export const StyledFormBody = styled("div", {
  padding: "24px 32px",
  "input, input:focus": {
    border: "1px solid " + borderInputColor,
    borderRadius: 8,
  },
  "input:focus": {
    borderColor: primaryColor,
  },
  ".ant-select": {
   
    width: "100%",
    marginBottom: "16px",
  },
  select:{
    padding: "10px",
    fontSize: "14px",
    borderRadius: 8,
    border: "1px solid " + borderInputColor,
  },
  label: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "bold",
  },
  ".ant-checkbox-input": {
    opacity: 0,
  },
  a: {
    color: primaryColor,
  },
  'div[data-section="RememberMe"]': {
    whiteSpace: "nowrap",
    "label:first-child": {
      display: "inline-block",
      width: "50%",
      textAlign: "left",
    },
    a: {
      display: "inline-block",
      width: "50%",
      textAlign: "right",
    },
  },
});
export const StyledFormFooter = styled("div", {
  textAlign: "center",
  padding: "24px auto",
  borderTop: "1px solid " + borderColor,
  a: {
    color: primaryColor,
  },
});
export const StyledLogoBox = styled("div", {
  textAlign: "center",
  height: 182,
});
