import { gql } from "urql";

export const QUERY_CONTESTS = gql`
  query Contests(
    $type: Int = 1
    $limit: Int = 1000
    $offset: Int = 0
    $order: String = "desc"
    $sort: String = "createdDate"
    $languageId: String = ""
    $merchantId: String = ""
    $published: Boolean = true
    $tokenUser: String = ""
  ) {
    membercontests(
      param: {
        type: $type
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        published: $published
        tokenUser: $tokenUser
      }
    ) {
      message
      items {
        id
        name
        status      
        images {
          id
          name
          path
          isFeatured
        }     
        startDate
        endDate       
      }
      totalCount
      success
    }
  }
`;

export const QUERY_CONTEST = gql`
  query Contest($id: String = "", $languageId: String = "", $merchantId: String = "") {
    membercontest(param: { id: $id, languageId: $languageId, merchantId: $merchantId }) {
      id
      name
      code
      subDescription
      description
      startDate
      endDate
      status
      images {
        id
        isFeatured
        path
        displayOrder
      }
   
      files {
        id
        name
        path
        type
        typeStorage
        description
        subDescription
      }
    }
  }
`;
