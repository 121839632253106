/* eslint-disable */
import React, { Component } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import VimeoPlayer from "./VimeoPlayer";
import { updateBreadcrumb } from "../../../Reducers/Book";
import { connect } from "react-redux";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";
import { FacebookProvider, Comments } from "react-facebook";

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                name: "",
                subDescription: "",
                description: "",
                createdDate: "",

                files: [],
                images: [],
            },
        };
    }

    componentDidMount() {
        var self = this;
        QueryGraphQL.getArticleById(this.props.match.params.id).then((data) => {
            this.setState({
                article: data.article,
            });
            console.log(this.state.article);
            self.props.setBreadcrumbAction(data.article.name);
        });
    }
    // componentDidUpdate() {
    //     FB.XFBML.parse();
    // }
    render() {
        this.props.setHeaderTranparentAction(false);
        return (
            <div className="uk-grid-collapse" uk-grid="true">
                <div className="uk-width-3-4@m bg-white">
                    <ul id="video-slider" className="uk-switcher">
                        {this.state.article.files.map((item, i) => (
                            <li>
                                {/* to autoplay video uk-video="automute: true" */}
                                <div className="embed-video">
                                    {item.typeStorage == 4 ? (
                                        <iframe
                                            src={`https://www.youtube.com/embed/${item.path}?enablejsapi=1`}
                                            frameBorder={0}
                                            uk-video={i == 0 ? "automute: true" : ""}
                                            allowFullScreen
                                            uk-responsive="true"
                                        />
                                    ) : (
                                        <VimeoPlayer
                                            options={{
                                                id: item.path.split("/")[item.path.split("/").length - 1],
                                                width: 750,
                                            }}
                                        />
                                    )}
                                    {/* <VimeoPlayer options={{
                                            id: 418397307,
                                            width: 750
                                        }} /> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                    {/* video description contents */}
                    <div className="p-lg-5 p-3">
                        <h2> {this.state.article.name} </h2>
                        <div className="uk-grid-small" uk-grid="true">
                            <div className="uk-width-auto">
                                <span> {Common.formatDateTime(this.state.article.createdDate, "dd-mm-yyyy")} </span>
                            </div>
                        </div>
                        {/* <div className="uk-card-default rounded px-3 pb-md-3" uk-toggle="cls: uk-flex uk-flex-between@m uk-flex-middle; mode: media; media: @m">
                            <div className="user-details-card">
                                <div className="user-details-card-avatar">
                                    <img src="../assets/images/avatars/avatar-2.jpg" alt="" />
                                </div>
                                <div className="user-details-card-name">
                                    {this.state.article.categories != undefined && this.state.article.categories.map((item, i) =>
                                        <b>
                                            {item.name}
                                        </b>
                                    )} <span> Chia sẻ <span> <Moment locale="vi" fromNow>{this.state.article.createdDate}</Moment> </span> </span>
                                </div>
                            </div>
                            <div>
                                <strong className="ml-3 uk-visible@s"> Chia sẻ trên </strong>
                                <a href="#" className="iconbox iconbox-sm button primary circle">
                                    <i className="icon-brand-facebook-f" />
                                </a>
                                <a href="#" className="iconbox iconbox-sm button danger circle">
                                    <i className="icon-brand-twitter" />
                                </a>
                                <a href="#" className="iconbox iconbox-sm button linkedin circle">
                                    <i className="icon-brand-linkedin-in" />
                                </a>
                            </div>
                        </div> */}
                        <hr className="my-2" />
                        <h4 className="mt-4"> Thông tin chi tiết</h4>
                        <div dangerouslySetInnerHTML={{ __html: this.state.article.description }}></div>
                        {/*  off canvas  code*/}
                        {/* <div className="fb-comments" data-href={"https://" + window.merchantCode + ".izzimember.com/product/" + this.props.match.params.id + "/comment"} data-numposts="5" data-width="100%" width="100%"></div> */}
                        {window.tempAppId != "" && (
                            <FacebookProvider appId={window.tempAppId}>
                                <Comments
                                    href={"https://" + window.merchantCode + ".izzimember.com/product/" + this.props.match.params.id + "/comment"}
                                    width="100%"
                                />
                            </FacebookProvider>
                        )}
                    </div>
                    <div className="footer">
                        <div className="uk-grid-collapse uk-grid" uk-grid="true">
                            <div className="uk-width-expand@s uk-first-column">
                                <p>
                                    © 2020 <strong>IzziLearn</strong>. Đã đăng kí bản quyền.{" "}
                                </p>
                            </div>
                            <div className="uk-width-auto@s">
                                <nav className="footer-nav-icon">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="icon-brand-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="icon-brand-dribbble" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="icon-brand-youtube" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="icon-brand-twitter" />
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* sidebar */}
                <div className="uk-width-1-4@m uk-overflow-hidden vidlist-3-container">
                    <div uk-sticky="true">
                        <h5 className="bg-gradient-grey text-white py-4 p-3 mb-0"> {this.state.article.name}</h5>
                        <ul
                            className="uk-child-width-expand mb-0"
                            uk-switcher="animation: uk-animation-slide-right-small, uk-animation-slide-left-small"
                            uk-tab="true">
                            <li className="uk-active">
                                <a href="#"> Các nội dung</a>
                            </li>
                            <li>
                                <a href="#"> Chi tiết</a>
                            </li>
                        </ul>
                        <ul className="uk-switcher uk-overflow-hidden">
                            {/* first tab */}
                            <li>
                                <div className="vidlist-3-content" data-simplebar>
                                    <ul className="vidlist-3-section" uk-accordion="true">
                                        {/*  section 1  */}
                                        <li className="uk-open">
                                            <a className="uk-accordion-title" href="#">
                                                {" "}
                                                Bài học{" "}
                                            </a>
                                            <div className="uk-accordion-content">
                                                {/* vidlist */}
                                                <ul
                                                    className="vidlist-3"
                                                    uk-switcher="connect: #video-slider ; animation: uk-animation-slide-right-small, uk-animation-slide-left-medium">
                                                    {this.state.article.files.map((item, i) => (
                                                        <li className={i == 0 ? "uk-active" : ""}>
                                                            <a href="#">
                                                                {" "}
                                                                {item.name} <span> {item.ContentLength} phút</span>{" "}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {/* seccond tab */}
                            <li>
                                <img src="../assets/images/course/8.png" alt="" />
                                <div className="p-3">
                                    <ul className="uk-list">
                                        <li>
                                            {" "}
                                            <i className="icon-feather-clock" /> 2 Hours and 8 minutes{" "}
                                        </li>
                                        <li>
                                            {" "}
                                            <i className="uil-youtube-alt" /> {this.state.article.files.length} clip{" "}
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* small device icon video sidebar */}
                <span className="btn-vidlist-3" uk-toggle="target: #wrapper; cls: is-open" />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    updateBreadcrumb,
    setBreadcrumbAction,
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
