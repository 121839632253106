import { styled, size } from "../../../../../../../Utils/stitches.config";

export const StyledLoadingBox = styled("div", {
  textAlign: "center",
  paddingTop: 64,
  paddingBottom: 64,
});
export const StyledCertificatesSection = styled("div", {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 32,
  "& table thead tr th": {
    verticalAlign: "middle",
  },
});
export const StyledProfileImageContainer = styled("div", {
  textAlign: "center",
  display: "none",
  [size.md]: {
    textAlign: "right",
    display: "block",
  },
});
export const StyledInfo = styled("div", {
  whiteSpace: "nowrap",
  overflow: "auto",
  img: {
    display: "inline-block",
    marginRight: 16,
    position: "relative",
    top: -26,
  },
  [size.md]: {
    img: {
      display: "none",
    },
  },
  '[data-section="info"]': {
    display: "inline-block",
  },
});
export const StyledTag = styled("span", {
  display: "inline-block",
  borderRadius: 12,
  paddingLeft: 16,
  paddingRight: 16,
  variants: {
    type: {
      Failed: {
        background: "#ffedef",
        color: "#E8364E",
      },
      Created: {
        background: "#ebf3ff",
        color: "#316FEA",
      },
      Process: {
        background: "#FFF7E0",
        color: "#F3B700",
      },
      Paid: {
        background: "#E7FFF8",
        color: "green",
      },
    },
  },
});
