import { gql } from "urql";

export const QUERY_NOTIFICATIONS = gql`
  query QueryNotifications(
    $limit: Int = 20
    $offset: Int = 0
    $tokenUser: String!
    $merchantId: String!
    $order: String = "desc"
    $sort: String = "createdDate"
  ) {
    notificationtransactions(
      param: {
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        tokenUser: $tokenUser
        merchantId: $merchantId
      }
    ) {
      items {
        id
        message
        urlReturn
        allStatus
        createdDate
      }
      totalCount
      message
    }
  }
`;
