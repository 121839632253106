import { gql } from "urql";

export const QUERY_MY_COURSES = gql`
  query QueryMyCourses(
    $languageId: String!
    $limit: Int = 12
    $offset: Int = 0
    $merchantId: String!
    $tokenId: String!
  ) {
    usercourses(
      param: {
        languageId: $languageId
        limit: $limit
        offset: $offset
        merchantId: $merchantId
        tokenUser: $tokenId
      }
    ) {
      totalCount
      items {
        id
        type
        name
        subDescription
        certificate {
          id
        }
        type
        period
        countLesson
        percent
        categories {
          id
          name
          type
        }
        images {
          id
          path
          displayOrder
          isFeatured
        }
        createdDate
        merchantLogo
      }
    }
  }
`;
