import React, { useState } from "react";
import { StyledPiPContainer } from "./styledComponents";
import { useMount, useSetState, useUnmount } from "react-use";

export function useQuizSecurity(props) {
  const [state, setState] = useSetState({
    ready: false,
    error: null,
  });
  const PiP = props?.show ? (
    <VideoRenderer
      onReady={() => {
        setState({ ready: true, error: null });
      }}
      onError={(error) => {
        setState({ error, ready: false });
      }}
    />
  ) : null;

  return [PiP, state.ready, state.error];
}

function VideoRenderer(props) {
  const [camvas, setCamvas] = useState(null);
  const [g, setG] = useState(null);

  useMount(() => {
    const camvas = window.initPico();
    const g = new window.GeetSmart(window.config.geetSmart);

    g.onRecognition((arr) => {
      console.log("face", arr);
    });
    g.recognition(camvas.video).catch(console.error);
    setG(g);
    setCamvas(camvas);
  });
  useUnmount(() => {
    camvas && stopBothVideoAndAudio(camvas.video.srcObject);
    g && g.stopRecognition && g.stopRecognition();
  });

  return (
    <StyledPiPContainer>
      <canvas id={"stream-video"} width={"640"} height={"480"} />
    </StyledPiPContainer>
  );
}

// stop both mic and camera
// https://stackoverflow.com/a/11646945/6435579
function stopBothVideoAndAudio(stream) {
  stream.getTracks().forEach(function (track) {
    if (track.readyState === "live") {
      track.stop();
    }
  });
}
