/* eslint-disable */
import React, { Component, Fragment } from "react";
// import { pdfjs, Document, Page, Outline } from 'react-pdf';
// import { PDFReader } from 'react-read-pdf';
import Configuration from "../../../../../Config/Config";
// import Development from './Development.pdf'
import WebViewer from '@pdftron/webviewer';
require("dotenv").config();

export default class BookRead extends Component {
    constructor() {
        super();
        this.viewer = React.createRef();
        this.docViewer = null;
        this.annotManager = null;
        this.instance = null;
    }

    componentDidMount() {
        // WebViewer({
        //     // path: '',
        //     path: "http://static.izzi.asia//ebook/pdf/sau-chiec-mu-tu-duy.pdf",
        // }, this.viewer.current)
        //     .then(instance => {
        //         // use APIs here
        //         // at this point, the viewer is 'ready'
        //         // call methods from instance, docViewer and annotManager as needed
        //         this.instance = instance;
        //         this.docViewer = instance.docViewer;
        //         this.annotManager = instance.annotManager;

        //         // you can also access major namespaces from the instance as follows:
        //         // var Tools = instance.Tools;
        //         // var Annotations = instance.Annotations;

        //         // now you can access APIs through `this.instance`
        //         this.instance.openElement('notesPanel')

        //         // or listen to events from the viewer element
        //         this.viewer.current.addEventListener('pageChanged', (e) => {
        //             const [pageNumber] = e.detail;
        //             console.log(`Current page is ${pageNumber}`);
        //         });

        //         // or from the docViewer instance
        //         this.docViewer.on('annotationsLoaded', () => {
        //             console.log('annotations loaded');
        //         });

        //         this.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler)
        //     })

        const { path } = this.props.location.state;

        window
            .WebViewer(
                {
                    path: "/lib",
                    // useDownloader: false,
                    initialDoc: Configuration.staticDomain + path, //"https://static.izzi.asia//ebook/pdf/sau-chiec-mu-tu-duy.pdf",
                },
                this.viewer.current
            )
            .then((instance) => {
                //disable featured
                instance.disableFeatures(instance.Feature.Download);
                instance.disableFeatures(instance.Feature.Print);
                instance.disableFeatures(instance.Feature.Annotations);
                // at this point, the viewer is 'ready'
                // call methods from instance, docViewer and annotManager as needed
                this.instance = instance;
                this.docViewer = instance.docViewer;
                this.annotManager = instance.annotManager;

                // you can also access major namespaces from the instance as follows:
                // var Tools = instance.Tools;
                // var Annotations = instance.Annotations;

                // now you can access APIs through `this.instance`
                this.instance.openElement("notesPanel");

                // or listen to events from the viewer element
                this.viewer.current.addEventListener("pageChanged", (e) => {
                    const [pageNumber] = e.detail;
                    console.log(`Current page is ${pageNumber}`);
                });

                // or from the docViewer instance
                this.docViewer.on("annotationsLoaded", () => {
                    console.log("annotations loaded");
                });

                this.docViewer.on("documentLoaded", this.wvDocumentLoadedHandler);
            });
    }

    wvDocumentLoadedHandler = () => {
        // call methods relating to the loaded document
        const { Annotations } = this.instance;
        const rectangle = new Annotations.RectangleAnnotation();
        rectangle.PageNumber = 1;
        rectangle.X = 100;
        rectangle.Y = 100;
        rectangle.Width = 250;
        rectangle.Height = 250;
        rectangle.StrokeThickness = 5;
        rectangle.Author = this.annotManager.getCurrentUser();
        this.annotManager.addAnnotation(rectangle);
        this.annotManager.drawAnnotations(rectangle.PageNumber);
        // see https://www.pdftron.com/api/web/WebViewer.html for the full list of low-level APIs
    };

    render() {
        return (
            <Fragment>
                <div className="page-content-inner">
                    <div className="App">
                        <div className="webviewer" ref={this.viewer}></div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
