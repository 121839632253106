import React, { Fragment, useState } from "react";
import { useMount, useSetState } from "react-use";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import "../../../Course/maxcoach/UserCourseDetails/styles.less";
import VimeoPlayer from "../../VimeoPlayer";
import { Divider } from "antd";
import { Observer } from "../../../../../../Utils/Ob";

function MaxcoachVideoDetail(props) {
  const [state, setState] = useSetState({
    article: {
      name: "",
      subDescription: "",
      description: "",
      createdDate: "",
      files: [],
      images: [],
    },
  });
  

  const [article, setArticle] = useState();
  useMount(() => {
    window.showPageLoading();
    QueryGraphQL.getArticleById(props.match.params.id)
      .then((data) => {
        setState({
          article: data.article,
        });
        setArticle(data.article);
      })
      .finally(() => window.hidePageLoading());
  });

  if (state.article.files.length === 0) return null;
  
  return (
    
    <div id={"dongsec"} style={{ padding: 8, height: "calc(100vh - 85px)", position: "relative" }}>
      <div className={"right-section hover video"}>
        {state.article.files.map((item, i) => {
          return (
            <Fragment key={i}>
              {i > 0 && <div style={{ width: "100%", height: 60, opacity: 0 }} />}
              <h3 className="course-item-title question-title">{item.name}</h3>
              <InfoDivider info={""} />
              <div className="videoWrapper">
                {item.typeStorage === 4 ? (
                  <iframe
                    title={"youtube"}
                    key={[item.id].join()}
                    src={`https://www.youtube.com/embed/${item.path}?enablejsapi=1`}
                    frameBorder={0}
                    allowFullScreen
                    width="560"
                    height="349"
                  />
                ) : (
                  <VimeoPlayer
                    key={[item.id].join()}
                    options={{
                      id: item.path.split("/")[item.path?.split("/").length - 1],
                      width: "100%",
                    }}
                  />
                )}
              </div>
              <div className={"content-item-description lesson-description"}>
<br></br>
                <h6>
                {article?.subDescription}
                </h6>
                <div dangerouslySetInnerHTML={{ __html: article?.description }}></div>

              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Observer(MaxcoachVideoDetail);

function InfoDivider(props) {
  return (
    <div style={{ position: "relative" }}>
      <Divider />
      <div className={"divider-text"}>{props.info}</div>
    </div>
  );
}
