import { styled, size } from "../../../../../../Utils/stitches.config";
import { borderColor, primaryTextColor } from "../../../../../../Utils/themeConfig";

export const StyledTag = styled("span", {
  display: "inline-block",
  borderRadius: 8,
  border: "1px solid " + borderColor,
  marginRight: 16,
  marginTop: 16,
  padding: "8px 16px",
  color: primaryTextColor,
  variants: {
    border: {
      no: {
        borderWidth: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
});
export const StyledShare = styled("div", {
  textAlign: "left",
  [size.lg]: {
    textAlign: "right",
  },
});
