import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useMeasure, useMount, useSetState, useUnmount } from "react-use";
import PropTypes from "prop-types";
import { Dropdown, Drawer, Modal, Input, Menu, Button } from "antd";
import Configuration from "../../../../../Config/Config";
import Common from "../../../../../Utils/Common";
import { Avatar, NotificationDot, ProfileSection, DisableMenuItem, LanguageIcon } from "./styled";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";
import { QUERY_NOTIFICATIONS } from "../../../../Components/Layout/Header/components/maxcoach/NotificationsListPage/gql";
import { getCommonStorage } from "../../../../../Config/gql";
import { StyledSearch } from "./styledComponents";
import { getMembershipMenuNavigations } from "../../../../Services/UserService";

import queryString from "query-string";
import {
  StyledMemberName,
  StyledMemberPoint,
} from "../../../../Components/Layout/User Account/Profile/maxcoach/Profile/styled";
import { Notifications } from "./Notifications";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import axios from "axios";
import { getCartAction } from "../../../../Actions/Cart";
import { getCart } from "../../../../Services/CartService";
import { QueryGraphQL } from "../../../../Queries/GraphQl";
import { setLanguageIdAction } from "../../../../Actions/Layout";

const { $ } = window;

const languageList = [
  {
    code: "vn",
    name: "Việt Nam",
    src: "https://static.izzi.asia/images/FlagIcons/vn.svg",
  },
  {
    code: "en",
    name: "English",
    src: "https://static.izzi.asia/images/FlagIcons/us.svg",
  },
];

function Header(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(window.localStorage.i18nextLng || "vn");
  const [ref, refInfo] = useMeasure();
  const breadcrumb = useSelector((state) => state.Layout.breadcrumb);

  const [navigation, setNavigation] = useState([]);
  const [state, setState] = useSetState({
    openDrawer: false,
    notifications: [],
    searchValue: "",
    cart: [],
  });
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [mobileRef, mobileRefInfo] = useMeasure();
  const history = useHistory();
  const user = useSelector((state) => state.User.user);
  console.log(user);
  const [fetchNotifications, refetchNotifications] = useQuery({
    query: QUERY_NOTIFICATIONS,
    requestPolicy: "cache-and-network",
    variables: (() => {
      const { tokenId: tokenUser, merchantId } = getCommonStorage();

      return {
        tokenUser,
        merchantId,
        offset: 0,
        limit: 20,
        // sort: "allStatus",
        // order: "asc",
      };
    })(),
  });
  const location = useLocation();
  const merchant = useSelector((state) => state.Merchant.merchant);
  const languageId = useSelector((state) => state.Layout.languageId);
  const cart = useSelector((state) => state.Cart.cart);

  const _search = () => {
    // alert(state.searchValue);
    setIsSearchOpen(false);
    history.push({
      pathname: "/product",
      search: "?q=" + encodeURIComponent(state.searchValue),
    });
  };

  const [menu, setMenu] = useState();
  const calculateCartLength = () => {
    const result = cart.reduce((acc, value) => {
      acc += value.quantity;
      return acc;
    }, 0);
    return result;
  };
  useEffect(() => {
    props.onHeightChange && props.onHeightChange(refInfo.height);
  }, [refInfo.height]);
  useEffect(() => {
    setState({
      notifications: fetchNotifications.data?.notificationtransactions?.items || [],
    });
  }, [fetchNotifications.data]);
  useEffect(() => {
    refetchNotifications();
  }, [location.pathname]);
  useEffect(() => {
    console.log(location.search);

    const params = queryString.parse(location.search);

    setState({ searchValue: params.q || "" });
  }, [location.search]);
  useEffect(() => {
    const onMessage = (payload) => {
      // console.log("new notifications", payload.data);
      refetchNotifications();
    };

    getMembershipMenuNavigations({ languageId }).then((result) => {
      if (result.membershipmenunavigations?.items?.length > 0) {
        console.log(JSON.parse(result.membershipmenunavigations.items[0].navigation));
        setMenu(JSON.parse(result.membershipmenunavigations.items[0].navigation));
      }
    });

    navigator.serviceWorker.addEventListener(
      "message",
      (event) =>
        event.data["firebase-messaging-msg-data"] &&
        onMessage(event.data["firebase-messaging-msg-data"])
    );
    getCart().then((data) => dispatch(getCartAction(data)));

    return () => {
      navigator.serviceWorker.removeEventListener("message", onMessage);
    };
  }, [window.localStorage.getItem("i18nextLng")]);
  useEffect(() => {
    const izziMember = JSON.parse(merchant.izziMember || "{}");
    console.log("run 147", izziMember.ProfileMenu);
    if (izziMember.ProfileMenu) {
      console.log("run 149");
      QueryGraphQL.getNavigation(izziMember.ProfileMenu, languageId).then((data) => {
        setNavigation([...JSON.parse(data.navigation.navigation)]);
      });
    }
  }, [merchant, languageId]);
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
    dispatch(setLanguageIdAction(Common.getCurrentLanguageId()));
  };
  if (!localStorage.getItem(window.config.localStorageTokenId)) return null;

  let arrayUrl;

  if (window.location.href.indexOf("?") > 0) {
    arrayUrl = window.location.href.substring(0, window.location.href.indexOf("?")).split("/");
  } else {
    arrayUrl = window.location.href.split("/");
  }

  // noinspection JSMismatchedCollectionQueryUpdate
  let temp = [{ name: "Home", href: "/" }];

  if (arrayUrl.length > 2) {
    temp.push({
      name: arrayUrl[3].charAt(0).toUpperCase() + arrayUrl[3].slice(1),
      href: "/" + arrayUrl[3],
    });
  }

  if (arrayUrl.length > 4) {
    temp.push({
      //name: arrayUrl[5], href: '/' + arrayUrl[3] + '/' + arrayUrl[4] + '/' + arrayUrl[5]
      name: breadcrumb,
      href: "/" + arrayUrl[3] + "/" + arrayUrl[4] + "/" + arrayUrl[5],
    });
  }

  const fullName = [user.lastName, user.firstName].filter((v) => !!v).join(" ");
  const numberOfNotifications = state.notifications.filter((e) => e.allStatus === 1).length;
  const numberOfCheckout = 0;

  const logoDark = JSON.parse(window.Merchant.data.configuration)["DarkLogoPath"];  
  const logoLight = JSON.parse(window.Merchant.data.configuration)["LightLogoPath"];  
  let logo = Common.getImageThumbnail(merchant.images, "?mode=crop&height=32");

  if (merchant.code === "baa") {
    logo = require("../../../../Assets/svg/Logo-BAA.png").default;
  }

  return (
    <React.Fragment>
      <div className="header-section header-shadow sticky-header section" ref={ref}>
        <div className="header-inner">
          <div className="container position-relative">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-2 col-auto">
                <div className="header-logo">
                  <a href={window.merchantConfig.UrlReturn || "/"}>
                    <img
                      className="dark-logo"
                      src={(logoDark != undefined && logoDark != "") ? logoDark  + "?mode=crop&height=44": logo}
                      alt="Learts Logo"
                      style={{
                        width: "auto",
                        maxHeight: 64,
                        padding: 0,
                      }}
                    />
                    <img
                      className="light-logo"
                      src={(logoLight != undefined && logoLight !== "") ? logoLight  + "?mode=crop&height=44": logo}
                      alt="Learts Logo"
                      style={{
                        width: "auto",
                        maxHeight: 64,
                        padding: 0,
                      }}
                    />
                  </a>
                </div>
              </div>
              <div className="col d-none d-xl-block position-static">
                <nav className="site-main-menu menu-hover-1" style={{ justifyContent: "normal" }}>
                  {/*maxcoach\partials\header\menu.ejs*/}
                  <RenderMenu menu={menu} />
                  {/* end:maxcoach\partials\header\menu.ejs */}
                </nav>
              </div>
              <div className="col-xl-3 col-auto">
                <div className="header-right">
                  <div className="inner">
                    {/*<div className="header-login">*/}
                    {/*  <Link to="/usercourse">*/}
                    {/*    <i*/}
                    {/*      className="uil-youtube-alt"*/}
                    {/*      uk-tooltip="title: Khóa học của tôi;pos: bottom"*/}
                    {/*    />*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    {/*<Notification />*/}
                    {/*<div className="header-login">*/}
                    {/*  <Link to={"/"} onClick={(e) => e.preventDefault()}>*/}
                    {/*    <i className="uil-envelope-alt" uk-tooltip="title: Gmail;pos: bottom" />*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div className="header-login">
                      {/*  {isSearchOpen ? (
                        <StyledSearch type={"pc"}>
                          <Input
                            onChange={(e) => setState({ searchValue: e.target.value })}
                            value={state.searchValue}
                            style={{ background: "#F5F5F5", borderRadius: 8, marginTop: "4px" }}
                            placeholder={t("Search courses")}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                _search();
                              }
                            }}
                            onClick={(e) => {
                              e.target.select();
                            }}
                            suffix={
                              <svg
                                className="cursor-pointer"
                                onClick={() => {
                                  _search();
                                }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10.9375 9.625H10.2462L10.0012 9.38875C10.8587 8.39125 11.375 7.09625 11.375 5.6875C11.375 2.54625 8.82875 0 5.6875 0C2.54625 0 0 2.54625 0 5.6875C0 8.82875 2.54625 11.375 5.6875 11.375C7.09625 11.375 8.39125 10.8587 9.38875 10.0012L9.625 10.2462V10.9375L14 15.3037L15.3037 14L10.9375 9.625ZM5.6875 9.625C3.50875 9.625 1.75 7.86625 1.75 5.6875C1.75 3.50875 3.50875 1.75 5.6875 1.75C7.86625 1.75 9.625 3.50875 9.625 5.6875C9.625 7.86625 7.86625 9.625 5.6875 9.625Z"
                                  fill="#316FEA"
                                />
                              </svg>
                            }
                          />
                        </StyledSearch>
                      ) : ( */}
                      <button
                        className="cursor-pointer mt-1 header-fs-search-toggle"
                        onClick={() => setIsSearchOpen(true)}>
                        <i class="far fa-search"></i>
                      </button>
                      {/*   )} */}
                    </div>
                    <div className="header-login">
                      <Link class="header-cart-btn" to="/shopping-cart">
                        <span class="cart-count totalShoppingCartItem">
                          {calculateCartLength()}
                        </span>
                        <i class="fal fa-shopping-bag"></i>
                      </Link>
                    </div>
                    <div className="header-login">
                      <Notifications
                        fetchNotifications={fetchNotifications}
                        refetchNotifications={refetchNotifications}
                      />
                    </div>

                    <div className="header-login">
                      <Dropdown
                        overlay={
                          <ProfileSection>
                            <ul>
                              {languageList.map((item) => (
                                <li key={item.code} onClick={() => changeLanguage(item.code)}>
                                  <LanguageIcon>
                                    <img src={item.src} alt={item.code} />
                                  </LanguageIcon>
                                </li>
                              ))}
                            </ul>
                          </ProfileSection>
                        }>
                        <LanguageIcon>
                          {currentLanguage === "vn" ? (
                            <img
                              src="https://static.izzi.asia/images/FlagIcons/vn.svg"
                              alt="vn"
                              onClick={() => changeLanguage("vn")}
                            />
                          ) : (
                            <img
                              src="https://static.izzi.asia/images/FlagIcons/us.svg"
                              alt="vn"
                              onClick={() => changeLanguage("en")}
                            />
                          )}
                        </LanguageIcon>
                      </Dropdown>
                    </div>
                    <div className="header-login">
                      <Dropdown
                        overlay={
                          <ProfileSection>
                            <ul>
                              <li onClick={() => history.push("/profile")}>
                                <div className={"dx-name"}>
                                  {fullName}
                                  {/* {user.memberName && (
                                    <StyledMemberName>({user.memberName})</StyledMemberName>
                                  )} */}
                                </div>
                                <div className={"dx-email"}>{user.email}</div>
                                <StyledMemberPoint>
                                  <span>{user.totalPoint}</span> {t("point")}
                                </StyledMemberPoint>
                              </li>

                              <li>
                                {t("Member")}:
                                <b>
                                  {user.memberName && (
                                    <StyledMemberName>{user.memberName}</StyledMemberName>
                                  )}
                                </b>
                              </li>
                              <li onClick={() => history.push("/product-purchase")}>
                                {t("Membership Levels")}
                              </li>
                              <li>
                                {t("Expired Date")}:{" "}
                                <b>
                                  {user.expiredDate &&
                                    Common.formatDateTime(user.expiredDate, "dd-mm-yyyy")}{" "}
                                </b>
                              </li>
                              {navigation?.length > 0 &&
                                navigation.map((nav) => {
                                  return (
                                    <li onClick={() => history.push(`${nav.Description}`)}>
                                      {nav.Name}
                                    </li>
                                  );
                                })}
                              {/* <li onClick={() => history.push("/profile")}>{t("My Profile")}</li>

                              <DisableMenuItem>Education</DisableMenuItem>
                              <li style={{}} onClick={() => history.push("/education")}>
                                {t("Education")}
                              </li>
                              <DisableMenuItem>Professional Qualification</DisableMenuItem>
                              <li onClick={() => history.push("/profile/qualification")}>
                                {t("Professional Qualification")}
                              </li>
                              <DisableMenuItem> Professional Membership</DisableMenuItem>
                              <li onClick={() => history.push("/profile/membership")}>
                                {t("Professional Membership")}
                              </li>
                              <DisableMenuItem>Award</DisableMenuItem>
                              <li onClick={() => history.push("/wishlist")}>{t("WishList")}</li>
                              <li onClick={() => history.push("/profile/award")}>{t("Award")}</li>
                              <li onClick={() => history.push("/profile/contact")}>
                                {t("Contact Information")}
                              </li>
                              <li onClick={() => history.push("/work/company")}>
                                {t("My Company")}
                              </li>
                              <li onClick={() => history.push("/board/experience")}>
                                {t("Board Experience")}
                              </li>
                              <li onClick={() => history.push("/work/experience")}>
                                {t("Work Experience")}
                              </li>
                              <li onClick={() => history.push("/cv/preview")}>{t("Preview CV")}</li>
                           
                              <li onClick={() => history.push("/board-open/list")}> 
                                {t("Board Opening")}
                              </li>
                              <li onClick={() => history.push("/board/preference")}>
                                {t("Directorship Opportunity")}
                              </li> */}
                              <li className={"dx-divider"} />
                              {/* <li onClick={() => history.push("/contest")}>My Contest</li> */}
                              <li onClick={() => history.push("/usercourse")}>{t("My Courses")}</li>
                              <li onClick={() => history.push("/profile?tab=quizzes")}>
                                {t("My Test Results")}
                              </li>
                              <li className={"dx-divider"} />

                              <li hidden={true}>{t("My Purchase")}</li>
                              <li className={"dx-divider"} />
                              <li onClick={() => history.push("/notifications-list")}>
                                <span className={"dx-label"}>
                                  {t("Notifications")}
                                  <NotificationDot hidden={numberOfNotifications === 0} />
                                </span>
                              </li>
                              <li onClick={() => history.push("/profile-edit")}>
                                {t("Account Settings")}
                              </li>
                              <li onClick={() => history.push("/personal-details-edit")}>
                                {t("Personal Details")}
                              </li>
                              <li className={"dx-divider"} />
                              <li
                                className={"dx-logout"}
                                onClick={() => {
                                  // if (window.confirm("Are you sure you want to logout?")) {
                                  //   logout();
                                  // }
                                  Modal.confirm({
                                    title: "Confirm",
                                    content: "Are you sure you want to logout?",
                                    okText: "Logout",
                                    onOk() {
                                      logout();
                                    },
                                  });
                                }}>
                                {t("Logout")}
                              </li>
                            </ul>
                            {/*<Menu>*/}
                            {/*  <Menu.Item*/}
                            {/*    icon={*/}
                            {/*      <FA>*/}
                            {/*        <i className="fas fa-user-circle" />*/}
                            {/*      </FA>*/}
                            {/*    }>*/}
                            {/*    <Link to={"/profile"}>Profile</Link>*/}
                            {/*  </Menu.Item>*/}
                            {/*  /!*<Menu.Item*!/*/}
                            {/*  /!*  icon={*!/*/}
                            {/*  /!*    <FA>*!/*/}
                            {/*  /!*      <i className="fas fa-shopping-cart" />*!/*/}
                            {/*  /!*    </FA>*!/*/}
                            {/*  /!*  }>*!/*/}
                            {/*  /!*  <Link to={"/order"}>Đơn hàng của tôi</Link>*!/*/}
                            {/*  /!*</Menu.Item>*!/*/}
                            {/*  /!*<Menu.Item*!/*/}
                            {/*  /!*  icon={*!/*/}
                            {/*  /!*    <FA>*!/*/}
                            {/*  /!*      <i className="fas fa-sack-dollar" />*!/*/}
                            {/*  /!*    </FA>*!/*/}
                            {/*  /!*  }>*!/*/}
                            {/*  /!*  <Link to={"/affiliate"}>Affiliate</Link>*!/*/}
                            {/*  /!*</Menu.Item>*!/*/}
                            {/*  /!*<Menu.Item*!/*/}
                            {/*  /!*  icon={*!/*/}
                            {/*  /!*    <FA>*!/*/}
                            {/*  /!*      <i className="fad fa-bags-shopping" />*!/*/}
                            {/*  /!*    </FA>*!/*/}
                            {/*  /!*  }>*!/*/}
                            {/*  /!*  <Link to={"/affiliateorder"}>Đơn hàng Affiliate</Link>*!/*/}
                            {/*  /!*</Menu.Item>*!/*/}
                            {/*  <Menu.Item*/}
                            {/*    icon={*/}
                            {/*      <FA>*/}
                            {/*        <i className="fas fa-key" />*/}
                            {/*      </FA>*/}
                            {/*    }>*/}
                            {/*    <Link to={"/change-password"}>Đổi mật khẩu</Link>*/}
                            {/*  </Menu.Item>*/}
                            {/*  <Menu.Item*/}
                            {/*    icon={*/}
                            {/*      <FA>*/}
                            {/*        <i className="fas fa-sign-out-alt" />*/}
                            {/*      </FA>*/}
                            {/*    }>*/}
                            {/*    <Link*/}
                            {/*      to={"/"}*/}
                            {/*      onClick={(e) => {*/}
                            {/*        e.preventDefault();*/}
                            {/*        if (window.confirm("Xác nhận đăng xuất?")) {*/}
                            {/*          logout();*/}
                            {/*        }*/}
                            {/*      }}>*/}
                            {/*      Đăng xuất*/}
                            {/*    </Link>*/}
                            {/*  </Menu.Item>*/}
                            {/*</Menu>*/}
                          </ProfileSection>
                        }
                        trigger={["click"]}>
                        <Link
                          to={"/profile"}
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}>
                          <Avatar>
                            <img
                              src={user.avatar ? Configuration.imageUrl + user.avatar : logo}
                              alt="Avatar"
                            />
                            <NotificationDot type={"avatar"} hidden={true} />
                          </Avatar>
                        </Link>
                      </Dropdown>
                    </div>
                    {/*<div className="header-login" hidden={true}>*/}
                    {/*  <Link to={"/"} onClick={(e) => e.preventDefault()}>*/}
                    {/*    <span className={"dx-relative"}>*/}
                    {/*      <i className="fas fa-shopping-cart" />*/}
                    {/*      <NotificationDot type={"number"} hidden={numberOfCheckout === 0}>*/}
                    {/*        {numberOfCheckout}*/}
                    {/*      </NotificationDot>*/}
                    {/*    </span>*/}
                    {/*  </Link>*/}
                    {/*</div>*/}
                    <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                      <button className="toggle" onClick={() => setState({ openDrawer: true })}>
                        <i className="icon-top" />
                        <i className="icon-middle" />
                        <i className="icon-bottom" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fullscreen-search" className={`fullscreen-search ${isSearchOpen ? "open" : ""}`}>
        <div className="fullscreen-search-close">
          <button onClick={() => setIsSearchOpen(false)} className="toggle">
            <i className="icon-top"></i>
            <i className="icon-bottom"></i>
          </button>
        </div>
        <div className="fullscreen-search-form">
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              onChange={(e) => setState({ searchValue: e.target.value })}
              value={state.searchValue}
              style={{ background: "#F5F5F5", borderRadius: 8, marginTop: "4px" }}
              placeholder={t("Search courses")}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  _search();
                }
              }}
              onClick={(e) => {
                e.target.select();
              }}
              type="text"
            />
          </form>
        </div>
      </div>
      <Drawer
        width={mobileRefInfo.width}
        zIndex={9999}
        placement={"right"}
        closable={false}
        bodyStyle={{ padding: 0 }}
        onClose={() => setState({ openDrawer: false })}
        visible={state.openDrawer}>
        <div
          style={{
            position: "relative",
            left: -mobileRefInfo.width,
            height: "100%",
          }}>
          <div className="site-main-mobile-menu-inner" ref={mobileRef}>
            <div className="mobile-menu-header">
              <div className="mobile-menu-logo" />
              <div className="mobile-menu-close" onClick={() => setState({ openDrawer: false })}>
                <button className="toggle">
                  <i className="icon-top" />
                  <i className="icon-bottom" />
                </button>
              </div>
            </div>
            <div className="mobile-menu-content">
              <nav className="site-mobile-menu">
                <StyledSearch type={"mobile"}>
                  <Input
                    onChange={(e) => setState({ searchValue: e.target.value })}
                    value={state.searchValue}
                    style={{ background: "#F5F5F5", borderRadius: 8 }}
                    placeholder={t("Search courses")}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        _search();
                      }
                    }}
                    onClick={(e) => e.target.select()}
                    suffix={
                      <svg
                        onClick={_search}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.9375 9.625H10.2462L10.0012 9.38875C10.8587 8.39125 11.375 7.09625 11.375 5.6875C11.375 2.54625 8.82875 0 5.6875 0C2.54625 0 0 2.54625 0 5.6875C0 8.82875 2.54625 11.375 5.6875 11.375C7.09625 11.375 8.39125 10.8587 9.38875 10.0012L9.625 10.2462V10.9375L14 15.3037L15.3037 14L10.9375 9.625ZM5.6875 9.625C3.50875 9.625 1.75 7.86625 1.75 5.6875C1.75 3.50875 3.50875 1.75 5.6875 1.75C7.86625 1.75 9.625 3.50875 9.625 5.6875C9.625 7.86625 7.86625 9.625 5.6875 9.625Z"
                          fill="#316FEA"
                        />
                      </svg>
                    }
                  />
                </StyledSearch>
                <RenderMenu
                  menu={menu}
                  mobile={true}
                  onClickItem={() => setState({ openDrawer: false })}
                />
              </nav>
            </div>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

Header.propTypes = {
  onHeightChange: PropTypes.func,
};

function RenderMenu(props) {
  const { t } = useTranslation();
  const _onClick = () => {
    props.onClickItem && props.onClickItem();
  };

  useMount(() => {
    fixExpand();
  });
  useUnmount(() => {
    $(".site-mobile-menu").off("click", ".menu-toggle");
  });

  return (
    <ul>
      {
        //console.log(props.menu)
        props.menu?.map((val, i) => {
          return val.Categories.length > 0 ? (
            <li className="has-children">
              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                <span className="menu-text">{val.Name}</span>
              </Link>
              <span className="menu-toggle">
                <i className="far fa-angle-down" />
              </span>
              <ul className="sub-menu w-auto">
                {val.Categories.map((item, index) => {
                  return (
                    <li onClick={_onClick}>
                      <Link to={item.Description}>{item.Name}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            <li className="position-static" onClick={_onClick}>
              <Link to={val.Description}>{val.Name}</Link>
            </li>
          );
        })
      }
      {/* <li className="position-static" onClick={_onClick}>
              <Link to="/product-purchase">Membership Levels</Link>
            </li> */}
    </ul>

    // <li className="position-static" onClick={_onClick}>
    //   <Link to={"/product"}>Courses</Link>
    // </li>
    // {/*<li className="position-static" onClick={_onClick}>*/}
    // {/*  <Link to={"/usercourse"}>Khóa học của tôi</Link>*/}
    // {/*</li>*/}
    // <li className="has-children">
    //   <Link to={"/"} onClick={(e) => e.preventDefault()}>
    //     <span className="menu-text">Shared</span>
    //   </Link>
    //   <span className="menu-toggle">
    //     <i className="far fa-angle-down" />
    //   </span>
    //   <ul className="sub-menu">
    //     <li onClick={_onClick}>
    //       <Link to={"/course"}>Learning materials</Link>
    //     </li>
    //     <li onClick={_onClick}>
    //       <Link to={"/book"}>Books</Link>
    //     </li>
    //     {/*<li onClick={_onClick}>*/}
    //     {/*  <Link to={"/slide"}>Slide</Link>*/}
    //     {/*</li>*/}
    //     {/*<li onClick={_onClick}>*/}
    //     {/*  <Link to={"/video"}>Video</Link>*/}
    //     {/*</li>*/}
    //     {/*<li onClick={_onClick}>*/}
    //     {/*  <Link to={"/quiz"}>Quiz</Link>*/}
    //     {/*</li>*/}
    //   </ul>
    // </li>
    // <li className="has-children">
    //   <Link to={"/"} onClick={(e) => e.preventDefault()}>
    //     <span className="menu-text">Blog</span>
    //   </Link>
    //   <span className="menu-toggle">
    //     <i className="far fa-angle-down" />
    //   </span>
    //   <ul className="sub-menu">
    //     <li onClick={_onClick}>
    //       <Link to={"/accessible-blog"}>Blog</Link>
    //     </li>
    //     <li onClick={_onClick}>
    //       <Link to={"/blog"}>Followed Categories</Link>
    //     </li>
    //     <li onClick={_onClick}>
    //       <Link to={"/blogAuthor"}>Followed Authors</Link>
    //     </li>
    //   </ul>
    // </li>
    // <li className="position-static" onClick={_onClick}>
    //   <Link to={"/calendar"}>Calendar</Link>
    // </li>

    // <ul>

    //   <li className="position-static" onClick={_onClick}>
    //     <Link to={"/"}>Home</Link>
    //   </li>
    //   <li className="position-static" onClick={_onClick}>
    //     <Link to={"/product"}>Courses</Link>
    //   </li>
    //   {/*<li className="position-static" onClick={_onClick}>*/}
    //   {/*  <Link to={"/usercourse"}>Khóa học của tôi</Link>*/}
    //   {/*</li>*/}
    //   <li className="has-children">
    //     <Link to={"/"} onClick={(e) => e.preventDefault()}>
    //       <span className="menu-text">Shared</span>
    //     </Link>
    //     <span className="menu-toggle">
    //       <i className="far fa-angle-down" />
    //     </span>
    //     <ul className="sub-menu">
    //       <li onClick={_onClick}>
    //         <Link to={"/course"}>Learning materials</Link>
    //       </li>
    //       <li onClick={_onClick}>
    //         <Link to={"/book"}>Books</Link>
    //       </li>
    //       {/*<li onClick={_onClick}>*/}
    //       {/*  <Link to={"/slide"}>Slide</Link>*/}
    //       {/*</li>*/}
    //       {/*<li onClick={_onClick}>*/}
    //       {/*  <Link to={"/video"}>Video</Link>*/}
    //       {/*</li>*/}
    //       {/*<li onClick={_onClick}>*/}
    //       {/*  <Link to={"/quiz"}>Quiz</Link>*/}
    //       {/*</li>*/}
    //     </ul>
    //   </li>
    //   <li className="has-children">
    //     <Link to={"/"} onClick={(e) => e.preventDefault()}>
    //       <span className="menu-text">Blog</span>
    //     </Link>
    //     <span className="menu-toggle">
    //       <i className="far fa-angle-down" />
    //     </span>
    //     <ul className="sub-menu">
    //       <li onClick={_onClick}>
    //         <Link to={"/accessible-blog"}>Blog</Link>
    //       </li>
    //       <li onClick={_onClick}>
    //         <Link to={"/blog"}>Followed Categories</Link>
    //       </li>
    //       <li onClick={_onClick}>
    //         <Link to={"/blogAuthor"}>Followed Authors</Link>
    //       </li>
    //     </ul>
    //   </li>
    //   <li className="position-static" onClick={_onClick}>
    //     <Link to={"/calendar"}>Calendar</Link>
    //   </li>
    // </ul>
  );
}

export default Header;

function fixExpand() {
  $(".site-mobile-menu").on("click", ".menu-toggle", function (e) {
    e.preventDefault();
    let $this = $(this);
    if ($this.siblings(".sub-menu:visible, .mega-menu:visible").length) {
      $this
        .siblings(".sub-menu, .mega-menu")
        .slideUp()
        .parent()
        .removeClass("open")
        .find(".sub-menu, .mega-menu")
        .slideUp()
        .parent()
        .removeClass("open");
    } else {
      $this
        .siblings(".sub-menu, .mega-menu")
        .slideDown()
        .parent()
        .addClass("open")
        .siblings()
        .find(".sub-menu, .mega-menu")
        .slideUp()
        .parent()
        .removeClass("open");
    }
  });
}

function logout() {
  $.ajax({
    url: Configuration.urlApi + `/User/Logout?tokenId=${localStorage.getItem("userIzzi")}`,
    type: "GET",
    contentType: "application/json",
    processData: false,
    dataType: "json",
    success: function (res) {
      if (res.Success) {
        if (localStorage.getItem(Configuration.tokenDevice)) {
          let objData = {
            Id: localStorage.getItem(Configuration.userId),
            DeviceToken: localStorage.getItem(Configuration.tokenDevice),
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem(Configuration.userId),
          };
          Common.sendSyncCommand("RemoveDeviceTokenUser", "User", objData);
        }
        localStorage.removeItem("userIzzi");
        localStorage.removeItem(Configuration.userId);
        window.location.href = "/login";
      }
    },
    error: function (res) {
      console.log(res);
    },
  });
}
