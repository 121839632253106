/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";
import Swal from "sweetalert2";

export const createRatingTransaction = (values) => {
  console.log(values);

  Common.sendSyncCommandNoLoading(
    "CreateRatingTransaction",
    "CustomerRelationshipManagement",
    values,
    () => {
      if (values.onDone) {
        values.onDone();
      } else {
        Swal.fire("Đánh giá thành công");
      }
    },
    () => {
      if (values.onDone) {
        values.onDone(true);
      } else {
        Swal.fire("Đánh giá lỗi.");
      }
    }
  );
};

export const getRating = (id, targetId) => {
  const merchantId = localStorage.getItem(Config.merchantId);
  const userId = localStorage.getItem(Config.userId);

  var query = `
    {
      ratingmember(param:{
          id:"${id}"
          ,targetId:"${targetId}"
        ,merchantId:"${merchantId}"
       })
        {
              id,
              maxScore,
                ratingTransactions {
                    id,
                    comment,
                    score,
                    createdDate,
                    targetId,
                    userName,
                    userAllType,
                    userImagePath
                },

        }
      }
    `;
  return Common.getDataGraphQl(query);
};
