/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";
import Swal from "sweetalert2";

const languageId = localStorage.getItem(Config.languageId);
const merchantId = localStorage.getItem(Config.merchantId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);

export const createOrder = (obj) => {
  Common.sendSyncCommand(
    "CreateOrder",
    "CustomerRelationshipManagement",
    obj,
    () =>
      (window.location.href =
        "https://" +
        window.Merchant.data.code +
        ".izzipayment.com?id=" +
        obj.Id +
        "&urlReturn=https://" +
        encodeURI(window.location.href).replace("https://", "") +
        "&tokenId=" +
        tokenId),
    () => Swal.fire("Cập nhật thất bại")
  );
};

export const cancelOrder = (cancelOrderDTO) => {
  const stringCommandName = "RejectOrder";
  const stringDomain = "Order";
  let dataSend = {
    CommandName: stringCommandName,
    Domain: stringDomain,
    Content: JSON.stringify(cancelOrderDTO),
    ModifiedBy: localStorage.getItem(Config.userId),
    ModifiedDate: Common.formatDateTime(new Date()),
    TimeOutSecond: 10,
  };
  return Common.sendSyncCommand2(dataSend);
};

export const getOrders = (textSearch, allStatus, sort, order, limit, offset) => {
  var query = `
    {
      userorders(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,keyword:"${textSearch}"        
        ,tokenUser:"${tokenId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${allStatus ? `allStatus:${allStatus}` : ""}
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                giftCodeAmount,
                promotionAmount,
                paymentMethodName,
                note,
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile
                },
              },
             
              totalCount
        }
      }
    `;
  return Common.getDataGraphQl(query);
};

export const getAffiliateOrders = (textSearch, allStatus, sort, order, limit, offset) => {
  const languageId = localStorage.getItem(Config.languageId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem(window.config.localStorageTokenId);

  var query = `
    {
      affiliateorders(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,keyword:"${textSearch}"        
        ,tokenUser:"${tokenId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${allStatus ? `allStatus:${allStatus}` : ""}
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                giftCodeAmount,
                promotionAmount,
                paymentMethodName,
                affiliateCommission,
                userName,
                userMobile,
                userEmail,
                note,
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile
                },
              },
             
              totalCount
        }
      }
    `;
  return Common.getDataGraphQl(query);
};

export const getOrderDetail = (orderId) => {
  const languageId = localStorage.getItem(Config.languageId);
  const merchantId = localStorage.getItem(Config.merchantId);
  const tokenId = localStorage.getItem("userIzzi");
  var query = `
    {
        orders(param:{id:${orderId}
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
       })
        {
              items {
                id,
                code,
                createdDate,
                grandTotal,
                orderLines {
                  targetName
                }
              }
              ,totalCount
        }
      }
    `;
  return Common.getDataGraphQl(query);
};
