import React, { useEffect } from "react";
import * as scs from "./scs";
import { useMount, useSetState } from "react-use";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import { Observer } from "../../../../../../Utils/Ob";
import Common from "../../../../../../Utils/Common";
import moment from "moment";
import { getProduct } from "../../../../../Services/ProductService";
import { Link } from "react-router-dom";
import { getUserCoursePreview } from "../../../../../Services/CourseService";
import { getUser } from "../../../../../Services/UserService";
import Configuration from "../../../../../../Config/Config";
import { createOrderAction } from "../../../../../Actions/Order";
import { gql } from "urql";

const { $ } = window;

const a = gql`
  {
    usercoursepreview(param: { id: "", languageId: "", merchantId: "" }) {
      id
      type
      name
      subDescription
      type
      period
      countLesson

      courseLessons {
        id
        name
        duration
        locked
        conditionValue
        slides {
          id
          name
        }
        videos {
          id
          name
          contentLength
          contentType
          typeStorage
          seen
          path
        }
        quizs {
          id
          seen
          score
          totalScore
          scoreDisplay
          name
        }
      }
      images {
        id
        path
        displayOrder
        isFeatured
      }

      createdDate
    }
  }
`;

function MaxcoachProductDetailPage(props) {
  const [state, setState] = useSetState({
    loading: false,
    item: null,
    usercoursepreview: null,
    author: null,
  });

  const _createOrder = () => {
    const items = [
      {
        Id: Common.guid(),
        TargetId: state.item.id,
        quantity: 1,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };
  const _getProduct = () => {
    setState({ loading: true });
    window.showPageLoading();

    getProduct(props.match.params.id)
      .then(async ({ product }) => {
        await Promise.all([
          getUserCoursePreview(product.courseId),
          getUser(product.authors[0]?.id || "00000000-0000-0000-0000-000000000000"),
        ]).then(([usercoursepreview, user]) => {
          setState({
            item: product,
            loading: false,
            author: user?.user,
            usercoursepreview: usercoursepreview?.usercoursepreview,
          });
        });
      })
      .finally(() => window.hidePageLoading());
  };

  useMount(() => {
    window.scrollTo(0, 0);

    _getProduct();
  });
  useEffect(() => {
    $("#sticky-sidebar").theiaStickySidebar({
      additionalMarginTop: 120,
    });
  }, [state.loading]);

  const pageTitle = state.item?.name || "";
  const courseLessons = state.usercoursepreview?.courseLessons;
  const quizs = courseLessons?.quizs || [];
  const slides = courseLessons?.slides || [];
  const videos = courseLessons?.videos || [];

  // noinspection HtmlUnknownAnchorTarget
  return (
    <scs.Container>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h1 className="title">{pageTitle}</h1>
          </div>
        </div>
        <Breadcrumb
          items={[
            { name: "Home", href: "/" },
            { name: "Sản phẩm", href: "/product" },
            { name: pageTitle },
          ]}
        />
      </div>
      <div className="section">
        <div className="container">
          {state.item && (
            <div className="section section-padding-bottom">
              <div className="container">
                <div className="row max-mb-n50">
                  <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
                    <div className="course-details-wrapper">
                      <div className="course-nav-tab">
                        <ul className="nav">
                          <li>
                            <a className="active" data-toggle="tab" href="#overview">
                              Thông tin
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#curriculum">
                              Chương trình
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#fqa">
                              FAQ
                            </a>
                          </li>
                          <li>
                            <a data-toggle="tab" href="#mentor">
                              Giảng viên
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div id="overview" className="tab-pane fade show active">
                          <div className="course-overview">
                            <scs.Description
                              dangerouslySetInnerHTML={{
                                __html: state.item.description,
                              }}
                            />
                          </div>
                        </div>
                        <div id="curriculum" className="tab-pane fade">
                          <div className="course-curriculum">
                            <ul className="curriculum-sections">
                              {(courseLessons || []).map((lesson) => {
                                const quizs = lesson?.quizs || [];
                                const slides = lesson?.slides || [];
                                const videos = lesson?.videos || [];
                                const items = quizs.concat(slides).concat(videos);

                                return (
                                  <li className="single-curriculum-section">
                                    <div className="section-header">
                                      <div className="section-left">
                                        <h5 className="title">{lesson.name}</h5>
                                      </div>
                                    </div>
                                    {items.length === 0 && (
                                      <div className="learn-press-message success ml-15 mr-15">
                                        <i className="fa" />
                                        No items in this section.
                                      </div>
                                    )}
                                    {items.length > 0 && (
                                      <ul className="section-content">
                                        {items.map((v) => (
                                          <li className="course-item">
                                            <Link
                                              to={"/"}
                                              onClick={(e) => e.preventDefault()}
                                              className={"section-item-link lesson"}>
                                              <span className="item-name">{v.name}</span>
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                              {/*<li className="single-curriculum-section">*/}
                              {/*  <div className="section-header">*/}
                              {/*    <div className="section-left">*/}
                              {/*      <h5 className="title">Videos</h5>*/}
                              {/*    </div>*/}
                              {/*  </div>*/}
                              {/*  {videos.length === 0 && (*/}
                              {/*    <div className="learn-press-message success ml-15 mr-15">*/}
                              {/*      <i className="fa" />*/}
                              {/*      No items in this section.*/}
                              {/*    </div>*/}
                              {/*  )}*/}
                              {/*  {videos.length > 0 && (*/}
                              {/*    <ul className="section-content">*/}
                              {/*      {videos.map((v) => (*/}
                              {/*        <li className="course-item">*/}
                              {/*          <Link*/}
                              {/*            to={"/"}*/}
                              {/*            onClick={(e) => e.preventDefault()}*/}
                              {/*            className={"section-item-link lesson"}>*/}
                              {/*            <span className="item-name">{v.name}</span>*/}
                              {/*          </Link>*/}
                              {/*        </li>*/}
                              {/*      ))}*/}
                              {/*    </ul>*/}
                              {/*  )}*/}
                              {/*</li>*/}
                              {/*<li className="single-curriculum-section">*/}
                              {/*  <div className="section-header">*/}
                              {/*    <div className="section-left">*/}
                              {/*      <h5 className="title">Slides</h5>*/}
                              {/*    </div>*/}
                              {/*  </div>*/}
                              {/*  {slides.length === 0 && (*/}
                              {/*    <div className="learn-press-message success ml-15 mr-15">*/}
                              {/*      <i className="fa" />*/}
                              {/*      No items in this section.*/}
                              {/*    </div>*/}
                              {/*  )}*/}
                              {/*  {slides.length > 0 && (*/}
                              {/*    <ul className="section-content">*/}
                              {/*      {slides.map((v) => (*/}
                              {/*        <li className="course-item">*/}
                              {/*          <Link*/}
                              {/*            to={"/"}*/}
                              {/*            onClick={(e) => e.preventDefault()}*/}
                              {/*            className={"section-item-link lesson"}>*/}
                              {/*            <span className="item-name">{v.name}</span>*/}
                              {/*          </Link>*/}
                              {/*        </li>*/}
                              {/*      ))}*/}
                              {/*    </ul>*/}
                              {/*  )}*/}
                              {/*</li>*/}
                            </ul>
                          </div>
                        </div>
                        <div id="fqa" className="tab-pane fade">
                          <FAQ />
                        </div>
                        <div id="mentor" className="tab-pane fade">
                          <div className="course-instructor">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="profile-image">
                                  <img
                                    src={
                                      require("../../../../../Assets/svg/instructor.jpeg").default
                                    }
                                    alt="profile"
                                  />
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="profile-info">
                                  <h5>Maggie Strickland</h5>
                                  <div className="author-career">/Advanced Educator</div>
                                  <p className="author-bio">
                                    Maggie is a brilliant educator, whose life was spent for
                                    computer science and love of nature. Being a female, she
                                    encountered a lot of obstacles and was forbidden to work in this
                                    field by her family. With a true spirit and talented gift, she
                                    was able to succeed and set an example for others.
                                  </p>
                                  <ul className="author-social-networks">
                                    <li className="item">
                                      <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                        <i className="fab fa-twitter social-link-icon" />
                                      </Link>
                                    </li>
                                    <li className="item">
                                      <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                        <i className="fab fa-facebook-f social-link-icon" />{" "}
                                      </Link>
                                    </li>
                                    <li className="item">
                                      <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                        <i className="fab fa-instagram social-link-icon" />{" "}
                                      </Link>
                                    </li>
                                    <li className="item">
                                      <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                        <i className="fab fa-pinterest social-link-icon" />{" "}
                                      </Link>
                                    </li>
                                    <li className="item">
                                      <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                        <i className="fab fa-youtube social-link-icon" />{" "}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12 order-lg-2 max-mb-50" id="sticky-sidebar">
                    <div className="sidebar-widget-wrapper pr-0">
                      <div className="sidebar-widget">
                        <div className="sidebar-widget-content">
                          <div className="sidebar-entry-course-info">
                            <scs.Thumbnail>
                              <img
                                src={Common.getImageThumbnail(
                                  state.item.images,
                                  "?mode=crop&width=1280&height=720"
                                )}
                                alt={"Thumbnail"}
                              />
                            </scs.Thumbnail>
                            <div className="course-price">
                              <span className="meta-label">Price</span>
                              <span className="meta-value">
                                <span className="price">
                                  {Number(state.item.price).toLocaleString("vi-VN")}₫
                                </span>
                              </span>
                            </div>
                            <div className="course-meta">
                              <div className="course-instructor">
                                <span className="meta-label">Quizs</span>
                                <span className="meta-value">{quizs.length}</span>
                              </div>
                              <div className="course-duration">
                                <span className="meta-label">Slides</span>
                                <span className="meta-value">{slides.length}</span>
                              </div>
                              <div className="course-duration">
                                <span className="meta-label">Videos</span>
                                <span className="meta-value">{videos.length}</span>
                              </div>
                              <div className="course-time">
                                <span className="meta-label">
                                  <i className="far fa-calendar" />
                                </span>
                                <span className="meta-value">
                                  {moment(state.item.createdDate).format("YYYY-MM-DD")}
                                </span>
                              </div>
                              <div className="lp-course-buttons">
                                <button
                                  className="btn btn-primary btn-hover-secondary btn-width-100"
                                  onClick={_createOrder}>
                                  Mua ngay
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </scs.Container>
  );
}

export default Observer(MaxcoachProductDetailPage);

function FAQ() {
  return (
    <scs.Description>
      <h3>Học online là gì?</h3>
      <p>
        Học online hay còn gọi là học trực tuyến, đây là phương pháp trao đổi, tiếp cận nội dung,
        kiến thức trên các thiết bị điện tử như điện thoại thông minh, laptop, máy tính bảng,… được
        trang bị kết nối internet. <br />
        So với phương pháp học truyền thống, học online mang tính vượt trội hơn bởi chúng mang lại
        sự tương tác, kết nối đa dạng giữa người học và người dạy. Giáo viên và người học có thể
        tương tác với nhau thông qua các tính năng, ứng dụng được tích hợp sẵn như email, chat, diễn
        đàn trực tuyến, hội thảo,…
      </p>
      <h3>Cách mua khóa học trực tuyến?</h3>
      <p>
        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
        development environment. By the end of this guide, you`ll know how to set up and develop C++
        Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new
        C++ class to it, compile the project, and add an instance of a new class to your level. By
        the time you reach the end of this guide, you`ll be able to see your programmed Actor
        floating above a table in the level.
      </p>
      <h3>Các câu hỏi thường gặp.</h3>
      <p>
        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
        development environment. By the end of this guide, you`ll know how to set up and develop C++
        Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new
        C++ class to it, compile the project, and add an instance of a new class to your level. By
        the time you reach the end of this guide, you`ll be able to see your programmed Actor
        floating above a table in the level.
      </p>
      <h3>Baa là gì?</h3>
      <p>
        The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
        development environment. By the end of this guide, you`ll know how to set up and develop C++
        Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new
        C++ class to it, compile the project, and add an instance of a new class to your level. By
        the time you reach the end of this guide, you`ll be able to see your programmed Actor
        floating above a table in the level.
      </p>
    </scs.Description>
  );
}
