import { styled } from "../../../../../../Utils/stitches.config";
import React from "react";
import { hintTextColor, primaryColor, primaryTextColor } from "../../../../../../Utils/themeConfig";

export const StyledRightSection = styled("div", {
  ".col": {
    paddingLeft: 0,
  },
});
export const StyledBlogItem = styled("div", {});
export const StyledBlogItemBody = styled("div", {
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
  borderRadius: 8,
  overflow: "hidden",
  height: "100%",
  position: "relative",
  '[data-section="image"]': {
    height: 184,
    overflow: "hidden",
  },
  "&:hover": {
    img: {
      transform: "scale(1.1)",
    },
  },
  img: {
    display: "block",
    objectFit: "cover",
    height: 184,
    width: "100%",
    transition: "all 1.5s cubic-bezier(0,0,.2,1)",
  },
  '[data-section="info"]': {
    marginLeft: 12,
    marginRight: 12,
    paddingBottom: 48,
  },
  '[data-section="category"]': {
    color: hintTextColor,
    fontSize: 12,
    lineHeight: "15px",
    marginTop: 16,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  '[data-section="name"]': {
    fontSize: 16,
    lineHeight: "24px",
    marginTop: 12,
    color: primaryTextColor,
    fontWeight: 600,
    cursor: "pointer",
    display: "block",
    "&:hover": {
      color: primaryColor,
    },
  },
  '[data-section="date"]': {
    color: hintTextColor,
    fontSize: 14,
    lineHeight: "17px",
    marginTop: 12,
    position: "absolute",
    bottom: 20,
  },
});
