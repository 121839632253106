/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";

const languageId = localStorage.getItem(Config.languageId);
const merchantId = localStorage.getItem(Config.merchantId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);

export const getProductDiscount = (id) => {
    var query = `
      {
            product(param:
            {
                id:"${id}" })
            {
            id,            
                discounts {
                    resultValue,
                    resultType,
                    from,
                    to,
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday
                }
                }
        }
    `;
    return Common.getDataGraphQl(query);
};

export const getProducts = (keyword, sort, order, limit, offset, type, categoryId, paramMerchantId) => {
    console.log(paramMerchantId);
    var temp = "";
    if (categoryId != undefined) {
        var categoryIds = [];
        categoryIds.push(categoryId);

        if (categoryId != "") {
            temp += ",categoryIds:" + JSON.stringify(categoryIds);
        }
    }
    var query = `
        {
            products(param:
            {   
                type: ${type}
                ,keyword:"${keyword}" 
                ,limit:${limit}
                ,offset:${offset}                 
                ${temp}
                ,published: true
                ,order:"${order}"
                ,sort:"${sort}"
                ,languageId:"${languageId}"
                 ,merchantId: "${paramMerchantId != undefined ? paramMerchantId : merchantId}"})
            {
    totalCount,
        items
    {

        id,
        name,
        subDescription,
        images {
        id,
            path,
            displayOrder,
            isFeatured,
            },
    categories {
        id,
            name
    }
    createdDate,
        }
}
        }
`;

    return Common.getDataGraphQl(query);
};
export const getProduct = (id, targetMerchantId) => {
    var query = `
      {
            product(param:
            {
                id:"${id}"                
                languageId:"${languageId}",                
                merchantId:"${targetMerchantId != undefined ? targetMerchantId : merchantId}"})
            {
            id,            
                name,
                subDescription,
                description,
                price,
                sku,
                courseId,
                ratingId,
                images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
                authors{
                    id,
                    name
                }
                categories {
                    id,
                    name
                  }
                createdDate,
                }
        }
    `;
    return Common.getDataGraphQl(query);
};
