import { gql } from "urql";

export const QueryBoardExperiences = gql`
  query WorkExperiences(
    $languageId: String!
    $merchantId: String!
    $tokenUser: String
    $type: Int!
    $targetId: String!
  ) {
    data: workexperiences(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        type: $type
        targetId: $targetId
      }
    ) {
      items {
        name
        id
        position
        committeeRoles
        userReferees
        fromDateTime
        toDateTime

        companies {
          id
          name
          __typename
        }
      }
      message
      success
      totalCount
    }
  }
`;
