export const getExpertises = () => {
  const data = [
    {value:"default", name: "Accounting/Finance" },
    {value:"default", name: "Quantity Survey" },
    {value:"default", name: "Legal" },
    {value:"default", name: "Engineering" },
    {value:"default", name: "Information Technology" },
    {value:"default", name: "Marketing" },
    {value:"default", name: "Secretarial" },
    {value:"default", name: "Medical" },
    {value:"default", name: "Human Resource" },
    {value:"default", name: "Other" },
  ];
  let num = 1;
  for (let i = 0; i < data.length; ++i) {
    if (i === 0) {
      num = 1;
    } else {
      num *= 2;
    }
    data[i] = { ...data[i], value: num };
  }
  return data;
};
