/* eslint-disable */
import React, { Component } from "react";

export default class Footer extends Component {
    render() {
        return (
            <div className="container">
                {/* footer
               ================================================== */}
                <div className="footer">
                    <div className="uk-grid-collapse" uk-grid="true">
                        <div className="uk-width-expand@s uk-first-column">
                            <p>
                                © 2020 <strong>IzziLearn</strong>. Đã đăng kí bản quyền.{" "}
                            </p>
                        </div>
                        <div className="uk-width-auto@s">
                            <nav className="footer-nav-icon">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="icon-brand-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icon-brand-dribbble" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icon-brand-youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="icon-brand-twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
