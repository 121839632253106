/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";

const languageId = localStorage.getItem(Config.languageId);
const merchantId = localStorage.getItem(Config.merchantId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);

export const setScore = (score) => {
    return score;
};
export const getQuizTransactions = (keyword, limit, offset, order, sort, status) => {
    var query = `
    {
        quizTransactions(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"        
        ,merchantId:"${merchantId}"
        ,keyword:"${keyword}"
        ,tokenUser:"${tokenId}"
        ,${status ? `status:${status}` : ""}
        
       })
        {
              items {
                id,
                quizName,
                quizId,
                userName,
                score,
                point,
                startTime,
                endTime,
                status
              }
              ,totalCount
        }
      }
    `;
    return Common.getDataGraphQl(query);
};

export const getQuizTransaction = (id) => {
  var query = `
  {
    quizTransaction(param:{id: "${id}", tokenUser:"${tokenId}", merchantId: "${merchantId}"})
    {
        id,
        quizName
        point
        score
        quizTransactionQuestions {
            id,
            name,
            point,
            status,
            score,
            type,
            files{
              path
              id
            }         
            quizTransactionAnswers {
                id,
                name,                
                isCorrect
            },
        },
        status,
        userName,
        userEmail
        userMobile
        startTime
        endTime

    }
}
  `;
  return Common.getDataGraphQl(query);
};

export const getQuizResultDetail = (quizId) => {
    var query = `
    {
        quiztransactionquestions(param:{id:"${quizId}"})
        {
              items {
                id,
                quizTransactionAnswers {
                    id,
                    name,
                },
                name,
                point,
                status,
              }
        }
      }
    `;
    return Common.getDataGraphQl(query);
};
