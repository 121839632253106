/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { SpinIcon } from "../../../PageLoading";
import { Link } from "react-router-dom";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import { useMount, useSetState } from "react-use";
import { connect } from "react-redux";
import Common from "../../../../../../Utils/Common";
import Swal from "sweetalert2";
import Select from "react-select";
import PropTypes from "prop-types";

function SubcribeAuthor(props) {
  const [state, setState] = useSetState({
    users: [],
    pickedUsers: [],
    loading: false,
    following: false,
  });

  const _load = () => {
    setState({ loading: true });
    QueryGraphQL.getAuthors(0, 99)
      .then((data) => {
        if (data.users.items.length) {
          setState({
            users: data.users.items,
          });

          return QueryGraphQL.subcribeUsers();
        }
      })
      .then((data) => {
        if (data?.subcribeusers.items.length) {
          setState({
            pickedUsers: data.subcribeusers.items.map((e) => ({ value: e.id, label: e.name })),
          });
        }
      })
      .finally(() => setState({ loading: false }));
  };

  const _submitFormHandler = (ids) => {
    setState({ following: true });
    let key = ids.join();
    message.loading({ content: "Đang cập nhật...", key });
    Common.sendSyncCommand(
      "SubcribeUsersArticleByUser",
      "User",
      {
        Id: props.infoUser.id,
        UserIds: ids,
      },
      () => {
        setState({ following: false });
        message.success({ content: "Thông tin tác giả đã được cập nhật.", key });
        props.onUpdateSubcrible && props.onUpdateSubcrible();
      },
      () => {
        setState({ following: false });
        let content = "Thông tin tác giả cập nhật thất bại!";
        Swal.fire(content);
        message.error({ content, key });
      }
    );
  };

  useMount(() => {
    _load();
  });

  let { users } = state;

  return (
    <React.Fragment>
      <p>
        Authors List
        <Link
          to={"/"}
          onClick={(e) => e.preventDefault()}
          className="text-primary"
          style={{
            display: "inline-block",
            marginLeft: 4,
          }}>
          View
        </Link>
      </p>
      <form className="mt-3">
        <Spin indicator={SpinIcon} spinning={state.loading}>
          <Select
            isMulti
            name="authors"
            options={users.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            value={state.pickedUsers}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(users) => {
              return setState({
                pickedUsers: users,
              });
            }}
          />
        </Spin>
      </form>
      <div>
        <button
          className="btn btn-primary"
          style={{ width: "100%", marginTop: 30 }}
          onClick={() => {
            if (state.following || state.loading) return;
            _submitFormHandler(state.pickedUsers?.map((v) => v.value) || []);
          }}>
          {state.following ? (
            <Spin indicator={SpinIcon} style={{ color: "#ffffff" }} />
          ) : (
            <span>Follow</span>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}

SubcribeAuthor.propTypes = {
  onUpdateSubcrible: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    infoUser: state.User.user,
  };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SubcribeAuthor);
