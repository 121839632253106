import { gql } from "urql";

export const QUERY_CATEGORIES = gql`
  query PrivilegeCategories($type: Int!, $languageId: String!, $merchantId: String!,$tokenUser:String) {
    privilegecategories(
      param: {
        tokenUser:$tokenUser
        type: $type
        published: true
        limit: 1000
        offset: 0
        order: "desc"
        sort: "createdDate"
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        type
        name
        subDescription
        images {
          id
          path
          displayOrder
          isFeatured
        }

        createdDate
      }
    }
  }
`;
export const QUERY_ARTICLE = gql`
  query QueryArticles(
    $type: Int!
    $limit: Int = 10
    $offset: Int = 0
    $categoryIds: [String]
    $languageId: String!
    $merchantId: String!
    $sort: String = "createdDate"
    $order: String = "desc"
  ) {
    articles(
      param: {
        type: $type
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        categoryIds: $categoryIds
        published: true
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        name
        subDescription
        files {
          id
          name
          contentLength
          contentType
          path
          displayOrder
          isFeatured
        }
        images {
          id
          path
          displayOrder
          isFeatured
        }
        authors {
          name
        }
        categories {
          id
          name
        }
        createdDate
      }
    }
  }
`;
