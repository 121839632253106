import { gql } from "urql";

export const QUERY_PRODUCT = gql`
  query Product($id: String!, $languageId: String!, $merchantId: String!, $tokenId: String) {
    product(
      param: { id: $id, languageId: $languageId, merchantId: $merchantId, tokenUser: $tokenId }
    ) {
      id
      name
      subDescription
      description
      price
      sku
      courseId
      ratingId
      images {
        id
        path
        displayOrder
        isFeatured
      }
      authors {
        id
        name
      }
      categories {
        id
        name
      }
      createdDate
      quantityStudent
      quantityRating
      averageRating
      hadBought
      exchangePoint
      discounts {
        friday
        monday
        from
        monday
        resultType
        resultValue
        saturday
        sunday
        thursday
        to
        tuesday
        wednesday
        membershipIds
        membershipNames
      }
      attributeVariants {
        id
        name
        attributeValues {
          value
          id
        }
      }
      productVariants {
        id
        price
        images {
          isFeatured
          displayOrder
          path
          id
         
        }
        attributeValues {
          id
          name
          value
        }
      }
      attributeValues {
        id
        name
        value
      }
    }
  }
`;
export const QUERY_PREVIEW = gql`
  query Preview($id: String!, $languageId: String!, $merchantId: String!) {
    usercoursepreview(param: { id: $id, languageId: $languageId, merchantId: $merchantId }) {
      id
      type
      name
      subDescription
      type
      period
      countLesson
      courseLessons {
        id
        name
        duration
        locked
        conditionValue
        slides {
          id
          name
        }
        videos {
          id
          name
          contentLength
          contentType
          typeStorage
          seen
          path
        }
        quizs {
          id
          seen
          score
          totalScore
          scoreDisplay
          name
        }
      }
      images {
        id
        path
        displayOrder
        isFeatured
      }
      createdDate
    }
  }
`;
export const QUERY_RATING = gql`
  query QueryRate($id: String!, $targetId: String!, $merchantId: String!) {
    ratingmember(param: { id: $id, targetId: $targetId, merchantId: $merchantId }) {
      id
      maxScore
      ratingTransactions {
        id
        comment
        score
        createdDate
        targetId
        userName
        userAllType
        userImagePath
      }
    }
  }
`;
