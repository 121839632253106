/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Configuration from "../../../../Config/Config";
import "lightgallery.js/dist/css/lightgallery.css";
import { updateBreadcrumb, setHeaderTranparent } from "../../../Reducers/Book";
import $ from "jquery";
import { Link } from "react-router-dom";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

const PhotoItem = ({ image, group, index }) => (
    <div style={{ padding: "5px" }}>
        <LightgalleryItem group={group} src={image}>
            <img id={"itemGallery" + index} src={image} />
        </LightgalleryItem>
    </div>
);
class CourseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                name: "",
                subDescription: "",
                description: "",
                createdDate: new Date(),
                files: [],
                images: [],
                authors: [],
            },
            popularArticle: [],
        };
    }
    componentDidMount() {
        var self = this;
        QueryGraphQL.getArticleById(this.props.match.params.id).then((data) => {
            this.setState({
                article: data.article,
            });
            console.log(this.state.article);
            self.props.setBreadcrumbAction(data.article.name);
        });

        QueryGraphQL.pagingFreeCourseArticles(7, 0, 4).then((data) => {
            if (data.freecoursearticles.items.length) {
                this.setState({
                    popularArticle: data.freecoursearticles.items.slice(0, 10),
                });
            }
        });
    }
    returnValue(obj, property) {
        if (obj === undefined) {
            return "";
        }
        return obj[property];
    }
    openGallery = () => {
        $("#itemGallery0").click();
    };
    render() {
        this.props.setHeaderTranparentAction(true);
        return (
            <Fragment>
                <div className="course-resume-wrapper topic-1">
                    {/* topbar */}
                    <div className="container">
                        <div className="uk-grid-large uk-light mt-lg-3" uk-grid="true">
                            <div className="uk-width-1-2@m">
                                <div className="course-thumbnail m-lg-4 p-lg-3" onClick={() => this.openGallery()}>
                                    <img src={Common.getImageThumbnail(this.state.article.images, "?mode=crop&width=1280&height=720")} alt="" />
                                    <a className="play-button-trigger show" href="#trailer-modal" uk-toggle="true">
                                        {" "}
                                    </a>
                                </div>
                                {/* video demo model */}
                                {/* <div id="trailer-modal" uk-modal>
                                    <div className="uk-modal-dialog">
                                        <button className="uk-modal-close-default mt-2  mr-1" type="button" uk-close />
                                        <div className="uk-modal-header">
                                            <h4> Trailer video </h4>
                                        </div>
                                        <div className="video-responsive">
                                            <iframe src="https://www.youtube.com/embed/nOCXXHGMezU" className="uk-padding-remove" uk-video="automute: true" frameBorder={0} allowFullScreen uk-responsive />
                                        </div>
                                        <div className="uk-modal-body">
                                            <h3>Build Responsive Websites </h3>
                                            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                                              eu
                                              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                                              in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="uk-width-1-2@m course-details">
                                <h1> {this.state.article.name}</h1>
                                <p> {this.state.article.subDescription}</p>
                                <div className="course-details-info mt-5">
                                    <ul>
                                        <li>
                                            <div className="star-rating">
                                                <span className="avg"> 4.9 </span> <span className="star" />
                                                <span className="star" />
                                                <span className="star" />
                                                <span className="star" />
                                                <span className="star" />
                                            </div>
                                        </li>
                                        {/* <li> <i className="icon-feather-users" /> 1200 Enerolled </li> */}
                                    </ul>
                                </div>
                                <div className="course-details-info">
                                    <ul>
                                        {this.state.article.authors.map((item, i) => (
                                            <li>
                                                {" "}
                                                Tạo bởi <a href="javascript:;"> {item.name} </a>{" "}
                                            </li>
                                        ))}
                                        <li> Tạo ngày {Common.formatDateTime(this.state.article.createdDate, "dd-mm-yyyy")}</li>
                                        <li> Việt Name </li>
                                    </ul>
                                </div>
                                <div className="uk-flex uk-flex-between uk-flex-middle">
                                    <div className=" uk-visible@m">
                                        <a hred="#" uk-tooltip="title: Add to your Bookmarks ; pos: top">
                                            <i className="icon-feather-bookmark icon-small" />{" "}
                                        </a>
                                        <a hred="#" uk-tooltip="title: Add to wishlist ; pos: top">
                                            <i className="icon-feather-heart icon-small ml-3 text-danger" />{" "}
                                        </a>
                                    </div>
                                    <a href="javascript:;" className="btn-course-start-2 my-lg-4 mt-3" onClick={() => this.openGallery()}>
                                        {" "}
                                        Start Learning
                                        <i className="icon-feather-chevron-right" />{" "}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="subnav">
                            <ul
                                className="uk-child-width-expand mb-0"
                                uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
                                uk-tab="true">
                                <li>
                                    <a href="#">
                                        {" "}
                                        <i className="uil-file-alt" />
                                        <span> Thông tin</span>{" "}
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        {" "}
                                        <i className="uil-film" />
                                        <span> Slide</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        {" "}
                                        <i className="uil-comment-lines" />
                                        <span> Ảnh</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="uk-grid-large mt-4" uk-grid="true">
                        <div className="uk-width-2-3@m">
                            <ul id="course-intro-tab" className="uk-switcher">
                                {/* course description */}
                                <li className="course-description-content">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.article.description,
                                        }}
                                    />
                                </li>
                                {/* course Reviews*/}
                                <li>
                                    <div className="review-summary">
                                        <LightgalleryProvider
                                            onAfterSlide={() => {
                                                $(".side-nav").hide();
                                                // console.log("onAfterSlide");
                                            }}
                                            onCloseAfter={() => {
                                                $(".side-nav").show();
                                            }}>
                                            <div style={{ alignItems: "center" }}>
                                                {this.state.article.images.map((p, idx) => (
                                                    <PhotoItem index={idx} key={idx} image={Configuration.imageUrl + p.path} />
                                                ))}
                                            </div>
                                        </LightgalleryProvider>
                                    </div>
                                </li>
                                <li>
                                    <div style={{ alignItems: "center" }}>
                                        {this.state.article.images.map((p, idx) => (
                                            <Link
                                                //to={`/bookRead/${item.path}}`} params={{ path: item.path }}
                                                to={{
                                                    pathname: "/drawing",
                                                    state: {
                                                        path: Configuration.imageUrl + p.path,
                                                    },
                                                }}>
                                                <img index={idx} key={idx} className="mb-3" src={Configuration.imageUrl + p.path} />
                                            </Link>
                                        ))}
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-width-1-3@m">
                            <h4 className="mt-lg-4"> Các slide miễn phí</h4>
                            {this.state.popularArticle.map((item, i) => (
                                <div>
                                    <a href={`/slide/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                        <div className="course-card">
                                            <div className="course-card-thumbnail ">
                                                <img src={Common.getImageThumbnail(item.images, "?mode=crop&width=800&height=418")} />
                                                <span className="play-button-trigger" />
                                            </div>
                                            <div className="course-card-body">
                                                <div className="course-card-info">
                                                    <div>
                                                        <span className="catagroy">Slide</span>
                                                    </div>
                                                    <div>
                                                        <i className="icon-feather-bookmark icon-small" />
                                                    </div>
                                                </div>
                                                <h4>{item.name} </h4>
                                                <p> {item.subDescription} </p>
                                                <div className="course-card-footer">
                                                    <h5>
                                                        {" "}
                                                        <i className="icon-feather-film" /> {item.images.length} ảnh
                                                    </h5>
                                                    {/* <h5> <i className="icon-feather-clock" /> 55 Hours </h5> */}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    updateBreadcrumb,
    setHeaderTranparent,
    setHeaderTranparentAction,
    setBreadcrumbAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
