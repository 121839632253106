import { styled } from "../../../../Utils/stitches.config";

export const StyledSurveyContainer = styled("div", {
  variants: {
    submitted: {
      yes: {
        iframe: {
          opacity: 0.2,
        },
        background: "#ddd",
        pointerEvents: "none",
      },
      no: {},
    },
  },
});
