import { gql } from "urql";

export const queryCertificates = gql`
  query Certificates($merchantId: String!, $languageId: String!, $tokenUser: String!) {
    articleusercertificates(
      param: { merchantId: $merchantId, languageId: $languageId, tokenUser: $tokenUser }
    ) {
      totalCount
      items {
        files{
          name
          path
          id
        }
        id
        name
        description
        subDescription
        message
        createdDate
        courses {
          id
          name
        }
      }
    }
  }
`;
export const queryPurchase = gql`
  query Purchase(
    $limit: Int = 10
    $offset: Int = 0
    $tokenUser: String
    $languageId: String
    $merchantId: String
  ) {
    userorders(
      param: {
        limit: $limit
        offset: $offset
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      items {
        allStatus
        grandTotal
        id
        code
        createdDate
        grandTotal
        subTotal
        paymentAmount
        giftCodeAmount
        promotionAmount
        paymentMethodName
        note
        payments {
          allStatus
          amount
          code
          id
          note
          paymentMethodId
          paymentMethodName
          referenceCode
          targetId
        }
        delivery {
          addressDetail
          trackingCode
          amount
        }
        orderLines {
          id
          targetName
          originalPrice
          price
          quantity
          total
        }
        user {
          id
          email
          lastName
          mobile
        }
      }
      totalCount
    }
  }
`;

export const queryContestEntries = gql`
query ContestEntries(
  $limit: Int = 1000
  $offset: Int = 0
  $order: String = "desc"
  $sort: String = "entryDate"
  $languageId: String = ""
  $tokenUser: String = ""
  $merchantId: String = ""
  $targetId: String
) {
  membercontestentries(
    param: {
      limit: $limit
      offset: $offset
      order: $order
      sort: $sort
      languageId: $languageId
      tokenUser: $tokenUser
      merchantId: $merchantId
      targetId: $targetId
    }
  ) {
    message
    items {
      id
      allMarks
      code
      contest {
        id
        status
        name
      }
      entryDate
      isPassed
      file {
        id
        name
        path
        type
        typeStorage
      }
      fileId
      finalMark
     
      status
      transaction {
        id
        allStatus
      }
      transcripts {
        criteriaCategoryName
        criteriaName
        remark
        mark
        maxMark
        userName
      }   
    }
    totalCount
    success
  }
}

`;
