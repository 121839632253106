import React, { Fragment, useEffect, useRef, useState } from "react";
import { BasePage } from "../../ComponentCommon/BasePage";
import { Row, Col, Card, Typography, Space, Tag, Avatar } from "antd";
import { getCommonStorage } from "../../../../Config/gql";
import { useQuery } from "urql";
import { QueryBoardExperiences, QueryCertificates } from "./gql";

import { Link } from "react-router-dom";
import Configuration from "../../../../Config/Config";
import moment from "moment";
import { getPosition, getFunctionals, getGlobalExperiences } from "../Work/data";
import { cmrType, cmrRole } from "../Board/data";
import { connect } from "react-redux";
import { QueryWorkExperiences } from "../Work/gql";
import ReactToPrint from "react-to-print";
import { ProfileInfo } from "./components";
import PopupCertificate from "../../ComponentCommon/PopupCertificate";
import { useTranslation } from "react-i18next";
import { QueryAwards } from "../User Account/Profile/maxcoach/Award/gql";
import { QueryEducation } from "../Education/gql";
import { QueryMemberShip } from "../User Account/Profile/maxcoach/ProfessionalMembership/gql";
import { QueryQualification } from "../User Account/Profile/maxcoach/ProfessionalQualification/gql";
import { getExpertises } from "../User Account/Profile/maxcoach/ProfessionalQualification/data";
import { getOrganization } from "../User Account/Profile/maxcoach/ProfessionalMembership/data";
import { useAttributeInfo } from "../../../../Utils/attribute";

interface ICertificate {
  name: string;
  createdDate: moment.Moment;
  subDescription: string;
}

const Preview = (props: any) => {
  const { t } = useTranslation();
  const { Text } = Typography;
  const printComponentRef = useRef(null);
  const [certificate, setCertificate] = useState<any>(null);
  const attributeValues = useAttributeInfo({id: props.user.id});
  const [BoardExperiencesDatas, refetchBoardExperiences] = useQuery({
    query: QueryBoardExperiences,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 1,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [AwardDatas] = useQuery({
    query: QueryAwards,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [EducationDatas] = useQuery({
    query: QueryEducation,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [WorkExperiencesDatas] = useQuery({
    query: QueryWorkExperiences,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 0,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [CertificateDatas] = useQuery({
    query: QueryCertificates,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [MembershipDatas] = useQuery({
    query: QueryMemberShip,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,

        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [QualificationDatas] = useQuery({
    query: QueryQualification,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const getCmrType = (value: number) => {
    let arr: any = [];
    cmrType.forEach((i, index) => {
      if ((i.value & value) === i.value) {
        arr.push(i);
      }
    });
    return arr;
  };
  const getCmrRole = (value: number) => {
    let arr: any = [];
    cmrRole.forEach((i, index) => {
      if ((i.value & value) === i.value) {
        arr.push(i);
      }
    });
    return arr;
  };
  const exists: any = {};
  const boardExperiences = BoardExperiencesDatas.data?.data?.items || [];
  const workExperiences = WorkExperiencesDatas.data?.data?.items || [];
  const educations = EducationDatas.data?.data?.items || [];
  const awards = AwardDatas.data?.data?.items || [];
  const memberships = MembershipDatas.data?.data?.items || [];
  const qualifications = QualificationDatas.data?.data?.items || [];
  const certificates = (CertificateDatas.data?.articleusercertificates?.items || []).filter(
    (v: any) => {
      let hasExists = false;

      (v.courses || []).forEach((course: any) => {
        if (exists[course.id]) {
          hasExists = true;
        }

        exists[course.id] = true;
      });

      return !hasExists;
    }
  );
  const getExpertise = (num: number) => {
    return getExpertises().find((value: any) => value.value === num);
  };
  const getMemberShip = (num: number) => {
    return getOrganization().find((value: any) => value.value === num);
  };
  return (
    <Fragment>
      <BasePage title="CV">
        <div>
          <ReactToPrint
            trigger={React.useCallback(() => {
              return (
                <button className="bg-gray-400 hover:bg-gray-600 border-0 py-1 px-3 font-bold mb-4 text-white">
                  {t("Export to PDF")}
                </button>
              );
            }, [])}
            content={React.useCallback(() => {
              return printComponentRef.current;
            }, [])}
          />
        </div>
        <Row ref={printComponentRef} gutter={8}>
          <Col span={8}>
            <ProfileInfo>
              <div className="flex flex-col items-center justify-center mb-3">
                <div className=" mb-3 font-bold">{props.user.firstName}</div>
                <Avatar
                  className="mb-3"
                  size={150}
                  style={{ border: "1px solid # ccc" }}
                  src={Configuration.imageUrl + props?.user?.avatar}></Avatar>
                <div className="rounded-b-3xl rounded-t-3xl border border-solid border-gray-100 py-0.5 px-4 bg-green-100">
                  {props.user.mobile}
                </div>
              </div>
              <div>
                <span className="font-bold">{t("Date of Birth")} : </span>{" "}
                {props.user.dateOfBirth
                  ? moment(props.user.dateOfBirth).format("YYYY-MM-DD")
                  : t("No Date Of Birth")}
              </div>
            </ProfileInfo>
            <PopupCertificate
              show={!!certificate}
              certificate={certificate}
              onCancel={() => setCertificate(null)}
            />
            <Card title={<span className="font-bold ">{t("Certificates")}</span>}>
              {certificates.map((certificate: ICertificate) => (
                <Space key={certificates.id} className="block mb-4 " direction="vertical">
                  <Text type="danger">
                    <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setCertificate(certificate);
                      }}
                      style={{ whiteSpace: "nowrap" }}>
                      {certificate.name}
                    </Link>
                  </Text>
                  <Text strong>{moment(certificate.createdDate).format("YYYY")}</Text>
                  <Text>{certificate.subDescription}</Text>
                </Space>
              ))}
            </Card>
          </Col>
          <Col span={16}>
            {/* <AttributeInfo id={props.user.id}/> */}
            {attributeValues.length > 0 &&
              attributeValues.map((value: any) => (
                <Card
                  style={{ borderTop: "1px solid #cccccc" }}
                  bordered={false}
                  bodyStyle={{ borderTop: "1px solid #cccccc" }}
                  title={<span className="font-bold">{value.name}</span>}>
                  <div dangerouslySetInnerHTML={{ __html: value.value }}></div>
                </Card>
              ))}
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Education")}</span>}>
              {educations.length > 0 &&
                educations.map((education: any) => (
                  <Space className="block mb-8" key={education.id} direction="vertical">
                    <Text strong>{education.yearCompleted.slice(0, 4)}</Text>
                    <Text type="danger">{education.fieldOfStudy}</Text>
                    <Text>{education.degree}</Text>
                    <Text>{education.major}</Text>
                  </Space>
                ))}
            </Card>
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Award")}</span>}>
              {awards.length > 0 &&
                awards.map((award: any) => (
                  <Space className="block mb-8" key={award.id} direction="vertical">
                    <Text type="danger">{award.title}</Text>
                    <Text strong>{award.year.slice(0.4)}</Text>
                    <Text>{award.issuer}</Text>
                  </Space>
                ))}
            </Card>
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Board Experience")}</span>}>
              {boardExperiences.length > 0 &&
                boardExperiences.map((exp: any) => (
                  <Space className="block mb-8" key={exp.id} direction="vertical">
                    <Text strong>
                      {moment(exp.fromDateTime).format("YYYY-MM-DD")} -{" "}
                      {exp.toDateTime ? moment(exp.toDateTime).format("YYYY-MM-DD") : t("Current")}
                    </Text>
                    <Text type="danger">{exp?.companies[0]?.name}</Text>
                    <Text strong>{exp.name}</Text>
                    {getPosition(exp.position).map((value: any, id: number) => (
                      <Text key={id}>{value.name}</Text>
                    ))}

                    <Text>
                      <Row>
                        <Col span={5}>{t("Committee Role")}:</Col>
                        <Col span={19}>
                          {JSON.parse(exp.committeeRoles)?.map((cmt: any, i: number) => (
                            <Tag key={i}>
                              {getCmrType(cmt.Type).map((value: any, i: string) => (
                                <span key={i}>{value.name}</span>
                              ))}
                              : {""}
                              {getCmrRole(cmt.Role).map((value: any, i: string) => (
                                <span key={i}>{value.name}</span>
                              ))}
                            </Tag>
                          ))}
                        </Col>
                      </Row>
                    </Text>
                  </Space>
                ))}
            </Card>
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Work Experience")}</span>}>
              {workExperiences.length > 0 &&
                workExperiences.map((exp: any) => (
                  <Space className="block mb-8" key={exp.id} direction="vertical">
                    <Text strong>
                      {moment(exp.fromDateTime).format("YYYY-MM-DD")} -{" "}
                      {exp.toDateTime ? moment(exp.toDateTime).format("YYYY-MM-DD") : "Hiện Tại"}
                    </Text>
                    <Text type="danger">{exp.companies[0]?.name}</Text>
                    <Text strong>{exp.name}</Text>
                    {getPosition(exp.position).map((value: any, index: number) => (
                      <Text key={index}>{value.name}</Text>
                    ))}

                    <Text>
                      <Row>
                        <Col span={5}>{t("Functional")}</Col>
                        <Col span={19}>
                          {getFunctionals(exp.functionals).map((value: any, index: number) => (
                            <Tag key={index}>{value.name}</Tag>
                          ))}
                        </Col>
                      </Row>
                    </Text>
                    <Text>
                      <Row>
                        <Col span={5}>{t("Global Experience")}</Col>
                        <Col span={19}>
                          {getGlobalExperiences(exp.globalExperiences).map(
                            (value: any, i: number) => (
                              <Tag key={i}>{value.name}</Tag>
                            )
                          )}
                        </Col>
                      </Row>
                    </Text>
                    <Text>{exp.rolesResponsibility}</Text>
                  </Space>
                ))}
            </Card>
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Professional Qualification")}</span>}>
              {qualifications.length > 0 &&
                qualifications.map((qualification: any) => (
                  <Space className="block mb-8" key={qualification.id} direction="vertical">
                    <Text strong>{qualification.yearCompleted.slice(0, 4)}</Text>
                    <Text type="danger">{qualification.name}</Text>
                    <Text>{qualification.institute}</Text>
                    <Text>
                      <Row>
                        <Col span={5}>{t("Expertise")}</Col>
                        <Col span={19}>
                          <Tag>{getExpertise(qualification.expertise)?.name}</Tag>
                        </Col>
                      </Row>
                    </Text>
                  </Space>
                ))}
            </Card>
            <Card
              style={{ borderTop: "1px solid #cccccc" }}
              bordered={false}
              bodyStyle={{ borderTop: "1px solid #cccccc" }}
              title={<span className="font-bold ">{t("Professional Membership")}</span>}>
              {memberships.length > 0 &&
                memberships.map((memberShip: any) => (
                  <Space className="block mb-8" key={memberShip.id} direction="vertical">
                    <Text strong>
                      {moment(memberShip.from).format("YYYY-MM-DD")} -{" "}
                      {memberShip.to ? moment(memberShip.to).format("YYYY-MM-DD") : "Hiện Tại"}
                    </Text>
                    <Text type="danger">{memberShip.type}</Text>
                    <Text>
                      <Row>
                        <Col span={5}>{t("Membership No")}</Col>
                        <Col span={19}>
                          <Tag>{memberShip.membershipNo}</Tag>
                        </Col>
                      </Row>
                    </Text>
                    <Text>
                      <Row>
                        <Col span={5}>{t("institute.membership")}</Col>
                        <Col span={19}>
                          <Tag>{getMemberShip(memberShip.institute)?.name}</Tag>
                        </Col>
                      </Row>
                    </Text>
                  </Space>
                ))}
            </Card>
          </Col>
        </Row>
      </BasePage>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.User.user,
  };
};

export default connect(mapStateToProps)(Preview);
