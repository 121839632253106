import { useQuery, gql } from "urql";

export const QueryAddress = gql`
  query Address($languageId: String!, $merchantId: String!, $tokenUser: String!, $type: Int) {
    data: useraddresses(
      param: {
        tokenUser: $tokenUser
        merchantId: $merchantId
        languageId: $languageId
        type: $type
      }
    ) {
      totalCount
      items {
        id

        name

        detailAddress

        latitude
        longitude

        mobile
      }
    }
  }
`;
