/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import { Link } from "react-router-dom";
import $ from "jquery";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
class Index extends Component {
    state = {
        offset: 0,
        totalItem: null,
        itemPerPage: 12,
        items: [],
    };
    componentDidMount() {
        QueryGraphQL.getCourse(this.state.offset, this.state.itemPerPage).then((data) => {
            if (data.courses.items?.length) {
                this.setState({
                    items: data.courses.items,
                    totalItem: data.courses.totalCount,
                });
            }
        });
    }
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.getCourse(this.state.offset, this.state.itemPerPage).then((data) => {
                    if (data.courses.items.length) {
                        this.setState({
                            items: data.courses.items,
                            totalItem: data.courses.totalCount,
                        });
                    } else {
                        this.setState({
                            arrayItemPerPage: [],
                            totalItem: 0,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };
    render() {
        let { totalItem } = this.state;

        var totalPage = totalItem / this.state.itemPerPage;

        return (
            <div className="container">
                <h4> Learning materials</h4>
                <div className="section-small">
                    <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                        {this.state.items.map((item, i) => (
                            <div key={i}>
                                <Link to={`/course/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                    <div className="course-card">
                                        <div className="course-card-thumbnail ">
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                            {/* <span className="play-button-trigger" /> */}
                                        </div>
                                        <div className="course-card-body">
                                            <div className="course-card-info">
                                                {/* <div>
                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                </div> */}
                                                <div>
                                                    <i className="icon-feather-bookmark icon-small" />
                                                </div>
                                            </div>
                                            <h4>{item.name}</h4>
                                            <p>{item.subDescription}</p>
                                            {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                <ul className="uk-pagination uk-flex-center uk-margin-medium">
                    {(() => {
                        let tempArray = [];
                        for (let index = 0; index < totalPage; index++) {
                            tempArray.push(
                                <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                    <span>{index + 1}</span>
                                </li>
                            );
                        }
                        return <Fragment>{tempArray}</Fragment>;
                    })()}
                </ul>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
