export const boardRoles = [
    {value:1,name:"Chairman (Executive)"},
    {value:2,name:"Chairman (Non-Executive)"},
    {value:4,name:"Vice Chair"},
    {value:8,name:"Independent Non-Executive Director (INED)"},
    {value:16,name:"Non-Independent Non-Executive Director (NINED)"},
    {value:32,name:"Executive Director"},
    {value:64,name:"Advisor"},
]
export const cmrType = [
    {value:1,name:"Audit"},
    {value:2,name:"Risk"},
    {value:4,name:"Nomination"},
    {value:8,name:"Remuneration"},
    {value:16,name:"Governance"},
    {value:32,name:"Strategy"},
    {value:64,name:"Sustainability"},
    {value:128,name:"Other"},

]
export const cmrRole = [
    
    {value:1,name:"Chairman"},
    {value:2,name:"Vice Chair"},
    {value:4,name:"Member"},
]
export const availableDays = [
    {value:1,name:"Not more than 5 days per month"},
    {value:2,name:"6 -18 days per month"},
    {value:4,name:"19 - 28 days per month"},
    {value:8,name:"More than 25 days per month"},
]