import React, { Fragment, useEffect, useState } from "react";
import { useMeasure, useMount, useSetState, useUnmount } from "react-use";
import "./styles.less";
import MCC from "../../../../Components/ComponentCommon/MiddleContent";
import { Observable, Observer } from "../../../../../Utils/Ob";
import { Button, Drawer, Grid, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import CourseList, { CourseListState } from "./CourseList";
import { useHistory } from "react-router";
import { StyledHeader } from "./styledComponents";
import ThreeDotsLoader from "../../../../Components/ComponentCommon/ThreeDotsLoader";
import { primaryTextColor } from "../../../../../Utils/themeConfig";
import Common from "../../../../../Utils/Common";
import ShareDialog from "../../../../Components/ComponentCommon/ShareDialog";
import PopupCertificate from "../../../../Components/ComponentCommon/PopupCertificate";

const state = Observable({
  title: "",
  showMenu: true,
  showDrawer: false,
  isSelectQuizModalOpen:false,
  selectedQuizId:""
});

function CourseHeader(props) {
  const [ref, refInfo] = useMeasure();
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const history = useHistory();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);

  useMount(() => {
    document.body.style.overflow = "hidden";
    state.reset();
    CourseListState.reset();
  });
  useUnmount(() => {
    document.body.style.overflow = "auto";
    window.resetPageLoading();
  });
  useEffect(() => {
    props.onHeightChange && props.onHeightChange(refInfo.height);
  }, [refInfo.height]);

  const isSoloQuiz =
    location.pathname.startsWith("/quiz/") ||
    location.pathname.startsWith("/video/") ||
    location.pathname.startsWith("/slide/");
  const hasCertificate = CourseListState.userCourse?.certificate;
  const showButton = CourseListState.showCompleteButton && !CourseListState.reloadingCourse;

  return (
    <Fragment>
      <PopupCertificate
        show={showCertificate}
        certificate={CourseListState.userCourse?.certificate}
        onCancel={() => {
          setShowCertificate(false);
        }}
      />
      <ShareDialog
        open={showShareDialog}
        quote={state.title}
        onClose={() => {
          setShowShareDialog(false);
        }}
      />
      <StyledHeader ref={ref} className={"header"}>
        <MCC style={{ width: "100%", paddingLeft: 16, paddingRight: 16 }}>
          <Row gutter={[16, 0]}>
            <Col lg={1} md={1} sm={2} xs={2}>
              <MCC style={{ paddingBottom: 6 }}>
                {!isSoloQuiz && (
                  <Button
                    className={"menu-button"}
                    type="text"
                    icon={<MenuOutlined style={{ color: primaryTextColor }} />}
                    onClick={() => {
                      if (checkBreakpoint(screens, "md")) {
                        state.showDrawer = !state.showDrawer;
                      } else {
                        state.showMenu = !state.showMenu;
                      }
                    }}
                  />
                )}
              </MCC>
            </Col>
            <Col lg={17} md={17} sm={0} xs={0} className={"title"}>
              <MCC containerStyle={{ justifyContent: "flex-start" }}>
                <div className={"title-container"}>
                  <Link to="/usercourse">
                    <i className="fas fa-arrow-left" />
                    Back
                  </Link>
                  {state.title && <h2>{Common.formatName(state.title, 40)}</h2>}
                </div>
              </MCC>
            </Col>
            <Col lg={6} md={6} sm={22} xs={22} className={"action"}>
              <MCC containerStyle={{ justifyContent: "flex-end" }}>
                {/*{!isSoloQuiz && (*/}
                {/*  <Link to={"/usercourse"} className="btn btn-primary btn-hover-secondary">*/}
                {/*    Khóa học của tôi*/}
                {/*  </Link>*/}
                {/*)}*/}
                {!isSoloQuiz && (
                  <>
                    {showButton && (
                      <Link
                        to={"/usercourse"}
                        className="btn btn-primary btn-hover-secondary"
                        onClick={(e) => {
                          e.preventDefault();

                          if (hasCertificate) {
                            setShowCertificate(true);
                          } else {
                            if(CourseListState.userCourse.quizs?.length > 1){
                                state.isSelectQuizModalOpen = true
                             }
                            else{

                              CourseListState.renderLastQuiz = true;
                            } 
                          }
                        }}>
                        <i
                          className="fas fa-medal"
                          style={{ display: "inline-block", marginRight: 8 }}
                        />
                        {hasCertificate ? "View your Certificate" : "Get a Certificate"}
                      </Link>
                    )}
                    {!showButton && (
                      <button
                        className={"share-this-course"}
                        onClick={() => {
                          setShowShareDialog(true);
                        }}>
                        <i className="fas fa-share-alt" />
                        Share this course
                      </button>
                    )}
                  </>
                )}
                {isSoloQuiz && (
                  <Link
                    to={"/usercourse"}
                    className="btn btn-primary btn-hover-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}>
                    Học liệu chia sẻ
                  </Link>
                )}
              </MCC>
            </Col>
          </Row>
        </MCC>
      </StyledHeader>
      <div style={{ width: "100%", height: 85 }} />
      <Drawer
        width={340}
        placement={"left"}
        closable={false}
        onClose={() => {
          state.showDrawer = false;
        }}
        visible={state.showDrawer && checkBreakpoint(screens, "md")}
        bodyStyle={{ padding: 0 }}
        title={"Mục lục"}>
        <CourseList />
      </Drawer>
    </Fragment>
  );
}

export default Observer(CourseHeader);
export const CourseHeaderState = state;

function checkBreakpoint(screens, size) {
  let keys = ["xxl", "xl", "lg", "md", "sm", "xs"];
  let index = 0;
  for (let i in keys) {
    let k = keys[i];
    if (screens[k]) {
      index = i;
      break;
    }
  }
  return keys.indexOf(size) <= index;
}
