/* eslint-disable */
import React, { useRef, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import DrawableCanvas from "react-canvas-draw";
import useWindowDimensions from "../../../../Utils/WindowDimensions";

function Drawing(props) {
    let canvas = useRef(null);
    const [brushRadius, setBrushRadius] = useState(2);
    const [brushColor, setBrushColor] = useState(2);
    const { path } = props.location.state;
    console.log(path);
    const { height, width } = useWindowDimensions();
    console.log(height + "-" + width);
    var tempResize = "?height=" + height + "&width=" + width;
    // if (height > width) {
    //     tempResize = '?height=' + (height - 100);
    // } else {
    //     tempResize = '?width=' + (width - 100);
    // }
    console.log(path + tempResize);
    return (
        <div className="container">
            <Row
                style={{
                    zIndex: 1000000001,
                }}>
                <Col className="text-center" md="12">
                    <Button
                        style={{
                            position: "fixed",
                            bottom: 10,
                            left: 10,
                            zIndex: 1000000001,
                        }}
                        onClick={() => window.history.back()}>
                        Trở lại
                    </Button>
                    <Button
                        color="primary"
                        style={{
                            position: "fixed",
                            bottom: 10,
                            left: 90,
                            zIndex: 1000000001,
                        }}
                        onClick={() => canvas.undo()}>
                        Undo
                    </Button>
                    <input
                        type="color"
                        value={brushColor}
                        onChange={(event) => setBrushColor(event.target.value)}
                        style={{
                            position: "fixed",
                            bottom: 10,
                            left: 170,
                            zIndex: 1000000001,
                        }}
                    />

                    <select
                        // type="select"
                        value={brushRadius}
                        onChange={(event) => setBrushRadius(event.target.value)}
                        style={{
                            position: "fixed",
                            bottom: 10,
                            left: 230,
                            zIndex: 1000000001,
                            width: 50,
                        }}>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                    </select>
                </Col>
            </Row>
            <DrawableCanvas
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 1000000000,
                }}
                canvasWidth={width}
                canvasHeight={height}
                // canvasWidth={`100%`}
                // canvasHeight={`100vh`}
                //loadTimeOffset={0}
                hideInterface
                brushRadius={brushRadius}
                lazyRadius={0}
                imgSrc={path + tempResize}
                brushColor={brushColor}
                ref={(canvasDraw) => (canvas = canvasDraw)}
            />
        </div>
    );
}

Drawing.propTypes = {};

export default Drawing;
