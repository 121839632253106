import { gql } from "urql";

export const queryCalendars = gql`
  query Calendars(
    $merchantId: String!
    $languageId: String!
    $tokenId: String!
    $from: DateTime
    $to: DateTime
  ) {
    userscalendars(
      param: {
        merchantId: $merchantId
        languageId: $languageId
        tokenUser: $tokenId
        from: $from
        to: $to
      }
    ) {
      items {
        id
        start
        end
        title
        description
        url
      }
    }
  }
`;
