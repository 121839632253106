import React, { useEffect, useState } from "react";
import { useMount, useSetState } from "react-use";
import { Link, useHistory } from "react-router-dom";
import { Observer } from "../../../../../../Utils/Ob";
import { Skeleton } from "antd";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import "./styles.less";
import * as ecs from "./ecs";
import {
  CardCarousel,
  FilterItems,
  formatTimeToLearn,
  getAuthorAvatar,
  getAuthorNames,
  RenderItem,
  SideWidget,
} from "../../../Product/maxcoach/Products";
import * as esc from "../../../Product/maxcoach/Products/esc";
import { useQuery } from "urql";
import { QUERY_MY_COURSES } from "./gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import {
  EmptyBox,
  FetchingItemsBox,
  TimeLeft,
  TimeLeftBar,
  TimeLeftBarBackground,
  TimeLeftBarValue,
} from "./styled";
import _ from "lodash";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import { useSelector } from "react-redux";
import { QUERY_RELATE_COURSE_PRODUCTS } from "../../../Product/maxcoach/Products/gql";
import { QUERY_CONTESTS } from "../../../Contest/gql";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Index() {
  const {t} = useTranslation()
  const history = useHistory();
  const [state, setState] = useSetState({
    offset: 0,
    filterByProgresses: [],
    filterByCategories: [],
    sortBy: 0,
  });
  const [fetchMyCourses] = useQuery({
    query: QUERY_MY_COURSES,
    variables: (() => {
      const { languageId, merchantId, tokenId } = getCommonStorage();

      return {
        offset: state.offset,
        limit: 666,
        languageId,
        merchantId,
        tokenId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [fetchContests] = useQuery({
    query: QUERY_CONTESTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";
      let type = 1;
      let published = true;
      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        tokenUser: commonStorage.tokenId,
        limit: 3,
        offset: 0,
        sort,
        order,
        type,
        published,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [myProgessesList] = useState([
    {
      label: t("In-progress"),
      id: "in-progress",
    },
    {
      label: t("Completed"),
      id: "completed",
    },
  ]);
  const [categories, setCategories] = useState([]);
  const merchant = useSelector((state) => state.Merchant.merchant);
  const [fetchProducts] = useQuery({
    query: QUERY_RELATE_COURSE_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        type: Configuration.productType.course,
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        offset: 0,
        limit: 3,
        tokenUser: commonStorage.tokenId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  useMount(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    const courses = fetchMyCourses.data?.usercourses?.items || [];
    const categories = (() => {
      const categories = [];

      _.forEach(courses, (course) => {
        _.forEach(course.categories, (category) => {
          let index = _.findIndex(categories, (v) => v.id === category.id);

          if (index === -1) {
            categories.push(category);
          }
        });
      });

      return categories;
    })();

    setCategories(categories);
  }, [fetchMyCourses.data]);

  const pageTitle = t("My Courses");
  const pageDescription = "Pick up where you left off and review what you’ve learned.";
  const fetching = fetchMyCourses.fetching;
  const courses = (fetchMyCourses.data?.usercourses?.items || []).filter((item) => {
    if (item.percent < 100) {
      item.progress = "in-progress";
    } else {
      item.progress = "completed";
    }

    if (state.filterByProgresses.length > 0) {
      if (!state.filterByProgresses.includes(item.progress)) return false;
    }

    if (state.filterByCategories.length) {
      let index = _.findIndex(item.categories, (v) => state.filterByCategories.includes(v.id));

      if (index === -1) return false;
    }

    return true;
  });
  const contests = fetchContests.data?.contests?.items || [];
  const allCourses = fetchMyCourses.data?.usercourses?.items || [];
  const totalCount = fetchMyCourses.data?.usercourses?.totalCount || 0;
  const currentCourse = _.find(
    fetchMyCourses.data?.usercourses?.items || [],
    (v) => v.id === localStorage.getItem("currentCourse")
  );
  const studyTime = _.sum(
    allCourses.map((v) => {
      return (v.period * v.percent) / 100;
    })
  );
  const totalCertificates = _.sum(
    allCourses.map((v) => {
      return v.certificate ? 1 : 0;
    })
  );
  const products = fetchProducts.data?.relatecourseproducts?.items || [];

  if (state.sortBy > 0) {
    courses.sort((a, b) => {
      if (state.sortBy === 2) {
        return b.name.localeCompare(a.name);
      }

      return a.name.localeCompare(b.name);
    });
  }

  return (
    <React.Fragment>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{pageTitle}</h1>
              <p>{pageDescription}</p>
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className="sidebar-widget-wrapper pr-0 animate__animated animate__fadeInUp animate__faster">
        <div className="sidebar-widget">
          <div className="sidebar-widget-content">
            <div className="section section-padding-bottom">
              <div className="container">
                <AnalyticsItems
                  // title={"Statistics"}
                  items={[
                    {
                      label: t("Total Course"),
                      value: totalCount,
                      suffix: "courses",
                      icon: "fa-graduation-cap",
                    },
                    {
                      label: t("Study Hours"),
                      value: parseFloat(parseFloat(studyTime / 60).toFixed(2)),
                      suffix: "hours",
                      background: "green",
                      icon: "fa-clock",
                    },
                    {
                      label: t("Course Certificate"),
                      value: totalCertificates,
                      suffix: "certificates",
                      background: "pink",
                      icon: "fa-file-certificate",
                    },
                    {
                      label: t("Courses Completed"),
                      value: allCourses.filter((v) => parseInt(v.percent) === 100).length,
                      suffix: "completed",
                      background: "blue",
                      icon: "fa-check-circle",
                    },
                  ]}
                />
              </div>
            </div>
            {/**/}
            <div className="section section-padding-bottom">
              <div className="container">
                <div className="row max-mb-n50">
                  <div className="col-lg-3 col-12 order-lg-1 max-mb-50">
                    <SideWidget title={t("MY PROGRESS")} canExpand={true}>
                      <FilterItems
                        items={myProgessesList}
                        onChange={(checkedKeys) => {
                          setState({ filterByProgresses: checkedKeys });
                        }}
                        value={state.filterByProgresses}
                      />
                    </SideWidget>
                    <esc.SideWidgetDivider />
                    <SideWidget title={t("CATEGORIES")} canExpand={true}>
                      <FilterItems
                        items={categories}
                        loading={fetching}
                        onChange={(checkedKeys) => setState({ filterByCategories: checkedKeys })}
                        search={true}
                        value={state.filterByCategories}
                      />
                    </SideWidget>
                  </div>
                  <div className="col-lg-9 col-12 order-lg-1 max-mb-50">
                    {contests != null && contests.length > 0 &&
                    <SideWidget
                    title={"Contest"}
                    sortable={false}
                    description={
                      <>
                        {t("Provide by")} <span>{merchant.name} </span> -{" "}
                        <Link className="" to="/contest">
                          {t("See All")}
                        </Link>
                      </>
                    }>
                    <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30 mb-3">
                      {contests.map((contest, i) => {
                        const { startDate, endDate } = contest;
                        return (
                          <div className="col max-mb-30">
                            <div className="event">
                              <div className="thumbnail">
                                <a href="event-details.html" className="image">
                                  <img
                                    src={Common.getImageThumbnail(
                                      contest.images,
                                      "?Mode=crop&width=480&height=298"
                                    )}
                                    alt="Event"
                                  />
                                </a>
                                <div className="event-overlay-background" />
                                <div className="event-overlay-content">
                                  <Link
                                    to={`/contest/${contest.id}`}
                                    className={
                                      "btn btn-md btn-light btn-hover-light theme-color"
                                    }>
                                   {t("Detail")}
                                  </Link>
                                  {/*<a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>*/}
                                </div>
                              </div>
                              <div className="info">
                                <span className="date">
                                  {t("Started From")} {moment(startDate).format("DD/MM/YYYY")}
                                </span>
                                <h3 className="title">
                                  <a href={`/contest/${contest.id}`}>{contest.name}</a>
                                </h3>
                                <div>{contest.subDescription}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </SideWidget>
                    }
                    
                    {(fetching || courses.length > 0) && (
                      <>
                        {currentCourse && (
                          <CardCarousel
                            items={[currentCourse]}
                            renderItem={(item) => {
                              const src = Common.getImageThumbnail(
                                item.images,
                                Configuration.imageFormatCoursesMaxcoach
                              );
                              const courseUrl = `/user-course-details/${item.id}`;
                              const timeLeft =
                                (item.period - (item.period * item.percent) / 100) * 60;
                              const formatedPeriod = formatTimeToLearn(timeLeft)
                                .replace(/m/g, " mimutes")
                                .replace(/h/g, " hours");

                              return (
                                <esc.HorizontalProductItem onClick={() => history.push(courseUrl)}>
                                  <div className={"dx-cell"}>
                                    <div className={"dx-content"}>
                                      <h1 className={"dx-title"}>{item.name}</h1>
                                      <p className={"dx-desc"}>
                                        {item.subDescription && (
                                          <span>{Common.formatName(item.subDescription, 160)}</span>
                                        )}
                                        {!item.subDescription && (
                                          <span>
                                            {(() => {
                                              const metaTag = JSON.parse(merchant.metaTag || "{}");

                                              return Common.formatName(metaTag.Description, 160);
                                            })()}
                                          </span>
                                        )}
                                      </p>
                                      <div className={"dx-author"}>
                                        <div className={"dx-avatar"}>
                                          <img
                                            src={getAuthorAvatar(item, merchant, "")}
                                            alt={"Avatar"}
                                          />
                                        </div>
                                        <div className={"dx-name"}>
                                          <p>{getAuthorNames(item, merchant)}</p>
                                          <p>
                                            {(() => {
                                              const metaTag = JSON.parse(merchant.metaTag || "{}");

                                              return metaTag.Title;
                                            })()}
                                          </p>
                                        </div>
                                      </div>
                                      <TimeLeft>
                                        <label>
                                          {item.percent > 0 && (
                                            <>
                                              {item.percent >= 100 && <span>{t("Completed")}</span>}
                                              {item.percent < 100 && (
                                                <>
                                                  <span>{formatedPeriod}</span> {t("left")}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {!(item.percent > 0) && <>{t("Course not started")}</>}
                                        </label>
                                        <TimeLeftBar>
                                          <TimeLeftBarBackground>
                                            <TimeLeftBarValue
                                              style={{ width: `${item.percent}%` }}
                                            />
                                          </TimeLeftBarBackground>
                                        </TimeLeftBar>
                                      </TimeLeft>
                                    </div>
                                  </div>
                                  <div className={"dx-cell"}>
                                    <img src={src} alt="Thumbnail" />
                                    <esc.CarouselImageInfo>
                                      <div>
                                        <span className={"dx-brand"} hidden={!item.merchantLogo}>
                                          <img
                                            src={Configuration.imageUrl + item.merchantLogo}
                                            alt={"Brand"}
                                          />
                                        </span>
                                      </div>
                                      <div>
                                        <span className={"dx-hours"}>
                                          {formatTimeToLearn(item.period * 60)}
                                        </span>
                                      </div>
                                    </esc.CarouselImageInfo>
                                  </div>
                                  <div className={"dx-rank dx-rank-blue"}>{t("Current lesson")}</div>
                                </esc.HorizontalProductItem>
                              );
                            }}
                          />
                        )}

                        <SideWidget
                          title={"All Courses"}
                          sortable={true}
                          sort={[
                            {
                              label: "Recently Accessed",
                              value: 0,
                            },
                            {
                              label: "Title: A to Z",
                              value: 1,
                            },
                            {
                              label: "Title: Z to A",
                              value: 2,
                            },
                          ]}
                          sortValue={state.sortBy}
                          onSortChange={(v) => setState({ sortBy: v })}>
                          <esc.FilterValuesContainer>
                            {categories.map((category, i) => {
                              if (state.filterByCategories.includes(category.id)) {
                                return (
                                  <span title={category.name} key={i}>
                                    {Common.formatName(category.name, 25)}
                                  </span>
                                );
                              }

                              return null;
                            })}
                            {myProgessesList.map((v, i) => {
                              if (state.filterByProgresses.includes(v.id)) {
                                return (
                                  <span title={v.label} key={i + "p"}>
                                    {Common.formatName(v.label, 25)}
                                  </span>
                                );
                              }

                              return null;
                            })}
                          </esc.FilterValuesContainer>
                          <FetchingItemsEffect hidden={!fetching} />
                          <div
                            className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30"
                            hidden={fetching}>
                            {courses.map((item, i) => (
                              <RenderItem item={item} key={i} index={i} type={"my-course"} />
                            ))}
                          </div>
                        </SideWidget>
                      </>
                    )}
                    {!fetching && (totalCount === 0 || courses.length === 0) && (
                      <EmptyBox>
                        {totalCount === 0 && <p>{t("You have no courses right now")}.</p>}
                        {totalCount !== 0 && <p>{t("No items found")}.</p>}
                        {totalCount === 0 && (
                          <button onClick={() => history.push("/product")}>Browse Now</button>
                        )}
                      </EmptyBox>
                    )}
                    <SideWidget
                      title={t("Try Something New")}
                      description={
                        <>
                          {t("Provide by")} <span>{merchant.name} </span> -{" "}
                          <a className="" href="/product">
                            {t("See All")}
                          </a>
                        </>
                      }
                      style={{ marginTop: 64 }}>
                      <FetchingItemsEffect hidden={!fetchProducts.fetching} />
                      <div
                        className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30"
                        hidden={fetchProducts.fetching}>
                        {products.map((item, i) => (
                          <RenderItem item={item} key={i} index={i} type={"product"} />
                        ))}
                      </div>
                    </SideWidget>
                  </div>
                </div>
              </div>
            </div>
            {/**/}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Observer(Index);

export function FetchingItemsEffect(props) {
  return (
    <FetchingItemsBox {...props}>
      <div className="section">
        <div className="container">
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
            <div className={"col max-mb-30"}>
              <div className={"d-skeleton-image"}>
                <Skeleton active={true} paragraph={{ rows: 0 }} />
              </div>
              <Skeleton active={true} />
            </div>
            <div className={"col max-mb-30"}>
              <div className={"d-skeleton-image"}>
                <Skeleton active={true} paragraph={{ rows: 0 }} />
              </div>
              <Skeleton active={true} />
            </div>
            <div className={"col max-mb-30"}>
              <div className={"d-skeleton-image"}>
                <Skeleton active={true} paragraph={{ rows: 0 }} />
              </div>
              <Skeleton active={true} />
            </div>
          </div>
        </div>
      </div>
    </FetchingItemsBox>
  );
}

export function AnalyticsItems(props) {
  const { title, items } = props;

  return (
    <ecs.AnalyticsSection>
      {title && <h1>{title}</h1>}
      <div className={"dx-table"}>
        {items.map((item, i) => (
          <div className={"dx-cell"} key={i}>
            <div className={"dx-container dx-bg-" + item.background}>
              <div className={"dx-title"}>
                <span className={"dx-icon"}>
                  <i className={"far " + item.icon} />
                  <span />
                </span>
                {item.label}
              </div>
              <div className={"dx-analytics"}>
                <span>{item.value}</span> {item.suffix}
              </div>
            </div>
          </div>
        ))}
      </div>
    </ecs.AnalyticsSection>
  );
}
