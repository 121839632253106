import React, { useEffect } from "react";
import { useDebounce, useSetState } from "react-use";
import { useQuery } from "urql";
import Configuration from "../../../../Config/Config";
import { getCommonStorage } from "../../../../Config/gql";
import { BasePage } from "../../ComponentCommon/BasePage";
import { QUERY_PRODUCTS, QUERY_PRODUCT_CATEGORIES } from "../Product/maxcoach/Products/gql";
import { RenderPagination } from "../Product/maxcoach/Products";
import Common from "../../../../Utils/Common";
import { SideWidget, FilterItems } from "../Product/maxcoach/Products/index";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ModalQuickView } from "./ModalQuickView";
import { useDiscount } from "../Product/maxcoach/Products/useDiscount";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
import {
  BarsOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { exec } from "../../../../Utils/dn";
import { addItemToCart, addItemToWishList, getCart } from "../../../Services/CartService";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { addItemToWishListAction, getCartAction } from "../../../Actions/Cart";
import ModalCompare, { TProduct } from "./ModalCompare";
import { Footer } from "antd/lib/layout/layout";
import { FooterCompareModal } from "./styled";

function SingleProduct(props: {
  product: any;
  setProductQuickView: (product: any) => void;
  setCompareProduct: (product: any) => void;
  setModalCompareVisible: (value: any) => void;
  setIsFooterCompareVisible: (value: any) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    product,
    setProductQuickView,
    setCompareProduct,
    setModalCompareVisible,
    setIsFooterCompareVisible,
  } = props;

  const [hasDiscounts, priceInfo] = useDiscount(product);

  const handleAddCompareProduct = () => {
    setCompareProduct((oldCompareProduct: any) => {
      if (
        oldCompareProduct.length > 0 &&
        oldCompareProduct.find((tempProduct: any) => tempProduct.id === product.id)
      ) {
        return [...oldCompareProduct];
      }
      return [{ ...product }, ...oldCompareProduct];
    });

    setModalCompareVisible(true);
    setIsFooterCompareVisible(true);
  };

  return (
    <div key={product.id} className="col max-mb-40" data-aos="fade-up">
      <div className="product">
        <div className="thumbnail">
          <Link to={"/product-basic/" + product.id} className="image">
            <img
              src={Common.getImageThumbnail(product.images, "?mode=crop&width=270&height=270")}
              alt=""
            />
          </Link>
          <div className="actions">
            <a
              href="#quick-view-modal-container"
              onClick={() => {
                setProductQuickView(product);
              }}
              data-toggle="modal"
              className="action hintT-left hintT-primary"
              data-hint="Quick view">
              <i className="far fa-search" />
            </a>
            <button
              onClick={() => addItemToCart({ productId: product.id, dispatch, t })}
              className={`action hintT-left hintT-primary border-none`}
              data-hint="Add to cart">
              {" "}
              <i className="far fa-shopping-cart" />
            </button>

            <button
              onClick={() => {
                addItemToWishList(product.id, history);

                /* history.push("/wishlist") */
              }}
              className="action hintT-left hintT-primary border-none"
              data-hint="Add to wishlist">
              <i className="far fa-heart" />
            </button>
            <button
              onClick={handleAddCompareProduct}
              className="action hintT-left hintT-primary border-none"
              data-hint="Compare">
              <i className="far fa-signal" />
            </button>
          </div>
        </div>
        <div className="info">
          <h3 className="title">
            <Link to={"/product-basic/" + product.id}>{product.name}</Link>
          </h3>
          {hasDiscounts ? (
            <span className="price">
              {Common.formatMoney(priceInfo.price, 0)}{" "}
              <span
                style={{
                  textDecoration: "line-through",
                  color: "gray",
                }}>
                {Common.formatMoney(product.price, 0)}
              </span>
            </span>
          ) : (
            <span className="price">{Common.formatMoney(product.price, 0)}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ProductBasic() {
  const { t } = useTranslation();
  const [pageState, setPageState] = useSetState({
    current: 0,
    limit: 6,
    categoryIds: [],
    keyword: "",
  });
  const [debounceKeyword, setDebounceKeyword] = useState("");
  const [productQuickview, setProductQuickView] = useState({});
  const [modalCompareVisible, setModalCompareVisible] = useState(false);
  const [compareProduct, setCompareProduct] = useState<any>(
    localStorage.getItem("compareProduct")
      ? JSON.parse(localStorage.getItem("compareProduct") || "")
      : []
  );
  const [isFooterCompareVisible, setIsFooterCompareVisible] = useState(compareProduct.length > 0);
  const [fetchProductsResult] = useQuery({
    query: QUERY_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";

      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        limit: pageState.limit,
        offset: pageState.limit * pageState.current,
        categoryIds: pageState.categoryIds,
        keyword: pageState.keyword,
        sort,
        order,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [fetchCategoriesResult] = useQuery({
    query: QUERY_PRODUCT_CATEGORIES,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        type: 3,
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
      };
    })(),
  });
  useEffect(() => {
    localStorage.setItem("compareProduct", JSON.stringify(compareProduct));
    if (compareProduct.length === 0) {
      setModalCompareVisible(false);
    }
  }, [compareProduct]);
  useDebounce(
    () => {
      setPageState({
        keyword: debounceKeyword,
      });
    },
    200,
    [debounceKeyword]
  );

  const products = fetchProductsResult.data?.products?.items || [];
  const categories = fetchCategoriesResult.data?.categories?.items || [];
  const rootCategories = categories.filter((v: any) => {
    return !v.parentId || v.parentId === "00000000-0000-0000-0000-000000000000";
  });
  const total = fetchProductsResult.data?.products?.totalCount || 0;
  const isEmpty = total === 0;
  return (
    <BasePage title={"Sản phẩm"}>
      <ModalCompare
        setCompareProduct={setCompareProduct}
        products={products}
        compareProduct={compareProduct}
        visible={modalCompareVisible}
        setVisible={setModalCompareVisible}
      />

      <ModalQuickView product={productQuickview} />
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="row max-mb-n50">
            <div className="col-xl-9 col-lg-8 col-12 order-lg-2 max-mb-50">
              {/* Course Top Bar Start */}
              <div className="row justify-content-between align-items-center max-mb-20">
                <div className="col-sm-auto col-12 max-mb-10">
                  {!fetchProductsResult.fetching ? (
                    <p className="result-count">
                      Chúng tôi tìm thấy <span>{total}</span> sản phẩm cho bạn
                    </p>
                  ) : null}
                </div>
                <div className="col-sm-auto col-12 max-mb-10">
                  {/* <select className="sort-by">
                    <option value="popularity">Popularity</option>
                    <option value="date">Latest</option>
                    <option value="price">Price: low to high</option>
                    <option value="price-desc">Price: high to low</option>
                  </select> */}
                </div>
              </div>
              {/* Course Top Bar End */}
              {/* Product Wrapper Start */}
              <div className="row row-cols-xl-3 row-cols-sm-2 row-cols-1 max-mb-n40">
                {fetchProductsResult.fetching
                  ? "Loading..."
                  : products.map((product: any) => (
                      <SingleProduct
                        setIsFooterCompareVisible={setIsFooterCompareVisible}
                        setCompareProduct={setCompareProduct}
                        setModalCompareVisible={setModalCompareVisible}
                        product={product}
                        setProductQuickView={setProductQuickView}
                      />
                    ))}
              </div>
              {/* Product Wrapper End */}
              {/* Pagination Start */}
              <div className="row max-mt-50" hidden={isEmpty || fetchProductsResult.fetching}>
                <div className="col">
                  <RenderPagination
                    currentPage={pageState.current}
                    totalItem={total}
                    itemPerPage={pageState.limit}
                    handlePageChange={(v: any) => {
                      setPageState({ current: v });
                      window.scrollTo(0, 300);
                    }}
                  />
                </div>
              </div>
              {/* Pagination End */}
            </div>
            {/* Sidebar Widget Wrapper Start */}
            <div className="col-xl-3 col-lg-4 col-12 order-lg-1 max-mb-50">
              <div className="sidebar-widget-wrapper pr-0">
                {/* Widget Search Start */}
                <div className="sidebar-widget mb-3">
                  <h3 className="sidebar-widget-title">Search</h3>
                  <div className="sidebar-widget-content">
                    <div className="sidebar-widget-search">
                      <form action="#">
                        <input
                          value={debounceKeyword}
                          onChange={(e) => {
                            setDebounceKeyword(e.target.value);
                          }}
                          type="text"
                          placeholder="Search..."
                        />
                        <button>
                          <i className="fas fa-search" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                {/* Widget Search End */}
                {/* @ts-ignore */}
                <SideWidget title={"CATEGORIES"} canExpand={true}>
                  <FilterItems
                    items={rootCategories}
                    loading={fetchCategoriesResult.fetching}
                    onChange={(categoryIds: any) => setPageState({ categoryIds })}
                    search={true}
                    value={pageState.categoryIds}
                  />
                </SideWidget>
              </div>
            </div>
            {/* Sidebar Widget Wrapper End */}
          </div>
        </div>
      </div>
      <Tooltip title={t("Click on compare bar to hide")}>
        <FooterCompareModal
          onClick={() => setIsFooterCompareVisible(false)}
          visible={isFooterCompareVisible}>
          <div className="footer-content__wrapper flex align-center">
            <div className="flex align-center">
              {compareProduct.map((prd: TProduct) => {
                return (
                  <div className="position-relative">
                    <CloseSquareOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        if (compareProduct.length === 1) setIsFooterCompareVisible(false);
                        setCompareProduct((oldCompareProduct: TProduct[]) => {
                          const temp = oldCompareProduct.filter((tempPrd) => tempPrd.id !== prd.id);
                          return [...temp];
                        });
                      }}
                      className="cursor-pointer hover:text-red-600 position-absolute right-3.5 top-0"
                    />
                    <img
                      className="mr-3 rounded-sm"
                      src={Common.getImageThumbnail(prd.images, "?mode=crop&width=50&height=50")}
                    />
                  </div>
                );
              })}
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setModalCompareVisible(!modalCompareVisible);
              }}
              style={{
                backgroundColor: "#20AD96",
                border: "none",
                color: "white",
                padding: "12px 20px",
                borderRadius: "4px",
              }}>
              {modalCompareVisible ? <CloseOutlined /> : <BarsOutlined />}
            </button>
          </div>
        </FooterCompareModal>
      </Tooltip>
    </BasePage>
  );
}
