import { useEffect, useMemo, useState } from "react";
import { useQuery } from "urql";
import { QueryAttribute } from "../App/Components/Layout/Cv/gql";
import { QUERY_FORM_ATTRIBUTE_VALUES } from "../App/Components/Layout/ProductExchangePoint/gql";
import { getCommonStorage } from "../Config/gql";

interface IProps {
  id: string;
  type?: number;
  subType?: number;
}
export const useAttributeInfo = (props: IProps) => {
  const [attributeValues, setAttributeValues] = useState<any>([]);
  const [formatAttributesDatas] = useQuery({
    query: QUERY_FORM_ATTRIBUTE_VALUES,
    variables: (() => {
      const { languageId } = getCommonStorage();
      return {
        targetId: props.id,
        languageId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [QueryAttributeDatas] = useQuery({
    query: QueryAttribute,
    variables: (() => {
      const { languageId, merchantId } = getCommonStorage();
      return {
        type: props?.type || undefined,
        subType: props?.subType || undefined,
        languageId: languageId,
        merchantId: merchantId,
      };
    })(),
  });
  const formatAttributes = useMemo(
    () => formatAttributesDatas.data?.formattributevalues || {},
    [formatAttributesDatas]
  );
  const attributes = useMemo(
    () => QueryAttributeDatas.data?.attributes?.items || [],
    [QueryAttributeDatas]
  );

  useEffect(() => {
    if (attributes.length > 0) {
      attributes.forEach((attribute: any) => {
        const attributeDateTimeValue = formatAttributes.attributeDateTimeValues?.find(
          (v: any) => v.attributeId === attribute.id
        );
        const attributeTextValue = formatAttributes.attributeTextValues?.find(
          (v: any) => v.attributeId === attribute.id
        );
        const attributeIntValue = formatAttributes.attributeIntValues?.find(
          (v: any) => v.attributeId === attribute.id
        );
        const attributeDecimalValue = formatAttributes.attributeDecimalValues?.find(
          (v: any) => v.attributeId === attribute.id
        );
        const attributeVarcharValue = formatAttributes.attributeVarcharValues?.find(
          (v: any) => v.attributeId === attribute.id
        );

        if (attributeDateTimeValue) {
          setAttributeValues((currValue: any) => {
            return [...currValue, { value: attributeDateTimeValue.value, name: attribute.name }];
          });
        }
        if (attributeTextValue) {
          setAttributeValues((currValue: any) => {
            return [...currValue, { value: attributeTextValue.value, name: attribute.name }];
          });
        }
        if (attributeIntValue) {
          setAttributeValues((currValue: any) => {
            return [...currValue, { value: attributeIntValue.value, name: attribute.name }];
          });
        }
        if (attributeDecimalValue) {
          setAttributeValues((currValue: any) => {
            return [...currValue, { value: attributeDecimalValue.value, name: attribute.name }];
          });
        }
        if (attributeVarcharValue) {
          setAttributeValues((currValue: any) => {
            return [...currValue, { value: attributeVarcharValue.value, name: attribute.name }];
          });
        }
      });
    }
  }, [attributes, formatAttributes]);

  return attributeValues;
  /*  return (
      <>
        {attributeValues.map((v: any, index: number) => (
          <Card
            style={{ borderTop: "1px solid #cccccc" }}
            bordered={false}
            bodyStyle={{ borderTop: "1px solid #cccccc" }}
            title={<span className="font-bold">{v.name}</span>}>
            <div dangerouslySetInnerHTML={{ __html: v.value }}></div>
          </Card>
        ))}
      </>
    ); */
};
