import { styled } from "../../../../../Utils/stitches.config";
import {
  borderColor,
  borderInputColor,
  infoTextColor,
  primaryColor,
  primaryTextColor,
} from "../../../../../Utils/themeConfig";

export const StyledTitle = styled("div", {
  color: primaryTextColor,
  textAlign: "center",
  marginTop: 90,
  position: "relative",
  "& > span:last-child": {
    fontSize: 24,
    lineHeight: "29px",
    fontWeight: "bold",
  },
  "& > span:first-child": {
    position: "absolute",
    top: 0,
    left: 0,
    color: infoTextColor,
    cursor: "pointer",
    ":hover": {
      color: primaryColor,
    },
    i: {
      display: "inline-block",
      marginRight: 8,
    },
  },
});
export const StyledItemInfo = styled("div", {
  marginTop: 60,
  fontSize: 16,
  lineHeight: "20px",
  color: primaryTextColor,
  "& > div:last-child": {
    fontWeight: "bold",
    marginTop: 16,
  },
});
export const StyledTable = styled("table", {
  width: "100%",
  marginTop: 42,
});
export const StyledSub = styled("div", {
  color: primaryTextColor,
  "& > span": {
    display: "inline-block",
    width: "50%",
    marginBottom: 24,
  },
  "& > span:first-child": {
    textAlign: "left",
  },
  "& > span:last-child": {
    fontWeight: "bold",
    textAlign: "right",
  },
  hr: {
    borderColor: "#EFF0F1",
    borderWidth: 1,
    padding: 0,
    margin: 0,
  },
});
