import { Button, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Common from "../../../../Utils/Common";
import { AddToCartBtn, CloseIconBtn, Table } from "./styled";
import "./style.less";
import { CloseOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { addItemToCart } from "../../../Services/CartService";
import { useDispatch } from "react-redux";

interface IProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  setCompareProduct: any;
  compareProduct: TProduct[];
  products: TProduct[];
}
export type TProduct = {
  categories: [];
  discounts: [];
  attributeValues: [{ id: string; name: string; value: string }];
  exchangePoint: number;
  id: string;
  images: [];
  name: string;
  price: number;
  sku: string;
  subDescription: string;
};

const TdPlaceHolder = ({ style, children, type }: any) => {
  if (type === 1) {
    return <td style={{ ...style, width: "600px", textAlign: "center" }}>{children}</td>;
  }
  return (
    <>
      <td style={{ ...style, width: "600px", textAlign: "center" }}>{children}</td>
      <td style={{ ...style, width: "600px", textAlign: "center" }}>{children}</td>
    </>
  );
};
const ModalCompare = (props: IProps) => {
  const { t } = useTranslation();
  const { visible, setVisible, compareProduct, products } = props;
  console.log(products, "39");
  const [modalProductVisible, setModalProductVisible] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(compareProduct, "48");
  }, [compareProduct]);
  return (
    <Modal
      maskStyle={{ backgroundColor: "black" }}
      className="top-18"
      zIndex={999}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0, overflow: "auto", height: "90vh" }}
      width={"100vw"}
      visible={visible}
      onCancel={() => setVisible(false)}>
      <Modal
        zIndex={2003}
        title={<div className="text-center">{t("Choose product")}</div>}
        onCancel={() => setModalProductVisible(false)}
        visible={modalProductVisible}>
        <List
          dataSource={products}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => {
                    props.setCompareProduct((oldProduct: TProduct[]) => {
                      if (oldProduct.length > 0 && oldProduct.find((prd) => prd.id === item.id)) {
                        return [...oldProduct];
                      }
                      return [...oldProduct, { ...item }];
                    });
                    setModalProductVisible(false);
                  }}
                  icon={<PlusOutlined />}
                />,
              ]}>
              <List.Item.Meta
                avatar={
                  <img
                    src={Common.getImageThumbnail(item.images, "?mode=crop&width=60&height=60")}
                    alt="prd"
                  />
                }
                title={item.name}
              />
            </List.Item>
          )}
        />
      </Modal>

      <Table>
        <CloseIconBtn onClick={() => setVisible(false)}>
          <CloseOutlined />
        </CloseIconBtn>
        <thead>
          <tr>
            <th style={{ backgroundColor: "#F8F8F8" }}></th>
            {compareProduct.map((item) => {
              return <th className="p-4 border-l-2 border-r-2">{item.name}</th>;
            })}
            {compareProduct.length === 1 && (
              <>
                <th style={{ width: "600px" }}></th>
                <th style={{ width: "600px" }}></th>
              </>
            )}
            {compareProduct.length === 2 && (
              <>
                <th style={{ width: "600px" }}></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ borderTop: "none",width:"200px" }}>{t("Image")}</td>
            {compareProduct.map((item) => {
              return (
                <td style={{ borderTop: "none" }} className="border-t-0">
                  <img
                    src={Common.getImageThumbnail(item.images, "?mode=crop&width=260&height=260")}
                    alt="product"
                  />
                </td>
              );
            })}
            {compareProduct.length < 3 && (
              <TdPlaceHolder type={compareProduct.length === 2 && 1} style={{ borderTop: "none" }}>
                <Button onClick={() => setModalProductVisible(true)}>+</Button>
              </TdPlaceHolder>
            )}
          </tr>
          <tr>
            <td>Sku</td>
            {compareProduct.map((item) => {
              return <td>{item.sku}</td>;
            })}
            {compareProduct.length < 3 && <TdPlaceHolder type={compareProduct.length === 2 && 1} />}
          </tr>
          <tr>
            <td>Price</td>
            {compareProduct.map((item) => {
              return (
                <td style={{ color: "rgb(32 173 150 / 81%)" }}>{Common.formatMoney(item.price)}</td>
              );
            })}
            {compareProduct.length < 3 && <TdPlaceHolder type={compareProduct.length === 2 && 1} />}
          </tr>
          <tr>
            <td>Add to cart</td>
            {compareProduct.map((item) => {
              return (
                <td>
                  {" "}
                  <AddToCartBtn onClick={() => addItemToCart({ productId: item.id, dispatch, t })}>
                    {t("Add to cart")}
                  </AddToCartBtn>
                </td>
              );
            })}
            {compareProduct.length < 3 && <TdPlaceHolder type={compareProduct.length === 2 && 1} />}
          </tr>
          <tr>
            <td>description</td>
            {compareProduct.map((item) => {
              return <td>{item.subDescription || "N / A"}</td>;
            })}
            {compareProduct.length < 3 && <TdPlaceHolder type={compareProduct.length === 2 && 1} />}
          </tr>
          {compareProduct.length > 0 &&
            compareProduct[0].attributeValues.map((item) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  {compareProduct.map((prd) => {
                    return (
                      <td>
                        {prd.attributeValues.find((att) => att.name === item.name)?.value ||
                          "N / A"}
                      </td>
                    );
                  })}
                  {compareProduct.length < 3 && (
                    <TdPlaceHolder type={compareProduct.length === 2 && 1} />
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ModalCompare;
