import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import _ from "lodash";

export function Observable(data) {
  let cloneData = _.cloneDeep(data);
  let ob = observable(cloneData);
  ob.reset = () => {
    let oldValue = _.cloneDeep(ob);
    _.forEach(_.cloneDeep(cloneData), (v, k) => {
      ob[k] = v;
    });
    if ("reset" in data) {
      delete oldValue.reset;
      data.reset(ob, oldValue);
    }
  };
  return ob;
}

export function Observer(component) {
  return observer(component);
}
