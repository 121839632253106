export const LOAD_ALL_ARTICLE = "LOAD_ALL_ARTICLE";
export const LOAD_ALL_CATEGORY_ARTICLE = "LOAD_ALL_CATEGORY_ARTICLE";
export const UPDATE_INFO_USER = "UPDATE_INFO_USER";
export const PASS_DATA_MODAL_CALLBACK = "PASS_DATA_MODAL_CALLBACK";
export const BREADCRUMB = "UPDATE_BREADCRUMB";
export const HEADERTRANPARENT = "HEADER_TRANPARENT";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const setHeaderTranparent = (data) => ({
    type: HEADERTRANPARENT,
    data,
});

export const updateBreadcrumb = (data) => ({
    type: BREADCRUMB,
    data,
});

export const passDataModalCallBack = (data) => ({
    type: PASS_DATA_MODAL_CALLBACK,
    data,
});

export const updateInfoUser = (data) => ({
    type: UPDATE_INFO_USER,
    data,
});
export const updateNotification = (data) => ({
    type: UPDATE_NOTIFICATION,
    data,
});

export const loadAllArticle = (data) => ({
    type: LOAD_ALL_ARTICLE,
    data,
});
export const loadAllCategoryArticle = (data) => ({
    type: LOAD_ALL_CATEGORY_ARTICLE,
    data,
});

const initialState = {
    notifications: [],
    allArticle: [],
    allCategoryArticle: [],
    breadcrumb: {},
    infoUser: {},
    headerTranparent: false,
    dataModalCallBack: {
        // type: "",
        isOpen: false,
        name: "",
        message: "",
        callBack: "",
    },
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HEADERTRANPARENT:
            return {
                ...state,
                headerTranparent: action.data,
            };
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: action.data,
            };
        case BREADCRUMB:
            return {
                ...state,
                breadcrumb: action.data,
            };
        case PASS_DATA_MODAL_CALLBACK:
            return {
                ...state,
                dataModal: action.data,
            };
        case LOAD_ALL_ARTICLE:
            return {
                ...state,
                allArticle: action.data,
            };
        case LOAD_ALL_CATEGORY_ARTICLE:
            return {
                ...state,
                allCategoryArticle: action.data,
            };
        case UPDATE_INFO_USER:
            return {
                ...state,
                infoUser: action.data,
            };
        default:
            return state;
    }
}
