import { styled } from "../../../../../../Utils/stitches.config";
import {
  borderColor,
  borderInputColor,
  hintTextColor,
  infoTextColor,
  primaryColor,
  primaryTextColor,
  secondaryColor,
} from "../../../../../../Utils/themeConfig";

export const StyledContainer = styled("div", {
  marginTop: 64,
  color: primaryTextColor,
  h1: {
    fontSize: 32,
    lineHeight: "39px",
    marginTop: 16,
    marginBottom: 16,
  },
});
export const StyledBackButton = styled("button", {
  color: infoTextColor,
  i: {
    display: "inline-block",
    marginRight: 8,
  },
  padding: 0,
  margin: 0,
  border: 0,
  background: "unset",
});
export const StyledPreviewContainer = styled("div", {});
export const StyledPreviewItem = styled("span", {
  display: "inline-block",
  marginBottom: 16,
  marginRight: 32,
  "&:not(:first-child)": {
    // marginLeft: 32,
  },
  svg: {
    display: "inline-block",
    marginRight: 4,
  },
  span: {
    fontSize: 14,
    lineHeight: "17px",
    color: hintTextColor,
    display: "inline-block",
  },
  variants: {
    direction: {
      start: {
        span: {
          marginRight: 4,
        },
      },
      end: {
        span: {
          marginLeft: 4,
        },
      },
    },
  },
});
export const StyledBody = styled("div", {
  marginTop: 40,
});
export const StyledCollapseHeader = styled("div", {
  "div:last-child": {
    fontSize: 12,
    lineHeight: "15px",
    color: hintTextColor,
  },
});
export const StyledBuy = styled("div", {
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
  position: "sticky",
  top: 100,
  borderRadius: 8,
  padding: 24,
  paddingBottom: 8,
  img: {
    display: "block",
    borderRadius: 8,
    width: "100%",
  },
  button: {
    border: 0,
    background: primaryColor,
    borderRadius: 8,
    fontSize: 16,
    lineHeight: "22px",
    color: "#FFFFFF",
    padding: 16,
    display: "block",
    width: "100%",
    marginTop: 16,
    fontWeight: 600,
  },
});
export const StyledBuyInfoItem = styled("div", {
  padding: 16,
  "&:not(:first-child)": {
    borderTop: "1px solid " + borderColor,
  },
  span: {
    display: "inline-block",
    marginLeft: 12,
  },
  cursor: "pointer",
});
export const StyledCollapseContainer = styled("div", {
  marginLeft: -16,
  ".ant-collapse": {
    border: 0,
    background: "unset",
  },
  ".ant-collapse > .ant-collapse-item": {
    borderBottom: 0,
  },
});
export const StyledCollapseItem = styled("li", {
  marginTop: 8,
  i: {
    display: "inline-block",
    width: 16,
    marginRight: 8,
  },
});
export const StyledReviewBox = styled("form", {
  display: "block",
  textarea: {
    display: "block",
    background: "#FFFFFF",
    borderColor: borderInputColor,
    minHeight: 96,
  },
  label: {
    marginTop: 24,
  },
});
export const StyledStarCounter = styled("div", {
  ".StyledStarCounter-value": {
    display: "inline-block",
    marginLeft: 12,
    fontWeight: "bold",
  },
  label: {
    fontSize: 16,
    lineHeight: "20px",
    display: "block",
    fontWeight: "bold",
  },
});
export const StyledButton = styled("button", {
  border: 0,
  borderRadius: 8,
  fontSize: 16,
  lineHeight: "20px",
  padding: "16px 24px",
  color: primaryTextColor,
  fontWeight: 600,
  background: "#EFF0F1",
  "&:hover":{
    cursor: "pointer"
  },
  variants: {
    variant: {
      secondary: {
        background: secondaryColor,
        color: "#FFFFFF",
      },
      primary: {
        background: primaryColor,
        color: "#FFFFFF",
      },
    },
    state: {
      lock: {
        cursor: "no-drop",
        pointerEvents: "none",
      },
    },
    display: {
      block: {
        display: "block",
        width: "100%",
      },
    },
  },
});
export const StyledTransactionItem = styled("div", {
  marginBottom: 32,
  fontSize: 14,
  img: {
    width: 48,
    height: 48,
    objectFit: "cover",
    borderRadius: "50%",
    display: "block",
  },
  td: {
    verticalAlign: "top",
    div: {
      marginTop: 8,
    },
  },
});
export const StyledCommentContainer = styled("div", {
  paddingBottom: 32,
});
