/* eslint-disable */
import React from "react";
import Swal from "sweetalert2";
import Common from "../../../../Utils/Common";
import { LoginBackground } from "./LoginPage";
import { Link } from "react-router-dom";

class RecoveryPassword extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("userIzzi") !== null) {
      window.location.href = "/";
    }

    this.state = {
      username: "",
      name: "",
      newPassword: "",
      rePassword: "",
      mobile: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;

    var id = Common.guid();
    let objData = {
      Id: id,
      Domain: window.Merchant.data.code,
      Email: email,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: id,
    };
    Common.sendSyncCommand(
      "RecoveryPasswordByEmail",
      "CustomerRelationshipManagement",
      objData,
      () => {
        Swal.fire("New password has been sent to your email.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      },
      () => {
        Swal.fire("Lấy lại mật khẩu thất bại!");
      }
    );
  }

  render() {
    const { email, newPassword, submitted, name, mobile, rePassword } = this.state;
    const merchant = window.Merchant.data;
    const izziMember = JSON.parse(merchant.izziMember || "{}");

    return (
      <div
        uk-height-viewport=""
        className="uk-flex uk-flex-middle"
        style={{ minHeight: "calc(100vh);" }}>
        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded" style={{ marginTop: "62px" }}>
          <div className="uk-child-width-1-2@m uk-grid-collapse bg-gradient-grey uk-grid" uk-grid>
            {/* column one */}
            <div className="uk-text-center uk-animation-scale-up p-3 uk-light uk-first-column dx-relative">
              {/*<i className=" uil-graduation-hat icon-large" />*/}
              {/*<h3 className="mb-4"> Izzi Learn</h3>*/}
              {/*<p>Nơi mà bạn có thể học mọi thứ. </p>*/}
              <LoginBackground />
            </div>
            {/* column two */}
            <div className="uk-card-default p-5 rounded">
              <div className="mb-4 uk-text-center">
                <h3 className="mb-0"> Bạn quên mật khẩu?</h3>
                <p className="my-2">Điền form dưới đây để khôi phục tài khoản của bạn.</p>
              </div>
              <form
                id="formSignUp"
                onSubmit={this.handleSubmit}
                name="form"
                className="uk-child-width-1-1 uk-grid-small uk-grid">
                <div className="uk-grid-margin uk-first-column">
                  <div className="uk-form-group">
                    <label className="uk-form-label"> Email</label>
                    <div className="uk-position-relative w-100">
                      <span className="uk-form-icon">
                        <i className="icon-feather-mail" />
                      </span>
                      <input
                        className="uk-input"
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="name@example.com"
                        required
                      />
                      {submitted && !email && (
                        <div className="help-block">Email là trường bắt buộc.</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 uk-flex-middle uk-grid-small uk-grid" uk-grid>
                  <div className="uk-width-expand@s uk-first-column">
                    <p>
                      {" "}
                      Trở về trang đăng nhập tại{" "}
                      <Link to="/login">
                        <b>đây</b>
                      </Link>
                    </p>
                  </div>
                  <div className="uk-width-auto@s">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#484b77", color: "#fff" }}
                      className="btn btn-default">
                      Khôi phục
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*  End column two */}
          </div>
        </div>
      </div>
    );
  }
}

export default RecoveryPassword;
