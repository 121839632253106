import TextArea from 'antd/lib/input/TextArea';
import React , {useEffect, useState} from 'react'
import Dragger from 'antd/lib/upload/Dragger';
import { message } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import axios from "axios"
import { getCommonStorage } from '../../../../../../Config/gql';
import _ from "lodash"

const ShortAnswer = ({state,question,answer,setState}) => {
    const [files, setFiles] = useState([]);
    const handleUploadFile = async (value) => {
        const formData = new FormData();
    
        const reader = new FileReader();
        const {tokenId} = getCommonStorage()
        reader.readAsBinaryString(value.file);
        formData.append("UploadedFile", value.file);
        formData.append("token", tokenId);
    
        try {
          await axios.post("https://apicommand.izzi.asia/File/UploadFile", formData).then((data) =>
            setFiles([
              ...files,
              ...data.data.Data.map((fileData) => ({
                id: fileData.Id,
                name: fileData.Name,
                path: fileData.Path,
              })),
            ]),
          );
          if (value.onSuccess) {
              
            value.onSuccess(message.success("Upload File thành công"));
          } else {
            value.onError(message.success("Upload File không thành công,File cần có định dạng pdf"));
          }
        } catch (error) {
          message.error(error.message);
        }
      };
      useEffect(()=>{
        

        let answers = _.cloneDeep(state.answers);
        if (!answers[question.id]) answers[question.id] = {};
        if(!   answers[question.id][answer.id]?.text){

            answers[question.id][answer.id] = {files:files};
        }
        else{
            answers[question.id][answer.id]  = {...answers[question.id][answer.id],files:files}
         
        }
        setState({ answers });
            
      },[files])
      useEffect(()=>{
        console.log(state.answers,"46")
      },[state.answers])
  return (
    <>
          <TextArea
              

                onChange={(e) => {
                  let answers = _.cloneDeep(state.answers);
                  if (!answers[question.id]) answers[question.id] = {};
                  if(!answers[question.id][answer.id]?.files){

                    answers[question.id][answer.id] = {text:e.target.value};
                }else{
                    answers[question.id][answer.id] = {...answers[question.id][answer.id],text:e.target.value};
                }
                 
                  setState({ answers });
                }}
                rows={4}
                placeholder={"Viết câu trả lời..."}
                style={{ borderRadius: 4 }}
                value={state.answers[question.id] ? state.answers[question.id][answer.id].text : null}
              />
              <Dragger
                onRemove={(e) =>
                  setFiles((files) => {
                    files.filter((file) => file.id === e.id);
                  })
                }
                style={{marginTop:"20px"}}
                fileList={files}
                accept=".pdf,.doc,.docx,.png,.jpeg,.gif"
                multiple={true}
                customRequest={handleUploadFile}
              >
                <p className="ant-upload-drag-icon">
                <FileTextOutlined />
                </p>
                <p className="ant-upload-text">Thêm câu trả lời của bạn</p>
              </Dragger>
    </>
  )
}

export default ShortAnswer