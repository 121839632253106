import { Cart } from "../../Config/Constants/Cart";
import { getCart } from "../Services/CartService";

export const getCartAction = (data) => {
  return {
    type: Cart.GET_CART,
    payload: data
  };
};
export const updateCartItem = ({type,id})=>{
  return {
    type: Cart.UPDATE_ITEM_CART,
    payload:{type,id}
  }
}
export const addItemToWishListAction = (data,navigate)=>{
  return {
    type: Cart.ADD_ITEM_TO_WISHLIST,
    payload: {data,navigate}
  }
}
export const deleteItemWishListAction = (id)=>{
  return {
    type:Cart.DELETE_ITEM_WISHLIST,
    payload:id
  }
}
export const getWishListAction = (data)=>{
  return {
    type:Cart.GET_WISHLIST,
    payload: data
  }
}
