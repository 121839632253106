import React from "react";
import { BasePage } from "../../ComponentCommon/BasePage";
import Configuration from "../../../../Config/Config";
import { useQuery } from "urql";
import { QUERY_PRODUCTS } from "../Product/maxcoach/Products/gql";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import { createOrderAction } from "../../../Actions/Order";
import { useSetState } from "react-use";
import { QUERY_CONTESTS } from "./gql";
import { RenderPagination } from "../Product/maxcoach/Products";
import { Link } from "react-router-dom";
import { Badge, Spin, Tag } from "antd";
import moment from "moment";

export default function Contest() {
  const [pageState, setPageState] = useSetState({
    current: 0,
    limit: 6,
  });

  const [fetchContests] = useQuery({
    query: QUERY_CONTESTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";
      let type = 1;
      let published = true;
      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        tokenUser: commonStorage.tokenId,
        limit: pageState.limit,
        offset: pageState.limit * pageState.current,
        sort,
        order,
        type,
        published,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const total = fetchContests.data?.membercontests?.totalCount || 0;
  const isEmpty = total === 0;

  return (
    <BasePage title={"Contests"}>
      <Spin spinning={fetchContests.fetching}>
        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
          {fetchContests.data
            ? fetchContests.data.membercontests.items.map((contest: any) => {
                const { startDate, endDate } = contest;
                return (
                  <div className="col max-mb-30">
                    <div className="event">
                      <div className="thumbnail">
                        <a href="event-details.html" className="image">
                          <img
                            src={Common.getImageThumbnail(
                              contest.images,
                              "?Mode=crop&width=480&height=298"
                            )}
                            alt="Event"
                          />
                        </a>
                        <div className="event-overlay-background" />
                        <div className="event-overlay-content">
                          <Link
                            to={`/contest/${contest.id}`}
                            className={"btn btn-md btn-light btn-hover-light theme-color"}>
                            Chi tiết
                          </Link>
                          {/*<a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>*/}
                        </div>
                      </div>
                      <div className="info">
                        <span className="date">
                          {Common.formatDateTime(startDate, "dd/mm/yyyy hh:mm")} -{" "}
                          {Common.formatDateTime(endDate, "dd/mm/yyyy hh:mm")}
                        </span>
                        <h3 className="title">
                          <a href={`/contest/${contest.id}`}>{contest.name}</a>
                        </h3>
                        <div>
                          {contest.status == "Closed" ? (
                            <Tag color="blue" title="">
                              Đã kết thúc
                            </Tag>
                          ) : contest.status == "OnGoing" ? (
                            <div>
                              {contest.endDate &&
                              moment(contest.endDate).diff(moment(), "seconds") <= 0 ? (
                                <Tag color="grey" title="">
                                  Đã hết thời gian nộp bài
                                </Tag>
                              ) : contest.startDate && moment(contest.startDate).diff(moment(), "seconds") > 0 ? (
                                <Tag color="grey" title="">
                                  Chưa bắt đầu
                                </Tag>
                              ) : (
                                <Tag color="green" title="">
                                  Đang diễn ra
                                </Tag>
                              )}
                            </div>
                          ) : (
                            <Tag color="orange" title="">
                              Đang chấm bài
                            </Tag>
                          )}
                        </div>
                        <div>{contest.subDescription}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="row max-mt-50" hidden={isEmpty || fetchContests.fetching}>
          <div className="col">
            <RenderPagination
              currentPage={pageState.current}
              totalItem={total}
              itemPerPage={pageState.limit}
              handlePageChange={(v) => {
                setPageState({ current: v });
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
      </Spin>
    </BasePage>
  );
}
