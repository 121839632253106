import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  Row,
  Empty,
  Divider,
  DatePicker,
  Space,
  Checkbox,
  Tag,
  Modal,
  Typography,
  Popover,
} from "antd";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import DNModal from "../DnStyles/Modal";
import {
  globalExperiences,
  functionals,
  positions,
  getFunctionals,
  getPosition,
  getGlobalExperiences,
} from "./data";
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
import { calculateTotal } from "./ultils";
import { exec } from "../../../../Utils/dn";
import { getCommonStorage } from "../../../../Config/gql";
import { QueryCompanyAddress, QueryWorkExperiences, QueryWorkExperience } from "./gql";
import { useQuery } from "urql";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { ContentPopupLanguage } from "../../ComponentCommon/PopoverContentLanguage";

interface IFormData {
  title: string;
  roles: string;
  position: number;
  functional: number[];
  globalExperiences: number[];
  startDate: moment.Moment;
  endDate: moment.Moment;
  users: Array<{
    Mobile: string;
    Name: string;
  }>;
  company: number;
}

interface IFormModalProps {
  state: {
    isEdit: boolean;
    isModalVisible: boolean;
    loading: boolean;
  };
  setState: any;
  editLanguageId: string;
  editId: string;
  refetchWorkExperiences: any;
  companies: any
}

const FormModal = ({
  state,
  setState,
  editId,
  refetchWorkExperiences,
  editLanguageId,
  companies
}: IFormModalProps) => {
  const { t } = useTranslation();
  const [id] = useState(v4());
  const { Option } = Select;
  const [experienceForm] = Form.useForm();
  const [startDate, setStartDate] = useState();
  const [isEndDateDisable, setIsEndDateDisable] = useState(false);

  const [WorkExperiencesData] = useQuery({
    query: QueryWorkExperience,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        languageId: editLanguageId,
        merchantId: merchantId,

        id: editId,
      };
    })(),
    requestPolicy: "network-only",
  });
  
  const _onFinish = (experience: IFormData) => {
    const { userId,languageId } = getCommonStorage();
    setState({
      loading: true,
    });

    console.log("line 80", experience);

    exec(
      "WorkExperience",
      state.isEdit ? "UpdateInformationWorkExperience" : "CreateWorkExperience",
      {
        Id: state.isEdit ? editId : v4(),
        Name: experience.title,
        RolesResponsibility: experience.roles,
        Position: calculateTotal([experience.position]),
        Functionals: calculateTotal(experience.functional),
        
        GlobalExperiences: calculateTotal(experience.globalExperiences),
        FromDateTime: experience.startDate.format("YYYY-MM-DD"),
        ToDateTime: isEndDateDisable ? null : experience.endDate.format("YYYY-MM-DD"),
        UserReferees: experience.users,
        CompanyId: experience.company,
        TargetId: userId,
        LanguageId: editId ? editLanguageId : languageId,
      },
      ["actionBy", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchWorkExperiences();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };
  useEffect(() => {
    if (WorkExperiencesData.data?.workexperience) {
      console.log("run 102");
      const experience = WorkExperiencesData.data.workexperience;
      setIsEndDateDisable(experience.toDateTime ? false : true);
      experienceForm.setFieldsValue({
        title: experience.name,
        functional: getFunctionals(experience.functionals).map((i: any) => i.value),
        position: getPosition(experience.position).map((i: any) => i.value),
        company: experience?.companies[0]?.id,
        startDate: moment(experience.fromDateTime, "YYYY-MM-DD"),
        endDate: experience.toDateTime ? moment(experience.toDateTime, "YYYY-MM-DD") : null,

        globalExperiences: getGlobalExperiences(experience.globalExperiences).map(
          (i: any) => i.value
        ),
        roles: experience.rolesResponsibility,
        users: JSON.parse(experience.userReferees),
      });
    }
  }, [WorkExperiencesData.data?.workexperience]);
  return (
    <DNModal
      afterClose={() => {
        experienceForm.resetFields();
        setIsEndDateDisable(false);
      }}
      title={state.isEdit ? t("Edit") : t("Experience")}
      visible={state.isModalVisible}
      onCancel={() => {
        setState({ isModalVisible: false });
      }}
      okButtonProps={{
        form: id,
        htmlType: "submit",
      }}
      confirmLoading={state.loading}>
      <Form form={experienceForm} id={id} onFinish={_onFinish} layout={"vertical"}>
        <Form.Item name={"company"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Company")}>
            {companies?.length > 0 &&
              companies.map((company: any, i: number) => (
                <Option key={i} value={company.id}>
                  {company.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name={"title"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("title.WorkExperience")} />
        </Form.Item>
        <Form.Item name={"position"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Position")}>
            {positions.map((position, i) => (
              <Option key={i} value={position.value}>
                {position.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"functional"}
          rules={[
            { required: true, message: t("Please Select") },
            { max: 3, type: "array", message: "Tối đa 3 loại hình" },
          ]}>
          <Select placeholder={"Khả năng"} mode="multiple">
            {functionals.map((functional, i) => (
              <Option key={i} value={functional.value}>
                {functional.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: t("Please Select") },
            { max: 3, type: "array", message: "Tối đa 3 loại hình" },
          ]}
          name={"globalExperiences"}>
          <Select placeholder={"Kinh nghiệm toàn cầu"} mode="multiple">
            {globalExperiences.map((globalExperience, i) => (
              <Option key={i} value={globalExperience.value}>
                {globalExperience.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="roles">
          <TextArea placeholder="Vị trí và vai trò" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={7}>
            <Form.Item rules={[{ required: true, message: t("Please Select") }]} name="startDate">
              <DatePicker
                onChange={(e: any) => {
                  experienceForm.setFieldsValue({
                    endDate: e,
                  });
                  setStartDate(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              rules={[
                {
                  required: isEndDateDisable ? false : true,
                  message: isEndDateDisable ? "" : t("Please Select"),
                },
              ]}
              name="endDate">
              <DatePicker
                disabledDate={(current) => {
                  return current < startDate!;
                }}
                disabled={isEndDateDisable ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Checkbox
              style={{ marginTop: "4px" }}
              checked={isEndDateDisable}
              onChange={() => setIsEndDateDisable(!isEndDateDisable)}
            />
            <Typography.Text className="ml-1">{t("Still Current")}</Typography.Text>
          </Col>
        </Row>

        <Form.List name="users">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  {t("Add Referee")}
                </Button>
              </Form.Item>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, "Name"]}
                    rules={[{ required: true, message: t("Name") + t("cannot be blank") }]}>
                    <Input placeholder={t("Name")} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Mobile"]}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp("(84|0[3|5|7|8|9])+([0-9]{8})"),
                        message: "Số điện thoại không đúng định dạng , Ví dụ : 0346556026",
                      },
                    ]}>
                    <Input type="number" placeholder={t("Phone Number")} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </DNModal>
  );
};
const WorkExperience = () => {
  const { t } = useTranslation();
  const [experienceForm] = Form.useForm();
  const [editLanguageId, setEditLanguageId] = useState("");

  const [editId, setEditId] = useState("");
  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const [WorkExperiencesDatas, refetchWorkExperiences] = useQuery({
    query: QueryWorkExperiences,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 0,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const [companyAddressDatas] = useQuery({
    query: QueryCompanyAddress,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 16,
      };
    })(),
    requestPolicy: "network-only",
  });
  const companies = companyAddressDatas.data?.data?.items || [];
  const expericences = WorkExperiencesDatas.data?.data?.items || [];

  const _onClickEdit = (experience: any, lang: string) => {
    setEditId(experience.id);
    setEditLanguageId(lang);

    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec("WorkExperience", "DeleteWorkExperience", {
          Id: id,
        }).finally(refetchWorkExperiences);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };

  return (
    <Fragment>
      <BasePage title={t("Work Experience")} hideTitle={true}>
        <Card
          title={t("Work Experience")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                experienceForm.resetFields();

                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={WorkExperiencesDatas.fetching}>
          {expericences.length === 0 && (
            <Empty
              description={
                <span>
                  Chưa có kinh nghiệm làm việc
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {expericences?.length > 0 &&
            expericences?.map((experience: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Company")}:
                      </Col>
                      <Col span={20}>{experience?.companies[0]?.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("title.WorkExperience")}:
                      </Col>
                      <Col span={20}>{experience.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Position")}
                      </Col>
                      <Col span={20}>
                        {getPosition(experience.position).map((value: any, index: number) => (
                          <Tag color="green" key={index}>
                            {value.name}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Functional")}
                      </Col>

                      <Col span={20}>
                        {getFunctionals(experience.functionals).map((value: any) => (
                          <Tag key={value.value} color="geekblue">
                            {value.name}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Global Experience")}
                      </Col>
                      <Col span={20}>
                        {getGlobalExperiences(experience.globalExperiences).map((value: any) => (
                          <Tag key={value.value} color="orange">
                            {value.name}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Roles & Responsibility")}
                      </Col>
                      <Col span={20}> {experience.rolesResponsibility} </Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("year.Join")}
                      </Col>
                      <Col span={20}>
                        {moment(experience.fromDateTime).format("YYYY-MM-DD")} --
                        {experience.toDateTime
                          ? moment(experience.toDateTime).format("YYYY-MM-DD")
                          : "Hiện tại"}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Popover
                        placement="bottom"
                        trigger="hover"
                        content={<ContentPopupLanguage edit={_onClickEdit} data={experience} />}>
                        <Button type={"link"}>Sửa</Button>
                      </Popover>
                      <Button type={"link"} danger={true} onClick={() => _remove(experience.id)}>
                        Xóa
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
        {state.isModalVisible && (
          <FormModal
            companies={companies}
            editLanguageId={editLanguageId}
            editId={editId}
            setState={setState}
            state={state}
            refetchWorkExperiences={refetchWorkExperiences}
          />
        )}
      </BasePage>
    </Fragment>
  );
};

export default WorkExperience;
