import { gql } from "urql";

export const QUERY_USER = gql`
  query QueryUser($userId: String!, $merchantId: String!) {
    user(param: { id: $userId, merchantId: $merchantId }) {
      id
      userName
      name
      email
      mobile
      allType
      firstName
      lastName
      dateOfBirth
      companyName
      companySite
      subDescription
      description
      createdDate
      images {
        id
        path
        displayOrder
        isFeatured
      }
      categories {
        id
        name
      }
      createdDate
    }
  }
`;
