import { styled } from "../../../../../../Utils/stitches.config";

export const CustomInput = styled("div", {
  "input.no-value": {
    background: "rgb(245, 245, 245)",
    border: "1px solid rgb(245, 245, 245)",
    boxShadow: "unset",
  },
});
export const ClearAll = styled("div", {
  color: "var(--primary-text-color)",
  textDecoration: "underline",
  fontSize: 14,
  lineHeight: "18px",
  paddingTop: 20,
  clear: "both",
  cursor: "pointer",
  ":hover": {
    fontWeight: "bold",
  },
  display: "inline-block",
});
export const NoItems = styled("div", {
  padding: 15,
  h3: {
    fontSize: 24,
    lineHeight: "29px",
    color: "var(--primary-text-color)",
    marginBottom: 16,
  },
  "h3:last-child": {
    fontSize: 18,
    lineHeight: "22px",
  },
  p: {
    fontSize: 18,
    lineHeight: "32px",
    color: "var(--primary-text-color)",
    marginBottom: 24,
  },
  button: {
    marginBottom: 32,
  },
  ".btn-primary": {
    background: "var(--primary-color)",
    borderColor: "var(--primary-color)",
  },
  hr: {
    borderColor: "#EFF0F1",
    borderWidth: 1,
    margin: 0,
    marginBottom: 32,
  },
});
