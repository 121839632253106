/* eslint-disable */
import React, { Fragment } from "react";
import Player from "../components/Player";
import ProgressVideo from "../components/ProgressVideo";
import { getDataQuizVideo } from "../service";
import Configuration from "../../../../../Config/Config";
import Common from "../../../../../Utils/Common";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import DetailQuiz from "./DetailQuiz";

// import { useSetState } from 'react-use';

let interval = 0;
let ratio = 0;
let dataQuestionUserDo = [];
let guid = Common.guid();
class Preview extends React.Component {
    state = {
        player: {},
        dataQuiz: {},
        dataPassTooltip: {},
        isOpenTooltipPreview: false,
        stateArr: [],
    };
    startTransactionQuizVideo() {
        let dataStartQuiz = {
            Id: guid,
            QuizId: this.props.id,
            UserId: localStorage.getItem(Configuration.userId),
            CommandInformation: navigator.userAgent,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem(Configuration.userId),
        };
        Common.sendSyncCommand(
            "StartQuiz",
            "CustomerRelationshipManagement",
            dataStartQuiz,
            () => console.log("thanh cong"),
            () => {
                Swal.fire("Có lỗi xảy ra!").then((result) => {
                    if (result.value) {
                        window.location.href = "/quiz";
                    }
                });
            }
        );
    }
    componentDidMount() {
        getDataQuizVideo.getDetailQuiz(this.props.id).then((data) => {
            if (data.quiz !== null) {
                let quiz = { ...data.quiz };
                quiz.questions.forEach((element) => {
                    if (element.answers.length) {
                        element.answers.sort((a, b) => a.time - b.time);
                    }
                });
                this.setState(
                    {
                        dataQuiz: quiz,
                        stateArr: quiz.questions,
                    },
                    () => {
                        this.startTransactionQuizVideo();
                    }
                );
            }
        });
    }
    onStatePlayerChange() {
        let { player, stateArr } = this.state;
        let widthProgress = document.getElementById("video-progress").offsetWidth;
        if (player.getPlayerState() == Configuration.stateVideo.PLAYING) {
            clearInterval(interval);
            interval = setInterval(() => {
                let currentTime = player.getCurrentTime();
                stateArr.forEach((element) => {
                    if (parseInt(currentTime) === element.time) {
                        this.props.toggleTooltipQuestion(true);
                        console.log(element);
                        this.setState({
                            dataPassTooltip: element,
                        });
                        player.pauseVideo();
                    }
                });
                document.getElementById("progress-bar").style.width = `${(currentTime / player.getDuration()) * widthProgress}px`;
            }, 1000);
        } else if (player.getPlayerState() == Configuration.stateVideo.PAUSED) {
            clearInterval(interval);
        } else if (player.getPlayerState() == Configuration.stateVideo.ENDED) {
            this.submitQuiz();
        }
    }
    updateAnswerQuestion(data) {
        let { player } = this.state;
        let tempData = dataQuestionUserDo.filter((e) => e.id === data.id);
        if (tempData.length) {
            dataQuestionUserDo = dataQuestionUserDo.map((e) => {
                if (e.id === data.id) {
                    return (e = tempData);
                }
                return e;
            });
        } else {
            dataQuestionUserDo.push(data);
        }
        this.setState({ isOpenTooltipPreview: false });
        player.seekTo(player.getCurrentTime() + 1, true);
        player.playVideo();
    }
    cancelAnswer(data) {
        this.setState({ isOpenTooltipPreview: false });
    }
    submitQuiz(event) {
        event.preventDefault();
        let data = {
            Id: guid, //this.props.match.params.id
            UserId: localStorage.getItem(Configuration.userId),
            UserDeviceToken: "",
            AnswerQuestions: this.props.dataUserDo,
            CommandInformation: navigator.userAgent,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem(Configuration.userId),
            // }
        };

        Common.sendSyncCommand(
            "FinishQuiz",
            "CustomerRelationshipManagement",
            data,
            (data) => {
                Swal.fire("Nộp bài thành công!");
            },
            () => {
                Swal.fire("Có lỗi xảy ra!");
            }
        );
    }
    render() {
        let { player, stateArr, dataQuiz } = this.state;
        let param = dataQuiz.subDescription;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-7 col-12">
                        <div className="App video-container" id="video-container" style={{ width: "640px", height: "360px", position: "relative" }}>
                            {!Common.isEmptyObj(dataQuiz) && (
                                <Fragment>
                                    <Player
                                        paramUrl={param}
                                        onStatePlayerChange={(player) => this.onStatePlayerChange(player)}
                                        getPlayer={(dataPlayer) => this.setState({ player: dataPlayer })}></Player>
                                    {/* <div className="tooltip-quiz" style={{ width: "100%" }}> */}
                                    {Common.isEmptyObj(player) ? null : (
                                        <ProgressVideo
                                            setRatio={(tempRatio) => {
                                                ratio = tempRatio;
                                            }}
                                            player={player}
                                            isPreview={true}
                                            // dataQuestionTooltip={this.state.dataPassTooltip}
                                            arrayDataQuestionQuiz={stateArr}
                                            onStatePlayerChange={(funCallback) => this.onStatePlayerChange(funCallback)}></ProgressVideo>
                                    )}
                                    {/* </div> */}
                                </Fragment>
                            )}
                            {this.props.isOpenQuestion && !Common.isEmptyObj(this.state.dataPassTooltip) && (
                                <span
                                    className="tooltiptext-question"
                                    onMouseMove={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                    id="content-tooltip-question"
                                    style={{
                                        left: `${(this.state.dataPassTooltip.time / player.getDuration()) * 100}%`,
                                    }}>
                                    <DetailQuiz dataQuestion={this.state.dataPassTooltip}></DetailQuiz>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-md-2 col-12">
                        <Button type="submit" color="success" onClick={(event) => this.submitQuiz(event)}>
                            Nộp bài
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapState = (state) => ({
    dataUserDo: state.QuizVideo.dataUserDo,
    isOpenQuestion: state.QuizVideo.isOpenQuestion,
});

const mapDispatch = (dispatch) => ({
    toggleTooltipQuestion: () => dispatch.QuizVideo.toggleTooltipQuestion(),
});

export default connect(mapState, mapDispatch)(Preview);
