import { gql } from "urql";

export const queryForm = gql`
  query Form($id: String = "", $merchantId: String = "") {
    form(param: { id: $id, merchantId: $merchantId }) {
      id
      name
      description
      subDescription
      actions
      typeOnSubmit
      valueOnSubmit
      __typename
    }
  }
`;

export const queryZoom = gql`
  query ZoomMeeting(
    $id: String = ""
    $tokenId: String = ""
    $merchantId: String = ""
    $languageId: String = ""
  ) {
    zoommeeting(
      param: { id: $id, tokenUser: $tokenId, merchantId: $merchantId, languageId: $languageId }
    ) {
      url
      name
      description
      startTime
      duration
      totalParticipants
      invitationUrl
      blogs {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
      }
      books {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
        files {
          id
          name
          contentLength
          contentType
          path
          displayOrder
          isFeatured
        }
      }
      courses {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
      }
      videos {
        id
        name
        subDescription
        createdDate
        modifiedDate
      }
      __typename
    }
  }
`;

export const queryMeeting = gql`
  query ZoomMeeting(
    $id: String = ""
    $tokenId: String = ""
    $merchantId: String = ""
    $languageId: String = ""
  ) {
    meeting(
      param: { id: $id, tokenUser: $tokenId, merchantId: $merchantId, languageId: $languageId }
    ) {
      url
      name
      description
      startTime
      duration
      totalParticipants
      invitationUrl
      blogs {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
      }
      books {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
        files {
          id
          name
          contentLength
          contentType
          path
          displayOrder
          isFeatured
        }
      }
      courses {
        id
        name
        subDescription
        createdDate
        modifiedDate
        images {
          id
          path
          displayOrder
          isFeatured
        }
      }
      videos {
        id
        name
        subDescription
        createdDate
        modifiedDate
      }
      __typename
    }
  }
`;
