import React, { useEffect } from "react";
import { Observer } from "../../../../../../Utils/Ob";
import { useMount, useSetState } from "react-use";
import { setBreadcrumbAction, setHeaderTranparentAction } from "../../../../../Actions/Layout";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import { Skeleton, message } from "antd";
import Common from "../../../../../../Utils/Common";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import { primaryTextColor } from "../../../../../../Utils/themeConfig";
import HeaderScene from "../../../../ComponentCommon/HeaderScene";
import moment from "moment";
import { StyledShare, StyledTag } from "./styledComponents";
import { RenderIcons } from "../../../../ComponentCommon/ShareDialog";
import { useQuery } from "urql";
import { queryPermissionArticles } from "../PublicBlog/gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { FetchingItemsEffect } from "../../../Course/maxcoach/UserCourse";
import { SideWidget } from "../../../Product/maxcoach/Products";
import { RenderItem } from "../PublicBlog";
import { useTranslation } from "react-i18next";

function BlogDetail(props) {
  const {t} = useTranslation()
  const [state, setState] = useSetState({
    success: false,
    loading: false,
  });
  const [article, setArticle] = useSetState({
    name: "",
    subDescription: "",
    description: "",
    files: [],
    images: [],
    authors: [],
    categories: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    setState({ success: false, loading: true });
    window.showPageLoading();

    QueryGraphQL.getArticleMemberById(props.match.params.id)
      .then((data) => {
        setArticle(data.articlemember);
        setState({ success: true });
      })
      .catch(() => {
        message.error("Có lỗi xảy ra!");
      })
      .finally(() => {
        setState({ loading: false });
        window.hidePageLoading();
      });
  }, [props.match.params.id]);

  // if (!state.success) return null;

  let image = Common.getImageThumbnail(article.images, "?mode=crop&width=1833&height=500");

  console.log(article, "article");

  return (
    <React.Fragment>
      {state.success && (
        <HeaderScene
          image={image}
          title={article.name}
          subTitle={(() => {
            const date = article.postTime != null ? moment(article.postTime).format("MMM DD, YYYY") : moment(article.createdDate).format("MMM DD, YYYY");// moment(article.createdDate).format("MMM DD, YYYY");
            const authors = article.authors.map((v) => v.name).join(", ") || "-";

            return `${t("By")}: ${authors} · ${date}`;
          })()}
          showIntroducing={false}
          logo={false}
          left={true}
        />
      )}
      <div className="section" hidden={state.loading}>
        <div className="container">
          <div
            className="blog-3 blog-details col"
            data-aos="fade-up"
            hidden={state.success}
            style={{ textAlign: "center", border: 0 }}>
            {/*<Skeleton active={true} />*/}
            <ThreeDotsLoader width={60} height={30} fill={primaryTextColor} />
          </div>
          {state.success && (
            <div
              className="blog-3 blog-details col"
              data-aos="fade-up"
              hidden={!state.success}
              style={{ border: 0 }}>
              <div className="desc">
                <span
                  dangerouslySetInnerHTML={{
                    __html: article.description,
                  }}
                />
              </div>
              <div className={"row"}>
                <div className={"col col-lg-6 col-12"}>
                  <div style={{ marginTop: 32 }} hidden={article.categories.length === 0}>
                    <StyledTag border={"no"}>{t("Topic")}:</StyledTag>
                    {article.categories.map((v, i) => {
                      return <StyledTag key={i}>{v.name}</StyledTag>;
                    })}
                  </div>
                </div>
                <StyledShare className={"col col-lg-6 col-12"}>
                  <span style={{ marginTop: 20, display: "inline-block" }}>
                    <StyledTag border={"no"}>{t("Share")}:</StyledTag>
                    <StyledTag border={"no"}>
                      <RenderIcons quote={article.name} />
                    </StyledTag>
                  </span>
                </StyledShare>
              </div>
            </div>
          )}
        </div>
      </div>
      {state.success && <RelatedCourse />}
    </React.Fragment>
  );
}

function RelatedCourse() {
  const {t} = useTranslation()
  const [fetchPermissionArticles] = useQuery({
    query: queryPermissionArticles,
    variables: (() => {
      const { tokenId, merchantId, languageId } = getCommonStorage();

      return {
        tokenUser: tokenId,
        merchantId,
        languageId,
        limit: 4,
        offset: 0,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const articles = fetchPermissionArticles.data?.articles?.items || [];

  return (
    <div className="section section-padding-bottom">
      <div className={"container"}>
        <SideWidget title={t("RELATED ARTICLES")} canExpand={false}>
          <FetchingItemsEffect hidden={!fetchPermissionArticles.fetching} />
          <div
            className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30"
            hidden={fetchPermissionArticles.fetching}>
            {articles.map((article, i) => {
              return <RenderItem article={article} key={i} />;
            })}
          </div>
        </SideWidget>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    allArticle: state.Book.allArticle,
    allCategoryArticle: state.Book.allCategoryArticle,
  };
}

const mapDispatchToProps = {
  // loadAllArticle,
  // loadAllCategoryArticle
  setBreadcrumbAction,
  setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Observer(BlogDetail));
