/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

export default class Aside extends Component {
    render() {
        if (localStorage.getItem("userIzzi") !== null) {
            return (
                <Fragment>
                    {/* side nav*/}
                    <div className="side-nav uk-animation-slide-left-medium">
                        <div className="side-nav-bg" />
                        {/* logo */}
                        <div className="logo uk-visible@s">
                            <Link to="/">
                                <i className=" uil-graduation-hat home-tour-guide" />
                            </Link>
                        </div>
                        <ul>
                            <li>
                                <Link to="/usercourse">
                                    <i className="icon-material-outline-dashboard course-tour-guide" /> <span className="tooltips">Khóa học của tôi</span>
                                </Link>
                                {/* <a href="/usercourse">
                                    <i className="icon-material-outline-dashboard" /> <span className="tooltips">Khóa học của tôi</span>
                                </a> */}
                            </li>
                            <li>
                                <a href="#">
                                    {" "}
                                    <i className="uil-layers library-tour-guide" />{" "}
                                </a>
                                <div className="side-menu-slide">
                                    <div className="side-menu-slide-content">
                                        <ul data-simplebar>
                                            <li>
                                                {/* book */}
                                                <Link to="/course">
                                                    {" "}
                                                    <i className="icon-brand-connectdevelop" /> Learning materials{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                {/* book */}
                                                <Link to="/book">
                                                    {" "}
                                                    <i className="uil-book-alt" /> Sách{" "}
                                                </Link>
                                            </li>
                                            <li>
                                                {/* Blog*/}
                                                <Link to="/slide">
                                                    {" "}
                                                    <i className="uil-window" /> Slide
                                                </Link>
                                            </li>
                                            <li>
                                                {/* Blog*/}
                                                <Link to="/video">
                                                    {" "}
                                                    <i className="uil-youtube-alt" /> Video
                                                </Link>
                                            </li>
                                            <li>
                                                {/* Blog*/}
                                                <Link to="/quiz">
                                                    {" "}
                                                    <i className="icon-material-outline-brush" /> Quiz
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li>
                                {/* Blog*/}
                                <Link to="/blog">
                                    {" "}
                                    <i className="icon-brand-blogger blog-tour-guide" /> <span className="tooltips">Blog</span>
                                </Link>
                            </li>
                            <li>
                                {/* Blog*/}
                                <Link to="/blogAuthor">
                                    {" "}
                                    <i className="icon-brand-blogger-b author-tour-guide" /> <span className="tooltips">Author Blog</span>
                                </Link>
                            </li>
                            <li>
                                {/* Blog*/}
                                <Link to="/calendar">
                                    {" "}
                                    <i className="icon-line-awesome-calendar-check-o calendar-tour-guide" /> <span className="tooltips">Lịch</span>
                                </Link>
                            </li>
                            <li>
                                {/* Blog*/}
                                <Link to="/product">
                                    {" "}
                                    <i className="icon-material-outline-add-shopping-cart product-tour-guide" /> <span className="tooltips">Sản phẩm</span>
                                </Link>
                            </li>
                        </ul>
                        <ul className="uk-position-bottom">
                            <li>
                                {/* Lunch information box */}
                                <a href="#">
                                    <i className="uil-paint-tool theme-tour-guide" />
                                </a>
                                <div uk-drop="pos: right-bottom ;mode:click ; offset: 10;animation: uk-animation-slide-right-small">
                                    <div className="uk-card-default rounded p-0">
                                        <h5 className="mb-0 p-3 px-4  bg-light"> Chế độ đêm</h5>
                                        <div className="p-3 px-4">
                                            <p>Tắt ánh sáng của trang để có trải nghiệm ban tối tốt hơn.</p>
                                            <div className="uk-flex">
                                                <p className="uk-text-small text-muted">DARK THEME </p>
                                                {/* night mode button */}
                                                <span href="#" id="night-mode" className="btn-night-mode">
                                                    <label className="btn-night-mode-switch">
                                                        <span className="uk-switch-button" />
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Fragment>
            );
        }

        return <div></div>;
    }
}
