/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";
import $ from "jquery";

export const getMerchantByCode = (code) => {
    var returnData = [];
    var searchObject = {
        _source: {},
        query: {
            match: {
                code: {
                    query: code,
                },
            },
        },
        sort: [],
    };

    var xhr = new XMLHttpRequest();
    //Starts the variable xhr as the variable for the request
    xhr.open("POST", "https://es.izzi.asia/merchants/merchant/_search?&size=1", false);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Authorization", "Basic " + btoa("amara:dSPKMcdQkG5X97b"));
    //Runs method 'open' which defines the request

    //Sends the request
    xhr.onload = function (e) {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                var temp = JSON.parse(xhr.responseText);
                $.each(temp.hits.hits, function (i, val) {
                    returnData.push(val._source);
                });

                // if (handleData != undefined) {
                //     handleData(returnData);
                // }
            } else {
                console.error(xhr.statusText);
            }
        }
    };
    xhr.onerror = function (e) {
        console.error(xhr.statusText);
    };
    xhr.send(JSON.stringify(searchObject));
    console.log(returnData[0]);
    return returnData[0];
};

export const getMerchants = (textSearch, allStatus, sort, order, limit, offset) => {
    const languageId = localStorage.getItem(Config.languageId);
    const merchantId = localStorage.getItem(Config.merchantId);
    const tokenId = localStorage.getItem(window.config.localStorageTokenId);

    var query = `
    {
      userorders(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
        ,keyword:"${textSearch}"        
        ,tokenUser:"${tokenId}"
        ,languageId:"${languageId}"
        ,merchantId:"${merchantId}"
        ,${allStatus ? `allStatus:${allStatus}` : ""}
       })
        {
              items {
                allStatus,
                grandTotal,
                id,
                code,
                createdDate,
                grandTotal,
                subTotal,
                paymentAmount,
                giftCodeAmount,
                promotionAmount,
                paymentMethodName,
                note,
                payments {
                  allStatus,
                  amount,
                  code,
                  id, note, paymentMethodId, paymentMethodName, referenceCode, targetId
                },
                delivery{
                  addressDetail, trackingCode,amount
                },
                orderLines {
                  id, targetName, originalPrice, price, quantity, total
                }, 
                user {
                  id, email, lastName, mobile
                },
              },
             
              totalCount
        }
      }
    `;
    return Common.getDataGraphQl(query);
};
