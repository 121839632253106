import React from "react";
import {
  StyledContainer,
  StyledFormBody,
  StyledFormBox,
  StyledFormFooter,
  StyledFormHeader,
  StyledLogoBox,
} from "./styledComponents";
import { useMeasure, useMount, useSetState } from "react-use";
import { Checkbox, Input, message } from "antd";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../Product/maxcoach/ProductDetail/styledComponents";
import Common from "../../../../../../Utils/Common";
import axios from "axios";
import Configuration from "../../../../../../Config/Config";
import Swal from "sweetalert2";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { hintTextColor } from "../../../../../../Utils/themeConfig";
import { getCommonStorage } from "../../../../../../Config/gql";

function MaxcoachRegisterPage(props) {
  const [state, setState] = useSetState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    mobile: "",
    loading: false,
  });
  const [containerRef, containerInfo] = useMeasure();

  const _refister = (e) => {
    e.preventDefault();
    if (state.loading) return;

    if (state.confirmPassword !== state.password) {
      return message.error("Password do not match!");
    }

    if (state.password.length < 8) {
      return message.error("Your password must be at least 8 characters!");
    }

    setState({ loading: true });

    const id = Common.guid();

    Common.sendSyncCommand(
      "RegisterMemberMerchant",
      "CustomerRelationshipManagement",
      {
        Id: id,
        Name: state.fullName,
        Mobile: state.mobile,
        Email: state.email,
        MerchantCode: window.Merchant.data.code,
        Password: state.password,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: id,
      },
      () => {
        Swal.fire("Cảm ơn đã đăng ký tài khoản.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setState({ loading: false });
      },
      (e) => {
        console.log(e);
        //Swal.fire(e?.Message || "Đăng kí thất bại!");
        Swal.fire("An account with this email already exists.","Please click <b><a href='/RecoveryPassword'>reset password</a></b>.");
        
        setState({ loading: false });
      }
    );
  };

  useMount(() => {
    const { tokenId } = getCommonStorage();

    if (tokenId) {
      window.location.href = "/";
    }
  });

  const merchant = window.Merchant.data;
  const logoDark = JSON.parse(merchant.configuration)["DarkLogoPath"];  
  const logo = Common.getImageThumbnail(merchant.images, "?mode=crop&width=180");
  const offset = window.innerHeight - containerInfo.height;

  return (
    <StyledContainer
      ref={containerRef}
      style={{
        paddingTop: Math.max(0, offset / 4),
      }}>
      <StyledLogoBox>
        <MCC>
          <img src={(logoDark != undefined && logoDark != "") ? logoDark  + "?mode=crop&width=180" : logo } alt="Logo" />
        </MCC>
      </StyledLogoBox>
      <StyledFormBox onSubmit={_refister}>
        <StyledFormHeader>Sign Up</StyledFormHeader>
        <StyledFormBody>
          <label>Your name</label>
          <Input
            value={state.fullName}
            onChange={(event) => setState({ fullName: event.target.value })}
            required={true}
            placeholder={"Your name"}
          />
          <label>Email</label>
          <Input
            value={state.email}
            onChange={(event) => setState({ email: event.target.value })}
            required={true}
            type={"email"}
            placeholder={"Email"}
          />
          <label>Phone number</label>
          <Input
            value={state.mobile}
            onChange={(event) => setState({ mobile: event.target.value })}
            required={true}
            type={"number"}
            placeholder={"Your phone number"}
          />
          <label>Password</label>
          <Input
            value={state.password}
            onChange={(event) => setState({ password: event.target.value })}
            type={"password"}
            required={true}
            placeholder={"Password"}
          />
          <label>Confirm password</label>
          <Input
            value={state.confirmPassword}
            onChange={(event) => setState({ confirmPassword: event.target.value })}
            type={"password"}
            required={true}
            placeholder={"Confirm password"}
          />
          <StyledButton
            variant={"primary"}
            display={"block"}
            style={{ marginTop: 32 }}
            type={"submit"}>
            {state.loading && <ThreeDotsLoader width={40} height={18} fill={"#FFFFFF"} />}
            {!state.loading && "Get Started"}
          </StyledButton>
          <div
            style={{
              marginTop: 8,
              textAlign: "center",
              color: hintTextColor,
            }}>
            By clicking “Get Started”, you agree to Terms & Policies.
          </div>
        </StyledFormBody>
        <StyledFormFooter>
          <Link to={"/login"}>Back to login</Link>
        </StyledFormFooter>
      </StyledFormBox>
    </StyledContainer>
  );
}

export default MaxcoachRegisterPage;
