import React, { useEffect } from "react";
import { Observer } from "../../../../../../Utils/Ob";
import { useMount, useSetState } from "react-use";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import Common from "../../../../../../Utils/Common";
import { Rate, Skeleton } from "antd";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Configuration from "../../../../../../Config/Config";
import MCC from "../../../../ComponentCommon/MiddleContent";
import "./style.less";
import { useTranslation } from "react-i18next";

const { $ } = window;

function CourseDetail(props) {
  const {t} = useTranslation()
  const [state, setState] = useSetState({
    category: null,
    slides: [],
    videos: [],
    quizs: [],
    courseArticles:[],
    totalItem: 0,
    loading: true,
  });
  const history = useHistory();

  useMount(() => {
    window.scrollTo(0, 0);
    setState({ loading: true });
    Promise.all([
      QueryGraphQL.getCategoryById(props.match.params.id),
      QueryGraphQL.pagingArticles(7, 0, 1000, props.match.params.id),
      QueryGraphQL.pagingArticles(32, 0, 1000, props.match.params.id),
      QueryGraphQL.pagingQuizs(1, 0, 1000, props.match.params.id),
      QueryGraphQL.pagingArticles(256, 0, 1000, props.match.params.id),
    ])
      .then(([data, data1, data2, data3,data4]) => {
        setState({
          category: data.category,
          slides: data1.articles.items,
          videos: data2.articles.items,
          totalItem: data3.quizs.totalCount,
          quizs: data3.quizs.items,
          courseArticles:data4.articles.items
        });
      })
      .finally(() => setState({ loading: false }));
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state.loading) {
      // setTimeout(fixGrid, 100);
    }
  }, [state.loading]);

  let pageTitle = t(state.category?.name);

  return (
    <div id={"dongsec"}>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h1 className="title">{t("Learning Materials")}</h1>
          </div>
        </div>
        <Breadcrumb
          items={[
            { name: "Home", href: "/" },
            { name: t("Learning Materials"), href: "/course" },
            { name: Common.formatName(pageTitle || "", 50) },
          ]}
        />
      </div>
      {state.loading && (
        <React.Fragment>
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <Skeleton active={true} />
              </div>
            </div>
          </div>
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                <div className={"col max-mb-30"}>
                  <div className={"d-skeleton-image"}>
                    <Skeleton active={true} paragraph={{ rows: 0 }} />
                  </div>
                  <Skeleton active={true} />
                </div>
                <div className={"col max-mb-30"}>
                  <div className={"d-skeleton-image"}>
                    <Skeleton active={true} paragraph={{ rows: 0 }} />
                  </div>
                  <Skeleton active={true} />
                </div>
                <div className={"col max-mb-30"}>
                  <div className={"d-skeleton-image"}>
                    <Skeleton active={true} paragraph={{ rows: 0 }} />
                  </div>
                  <Skeleton active={true} />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {!state.loading && (
        <React.Fragment>
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                {/*, background: "#faf8f6"*/}
                <div className="course-3" style={{ width: "100%" }}>
                  <div className="thumbnail">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} class="image">
                      <img
                        src={Common.getImageThumbnail(
                          state.category.images,
                          "?mode=crop&width=640&height=360"
                        )}
                        alt="Course"
                      />
                    </Link>
                  </div>
                  <div className="info">
                    <span className="price">
                      <Rate allowHalf defaultValue={5} />
                    </span>
                    <h3 className="title">{state.category.name}</h3>
                    <p>{state.category.subDescription}</p>
                    <ul className="meta">
                      <li>
                        <i className="far fa-calendar-day" />
                        {moment(state.category.createdDate).format("YYYY-MM-DD")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <div
                  className="sidebar-widget"
                  style={{ padding: 20, background: "#faf8f6", borderRadius: 5 }}>
                  <h3 className="sidebar-widget-title">
                    <i className="fas fa-award" /> {t("Your goals")}
                  </h3>
                  <div className="sidebar-widget-content">
                    <p dangerouslySetInnerHTML={{ __html: state.category.description }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*slides*/}
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title">Slide</h3>
                  <div className="sidebar-widget-content">
                    <div className="isotope-grid row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                      {/*<div className="grid-sizer col-1" />*/}
                      {state.slides.map((item, i) => (
                        <div
                          className="grid-item col max-mb-30"
                          data-aos="fade-up"
                          key={i}
                          onClick={() =>
                            history.push(`/slide/${item.id}/${Common.rewriteUrl(item.name)}`)
                          }>
                          <div className="blog-2">
                            <div className="thumbnail">
                              <span className="image">
                                <img
                                  src={Common.getImageThumbnail(
                                    item.images,
                                    Configuration.imageFormatSlide
                                  )}
                                  alt="Blog"
                                />
                              </span>
                            </div>
                            <div className="info">
                              <span className="category cut-text">
                              {Common.formatDateTime(item.createdDate, "dd/mm/yyyy")}
                              </span>
                              <h3 className="title">{item.name}</h3>
                              <p style={{ marginTop: 5 }}>{item.subDescription}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*videos*/}
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title">Video</h3>
                  <div className="sidebar-widget-content">
                    <div className="isotope-grid row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                      {/*<div className="grid-sizer col-1" />*/}
                      {state.videos.map((item, i) => (
                        <div
                          className="grid-item col max-mb-30"
                          data-aos="fade-up"
                          key={i}
                          onClick={() =>
                            history.push(`/video/${item.id}/${Common.rewriteUrl(item.name)}`)
                          }>
                          <div className="blog-2">
                            <div className="thumbnail">
                              <span className="image" style={{ position: "relative" }}>
                                <img
                                  src={Common.getImageThumbnail(
                                    item.images,
                                    Configuration.imageFormatSlide
                                  )}
                                  alt="Blog"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                  }}>
                                  <MCC>
                                    <i
                                      className="fas fa-play"
                                      style={{
                                        fontSize: "4em",
                                        color: "rgba(255, 255, 255, 0.75)",
                                        textShadow:
                                          "0px 4px 3px rgba(0,0,0,0.2), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
                                      }}
                                    />
                                  </MCC>
                                </div>
                              </span>
                            </div>
                            <div className="info">
                              <span className="category cut-text">
                              {Common.formatDateTime(item.createdDate, "dd/mm/yyyy")}
                              </span>
                              <h3 className="title">{item.name}</h3>
                              <p style={{ marginTop: 5 }}>{item.subDescription}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title">{t("Article")}</h3>
                  <div className="sidebar-widget-content">
                    <div className="isotope-grid row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                      {/*<div className="grid-sizer col-1" />*/}
                      {state.courseArticles.map((item, i) => (
                        <div
                          className="grid-item col max-mb-30"
                          data-aos="fade-up"
                          key={i}
                          onClick={() =>
                            history.push(`/blog/${item.id}/${Common.rewriteUrl(item.name)}`)
                          }>
                          <div className="blog-2">
                            <div className="thumbnail">
                              <span className="image">
                                <img
                                  src={Common.getImageThumbnail(
                                    item.images,
                                    Configuration.imageFormatSlide
                                  )}
                                  alt="Blog"
                                />
                              </span>
                            </div>
                            <div className="info">
                              <span className="category cut-text">
                              {Common.formatDateTime(item.createdDate, "dd/mm/yyyy")}
                              </span>
                              <h3 className="title">{item.name}</h3>
                              <p style={{ marginTop: 5 }}>{item.subDescription}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*quizs*/}
          <div className="section section-padding-bottom">
            <div className="container">
              <div className="row max-mb-n30">
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title">{t("Quiz")}</h3>
                  <div className="sidebar-widget-content">
                    <div className="row row-cols-lg-2 row-cols-1 max-mb-n30">
                      {state.quizs.map((item, i) => (
                        <div
                          className="col max-mb-30"
                          data-aos="fade-up"
                          key={i}
                          onClick={() =>
                            history.push(`/quiz/${item.id}/${Common.rewriteUrl(item.name)}`)
                          }>
                          <div className="course-3">
                            <div className="thumbnail">
                              <span className="image">
                                <img
                                  src={Common.getImageThumbnail(
                                    item.images,
                                    "?mode=crop&width=200&height=200"
                                  )}
                                  alt="Quiz"
                                />
                              </span>
                            </div>
                            <div className="info">
                              {/*<span className="price">*/}
                              {/*  $40<span>.00</span>*/}
                              {/*</span>*/}
                              <h3 className="title">{item.name}</h3>
                              <p>{item.subDescription}</p>
                              <ul className="meta">
                                <li>
                                  <i className="far fa-calendar-day" />
                                  {moment(item.createdDate).format("YYYY-MM-DD")}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Observer(CourseDetail);

function fixGrid() {
  let $isotopeGrid = $(".isotope-grid");
  $isotopeGrid.imagesLoaded(function () {
    $isotopeGrid.isotope({
      itemSelector: ".grid-item",
      masonry: {
        columnWidth: ".grid-sizer",
      },
    });
  });
}
