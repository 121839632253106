import React from "react";
import { useDebounce, useSetState } from "react-use";
import { useQuery } from "urql";
import { getCommonStorage } from "../../../../Config/gql";
import { BasePage } from "../../ComponentCommon/BasePage";
import { QUERY_PRODUCTS } from "../Product/maxcoach/Products/gql";
import { RenderPagination } from "../Product/maxcoach/Products";
import { useState } from "react";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { Link } from "react-router-dom";

export default function ProductExchangePoint() {
  const [pageState, setPageState] = useSetState({
    current: 0,
    limit: 6,
    categoryIds: [],
    keyword: "",
  });
  const [fetchProductsResult] = useQuery({
    query: QUERY_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";

      return {
        type: Configuration.productType.exchangePoint,
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        limit: pageState.limit,
        offset: pageState.limit * pageState.current,
        categoryIds: pageState.categoryIds,
        keyword: pageState.keyword,
        sort,
        order,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const products = fetchProductsResult.data?.products?.items || [];

  const total = fetchProductsResult.data?.products?.totalCount || 0;
  const isEmpty = total === 0;
  return (
    <BasePage title={"Sản phẩm điểm"}>
      <div className="section section-padding-bottom">
        <div className="container">
          {/* Course Top Bar Start */}
          <div className="row justify-content-between align-items-center max-mb-20">
            <div className="col-sm-auto col-12 max-mb-10">
              {!fetchProductsResult.fetching ? (
                <p className="result-count">
                  Chúng tôi tìm thấy <span>{total}</span> sản phẩm cho bạn
                </p>
              ) : null}
            </div>
            <div className="col-sm-auto col-12 max-mb-10">
              {/* <select className="sort-by">
                    <option value="popularity">Popularity</option>
                    <option value="date">Latest</option>
                    <option value="price">Price: low to high</option>
                    <option value="price-desc">Price: high to low</option>
                  </select> */}
            </div>
          </div>
          {/* Course Top Bar End */}
          {/* Product Wrapper Start */}
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
            {fetchProductsResult.fetching
              ? "Loading..."
              : products.map((product: any) => (
                  <div className="col max-mb-30" data-aos="fade-up">
                    <div className="course">
                      <div className="thumbnail">
                        <Link to={"/product-exchange-point/" + product.id} className="image">
                          <img
                            src={Common.getImageThumbnail(
                              product.images,
                              "?Mode=crop&width=370&height=229"
                            )}
                            alt="Course Image"
                          />
                        </Link>
                      </div>
                      <div className="info">
                        <span className="price">{Common.formatMoneyIntl(product.price)}</span>
                        <h3 className="title">
                          <Link to={"/product-exchange-point/" + product.id}>{product.name}</Link>
                        </h3>
                        <ul className="meta">
                          <li>
                            <i className="far fa-file-alt" />
                            {product.exchangePoint || "N/A"} Điểm
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {/* Product Wrapper End */}
          {/* Pagination Start */}
          <div className="row max-mt-50" hidden={isEmpty || fetchProductsResult.fetching}>
            <div className="col">
              <RenderPagination
                currentPage={pageState.current}
                totalItem={total}
                itemPerPage={pageState.limit}
                handlePageChange={(v: any) => {
                  setPageState({ current: v });
                  window.scrollTo(0, 300);
                }}
              />
            </div>
          </div>
          {/* Pagination End */}
        </div>
      </div>
    </BasePage>
  );
}
