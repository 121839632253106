import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next"

function Breadcrumb(props) {
  const temp = props.items;
  const {t} = useTranslation()

  return (
    <div className="page-breadcrumb">
      <div className="container">
        <ul className="breadcrumb">
          {temp.map((item, i) => (
            <li key={i}>
              <Link to={i !== temp.length - 1 && item.href}>{t(item.name)}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Breadcrumb;
