import { styled } from "../../../../../../Utils/stitches.config";
import { borderColor, hintTextColor, primaryTextColor } from "../../../../../../Utils/themeConfig";

export const StyledLessonHeader = styled("div", {
  position: "relative",
  marginTop: 26,
  marginBottom: 14,
  marginLeft: 16,
  h5: {
    fontSize: 16,
    lineHeight: "19px",
    color: primaryTextColor,
    paddingRight: 60,
  },
});
export const StyledExpandButton = styled("button", {
  position: "absolute",
  top: -10,
  right: 16,
  border: 0,
  padding: 8,
  color: primaryTextColor,
  background: "unset",
  margin: 0,
});
export const StyledIconBox = styled("span", {
  display: "inline-block",
});
export const StyledSubDescription = styled("div", {
  fontSize: 12,
  lineHeight: "15px",
  marginTop: 4,
  variants: {
    active: {
      true: {
        color: "#FFFFFF",
      },
      false: {
        color: "#999FAE",
      },
    },
  },
});
export const StyledLessonBox = styled("li", {
  border: 0,
  margin: 0,
  padding: 0,
  borderBottom: "1px solid " + borderColor,
  "&:not(:first-child)": {
    borderTop: "1px solid " + borderColor,
    marginTop: 16,
  },
});
