import React, { useEffect } from "react";
import { useQuery } from "urql";
import { QUERY_ARTICLE, QUERY_CATEGORIES } from "./gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { useMeasure, useMount, useSetState } from "react-use";
import { FilterItems, RenderPagination, SideWidget } from "../../../Product/maxcoach/Products";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import * as ecs from "../../../Course/maxcoach/UserCourse/ecs";
import { BookItem, BookItemBox, FileItem, PopularBooks } from "./styled";
import { Link } from "react-router-dom";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import * as esc from "../../../Product/maxcoach/Products/esc";
import { Modal, Rate } from "antd";
import LoadingSpinner from "../../../../ComponentCommon/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MaxcoachBooksPage() {
  const {t} = useTranslation()
  const [state, setState] = useSetState({
    categoryIds: [],
    limit: 10,
    currentPage: 0,
    selectedBook: null,
  });
  const [fetchCategories] = useQuery({
    query: QUERY_CATEGORIES,
    requestPolicy: "cache-and-network",
    variables: (() => {
      const { languageId, merchantId, tokenId } = getCommonStorage();

      return {
        type: 8,
        languageId,
        merchantId,
        tokenUser: tokenId,
      };
    })(),
  });
  const [fetchPopularBooks] = useQuery({
    query: QUERY_ARTICLE,
    requestPolicy: "cache-and-network",
    variables: (() => {
      const { languageId, merchantId } = getCommonStorage();

      return {
        type: 5,
        languageId,
        merchantId,
        limit: 5,
        sort: "createdDate",
        order: "desc",
      };
    })(),
  });
  const [fetchBooks] = useQuery({
    query: QUERY_ARTICLE,
    requestPolicy: "cache-and-network",
    variables: (() => {
      const { languageId, merchantId } = getCommonStorage();

      return {
        type: 5,
        languageId,
        merchantId,
        categoryIds:
          state.categoryIds.length === 0
            ? (fetchCategories.data?.privilegecategories?.items || []).map((v) => v.id)
            : state.categoryIds,
        limit: state.limit,
        offset: state.currentPage * state.limit,
      };
    })(),
    pause: fetchCategories.fetching,
  });
  const history = useHistory();

  useEffect(() => {
    setState({ currentPage: 0 });
  }, [state.categoryIds]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [fetchBooks.fetching, state.currentPage]);

  const pageTitle = t("Books");
  const popularBooks = fetchPopularBooks.data?.articles?.items || [];
  const books = fetchBooks.data?.articles?.items || [];
  const categories = fetchCategories.data?.privilegecategories?.items || [];
  const totalCount = fetchBooks.data?.articles?.totalCount || 0;
  const isEmpty = !fetchBooks.fetching && books.length === 0;

  return (
    <>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{pageTitle}</h1>
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="row max-mb-n50">
            <div className="col-lg-3 col-12 order-lg-1 max-mb-50">
              <SideWidget title={t("CATEGORIES")} canExpand={true}>
                <FilterItems
                  items={categories}
                  loading={fetchCategories.fetching}
                  onChange={(categoryIds) => setState({ categoryIds })}
                  search={true}
                  value={state.categoryIds}
                />
              </SideWidget>
            </div>
            <div className="col-lg-9 col-12 order-lg-1 max-mb-50">
              {/**/}
              <SideWidget title={t("Popular")} hidden={true}>
                <PopularBooks>
                  <div className="uk-position-relative" tabIndex={-1} uk-slider="autoplay: true">
                    <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s uk-grid">
                      {popularBooks.map((item, i) => {
                        const detailUrl = "/book/" + item.id + "/" + Common.rewriteUrl(item.name);

                        return (
                          <BookItem key={i}>
                            <Link to={detailUrl}>
                              <div className="book-card">
                                <div className="book-cover">
                                  <img
                                    src={Common.getImageThumbnail(
                                      item.images,
                                      Configuration.imageFormatBook
                                    )}
                                    alt={item.name}
                                  />
                                </div>
                                <div className="book-content">
                                  <h5>{item.name}</h5>
                                </div>
                              </div>
                            </Link>
                          </BookItem>
                        );
                      })}
                    </ul>
                    <div className="uk-flex uk-flex-center mt-2">
                      <ul className="uk-slider-nav uk-dotnav" />
                    </div>
                  </div>
                </PopularBooks>
              </SideWidget>
              {/*  */}
              <SideWidget title={t("All Genres")}>
                <esc.FilterValuesContainer>
                  {categories.map((category, i) => {
                    if (state.categoryIds.concat(state.filterByCourseTypes).includes(category.id)) {
                      return <span key={i}>{category.name}</span>;
                    }

                    return null;
                  })}
                </esc.FilterValuesContainer>
                {fetchBooks.fetching && <LoadingSpinner />}
                <div
                  className="row row-cols-lg-2 row-cols-md-2 row-cols-1 max-mb-n30"
                  hidden={fetchBooks.fetching}>
                  {books.map((book, i) => {
                    const files = book?.files || [];

                    // if (files.length === 0) {
                    //   return null;
                    // }

                    return (
                      <RenderBookItem
                        item={book}
                        key={i}
                        index={i}
                        onClick={() => {
                          if (files.length === 1) {
                            const win = window.open(
                              //"/bookRead?file=" + encodeURIComponent(files[0].path)
                              "/bookRead?file=" + files[0].path
                            );
                            win.focus();
                          } else {
                            setState({ selectedBook: book });
                          }
                          
                        }}
                      />
                    );
                  })}
                </div>
              </SideWidget>
            </div>
          </div>
          <div className="row max-mt-50" hidden={books.length === 0 || fetchBooks.fetching}>
            <div className="col">
              <RenderPagination
                currentPage={state.currentPage}
                totalItem={totalCount}
                itemPerPage={state.limit}
                handlePageChange={(currentPage) => setState({ currentPage })}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={state.selectedBook?.name}
        visible={!!state.selectedBook}
        onCancel={() => setState({ selectedBook: null })}
        onOk={() => setState({ selectedBook: null })}>
        {(state.selectedBook?.files || []).map((file, i) => {
          return (
            <FileItem
              key={i}
              onClick={() => {
                // history.push({
                //   pathname: "/bookRead",
                //   state: {
                //     path: file.path,
                //   },
                //   search: "?file=" + encodeURIComponent(file.path),
                // });
                const win = window.open("/bookRead?file=" + encodeURIComponent(file.path));
                win.focus();
              }}>
              {file.name}
            </FileItem>
          );
        })}
      </Modal>
    </>
  );
}

export function RenderBookItem(props) {
  const [thumbnailRef, thumbnailInfo] = useMeasure();
  const [containerRef, containerInfo] = useMeasure();

  const book = props.item;
  const image = Common.getImageThumbnail(book.images, Configuration.imageFormatBook);
  const categories = (book.categories || []).map((v) => v.name).join(", ");
  const isSmallBox = containerInfo.width < 390;

  return (
    <BookItemBox className={"col max-mb-30"}>
      <div className={"dx-container"} ref={containerRef} {...props}>
        <div className={"dx-table"}>
          <div className={"dx-thumbnail"}>
            <img
              ref={thumbnailRef}
              src={image}
              alt="Thumbnail"
              style={{
                height: thumbnailInfo.width * (297 / 210), // book dimensions
              }}
            />
          </div>
          <div className={"dx-info"}>
            <div className={"dx-info-container"}>
              <h3>{book.name}</h3>
              <p className={"dx-categories"}>{categories}</p>
              <div className={"dx-rate"}>
                <Rate value={5} />
              </div>
              <p className={"dx-description"} hidden={isSmallBox}>
                {Common.formatName(book.subDescription, 80)}
              </p>
            </div>
          </div>
        </div>
        <p className={"dx-description dx-special"} hidden={!isSmallBox}>
          {Common.formatName(book.subDescription, 80)}
        </p>
      </div>
    </BookItemBox>
  );
}

export default MaxcoachBooksPage;
