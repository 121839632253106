export const types = [
    {value:1,name:"Government"},
    {value:2,name:"MNC"},
    {value:3,name:"NGO"},
    {value:4,name:"PRIVATE"},
    {value:5,name:"PublicListed"},
    {value:6,name:"PublicUnlisted"},
    {value:7,name:"StatutoryAuthority"},
    {value:8,name:"MOFInc"},
    {value:9,name:"GLIC"},
    {value:10,name:"GLC"},
  ];

  export const industries = [
    {value:1,name:"Academia/Education",},
    {value:2,name:"Accounting Services"},
    {value:3,name:"Agricultural/Forestry/Fishing"},
    {value:4,name:"Airline/Aviation" },
    { value:6 ,name:"Automotive" ,},
    { value:7 ,name:"Banking"},
    { value:8 ,name:"Biotech/Life Sciences"},
    { value:9 ,name:"Chemical"},
    { value:10 ,name:"Conglomerate"},
    { value:11 ,name:"Construction/Real Estate" },
    { value:12 ,name:"Consultancy" },
    { value:13 ,name:"Consumer Products" },
    { value:14 ,name:"Energy/Utilities" },
    { value:15 ,name:"Engineering" },
    { value:16 ,name:"Expressway Concession" },
    { value:17 ,name:"Financial Services" },
    { value:18 ,name:"Government" },
    { value:19 ,name:"Healthcare" },
    { value:20 ,name:"Hospitality/Tourism" },
    { value:21 ,name:"Infrastructure Development" },
    { value:22 ,name:"Insurance" },
    { value:23 ,name:"IT" },
    { value:24 ,name:"Legal Services" },
    { value:25 ,name:"Logistics/Transportation" },
    { value:26 ,name:"Manufacturing" },
    { value:27 ,name:"Media/Advertising" },
    { value:28 ,name:"Non-Profit/Social" },
    { value:29 ,name:"Oil & Gas" },
    { value:30 ,name:"Pharmaceuticals" },
    { value:31 ,name:"PR/Communication" },
    { value:32 ,name:"Professional Services" },
    { value:33 ,name:"Property Development" },
    { value:34 ,name:"Retail" },
    { value:35 ,name:"Semi-conductor Manufacturing" },
    { value:36 ,name:"Telecommunications" },
  ];



export const positions =  [
  {value:1,name:"Executive Chairman"},
  {value:2,name:"President"},
  {value:4,name:"CEO/MD"},
  {value:8,name:"CXO (eg. COO, CTO, CMO, CFO, etc)"},
  {value:16,name:"Senior Vice President and Vice President"},
  {value:32,name:"Director General/Deputy Director General"},
  {value:64,name:"GM, Senior Manager, Manager and below"},
 
];
export const functionals = [
  {value:1,name:"Accounting"},
  {value:2,name:"Audit"},
  {value:4,name:"Consulting"},
  {value:8,name:"Corporate Strategy"},
  {value:16,name:"Customer Service"},
  {value:32,name:"Engineering"},
  {value:64,name:"Finance"},
  {value:128,name:"General Management"},
  {value:256,name:"Human Resource/ Learning & Development"},
  {value:512,name:"Legal/Corporate Secretarial"},
  {value:1024,name:"Logistics/Purchasing/Supply Chain"},
  {value:2048,name:"Operations"},
  {value:4096,name:"PR/Communication"},
  {value:8192,name:"Profit & Loss"},
  {value:16384,name:"R&D"},
  {value:32768,name:"Risk Management"},
  {value:65536,name:"Sales/Marketing/Business Development"},
  {value:131072,name:"Tax Advisory"},
]
export const globalExperiences = [
  {value:1,name:"Asean"},
  {value:2,name:"Asia North"},
  {value:4,name:"Asia Pacific"},
  {value:8,name:"Australia/New Zealand"},
  {value:16,name:"Europe"},
  {value:32,name:"India"},
  {value:64,name:"Japan"},
  {value:128,name:"Middle East"},
  {value:256,name:"North Africa"},
  {value:512,name:"North America"},
  {value:1024,name:"South Africa"},
  {value:2048,name:"South America"},
  {value:4096,name:"USA"},
  
]
export  const getPosition = (value: number) => {
  let arr: any = [];
  positions.forEach((i, index) => {
    if ((i.value & value) === i.value) {
      arr.push(i);
    }
  });

  return arr;
};
export const getGlobalExperiences = (value: number) => {
  let arr: any = [];
  globalExperiences.forEach((i, index) => {
    if ((i.value & value) === i.value) {
      arr.push(i);
    }
  });

  return arr;
};

export  const getFunctionals = (value: number) => {
  let arr: any = [];
  functionals.forEach((i, index) => {
    if ((i.value & value) === i.value) {
      arr.push(i);
    }
  });

  return arr;
};