import React from "react";
import Configuration from "../../../../../../Config/Config";
import { useLocation, useMount } from "react-use";

function MaxcoachReadBook(props) {
  const location = useLocation();

  useMount(() => {
    window.scrollTo(0, 0);
  });

  // const { path } = props.location.state;
  // const { path } = props.location.state;
  const path = location.search.replace("?file=", "");
  console.log(path,"15")
  const url = Configuration.staticDomain + path;
  const targetUrl =
    "/assets/vendor/pdfjs-2.6.347-es5-dist/web/viewer.html?file=" + encodeURIComponent(url);
  console.log(targetUrl,"19")
  return (
    <div className={"section section-padding-bottom"}>
      <div className={"container"}>
        <iframe
          title={"Read Book"}
          style={{
            width: "100%",
            height: "calc(100vh - 80px)",
          }}
          src={targetUrl}
        />
      </div>
    </div>
  );
}

export default MaxcoachReadBook;
