/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import "lightgallery.js/dist/css/lightgallery.css";
import { updateBreadcrumb, setHeaderTranparent } from "../../../Reducers/Book";
import $ from "jquery";

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                code: "",
                price: 0,
                name: "",
                subDescription: "",
                description: "",
                files: [],
                images: [],
                authors: [],
            },
        };
    }
    componentDidMount() {
        var self = this;
        QueryGraphQL.getProductById(this.props.match.params.id).then((data) => {
            this.setState({
                item: data.product,
            });
            self.props.updateBreadcrumb(data.product);
        });
    }
    returnValue(obj, property) {
        if (obj === undefined) {
            return "";
        }
        return obj[property];
    }
    createOrder = () => {
        var id = Common.guid();
        var items = [
            {
                Id: Common.guid(),
                TargetId: this.state.item.id,
                quantity: 1,
            },
        ];

        var data = {
            Id: id,
            MerchantId: localStorage.getItem(Configuration.merchantId),
            TargetId: localStorage.getItem(Configuration.merchantId),
            CustomerId: localStorage.getItem(Configuration.userId),
            OrderItems: items,
            CreatedDate: Common.getCurrentDateTime(),
            CreatedBy: localStorage.getItem(Configuration.userId),
        };
        var dataCommand = {
            CommandName: "CreateOrder",
            Domain: "CustomerRelationshipManagement",
            Content: JSON.stringify(data),
            ModifiedDate: Common.getCurrentDateTime(),
            ModifiedBy: id,
            //ReCaptcha: reCaptcha,
            timeOutSecond: 30,
        };
        var tokenParam = "";
        if (localStorage.getItem("userIzzi")) {
            tokenParam += "&tokenId=" + localStorage.getItem("userIzzi");
        }
        $.ajax({
            //url: Merchant.apiUrl + "/Command/SendSyncReCaptcha",
            url: Configuration.urlApi + "/Command/SendSync",
            type: "post",
            contentType: "application/json",
            processData: false,
            dataType: "json",
            data: JSON.stringify(dataCommand),
            // async:false
            success: function (res) {
                if (res.Success) {
                    window.location.href =
                        "https://" + window.Merchant.data.code + ".izzipayment.com?id=" + id + "&urlReturn=https://" + encodeURI(window.location.href) + tokenParam;
                } else {
                    alert(res.Message);
                }
            },
            error: function (err) {
                alert(err.responseText);
            },
        });
    };
    render() {
        this.props.setHeaderTranparent(true);
        return (
            <Fragment>
                <div className="course-details-wrapper topic-1 uk-light pt-5">
                    {/* topbar */}

                    <div className="container p-sm-0">
                        <div uk-grid="true">
                            <div className="uk-width-2-3@m">
                                <div className="course-details">
                                    <h1> {this.state.item.name}</h1>
                                    <p> {this.state.item.subDescription} </p>
                                    <div className="course-details-info mt-4">
                                        <ul>
                                            <li>
                                                <div className="star-rating">
                                                    <span className="avg"> 4.9 </span>
                                                    <span className="star" />
                                                    <span className="star" />
                                                    <span className="star" />
                                                    <span className="star" />
                                                    <span className="star" />
                                                </div>
                                            </li>
                                            {/* <li> <i className="icon-feather-users"></i> 1200 Enerolled </li> */}
                                        </ul>
                                    </div>
                                    <div className="course-details-info">
                                        <ul>
                                            {this.state.item.authors.map((item, i) => (
                                                <li>
                                                    {" "}
                                                    Tạo bởi <a href="javascript:;"> {item.name} </a>{" "}
                                                </li>
                                            ))}

                                            <li> Tạo ngày {Common.formatDateTime(this.state.item.createdDate, "dd-mm-yyyy")}</li>
                                        </ul>
                                    </div>
                                </div>
                                <nav className="responsive-tab style-5">
                                    <ul uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium">
                                        <li>
                                            <a href="#">Thông tin</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="uk-grid-large mt-4" uk-grid="true">
                        <div className="uk-width-2-3@m">
                            <ul id="course-intro-tab" className="uk-switcher mt-4">
                                {/* course description */}
                                <li className="course-description-content">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.item.description,
                                        }}
                                    />
                                </li>
                                {/* course Reviews*/}
                            </ul>
                        </div>
                        <div className="uk-width-1-3@m">
                            <div className="course-card-trailer" uk-sticky="top: 10 ;offset:95 ; media: @m ; bottom:true">
                                <div className="course-thumbnail" onClick={() => this.createOrder()}>
                                    <img src={Common.getImageThumbnail(this.state.item.images, "?mode=crop&amp;width=1280&amp;height=720")} alt />
                                    {/* <a className="play-button-trigger show" href="#trailer-modal" uk-toggle="true"> </a> */}
                                </div>

                                <div className="p-3">
                                    <p className="my-3 text-center">
                                        <span className="uk-h1"> {Common.formatMoney(this.state.item.price, 0, 3)} </span>
                                        {/* <s className="uk-h4 text-muted"> $19.99 </s>
                                        <s className="uk-h6 ml-1 text-muted"> $32.99 </s> */}
                                    </p>
                                    <div className="uk-child-width-1-2 uk-grid-small mb-4" uk-grid="true">
                                        <div>
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => this.createOrder()}
                                                className="uk-width-1-1 btn btn-default transition-3d-hover">
                                                {" "}
                                                <i className="uil-play" /> Mua ngay{" "}
                                            </a>
                                        </div>
                                    </div>
                                    <p className="uk-text-bold"> Khóa học bao gồm </p>
                                    <div className="uk-child-width-1-2 uk-grid-small" uk-grid="true">
                                        <div>
                                            <span>
                                                <i className="uil-youtube-alt" /> {this.state.item.images.length} ảnh
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                {" "}
                                                <i className="uil-award" /> Chứng nhận{" "}
                                            </span>
                                        </div>
                                        {/* <div>
                                            <span> <i className="uil-video" /> Dạy offline </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setHeaderTranparent,
    updateBreadcrumb,
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
