import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  Row,
  Empty,
  DatePicker,
  Space,
  Checkbox,
  Modal,
  Divider,
  Tag,
  Typography,
} from "antd";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import DNModal from "../DnStyles/Modal";
import { boardRoles, cmrType, cmrRole } from "./data";
import { positions } from "../Work/data";
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
import { QueryCompanyAddress } from "../Work/gql";
import { QueryBoardExperiences } from "./gql";
import { exec } from "../../../../Utils/dn";
import { getCommonStorage } from "../../../../Config/gql";
import { calculateTotal } from "../Work/ultils";
import { useTranslation } from "react-i18next";

import { useQuery } from "urql";
const WorkCompany = () => {
  const {t} = useTranslation()
  const [boardForm] = Form.useForm();
  const [id] = useState(v4());
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState();
  const [editId, setEditId] = useState("");

  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });
  const [isEndDateDisable, setIsEndDateDisable] = useState(false);

  const [BoardExperiencesDatas, refetchBoardExperiences] = useQuery({
    query: QueryBoardExperiences,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 1,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const [queryCompanyAddress, refetchCompanyAddresses] = useQuery({
    query: QueryCompanyAddress,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 16,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (experience: any) => {
    const rangeValue = experience["dates"];
    const { userId } = getCommonStorage();
    setState({
      loading: true,
    });
    console.log("line 85", experience);

    exec(
      "WorkExperience",
      state.isEdit ? "UpdateInformationBoardExperience" : "CreateBoardExperience",
      {
        Id: state.isEdit ? editId : v4(),
        Name: experience.title,

        Position: calculateTotal([experience.position]),

        CommitteeRoles: experience.committeeRoles,
        FromDateTime: experience.startDate.format("YYYY-MM-DD"),
        ToDateTime: isEndDateDisable ? null : experience.endDate.format("YYYY-MM-DD"),
        UserReferees: experience.users,
        CompanyId: experience.company,
        TargetId: userId,
      },
      ["actionBy", "languageId", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchBoardExperiences();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };
  const _onClickEdit = (experience: any) => {
    setEditId(experience.id);
    setIsEndDateDisable(experience.toDateTime ? false : true);
    boardForm.setFieldsValue({
      company: experience.companies[0].id,

      title: experience.name,
      position: getPosition(experience.position).map((i: any) => i.value),
      startDate: moment(experience.fromDateTime, "YYYY-MM-DD"),
      endDate: experience.toDateTime ? moment(experience.toDateTime, "YYYY-MM-DD") : null,
      committeeRoles: JSON.parse(experience.committeeRoles),
      users: JSON.parse(experience.userReferees),
    });
    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec("WorkExperience", "DeleteWorkExperience", {
          Id: id,
        }).finally(refetchBoardExperiences);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };
  const getPosition = (value: number) => {
    let arr: any = [];
    positions.forEach((i, index) => {
      if ((i.value & value) === i.value) {
        arr.push(i);
      }
    });

    return arr;
  };
  const companies = queryCompanyAddress.data?.data?.items;
  const experiences = BoardExperiencesDatas.data?.data?.items || [];

  return (
    <Fragment>
      <BasePage title={t("Board Experience")} hideTitle={true}>
        <Card
          title={t("Board Experience")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                boardForm.resetFields();
                setIsEndDateDisable(false);
                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={BoardExperiencesDatas.fetching}>
          {experiences.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Board Experience")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {experiences.length > 0 &&
            experiences.map((experience: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Company")}:
                      </Col>
                      <Col span={20}>{experience?.companies[0]?.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("title.BoardExperience")}:
                      </Col>
                      <Col span={20}>{experience.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Position")}
                      </Col>
                      <Col span={20}>
                        {" "}
                        {getPosition(experience.position).map((value: any) => (
                          <Tag color="green">{value.name}</Tag>
                        ))}
                      </Col>
                    </Row>

                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                      {t("year.Join")}
                      </Col>
                      <Col span={20}>
                        {moment(experience.fromDateTime).format("YYYY-MM-DD")} --
                        {experience.toDateTime
                          ? moment(experience.toDateTime).format("YYYY-MM-DD")
                          : "Hiện tại"}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Button type={"link"} onClick={() => _onClickEdit(experience)}>
                        {t("Edit")}
                      </Button>
                      <Button type={"link"} danger={true} onClick={() => _remove(experience.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
      </BasePage>
      <DNModal
        title={state.isEdit ? t("Edit") : t("Experience")}
        visible={state.isModalVisible}
        onCancel={() => {
          setState({ isModalVisible: false });
        }}
        okButtonProps={{
          form: id,
          htmlType: "submit",
        }}
        confirmLoading={state.loading}>
        <Form form={boardForm} onFinish={_onFinish} id={id} layout={"vertical"}>
          <Form.Item name={"company"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Select placeholder={t("Company")}>
              {companies?.length > 0 &&
                companies.map((company: any, i: number) => (
                  <Option key={i} value={company.id}>
                    {company.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name={"title"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Input placeholder={t("title.BoardExperience")} />
          </Form.Item>
          <Form.Item name={"position"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Select placeholder={t("Board Role")}>
              {boardRoles.map((boardRole, i) => (
                <Option key={i} value={boardRole.value}>
                  {boardRole.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col span={7}>
              <Form.Item rules={[{ required: true, message: "Vui lòng chọn" }]} name="startDate">
                <DatePicker
                  onChange={(e: any) => {
                    boardForm.setFieldsValue({
                      endDate: e
                    })
                    setStartDate(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                rules={[
                  {
                    required: isEndDateDisable ? false : true,
                    message: isEndDateDisable ? "" : t("Please Select"),
                  },
                ]}
                name="endDate">
                <DatePicker
                  disabledDate={(current) => {
                    return current < startDate!;
                  }}
                  disabled={isEndDateDisable ? true : false}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Checkbox
                checked={isEndDateDisable}
                onChange={() => {
                  setIsEndDateDisable(!isEndDateDisable);
                }}
                style={{ marginTop: "4px" }}
              />
              <Typography.Text className="ml-1">{t("Still Current")}</Typography.Text>
            </Col>
          </Row>

          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t("Add Referee")}
                  </Button>
                </Form.Item>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "Name"]}
                      rules={[{ required: true, message: t("Name") + t("cannot be blank") }]}>
                      <Input placeholder={t("Name")} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "Mobile"]}
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp("(84|0[3|5|7|8|9])+([0-9]{8})"),
                          message: "Số điện thoại không đúng định dạng , Ví dụ : 0346556026",
                        },
                      ]}>
                      <Input type="number" placeholder={t("Phone Number")} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
          <Form.List name="committeeRoles">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t("Add Committee Role")}
                  </Button>
                </Form.Item>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, "Type"]}
                      rules={[{ required: true, message: "Tên không được để trống" }]}>
                      <Select placeholder={t("Select type")}>
                        {cmrType.map((type, i) => (
                          <Option key={i} value={type.value}>
                            {type.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "Role"]}
                      rules={[{ required: true, message: "Số điện thoại không được để trống" }]}>
                      <Select placeholder={t("Select Role")}>
                        {cmrRole.map((role, i) => (
                          <Option key={i} value={role.value}>
                            {role.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </DNModal>
    </Fragment>
  );
};

export default WorkCompany;
