/* eslint-disable */
//import { USER } from '../../Config/Constants/UserConstant';
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const USER = {
    SET_USER: "user/SET_USER",
    GET_USER: "user/GET_USER",
    GET_UPDATE_INFORMATION: "user/GET_UPDATE_INFORMATION",
};

export const setUser = (user) => ({
    type: USER.SET_USER,
    user,
});

const initialState = {
    user: {},
};

export const reducer = persistReducer(
    {
        storage,
        key: "user",
        blacklist: ["user", "isLoading"],
    },
    (state = initialState, action) => {
        switch (action.type) {
            case USER.SET_USER:
                return {
                    ...state,
                    user: action.user,
                };
            case REQUEST(USER.GET_USER):
                return {
                    ...state,
                    isLoading: true,
                };
            case FAILURE(USER.GET_USER):
                return {
                    ...state,
                    isLoading: false,
                };
            case SUCCESS(USER.GET_USER):
                return {
                    ...state,
                    user: action.payload.user,
                    isLoading: false,
                };
            case REQUEST(USER.GET_UPDATE_INFORMATION):
                return {
                    ...state,
                    isLoading: true,
                };
            case FAILURE(USER.GET_UPDATE_INFORMATION):
                return {
                    ...state,
                    isLoading: false,
                };
            case SUCCESS(USER.GET_UPDATE_INFORMATION):
                return {
                    ...state,
                    isLoading: false,
                };
            default:
                return state;
        }
    }
);
