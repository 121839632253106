import { gql } from "urql";

export const QueryAwards = gql`
  query Awards($languageId: String!, $merchantId: String!, $tokenUser: String, $targetId: String) {
    data: awards(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        targetId: $targetId
      }
    ) {
      items {
        id
        issuer
        year
        title
        description
      }
    }
  }
`;
export const QueryAward = gql`
  query Award($languageId: String!, $merchantId: String!, $id: String) {
   award(param: { languageId: $languageId, merchantId: $merchantId, id: $id }) {
      id
      issuer
      year
      title
      description
    }
  }
`;
