import React from "react";
import { Observable, Observer } from "../../../../Utils/Ob";
import Header from "./Header";
import PageLoading from "../../../Components/ComponentCommon/PageLoading";
// eslint-disable-next-line import/no-webpack-loader-syntax
import "./index.less";
import Footer from "./Footer";
import { useMount, useUnmount } from "react-use";
import { useLocation } from "react-router-dom";
import CourseHeader from "./CourseHeader";

const data = Observable({
  pageLoading: false,
  bodyHeight: window.innerHeight,
  headerHeight: 0,
});

///
window.maxcoachGlobal = data;
window.loadingQueue = [];
window.hideScrollY = function () {
  window.scrollTo(0, 0);
  document.body.style.overflowY = "hidden";
};
window.resetScrollY = function () {
  document.body.style.overflowY = "auto";
};
window.showPageLoading = (time = 1) => {
  window.hideScrollY();
  data.pageLoading = true;
  for (let i = 0; i < time; i++) {
    window.loadingQueue.push(true);
  }
};
window.hidePageLoading = () => {
  if (window.loadingQueue.length > 0) window.loadingQueue.pop();
  if (window.loadingQueue.length === 0) {
    data.pageLoading = false;
    window.resetScrollY();
  }
};
window.resetPageLoading = () => {
  window.loadingQueue = [];
  window.hidePageLoading();
};
window.isPageLoading = () => data.pageLoading;
function windowChange() {
  data.bodyHeight = window.innerHeight;
}
///

function MaxCoachLayout(props) {
  const location = useLocation();

  useMount(() => {
    window.addEventListener("resize", windowChange);
  });
  useUnmount(() => {
    window.removeEventListener("resize", windowChange);
  });

  const isCourseDetail = checkIfCourseLayout(location.pathname);

  return (
    <React.Fragment>
      {!isCourseDetail && <Header onHeightChange={(height) => (data.headerHeight = height)} />}
      {isCourseDetail && <CourseHeader onHeightChange={(height) => (data.headerHeight = height)} />}

      <div style={{ minHeight: "100vh" }}>{props.children}</div>

      {!isCourseDetail && <Footer />}
      <PageLoading show={data.pageLoading} />
    </React.Fragment>
  );
}

export function EmptyLayout(props) {
  useMount(() => {
    document.body.style = "#FFFFFF";
  });

  return <React.Fragment>{props.children}</React.Fragment>;
}

export default Observer(MaxCoachLayout);

function checkIfCourseLayout(pathname) {
  const patterns = ["/user-course-details", "/quiz/", "/usercoursedetails", "/video/", "/slide/"];

  for (const pattern of patterns) {
    if (pathname.startsWith(pattern)) {
      return true;
    }
  }

  return false;
}
