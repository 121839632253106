import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserCourses } from "../../../../../Services/CourseService";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import { setHeaderTranparentAction } from "../../../../../Actions/Layout";
import { useMount, useSetState, useUnmount } from "react-use";
import { useHistory } from "react-router-dom";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { Observer } from "../../../../../../Utils/Ob";
import { Skeleton, Empty, Tooltip, Pagination, Spin } from "antd";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import "./styles.less";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import { SpinIcon } from "../../../../ComponentCommon/PageLoading";
import {useTranslation} from "react-i18next"

function CourseList() {
  const {t} = useTranslation()
  const dispatch = useDispatch();

  dispatch(setHeaderTranparentAction(false));

  const history = useHistory();
  const [state, setState] = useSetState({
    courses: [],
    loading: false,
    itemPerPage: 12,
    totalItem: 0,
    currentPage: 0,
  });

  const _paginate = (indexPage) => {
    setState({ loading: true, currentPage: indexPage });
    QueryGraphQL.getCourse(state.itemPerPage * indexPage, state.itemPerPage)
      .then((data) => {
        if (data.courses.items?.length) {
          setState({
            courses: data.courses.items,
            totalItem: data.courses.totalCount,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };

 
  useEffect(()=>{
    _paginate(0)
  },[t])
  useUnmount(() => {
    window.resetPageLoading();
    window.resetScrollY();
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.loading]);

  let pageTitle = t("Learning Materials");
  return (
    <React.Fragment>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h1 className="title">{pageTitle}</h1>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div hidden={!state.loading}>
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              <div className={"col max-mb-30"}>
                <div className={"d-skeleton-image"}>
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <Skeleton active={true} />
              </div>
              <div className={"col max-mb-30"}>
                <div className={"d-skeleton-image"}>
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <Skeleton active={true} />
              </div>
              <div className={"col max-mb-30"}>
                <div className={"d-skeleton-image"}>
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <Skeleton active={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="sidebar-widget-wrapper pr-0 animate__animated animate__fadeInUp animate__faster"
        hidden={window.isPageLoading() || state.loading}>
        <div className="sidebar-widget">
          {/*<h3 className="sidebar-widget-title text-center">Khóa học của tôi</h3>*/}
          <div className="sidebar-widget-content">
            <div className="section section-padding-bottom">
              <div className="container">
                {!window.isPageLoading() && state.courses.length === 0 && (
                  <div style={{ textAlign: "center", height: "calc(100vh / 2)" }}>
                    <MCC>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Empty"} />
                    </MCC>
                  </div>
                )}
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                  {/*Courses*/}
                  {state.courses.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="col max-mb-30 animate__animated animate__fadeInUp"
                        onClick={() =>
                          history.push(`/course/${item.id}/${Common.rewriteUrl(item.name)}`)
                        }>
                        <div className="course-2">
                          <div className="thumbnail">
                            <Link to={"/"} className="image" onClick={(e) => e.preventDefault()}>
                              <span
                                style={{
                                  display: "inline-block",
                                  minHeight: 250,
                                  height: 250,
                                  width: "100%",
                                  background: "#C3C3C3",
                                }}>
                                <MCC className={"img-object-fit-container"}>
                                  <img
                                    className={"img-object-fit"}
                                    style={{ display: "inline-block" }}
                                    src={Common.getImageThumbnail(
                                      item.images,
                                      Configuration.imageFormatCoursesMaxcoach
                                    )}
                                    alt="Course"
                                  />
                                </MCC>
                              </span>
                            </Link>
                          </div>
                          <div className="info" style={{ height: "calc(100% - 250px)" }}>
                            <span className="date">                              
                              {Common.formatDateTime(item.createdDate, "dd/mm/yyyy")}
                            </span>
                            <h3 className="title">
                              <Tooltip title={item.name.length > 50 ? item.name : undefined}>
                                <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                  {Common.formatName(item.name, 50)}
                                </Link>
                              </Tooltip>
                            </h3>                            
                            <p>{Common.formatName(item.subDescription, 100)}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/*  */}
                </div>
                <div className="row max-mt-50">
                  <div className="col">
                    <ul className="pagination center" style={{ whiteSpace: "nowrap" }}>
                      <Pagination
                        total={state.totalItem}
                        pageSize={state.itemPerPage}
                        showSizeChanger={false}
                        showQuickJumper={state.totalItem / state.itemPerPage > 6}
                        responsive={true}
                        current={state.currentPage + 1}
                        onChange={(pageIndex) => {
                          _paginate(pageIndex - 1);
                        }}
                        itemRender={(page, type) => {
                          let renderText = page;
                          if (type === "prev")
                            renderText = (
                              <span style={{ display: "inline-block", marginRight: 16 }}>{t("Prev")}</span>
                            );
                          else if (type === "next") renderText = t("Next");
                          return (
                            <li>
                              <Link
                                to={"/"}
                                onClick={(e) => e.preventDefault()}
                                className={
                                  page - 1 === state.currentPage && type === "page"
                                    ? "active"
                                    : undefined
                                }>
                                {state.currentPage === page - 1 && (
                                  <div>
                                    {state.loadingFollow && type === "page" ? (
                                      <Spin indicator={SpinIcon} />
                                    ) : (
                                      <span>{renderText}</span>
                                    )}
                                  </div>
                                )}
                                {state.currentPage !== page - 1 && <span>{renderText}</span>}
                              </Link>
                            </li>
                          );
                        }}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Observer(CourseList);
