import { useState, useEffect,  } from "react";
import { useSearchHandle } from "../../../../../../../Utils/dn";
import { SelectProps, Select } from "antd";
import axios from "axios";
import Configuration from "../../../../../../../Config/Config";

export type IPlaceSearchProps = SelectProps<any> & {
  onPlace: (value: IPlaceDetail) => any;
  initialOptions?: Array<{
    label: string;
    value: any;
  }>;
  addressDetail?: IPlaceDetail;
};
export interface ILocation {
  lat: number | undefined;
  lng: number | undefined;
}

export interface IGeometry {
  location: ILocation;
}

export interface IPlaceDetail {
  place_id: string | undefined;
  formatted_address: string;
  geometry: IGeometry;
  name: string;
}

function PlaceSearch(props: IPlaceSearchProps) {
  const [searchValue, setSearchValue] = useState<string | undefined>("");
  const [options, setOptions] = useState<
    Array<{
      label: string;
      value: any;
    }>
  >(props.initialOptions || []);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined>(props.value);
  const { Option } = Select;
  useSearchHandle(() => {
    if (!searchValue) return;

    setLoading(true);
    axios
      .get("https://rsapi.goong.io/place/autocomplete?radius=3000", {
        params: {
          input: searchValue,
          api_key: Configuration.GOONG_MAP_KEY,
        },
      })
      .then((value) => {
        setLoading(false);
        const predictions: Array<any> = value?.data?.predictions || [];

        setOptions(
          predictions.map((v) => ({
            label: v.description,
            value: v.place_id,
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [searchValue]);
  useSearchHandle(() => {
    if (props.value === "_") return;

    axios
      .get("https://rsapi.goong.io/Place/Detail", {
        params: {
          api_key: Configuration.GOONG_MAP_KEY,
          place_id: value,
        },
      })
      .then((value) => {
        const place = value.data?.result as IPlaceDetail;

        if (props.addressDetail) {
          if (place.place_id !== props.addressDetail.place_id) props.onPlace(place);
        } else {
          props.onPlace(place);
        }
      });
  }, [props.value]);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Select
      size={"large"}
      showSearch={true}
      allowClear={true}
      onSearch={setSearchValue}
      loading={loading}
      options={options}
      filterOption={false}
      {...(props || {})}
    />
  );
}
export default PlaceSearch;
