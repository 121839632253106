import React from "react";
import Common from "../../../../../../Utils/Common";
import { useMount } from "react-use";

const {Swiper} = window;

const EventSpeaker = ({speakers}) => {
  useMount(() => {
    var speakerSlider = new Swiper('.speaker-slider', {
      watchSlidesVisibility: true,
      loop: false,
      spaceBetween: 30,
      slidesPerView: 5,
      breakpoints: {
        1200: {
          slidesPerView: 5
        },
        992: {
          slidesPerView: 4
        },
        768: {
          slidesPerView: 3
        },
        576: {
          slidesPerView: 2
        },
        320: {
          slidesPerView: 1
        }
      }
    })
  })
  return (
    <div className="speaker-section section section-padding-bottom">
      <div className="container section-border-bottom section-padding-bottom-90">
        <div className="row">
          <div className="col-12">
            {/* Section Title Start */}
            <div className="section-title text-center max-mb-50" data-aos="fade-up">
              <h2 className="title fz-48 heading-color">Diễn giả</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="speaker-slider swiper-container" >
          <div className="swiper-wrapper">
            {speakers.map(speaker => (
              <div className="swiper-slide">
                <div className="single-speaker">
                  <div className="image">
                    <img src={Common.getImageThumbnail(speaker.images)} alt="speaker" />
                  </div>
                  <div className="content">
                    <h6 className="speaker-name">{speaker.name}</h6>
                    {/*<div className="speaker-description">/ Designer</div>*/}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="event-speakers-description">
          Các diễn gia tài năng, nhiều kinh nghiệm sẽ mang đến những kiến thức hay đến các bạn
        </div>
      </div>
    </div>
  );
};

export default EventSpeaker;
