import React from "react";
import {
  StyledContainer,
  StyledFormBody,
  StyledFormBox,
  StyledFormFooter,
  StyledFormHeader,
  StyledLogoBox,
} from "./styledComponents";
import { useMeasure, useMount, useSetState } from "react-use";
import { Checkbox, Input } from "antd";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../Product/maxcoach/ProductDetail/styledComponents";
import Common from "../../../../../../Utils/Common";
import axios from "axios";
import Configuration from "../../../../../../Config/Config";
import Swal from "sweetalert2";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { getCommonStorage } from "../../../../../../Config/gql";

function MaxcoachLoginPage(props) {
  const [state, setState] = useSetState({
    login: "",
    password: "",
    loading: false,
  });
  const [containerRef, containerInfo] = useMeasure();

  const _login = (e) => {
    e.preventDefault();
    if (state.loading) return;

    setState({ loading: true });
    axios
      .post(window.config.urlApi + "/User/LoginByMerchantCode", {
        UserName: state.login,
        Password: state.password,
        MerchantCode: window.Merchant.data.code,
      })
      .then((res) => {
        if (res.data.Success) {
          localStorage.setItem(window.config.localStorageTokenId, res.data.Data.tokenId);
          localStorage.setItem(Configuration.userId, res.data.Data.Id);

          const done = () => {
            setState({ loading: false });
            Common.setCookie(Configuration.userId, res.data.Data.Id);

            if (props.location.state !== undefined) {
              window.location.href = props.location.state.from.pathname;
            } else {
              window.location.href = "/";
              
            }
          };

          //Add device token
          if (localStorage.getItem(Configuration.tokenDevice)) {
            let objData = {
              Id: res.data.Data.Id,
              DeviceToken: localStorage.getItem(Configuration.tokenDevice),
              UserToken: res.data.Data.tokenId,
              MerchantId: res.data.Data.MerchantId,
              Type: 1,
              ModifiedDate: Common.formatDateTime(new Date()),
              ModifiedBy: res.data.Data.Id,
            };
            Common.sendSyncCommand(
              "AddDeviceTokenUser",
              "User",
              objData,
              () => {
                done();
              },
              () => {
                done();
              }
            );
          } else {
            done();
          }
        } else {
          Swal.fire("Sai tên hoặc mật khẩu! Mời bạn đăng nhập lại.");
          setState({ loading: false });
        }
      });
  };

  useMount(() => {
    const { tokenId } = getCommonStorage();

    if (tokenId) {
      window.location.href = "/";
    }
  });

  const merchant = window.Merchant.data;
  const logoDark = JSON.parse(merchant.configuration)["DarkLogoPath"];  
  console.log(logoDark, "logo")
  const logo = Common.getImageThumbnail(merchant.images, "?mode=crop&width=180");
  const offset = window.innerHeight - containerInfo.height;

  return (
    <StyledContainer
      ref={containerRef}
      style={{
        paddingTop: Math.max(0, offset / 4),
      }}>
      <StyledLogoBox>
        <MCC>
          <img src={(logoDark != undefined && logoDark != "") ? logoDark  + "?mode=crop&width=180" : logo } alt="Logo" />
        </MCC>
      </StyledLogoBox>
      <StyledFormBox onSubmit={_login}>
        <StyledFormHeader>Sign In</StyledFormHeader>
        <StyledFormBody>
          <label>Email</label>
          <Input
            value={state.login}
            onChange={(event) => setState({ login: event.target.value })}
            required={true}
            type={"email"}
          />
          <label>Password</label>
          <Input
            value={state.password}
            onChange={(event) => setState({ password: event.target.value })}
            type={"password"}
            required={true}
          />
          <div className="remember-check" data-section={"RememberMe"}>
            <Checkbox>Remember Me</Checkbox>
            <Link to={"/RecoveryPassword"}>Forgot Password?</Link>
          </div>
          <StyledButton
            variant={"primary"}
            display={"block"}
            style={{ marginTop: 32 }}
            type={"submit"}>
            {state.loading && <ThreeDotsLoader width={40} height={18} fill={"#FFFFFF"} />}
            {!state.loading && "Login"}
          </StyledButton>
        </StyledFormBody>
        <StyledFormFooter>
          Don’t have an account? <Link to={"/SignUp"}>Sign Up</Link>
        </StyledFormFooter>
      </StyledFormBox>
    </StyledContainer>
  );
}

export default MaxcoachLoginPage;
