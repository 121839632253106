import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import { queryForm } from "../../../../ComponentCommon/BasePage/gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { StyledSurveyContainer } from "../../../Survey/styled";
import { SafeHtmlBox } from "../../../../ComponentCommon/SafeHtmlBox";
import { BasePage } from "../../../../ComponentCommon/BasePage";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import { notification } from "antd";

const bootstrap = `
<!-- Latest compiled and minified CSS -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
<!-- Optional theme -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<!-- Latest compiled and minified JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
<script src="/js/surveyEvents.js"></script>
`;

const EventForm = ({id}) => {
  const [fetchForm] = useQuery({
    query: queryForm,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        merchantId,
        id,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    const handleEvent = (e) => {
      window.showPageLoading();

      Common.sendSyncCommand(
        "SubmitForm",
        "CustomerRelationshipManagement",
        {
          Id: id,
          Data: JSON.stringify(e.detail),
          ModifiedBy: localStorage.getItem(Configuration.userId),
          ModifiedDate: Common.formatDateTime(new Date()),
        },
        () => {
          window.hidePageLoading();
          notification.success({
            message: "Survey has been submitted.",
          });
          setSubmitted(true);
        },
        () => {
          // @ts-ignore
          window.hidePageLoading();
          alert("Something went wrong!");
        }
      );
    };

    window.document.addEventListener("submitSurvey", handleEvent, false);

    return () => {
      window.document.removeEventListener("submitSurvey", handleEvent);
    };
  }, [id]);

  const form = fetchForm.data?.form;
  const htmlForm = `<form>${form?.description || ""}</form>`;

  if (!id) return null

  return (
    <div className="event-comment-section section section-padding-bottom">
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8">
            <div className="event-form">

              <StyledSurveyContainer submitted={submitted ? "yes" : "no"}>
                <SafeHtmlBox autoHeight={true} srcDoc={`${htmlForm}${bootstrap}`} />
              </StyledSurveyContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
