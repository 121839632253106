/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import SubcribeBox from "../../ComponentCommon/Box/SubcribeAuthor";
import { connect } from "react-redux";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

class Index extends Component {
    state = {
        newestArticles: [],
        newestSubcribeCategoryArticles: [],
        offset: 0,
        totalItem: null,
        itemPerPage: 4,
        arrayItemPerPage: [],
        currentItem: null,
        items: [],
    };
    componentDidMount() {
        QueryGraphQL.getNewestArticle(0, 5).then((data) => {
            this.setState({
                newestArticles: data.articles.items,
            });
        });
        QueryGraphQL.getSubcribeArticles(0, 0, 5, "").then((data) => {
            this.setState({
                newestSubcribeCategoryArticles: data.subcribearticlesbycategory.items,
            });
        });
        QueryGraphQL.subcribeUsers().then((data) => {
            if (data.subcribeusers.items.length) {
                this.setState({
                    items: data.subcribeusers.items,
                    currentItem: data.subcribeusers.items.slice(0, 1)[0].id,
                });

                QueryGraphQL.subcribeArticlesByUser(0, this.state.offset, this.state.itemPerPage, this.state.currentItem).then((data) => {
                    if (data.subcribearticlesbyuser.items.length) {
                        this.setState({
                            totalItem: data.subcribearticlesbyuser.totalCount,
                            arrayItemPerPage: data.subcribearticlesbyuser.items,
                        });
                    }
                });
            }
        });
    }
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.subcribeArticlesByUser(0, this.state.offset, this.state.itemPerPage, this.state.currentItem).then((data) => {
                    if (data.subcribearticlesbyuser.items.length) {
                        this.setState({
                            arrayItemPerPage: data.subcribearticlesbyuser.items,
                            totalItem: data.subcribearticlesbyuser.totalCount,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };
    changeCategory = (id) => {
        this.setState({ currentItem: id }, () => {
            QueryGraphQL.subcribeArticlesByUser(0, this.state.offset, this.state.itemPerPage, this.state.currentItem).then((data) => {
                if (data.subcribearticlesbyuser.items.length) {
                    this.setState({
                        arrayItemPerPage: data.subcribearticlesbyuser.items,
                        totalItem: data.subcribearticlesbyuser.totalCount,
                    });
                } else {
                    this.setState({
                        arrayItemPerPage: [],
                        totalItem: 0,
                    });
                }
            });
        });
    };
    render() {
        let { totalItem, popularArticle, latestArticle } = this.state;
        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div>
                <div className="container">
                    <h3 className="mt-6 mb-0"> Tác giả theo dõi</h3>
                    <div className="section-header mb-lg-3">
                        <div className="section-header-left">
                            <nav className="responsive-tab style-2">
                                <ul>
                                    {this.state.items.map(
                                        (item, i) => (
                                            <Fragment key={i}>
                                                <li
                                                    onClick={() => this.changeCategory(item.id)}
                                                    uk-filter-control={`[data-color='${item.id}']`}
                                                    className={item.id === this.state.currentItem ? "uk-active" : ""}>
                                                    <a href="javascript:;">{item.name}</a>
                                                </li>
                                            </Fragment>
                                        )
                                        // <li><a href="#">CSS</a></li>
                                        // <li><a href="#">HTML</a></li>
                                        // <li><a href="#">Coding</a></li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="uk-grid-large" uk-grid="true">
                        <div className="uk-width-expand@m">
                            {this.state.arrayItemPerPage.length > 0 ? (
                                this.state.arrayItemPerPage.map((item, i) => (
                                    <div className="blog-article">
                                        <Link to={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)}>
                                            {/* Blog Post Thumbnail */}
                                            <h2 className="text-capitalize">{Common.formatName(item.name, 75).toLowerCase()}</h2>
                                        </Link>
                                        <p className="blog-articl-meta">
                                            {/* <strong> Bootstrap </strong>
                                                <a href="user-profile.html"> Nataly Birch </a>
                                                • */}
                                            <span> {Common.formatDateTime(item.createdDate, "dd-mm-yyyy")} </span>
                                        </p>
                                        <Link to={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)}>
                                            <div className="blog-article-thumbnail">
                                                <div className="blog-article-thumbnail-inner">
                                                    <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogAuthorList)} alt={item.name} />
                                                </div>
                                            </div>
                                        </Link>
                                        <p className="blog-article-content">
                                            {Common.formatName(item.subDescription, 150)}
                                            <a href={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)}> đọc thêm</a>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>Cần chọn tác giả theo dõi.</p>
                            )}

                            <ul className="uk-pagination my-5 uk-flex-center">
                                {(() => {
                                    let tempArray = [];
                                    for (let index = 0; index < totalPage; index++) {
                                        tempArray.push(
                                            <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                                <span>{index + 1}</span>
                                            </li>
                                        );
                                    }
                                    return <Fragment>{tempArray}</Fragment>;
                                })()}
                            </ul>
                        </div>
                        <div className="uk-width-1-3@s">
                            <SubcribeBox />
                            <div className="uk-card-default rounded mt-4">
                                <ul className="uk-tab-bottom uk-child-width-expand uk-tab" uk-switcher="animation: uk-animation-fade">
                                    <li>
                                        <a href="#">Mới nhất</a>
                                    </li>
                                    <li>
                                        <a href="#">Chuyên mục</a>
                                    </li>
                                </ul>
                                <ul className="uk-switcher uk-margin">
                                    {/* tab 1 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                    {/* tab 2 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestSubcribeCategoryArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {}
const mapDispatchToProps = {
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
