import React from "react";
import {
  StyledContainer,
  StyledFormBody,
  StyledFormBox,
  StyledFormFooter,
  StyledFormHeader,
  StyledLogoBox,
} from "./styledComponents";
import { useMeasure, useMount, useSetState } from "react-use";
import { Checkbox, Input, message, Row, Col, Select } from "antd";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../Product/maxcoach/ProductDetail/styledComponents";
import Common from "../../../../../../Utils/Common";
import axios from "axios";
import Configuration from "../../../../../../Config/Config";
import Swal from "sweetalert2";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { hintTextColor } from "../../../../../../Utils/themeConfig";
import { getCommonStorage } from "../../../../../../Config/gql";

function MaxcoachRegisterPage(props) {
  const [state, setState] = useSetState({
    passportName: "",
    passportNum: "",
    country: "",
    countryCode: "84",
    salutation: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    mobile: "",
    ruleAgreed: false,
    loading: false,
  });
  const [containerRef, containerInfo] = useMeasure();
  const { Option } = Select;
  const _register = (e) => {
    e.preventDefault();
    const mobileRegExp = new RegExp("(84|0[3|5|7|8|9])+([0-9]{8})");
    if (state.loading) return;
    if (!mobileRegExp.test(state.mobile)) {
      return message.error("The phone number is not in the correct format");
    }
    if (!state.ruleAgreed) {
      return message.error("You have not agreed to our terms");
    }
    if (state.confirmPassword !== state.password) {
      return message.error("Password do not match!");
    }

    if (state.password.length < 8) {
      return message.error("Your password must be at least 8 characters!");
    }

    setState({ loading: true });

    const id = Common.guid();

    /*  Common.sendSyncCommand(
      "RegisterMemberMerchant",
      "CustomerRelationshipManagement",
      {
        Id: id,
        Name: state.fullName,
        Mobile: state.mobile,
        Email: state.email,
        MerchantCode: window.Merchant.data.code,
        Password: state.password,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: id,
      },
      () => {
        Swal.fire("Cảm ơn đã đăng ký tài khoản.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setState({ loading: false });
      },
      (e) => {
        console.log(e);
        Swal.fire(e?.Message || "Đăng kí thất bại!");
        setState({ loading: false });
      }
    ); */
  };

  useMount(() => {
    const { tokenId } = getCommonStorage();

    if (tokenId) {
      window.location.href = "/";
    }
  });

  const merchant = window.Merchant.data;
  const logo = Common.getImageThumbnail(merchant.images, "?mode=crop&width=180");
  const offset = window.innerHeight - containerInfo.height;

  return (
    <StyledContainer
      ref={containerRef}
      style={{
        paddingTop: Math.max(0, offset / 4),
      }}>
      <StyledLogoBox>
        <MCC>
          <img src={logo} alt="Logo" />
        </MCC>
      </StyledLogoBox>
      <StyledFormBox onSubmit={_register}>
        <StyledFormHeader>Sign Up</StyledFormHeader>
        <StyledFormBody>
          <label>Salutation</label>
          <Select size="large" onChange={(e) => setState({ salutation: e })}>
            <Option value="Ms">Ms</Option>
            <Option value="Mr">Mr</Option>
          </Select>
          <Row gutter={12}>
            <Col span={12}>
              <label>First name</label>
              <Input
                value={state.firstName}
                onChange={(event) => setState({ firstName: event.target.value })}
                required={true}
                placeholder={"Your name"}
              />
            </Col>
            <Col span={12}>
              <label>Last name</label>
              <Input
                value={state.lastName}
                onChange={(event) => setState({ lastName: event.target.value })}
                required={true}
                placeholder={"Your name"}
              />
            </Col>
          </Row>
          <label>Choose Passport</label>
          <Row>
            <Col span={10}>
              <select placeholder="Passport No.">
                <option>PassPort1</option>
                <option>PassPort2</option>
              </select>
            </Col>
            <Col span={14}>
              <Input placeholder="Passport Number" type="number" />
            </Col>
          </Row>
          <label>Choose Country</label>
          <Row>
            <Col span={10}>
              <select
                onChange={(e) => setState({ countryCode: e.target.value })}
                placeholder="Country">
                <option value="84">VIETNAM</option>
                <option value="36">FRANCE</option>
                <option value="25">GERMANY</option>
              </select>
            </Col>
            <Col span={14}>
              <Input value={state.countryCode} readOnly placeholder="Country Code" type="number" />
            </Col>
          </Row>
          <label>Email</label>
          <Input
            value={state.email}
            onChange={(event) => setState({ email: event.target.value })}
            required={true}
            type={"email"}
            placeholder={"Email"}
          />
          <label>Phone number</label>
          <Input
            value={state.mobile}
            onChange={(event) => setState({ mobile: event.target.value })}
            required={true}
            type={"number"}
            placeholder={"Your phone number"}
          />
          <label>Password</label>
          <Input
            value={state.password}
            onChange={(event) => setState({ password: event.target.value })}
            type={"password"}
            required={true}
            placeholder={"Password"}
          />
          <label>Confirm password</label>
          <Input
            value={state.confirmPassword}
            onChange={(event) => setState({ confirmPassword: event.target.value })}
            type={"password"}
            required={true}
            placeholder={"Confirm password"}
          />
          <Checkbox
            onChange={(e) => setState({ ruleAgreed: e.target.checked })}
            checked={state.ruleAgreed}
          />
          <span style={{ marginLeft: "8px" }}>
            I agree to accept The Term of Use and Privacy Policy
          </span>
          <StyledButton
            variant={"primary"}
            display={"block"}
            style={{ marginTop: 32 }}
            type={"submit"}>
            {state.loading && <ThreeDotsLoader width={40} height={18} fill={"#FFFFFF"} />}
            {!state.loading && "Created"}
          </StyledButton>
        </StyledFormBody>
        <StyledFormFooter>
          <Link to={"/login"}>Back to login</Link>
        </StyledFormFooter>
      </StyledFormBox>
    </StyledContainer>
  );
}

export default MaxcoachRegisterPage;
