import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const { themes } = window;
const THEME_ROUTES = [
  "/home",
  "/usercourse",
  "/course",
  "/quiz/",
  "/user-course-details/",
  "/usercoursedetails/",
  "/profile-edit",
  "/personal-details-edit",
  "/profile",
  "/blogAuthor",
  "/blog",
  "/author-blog",
  "/product",
  "/home",
  "/calendar",
  "/change-password",
  "/video/",
  "/slide/",
  "/notifications-list",
  "/book",
  "/accessible-blog",
  "/purchase-detail",
  "/survey",
  "/zoom",
  "/event",
  "/contest",
  "/product-exchange-point",
  "/product-purchase",
  "/work",
  "/board",
  "/cv/preview",
  "/certificate",
  "/education",
  "/profile/qualification",
  "/profile/membership",
  "/profile/award",
  "/profile/contact",
  "/shopping-cart",
  "/wishlist",
  // "/login",
];
const IGNORES = ["/productmerchant"];
const EXACTS = ["/"];

function AutoTheming() {
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("theme") === "maxcoach") {
      const tempTheme = localStorage.getItem("temp-theme");

      if (
        (checkPathname(location.pathname, THEME_ROUTES) &&
          !checkPathname(location.pathname, IGNORES)) ||
        EXACTS.includes(location.pathname)
      ) {
        if (tempTheme) {
          localStorage.removeItem("temp-theme");
          window.location.reload();
        }
      } else {
        if (!tempTheme) {
          localStorage.setItem("temp-theme", "default");
          window.location.reload();
        }
      }
    }
  }, [location]);

  return null;
}

export default AutoTheming;

function checkPathname(pathname, patterns) {
  for (const pattern of patterns) {
    if (pathname.startsWith(pattern)) {
      return true;
    }
  }

  return false;
}
