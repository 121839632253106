import { useQuery } from "urql";
import { queryMeeting, queryZoom } from "../../ComponentCommon/BasePage/gql";
import { getCommonStorage } from "../../../../Config/gql";
import React from "react";
import { BasePage } from "../../ComponentCommon/BasePage";
import Countdown from "react-countdown";
import { Button, Tabs } from "antd";
import { RenderBookItem } from "../Books/maxcoach/Books";
import { RenderItem as RenderBlogItem } from "../Blog/maxcoach/PublicBlog";
import { RenderItem as RenderCourseItem } from "../Product/maxcoach/Products";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import MCC from "../../ComponentCommon/MiddleContent";

function ZoomDescription(props: { zoomMeeting: any }) {
  if (!props.zoomMeeting) return null;

  return (
    <div>
      <table className={"table-auto"}>
        <tbody>
          <tr>
            <td className={"font-bold"}>Giờ bắt đầu:</td>
            <td className={"underline px-4"}>
              <Countdown date={props.zoomMeeting.startTime} />
            </td>
          </tr>
          <tr>
            <td className={"font-bold"}>Thời lượng dự kiến:</td>
            <td className={"underline px-4"}>{props.zoomMeeting.duration} phút</td>
          </tr>
          <tr>
            <td className={"font-bold"}>Số lượng người tham dự:</td>
            <td className={"underline px-4"}>{props.zoomMeeting.totalParticipants}</td>
          </tr>
        </tbody>
      </table>
      <p
        className={"mt-8 mb-4"}
        dangerouslySetInnerHTML={{
          __html: props.zoomMeeting.description,
        }}
      />
    </div>
  );
}

function JoinMeeting(props: { fetchForm: any; zoomMeeting: any }) {
  return (
    <div className={"min-h-screen mb-32 w-full p-4"}>
      {props.fetchForm.fetching && <div className={"py-8"}>Loading...</div>}
      {props.zoomMeeting?.url && (
        <div className={"mb-24 w-full min-h-full"}>
          <iframe
            src={`${props.zoomMeeting?.url?.replace("/zoom", "/zoom.html")}`}
            className={"min-h-screen w-full"}
            title={"Join"}
          />
        </div>
      )}
      {props.zoomMeeting && !props.zoomMeeting?.url && (
        <div className={"flex items-center w-full h-full justify-center"}>Phòng không tồn tại.</div>
      )}
    </div>
  );
}

function Books(props: { books: Array<any> }) {
  return (
    <div className="my-8 row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
      {props.books.map((book: any, i: number) => {
        const files = book?.files || [];

        if (files.length === 0) {
          return null;
        }

        return (
          <RenderBookItem
            item={book}
            key={i}
            index={i}
            onClick={() => {
              const win = window.open("/bookRead?file=" + encodeURIComponent(files[0].path));
              // @ts-ignore
              win.focus();
              // if (files.length === 1) {
              //   const win = window.open(
              //     "/bookRead?file=" + encodeURIComponent(files[0].path)
              //   );
              //   // @ts-ignore
              //   win.focus();
              // } else {
              //   // setState({ selectedBook: book });
              // }
            }}
          />
        );
      })}
    </div>
  );
}

function Blogs(props: { blogs: Array<any> }) {
  return (
    <div className="my-8 row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
      {props.blogs.map((v: any, i: number) => (
        <RenderBlogItem
          article={{
            categories: [],
            ...v,
          }}
          newTab={true}
          key={i}
        />
      ))}
    </div>
  );
}

function RenderVideoItem(props: { onClick: () => void; item: any }) {
  return (
    <div className="grid-item col max-mb-30" data-aos="fade-up" onClick={props.onClick}>
      <div className="blog-2">
        <div className="thumbnail">
          <span className="image" style={{ position: "relative" }}>
            <img
              src={Common.getImageThumbnail(props.item.images, Configuration.imageFormatSlide)}
              alt="Blog"
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}>
              <MCC>
                <i
                  className="fas fa-play"
                  style={{
                    fontSize: "4em",
                    color: "rgba(255, 255, 255, 0.75)",
                    textShadow:
                      "0px 4px 3px rgba(0,0,0,0.2), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)",
                  }}
                />
              </MCC>
            </div>
          </span>
        </div>
        <div className="info">
          <span className="category cut-text">{Common.rewriteUrl(props.item.name)}</span>
          <h3 className="title">{props.item.name}</h3>
          <p style={{ marginTop: 5 }}>{props.item.subDescription}</p>
        </div>
      </div>
    </div>
  );
}

function Videos(props: { videos: Array<any> }) {
  return (
    <div className="my-8 isotope-grid row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
      {props.videos.map((item: any, i: number) => (
        <RenderVideoItem
          key={i}
          onClick={() => {
            const win = window.open(`/video/${item.id}/${Common.rewriteUrl(item.name)}`);

            win?.focus();
          }}
          item={item}
        />
      ))}
    </div>
  );
}

export default function ZoomPage(props: any) {
  const { id } = props.match.params;
  const [fetchForm] = useQuery({
    query: queryZoom,
    variables: (() => {
      const { tokenId, merchantId, languageId } = getCommonStorage();

      return {
        languageId,
        tokenId,
        id,
        merchantId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const [fetchMeeting] = useQuery({
    query: queryMeeting,
    variables: (() => {
      const { tokenId, merchantId, languageId } = getCommonStorage();

      return {
        languageId,
        tokenId,
        id,
        merchantId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const zoomMeeting = fetchForm.data?.zoommeeting;
  const meeting = fetchMeeting.data?.meeting;
  const books = zoomMeeting?.books || [];
  const blogs = zoomMeeting?.blogs || [];
  const courses = zoomMeeting?.courses || [];
  const videos = zoomMeeting?.videos || [];

  console.log("zoomMeeting", zoomMeeting, meeting);

  return (
    <BasePage title={zoomMeeting?.name || "Meeting"}>
      <ZoomDescription zoomMeeting={zoomMeeting} />
      <Tabs>
        <Tabs.TabPane tab={"Join Meetting"} key={1}>
          {/*<JoinMeeting fetchForm={fetchForm} zoomMeeting={zoomMeeting} />*/}
          <Button
            onClick={() => {
              const newwindow = window.open(meeting.invitationUrl, "Join", "height=600,width=600");

              if ((window as any).focus) {
                (newwindow as any).focus();
              }
              return false;
            }}
            loading={fetchMeeting.fetching}
            type={"primary"}
            size={"large"}>
            <span
              style={{
                display: "inline-block",
                paddingLeft: "5rem",
                paddingRight: "5rem",
              }}>
              Join
            </span>
          </Button>
        </Tabs.TabPane>
        {books.length > 0 && (
          <Tabs.TabPane tab={"Books"} key={2}>
            <Books books={books} />
          </Tabs.TabPane>
        )}
        {blogs.length > 0 && (
          <Tabs.TabPane tab={"Blogs"} key={3}>
            <Blogs blogs={blogs} />
          </Tabs.TabPane>
        )}
        {courses.length && (
          <Tabs.TabPane tab={"Courses"} key={4}>
            <div className="my-8 row row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              {courses.map((v: any, i: number) => (
                <RenderCourseItem item={v} key={i} index={i} type={"my-course"} newTab={true} />
              ))}
            </div>
          </Tabs.TabPane>
        )}
        {videos.length > 0 && (
          <Tabs.TabPane tab={"Videos"} key={5}>
            <Videos videos={videos} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </BasePage>
  );
}
