/* eslint-disable */
import React, { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import Configuration from "../../../../../Config/Config";

function DetailQuiz(props) {
    let { dataQuestion = {} } = props;
    let ComponentQuiz = [];
    let arrayNewAnswer = []; // k dùng state vì k cần render lại
    let answers = dataQuestion.answers;
    const { handleSubmit, control, getValues, setValue, reset } = useForm({
        defaultValues: dataQuestion,
    });

    const QuizVideo = useSelector((state) => state.QuizVideo);
    const callBackCancel = () => {
        props.toggleTooltipQuestion();
    };

    const updateAnswerQuestion = (data) => {
        let { player, dataUserDo } = QuizVideo;
        let dataUser = [...dataUserDo];
        let tempData = dataUser.filter((e) => e.id === data.id);
        if (tempData.length) {
            // check update câu trả lời
            dataUser = dataUser.map((e) => {
                if (e.id === data.id) {
                    return (e = data);
                }
                return e;
            });
        } else {
            // thêm câu trả lời
            dataUser.push(data);
        }
        props.updateDataUserDo(dataUser);
        props.toggleTooltipQuestion(false);

        player.seekTo(player.getCurrentTime() + 1, true);
        player.playVideo();
    };
    const onSubmit = (data) => {
        let idQuestion = dataQuestion.id;
        updateAnswerQuestion({
            id: idQuestion,
            answers: arrayNewAnswer,
        });
    };
    const changeAnswerShortAnswer = (event) => {
        let text = event.target.value;
        let tempAnswer = arrayNewAnswer.filter((e) => e.id === dataQuestion.id);
        if (tempAnswer.length) {
            arrayNewAnswer = arrayNewAnswer.map((data) => {
                if (dataQuestion.id === data.id) {
                    return {
                        id: dataQuestion.id,
                        name: text,
                    };
                } else return data;
            });
        } else {
            arrayNewAnswer.push({
                id: dataQuestion.id,
                name: text,
            });
        }
    };
    const changeAnswer = (event, element) => {
        // let answers = [...dataQuestion.answers]
        let tempAnswer = arrayNewAnswer.filter((e) => e.id === element.id);
        if (tempAnswer.length) {
            arrayNewAnswer = arrayNewAnswer.map((data) => {
                if (element.id === data.id) {
                    return {
                        id: element.id,
                        name: element.name,
                    };
                } else return data;
            });
        } else {
            arrayNewAnswer.push({
                id: element.id,
                name: "",
            });
        }
    };
    ComponentQuiz.push(<h6>{dataQuestion.name}</h6>);
    let htmlAnswer = [];
    switch (dataQuestion.type) {
        case Configuration.answerType.trueFalse.type:
        case Configuration.answerType.multiChoice.type:
            answers.forEach((element, i) => {
                htmlAnswer.push(
                    <FormGroup className="text-left" check key={i}>
                        <Label check>
                            <Input type="radio" name="radio" onChange={(event) => changeAnswer(event, element)} value={element.isCorrect} /> {element.name}
                        </Label>
                    </FormGroup>
                );
            });
            ComponentQuiz.push(<Controller as={<Fragment>{htmlAnswer}</Fragment>} name="answers" control={control} />);
            break;
        case Configuration.answerType.multiAnswer.type:
            answers.forEach((element, i) => {
                htmlAnswer.push(
                    <FormGroup className="text-left" check key={i}>
                        <Label check>
                            <Input type="checkbox" name="radio" onChange={(event) => changeAnswer(event, element)} value={element.isCorrect} /> {element.name}
                        </Label>
                    </FormGroup>
                );
            });
            ComponentQuiz.push(<Controller as={<Fragment>{htmlAnswer}</Fragment>} name="answers" control={control} />);
            break;
        case Configuration.answerType.shortAnswer.type:
            htmlAnswer = <Input type="textarea" onChange={(event) => changeAnswerShortAnswer(event)} placeholder="Nhập câu trả lời" />;
            ComponentQuiz.push(<Controller as={<Fragment>{htmlAnswer}</Fragment>} name="answers" control={control} />);
            break;
        case Configuration.answerType.fillInTheBank.type:
            let tempArray = dataQuestion.name.split("_");
            tempArray.forEach((element, i) => {
                htmlAnswer.push(
                    <Fragment>
                        <Label>{element}</Label>
                        <Col>
                            <Input type="text" placeholder="Nhập câu trả lời" />
                        </Col>
                    </Fragment>
                );
            });
            ComponentQuiz.push(
                <Controller
                    as={
                        <FormGroup row className="align-items-center">
                            {htmlAnswer}
                        </FormGroup>
                    }
                    name="answers"
                    control={control}
                />
            );
            break;
        default:
            break;
    }
    return (
        <form className="container form-answer" onSubmit={handleSubmit(onSubmit)}>
            {ComponentQuiz}
            <div className="row d-flex justify-content-end mt-2">
                <Button
                    color="danger"
                    size="sm"
                    className="mr-2"
                    onClick={(event) => {
                        callBackCancel();
                    }}>
                    Hủy
                </Button>{" "}
                <Button color="success" type="submit" size="sm">
                    Lưu
                </Button>
            </div>
        </form>
    );
}
const mapState = (state) => ({
    // isOpenQuestion: state.QuizVideo.isOpenQuestion,
});

const mapDispatch = (store) => ({
    toggleTooltipQuestion: store.QuizVideo.toggleTooltipQuestion,
    updateDataUserDo: store.QuizVideo.updateDataUserDo,
});

export default connect(mapState, mapDispatch)(DetailQuiz);
