import { styled, size } from "../../../../../../../Utils/stitches.config";
import { primaryColor } from "../../../../../../../Utils/themeConfig";

export const Container = styled("div", {
  ".dx-left-side": {
    textAlign: "center",
    borderRight: 0,
    padding: "0 64px",
    paddingBottom: 40,
    [size.lg]: {
      borderRight: "1px solid #EFF0F1",
      paddingBottom: 0,
    },
    span: {
      position: "relative",
      display: "inline-block",
      cursor: "pointer",
    },
    h3: {
      margin: 0,
      marginTop: 24,
      fontSize: 18,
      lineHeight: "22px",
      color: "var(--primary-text-color)",
    },
    p: {
      fontSize: 14,
      lineHeight: "17px",
      color: "#999FAE",
      marginTop: 8,
    },
  },
  ".dx-avatar": {
    width: 86,
    height: 86,
    objectFit: "cover",
    borderRadius: "50%",
    overflow: "hidden",
    filter: "drop-shadow(0px 4px 10px rgba(49, 111, 234, 0.25))",
  },
  ".dx-capture": {
    width: 28,
    height: 28,
    background: "#fff",
    boxShadow: "0px 4px 10px rgba(125, 125, 125, 0.25)",
    borderRadius: "50%",
    position: "absolute",
    right: 0,
    bottom: 0,
    color: "var(--primary-text-color)",
  },
  ".dx-right-side": {
    color: "var(--primary-text-color)",
    padding: "0 15px",
    [size.lg]: {
      padding: "0 64px",
    },
    h3: {
      fontSize: 24,
      lineHeight: "29px",
      color: "var(--primary-text-color)",
      margin: 0,
      marginBottom: 46,
    },
    ".ant-input": {
      fontSize: 16,
      lineHeight: "20px",
      color: "var(--primary-text-color)",
      background: "#fff",
      maxHeight: 56,
      height: 56,
      borderColor: "#999fae",
    },
    label: {
      fontSize: 16,
      lineHeight: "20px",
      color: "var(--primary-text-color)",
      fontWeight: "bold",
      marginBottom: 8,
    },
    ".row": {
      marginLeft: 0,
      [size.lg]: {
        marginLeft: "-40px !important",
      },
    },
    ".col-12, .col-6": {
      paddingLeft: 0,
      marginBottom: 48,
      [size.lg]: {
        paddingLeft: 40,
      },
    },
    ".ant-input[disabled]": {
      background: "#fff",
      color: "#999FAE",
    },
    ".ant-picker": {
      display: "block",
      padding: "0 11px 0 0",
      border: "1px solid #999fae",
      borderRadius: 5,
      input: {
        fontSize: 16,
        lineHeight: "20px",
        color: "rgb(17, 18, 55)",
        padding: "3px 20px",
        minHeight: 54,
      },
    },
    ".ant-btn": {
      minHeight: 54,
      fontSize: 16,
      lineHeight: "20px",
      color: "var(--primary-text-color)",
      fontWeight: "bold",
      borderRadius: 10,
      padding: "4px 22px",
      minWidth: "auto",
      [size.lg]: {
        minWidth: 200,
      },
    },
    ".btn-default": {
      background: "#EFF0F1",
      border: "1px solid #EFF0F1",
    },
    ".btn-primary": {
      background: primaryColor,
      border: "1px solid " + primaryColor,
      color: "#fff !important",
    },
  },
});
export const ChangePasswordSection = styled("div", {
  label: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "bold",
    marginTop: 24,
    marginBottom: 8,
  },
  ".dx-divider": {
    marginTop: 24,
    borderTop: "1px solid #EFF0F1",
    width: "100%",
  },
  ".ant-input-affix-wrapper": {
    padding: "0px 20px",
    border: "1px solid #999FAE",
    borderRadius: 5,
  },
});
export const Title = styled("div", {
  color: "var(--primary-text-color)",
  fontSize: 24,
  lineHeight: "29px",
  fontWeight: "bold",
  textAlign: "center",
});
export const FooterSection = styled("div", {
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 24,
  fontSize: 16,
  lineHeight: "19px",
  ".btn": {
    width: "100%",
    ":hover": {
      background: primaryColor,
      opacity: 0.75,
    },
  },
  ".btn-primary:focus, .btn-primary:hover": {
    background: primaryColor,
  },
});
export const HawkEye = styled("span", {
  display: "inline-block",
  color: "rgb(153, 179, 173)",
  fontSize: 18,
  cursor: "pointer",
});
