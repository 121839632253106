/* eslint-disable */
import { Observable, Observer } from "../../../../../../Utils/Ob";
import React, { Fragment, useEffect } from "react";
import { message, Skeleton } from "antd";
import "./styles.less";
import _ from "lodash";
import { CourseHeaderState } from "../index";
import Configuration from "../../../../../../Config/Config";
import { seenLessonItemAction, unlockCourseLessonUserAction } from "../../../../../Actions/Course";
import {
  StyledExpandButton,
  StyledIconBox,
  StyledLessonBox,
  StyledLessonHeader,
  StyledSubDescription,
} from "./styledComponents";
import { useSetState } from "react-use";

const state = Observable({
  userCourse: undefined,
  selected: null,
  showCompleteButton: false,
  renderLastQuiz: false,
  reloadCourse: false,
  reloadingCourse: false,
  currentIsLastQuiz: false,
});

function CourseList() {
  const [expanded, setExpanded] = useSetState({});

  const _clickLesson = (lesson, item, itemType, courseType) => {
    state.renderLastQuiz = false;
    state.currentIsLastQuiz = false;

    let userCourse = _.cloneDeep(state.userCourse);
    let id = lesson.id;
    let itemId = item.id;

    if (!lesson.locked) {
      switch (userCourse.type) {
        case Configuration.courseType.timeTable:
          message.error("Chưa đến thời gian mở bài. Mời xem lịch để biết thêm chi tiết.");
          break;
        case Configuration.courseType.conditionQuiz:
          message.error("Bạn cần hoàn thành quiz để mở bài tiếp theo.");
          break;
        case Configuration.courseType.sequence:
          message.error("Bạn cần xem tất cả nội dung bài học để mở bài tiếp theo.");
          break;
        default:
          break;
      }
      return;
    }

    if (!item.seen) {
      seenLessonItemAction(itemId, courseType);
    }

    userCourse.courseLessons.forEach((lesson, index) => {
      if (lesson.id === id) {
        switch (itemType) {
          case "slide":
            userCourse.courseLessons[index].slides.forEach((slide, indexSlide) => {
              if (slide.id === itemId) {
                userCourse.courseLessons[index].slides[indexSlide].seen = true;
              }
            });
            break;
          case "video":
            userCourse.courseLessons[index].videos.forEach((video, indexVideo) => {
              if (video.id === itemId) {
                userCourse.courseLessons[index].videos[indexVideo].seen = true;
              }
            });
            break;
          case "quizz":
            userCourse.courseLessons[index].quizs.forEach((quiz, indexQuiz) => {
              if (quiz.id === itemId) {
                userCourse.courseLessons[index].quizs[indexQuiz].seen = true;
              }
            });
            break;
          case "videoQuizs":
            userCourse.courseLessons[index].videoQuizs.forEach((quiz, indexQuiz) => {
              if (quiz.id === itemId) {
                userCourse.courseLessons[index].videoQuizs[indexQuiz].seen = true;
              }
            });
            break;
          case "article":
            userCourse.courseLessons[index].articles.forEach((quiz, indexQuiz) => {
              if (quiz.id === itemId) {
                userCourse.courseLessons[index].articles[indexQuiz].seen = true;
              }
            });
            break;
          default:
            break;
        }
      }
    });

    state.userCourse = userCourse;

    if (state.userCourse?.type === Configuration.courseType.sequence) {
      let foundIndex = _.findIndex(userCourse.courseLessons, (v) => v.id === lesson.id);

      if (foundIndex >= 0 && foundIndex < userCourse.courseLessons.length - 1) {
        if (userCourse.courseLessons[foundIndex + 1].locked === false) {
          let flag = true;
          let tempLesson = userCourse.courseLessons.find((e) => e.id === id);

          if (tempLesson.slides.filter((e) => e.seen === false).length > 0) {
            flag = false;
          }

          if (tempLesson.videos.filter((e) => e.seen === false).length > 0) {
            flag = false;
          }

          if (tempLesson.quizs.filter((e) => e.seen === false).length > 0) {
            flag = false;
          }

          if (tempLesson.videoQuizs.filter((e) => e.seen === false).length > 0) {
            flag = false;
          }

          if (tempLesson.articles.filter((e) => e.seen === false).length > 0) {
            flag = false;
          }

          if (flag) {
            unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
            userCourse.courseLessons[foundIndex + 1].locked = true;
            state.userCourse = userCourse;
          }
        }
      }
    }
    else if (state.userCourse?.type === Configuration.courseType.conditionQuiz){
      let foundIndex = _.findIndex(userCourse.courseLessons, (v) => v.id === lesson.id);

      if (foundIndex >= 0 && foundIndex < userCourse.courseLessons.length - 1) {
        if (userCourse.courseLessons[foundIndex + 1].locked === false) {
          let flag = true;
          let tempLesson = userCourse.courseLessons.find((e) => e.id === id);

          if (tempLesson.quizs.length == 0 && tempLesson.videoQuizs.length == 0) {
            if (tempLesson.slides.filter((e) => e.seen === false).length > 0) {
              flag = false;
            }
  
            if (tempLesson.videos.filter((e) => e.seen === false).length > 0) {
              flag = false;
            }
  
            if (tempLesson.articles.filter((e) => e.seen === false).length > 0) {
              flag = false;
            }
            
            if (flag) {
              unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
              userCourse.courseLessons[foundIndex + 1].locked = true;
              state.userCourse = userCourse;
            }
          }
          
        }
      }
    }

    // Check & show Complete course
    // if (!item.seen) {
    state.showCompleteButton = false;

    if (userCourse.certificate || userCourse.quizs.length > 0) {
      let lastLesson;
      let allIsSeen = true;

      _.forEach(userCourse.courseLessons, (lesson) => {
        const items = lesson.articles
          .concat(lesson.slides)
          .concat(lesson.videos)
          .concat(lesson.quizs)
          .concat(lesson.videoQuizs);

        for (const item of items) {
          if (!item.seen) {
            allIsSeen = false;

            return false;
          }
        }
      });

      if (allIsSeen) {
        state.showCompleteButton = true;

        // for (let i = userCourse.courseLessons.length - 1; i >= 0; i--) {
        //   let lesson = userCourse.courseLessons[i];
        //
        //   if (lesson.relations && lesson.relations.length > 0) {
        //     lastLesson = lesson;
        //     break;
        //   }
        // }
        //
        // if (lastLesson) {
        //   let lastLessonId = lastLesson.relations[lastLesson.relations.length - 1].relationId;
        //
        //   if (lastLessonId === itemId) {
        //     state.showCompleteButton = true;
        //   }
        // }
      }
    }
    // }
    //

    CourseHeaderState.showDrawer = false;
    state.selected = { lesson, item, itemType, courseType };
  };

  useEffect(() => {
    if (state.userCourse?.courseLessons?.length > 0) {
      let allLocked = true;
      const newExpanded = {};

      _.forEach(state.userCourse.courseLessons, (lesson) => {
        if (!(lesson.id in expanded)) {
          newExpanded[lesson.id] = false;
        }

        if (lesson.locked) {
          allLocked = false;
          return false;
        }
      });

      if (allLocked) {
        let userCourse = _.cloneDeep(state.userCourse);
        userCourse.courseLessons[0].locked = true;
        state.userCourse = userCourse;
      }

      setExpanded(newExpanded);
    }
  }, [state.userCourse]);
  useEffect(() => {
    if (state.selected?.item) {
      try{

        localStorage.setItem("current_course", JSON.stringify(state.selected?.item));
      }
      catch(error){
        console.log(error,"line 228")
      }
    } else {
      localStorage.removeItem("current_course");
    }
  }, [state.selected]);

  return (
    <div className={"menu-body course-curriculum"}>
      {!state.userCourse && <RenderLoading />}
      {state.userCourse && (
        <Fragment>
          <ul className="curriculum-sections">
            {(state.userCourse.courseLessons || []).map((lesson, i) => {
              const types = {
                0: "article",
                1: "slide",
                2: "video",
                3: "quizz",
                4: "videoQuizs",
                quizz: 300,
                slide: 140,
                video: 1500,
                article: 140,
                videoQuizs: 300,
              };
              let items = [];

              _.forEach(lesson.relations, (relation) => {
                let type = types[+relation.relationEntity];
                let source;

                if (type === "quizz") {
                  source = lesson.quizs;
                }
                if (type === "videoQuizs") {
                  source = lesson.videoQuizs;
                } else if (type === "slide") {
                  source = lesson.slides;
                } else if (type === "video") {
                  source = lesson.videos;
                } else if (type === "article") {
                  source = lesson.articles;
                }

                let item = _.find(source, (v) => v.id === relation.relationId);

                if (item) {
                  items.push({
                    ...item,
                    _type: type,
                  });
                }
              });

              return (
                <StyledLessonBox key={i}>
                  <div className="section-header">
                    <StyledLessonHeader>
                      <h5>{lesson.name}</h5>
                      <StyledExpandButton
                        onClick={() => {
                          setExpanded({
                            [lesson.id]: !expanded[lesson.id],
                          });
                        }}>
                        <i className={"fas fa-chevron-" + (expanded[lesson.id] ? "down" : "up")} />
                      </StyledExpandButton>
                    </StyledLessonHeader>
                  </div>
                  <ul className="section-content" hidden={expanded[lesson.id]}>
                    {items.map((item, i) => {
                      if (!state.selected && lesson.locked) {
                        _clickLesson(lesson, item, item._type, types[item._type]);
                      }
                      return (
                        <RenderItem
                          key={i}
                          bg={i % 2 === 0}
                          type={item._type}
                          data={item}
                          lesson={lesson}
                          active={
                            state.selected?.lesson.id === lesson.id &&
                            state.selected?.item.id === item.id
                          }
                          onClick={() => _clickLesson(lesson, item, item._type, types[item._type])}
                        />
                      );
                    })}
                  </ul>
                </StyledLessonBox>
              );
            })}
          </ul>
        </Fragment>
      )}
    </div>
  );
}

function RenderItem(props) {
  let containerClasses = ["d-lesson"];
  if (props.bg) containerClasses.push("d-lesson-bg");
  if (props.active) containerClasses.push("active");
  containerClasses = containerClasses.join(" ");

  const isActive = !!props.active;

  return (
    <li className={containerClasses} onClick={props.onClick}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              {props.data.seen && props.lesson.locked ? (
                <i className="fas fa-check-circle seen" />
              ) : (
                <i className="fal fa-check-circle" />
              )}
            </td>
            <td className={"name"}>
              {props.data.name}
              {props.type === "slide" && (
                <StyledSubDescription active={isActive + ""}>
                  {props.data.images.length} slides
                </StyledSubDescription>
              )}
              {/*{props.type === "video" && (*/}
              {/*  <StyledSubDescription active={isActive + ""}>N/A minutes</StyledSubDescription>*/}
              {/*)}*/}
              {props.type === "quizz" && (
                <StyledSubDescription active={isActive + ""}>
                  {props.data.scoreDisplay && (
                    <span>
                      {props.data.score}/{props.data.totalScore} point
                    </span>
                  )}
                  {!props.data.scoreDisplay && <span>{props.data.totalScore} point</span>}
                  {+state.userCourse.type === 16 && (
                    <span style={{ display: "inline-block", marginLeft: 8 }}>
                      <b>[{props.data.totalScore * (props.lesson.conditionValue / 100)} pass]</b>
                    </span>
                  )}
                </StyledSubDescription>
              )}
              {props.type === "videoQuizs" && (
                <StyledSubDescription active={isActive + ""}>
                  {props.data.scoreDisplay && (
                    <span>
                      {props.data.score}/{props.data.totalScore} point
                    </span>
                  )}
                  {!props.data.scoreDisplay && <span>{props.data.totalScore} point</span>}
                  {+state.userCourse.type === 16 && (
                    <span style={{ display: "inline-block", marginLeft: 8 }}>
                      <b>[{props.data.totalScore * (props.lesson.conditionValue / 100)} pass]</b>
                    </span>
                  )}
                </StyledSubDescription>
              )}
            </td>
            <td style={{ textAlign: "right", minWidth: 100 }}>
              {/*<span className={"duration"}>30 min</span>*/}
              {props.type === "slide" && (
                <StyledIconBox style={{ position: "relative", left: -1 }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12 15.5V17.5M12 17.5L9 20M12 17.5L15 20"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <mask id="path-2-inside-1" fill="white">
                      <rect x="3" y="4" width="18.4615" height="12.3077" rx="1" />
                    </mask>
                    <rect
                      x="3"
                      y="4"
                      width="18.4615"
                      height="12.3077"
                      rx="1"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="4"
                      stroke-linecap="round"
                      mask="url(#path-2-inside-1)"
                    />
                    <path
                      d="M2 5L22 5"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </StyledIconBox>
              )}
              {props.type === "article" && (
                <StyledIconBox style={{ position: "relative", left: -4 }}>
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 1H2.8C2.32261 1 1.86477 1.18964 1.52721 1.52721C1.18964 1.86477 1 2.32261 1 2.8V17.2C1 17.6774 1.18964 18.1352 1.52721 18.4728C1.86477 18.8104 2.32261 19 2.8 19H13.6C14.0774 19 14.5352 18.8104 14.8728 18.4728C15.2104 18.1352 15.4 17.6774 15.4 17.2V6.4L10 1Z"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 1V6.4H15.4"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.7996 10.9004H4.59961"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.7996 14.5H4.59961"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.39961 7.2998H5.49961H4.59961"
                      stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </StyledIconBox>
              )}
              {props.type === "video" && (
                <Fragment>
                  {/*<span>N/A phút</span>*/}
                  <StyledIconBox>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="2"
                        y="5"
                        width="20"
                        height="15"
                        rx="1"
                        fill="no"
                        stroke={isActive ? "#FFFFFF" : "#2B2C57"}
                        stroke-width="2"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.7052 9.08902C10.4143 8.87036 10 9.08027 10 9.43886V15.5611C10 15.9197 10.4143 16.1296 10.7052 15.911L14.8215 12.8498C15.0595 12.6749 15.0595 12.3251 14.8215 12.1502L10.7052 9.08902Z"
                        fill={isActive ? "#FFFFFF" : "#2B2C57"}
                      />
                    </svg>
                  </StyledIconBox>
                </Fragment>
              )}
              {["quizz", "videoQuizs"].includes(props.type) && (
                <Fragment>
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 2H4C2.9 2 2 2.89999 2 4V16C2 17.1 2.9 18 4 18H18L22 22V4C22 2.89999 21.1 2 20 2ZM18 16H5C4.45 16 4 15.55 4 15V5C4 4.45001 4.45 4 5 4H19C19.55 4 20 4.45001 20 5V18L18 16Z"
                        fill={isActive ? "#FFFFFF" : "#2B2C57"}
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.7112 12.7602H13.1677V14.2168H11.7112V12.7602ZM10.7281 8.52899H10.6334C10.2037 8.52899 9.89053 8.10661 10.0362 7.69878C10.4149 6.59179 11.5219 5.85625 12.8109 6.02374C13.9397 6.1694 14.8428 7.10888 14.9811 8.23769C15.1116 9.28108 14.5421 9.78846 13.9948 10.2761C13.6558 10.5781 13.3255 10.8724 13.175 11.2819C13.1168 11.4275 13.0876 11.5877 13.0876 11.7698H11.8059C11.8059 11.4639 11.8496 11.209 11.9224 10.9833C11.9224 10.976 11.9224 10.9614 11.9297 10.9469C11.9297 10.9361 11.9336 10.9294 11.9386 10.9208C11.9404 10.9176 11.9423 10.9143 11.9443 10.9104C11.9879 10.7939 12.0316 10.6847 12.0899 10.5827H12.0972C12.3227 10.1956 12.644 9.94118 12.9413 9.70573C13.3543 9.37859 13.7212 9.08801 13.7212 8.52899C13.7212 7.85171 13.1241 7.25452 12.4468 7.25452C11.9006 7.25452 11.4126 7.63323 11.2378 8.13574C11.1577 8.3615 10.9684 8.52899 10.7281 8.52899Z"
                        fill={isActive ? "#FFFFFF" : "#2B2C57"}
                      />
                    </svg>
                  </span>
                </Fragment>
              )}
              <span hidden={props.lesson.locked}>
                <i className="fas fa-lock" style={{ color: isActive ? "#FFFFFF" : "#999FAE" }} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </li>
  );
}

function RenderLoading() {
  return (
    <div style={{ padding: 16 }}>
      <Skeleton active={true} />
    </div>
  );
}

export default Observer(CourseList);
export const CourseListState = state;
