import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Select, Row, Empty, Modal, Divider, Popover } from "antd";
import { BasePage } from "../../../../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined } from "@ant-design/icons";
import DNModal from "../../../../DnStyles/Modal";
import { getYears } from "../../../../Education/data";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { v4 } from "uuid";
import { QueryAwards, QueryAward } from "./gql";

import { exec } from "../../../../../../../Utils/dn";
import { getCommonStorage } from "../../../../../../../Config/gql";

import { useQuery } from "urql";
import { ContentPopupLanguage } from "../../../../../ComponentCommon/PopoverContentLanguage";

interface IFormModalProps {
  state: {
    isEdit: boolean;
    isModalVisible: boolean;
    loading: boolean;
  };
  setState: any;
  editLanguageId: string;
  editId: string;
  refetchAwards: any;
}

const FormModal = ({ state, setState, editId, refetchAwards,editLanguageId }: IFormModalProps) => {
  const { t } = useTranslation();
  const [id] = useState(v4());
  const [form] = Form.useForm();

  const [AwardData, refetchAward] = useQuery({
    query: QueryAward,
    variables: (() => {
      const {  merchantId } = getCommonStorage();

      return {
        languageId: editLanguageId,
        merchantId: merchantId,
        id: editId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (award: any) => {
    const { userId,languageId } = getCommonStorage();
    setState({
      loading: true,
    });
    console.log("line 85", award);

    exec(
      "Award",
      state.isEdit ? "UpdateInformationAward" : "CreateAward",
      {
        Id: state.isEdit ? editId : v4(),
        Title: award.title,
        Issuer: award.issuer,
        Description: award.description,
        Year: award.years.toString() + "-01-01",
        LanguageId: editId ? editLanguageId : languageId,
        UserId: userId,
      },
      ["actionBy", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          /* refetchBoardExperiences(); */
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        refetchAwards();
        setState({ loading: false });
      });
  };
  useEffect(() => {
    if (AwardData.data?.award) {
      console.log("run 102");
      const award = AwardData.data.award;
      form.setFieldsValue({
        years: Number(award.year.slice(0, 4)),
        title: award.title,
        issuer: award.issuer,
        description: award.description,
      });
    }
  }, [AwardData.data?.award]);

  return (
    <DNModal
      afterClose={() => state.isEdit && form.resetFields()}
      title={state.isEdit ? t("Edit") : t("Award")}
      visible={state.isModalVisible}
      onCancel={() => {
        setState({ isModalVisible: false });
      }}
      okButtonProps={{
        form: id,
        htmlType: "submit",
      }}
      confirmLoading={state.loading}>
      <Form form={form} onFinish={_onFinish} id={id} layout={"vertical"}>
        <Form.Item name={"title"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("title.award")} />
        </Form.Item>
        <Form.Item name={"issuer"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Issuer")} />
        </Form.Item>
        <Form.Item name={"description"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input.TextArea placeholder={t("Description")} />
        </Form.Item>
        <Form.Item name={"years"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("award.yearCompleted")}>
            {getYears().map((v) => (
              <Select.Option key={v.value} value={v.value}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </DNModal>
  );
};

const Award = () => {
  const { t } = useTranslation();

  const [editId, setEditId] = useState("");
  const [editLanguageId, setEditLanguageId] = useState("");

  const [state, setState] = useSetState<{
    isPopOverVisible: boolean;
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    isPopOverVisible: false,
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const [AwardDatas, refetchAwards] = useQuery({
    query: QueryAwards,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onClickEdit = (award: any,lang: string) => {
    setEditId(award.id);
    setEditLanguageId(lang)
    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec(
          "Award",
          "DeleteAward",
          {
            Id: id,
          },
          ["actionBy"]
        ).finally(refetchAwards);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };

  const awards = AwardDatas.data?.data?.items || [];
  
  return (
    <Fragment>
      <BasePage title={t("Award")} hideTitle={true}>
        <Card
          title={t("Award")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                setEditId("");
                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={AwardDatas.fetching}>
          {awards.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Award")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {awards.length > 0 &&
            awards.map((award: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("title.award")}:
                      </Col>
                      <Col span={12}>{award.title}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("award.yearCompleted")}:
                      </Col>
                      <Col span={12}>{award.year.slice(0, 4)}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Issuer")}
                      </Col>
                      <Col span={12}>{award.issuer}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Description")}
                      </Col>
                      <Col span={12}>{award.description}</Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Popover placement="bottom" trigger="hover" content={<ContentPopupLanguage edit={_onClickEdit} data={award} />}>
                        <Button type={"link"} >
                          {t("Edit")}
                        </Button>
                      </Popover>
                      <Button type={"link"} danger={true} onClick={() => _remove(award.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
        {state.isModalVisible && (
          <FormModal
          editLanguageId={editLanguageId}
            editId={editId}
            setState={setState}
            state={state}
            refetchAwards={refetchAwards}
          />
        )}
      </BasePage>
    </Fragment>
  );
};

export default Award;
