import axios from "axios";
import moment from "moment";

type TUser = {
  userName: string;
  email: string;
  id: string;
  merchantId: string;
};

export default class Tracker {
  private static _tracker: Tracker;
  private trackerId: any;
  private storageKey = "izzi_tracker_entity";
  private sessionTime = 3000;
  private deltaTime = 0;
  private user: TUser | undefined;

  public start(user: TUser) {
    console.log("tracker is started.", user);
    this.user = user;
    this.trackerId = 1;
    this.tracking().catch(console.log);
  }

  public stop() {
    console.log("tracker is stopped.");
    this.trackerId = -1;
  }

  private async tracking() {
    const startTime = Date.now();

    this.trackBlog();
    this.trackBook();
    this.trackCourse();

    if (!document.hidden) {
      await this.sendSessionEnded();
    }

    if (this.trackerId !== -1) {
      this.deltaTime = Date.now() - startTime;

      await new Promise((rel) => setTimeout(rel, 1000));
      await this.tracking();
    }
  }

  private trackBlog() {
    if (window.location.href.includes("/blog/")) {
      const splitUrl = window.location.href.split("/");
      const blogId = splitUrl[4];

      if (blogId) {
        const trackingId = [this.storageKey, "blog", blogId].join(":");
        const isStarted = localStorage.getItem(trackingId) === null;

        if (isStarted) {
          console.log("start blog", blogId);
          this.send(moment().format(), "blog", window.location.href).catch(console.log);
        }

        localStorage.setItem(
          trackingId,
          JSON.stringify({
            url: window.location.href,
            updatedAt: Date.now(),
          })
        );
      }
    }
  }

  private trackBook() {
    if (window.location.href.includes("/bookRead?file=")) {
      const splitUrl = window.location.href.split("/bookRead?file=");
      const bookFile = splitUrl[1];

      if (bookFile) {
        const trackingId = [this.storageKey, "book", bookFile].join(":");
        const isStarted = localStorage.getItem(trackingId) === null;

        if (isStarted) {
          console.log("start book", bookFile);
          this.send(moment().format(), "book", window.location.href).catch(console.log);
        }

        localStorage.setItem(
          trackingId,
          JSON.stringify({
            url: window.location.href,
            updatedAt: Date.now(),
          })
        );
      }
    }
  }

  private trackCourse() {
    if (window.location.href.includes("/user-course-details/")) {
      const course = JSON.parse(localStorage.getItem("current_course") || "{}");
      const courseId = course.id;

      if (courseId) {
        const trackingId = [this.storageKey, "course", courseId].join(":");
        const isStarted = localStorage.getItem(trackingId) === null;

        if (isStarted) {
          console.log("start course", courseId);
          this.send(moment().format(), "course", window.location.href).catch(console.log);
        }

        localStorage.setItem(
          trackingId,
          JSON.stringify({
            url: window.location.href,
            updatedAt: Date.now(),
          })
        );
      }
    } else {
      localStorage.removeItem("current_course");
    }
  }

  private async sendSessionEnded() {
    const items = Object.entries(localStorage);

    for (const item of items) {
      if (item[0].startsWith(this.storageKey)) {
        const data = JSON.parse(item[1]);

        if (Date.now() - data.updatedAt > this.sessionTime + this.deltaTime) {
          console.log("send", item[0]);
          this.send(moment().format(), item[0].split(":")[1], data.url, "finished").catch(
            console.log
          );

          localStorage.removeItem(item[0]);
        }
      }
    }
  }

  private async send(date: string, objectType: string, currentUrl: string, verb = "attempted") {
    return axios.post("https://learningrecordstore.labo.io/graphql", {
      query: `
        mutation ($statement: InputStatement!) {
          createStatement(param: $statement) {
            id
            timeStamp
            actor
            verb
            object
          }
        }
      `,
      variables: {
        statement: {
          timeStamp: date,
          actor: `{"emailAddress":"mailto:${this.user?.email}","name":"${this.user?.userName}","objectType":"Agent"}`,
          verb: `{"id":"http://adlnet.gov/expapi/verbs/${verb}","display":{"en-US":"${verb}"}}`,
          merchantId: this.user?.merchantId,
          object: `{"Id":"${currentUrl}","Definition":{"Name":"ActivityName","Description":"${currentUrl}","Type":"${
            verb === "finished" ? "Finished" : "Attempted"
          }"},"ObjectType":"${objectType}"}`,
        },
      },
    });
  }

  static instance(): Tracker {
    if (!this._tracker) {
      this._tracker = new Tracker();
    }

    return this._tracker;
  }
}
