import { styled } from "../../../../../../Utils/stitches.config";
import {
  borderColor,
  hintTextColor,
  primaryColor,
  primaryTextColor,
} from "../../../../../../Utils/themeConfig";

export const StyledStartButton = styled("button", {
  background: primaryColor,
  color: "#FFFFFF",
  border: 0,
  padding: "16px 8px",
  borderRadius: 8,
  fontWeight: 600,
  fontSize: 18,
  lineHeight: "22px",
  alignSelf: "center",
  variants: {
    width: {
      full: {
        width: "100%",
      },
    },
  },
  width: "100%",
  maxWidth: 400,
  whiteSpace: "nowrap",
});
export const StyledLayout = styled("div", {
  variants: {
    align: {
      center: {
        textAlign: "center",
      },
      right: {
        textAlign: "right",
      },
    },
    display: {
      "inline-block": {
        display: "inline-block",
      },
      table: {
        display: "table",
      },
      left: {
        display: "table-cell",
      },
      right: {
        display: "table-cell",
      },
    },
  },
});
export const StyledBannerInfo = styled("div", {
  background:
    "linear-gradient(180deg, rgba(35, 35, 35, 0) 0%, rgba(31, 31, 31, 0.58) 49.28%, #0D0D0D 100%);",
  padding: "21px 28px",
  ".quiz-item": {
    fontSize: 14,
    lineHeight: "17px",
    marginBottom: 16,
  },
  ".header-item": {
    fontSize: 32,
    lineHeight: "39px",
    fontWeight: "bold",
    marginBottom: 16,
  },
  ".info-item": {
    span: {
      display: "inline-block",
      marginRight: 16,
    },
  },
});
export const StyledQuestion = styled("p", {
  margin: 0,
  padding: 0,
  fontWeight: "bold",
  fontSize: 16,
  lineHeight: "26px",
  color: primaryTextColor,
  marginBottom: 16,
});
export const StyledDivider = styled("hr", {
  padding: 0,
  margin: "16px 0",
  borderColor: borderColor,
});
export const StyledQuestionInfo = styled("div", {
  marginTop: -24,
  svg: {
    display: "inline-block",
  },
  span: {
    display: "inline-block",
    marginLeft: 4,
    fontSize: 14,
    lineHeight: "17px",
  },
});
export const StyledFixPosition = styled("div", {
  marginLeft: -6,
});
export const StyledMultipleLineIndent = styled("span", {
  // display: "inline-block",
  // paddingLeft: "1.5em",
  // paddingLeft: 30,
  // textIndent: "30px hanging",
  lineHeight: "26px",
  div: {
    marginTop: 14,
  },
});

export class StyledCenter {}
