import { gql } from "urql";

export const QueryEducations = gql`
  query Educations(
    $languageId: String!
    $merchantId: String!
    $tokenUser: String
    $targetId: String
  ) {
    data: educations(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        targetId: $targetId
      }
    ) {
      items {
        id
        degree
        fieldOfStudy
        institute
        yearCompleted
        major
      }
    }
  }
`;
export const QueryEducation = gql`
  query Education($languageId: String!, $merchantId: String!, $id: String) {
    education(param: { languageId: $languageId, merchantId: $merchantId, id: $id }) {
      id
      degree
      fieldOfStudy
      institute
      yearCompleted
      major
    }
  }
`;
