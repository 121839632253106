import React from "react";
import PropTypes from "prop-types";

export default function MCC(props) {
  return (
    <div
      style={{
        ...{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        },
        ...(props.containerStyle || {}),
      }}>
      <div
        {...props}
        style={{
          ...{
            display: "inline-block",
          },
          ...(props.style || {}),
        }}>
        {props.children}
      </div>
    </div>
  );
}

MCC.propTypes = {
  style: PropTypes.any,
  containerStyle: PropTypes.object,
};
