/* eslint-disable */
import { LAYOUT } from "../../Config/Constants/LayoutConstant";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Configuration from "../../Config/Config";
import Common from "../../Utils/Common";

const initialState = {
    languageId: Common.getCurrentLanguageId(),
    breadcrumb: "",
    headerTranparent: false,
};

export const reducer = persistReducer(
    {
        storage,
        key: "layout",
        blacklist: ["layout", "isLoading"],
    },
    (state = initialState, action) => {
        switch (action.type) {
            case LAYOUT.GET_BREADCRUMB:
                return {
                    ...state,
                    breadcrumb: action.payload.breadcrumb,
                };
            case LAYOUT.SET_BREADCRUMB:
                return {
                    ...state,
                    breadcrumb: action.breadcrumb,
                };
            case LAYOUT.GET_HEADER_TRANPARENT:
                return {
                    ...state,
                    headerTranparent: action.payload.headerTranparent,
                };
            case LAYOUT.SET_HEADER_TRANPARENT:
                return {
                    ...state,
                    headerTranparent: action.headerTranparent,
                };
            case LAYOUT.SET_LANGUAGE_ID:
                return{
                    ...state,
                    languageId: action.languageId
                }
            default:
                return state;
        }
    }
);
