import { gql } from "urql";

export const queryPermissionArticles = gql`
  query PermissionArticles(
    $languageId: String!
    $tokenUser: String!
    $merchantId: String!
    $authorIds: [String]
    $categoryIds: [String]
    $limit: Int = 6
    $offset: Int = 0
    $type: Int = 0    
  ) {
    articlesmember(
      param: {
        languageId: $languageId
        tokenUser: $tokenUser
        merchantId: $merchantId
        authorIds: $authorIds
        categoryIds: $categoryIds
        limit: $limit
        offset: $offset
        type: $type
        published: true
        sort: "postTime"
        order: "desc"
      }
    ) {
      totalCount
      items {
        id
        name
        description
        subDescription
        postTime
        authors {
          id
          name
        }
        categories {
          id
          name
          type
        }
        friendlyUrl
        images {
          id
          path
          displayOrder
          isFeatured
        }
        createdDate
      }
    }
  }
`;
export const queryCategories = gql`
  query ProductCategories($type: Int = 3, $languageId: String!, $merchantId: String) {
    categories(
      param: {
        type: $type
        published: true
        limit: 1000
        offset: 0
        order: "desc"
        sort: "createdDate"
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        name
        type
        parentId
      }
    }
  }
`;

export const queryPrivilegeCategories = gql`
  query PrivilegeCategories($type: Int = 3, $languageId: String!, $merchantId: String, $tokenUser:String) {
    privilegecategories(
      param: {
        tokenUser:$tokenUser,
        type: $type
        published: true
        limit: 1000
        offset: 0
        order: "desc"
        sort: "createdDate"
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        name
        type
        parentId
      }
    }
  }
`;
export const queryAuthors = gql`
  query Authors($languageId: String!, $merchantId: String!) {
    users(
      param: {
        limit: 99
        keyword: ""
        offset: 0
        order: "desc"
        sort: "createdDate"
        languageId: $languageId
        type: 64
        merchantId: $merchantId
      }
    ) {
      totalCount
      message
      success
      items {
        active
        categories {
          id
          name
        }
        createdBy
        createdDate
        email
        id
        images {
          id
        }
        merchantId
        mobile
        modifiedBy
        modifiedDate
        name
        userName
        merchantCode
      }
    }
  }
`;
