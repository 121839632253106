/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { updateInfoUser } from "../../../Reducers/Book";
import { Background, Bottom } from "./styled";
import { Link } from "react-router-dom";
import MCC from "../../ComponentCommon/MiddleContent";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    if (localStorage.getItem("userIzzi") !== null) {
      window.location.href = "/";
    }

    this.state = {
      username: "",
      password: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;

    let objData = {
      UserName: username,
      Password: password,
      MerchantCode: window.Merchant.data.code,
    };
    window.showLoading();
    axios.post(window.config.urlApi + "/User/LoginByMerchantCode", objData).then((res) => {
      if (res.data.Success) {
        localStorage.setItem(window.config.localStorageTokenId, res.data.Data.tokenId);
        localStorage.setItem(Configuration.userId, res.data.Data.Id);

        //Add device token
        if (localStorage.getItem(Configuration.tokenDevice)) {
          let objData = {
            Id: res.data.Data.Id,
            DeviceToken: localStorage.getItem(Configuration.tokenDevice),
            UserToken: res.data.Data.tokenId,
            MerchantId: res.data.Data.MerchantId,
            Type: 1,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: res.data.Data.Id,
          };
          Common.sendSyncCommand("AddDeviceTokenUser", "User", objData);
        }
        Common.setCookie(Configuration.userId, res.data.Data.Id);
        if (this.props.location.state != undefined) {
          window.location.href = this.props.location.state.from.pathname;
        } else {
          window.location.href = "/";
          
        }
      } else {
        window.hideLoading();
        Swal.fire("Sai tên hoặc mật khẩu! Mời bạn đăng nhập lại.");
      }
    });
  }

  render() {
    const { loggingIn } = this.props;
    const { username, password, submitted } = this.state;
    const merchant = window.Merchant.data;
    const izziMember = JSON.parse(merchant.izziMember || "{}");

    return (
      <div
        uk-height-viewport=""
        className="uk-flex uk-flex-middle"
        style={{ minHeight: "calc(100vh);" }}>
        {/* <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                        <label htmlFor="username">Username</label>
                        <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                        {submitted && !username &&
                            <div className="help-block">Username is required</div>
                        }
                    </div>
                    <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                        {submitted && !password &&
                            <div className="help-block">Password is required</div>
                        }
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">Login</button>                        
                    </div>
                </form> */}

        <div className="uk-width-2-3@m uk-width-1-2@s m-auto rounded" style={{ marginTop: "62px" }}>
          <div className="uk-child-width-1-2@m uk-grid-collapse bg-gradient-grey uk-grid" uk-grid>
            {/* column one */}
            <div className="uk-text-center uk-animation-scale-up p-3 uk-light uk-first-column dx-relative">
              <LoginBackground />
            </div>
            {/* column two */}
            <div className="uk-card-default p-5 rounded">
              <div className="mb-4 uk-text-center">
                {/*<h3 className="mb-0"> Chào mừng bạn đã trở lại</h3>*/}
                {/*<p className="my-2">Đăng nhập để quản trị tài khoản của bạn.</p>*/}
                <h3>Đăng nhập</h3>
              </div>
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="uk-form-group">
                  <label className="uk-form-label"> Email</label>
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-mail" />
                    </span>
                    <input
                      className="uk-input"
                      type="email"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                      placeholder="name@example.com"
                    />
                    {submitted && !username && (
                      <div className="help-block">Email là trường bắt buộc.</div>
                    )}
                  </div>
                </div>
                <div className="uk-form-group">
                  <label className="uk-form-label"> Mật khẩu</label>
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-lock" />
                    </span>
                    <input
                      className="uk-input"
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      placeholder="********"
                    />
                    {submitted && !password && (
                      <div className="help-block">Mật khẩu là trường bắt buộc.</div>
                    )}
                  </div>
                </div>
                {/* <div className="uk-form-group">
                  <label className="uk-form-label"> Confirm password</label>
                  <div className="uk-position-relative w-100">
                    <span className="uk-form-icon">
                      <i className="icon-feather-lock" />
                    </span>
                    <input className="uk-input" type="password" placeholder="********" />
                  </div>
                </div> */}
                <div className="mt-4 uk-flex-middle uk-grid-small uk-grid" uk-grid>
                  <div className="uk-width-expand@s uk-first-column">
                    {/*<p>*/}
                    {/*  {" "}*/}
                    {/*  Bạn không có tài khoản ?{" "}*/}
                    {/*  <a href="/SignUp">*/}
                    {/*    <b>Đăng kí</b>*/}
                    {/*  </a>*/}
                    {/*</p>*/}
                    {/*<p>*/}
                    {/*  {" "}*/}
                    {/*  Khôi phục mật khẩu tại{" "}*/}
                    {/*  <a href="/RecoveryPassword">*/}
                    {/*    <b>đây</b>*/}
                    {/*  </a>*/}
                    {/*</p>*/}
                    <Bottom>
                      <Link to="/SignUp">Đăng ký</Link>
                      <Link to="/RecoveryPassword">Quên mật khẩu</Link>
                    </Bottom>
                  </div>
                  <div className="uk-width-auto@s">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#484b77", color: "#fff" }}
                      className="btn btn-default">
                      Bắt đầu
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*  End column two */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProp = (state) => ({});
const mapDispatchToProps = {
  updateInfoUser,
};
export default connect(mapStateToProp, mapDispatchToProps)(LoginPage);
//export default LoginPage;

export function LoginBackground() {
  const merchant = window.Merchant.data;
  const izziMember = JSON.parse(merchant.izziMember || "{}");

  return (
    <Background>
      <img src={izziMember.ImagePath} alt={"Banner"} />
      <div className={"dx-info"}>
        <MCC>
          <span
            dangerouslySetInnerHTML={{
              __html: izziMember.Description,
            }}
          />
        </MCC>
      </div>
    </Background>
  );
}
