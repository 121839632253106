/* eslint-disable */
import React from "react";
import { messaging } from "./init-fcm";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { connect } from "react-redux";
import { updateNotification } from "./App/Reducers/Notification";
import Configuration from "./Config/Config";
import Tracker from "./Services/tracker";

const renderNotification = (notification, i) => console.log(notification);

const registerPushListener = (pushNotification) =>
  navigator.serviceWorker.addEventListener("message", ({ data }) =>
    pushNotification(data.data ? data.data : data["firebase-messaging-msg-data"].data)
  );

const App = ({ token, notifications, updateNotification }) => (
  //notifications.push(reduxNotifications),
  notifications.length > 0 ? updateNotification(notifications) : console.log(notifications.length),
  // updateNotification(notifications),
  localStorage.setItem(Configuration.tokenDevice, token),
  console.log("Current token is: " + token),
  // console.log(reduxNotifications),
  notifications.map(renderNotification)
);

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  updateNotification,
};

export default compose(
  withState("token", "setToken", ""),
  withState("notifications", "setNotifications", []),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    pushNotification:
      ({ setNotifications, notifications }) =>
      (newNotification) =>
        setNotifications(notifications.concat(newNotification)),
  }),
  lifecycle({
    async componentDidMount() {
      // Tracker.instance().start();

      const { pushNotification, setToken } = this.props;

      messaging &&
        messaging
          .requestPermission()
          .then(async function () {
            const token = await messaging.getToken();
            setToken(token);
          })
          .catch(function (err) {
            console.log("Unable to get permission to notify.", err);
          });

      registerPushListener(pushNotification);
    },
    componentWillUnmount() {
      Tracker.instance().stop();
    },
  })
)(App);
