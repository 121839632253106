import { languageMapping } from "../../../../Utils/Common";

export const ContentPopupLanguage = ({data,edit}: {data: any,edit:(data: any,languageId: string)=>void})=> (
    <div className="flex justify-between">
      <div className="cursor-pointer hover:bg-gray-100 p-2" onClick={()=>edit(data,languageMapping["vn"])}>
        <img src="https://static.izzi.asia/images/FlagIcons/vn.svg" alt="vn"  />
      </div>
      <div className="cursor-pointer hover:bg-gray-100 p-2" onClick={()=>edit(data,languageMapping["en"])}>
        <img src="https://static.izzi.asia/images/FlagIcons/us.svg" alt="en" />
      </div>
    </div>
  );