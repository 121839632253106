import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import { useSetState } from "react-use";
import { Observer } from "../../../../../../Utils/Ob";
import { useQuery } from "urql";
import { queryCalendars } from "./gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/vi"


const localizer = momentLocalizer(moment);

function MaxcoachCalendarPage() {
  const {t} = useTranslation()
  const [state, setState] = useSetState({
    events: [],
    selected: null,
  });
  const [fetchCalendars] = useQuery({
    query: queryCalendars,
    variables: (() => {
      const { tokenId, languageId, merchantId } = getCommonStorage();

      return { tokenId, languageId, merchantId };
    })(),
    requestPolicy: "cache-and-network",
  });

  const _onSelectEvent = (e) => {
    setState({ selected: e });
  };

  const _onSelectSlot = ({ start, end }) => {
    // const title = window.prompt("New Event name");
    //
    // if (title) {
    //   setState({
    //     events: [
    //       ...state.events,
    //       {
    //         start,
    //         end,
    //         title,
    //       },
    //     ],
    //   });
    // }
  };

  useEffect(() => {
    setState({
      events: (fetchCalendars.data?.userscalendars.items || []).map((v) => ({
        ...v,
        start: moment(v.start).toDate(),
        end: moment(v.end).toDate(),
      })),
    });
  }, [fetchCalendars.data]);

  const pageTitle = t("Calendar");

  return (
    <>
      <Modal
        title={state.selected?.title || ""}
        visible={!!state.selected}
        centered={true}
        okText={state.selected?.url ? "View" : "OK"}
        onOk={() => {
          if (state.selected?.url) {
            window.open(state.selected?.url, "_blank");
          }

          setState({ selected: null });
        }}
        onCancel={() => {
          setState({ selected: null });
        }}>
        <p
          dangerouslySetInnerHTML={{
            __html: state.selected?.description || "",
          }}
        />
      </Modal>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h1 className="title">{pageTitle}</h1>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className="section section-padding-bottom">
        <div className="container">
          <Calendar
          
            selectable
            localizer={localizer}
            events={state.events}
            startAccessor={"start"}
            endAccessor={"end"}
            style={{ height: 600 }}
            onSelectEvent={_onSelectEvent}
            onSelectSlot={_onSelectSlot}
          />
        </div>
      </div>
    </>
  );
}

export default Observer(MaxcoachCalendarPage);
