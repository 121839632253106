/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Common from "../../../../Utils/Common";

import { setHeaderTranparentAction } from "../../../Actions/Layout";

class ListQuiz extends Component {
    constructor() {
        super();
        this.state = {
            offset: 0,
            totalItem: null,
            itemPerPage: 12,
            arrayItemPerPage: [],
            currentCategory: "",
            categories: [],
        };
        this.imageQuizDefault = "https://static.izzi.asia/images/2020/3/27/2003272288_shutterstock663203059Custom.jpg";
    }

    componentDidMount() {
        QueryGraphQL.getCourse(0, 10000000).then((data) => {
            if (data.courses.items != null && data.courses.items.length) {
                this.setState(
                    {
                        categories: data.courses.items,
                        currentCategory: data.courses.items[0].id,
                    },
                    () => {
                        QueryGraphQL.pagingQuizs(1, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                            console.log(data.quizs.items);
                            if (data.quizs.items.length) {
                                this.setState({
                                    totalItem: data.quizs.totalCount,
                                    arrayItemPerPage: data.quizs.items,
                                    popularArticle: data.quizs.items.slice(0, 3),
                                });
                            }
                        });
                    }
                );
                QueryGraphQL.pagingQuizs(1, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.quizs.items.length) {
                        this.setState({
                            totalItem: data.quizs.totalCount,
                            arrayItemPerPage: data.quizs.items,
                            popularArticle: data.quizs.items.slice(0, 3),
                        });
                    }
                });
            }
        });
    }
    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.pagingQuizs(1, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                // if (data.quizs.items.length) {
                this.setState({
                    arrayItemPerPage: data.quizs.items,
                    totalItem: data.quizs.totalCount,
                });
                // }
            });
        });
    };
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.pagingQuizs(1, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.quizs.items.length) {
                        this.setState({
                            arrayItemPerPage: data.quizs.items,
                            totalItem: data.quizs.totalCount,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };

    render() {
        let { totalItem } = this.state;

        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div className="container">
                <h1>Quiz</h1>
                {this.state.categories.length > 0 ? (
                    <div>
                        <h4>Danh sách các quiz</h4>
                        <nav className="responsive-tab  style-2" uk-filter="target: .js-filter">
                            <ul>
                                {this.state.categories.map((item, i) => (
                                    <Fragment key={i}>
                                        <li
                                            onClick={() => this.changeCategory(item.id)}
                                            uk-filter-control={`[data-color='${item.id}']`}
                                            className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                            <a href="#">{item.name}</a>
                                        </li>
                                    </Fragment>
                                ))}
                            </ul>
                        </nav>
                        <div className="section-small">
                            <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                                {this.state.arrayItemPerPage.map((item, i) => (
                                    <div key={i}>
                                        <a href={`/quiz/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                            <div className="course-card">
                                                <div className="course-card-thumbnail ">
                                                    <img src={Common.getImageThumbnail(item.images, "?mode=crop&width=300&height=200")} />
                                                    {/* <span className="play-button-trigger" /> */}
                                                </div>
                                                <div className="course-card-body">
                                                    <div className="course-card-info">
                                                        <div>
                                                            <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                        </div>
                                                        <div>
                                                            <i className="icon-feather-bookmark icon-small" />
                                                        </div>
                                                    </div>
                                                    <h4>{item.name}</h4>
                                                    <p>{item.subDescription}</p>
                                                    {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <ul className="uk-pagination uk-flex-center uk-margin-medium">
                            {(() => {
                                let tempArray = [];
                                for (let index = 0; index < totalPage; index++) {
                                    tempArray.push(
                                        <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                            <span>{index + 1}</span>
                                        </li>
                                    );
                                }
                                return <Fragment>{tempArray}</Fragment>;
                            })()}
                        </ul>
                    </div>
                ) : (
                    <h4>Chưa có nội dung</h4>
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListQuiz);
