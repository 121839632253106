/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import Swal from "sweetalert2";
import { connect } from "react-redux";

class SubcribeAuthor extends Component {
    constructor() {
        super();
        this.state = {
            items: [],
            pickedItems: [],
        };
    }
    componentDidMount = () => {
        this.load();
    };
    load = () => {
        QueryGraphQL.getAuthors(0, 1000000).then((data) => {
            if (data.users.items.length) {
                this.setState({
                    items: data.users.items,
                });

                QueryGraphQL.subcribeUsers().then((data) => {
                    if (data.subcribeusers.items.length) {
                        this.setState({
                            pickedItems: data.subcribeusers.items.map((e) => ({ value: e.id, label: e.name })),
                        });
                    }
                });
            }
        });
    };

    submitFormHandler = (event, value) => {
        event.preventDefault();
        var data = {
            Id: this.props.infoUser.id,
            UserIds: this.state.pickedItems != undefined ? this.state.pickedItems.map((e) => e.value) : null,
        };
        Common.sendSyncCommand(
            "SubcribeUsersArticleByUser",
            "User",
            data,
            () => {
                Swal.fire("Thông tin tác giả đã được cập nhật.");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            () => {
                Swal.fire("Thông tin tác giả cập nhật thất bại!");
            }
        );
    };

    render() {
        return (
            <div className="uk-card-default rounded">
                <div className="p-4 text-center">
                    <h4 className="uk-text-bold"> Theo dõi </h4>
                    <p>
                        {" "}
                        Các tác giả bạn đang theo dõi xem tại{" "}
                        <Link to="/blogAuthor">
                            <b>đây</b>
                        </Link>
                    </p>
                    <form className="mt-3" onSubmit={this.submitFormHandler}>
                        <Select
                            isMulti
                            name="authors"
                            options={this.state.items.map((item) => ({
                                value: item.id,
                                label: item.name,
                            }))}
                            value={this.state.pickedItems}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(items) => {
                                return this.setState({
                                    pickedItems: items,
                                });
                            }}
                        />
                        <input type="submit" value="Xác nhận" className="btn btn-default  btn-block mt-3" />
                    </form>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        infoUser: state.Book.infoUser,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle,
    // updateInfoUser,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(SubcribeAuthor);
