/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from "react-use";

import Configuration from "../../../../../Config/Config";
import { connect, useDispatch, useSelector } from "react-redux";
// let widthProgress = 0;
let widthProgress = 0;
let tempRatio = 0;
let videoProgress = null;

function ProgressVideo(props) {
    let { arrayDataQuestionQuiz, player, setRatio, isPreview } = props;
    const [edit, setEdit] = useState(false);
    const [widthProgress, setWidthProgress] = useState(0);
    const [itemQuestion, setItemQuestion] = useState({});

    // const dispatch = useDispatch();
    // const QuizVideo = useSelector(store => store.QuizVideo);
    // console.log(QuizVideo)
    useEffect(() => {
        videoProgress = document.getElementById("video-progress");
        let widthProgress = document.getElementById("video-progress").offsetWidth;
        setWidthProgress(widthProgress);
    }, []);
    useDeepCompareEffect(() => {
        // widthProgress = document.getElementById("video-progress").offsetWidth;
        if (!isPreview) {
            updateProgressByTime();
        }
    }, [arrayDataQuestionQuiz]);

    function updateProgressByTime() {
        let interval = setInterval(() => {
            let currentTime = player.getCurrentTime();
            document.getElementById("progress-bar").style.width = `${(currentTime / player.getDuration()) * widthProgress}px`;
            if (player.getPlayerState() == Configuration.stateVideo.PAUSED || player.getPlayerState() == Configuration.stateVideo.ENDED) {
                clearInterval(interval);
            }
        }, 1000);
    }

    function moveMouse(event) {
        let position = 0;
        position = event.clientX - videoProgress.getBoundingClientRect().left;
        if (position / widthProgress > 1) {
            position = widthProgress;
        }

        tempRatio = position / widthProgress;
        setRatio(tempRatio);
    }
    function seekTo(event) {
        player.seekTo(tempRatio * player.getDuration(), true);
        setEdit(false);
    }

    function openDropDown(event, item) {
        let videoContainer = document.getElementById("video-container");
        event.stopPropagation();
        document.getElementById("content-tooltip").style.left = event.pageX - videoContainer.offsetLeft;
        setEdit(true);
        setItemQuestion(item);
    }

    return (
        <div
            className="video-progress large"
            id="video-progress"
            style={{ width: "100%" }}
            onClick={(event) => seekTo(event)}
            onMouseMove={(event) => moveMouse(event)}>
            <div className="progress-bar" id="progress-bar" role="progressbar">
                {arrayDataQuestionQuiz.map((item, i) => {
                    return (
                        <div
                            onClick={(event) => !isPreview && openDropDown(event, item)}
                            className="interaction-point"
                            style={{
                                left: `${(item.time / player.getDuration()) * widthProgress}px`,
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
}
const mapState = (state) => ({
    isOpenQuestion: state.QuizVideo.isOpenQuestion,
});

const mapDispatch = (dispatch) => ({
    toggleTooltipQuestion: () => dispatch.QuizVideo.toggleTooltipQuestion(),
});

export default connect(mapState, mapDispatch)(ProgressVideo);
