import React from "react";
import PropTypes from "prop-types";
import { message, Modal } from "antd";
import {
  StyledButtonsContainer,
  StyledContainer,
  StyledCopyButton,
  StyledInputContainer,
} from "./styledComponents";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

function ShareDialog(props) {
  const _copyUrl = () => {
    document.querySelector("#share-url").select();
    document.execCommand("copy");
    message.success("Copied!");
  };

  const shareUrl = window.location.href;

  return (
    <React.Fragment>
      <Modal
        title={props.title}
        visible={props.open}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={null}>
        <StyledContainer>
          <RenderIcons {...props} />
          <StyledInputContainer>
            <label>URL</label>
            <input id={"share-url"} value={shareUrl} onClick={_copyUrl} />
            <StyledCopyButton onClick={_copyUrl}>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 5H9C8.47005 5.00158 7.96227 5.2128 7.58753 5.58753C7.2128 5.96227 7.00158 6.47005 7 7V20C7 20.2652 7.10536 20.5196 7.29289 20.7071C7.48043 20.8946 7.73478 21 8 21C8.26522 21 8.51957 20.8946 8.70711 20.7071C8.89464 20.5196 9 20.2652 9 20V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7H20C20.2652 7 20.5196 6.89464 20.7071 6.70711C20.8946 6.51957 21 6.26522 21 6C21 5.73478 20.8946 5.48043 20.7071 5.29289C20.5196 5.10536 20.2652 5 20 5ZM24 9H13C12.4701 9.00158 11.9623 9.2128 11.5875 9.58753C11.2128 9.96227 11.0016 10.4701 11 11V25C11.0016 25.5299 11.2128 26.0377 11.5875 26.4125C11.9623 26.7872 12.4701 26.9984 13 27H24C24.5299 26.9984 25.0377 26.7872 25.4125 26.4125C25.7872 26.0377 25.9984 25.5299 26 25V11C25.9984 10.4701 25.7872 9.96227 25.4125 9.58753C25.0377 9.2128 24.5299 9.00158 24 9ZM14 25H23C23.2652 25 23.5196 24.8946 23.7071 24.7071C23.8946 24.5196 24 24.2652 24 24V12C24 11.7348 23.8946 11.4804 23.7071 11.2929C23.5196 11.1054 23.2652 11 23 11H14C13.7348 11 13.4804 11.1054 13.2929 11.2929C13.1054 11.4804 13 11.7348 13 12V24C13 24.2652 13.1054 24.5196 13.2929 24.7071C13.4804 24.8946 13.7348 25 14 25Z"
                  fill="#111237"
                />
              </svg>
            </StyledCopyButton>
          </StyledInputContainer>
        </StyledContainer>
      </Modal>
    </React.Fragment>
  );
}

ShareDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  quote: PropTypes.string,
};

ShareDialog.defaultProps = {
  title: "Share with friends",
  onClose: () => {},
};

export function RenderIcons(props) {
  const shareUrl = window.location.href;

  return (
    <StyledButtonsContainer>
      <FacebookShareButton url={shareUrl} quote={props.quote}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.484 0H3.516C2.58382 0.00105869 1.69013 0.371833 1.03098 1.03098C0.371833 1.69013 0.00105869 2.58382 0 3.516L0 20.484C0.00105869 21.4162 0.371833 22.3099 1.03098 22.969C1.69013 23.6282 2.58382 23.9989 3.516 24H10.594V15.516H7.781V11.3H10.594V8.438C10.5951 7.31938 11.0399 6.24687 11.8309 5.45589C12.6219 4.6649 13.6944 4.22006 14.813 4.219H19.079V8.438H14.813V11.3H19.079L18.379 15.519H14.813V24H20.485C21.417 23.9987 22.3104 23.6278 22.9694 22.9687C23.6283 22.3095 23.9989 21.416 24 20.484V3.516C23.9989 2.58382 23.6282 1.69013 22.969 1.03098C22.3099 0.371833 21.4162 0.00105869 20.484 0V0Z"
            fill="#111237"
          />
        </svg>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={props.quote}>
        <svg
          width="25"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.327 19.8462C8.21794 19.8446 7.11054 19.7604 6.014 19.5942C4.43214 19.2981 2.90418 18.7644 1.482 18.0112L0 17.2322L1.6 16.7262C3.353 16.1732 4.419 15.8262 5.739 15.2922C5.0779 14.9872 4.4859 14.5507 3.99915 14.0093C3.51241 13.4679 3.14113 12.8329 2.908 12.1432L2.535 11.0562L2.841 11.1012C2.58059 10.8504 2.34607 10.574 2.141 10.2762C1.52155 9.39774 1.20716 8.34041 1.246 7.26621L1.316 6.31721L1.908 6.53721C1.6584 6.08562 1.47773 5.59924 1.372 5.09421C1.11735 3.82787 1.33391 2.51212 1.981 1.39421L2.496 0.485207L3.186 1.28521C5.27019 3.74723 8.20549 5.3336 11.407 5.72821C11.2681 4.88065 11.3721 4.01108 11.707 3.22021C12.1217 2.29718 12.8198 1.53038 13.7 1.03121C14.7499 0.424148 15.9555 0.139978 17.166 0.214207C18.4172 0.274533 19.613 0.748283 20.566 1.56121C20.9884 1.45111 21.4044 1.31757 21.812 1.16121C22.085 1.06121 22.395 0.950207 22.783 0.822207L24.211 0.350207L23.284 2.90021C23.345 2.90021 23.409 2.89121 23.475 2.88821L25 2.82621L24.1 4.00921C24.048 4.07721 24.035 4.09621 24.017 4.12321C23.944 4.22821 23.854 4.35921 22.617 5.94521C22.301 6.36183 22.1459 6.87843 22.18 7.40021C22.3159 9.2822 22.049 11.1715 21.397 12.9422C20.818 14.4481 19.8761 15.7877 18.655 16.8422C17.006 18.2152 15.0271 19.1339 12.914 19.5072C11.7323 19.7373 10.5309 19.8509 9.327 19.8462Z"
            fill="#111237"
          />
        </svg>
      </TwitterShareButton>
      <LinkedinShareButton url={shareUrl} title={props.quote}>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.338 6.31282e-05H3.662C2.71092 -0.0165748 1.7919 0.343884 1.10574 1.00269C0.419583 1.66149 0.0220499 2.56509 0 3.51606V20.4841C0.0220499 21.435 0.419583 22.3386 1.10574 22.9974C1.7919 23.6562 2.71092 24.0167 3.662 24.0001H21.338C22.2891 24.0167 23.2081 23.6562 23.8943 22.9974C24.5804 22.3386 24.978 21.435 25 20.4841V3.51606C24.978 2.56509 24.5804 1.66149 23.8943 1.00269C23.2081 0.343884 22.2891 -0.0165748 21.338 6.31282e-05ZM8.838 19.0311H5.908V9.18806H8.838V19.0311ZM8.838 7.78106H5.908V4.96906H8.838V7.78106ZM19.092 19.0311H16.162V13.4061C16.148 13.0269 15.9875 12.6679 15.7143 12.4046C15.4411 12.1413 15.0764 11.9942 14.697 11.9942C14.3176 11.9942 13.9529 12.1413 13.6797 12.4046C13.4065 12.6679 13.246 13.0269 13.232 13.4061V19.0311H10.3V9.18806H13.23V9.71806C13.9271 9.41539 14.6715 9.23606 15.43 9.18806C16.4066 9.21123 17.3356 9.61444 18.0194 10.312C18.7033 11.0095 19.0881 11.9462 19.092 12.9231V19.0311Z"
            fill="#111237"
          />
        </svg>
      </LinkedinShareButton>
      {/*<InstapaperShareButton url={shareUrl} title={props.quote}>*/}
      {/*  <svg*/}
      {/*    width="24"*/}
      {/*    height="24"*/}
      {/*    viewBox="0 0 24 24"*/}
      {/*    fill="none"*/}
      {/*    xmlns="http://www.w3.org/2000/svg">*/}
      {/*    <path*/}
      {/*      d="M20.484 0H3.516C2.58382 0.00105869 1.69013 0.371833 1.03098 1.03098C0.371834 1.69013 0.00105869 2.58382 0 3.516V20.484C0.00105869 21.4162 0.371834 22.3099 1.03098 22.969C1.69013 23.6282 2.58382 23.9989 3.516 24H20.484C21.4162 23.9989 22.3099 23.6282 22.969 22.969C23.6282 22.3099 23.9989 21.4162 24 20.484V3.516C23.9989 2.58382 23.6282 1.69013 22.969 1.03098C22.3099 0.371833 21.4162 0.00105869 20.484 0V0ZM12.047 18.281C10.7954 18.281 9.57199 17.9099 8.53136 17.2145C7.49072 16.5192 6.67964 15.5309 6.20068 14.3746C5.72173 13.2183 5.59642 11.946 5.84059 10.7185C6.08476 9.49096 6.68745 8.36341 7.57243 7.47843C8.45742 6.59344 9.58496 5.99076 10.8125 5.74659C12.04 5.50242 13.3123 5.62774 14.4686 6.10669C15.6249 6.58564 16.6132 7.39672 17.3085 8.43735C18.0039 9.47799 18.375 10.7014 18.375 11.953C18.3731 13.6307 17.7059 15.2392 16.5195 16.4255C15.3332 17.6119 13.7247 18.2791 12.047 18.281ZM19.078 7.031C18.6609 7.0308 18.2533 6.90694 17.9066 6.67508C17.5599 6.44322 17.2897 6.11377 17.1302 5.72839C16.9708 5.343 16.9292 4.91898 17.0107 4.50994C17.0921 4.1009 17.2931 3.72521 17.5881 3.43036C17.883 3.13551 18.2588 2.93475 18.6679 2.85345C19.077 2.77216 19.501 2.81398 19.8863 2.97363C20.2716 3.13329 20.6009 3.4036 20.8326 3.75041C21.0643 4.09721 21.188 4.50492 21.188 4.922C21.1872 5.48127 20.9646 6.01739 20.5691 6.41276C20.1735 6.80814 19.6373 7.03047 19.078 7.031Z"*/}
      {/*      fill="#111237"*/}
      {/*    />*/}
      {/*    <path*/}
      {/*      d="M12.047 7.03125C11.0735 7.03125 10.1219 7.31992 9.31249 7.86076C8.50307 8.40159 7.8722 9.1703 7.49967 10.0697C7.12713 10.9691 7.02966 11.9587 7.21958 12.9135C7.40949 13.8683 7.87827 14.7453 8.56662 15.4336C9.25498 16.122 10.132 16.5908 11.0868 16.7807C12.0415 16.9706 13.0312 16.8731 13.9306 16.5006C14.83 16.1281 15.5987 15.4972 16.1395 14.6878C16.6803 13.8783 16.969 12.9267 16.969 11.9532C16.9674 10.6483 16.4483 9.39733 15.5256 8.47462C14.6029 7.55191 13.3519 7.03284 12.047 7.03125Z"*/}
      {/*      fill="#111237"*/}
      {/*    />*/}
      {/*  </svg>*/}
      {/*</InstapaperShareButton>*/}
    </StyledButtonsContainer>
  );
}

export default ShareDialog;
