/* eslint-disable */
import { PRODUCT } from "../../Config/Constants/ProductConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    product: {},
    products: { items: [], totalCount: 0 },
    productdiscount: {},
};

export const reducer = persistReducer(
    {
        storage,
        key: "product",
        blacklist: ["products", "isLoading"],
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(PRODUCT.GET_PRODUCT):
                return {
                    ...state,
                    product: {},
                    isLoading: true,
                };
            case FAILURE(PRODUCT.GET_PRODUCT):
                return {
                    ...state,

                    isLoading: false,
                };
            case SUCCESS(PRODUCT.GET_PRODUCT):
                return {
                    ...state,
                    product: action.payload.product,
                    isLoading: false,
                };

            case REQUEST(PRODUCT.GET_PRODUCTS):
                return {
                    ...state,

                    isLoading: true,
                };
            case FAILURE(PRODUCT.GET_PRODUCTS):
                return {
                    ...state,

                    isLoading: false,
                };
            case SUCCESS(PRODUCT.GET_PRODUCTS):
                return {
                    ...state,
                    products: action.payload.products,
                    isLoading: false,
                };

            case REQUEST(PRODUCT.GET_PRODUCT_DISCOUNT):
                return {
                    ...state,

                    isLoading: true,
                };
            case FAILURE(PRODUCT.GET_PRODUCT_DISCOUNT):
                return {
                    ...state,

                    isLoading: false,
                };
            case SUCCESS(PRODUCT.GET_PRODUCT_DISCOUNT):
                return {
                    ...state,
                    productdiscount: action.payload.productdiscount,
                    isLoading: false,
                };
            default:
                return state;
        }
    }
);
