import React, { useEffect } from "react";
import { Observer } from "../../../../../../Utils/Ob";
import { useMount, useSetState } from "react-use";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import Breadcrumb from "../../../../ComponentCommon/maxcoach/Breadcrumb";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { Empty, Pagination, Skeleton, Spin } from "antd";
import { Link } from "react-router-dom";
import SubcribeAuthor from "../../../../ComponentCommon/Box/maxcoach/SubcribeAuthor";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import { SpinIcon } from "../../../../ComponentCommon/PageLoading";
import { useTranslation } from "react-i18next";
import "./style.css";


function BlogAuthor() {

  const {t} = useTranslation()
  const [state, setState] = useSetState({
    newestArticles: [],
    newestSubcribeCategoryArticles: [],
    offset: 0,
    totalItem: null,
    itemPerPage: 4,
    arrayItemPerPage: [],
    currentItem: null,
    items: [],
    loadingTrend: false,
    changeItem: false,
  });

  const _paginate = (indexPage, noAutoScroll) => {
    let offset = state.itemPerPage * indexPage;
    setState({ offset, loadingFollow: true, currentPage: indexPage });
    QueryGraphQL.subcribeArticlesByUser(0, offset, state.itemPerPage, state.currentItem).then(
      (data) => {
        if (data.subcribearticlesbyuser.items?.length) {
          setState({
            arrayItemPerPage: data.subcribearticlesbyuser.items,
            totalItem: data.subcribearticlesbyuser.totalCount,
          });
          if (!noAutoScroll) {
            setTimeout(() => document.getElementById("follow-categories")?.scrollIntoView());
          }
        }
        setState({ loadingFollow: false, changeItem: false });
      }
    );
  };
  const _init = () => {
    setState({ loadingTrend: true });

    Promise.all([
      QueryGraphQL.getNewestArticle(0, 5),
      QueryGraphQL.getSubcribeArticles(0, 0, 5, ""),
    ]).then(([data1, data2]) => {
      setState({
        newestArticles: data1.articles.items,
        newestSubcribeCategoryArticles: data2.subcribearticlesbycategory.items,
        loadingTrend: false,
      });
    });

    window.showPageLoading();

    QueryGraphQL.subcribeUsers()
      .then((data) => {
        if (data.subcribeusers.items?.length) {
          setState({
            items: data.subcribeusers.items,
            currentItem: data.subcribeusers.items.slice(0, 1)[0].id,
          });

          return QueryGraphQL.subcribeArticlesByUser(
            0,
            state.offset,
            state.itemPerPage,
            state.currentItem
          );
        }
      })
      .then((data) => {
        if (data?.subcribearticlesbyuser.items?.length) {
          setState({
            totalItem: data.subcribearticlesbyuser.totalCount,
            arrayItemPerPage: data.subcribearticlesbyuser.items,
          });
        }
      })
      .finally(() => window.hidePageLoading());
  };

  useMount(() => {
    _init();
  });
  useEffect(() => {
    if (state.currentItem) _paginate(0, true);
  }, [state.currentItem]);

  if (window.isPageLoading()) {
    return null;
  }

  let pageTitle = t("Followed Authors");

  return (
    <React.Fragment>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h3 className="title">{pageTitle}</h3>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className="section section-padding-bottom">
        <div className="container">
          <div className="row max-mb-n50">
            <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
              <div
                className="sidebar-widget-wrapper pr-0 animate__animated animate__fadeInUp animate__faster"
                hidden={window.isPageLoading()}>
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title text-center">
                    {t("Authors")}
                    <div
                      style={{
                        position: "absolute",
                        top: -150,
                      }}
                      id={"follow-categories"}
                    />
                  </h3>
                  <div className="section" style={{ marginBottom: 30 }}>
                    <div className="container">
                      <div
                        className="tagcloud animate__animated animate__fadeIn"
                        style={{ textAlign: "center" }}>
                        <Spin indicator={SpinIcon} spinning={state.changeItem}>
                          {state.items.map((itemInfo, i) => {
                            return (
                              <Link
                                key={i}
                                to={"/"}
                                className={
                                  itemInfo.id === state.currentItem ? "active-category" : undefined
                                }
                                style={{ float: "unset", display: "inline-block" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setState({
                                    currentItem: itemInfo.id,
                                    changeItem: true,
                                  });
                                }}>
                                {itemInfo.name}
                              </Link>
                            );
                          })}
                        </Spin>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar-widget-content">
                    <div className="section" style={{ paddingBottom: 50 }}>
                      <div className="container">
                        {state.arrayItemPerPage.length === 0 && (
                          <div style={{ textAlign: "center", height: "calc(100vh / 4)" }}>
                            <MCC>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={"Cần chọn tác giả theo dõi."}
                              />
                            </MCC>
                          </div>
                        )}
                        <div className="row row-cols-1 no-gutters">
                          {state.arrayItemPerPage.map((item, i) => (
                            <div className="blog-3 col" data-aos="fade-up" key={i}>
                              <div className="row row-cols-lg-2 row-cols-1 max-mb-n30">
                                <div className="col max-mb-30">
                                  <div className="thumbnail">
                                    <Link
                                      to={"/"}
                                      onClick={(e) => e.preventDefault()}
                                      className="image">
                                      <img
                                        src={Common.getImageThumbnail(
                                          item.images,
                                          Configuration.imageFormatBlogMaxcoach
                                        )}
                                        alt={"Thumbnail"}
                                      />
                                    </Link>
                                  </div>
                                </div>
                                <div className="col max-mb-30">
                                  <div className="info pt-0">
                                    <h4 className="title">
                                      <Link
                                        to={
                                          "/author-blog/" +
                                          item.id +
                                          "/" +
                                          Common.rewriteUrl(item.name)
                                        }>
                                        {Common.formatName(item.name, 75).toLowerCase()}
                                      </Link>
                                    </h4>
                                    <ul className="meta">
                                      <li>
                                        <i className="far fa-calendar" />
                                        {Common.formatDateTime(
                                          item.createdDate || new Date(),
                                          "dd-mm-yyyy"
                                        )}
                                      </li>
                                    </ul>
                                    <div className="desc">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: Common.formatName(item.subDescription || "", 150),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row max-mt-50">
                          <div className="col">
                            <ul className="pagination center" style={{ whiteSpace: "nowrap" }}>
                              <Pagination
                                total={state.totalItem}
                                pageSize={state.itemPerPage}
                                showSizeChanger={false}
                                showQuickJumper={state.totalItem / state.itemPerPage > 6}
                                responsive={true}
                                current={state.currentPage + 1}
                                onChange={(pageIndex) => {
                                  _paginate(pageIndex - 1);
                                }}
                                itemRender={(page, type) => {
                                  let renderText = page;
                                  if (type === "prev")
                                    renderText = (
                                      <span style={{ display: "inline-block", marginRight: 16 }}>
                                        Prev
                                      </span>
                                    );
                                  else if (type === "next") renderText = "Next";
                                  return (
                                    <li>
                                      <Link
                                        to={"/"}
                                        onClick={(e) => e.preventDefault()}
                                        className={
                                          page - 1 === state.currentPage && type === "page"
                                            ? "active"
                                            : undefined
                                        }>
                                        {state.currentPage === page - 1 && (
                                          <div>
                                            {state.loadingFollow && type === "page" ? (
                                              <Spin indicator={SpinIcon} />
                                            ) : (
                                              <span>{renderText}</span>
                                            )}
                                          </div>
                                        )}
                                        {state.currentPage !== page - 1 && (
                                          <span>{renderText}</span>
                                        )}
                                      </Link>
                                    </li>
                                  );
                                }}
                              />
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 order-lg-2 max-mb-50" id="sticky-sidebar">
              <div className="sidebar-widget-wrapper pr-0">
                <div className="sidebar-widget">
                  <h3 className="sidebar-widget-title">{t("Follow")}</h3>
                  <div className="sidebar-widget-content">
                    <SubcribeAuthor onUpdateSubcrible={_init} />
                  </div>
                </div>
                <div className="sidebar-widget">
                  <div className="sidebar-widget-content">
                    <div className="course-nav-tab">
                      <ul className="nav">
                        <li>
                          <a className="active" data-toggle="tab" href="#news">
                            {t("Newest")}
                          </a>
                        </li>
                        <li>
                          <a data-toggle="tab" href="#trend">
                            {t("Categories")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      <div id="news" className="tab-pane fade show active">
                        <ul className="sidebar-widget-list-post">
                          {state.newestArticles.map((item, i) => (
                            <li key={i}>
                              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                {item.name}
                                {item.subDescription && item.subDescription.length && (
                                  <p style={{ fontSize: 14, color: "#ababab", fontWeight: 400 }}>
                                    {item.subDescription}
                                  </p>
                                )}
                              </Link>
                            </li>
                          ))}
                          {state.loadingTrend && (
                            <li>
                              <Skeleton active={true} />
                            </li>
                          )}
                        </ul>
                      </div>
                      <div id="trend" className="tab-pane fade show">
                        <ul className="sidebar-widget-list-post">
                          {state.newestSubcribeCategoryArticles.map((item, i) => (
                            <li key={i}>
                              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                                {item.name}
                                {item.subDescription && item.subDescription.length && (
                                  <p style={{ fontSize: 14, color: "#ababab", fontWeight: 400 }}>
                                    {item.subDescription}
                                  </p>
                                )}
                              </Link>
                            </li>
                          ))}
                          {state.loadingTrend && (
                            <li>
                              <Skeleton active={true} />
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Observer(BlogAuthor);
