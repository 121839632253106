/* eslint-disable */
import Common from "../../../../../Utils/Common";
import React, { Fragment } from "react";
import Configuration from "../../../../../Config/Config";
import { connect } from "react-redux";
import $ from "jquery";
import { Link } from "react-router-dom";

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    signOut = () => {
        $.ajax({
            url: Configuration.urlApi + `/User/Logout?tokenId=${localStorage.getItem("userIzzi")}`,
            type: "GET",
            contentType: "application/json",
            processData: false,
            dataType: "json",
            success: function (res) {
                if (res.Success) {
                    if (localStorage.getItem(Configuration.tokenDevice)) {
                        let objData = {
                            Id: localStorage.getItem(Configuration.userId),
                            DeviceToken: localStorage.getItem(Configuration.tokenDevice),
                            ModifiedDate: Common.formatDateTime(new Date()),
                            ModifiedBy: localStorage.getItem(Configuration.userId),
                        };
                        Common.sendSyncCommand("RemoveDeviceTokenUser", "User", objData);
                    }
                    localStorage.removeItem("userIzzi");
                    localStorage.removeItem(Configuration.userId);
                    window.location.href = "/login";
                }
            },
            error: function (res) {
                console.log(res);
            },
        });
    };

    render() {
        var avatar = this.props.user.avatar == null ? Configuration.imageAvatarDefault : Configuration.imageUrl + this.props.user.avatar;
        return (
            <Fragment>
                <a href="javascript:" className="header-widget-icon profile-icon user-tour-guide">
                    <img src={avatar + "?mode=crop&width=32&height=32"} height="32" alt="Avatar" className="header-profile-icon" />
                </a>
                <div uk-dropdown="pos: top-right ;mode:click" className="dropdown-notifications small">
                    {/* User Name / Avatar */}
                    <a href="javascript:">
                        <div className="dropdown-user-details">
                            <div className="dropdown-user-avatar">
                                <img src={avatar + "?mode=crop&width=37&height=37"} alt={"Avatar"} />
                            </div>
                            <div className="dropdown-user-name">
                                {this.props.user.firstName} {this.props.user.lastName}
                                <span>{this.props.user.email}</span>
                            </div>
                        </div>
                    </a>
                    {/* User menu */}
                    <ul className="dropdown-user-menu">
                        <li>
                            <Link to="/usercourse">
                                <i className="icon-material-outline-dashboard" /> Khóa học của tôi
                            </Link>
                        </li>
                        <li>
                            <Link to="/quiztransaction">
                                <i className="icon-material-outline-assessment" /> Kết quả kiểm tra
                            </Link>
                        </li>

                        <li>
                            <Link to="/order">
                                <i className="icon-material-outline-credit-card" /> Đơn hàng của tôi
                            </Link>
                        </li>
                        <li>
                            <Link to="/affiliate">
                                <i className="icon-feather-link-2" /> Affiliate
                            </Link>
                        </li>
                        <li>
                            <Link to="/affiliateorder">
                                <i className="icon-feather-dollar-sign" /> Đơn hàng Affiliate
                            </Link>
                        </li>
                        <li>
                            <Link to="/change-password">
                                <i className="icon-feather-bookmark" /> Đổi mật khẩu
                            </Link>
                        </li>
                        <li>
                            <Link to="/profile-edit">
                                <i className="icon-feather-settings" /> Cấu hình tài khoản
                            </Link>
                        </li>

                        <li>
                            <a href="javascript:;" onClick={() => this.signOut()}>
                                <i className="icon-feather-log-out" /> Đăng xuất
                            </a>
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.User.user,
        breadcrumb: state.Book.breadcrumb,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle,
    // updateInfoUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
