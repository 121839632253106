const Configuration = {
 
    GOONG_MAP_KEY : "6RjmIw0Lhv59nNg7Tp4ceqmnhL4kgBIzhXAGq8Ri",
    guidEmpty: "00000000-0000-0000-0000-000000000000",
    urlApi: "https://apiRestful.izzi.asia",
    GOOGLE_MAP_KEY: "AIzaSyAVcxZGqWDCffAAA_IIvoyHrrMoscu4T9U",
    merchantCode: "merchantCode",
    tokenId: "tokenId",
    returnUrl: "returnUrl",
    userId: "userId",
    merchantId: "merchantId",
    languageId: "memberLanguage",
    tokenLanguage: "cmsLanguage",
    tokenDevice: "izziDevice",
    urlGraphQl: "https://apicms.izzi.asia/graphql/", //'http://graphql.labo.io/graphql',
    staticDomain: "https://static.izzi.asia/",
    imageUrl: "https://static.izzi.asia/",
    imageDefault: "https://static.izzi.asia/images/default/default-image.jpg",
    imageBookDefault: "https://static.izzi.asia/images/default/default-book.png",
    imageAvatarDefault: "https://static.izzi.asia/images/default/avartar.png",
    imageFormatBlog: "?mode=crop&width=776&height=400",
    imageFormatBlogMaxcoach: "?mode=crop&width=227&height=149",
    imageFormatBlogList: "?mode=crop&width=327&height=246",
    imageFormatBlogAuthorList: "?mode=crop&width=816&height=380",
    imageFormatBlogRelate: "?mode=crop&width=95&height=56",
    imageFormatBook: "?mode=crop&width=300&height=390",
    imageFormatSlide: "?mode=crop&width=300&height=200",
    imageFormatCourses: "?mode=crop&width=400&height=125",
    imageFormatCoursesMaxcoach: "?mode=crop&width=370&height=250",
    imageFormatCourseMaxcoach: (w, h) => {
      return "?mode=crop&width=" + w + "&height=" + h;
    },
    imageFormatVideo: "?mode=crop&width=367&height=190",
    imageFormatCourse: "?mode=crop&width=293&height=150",

    urlCheckout: "http://checkout.foodtalk.vn",

    MAX_FILE_SIZE: 512000,
    domainDev: ".labo.io",
    allLanguage: [
        {
            Vi: "838aef56-78bb-11e6-b5a6-00155d582814",
        },
        {
            En: "e3509252-c42d-4ae5-9779-d4805a687a8e",
        },
    ],
    timeZone: [
        { offset: "-39600", value: "International Date Line West", text: "(GMT-11:00) International Date Line West" },
        { offset: "-39600", value: "Midway Island", text: "(GMT-11:00) Midway Island" },
        { offset: "-39600", value: "Samoa", text: "(GMT-11:00) Samoa" },
        { offset: "-36000", value: "Hawaii", text: "(GMT-10:00) Hawaii" },
        { offset: "-28800", value: "Alaska", text: "(GMT-08:00) Alaska" },
        {
            offset: "-25200",
            value: "Pacific Time (US &amp; Canada)",
            text: "(GMT-07:00) Pacific Time (US &amp; Canada)",
        },
        { offset: "-25200", value: "Tijuana", text: "(GMT-07:00) Tijuana" },
        { offset: "-25200", value: "Arizona", text: "(GMT-07:00) Arizona" },
        {
            offset: "-21600",
            value: "Mountain Time (US &amp; Canada)",
            text: "(GMT-06:00) Mountain Time (US &amp; Canada)",
        },
        { offset: "-21600", value: "Chihuahua", text: "(GMT-06:00) Chihuahua" },
        { offset: "-21600", value: "Mazatlan", text: "(GMT-06:00) Mazatlan" },
        { offset: "-21600", value: "Saskatchewan", text: "(GMT-06:00) Saskatchewan" },
        { offset: "-21600", value: "Central America", text: "(GMT-06:00) Central America" },
        {
            offset: "-18000",
            value: "Central Time (US &amp; Canada)",
            text: "(GMT-05:00) Central Time (US &amp; Canada)",
        },
        { offset: "-18000", value: "Guadalajara", text: "(GMT-05:00) Guadalajara" },
        { offset: "-18000", value: "Mexico City", text: "(GMT-05:00) Mexico City" },
        { offset: "-18000", value: "Monterrey", text: "(GMT-05:00) Monterrey" },
        { offset: "-18000", value: "Bogota", text: "(GMT-05:00) Bogota" },
        { offset: "-18000", value: "Lima", text: "(GMT-05:00) Lima" },
        { offset: "-18000", value: "Quito", text: "(GMT-05:00) Quito" },
        {
            offset: "-14400",
            value: "Eastern Time (US &amp; Canada)",
            text: "(GMT-04:00) Eastern Time (US &amp; Canada)",
        },
        { offset: "-14400", value: "Indiana (East)", text: "(GMT-04:00) Indiana (East)" },
        { offset: "-14400", value: "Caracas", text: "(GMT-04:00) Caracas" },
        { offset: "-14400", value: "La Paz", text: "(GMT-04:00) La Paz" },
        { offset: "-14400", value: "Georgetown", text: "(GMT-04:00) Georgetown" },
        { offset: "-10800", value: "Atlantic Time (Canada)", text: "(GMT-03:00) Atlantic Time (Canada)" },
        { offset: "-10800", value: "Santiago", text: "(GMT-03:00) Santiago" },
        { offset: "-10800", value: "Brasilia", text: "(GMT-03:00) Brasilia" },
        { offset: "-10800", value: "Buenos Aires", text: "(GMT-03:00) Buenos Aires" },
        { offset: "-9000", value: "Newfoundland", text: "(GMT-02:30) Newfoundland" },
        { offset: "-7200", value: "Greenland", text: "(GMT-02:00) Greenland" },
        { offset: "-7200", value: "Mid-Atlantic", text: "(GMT-02:00) Mid-Atlantic" },
        { offset: "-3600", value: "Cape Verde Is.", text: "(GMT-01:00) Cape Verde Is." },
        { offset: "0", value: "Azores", text: "(GMT) Azores" },
        { offset: "0", value: "Monrovia", text: "(GMT) Monrovia" },
        { offset: "0", value: "UTC", text: "(GMT) UTC" },
        { offset: "3600", value: "Dublin", text: "(GMT+01:00) Dublin" },
        { offset: "3600", value: "Edinburgh", text: "(GMT+01:00) Edinburgh" },
        { offset: "3600", value: "Lisbon", text: "(GMT+01:00) Lisbon" },
        { offset: "3600", value: "London", text: "(GMT+01:00) London" },
        { offset: "3600", value: "Casablanca", text: "(GMT+01:00) Casablanca" },
        { offset: "3600", value: "West Central Africa", text: "(GMT+01:00) West Central Africa" },
        { offset: "7200", value: "Belgrade", text: "(GMT+02:00) Belgrade" },
        { offset: "7200", value: "Bratislava", text: "(GMT+02:00) Bratislava" },
        { offset: "7200", value: "Budapest", text: "(GMT+02:00) Budapest" },
        { offset: "7200", value: "Ljubljana", text: "(GMT+02:00) Ljubljana" },
        { offset: "7200", value: "Prague", text: "(GMT+02:00) Prague" },
        { offset: "7200", value: "Sarajevo", text: "(GMT+02:00) Sarajevo" },
        { offset: "7200", value: "Skopje", text: "(GMT+02:00) Skopje" },
        { offset: "7200", value: "Warsaw", text: "(GMT+02:00) Warsaw" },
        { offset: "7200", value: "Zagreb", text: "(GMT+02:00) Zagreb" },
        { offset: "7200", value: "Brussels", text: "(GMT+02:00) Brussels" },
        { offset: "7200", value: "Copenhagen", text: "(GMT+02:00) Copenhagen" },
        { offset: "7200", value: "Madrid", text: "(GMT+02:00) Madrid" },
        { offset: "7200", value: "Paris", text: "(GMT+02:00) Paris" },
        { offset: "7200", value: "Amsterdam", text: "(GMT+02:00) Amsterdam" },
        { offset: "7200", value: "Berlin", text: "(GMT+02:00) Berlin" },
        { offset: "7200", value: "Bern", text: "(GMT+02:00) Bern" },
        { offset: "7200", value: "Rome", text: "(GMT+02:00) Rome" },
        { offset: "7200", value: "Stockholm", text: "(GMT+02:00) Stockholm" },
        { offset: "7200", value: "Vienna", text: "(GMT+02:00) Vienna" },
        { offset: "7200", value: "Cairo", text: "(GMT+02:00) Cairo" },
        { offset: "7200", value: "Harare", text: "(GMT+02:00) Harare" },
        { offset: "7200", value: "Pretoria", text: "(GMT+02:00) Pretoria" },
        { offset: "10800", value: "Bucharest", text: "(GMT+03:00) Bucharest" },
        { offset: "10800", value: "Helsinki", text: "(GMT+03:00) Helsinki" },
        { offset: "10800", value: "Kiev", text: "(GMT+03:00) Kiev" },
        { offset: "10800", value: "Kyiv", text: "(GMT+03:00) Kyiv" },
        { offset: "10800", value: "Riga", text: "(GMT+03:00) Riga" },
        { offset: "10800", value: "Sofia", text: "(GMT+03:00) Sofia" },
        { offset: "10800", value: "Tallinn", text: "(GMT+03:00) Tallinn" },
        { offset: "10800", value: "Vilnius", text: "(GMT+03:00) Vilnius" },
        { offset: "10800", value: "Athens", text: "(GMT+03:00) Athens" },
        { offset: "10800", value: "Istanbul", text: "(GMT+03:00) Istanbul" },
        { offset: "10800", value: "Minsk", text: "(GMT+03:00) Minsk" },
        { offset: "10800", value: "Jerusalem", text: "(GMT+03:00) Jerusalem" },
        { offset: "10800", value: "Moscow", text: "(GMT+03:00) Moscow" },
        { offset: "10800", value: "St. Petersburg", text: "(GMT+03:00) St. Petersburg" },
        { offset: "10800", value: "Volgograd", text: "(GMT+03:00) Volgograd" },
        { offset: "10800", value: "Kuwait", text: "(GMT+03:00) Kuwait" },
        { offset: "10800", value: "Riyadh", text: "(GMT+03:00) Riyadh" },
        { offset: "10800", value: "Nairobi", text: "(GMT+03:00) Nairobi" },
        { offset: "10800", value: "Baghdad", text: "(GMT+03:00) Baghdad" },
        { offset: "14400", value: "Abu Dhabi", text: "(GMT+04:00) Abu Dhabi" },
        { offset: "14400", value: "Muscat", text: "(GMT+04:00) Muscat" },
        { offset: "14400", value: "Baku", text: "(GMT+04:00) Baku" },
        { offset: "14400", value: "Tbilisi", text: "(GMT+04:00) Tbilisi" },
        { offset: "14400", value: "Yerevan", text: "(GMT+04:00) Yerevan" },
        { offset: "16200", value: "Tehran", text: "(GMT+04:30) Tehran" },
        { offset: "16200", value: "Kabul", text: "(GMT+04:30) Kabul" },
        { offset: "18000", value: "Ekaterinburg", text: "(GMT+05:00) Ekaterinburg" },
        { offset: "18000", value: "Islamabad", text: "(GMT+05:00) Islamabad" },
        { offset: "18000", value: "Karachi", text: "(GMT+05:00) Karachi" },
        { offset: "18000", value: "Tashkent", text: "(GMT+05:00) Tashkent" },
        { offset: "19800", value: "Chennai", text: "(GMT+05:30) Chennai" },
        { offset: "19800", value: "Kolkata", text: "(GMT+05:30) Kolkata" },
        { offset: "19800", value: "Mumbai", text: "(GMT+05:30) Mumbai" },
        { offset: "19800", value: "New Delhi", text: "(GMT+05:30) New Delhi" },
        { offset: "19800", value: "Sri Jayawardenepura", text: "(GMT+05:30) Sri Jayawardenepura" },
        { offset: "20700", value: "Kathmandu", text: "(GMT+05:45) Kathmandu" },
        { offset: "21600", value: "Astana", text: "(GMT+06:00) Astana" },
        { offset: "21600", value: "Dhaka", text: "(GMT+06:00) Dhaka" },
        { offset: "21600", value: "Almaty", text: "(GMT+06:00) Almaty" },
        { offset: "21600", value: "Urumqi", text: "(GMT+06:00) Urumqi" },
        { offset: "23400", value: "Rangoon", text: "(GMT+06:30) Rangoon" },
        { offset: "25200", value: "Novosibirsk", text: "(GMT+07:00) Novosibirsk" },
        { offset: "25200", value: "Bangkok", text: "(GMT+07:00) Bangkok" },
        { offset: "25200", value: "Hanoi", text: "(GMT+07:00) Hanoi" },
        { offset: "25200", value: "Jakarta", text: "(GMT+07:00) Jakarta" },
        { offset: "25200", value: "Krasnoyarsk", text: "(GMT+07:00) Krasnoyarsk" },
        { offset: "28800", value: "Beijing", text: "(GMT+08:00) Beijing" },
        { offset: "28800", value: "Chongqing", text: "(GMT+08:00) Chongqing" },
        { offset: "28800", value: "Hong Kong", text: "(GMT+08:00) Hong Kong" },
        { offset: "28800", value: "Kuala Lumpur", text: "(GMT+08:00) Kuala Lumpur" },
        { offset: "28800", value: "Singapore", text: "(GMT+08:00) Singapore" },
        { offset: "28800", value: "Taipei", text: "(GMT+08:00) Taipei" },
        { offset: "28800", value: "Perth", text: "(GMT+08:00) Perth" },
        { offset: "28800", value: "Irkutsk", text: "(GMT+08:00) Irkutsk" },
        { offset: "28800", value: "Ulaan Bataar", text: "(GMT+08:00) Ulaan Bataar" },
        { offset: "32400", value: "Seoul", text: "(GMT+09:00) Seoul" },
        { offset: "32400", value: "Osaka", text: "(GMT+09:00) Osaka" },
        { offset: "32400", value: "Sapporo", text: "(GMT+09:00) Sapporo" },
        { offset: "32400", value: "Tokyo", text: "(GMT+09:00) Tokyo" },
        { offset: "32400", value: "Yakutsk", text: "(GMT+09:00) Yakutsk" },
        { offset: "34200", value: "Darwin", text: "(GMT+09:30) Darwin" },
        { offset: "34200", value: "Adelaide", text: "(GMT+09:30) Adelaide" },
        { offset: "36000", value: "Canberra", text: "(GMT+10:00) Canberra" },
        { offset: "36000", value: "Melbourne", text: "(GMT+10:00) Melbourne" },
        { offset: "36000", value: "Sydney", text: "(GMT+10:00) Sydney" },
        { offset: "36000", value: "Brisbane", text: "(GMT+10:00) Brisbane" },
        { offset: "36000", value: "Hobart", text: "(GMT+10:00) Hobart" },
        { offset: "36000", value: "Vladivostok", text: "(GMT+10:00) Vladivostok" },
        { offset: "36000", value: "Guam", text: "(GMT+10:00) Guam" },
        { offset: "36000", value: "Port Moresby", text: "(GMT+10:00) Port Moresby" },
        { offset: "36000", value: "Solomon Is.", text: "(GMT+10:00) Solomon Is." },
        { offset: "39600", value: "Magadan", text: "(GMT+11:00) Magadan" },
        { offset: "39600", value: "New Caledonia", text: "(GMT+11:00) New Caledonia" },
        { offset: "43200", value: "Fiji", text: "(GMT+12:00) Fiji" },
        { offset: "43200", value: "Kamchatka", text: "(GMT+12:00) Kamchatka" },
        { offset: "43200", value: "Marshall Is.", text: "(GMT+12:00) Marshall Is." },
        { offset: "43200", value: "Auckland", text: "(GMT+12:00) Auckland" },
        { offset: "43200", value: "Wellington", text: "(GMT+12:00) Wellington" },
        { offset: "46800", value: "Nuku'alofa", text: "(GMT+13:00) Nuku'alofa" },
    ],
    questionType: [
        { type: Math.pow(2, 0), value: "trueFalse", text: "True/False" },
        { type: Math.pow(2, 1), value: "multiChoice", text: "Multiple Choice" },
        { type: Math.pow(2, 2), value: "multiAnswer", text: "Multiple Answer" },
        { type: Math.pow(2, 3), value: "shortAnswer", text: "Short Answer" },
        { type: Math.pow(2, 4), value: "fillInTheBank", text: "Fill in the Blanks" },
        { type: Math.pow(2, 5), value: "matching", text: "Matching" },
    ],
    articleType: {
        default: 0,
        book: 5,
        slide: 7,
        video: 32,
    },
    categoryType: {
        address: 1,
        article: 2,
        product: 3,
        user: 4,
        book: 8,
        cart: 10,
    },
    productType: {
        none: 1,
        evoucher: 8,
        course: 32,
        exchangePoint: 64,
        merchantPackageElearn: 1024,
        memberPackage: 2097152
    },
    userType: {
        default: Math.pow(2, 0),
        affiliate: Math.pow(2, 2),
        staff: Math.pow(2, 5),
        author: Math.pow(2, 6),
        editor: Math.pow(2, 8),
        eVoucher: Math.pow(2, 10),
        mod: Math.pow(2, 15),
        admin: Math.pow(2, 20),
    },
    courseType: {
        default: Math.pow(2, 0),
        timeTable: Math.pow(2, 2),
        conditionQuiz: Math.pow(2, 4),
        sequence: Math.pow(2, 6),
    },
    stateVideo: {
        UNSTARTED: -1,
        ENDED: 0,
        PLAYING: 1,
        PAUSED: 2,
        BUFFERING: 3,
        VIDEO_CUED: 5,
    },
    answerType: {
        trueFalse: {
            type: Math.pow(2, 0),
            name: "True/False",
        },
        multiChoice: {
            type: Math.pow(2, 1),
            name: "Multiple Choice",
        },
        multiAnswer: {
            type: Math.pow(2, 2),
            name: "Multiple Answer",
        },
        shortAnswer: {
            type: Math.pow(2, 3),
            name: "Short Answer",
        },
        fillInTheBank: {
            type: Math.pow(2, 4),
            name: "Fill in the Blanks",
        },
    },
    attributeConfiguration: {
        target: {
          PRODUCT: 1,
          ADDRESS: 2,
          USER: 3,
          CATEGORY: 4,
          CLASSROOM: 5,
        },
        dataType: {
          VARCHAR: 1,
          TEXT: 2,
          INT: 3,
          DECIMAL: 4,
          DATETIME: 5,
        },
        displayType: {
          TEXTBOX: 1,
          RADIO: 2,
          CHECKBOX: 3,
          SELECTION: 4,
          MUILTISECTION: 5,
          TEXTAREA: 6,
          DATETIME: 7,
        },
      },
};

export default Configuration;
