/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/vi";
//import { getGoogleEmail } from ''; from '../'
import { getGoogleEmail } from "../../../../Services/EmailService";

function Email() {
    const [emails, setEmails] = useState();
    var user = useSelector((state) => state.User.user);
    useEffect(() => {
        console.log(user);
        if (user != null) {
        /*     getGoogleEmail(user.email).then((res) => setEmails(res.googleEmails.items)); */
        }
    }, [user]);

    return (
        <Fragment>
            <a href="#" className="header-widget-icon notify-tour-guide" uk-tooltip="title: Gmail ; pos: bottom ;offset:21">
                <i className="uil-envelope-alt" />
                <span>{emails != undefined && emails.length}</span>
            </a>
            {/* notificiation dropdown */}
            <div uk-dropdown="pos: top-right;mode:click ; animation: uk-animation-slide-bottom-small" className="dropdown-notifications">
                {/* notivication header */}
                <div className="dropdown-notifications-headline">
                    <h4>Emails </h4>
                    <a href="#">
                        <i className="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" />
                    </a>
                </div>
                {/* notification contents */}
                <div className="dropdown-notifications-content" data-simplebar>
                    {/* notiviation list className=notifications-not-read*/}
                    <ul>
                        {emails != undefined &&
                            emails.map((item, i) => (
                                <li>
                                    <a href="https://mail.google.com/mail/u/1/#inbox">
                                        <span className="notification-icon btn btn-soft-primary disabled">
                                            <i className="icon-feather-message-circle" />
                                        </span>
                                        <span className="notification-text">
                                            {item.title} - {item.from}
                                            <br />{" "}
                                            <span className="time-ago">
                                                {" "}
                                                <Moment locale="vi" fromNow>
                                                    {item.sendFrom}
                                                </Moment>{" "}
                                            </span>
                                        </span>
                                    </a>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    );
}

export default Email;
