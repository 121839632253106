/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { message, Spin } from "antd";
import { SpinIcon } from "../../../PageLoading";
import { Link } from "react-router-dom";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import { useMount, useSetState } from "react-use";
import { connect } from "react-redux";
import Common from "../../../../../../Utils/Common";
import Swal from "sweetalert2";
import Select from "react-select";
import PropTypes from "prop-types";

function SubcribeCategory(props) {
  const [state, setState] = useSetState({
    categories: [],
    pickedCategories: [],
    readyPickedCategories: {},
    loading: false,
    following: false,
  });

  const _load = () => {
    setState({ loading: true });
    QueryGraphQL.getAllSubcriberCategoryByType(2).then((data) => {
      if (data.allsubcribecategories != null && data.allsubcribecategories.items.length) {
        setState({
          categories: data.allsubcribecategories.items,
        });
        QueryGraphQL.getSubcribeCategories()
          .then((data) => {
            setState({ loading: false });
            if (data.subcribecategories.items.length) {
              console.log(data.subcribecategories.items);
              setState({
                pickedCategories: data.subcribecategories.items.map((e) => ({
                  value: e.id,
                  label: e.name,
                })),
              });
            }
          })
          .catch(() => {
            setState({ loading: false });
          });
      } else {
        setState({ loading: false });
      }
    });
  };

  const _submitFormHandler = (ids) => {
    setState({ following: true });
    let key = ids.join();
    message.loading({ content: "Đang cập nhật...", key });
    Common.sendSyncCommand(
      "SubcribeCategoriesArticleByUser",
      "User",
      {
        Id: props.infoUser.id,
        CategoryIds: ids,
      },
      () => {
        setState({ following: false });
        // Swal.fire("Thông tin chuyên mục đã được cập nhật.");
        setTimeout(() => {
          // window.location.reload();
        }, 3000);
        message.success({ content: "Thông tin chuyên mục đã được cập nhật.", key });
        props.onUpdateSubcrible && props.onUpdateSubcrible();
      },
      () => {
        setState({ following: false });
        let content = "Thông tin chuyên mục cập nhật thất bại!";
        Swal.fire(content);
        message.error({ content, key });
      }
    );
  };

  useMount(() => {
    _load();
  });
  useEffect(() => {
    props.onCategories && props.onCategories(state.categories);
  }, [state.categories]);

  let categories = state.categories;

  return (
    <React.Fragment>
      <p>
        Categories List
        <Link
          to={"/"}
          onClick={(e) => e.preventDefault()}
          className="text-primary"
          style={{
            display: "inline-block",
            marginLeft: 4,
          }}>
          View
        </Link>
      </p>
      <form className="mt-3" onSubmit={_submitFormHandler}>
        <Spin indicator={SpinIcon} spinning={state.loading}>
          <Select
            isMulti
            name="authors"
            options={categories.map((category) => ({
              value: category.id,
              label: category.name,
            }))}
            value={state.pickedCategories}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(categories) => {
              return setState({
                pickedCategories: categories,
              });
            }}
          />
        </Spin>
      </form>
      <div>
        <button
          className="btn btn-primary"
          style={{ width: "100%", marginTop: 30 }}
          onClick={() => {
            if (state.following || state.loading) return;
            _submitFormHandler(state.pickedCategories?.map((v) => v.value) || []);
          }}>
          {state.following ? (
            <Spin indicator={SpinIcon} style={{ color: "#ffffff" }} />
          ) : (
            <span>Follow</span>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}

SubcribeCategory.propTypes = {
  onUpdateSubcrible: PropTypes.func,
  onCategories: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    infoUser: state.User.user,
  };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(SubcribeCategory);
