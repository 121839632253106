import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Select, Row, Empty, Modal, Divider, Popover } from "antd";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined } from "@ant-design/icons";
import DNModal from "../DnStyles/Modal";
import { getYears, degrees } from "./data";
import { positions } from "../Work/data";
import _ from "lodash";

import { v4 } from "uuid";

/* import { QueryBoardExperiences } from "./gql"; */
import { exec } from "../../../../Utils/dn";
import { getCommonStorage } from "../../../../Config/gql";

import { useTranslation } from "react-i18next";
import { QueryEducations, QueryEducation } from "./gql";

import { useQuery } from "urql";
import { ContentPopupLanguage } from "../../ComponentCommon/PopoverContentLanguage";

interface IFormModalProps {
  state: {
    isEdit: boolean;
    isModalVisible: boolean;
    loading: boolean;
  };
  setState: any;
  editLanguageId: string;
  editId: string;
  refetchEducations: any;
}

const FormModal = ({
  state,
  setState,
  editId,
  refetchEducations,
  editLanguageId,
}: IFormModalProps) => {
  const { t } = useTranslation();
  const [id] = useState(v4());
  const [form] = Form.useForm();

  const [EducationData] = useQuery({
    query: QueryEducation,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        languageId: editLanguageId,
        merchantId: merchantId,
        id: editId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (education: any) => {
    const { userId, languageId } = getCommonStorage();
    setState({
      loading: true,
    });
    console.log("line 85", education);

    exec(
      "Education",
      state.isEdit ? "UpdateInformationEducation" : "CreateEducation",
      {
        Id: state.isEdit ? editId : v4(),
        Institute: education.institute,
        Degree: education.degree,
        UserId: userId,
        FieldOfStudy: education.fieldOfStudy,
        Major: education.major,
        YearCompleted: education.yearCompleted.toString() + "-01-01",
        LanguageId: editId ? editLanguageId : languageId,
      },
      ["actionBy", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          /* refetchBoardExperiences(); */
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        refetchEducations();
        setState({ loading: false });
      });
  };
  useEffect(() => {
    if (EducationData.data?.education) {
      const education = EducationData.data.education;
      form.setFieldsValue({
        institute: education.institute,
        fieldOfStudy: education.fieldOfStudy,
        major: education.major,
        yearCompleted: Number(education.yearCompleted.slice(0, 4)),
        degree: degrees.find((item) => item.value === education.degree)?.value,
      });
    }
  }, [EducationData.data?.education]);

  return (
    <DNModal
      title={state.isEdit ? t("Edit") : t("Education")}
      visible={state.isModalVisible}
      onCancel={() => {
        setState({ isModalVisible: false });
      }}
      okButtonProps={{
        form: id,
        htmlType: "submit",
      }}
      confirmLoading={state.loading}>
      <Form form={form} onFinish={_onFinish} id={id} layout={"vertical"}>
        <Form.Item name={"institute"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("institute.education")} />
        </Form.Item>
        <Form.Item name={"fieldOfStudy"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Field Of Study")} />
        </Form.Item>
        <Form.Item name={"major"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Major")} />
        </Form.Item>
        <Form.Item name={"degree"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Degree")}>
            {degrees.map((v) => (
              <Select.Option key={v.value} value={v.value}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"yearCompleted"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Year Completed")}>
            {getYears().map((v) => (
              <Select.Option key={v.value} value={v.value}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </DNModal>
  );
};

const Education = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [editLanguageId, setEditLanguageId] = useState("");

  const [editId, setEditId] = useState("");

  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const [EducationDatas, refetchEducations] = useQuery({
    query: QueryEducations,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onClickEdit = (education: any, lang: string) => {
    setEditId(education.id);
    setEditLanguageId(lang);

    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec("Education", "DeleteEducation", {
          Id: id,
        }).finally(refetchEducations);
      },
      okText: t("Confirm"),
      cancelText: t("Back"),
      centered: true,
    });
  };

  const educations = EducationDatas.data?.data?.items || [];

  return (
    <Fragment>
      <BasePage title={t("Education")} hideTitle={true}>
        <Card
          title={t("Education")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                setEditId("");

                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={EducationDatas.fetching}>
          {educations.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Education")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {educations.length > 0 &&
            educations.map((education: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Degree")}:
                      </Col>
                      <Col span={20}>
                        {degrees.find((item) => item.value === education.degree)?.name}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }} gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Field Of Study")}:
                      </Col>
                      <Col span={20}>{education.fieldOfStudy}</Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }} gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("institute.education")}:
                      </Col>
                      <Col span={20}>{education.institute}</Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }} gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Year Completed")}:
                      </Col>
                      <Col span={20}>{education.yearCompleted.slice(0, 4)}</Col>
                    </Row>
                    <Row style={{ marginTop: "1em" }} gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Major")}:
                      </Col>
                      <Col span={20}>{education.major}</Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Popover
                        placement="bottom"
                        trigger="hover"
                        content={<ContentPopupLanguage edit={_onClickEdit} data={education} />}>
                        <Button type={"link"}>{t("Edit")}</Button>
                      </Popover>
                      <Button type={"link"} danger={true} onClick={() => _remove(education.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
        {state.isModalVisible && (
          <FormModal
            editLanguageId={editLanguageId}
            editId={editId}
            setState={setState}
            state={state}
            refetchEducations={refetchEducations}
          />
        )}
      </BasePage>
    </Fragment>
  );
};

export default Education;
