import React, { useEffect } from "react";
import EventBanner from "./components/maxcoach/EventBanner";
import EventAbout from "./components/maxcoach/EventAbout";
import EventSpeaker from "./components/maxcoach/EventSpeaker";
import EventForm from "./components/maxcoach/EventForm";
import { useQuery } from "urql";
import { QUERY_EVENT } from "./gql";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import _ from "lodash";

const DetailEvent = (props) => {
  const id = props.match.params.id;

  const [queryEvent] = useQuery({
    query: QUERY_EVENT,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";

      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        id,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  useEffect(() => {
    const event = queryEvent.data?.event;
    let timerId = -1;

    if (event && event.type == 64) {

      
      const endDate = new Date(event.startDateTime).getTime();


      timerId = setInterval(() => {
        if (endDate < Date.now()) {
          if (event.quizs?.length > 0) {
            const quiz = event.quizs[_.random(0, event.quizs.length - 1)];

            window.location.href = `/quiz/${quiz.id}/${quiz.name}?autostart=true`;
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [queryEvent.data]);

  const event = queryEvent.data?.event || {};

  return (
    <div>
      {queryEvent.data ? (
        <>
          <EventBanner
            name={event.name}
            date={new Date(event.startDateTime)}
            imageThumbnailUrl={Common.getImageThumbnail(event.images)}
          />
          <EventAbout
            products={event.products}
            address={event.addresses && event.addresses.length > 0 ? event.addresses[0] : null}
            description={event.description}
            date={new Date(event.startDateTime)}
          />

          {event.type != 64 && event.authors && event.authors.length > 0 && (
            <EventSpeaker speakers={event.authors || event.users} />
          )}

          <EventForm id={event.forms && event.forms.length > 0 ? event.forms[0].id : null} />
        </>
      ) : null}
    </div>
  );
};

export default DetailEvent;
