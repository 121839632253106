import { gql } from "urql";

export const QUERY_EVENTS = gql`
  query QueryEvents(
    $keyword: String
    $limit: Int = 15
    $offset: Int = 0
    $published: Boolean = true
    $merchantId: String
    $order: String = "desc"
    $sort: String = "createdDate"
    $tokenId: String!
    $languageId: String!
  ) {
    events(
      param: {
        tokenUser: $tokenId
        keyword: $keyword
        limit: $limit
        offset: $offset
        published: $published
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        name
        friendlyUrl
        startDateTime
        eventSchedules {
          id
          eventId
          name
          startDateTime
          endDateTime
        }
        images {
          id
          name
          path
          isFeatured
        }
        addresses {
          id
          name
        }
        published
        createdDate
      }
    }
  }
`;

export const QUERY_EVENT = gql`
  query QueryEvent($id: String, $merchantId: String, $languageId: String!) {
    event(param: { id: $id, languageId: $languageId, merchantId: $merchantId }) {
      id
      name
      type
      subDescription
      description
      startDateTime
      endDateTime
      images {
        id
        path
        name
        displayOrder
        isFeatured
      }
      eventSchedules {
        id
        eventId
        name
        description
        startDateTime
        endDateTime
      }
      authors {
        id
        name
        images {
          id
          path
          name
          displayOrder
          isFeatured
        }
      }
      products {
        id
        name
        price
      }
      forms {
        id
        name
      }
      addresses {
        id
        name
        latitude
        longitude
      }
      quizs {
        id
        name
      }
      friendlyUrl
      metaName
      metaDescription
      metaImage
      metaKeyword
      files {
        id
        name
        path
      }
    }
  }
`;
