import { styled, size } from "../../../../../Utils/stitches.config";

export const StyledSearch = styled("div", {
  display: "none",
  width: 230,
  "input[type=email], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url]":
    {
      minHeight: 46,
      borderColor: "#F5F5F5",
      background: "#F5F5F5",
    },
  ".ant-input-affix-wrapper": {
    padding: "0 18px",
    borderColor: "#F5F5F5",
  },
  ".ant-input, input:focus": {
    background: "#F5F5F5",
  },
  ".ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused": {
    boxShadow: 0,
  },
  variants: {
    type: {
      pc: {
        marginRight: 16,
        [size.md]: {
          display: "inline-block",
        },
      },
      mobile: {
        display: "inline-block",
        marginBottom: 16,
        marginTop: 8,
        [size.md]: {
          display: "none",
        },
        width: "100%",
      },
    },
  },
});
