import { Modal, ModalProps } from "antd";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

export type TDNModal = ModalProps & { children: any; titleIcon?: any; hideTitleIcon?: boolean };

export default function DNModal(props: TDNModal) {
  const {t} = useTranslation()
  return (
    <Modal
      centered={true}
      closeIcon={<i className="fad fa-times" />}
      okText={t("finished")}
      cancelText={t("back")}
      {...props}
      title={
        props.hideTitleIcon ? (
          props.title
        ) : (
          <Fragment>
            <span style={{ marginRight: "0.5em" }}>
              {props.titleIcon || <i className="fad fa-tags" />}
            </span>
            {props.title}
          </Fragment>
        )
      }
    />
  );
}
