/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import SubcribeBox from "../../ComponentCommon/Box/SubcribeCategory";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

const merchants = [
    {
        id: "86a050ba-72a6-4d93-8fad-bc037c6b94c7",
        image: "https://static1.izzi.asia//images/2020/1/17/2001174760_IZZIMeeting200114.32048px.jpg?mode=crop&width=1920&height=1100",
        name: "Trịnh Minh Giang Blog",
        link: "https://trinhminhgiang.com/",
    },
    {
        id: "d5f161d2-32f3-4f03-a30c-2e8536a8da44",
        image: "https://static.izzi.asia/images/2019/12/27/1912277668_wave.ngsversion.1500050062134.adapt.1900.1.jpg",
        name: "Học viện Strategyacademy",
        link: "https://strategyacademy.vn/",
    },
];

class Index extends Component {
    state = {
        newestArticles: [],
        allArticleByCate: [],
        popularArticle: [],
        toplatestArticle: 9,

        offset: 0,
        totalItem: null,
        itemPerPage: 4,
        arrayItemPerPage: [],
        currentCategory: null,
        categories: [],
    };
    componentDidMount() {
        console.log(this.props.allCategoryArticle);

        QueryGraphQL.getSubcribeArticles(0, 0, 5, "").then((data) => {
            this.setState({
                newestArticles: data.subcribearticlesbycategory.items,
            });
        });

        QueryGraphQL.getSubcribeCategories().then((data) => {
            if (data.subcribecategories.items.length) {
                this.setState({
                    categories: data.subcribecategories.items,
                    currentCategory: data.subcribecategories.items.slice(0, 1)[0].id,
                });

                QueryGraphQL.getSubcribeArticles(0, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.subcribearticlesbycategory.items.length) {
                        this.setState({
                            totalItem: data.subcribearticlesbycategory.totalCount,
                            arrayItemPerPage: data.subcribearticlesbycategory.items,
                            popularArticle: data.subcribearticlesbycategory.items.slice(0, 3),
                        });
                    }
                });
            }
        });
    }
    subcribe = (id) => {
        var data = {
            Id: this.props.infoUser.id,
            TargetId: id,
            TargetEntity: 80,
        };
        Common.sendSyncCommand(
            "AddTargetUser",
            "User",
            data,
            () => {
                Swal.fire("Theo dõi thành công.");
                // setTimeout(() => {
                //     window.location.reload();
                // }, 3000);
            },
            () => {
                Swal.fire("Thông tin theo dõi cập nhật thất bại!");
            }
        );
    };
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.getSubcribeArticles(0, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.subcribearticlesbycategory.items.length) {
                        this.setState({
                            arrayItemPerPage: data.subcribearticlesbycategory.items,
                            totalItem: data.subcribearticlesbycategory.totalCount,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };
    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.getSubcribeArticles(0, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                if (data.subcribearticlesbycategory.items.length) {
                    this.setState({
                        arrayItemPerPage: data.subcribearticlesbycategory.items,
                        totalItem: data.subcribearticlesbycategory.totalCount,
                    });
                } else {
                    this.setState({
                        arrayItemPerPage: [],
                        totalItem: 0,
                    });
                }
            });
        });
    };
    render() {
        let { totalItem, popularArticle, latestArticle } = this.state;
        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div>
                <div className="container">
                    <h1> Blogs </h1>
                    <h4> Các trang blog bạn có thể theo dõi.</h4>
                    {merchants.length > 0 && (
                        <div className="section-small pt-2">
                            <div className="course-grid-slider" uk-slider="finite: true">
                                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                    {merchants.map((item, i) => (
                                        <li>
                                            <a href="javascript:;" onClick={() => this.subcribe(item.id)}>
                                                <div className="course-card episode-card animate-this">
                                                    <div className="course-card-thumbnail ">
                                                        <span className="item-tag">Theo dõi</span>
                                                        {/* <span className="duration">2:39</span> */}
                                                        <img src={item.image} alt={item.name} />
                                                        {/* <span className="play-button-trigger" /> */}
                                                    </div>
                                                    <div className="course-card-body">
                                                        <h4 className="mb-0"> {item.name} </h4>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {/* <div className="uk-child-width-1-2@m uk-grid-match uk-grid-small" uk-grid="true">
                        <div>
                            <div className="uk-child-width-1-2@m uk-grid-small uk-card-match" uk-grid="true">
                                {
                                    this.state.newestArticles.map((item, i) => {
                                        if (i != 0) {
                                            return (
                                                <div> <a href={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)}>
                                                    <div className="uk-card-default rounded uk-overflow-hidden">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlog)} alt={item.name} />
                                                        <div className="p-3" style={{ minHeight: 72 }}>
                                                            <h6 className="mb-0">{item.name}</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                                </div>
                                            )

                                        }
                                    }

                                    )
                                }


                            </div>
                        </div>
                        <div className="uk-flex-first@s">
                            {
                                this.state.newestArticles.map((item, i) => {
                                    if (i == 0) {
                                        return (
                                            <a href={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)} className="uk-flex">
                                                <div style={{
                                                    backgroundImage: `url('${Common.getImageThumbnail(item.images, "?mode=crop&width=622&height=484")}')`
                                                }} className="uk-card-default uk-background-cover rounded uk-flex uk-flex-bottom uk-inline-clip" uk-img>
                                                    <div className="uk-light p-3 my-3">
                                                        <h4>{item.name}</h4>
                                                        <p>{item.subDescription}</p>
                                                    </div>
                                                </div>
                                            </a>

                                        )

                                    }
                                }

                                )
                            }
                        </div>

                    </div> */}
                    <h3 className="mt-5 mb-0">Chuyên mục theo dõi</h3>
                    <div className="section-header mb-lg-3">
                        <div className="section-header-left">
                            <nav className="responsive-tab style-2">
                                <ul>
                                    {this.state.categories.map(
                                        (item, i) => (
                                            <Fragment key={i}>
                                                <li
                                                    onClick={() => this.changeCategory(item.id)}
                                                    uk-filter-control={`[data-color='${item.id}']`}
                                                    className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                                    <a href="javascript:;">{item.name}</a>
                                                </li>
                                            </Fragment>
                                        )
                                        // <li><a href="#">CSS</a></li>
                                        // <li><a href="#">HTML</a></li>
                                        // <li><a href="#">Coding</a></li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="uk-grid-large" uk-grid="true">
                        <div className="uk-width-expand">
                            {this.state.arrayItemPerPage.length > 0 ? (
                                this.state.arrayItemPerPage.map((item, i) => (
                                    <Link to={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)} className="blog-post">
                                        {/* Blog Post Thumbnail */}
                                        <div className="blog-post-thumbnail">
                                            <div className="blog-post-thumbnail-inner">
                                                <span className="blog-item-tag">Tips</span>
                                                <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlog)} alt="" />
                                            </div>
                                        </div>
                                        {/* Blog Post Content */}
                                        <div className="blog-post-content" style={{ minHeight: 230 }}>
                                            <span className="blog-post-date">{Common.formatDateTime(item.createdDate, "dd-mm-yyyy")}</span>
                                            <h3 className="text-capitalize">{Common.formatName(item.name, 75).toLowerCase()}</h3>
                                            <p>{Common.formatName(item.subDescription, 150)}</p>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <p>Cần chọn chuyên mục theo dõi.</p>
                            )}

                            <ul className="uk-pagination my-5 uk-flex-center">
                                {(() => {
                                    let tempArray = [];
                                    for (let index = 0; index < totalPage; index++) {
                                        tempArray.push(
                                            <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                                <span>{index + 1}</span>
                                            </li>
                                        );
                                    }
                                    return <Fragment>{tempArray}</Fragment>;
                                })()}
                            </ul>
                        </div>
                        <div className="uk-width-1-3@s">
                            <SubcribeBox />
                            <div className="uk-card-default rounded mt-4">
                                <ul className="uk-tab-bottom uk-child-width-expand uk-tab" uk-switcher="animation: uk-animation-fade">
                                    <li>
                                        <a href="#">Mới nhất</a>
                                    </li>
                                    <li>
                                        <a href="#">Phổ biến</a>
                                    </li>
                                </ul>
                                <ul className="uk-switcher uk-margin">
                                    {/* tab 1 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                    {/* tab 2 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <h4 className="mt-4"> Tags </h4>
                            <div uk-margin>
                                <a href="#" className="button small soft-grey">#BAA IT</a>
                                <a href="#" className="button small soft-grey">#BAA Business</a>
                                <a href="#" className="button small soft-grey">#BAA Banking</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        infoUser: state.User.user,
    };
}
const mapDispatchToProps = {
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
