import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import { QUERY_USER } from "./gql";
import { getCommonStorage } from "../../../../../../../Config/gql";
import * as ecs from "../../../../Course/maxcoach/UserCourse/ecs";
import Breadcrumb from "../../../../../ComponentCommon/maxcoach/Breadcrumb";
import { ChangePasswordSection, Container, FooterSection, HawkEye, Title } from "./styled";
import PropTypes from "prop-types";
import Configuration from "../../../../../../../Config/Config";
import MCC from "../../../../../ComponentCommon/MiddleContent";
import Common from "../../../../../../../Utils/Common";
import moment from "moment";
import { Observer } from "../../../../../../../Utils/Ob";
import { useSetState, useUnmount } from "react-use";
import { Input, Button, DatePicker, message, Modal, Spin, Tooltip } from "antd";
import $ from "jquery";
import { SpinIcon } from "../../../../../ComponentCommon/PageLoading";
import "./style.less";
import AttributeValueForm from "../../../../Attribute/Components/AttributeValueForm";
import {
  handleAttributeFormSubmit,
  getFormAttributeValues
} from "../../../../../../Services/AttributeService";
import {useTranslation} from "react-i18next"
import { useSelector } from "react-redux";

function MaxcoachProfileEditPage() {
  const {t} = useTranslation()
  const languageId = useSelector((state)=>state.Layout.languageId)
  const [fetchUser, refetchUser] = useQuery({
    query: QUERY_USER,
    requestPolicy: "network-only",
    variables: (() => {
      const { merchantId, userId } = getCommonStorage();

      return {
        userId,
        merchantId,
      };
    })(),
  });
  const [state, setState] = useSetState({
    fullName: undefined,    
    dateOfBirth: new Date(),
    showModal: false,
    currentPassword: undefined,
    newPassword: undefined,
    confirmPassword: undefined,
    changingPassword: false,
  });
  const [showState, setShowState] = useSetState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [attributeFormValues, setAttributeFormValues] = useState();
  const [attributeErrors, setAttributeErrors] = useState([]);
  const [currentAttributeValues, setCurrentAttributeValues] = useState({
    attributeVarcharValue: [],
    attributeTextValue: [],
    attributeIntValue: [],
    attributeDecimalValue: [],
    attributeDateTimeValue: []
  });

  const _save = () => {
    const user = fetchUser.data.user;
    

    const {
      relationAttributeValues,
      isFormValid,
      errorMessages
    } = handleAttributeFormSubmit(user.id, attributeFormValues, currentAttributeValues);

    

    if (!isFormValid) {
      setAttributeErrors(errorMessages);
      return;
    }
    window.showPageLoading();
    Common.sendSyncCommand(
      "UpdatePersonalDetailsUser",
      "User",
      {
        Id: user.id,
        FullName: state.fullName,      
        DateOfBirth: state.dateOfBirth,
        ImageId: null,        
        ActionBy: user.id,
        attributeValues: relationAttributeValues,
      },
      () => {
        message.success("Account info update was successful.");
        window.hidePageLoading();
        refetchUser();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      () => {
        message.error("Something went wrong!");
        window.hidePageLoading();
      }
    );
  };
  const _onSelectAvatar = (e) => {
    const files = e.target.files;

    if (files.length) {
      const file = files[0];

      const formData = new FormData();
      formData.append("token", localStorage.getItem(window.config.localStorageTokenId));
      formData.append("file", file);
      const ajaxRequest = $.ajax({
        type: "POST",
        url: window.config.urlApi + "/Image/UploadAvatar",
        contentType: false,
        processData: false,
        data: formData,
      });
      window.showPageLoading();
      ajaxRequest.done((response) => {
        if (response.Success) {
          message.success("Your file upload was successful.");
          window.hidePageLoading();
          window.location.reload();
        } else {
          message.error("Maximum file size exceeded!");
          window.hidePageLoading();
        }
      });
    }
  };
  const _onChangePassword = () => {
    const user = fetchUser.data.user;
    const { currentPassword, newPassword, confirmPassword } = state;
    const filledAllFields =
      [currentPassword, newPassword, confirmPassword].filter((v) => !!v).length === 3;

    if (!filledAllFields) {
      return message.error("Please fill in all the required fields!");
    }

    if (newPassword !== confirmPassword) {
      return message.error("New Password and Confirm Password does not match!");
    }

    setState({ changingPassword: true });

    Common.sendSyncCommand(
      "ChangePasswordUser",
      "User",
      {
        Id: user.id,
        OldPassword: currentPassword,
        Password: newPassword,
        ModifiedBy: user.id,
        ModifiedDate: Common.formatDateTime(new Date()),
      },
      () => {
        message.success("Your password has been changed successfully.");
        setState({ changingPassword: false, showModal: false });
      },
      (e) => {
        message.error(e.Message);
        setState({ changingPassword: false });
      }
    );
  };

  useEffect(() => {
    if (fetchUser.fetching) {
      window.showPageLoading();
    } else {
      window.hidePageLoading();
    }
  }, [fetchUser.fetching]);
  useUnmount(() => {
    window.resetPageLoading();
  });
  useEffect(() => {
    const user = fetchUser.data?.user;

    if (!user) return;

    const keys = Object.keys(state);
    const newState = {};

    for (let k of keys) {
      if (k in user) newState[k] = user[k];
    }

    setState(newState);
    getFormAttributeValues({ targetId: user.id ,languageId}).then((res) => {
      const {
        attributeTextValues,
        attributeVarcharValues,
        attributeDecimalValues,
        attributeIntValues,
        attributeDateTimeValues
      } = res.formattributevalues;

      setCurrentAttributeValues({
        attributeVarcharValue: attributeVarcharValues,
        attributeTextValue: attributeTextValues,
        attributeIntValue: attributeIntValues,
        attributeDecimalValue: attributeDecimalValues,
        attributeDateTimeValue: attributeDateTimeValues
      });
    });
  }, [fetchUser.data]);

  const user = fetchUser.data?.user;
  const avatar = (() => {
    if (user?.images?.length > 0) {
      return Common.getImageThumbnail(user.images, "?mode=crop&width=300&height=300");
    }

    return Configuration.imageAvatarDefault;
  })();
  const fullName = user?.name;// [user?.lastName, user?.firstName].filter((v) => !!v).join(" ");

  return (
    <BaseLayout pageTitle={t("Personal Details")}>
      {user && (
        <Container id={"profile-edit-page"}>
          <div className={"row"}>
            <div className={"col-lg-4 col-12 dx-left-side"}>
              <Tooltip title={"Maximum file size is 512 kb"}>
                <span onClick={() => document.getElementById("choose-avatar").click()}>
                  <img className={"dx-avatar"} src={avatar} alt={"Avatar"} />
                  <div className={"dx-capture"}>
                    <MCC>
                      <i className="fas fa-camera" />
                    </MCC>
                  </div>
                  <Input
                    id={"choose-avatar"}
                    type={"file"}
                    style={{ display: "none" }}
                    onChange={_onSelectAvatar}
                  />
                </span>
              </Tooltip>
              <h3>{fullName}</h3>
              <p>{t("Member Since")} {moment(user.createdDate).format("D MMM YYYY")}</p>
            </div>
            <div className={"col-lg-8 col-12 dx-right-side"}>
              
              <div className={"row"}>              
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Full Name")}</label>
                  <Input      
                  defaultValue={fullName}              
                    
                    onChange={(e) => setState({ fullName: e.target.value })}
                  />
                </div>
           
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Date of Birth")}</label>
                  <DatePicker
                    defaultValue={new Date()}
                    value={moment(state.dateOfBirth || new Date())}
                    onChange={(_v, v) => {
                      setState({ dateOfBirth: v });
                    }}
                  />
                </div>
                <div className={"col-lg-6"} />
                <div className={"col-12"}>              
            <AttributeValueForm
                target={Configuration.attributeConfiguration.target.USER}
                targetType = {1}
                attributeErrors={attributeErrors}
                //attributeSelecteds={attributeSelecteds}
                currentAttributeValues={currentAttributeValues}
                //control = {control}
                //attributeErrors={attributeErrors}
                onChange={(formData) => { setAttributeFormValues(formData) }}
              >
              </AttributeValueForm>
            </div>
                <div className={"col-lg-6 col-6"}>
               
                </div>
                <div className={"col-lg-6 col-6"} style={{ textAlign: "right" }}>
                  <Button className={"btn-primary"} onClick={_save}>
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}   
    </BaseLayout>
  );
}

export default Observer(MaxcoachProfileEditPage);

export function BaseLayout(props) {
  const {t} = useTranslation()
  const { pageTitle } = props;

  return (
    <>
      <div className="section page-title-section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{t(pageTitle)}</h1>
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className={"section section-padding-bottom"}>
        <div className={"container"}>{props.children}</div>
      </div>
    </>
  );
}

BaseLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};
