/* eslint-disable */
import Common from "../../Utils/Common";
import Configuration from "../../Config/Config";
const languageId = localStorage.getItem(Configuration.languageId);
const merchantId = localStorage.getItem(Configuration.merchantId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);
const userId = localStorage.getItem(Configuration.userId);

export const seenLessonItem = (relationId, relationEntity) => {
  let objData = {
    Id: userId,
    RelationId: relationId,
    RelationEntity: relationEntity,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  };

  Common.sendSyncCommandNoLoading("AddRelationUser", "User", objData);
};

export const unlockCourseLessonUser = (courseLessonId) => {
  let objData = {
    Id: userId,
    CourseLessonId: courseLessonId,
    ModifiedDate: Common.formatDateTime(new Date()),
    ModifiedBy: userId,
  };

  Common.sendSyncCommandNoLoading("UnlockCourseLessonUser", "User", objData);
};

export const getUserCoursePreview = (id) => {
  var query = `
    {
        usercoursepreview(param:
        {
            id:"${id}",
            languageId:"${languageId}", 
            merchantId:"${merchantId}"
         } )
        {
        id,
            type,
            name,
            subDescription,
            type,
            period,
            countLesson,       
                
            courseLessons{
                        id,
                        name,
                        duration,
                        locked,
                        conditionValue,
                         slides {                    
                    id,              
                    name                     
              },
                      videos {                    
                    id,
name, 
 contentLength,
contentType,
typeStorage,
seen,
                    path
                }  ,
                quizs {                    
                    id,
                    seen,
                    score,
                    totalScore,
                    scoreDisplay,
name
                }
                    },
            images {
                        id,
                        path,
                        displayOrder,
                        isFeatured,
                    },
           
            createdDate,
            }
    }
    `;
  return Common.getDataGraphQl(query);
};

export const getUserCourse = (id) => {
  var query = `
    {
        usercourse(param:
        {
            id:"${id}",
            languageId:"${languageId}", 
            merchantId:"${merchantId}",
            tokenUser:"${tokenId}"})
        {
        id,
            type,
            name,
            subDescription,
            type,
            period,
            countLesson,       
            certificate {
              id
              name
              subDescription
              description
            }
            quizs {                    
                id,
                seen,
                score,
                totalScore,
                scoreDisplay,
                name
            }
            courseLessons {

                        id,
                        name,
                        description,
                        duration,
                        locked,
                        conditionValue,
                       
                        relations{
   relationId,
                                  relationName,
                                  displayOrder,
                                  relationEntity
                        },
                         slides {   
                    files{
                      path
                    }                 
                    id,              
                    name,  
                    seen
images{
  path
}
              },
                      videos {                    
                    id,
name, 
 contentLength,
contentType,
typeStorage,
seen,
                    path
                }  ,
                quizs {                    
                    id,
                    seen,
                    score,
                    totalScore,
                    scoreDisplay,
name
                },
                 videoQuizs{
                  id,
                  name,
                  seen,
                    score,
                    totalScore,
                    scoreDisplay
                }
                articles {
                id,
                name,
                seen,
                subDescription,
                description,
                categories {
                  id,
                  name
                },
                images {
                  id, 
                  path,
                  name,
                  displayOrder,
                  isFeatured
                },
                authors {
                    id,
                    name
                }
                targetId,
                sections {
                    id,
                    name
                },
                friendlyUrl,
                metaName,
                metaDescription,
                metaImage,
                metaKeyword,
                files {
                    id,
                    name,
                    path
                }
            }
                    },
            images {
                        id,
                        path,
                        displayOrder,
                        isFeatured,
                    },
           
            createdDate,
            }
    }
    `;
  return Common.getDataGraphQl(query);
};

export const getUserCourses = (offset, limit) => {
  var query = `
    {
        usercourses(param:
        {
            languageId:"${languageId}", 
            limit:${limit},offset:${offset},
            merchantId:"${merchantId}",
            tokenUser:"${tokenId}"})
        {
        totalCount,
        items
        {   
            id,
            type,
            name,
            subDescription,
            type,
            period,
            countLesson,
            percent,
            categories{
                        id,
                        name
                    },
            images {
                        id,
                        path,
                        displayOrder,
                        isFeatured,
                    },
           
            createdDate,
        }
            }
    }
    `;
  return Common.getDataGraphQl(query);
};

export const getCourse = () => {
  var query = `
    {
        courses(param:
        {                  
            languageId:"${languageId}",
            tokenUser:"${tokenId}"})
        {
        totalCount,
        items
        {   
            id,
            type,
            name,
            subDescription,
            images {
                        id,
                        path,
                        displayOrder,
                        isFeatured,
                    },
           
            createdDate,
        }
            }
    }
    `;
  return Common.getDataGraphQl(query);
};
