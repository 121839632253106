import React from "react";
import MCC from "../MiddleContent";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function PageLoading(props) {
  return (
    <div className={"loading-page"} hidden={!props.show}>
      <MCC>
        <p className={"page-loading"}>
          <Spin indicator={SpinIcon} />
        </p>
        {/*<p>Đang tải dữ liệu...</p>*/}
        <p>Loading...</p>
      </MCC>
    </div>
  );
}

PageLoading.propsTypes = {
  show: PropTypes.bool.isRequired,
};

export default PageLoading;

export const SpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
