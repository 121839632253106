/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import { loadAllArticle, loadAllCategoryArticle } from "../../../Reducers/Book";
import { connect } from "react-redux";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

class Index extends Component {
    state = {
        popularArticle: [],
        toplatestArticle: 9,

        offset: 0,
        totalItem: null,
        itemPerPage: 8,
        arrayItemPerPage: [],
        currentCategory: "",
        categories: [],
    };
    componentDidMount() {
        console.log(this.props.allCategoryArticle);
        QueryGraphQL.pagingFreeCourseArticles(32, this.state.offset, 10).then((data) => {
            if (data.freecoursearticles.items.length) {
                this.setState({
                    popularArticle: data.freecoursearticles.items.slice(0, 10),
                });
            }
        });

        QueryGraphQL.getCourse(0, 100000000).then((data) => {
            if (data.courses.items != null && data.courses.items.length) {
                this.setState({
                    categories: data.courses.items,
                    currentCategory: data.courses.items.slice(0, 1)[0].id,
                });
            }

            QueryGraphQL.pagingArticles(32, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                if (data.articles.items.length) {
                    this.setState({
                        totalItem: data.articles.totalCount,
                        arrayItemPerPage: data.articles.items,
                    });
                }
            });
        });
    }
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.pagingArticles(32, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            arrayItemPerPage: data.articles.items,
                            totalItem: data.articles.totalCount,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };

    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.pagingArticles(32, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                if (data.articles.items.length) {
                    this.setState({
                        arrayItemPerPage: data.articles.items,
                        totalItem: data.articles.totalCount,
                    });
                } else {
                    this.setState({
                        totalItem: 0,
                        arrayItemPerPage: [],
                    });
                }
            });
        });
    };
    render() {
        let { totalItem, popularArticle, latestArticle } = this.state;
        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div>
                <div className="container">
                    <h1> Video </h1>
                    <div className="section-small pt-2">
                        <div className="course-grid-slider" uk-slider="finite: true">
                            <div className="grid-slider-header">
                                <div>
                                    <h4 className="uk-text-truncate"> Các video mới nhất </h4>
                                </div>
                                <div className="grid-slider-header-link">
                                    {/* <a href="courses.html" className="button transparent uk-visible@m"> View all </a> */}
                                    <a href="#" className="slide-nav-prev" uk-slider-item="previous" />
                                    <a href="#" className="slide-nav-next" uk-slider-item="next" />
                                </div>
                            </div>
                            <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                {this.state.popularArticle.map((item, i) => (
                                    <li>
                                        <Link to={`/video/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                            <div className="course-card episode-card animate-this">
                                                <div className="course-card-thumbnail ">
                                                    <span className="item-tag">{item.files.length}</span>
                                                    <span className="duration">2:39</span>
                                                    <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatVideo)} alt={item.name} />
                                                    <span className="play-button-trigger" />
                                                </div>
                                                <div className="course-card-body">
                                                    <h4 className="mb-0"> {item.name} </h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <h4> Các nhóm bài học </h4>
                    <nav className="responsive-tab  style-2">
                        <ul>
                            {this.state.categories.map((item, i) => (
                                <Fragment key={i}>
                                    <li
                                        onClick={() => this.changeCategory(item.id)}
                                        uk-filter-control={`[data-color='${item.id}']`}
                                        className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                        <a href="#">{item.name}</a>
                                    </li>
                                </Fragment>
                            ))}
                        </ul>
                    </nav>
                    <div className="section-small">
                        <div className="uk-child-width-1-4@m uk-child-width-1-3@s" uk-grid="true">
                            {this.state.arrayItemPerPage.map((item, i) => (
                                <div key={i} data-color={item.id} className={""}>
                                    <Link to={`/video/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                        <div className="course-card episode-card animate-this">
                                            <div className="course-card-thumbnail ">
                                                <span className="item-tag">{item.files.length}</span>
                                                <span className="duration">3:39</span>
                                                <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatVideo)} />
                                                <span className="play-button-trigger" />
                                            </div>
                                            <div className="course-card-body">
                                                <h4 className="mb-0">{item.name} </h4>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* pagination*/}
                    <ul className="uk-pagination uk-flex-center uk-margin-medium">
                        {(() => {
                            let tempArray = [];
                            for (let index = 0; index < totalPage; index++) {
                                tempArray.push(
                                    <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                        <span>{index + 1}</span>
                                    </li>
                                );
                            }
                            return <Fragment>{tempArray}</Fragment>;
                        })()}
                    </ul>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    loadAllArticle,
    loadAllCategoryArticle,
    setBreadcrumbAction,
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
