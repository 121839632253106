import React from "react";
import Common from "../../../../../../Utils/Common";
import { useMount } from "react-use";

const {$} =window;

const EventBanner = ({ date, name, imageThumbnailUrl }) => {
  useMount(() => {
    $('[data-countdown]:not(.pro-countdown-1,.pro-countdown-2)').each(function() {
      var $this = $(this), finalDate = $(this).data('countdown');
      $this.countdown(finalDate, function(event) {
        $this.html(event.strftime('<div class="single-countdown-box"><span>%D</span>Day</div><div class="single-countdown-box"><span>%H</span>Hours</div><div class="single-countdown-box"><span>%M</span>Mins</div><div class="single-countdown-box"><span>%S</span>Secs</div>'));
      });
    });
    $('[data-countdown].pro-countdown-1').each(function() {
      var $this = $(this), finalDate = $(this).data('countdown');
      $this.countdown(finalDate, function(event) {
        $this.html(event.strftime('<div class="single-countdown-box-1"><span>%D</span>Day</div><div class="single-countdown-box-1"><span>%H</span>Hours</div><div class="single-countdown-box-1"><span>%M</span>Mins</div><div class="single-countdown-box-1"><span>%S</span>Secs</div>'));
      });
    });
    $('[data-countdown].pro-countdown-2').each(function() {
      var $this = $(this), finalDate = $(this).data('countdown');
      $this.countdown(finalDate, function(event) {
        $this.html(event.strftime('<div class="countdown-item before-none"><span>%D</span></div><div class="countdown-item"><span>%H</span></div><div class="countdown-item"><span>%M</span></div><div class="countdown-item"><span>%S</span></div>'));
      });
    });
  })

  return (
    <div className="event-page-banner section overlay section-padding-top-200 section-padding-bottom-180" data-bg-image={imageThumbnailUrl} style={{
      backgroundImage: `url(${imageThumbnailUrl})`
    }}>
      <div className="container">
        <div className="col-12">
          <div className="event-hero-content">
            <div className="entry-date">{Common.formatDateTime(date, "dd/mm/yyyy hh:mm")}</div>
            <h1 className="entry-title">{name}</h1>
            <div className="entry-countdown">
              {/*<div className="pro-countdown-1" data-countdown={"2021/12/03"} />*/}
              <div className="pro-countdown-1" data-countdown={Common.formatDateTime(date, "yyyy/mm/dd")} />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EventBanner;
