/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import { LightgalleryItem } from "react-lightgallery";
import Configuration from "../../../../Config/Config";
import "lightgallery.js/dist/css/lightgallery.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

const PhotoItem = ({ image, group, index }) => (
    <div style={{ padding: "5px" }}>
        <LightgalleryItem group={group} src={image}>
            <img id={"itemGallery" + index} src={image} />
        </LightgalleryItem>
    </div>
);

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {
                name: "",
                subDescription: "",
                description: "",
                images: [],
            },
            tempSlide: [],

            videos: [],
            quizs: [],
        };
    }
    componentDidMount() {
        var seft = this;
        QueryGraphQL.getCategoryById(this.props.match.params.id).then((data) => {
            this.setState({
                category: data.category,
            });
            seft.props.setBreadcrumbAction(data.category.name);
        });
        QueryGraphQL.pagingArticles(7, 0, 1000, this.props.match.params.id).then((data) => {
            if (data.articles.items.length) {
                console.log(data.articles.items);
                this.setState({
                    tempSlide: data.articles.items,
                });
                console.log(this.state.tempSlide);
            }
        });

        QueryGraphQL.pagingArticles(32, 0, 1000, this.props.match.params.id).then((data) => {
            if (data.articles.items.length) {
                this.setState({
                    videos: data.articles.items,
                });
            }
        });

        QueryGraphQL.pagingQuizs(1, 0, 1000, this.props.match.params.id).then((data) => {
            if (data.quizs.items.length) {
                this.setState({
                    totalItem: data.quizs.totalCount,
                    quizs: data.quizs.items,
                });
            }
        });
    }
    returnValue(obj, property) {
        if (obj === undefined) {
            return "";
        }
        return obj[property];
    }
    openGallery = () => {
        $("#itemGallery0").click();
    };
    render() {
        this.props.setHeaderTranparentAction(false);
        return (
            <div className="container">
                <div className="mt-lg-4" uk-grid="true">
                    <div className="uk-width-1-4@m">
                        <img src={Common.getImageThumbnail(this.state.category.images, "?mode=crop&width=640&height=360")} alt="" className="rounded shadow" />
                    </div>
                    <div className="uk-width-expand">
                        <p className="my-0 uk-text-small">Học liệu</p>
                        <h3 className="mt-0"> {this.state.category.name} </h3>
                        <p> {this.state.category.subDescription} </p>
                    </div>
                    <div className="uk-width-1-4@m">
                        <h5> Thông tin </h5>
                        <div uk-margin>Tạo ngày {Common.formatDateTime(this.state.category.createdDate, "dd-mm-yyyy")}</div>
                    </div>
                </div>
                <div className="course-path-info my-4 my-lg-5">
                    <h4 className="uk-text-bold"> Bạn sẽ học được gì </h4>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.state.category.description,
                        }}
                    />
                    {/* <ul>
                        <li>Basics of programming </li>
                        <li>Built-in types</li>
                        <li>JavaScript operators</li>
                        <li>Applying CSS Filters</li>
                        <li>Flexible Box</li>
                        <li>Floating and Clearing Elements</li>
                        <li>Grid </li>
                        <li>CSS Variables</li>
                        <li>How Elements are Rendered</li>
                        <li>CSS Grid Layout </li>
                    </ul> */}
                </div>

                <ul className="course-path-level" uk-accordion="true">
                    <li className="uk-open">
                        <a className="uk-accordion-title" href="javascript:;">
                            Slide{" "}
                        </a>
                        <div className="uk-accordion-content">
                            <p> Danh sách slide bài học.</p>
                            <div className="path-wrap">
                                <div className=" course-grid-slider" uk-slider="finite: true">
                                    <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-5@m uk-grid-match uk-grid">
                                        {this.state.tempSlide.map((item, i) => (
                                            <li>
                                                <Link to={`/slide/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                    <div className="course-card">
                                                        <div className="course-card-thumbnail ">
                                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                                            {/* <span className="play-button-trigger" /> */}
                                                        </div>
                                                        <div className="course-card-body">
                                                            <div className="course-card-info">
                                                                <div>
                                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                                </div>
                                                                <div>
                                                                    <i className="icon-feather-bookmark icon-small" />
                                                                </div>
                                                            </div>
                                                            <h4>{item.name}</h4>
                                                            <p>{item.subDescription}</p>
                                                            {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous" />
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="uk-open">
                        <a className="uk-accordion-title" href="javascript:;">
                            {" "}
                            Video{" "}
                        </a>
                        <div className="uk-accordion-content">
                            <p> Danh sách video bài học.</p>
                            <div className="path-wrap">
                                <div className="course-grid-slider" uk-slider="finite: true">
                                    <ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-5@m uk-grid-match uk-grid">
                                        {this.state.videos.map((item, i) => (
                                            <li>
                                                <div className="course-card">
                                                    <div className="course-card-thumbnail">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatVideo)} />
                                                        <Link to={`/video/${item.id}/${Common.rewriteUrl(item.name)}`} className="play-button-trigger show">
                                                            {" "}
                                                        </Link>
                                                        {/* <span className="duration">5:39</span> */}
                                                    </div>
                                                    <div className="course-progressbar">
                                                        {/* <div className="course-progressbar-filler" style={{ width: '25%' }} /> */}
                                                    </div>
                                                    <div className="course-card-body">
                                                        <h4> {item.name} </h4>
                                                        <p> {item.subDescription}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous" />
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next" />
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="uk-open">
                        <a className="uk-accordion-title" href="javascript:;">
                            {" "}
                            Quiz{" "}
                        </a>
                        <div className="uk-accordion-content">
                            <p> Danh sách quiz bài học.</p>
                            <div className="path-wrap">
                                <div className="course-grid-slider" uk-slider="finite: true">
                                    <ul className="uk-slider-items uk-child-width-1-3@s uk-child-width-1-5@m uk-grid-match uk-grid">
                                        {this.state.quizs.map((item, i) => (
                                            <li>
                                                <Link to={`/quiz/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                    <div className="course-card">
                                                        <div className="course-card-thumbnail ">
                                                            <img src={Common.getImageThumbnail(item.images, "?mode=crop&width=300&height=200")} />
                                                            {/* <span className="play-button-trigger" /> */}
                                                        </div>
                                                        <div className="course-card-body">
                                                            <div className="course-card-info">
                                                                <div>
                                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                                </div>
                                                                <div>
                                                                    <i className="icon-feather-bookmark icon-small" />
                                                                </div>
                                                            </div>
                                                            <h4>{item.name}</h4>
                                                            <p>{item.subDescription}</p>
                                                            {/* <div className="course-card-footer">
                                                                            <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                                            <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                    <a className="uk-position-center-left uk-position-small uk-hidden-hover slidenav-prev" href="#" uk-slider-item="previous" />
                                    <a className="uk-position-center-right uk-position-small uk-hidden-hover slidenav-next" href="#" uk-slider-item="next" />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}
function mapStateToProps(state) {}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setBreadcrumbAction,
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
