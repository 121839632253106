import React, { useCallback } from "react";
import { useQuery } from "urql";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import { QUERY_CONTEST } from "./gql";
import { useDropzone } from "react-dropzone";
import { uploadFileContest } from "../../../Services/FileService";
import { submitEntryContest } from "../../../Services/ContestService";
import { useState } from "react";
import Swal from "sweetalert2";
import Countdown from "react-countdown";
import moment from "moment";
import { queryContestEntries } from "../User Account/Profile/maxcoach/Profile/gql";
import { Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import EntryList from "./EntryList";

const ContestDetail = (props: any) => {
  const id = props.match.params.id;
  const [errorText, setErrorText] = useState<String>("");
  const [submitting, setSubmitting] = useState(false);
  const [isEndTime, setIsEndTime] = useState(false);

  const [queryContest] = useQuery({
    query: QUERY_CONTEST,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        id,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [fetchContestEntries] = useQuery({
    query: queryContestEntries,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return {
        merchantId: merchantId,
        languageId: languageId,
        tokenUser: tokenId,
        limit: 100,
        offset: 0,
        targetId: id,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  console.log(fetchContestEntries.data);
  const contestEntries = fetchContestEntries.data?.membercontestentries?.items || [];

  const contest = queryContest.data?.membercontest || {};
  const isStarted = contest.startDate ? moment(contest.startDate).isBefore(moment()) : false;
  const isEnded = contest.endDate ? moment(contest.endDate).isBefore(moment()) : false;
  const isOngoing = contest.status == "OnGoing";
  const isClosed = contest.status == "Closed";
  const isEvaluating = contest.status == "Evaluating" || contest.status == "Reviewing";

  const status = isClosed
    ? "Đã kết thúc"
    : isEvaluating
    ? "Đã hết thời gian nộp bài"
    : isStarted
    ? "Đã hết thời gian nộp bài"
    : "Chưa bắt đầu";


  const onDrop = useCallback(
    async (acceptedFiles) => {
      setErrorText("");
      console.log(acceptedFiles);

      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];

        setSubmitting(true);
        const res = await uploadFileContest(file, contest.code, contest.id);
        const { fileId, path, success, message } = res;
        if (!success) {
          setErrorText(message || "Something went wrong");
          setSubmitting(false);
          return;
        } else {
          Swal.fire("Thành công");
          setSubmitting(false);
        }
      } else {
        setErrorText("Vui lòng chọn 1 file");
        return;
      }
      // Do something with the files
    },
    [contest]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div>
      <Spin spinning={fetchContestEntries.fetching || queryContest.fetching}>
        <div
          className="page-banner-section section overlay section-padding"
          data-bg-image={Common.getImageThumbnail(contest.images)}
          style={{
            marginBottom: 18,
            backgroundImage: `url(${Common.getImageThumbnail(contest.images || [])})`,
            backgroundPosition: "center",
          }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="page-banner-title course-banner-title">
                  <h1 className="title">{contest.name}</h1>
                </div>
                <div className="page-title-bar-meta">
                  {/* <div className="course-instructor post-author">
                  <span className="meta-icon meta-image">
                    <img
                      alt="User Avatar"
                      src={}
                      className="avatar"
                    />
                  </span>
                  <span className="meta-value">Blanche Fields</span>
                </div> */}
                  <div className="course-duration">
                    <span className="meta-icon far fa-clock" />
                    <span className="meta-value">
                      Từ {moment(contest.startDate).format("HH:mm DD/MM/YYYY")} đến{" "}
                      {moment(contest.endDate).format("HH:mm DD/MM/YYYY")}
                    </span>
                  </div>
                  {/* <div className="course-lesson">
                  <span className="meta-icon far fa-file-alt" />
                  <span className="meta-value">6 Lessons</span>
                </div> */}
                  {/* <div className="course-students">
                  <span className="meta-icon far fa-user-alt" />
                  <span className="meta-value">{contest.participants?.length} participants</span>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section mb-5">
          <div className="container">
            <div className="row max-mb-n50">
              <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
                {/* Course Details Wrapper Start */}
                <div className="course-details-wrapper">
                  <div className="course-nav-tab">
                    <ul className="nav">
                      <li>
                        <a className="active" data-toggle="tab" href="#overview">
                          Tổng quan
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#file">
                          File, tài liệu
                        </a>
                      </li>
                      <li>
                        <a data-toggle="tab" href="#result">
                          Kết quả
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content">
                    <div id="overview" className="tab-pane fade show active">
                      <div className="course-overview">
                        <div dangerouslySetInnerHTML={{ __html: contest.description }}></div>
                      </div>
                    </div>
                    <div
                      id="file"
                      className="tab-pane fade"
                      style={{
                        overflow: "auto",
                      }}>
                      <div>
                        <Table
                          dataSource={contest.files}
                          columns={[
                            {
                              title: "#",
                              dataIndex: "id",
                              width: "10%",
                              key: "id",
                              render: (v: any, row: any, index: number) => {
                                return (index && <span>{index + 1}</span>) || 1;
                              },
                            },
                            {
                              title: "Tên file",
                              dataIndex: "name",
                              key: "name",
                              width: "30%",
                              //render: renderContent,
                            },
                            {
                              title: "Mô tả",
                              width: "50%",
                              dataIndex: "subDescription",
                              key: "subDescription",
                              //render: renderContent,
                            },
                            {
                              title: "",
                              dataIndex: "path",
                              width: "10%",
                              key: "path",
                              render: (v: any) => {
                                return (
                                  v && (
                                    <a target="_blank" href={"https://static.izzi.asia/" + v}>
                                      Tải về
                                    </a>
                                  )
                                );
                              },
                            },
                          ]}
                        />
                      </div>
                      {/* <table className="lp-list-table table w-100">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Tên file</th>
                            <th>Mô tả</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {(contest.files || []).map((file: any, i: number) => (
                            <tr key={file.id}>
                              <th>{i + 1}</th>
                              <th title={file.name}>
                                {file.name.length > 50
                                  ? file.name.substring(0, 35) + "..."
                                  : file.name}
                              </th>
                              <th>{file.subDescription}</th>
                              <th>
                                <a target="_blank" href={file.path}>
                                  Tải về
                                </a>
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table> */}
                    </div>
                    <div
                      id="result"
                      className="tab-pane fade"
                      style={{
                        overflow: "auto",
                      }}>
                      <div style={{ width: "100%" }}>
                        <EntryList title="" entries={contestEntries || []} />
                      </div>

                      {/* <table className="lp-list-table table">
                        <thead>
                          <tr>
                            <th>Entry date</th>
                            <th>Tệp bài thi</th>
                            <th>Điểm thi</th>
                            <th>Kết quả đánh giá</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(contestEntries || []).map((entry: any) => (
                            <tr>
                              <th>{moment(entry.entryDate).format("HH:mm DD/MM/YYYY")}</th>
                              <th>{entry.file && entry.file.name}</th>
                              <th>{entry.finalMark}</th>
                              <th>
                                {entry.isPassed ? (
                                  <Badge status="success" text="Passed" />
                                ) : (
                                  <Badge status="error" text="Not passed" />
                                )}
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
                {/* Course Details Wrapper End */}
              </div>
              <div className="col-lg-4 col-12 order-lg-2 max-mb-50">
                <div className="sidebar-widget-wrapper pl-30 pl-md-0 pl-sm-0 pl-xs-0 mb-5">
                  <div className="sidebar-widget-content">
                    {isStarted && !isEnded && isOngoing ? (
                      <div className="sidebar-entry-event" style={{ minHeight: 0 }}>
                        <div className="entry-register">
                          <ul className="entry-event-info">
                            <li className="meta-price">
                              <span className="meta-label">
                                <i className="meta-icon far fa-stopwatch" />
                                Thời gian còn lại :
                              </span>
                              <span className="meta-value">
                                <span
                                  className="event-price"
                                  style={{
                                    color: "#4CC9F0",
                                  }}>
                                  {" "}
                                  <Countdown
                                    date={new Date(contest.endDate)}
                                    onComplete={() => {
                                      setIsEndTime(true);
                                    }}
                                    renderer={({ hours, minutes, seconds, completed, days }) => {
                                      const padd = (number: number) => {
                                        if (number >= 10) return number.toString();
                                        else return `0${number}`;
                                      };
                                      if (completed) {
                                        // Render a completed state
                                        return "Hết thời gian";
                                      } else {
                                        // Render a countdown
                                        return (
                                          <span>
                                            {days > 0 ? `${days} ngày` : ""} {padd(hours)}:
                                            {padd(minutes)}:{padd(seconds)}
                                          </span>
                                        );
                                      }
                                    }}
                                  />
                                </span>
                              </span>
                            </li>
                          </ul>

                          <p className="event-register-message">
                            Hệ thống chỉ ghi nhận lần nộp bài cuối cùng
                          </p>

                          {!isEndTime ? (
                            <div
                              style={{
                                border: "2px",
                                backgroundColor: "#4CC9F0",
                                color: " #ffffff",
                                cursor: "pointer",
                                height: "100%",
                                padding: "5px",
                              }}
                              {...getRootProps()}>
                              <input {...getInputProps()} accept="application/pdf" />
                              {submitting ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}>
                                  <Spin indicator={antIcon} /> Wait for it...
                                </p>
                              ) : (
                                <p
                                  style={{
                                    textAlign: "center",
                                  }}>
                                  Nộp bài
                                </p>
                              )}
                            </div>
                          ) : null}

                          {errorText && (
                            <div
                              style={{
                                color: "red",
                                textAlign: "center",
                                padding: 5,
                                fontSize: 18,
                              }}>
                              {errorText}
                            </div>
                          )}
                          {/* <div>Submit</div> */}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          width: "100%",
                          minHeight: 30,
                          lineHeight: 3,
                        }}>
                        <span
                          style={{
                            fontSize: "24px",
                            fontWeight: 800,
                            color: "#20AD96",
                          }}>
                          {status}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="sidebar-widget pl-30 mb-3">
                  <h3 className="sidebar-widget-title">Bài viết liên quan</h3>
                  <div className="sidebar-widget-content">
                    <ul className="sidebar-widget-course">
                      {(contest.articles || []).map((article: any) => (
                        <li className="widget-course">
                          <div className="thumbnail" style={{ width: 50, height: 50 }}>
                            <a
                              href={"/blog/" + article.id + "/" + article.friendlyUrl}
                              className="image">
                              <img
                                src={Common.getImageThumbnail(
                                  article.images,
                                  "?Mode=Crop&width=100&height=100"
                                )}
                                alt="Course Image"
                              />
                            </a>
                          </div>
                          <div className="info">
                            {/* <span className="price">
                            $40<span>.00</span>
                          </span> */}
                            <h6 className="title">
                              <a href={"/blog/" + article.id + "/" + article.friendlyUrl}>
                                {article.name}
                              </a>
                            </h6>
                            <p>{article.subDescription}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default ContestDetail;
