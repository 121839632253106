import { Button, Card, Checkbox, Form, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";
import Swal from "sweetalert2";
import { v4 } from "uuid";
import { getCommonStorage } from "../../../../Config/gql";
import { exec } from "../../../../Utils/dn";
import { BasePage } from "../../ComponentCommon/BasePage";
import { industries } from "../Work/data";
import { availableDays } from "./data";

interface IFormData {
  available: boolean;
  availableDays: number;
  industries: number[];
  declaration: boolean;
  agreement: boolean;
}

const Preference = () => {
  const [checkList, setCheckList] = useSetState({
    isAvailable: false,
    isAgree: false,
    isDeclaration: false,
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const handleSubmit = (formData: IFormData) => {
    console.log(formData, "21");
    setBtnLoading(true);
    exec(
      "User",
      "UpdateDirectorshipOpportunitiesUser",
      {
        Id: getCommonStorage().userId,
        Available: checkList.isAvailable,
        AvailableDays: formData.availableDays ,
        PreferredIndustry: formData.industries ,
        DeclarationOfNoObstacleAndBankruptcy: checkList.isDeclaration ,
        AgreeTermsAndConditions: checkList.isAgree ,
      },
      ["actionBy"]
    ).then((data) => {
      if (data.data.Success === true) { 
        Swal.fire({
          text:t("Save information success"),
          icon:"success",
        })
      } else {
        Swal.fire("something wrong");
      }
    });
    form.resetFields();
    setCheckList({ isAgree: false, isAvailable: false, isDeclaration: false });
  };

  return (
    <BasePage title={t("Directorship Opportunities")}>
      <Card title={<div style={{ textAlign: "center" }}>Xin cảm ơn Anh/Chị đã quan tâm.<br/> Nội dung này hiện đang được xây dựng & hoàn thiện. Vui lòng email về địa chỉ membership@viod.vn để được hỗ trợ thông tin.
<br/><b>Trân trọng</b></div>}></Card>
      {/* <Card title={<div style={{ textAlign: "center" }}>{t("Sign up for job opportunities")}</div>}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="available">
            <Checkbox
              checked={checkList.isAvailable}
              onChange={(e) => {
                setCheckList({ isAvailable: e.target.checked });
              }}
            />

            <Typography.Text className="ml-2">
              {t("Yes, i am interested and available to serve as a new director.")}
            </Typography.Text>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 3 }}
            labelAlign="left"
            name="availableDays"
            label={t("Available Days")}>
            <Select disabled={!checkList.isAvailable}>
              {availableDays.map((day: { value: number; name: string }) => (
                <Select.Option value={day.value}>{day.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            labelCol={{ span: 3 }}
            labelAlign="left"
            name="industries"
            label={t("Preferred Industry")}>
            <Select mode="multiple" disabled={!checkList.isAvailable}>
              {industries.map((day: { value: number; name: string }) => (
                <Select.Option value={day.value}>{day.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item valuePropName="checked" name="declaration" wrapperCol={{ offset: 3 }}>
            <Checkbox
              checked={checkList.isDeclaration}
              onChange={(e) => {
                setCheckList({ isDeclaration: e.target.checked });
              }}
              disabled={!checkList.isAvailable}
            />

            <Typography.Text className="ml-2">
              {t("Declaration of no obstacle and bankruptcy")}
            </Typography.Text>
          </Form.Item>
          <Form.Item valuePropName="checked" name="agreement" wrapperCol={{ offset: 3 }}>
            <Checkbox
              checked={checkList.isAgree}
              onChange={(e) => {
                setCheckList({ isAgree: e.target.checked });
              }}
              disabled={!checkList.isAvailable}
            />

            <Typography.Text className="ml-2">
              {t("Agree to Viod Terms and Conditions")}
            </Typography.Text>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            <Button type="primary" htmlType="submit">
              {t("Save Changes")}
            </Button>
          </Form.Item>
        </Form>
      </Card> */}
    </BasePage>
  );
};

export default Preference;
