import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { StyledItemInfo, StyledSub, StyledTable, StyledTitle } from "./styledComponents";
import moment from "moment";
import { Status } from "../../User Account/Profile/maxcoach/Profile";
import { borderInputColor } from "../../../../../Utils/themeConfig";
import { useMount } from "react-use";

function PurchaseDetail() {
  const location = useLocation();
  const history = useHistory();

  const order = location.state?.state;
  const merchant = window.Merchant.data;

  useMount(() => {
    window.scrollTo(0, 0);
  });

  if (!order) return null;

  return (
    <div className={"section section-padding-bottom"}>
      <div className={"container"}>
        <StyledTitle>
          <span onClick={() => history.goBack()}>
            <i className="fas fa-arrow-left" />
            Back
          </span>
          <span>Purchase Detail</span>
        </StyledTitle>
        <div className={"row row-cols-lg-4 row-cols-md-2 row-cols-1"}>
          <StyledItemInfo className={"col"}>
            <div>Purchase ID</div>
            <div>#{order.code}</div>
          </StyledItemInfo>
          <StyledItemInfo className={"col"}>
            <div>Purchase Date</div>
            <div>{moment(order.createdDate).format("MMM DD, YYYY")}</div>
          </StyledItemInfo>
          <StyledItemInfo className={"col"}>
            <div>Payment Method</div>
            <div>{order.paymentMethodName}</div>
          </StyledItemInfo>
          <StyledItemInfo className={"col"}>
            <div>Status</div>
            <div>{Status(order.allStatus)}</div>
          </StyledItemInfo>
        </div>
        <StyledTable className="lp-list-table table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Description</th>
              <th>Provider</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {(order.orderLines || []).map((line, j) => {
                  return <div key={"l" + j}>{line.targetName}</div>;
                })}
              </td>
              <td>{order.note}</td>
              <td>{merchant.name}</td>
            </tr>
          </tbody>
        </StyledTable>
        <div style={{ textAlign: "right" }}>
          <div style={{ display: "inline-block", minWidth: 300 }}>
            <StyledSub>
              <span>Sub Total</span>
              <span>{Number(order.grandTotal).toLocaleString("vi-VN")}₫</span>
            </StyledSub>
            <StyledSub>
              <span>Discount</span>
              <span>0₫</span>
            </StyledSub>
            <StyledSub>
              <hr />
            </StyledSub>
            <StyledSub style={{ marginTop: 24 }}>
              <span>
                <b>TOTAL</b>
              </span>
              <span>{Number(order.grandTotal).toLocaleString("vi-VN")}₫</span>
            </StyledSub>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseDetail;
