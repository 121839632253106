/* eslint-disable */
import Book from "./Book";
import { reducer as Merchant } from "./Merchant";
import { reducer as Order } from "./Order";
import { reducer as User } from "./User";
import { reducer as Event } from "./Event";
import { reducer as Cart } from "./Cart";
import { reducer as Product } from "./Product";
import { reducer as Rating } from "./Rating";
import { reducer as Layout } from "./Layout";
import { reducer as Schedule } from "./Schedule";
import Course from "./Course";
import Quiz from "./Quiz";
import Notification from "./Notification";

// const rootReducer = combineReducers({
//   Book,
//   Merchant,
//   Course,
//   Quiz,
//   Layout,
//   Product,
//   Notification,
//   Order,
//   Event,
//   Schedule,
//   Rating,
//   User
// });

export default {
    Book,
    Merchant,
    Course,
    Quiz,
    Layout,
    Product,
    Notification,
    Order,
    Event,
    Schedule,
    Rating,
    User,
    Cart
};
