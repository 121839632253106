import * as ecs from "../../Layout/Course/maxcoach/UserCourse/ecs";
import React from "react";
import Breadcrumb from "../../ComponentCommon/maxcoach/Breadcrumb";
import { useTranslation } from "react-i18next";

export function BasePage(props: {
  title?: any;
  description?: any;
  children: any;
  hideTitle?: boolean;
}) {
  const {t}= useTranslation()

  return (
    <>
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{t(props.title)}</h1>
              {props.description && <p>{props.description}</p>}
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: t(props.title) }]} />
      </div>
      <div className="sidebar-widget-wrapper pr-0">
        <div className="sidebar-widget">
          <div className="sidebar-widget-content">
            <div className="section section-padding-bottom">
              <div className="container">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
