import { gql } from "urql";

export const QueryCertificate = gql`
  query Certificate($languageId: String!, $merchantId: String!, $tokenUser: String, $type: Int!) {
    data: articleusercertificates(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        type: $type
      }
    ) {
      items {
        id
        name
        subDescription
        files{
            id
            name
            path
        }
      }
     message
     success
     totalCount
      
    }
}
`;

