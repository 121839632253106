/* eslint-disable */
import Common from "../../Utils/Common";
import Configuration from "../../Config/Config";

const languageId = Common.getCurrentLanguageId();
const merchantId = localStorage.getItem(Configuration.merchantId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);
console.log(languageId,"9")
export const QueryGraphQL = {

  getNavigation: function (id,currentLanguageId) {
    return Common.getDataGraphQl(`
        {
            navigation(param:{id:"${id}",tokenUser:"${tokenId}",languageId:"${currentLanguageId || languageId}",merchantId:"${merchantId}"})
           {
                id,
                name,
                description
                navigation
            } 
           }
         
        `);
  },
  getWishList: function () {
    return Common.getDataGraphQl(`
           {
            wishlistproducts(param:{limit:100, keyword:"",offset:0,order:"desc",sort:"createdDate",tokenUser:"${tokenId}",
                languageId:"${languageId}"
          
                    ,merchantId:"${merchantId}" })
                {
                    totalCount,
                    message, 
                    success,
                    items{
                        id
                        name
                        price
                        images{
                            isFeatured
                            id
                            name
                            path
                        }

                    },
                    
                }
              }
    `);
  },
  getMerchant: function () {
    return Common.getDataGraphQl(`
     {
            merchant(param:{id:"${merchantId}"})
            {
              code,
              configuration,
              createdBy,
              createdDate
              izziMember
            }
          }
`);
  },
  getAuthors: function (offset, limit) {
    return Common.getDataGraphQl(`
       {
            users(param:{limit:${limit}, keyword:"",offset:${offset},order:"desc",sort:"createdDate",
            languageId:"${languageId}"
            type: 64,
                ,merchantId:"${merchantId}" })
            {
                totalCount,
                message, 
                success,
                items{
                    active,
                    categories {
                      id,
                      name
                    },
                    createdBy,
                    createdDate,
                    email,
                    id,
                    images {
                      id
                    },
                    merchantId,
                    mobile,
                    modifiedBy,
                    modifiedDate,
                    name,
                    userName,
                    merchantCode
                },
                
            }
          }
`);
  },
  subcribeUsers: function () {
    return Common.getDataGraphQl(`
        {
            subcribeusers(param:
            {   
                tokenUser:"${tokenId}"  ,merchantId:"${merchantId}"    })        
            {
            totalCount,
            items
            {   

                id,
                allType,
                name,
                
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },

  pagingNotificationTransactions: function (offset, limit) {
    return Common.getDataGraphQl(`
       {
        notificationtransactions(param:{limit:${limit},offset:${offset},
            order:"desc",sort:"createdDate",
          tokenUser:"${tokenId}",
        
        ,merchantId:"${merchantId}"
       })
        {
              items {
                id
                  message,
        urlReturn,
        allStatus,
                 createdDate,
              }
              ,totalCount,
          message
        }
      }
`);
  },
  getProductById(id) {
    return Common.getDataGraphQl(`
        {
            product(param:
            {   
                id:"${id}"                
                languageId:"${languageId}",                
                merchantId:"${merchantId}"})
            {
            id,            
                name,
                subDescription,
                description,
                price,
                sku,
                images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
                authors{
                    id,
                    name
                }
                categories {
                    id,
                    name
                  }
                createdDate,
                }
        }
        `);
  },
  pagingProducts: function (type, offset, limit, categoryId) {
    var temp = "";
    if (categoryId != undefined) {
      var categoryIds = [];
      categoryIds.push(categoryId);

      if (categoryId != "") {
        temp += "categoryIds:" + JSON.stringify(categoryIds);
      }
    }

    return Common.getDataGraphQl(`
        {
            products(param:
            {   
                type: ${type},
                limit:${limit},offset:${offset},
                
                ${temp}
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,
        items
    {

        id,
        name,
        subDescription,
        price,
        attributeValues{
          id, 
          name, 
          value
        }
        images {
        id,
            path,
            displayOrder,
            isFeatured,
            },
            categories {
                id,
            name
    }
    createdDate,
        }
}
        }
`);
  },
  getNewestProduct: function (type, limit) {
    return Common.getDataGraphQl(`
        {
            products(param:
            {   
                type:${type},
                limit:${limit},offset:0,
                
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,,
        items
    {

        id,
            name,
            subDescription,
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },
  getCategoryById(id) {
    console.log(`
        {
            category(param:
            {   
                id:"${id}"                
                languageId:"${languageId}",
                merchantId:"${merchantId}"})
                
            {
            id,
                name,
                subDescription,
                description,
           
                images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                }
                createdDate,
                }
        }
        `);
    return Common.getDataGraphQl(`
        {
            category(param:
            {   
                id:"${id}"                
                languageId:"${languageId}",                
                merchantId:"${merchantId}"})
            {
            id,
                name,
                subDescription,
                description,
           
                images {
                    id,
                    path,

                    displayOrder,
                    isFeatured,
                }
                createdDate,
                }
        }
        `);
  },
  getArticleMemberById(id) {
    return Common.getDataGraphQl(`
        {
            articlemember(param:
            {   
                id:"${id}",
                tokenUser:"${tokenId}",
                languageId:"${languageId}",
                merchantId:"${merchantId}"})
            {
            id,
            message,
                name,
                subDescription,
                description,
                postTime,
                authors{
                    id,
                    name,
                    email,
                    mobile
                }
                files {                    
                    id,
name, 
 contentLength,
contentType,
typeStorage,
                    path
                }
                images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
                categories {
                    id,
                    name
                  }
                createdDate,
                }
        }
        `);
  },

  getArticleById(id) {
    return Common.getDataGraphQl(`
        {
            article(param:
            {   
                id:"${id}"                
                languageId:"${languageId}",
                merchantId:"${merchantId}"})
            {
            id,
            message,
                name,
                subDescription,
                description,
                authors{
                    id,
                    name,
                    email,
                    mobile
                }
                files {                    
                    id,
name, 
 contentLength,
contentType,
typeStorage,
                    path
                }
                images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
                categories {
                    id,
                    name
                  }
                createdDate,
                }
        }
        `);
  },
  pagingArticles: function (type, offset, limit, categoryId) {
    var categoryIds = [];
    categoryIds.push(categoryId);
    var temp = "";
    if (categoryId != "") {
      temp += "categoryIds:" + JSON.stringify(categoryIds);
    }
    return Common.getDataGraphQl(`
        {
            articles(param:
            {   
                type: ${type},
                limit:${limit},offset:${offset},
                order:"desc",sort:"createdDate",
                ${temp}
                published: true,
                             
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,
        items
    {

        id,
            name,
            subDescription,
            files {
            id,
            name, 
 contentLength,
contentType,
                path,    
            }
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
                 files {
            id,
                path,
                
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },
  pagingFreeCourseArticles: function (type, offset, limit) {
    return Common.getDataGraphQl(`
        {
            freecoursearticles(param:
            {   
                type: ${type},
                limit:${limit},offset:${offset},
                order:"desc",sort:"createdDate",
                published: true,                
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,
        items
    {

        id,
            name,
            subDescription,
            files {
                id,
                name, 
     contentLength,
    contentType,
                    path,    
                }
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },         
   
        createdDate,
            }
}
        }
`);
  },
  pagingQuizs: function (type, offset, limit, categoryId) {
    var categoryIds = [];
    categoryIds.push(categoryId);
    var temp = "";
    if (categoryId != "") {
      temp += "categoryIds:" + JSON.stringify(categoryIds);
    }
    console.log(`
        {
            quizs(param:{
                type: ${type},
                limit:${limit},offset:${offset},
                ${temp}
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",                
                 merchantId: "${merchantId}"})
            {
                items{
                    id,
                    name,
                  subDescription,
                  description,
                  merchantId,
                  published,
                  createdDate,
                  categories{
                      id,
                      name,
                      displayOrder,
                  }
                  images {
                    id,
                    name,
                    path,
                    isFeatured,
                  },
                  option,
                  duration,     
                },
                totalCount,
                success,
                message
            }

        }

        `);
    return Common.getDataGraphQl(`
        {
            quizs(param:{
                type: ${type},
                limit:${limit},offset:${offset},
                
                ${temp}
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",                
                 merchantId: "${merchantId}"})
            {
                items{
                    id,
                    name,
                  subDescription,
                  description,
                  merchantId,
                  published,
                  createdDate,
                  categories{
                      id,
                      name,
                      displayOrder,
                  }
                  images {
                    id,
                    name,
                    path,
                    isFeatured,
                  },
                  option,
                  duration,     
                },
                totalCount,
                success,
                message
            }

        }
`);
  },
  getDetailQuiz(idQuiz) {
    return Common.getDataGraphQl(
      `
            {
                quiz(param: {id: "${idQuiz}",
                languageId:"${languageId}",
                 merchantId: "${merchantId}",
                 tokenUser:"${tokenId}"
                }
                 
                 ) {
                  id
                  name
                  duration
                  subDescription
                  questions {
                    id
                    name
                    point
                    images {
                      id
                      name
                      path
                      displayOrder
                    }
                    subDescription
                    description
                    type
                    displayOrder
                    answers {
                      id
                      name
                      images {
                        id
                        name
                        path
                        displayOrder
                      }
                      isCorrect
                      displayOrder
                    }
                  }
                  description
                  categories{
                    id,
                    name,
                    displayOrder,
                }
                  images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
                  option
                }
              }                  
              
            `
    );
  },
  getCourse: function (offset, limit) {
    return Common.getDataGraphQl(`
        {
            courses(param:
            {                  
                limit:${limit},offset:${offset},
                   languageId:"${languageId}",
                   order:"desc",sort:"createdDate",
                tokenUser:"${tokenId}"})
            {
            totalCount,
            items
            {   

                id,
                type,
                name,
                subDescription,
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },
  getProduct: function () {
    return Common.getDataGraphQl(`
        {
            courses(param:
            {                  
                
                   languageId:"${languageId}",
                   order:"desc",sort:"createdDate",
                tokenUser:"${tokenId}"})
            {
            totalCount,
            items
            {   

                id,
                type,
                name,
                subDescription,
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },
  getCategoryByType: function (type) {
    return Common.getDataGraphQl(`
        {
            categories(param:
            {   
                type:${type},
                published: true,
                limit:1000,offset:0,
                order:"desc",sort:"createdDate",
                   languageId:"${languageId}",
                merchantId:"${merchantId}"})
            {
            totalCount,
            items
            {   

                id,
                type,
                name,
                subDescription,
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },
  getAllSubcriberCategoryByType: function (type) {
    return Common.getDataGraphQl(`
        {
            allsubcribecategories(param:
            {   
                tokenUser:"${tokenId}",   
                type:${type},
                published: true,
                limit:1000,offset:0,
                order:"desc",sort:"createdDate",
                   languageId:"${languageId}",
                merchantId:"${merchantId}"})
            {
            totalCount,
            items
            {   

                id,
                type,
                name,
                subDescription,
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },
  getSubcribeCategories: function () {
    return Common.getDataGraphQl(`
        {
            subcribecategories(param:
            {   
                tokenUser:"${tokenId}",            
                limit:1000,offset:0,
                order:"desc",sort:"createdDate",
                   languageId:"${languageId}",
                merchantId:"${merchantId}"})
            {
            totalCount,
            items
            {   

                id,
                type,
                name,
                subDescription,
           images {
                    id,
                    path,
                    displayOrder,
                    isFeatured,
                },
               
                createdDate,
            }
                }
        }
        `);
  },
  //subcribearticlesbyuser
  subcribeArticlesByUser: function (type, offset, limit, userId) {
    var userIds = [];
    userIds.push(userId);
    var temp = "";
    if (userId != "") {
      temp += "authorIds:" + JSON.stringify(userIds);
    }
    return Common.getDataGraphQl(`
        {
            subcribearticlesbyuser(param:
            {   
                type:${type},
                limit:${limit},
                offset:${offset},
                
                tokenUser:"${tokenId}",
                ${temp}
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,,
        items
    {

        id,
            name,
            subDescription,            
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },
  getSubcribeArticles: function (type, offset, limit, categoryId) {
    var categoryIds = [];
    categoryIds.push(categoryId);
    var temp = "";
    if (categoryId != "") {
      temp += "categoryIds:" + JSON.stringify(categoryIds);
    }
    return Common.getDataGraphQl(`
        {
            subcribearticlesbycategory(param:
            {   
                type:${type},
                limit:${limit},
                offset:${offset},
                order:"desc",sort:"createdDate",
                tokenUser:"${tokenId}",
                ${temp}
                
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,,
        items
    {

        id,
            name,
            subDescription,
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },

  getNewestArticle: function (type, limit) {
    return Common.getDataGraphQl(`
        {
            articles(param:
            {   
                type:${type},
                limit:${limit},offset:0,
                
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,,
        items
    {

        id,
            name,
            postTime
            subDescription,
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },
  getBoardOpeningArticle: function (targetId) {
    return Common.getDataGraphQl(`
        {
            articles(param:
            {   
                targetId:"${targetId}"
                type:12,
                limit:1000,offset:0,
                
                published: true,
                order:"desc",sort:"createdDate",
                languageId:"${languageId}",
                 merchantId: "${merchantId}"})
            {
    totalCount,,
        items
    {

        id,
        isFeatured
            name,
            postTime
            subDescription,
            images {
            id,
                path,
                displayOrder,
                isFeatured,
                },
        categories {
            id,
                name
        }
        createdDate,
            }
}
        }
`);
  },
  getDataByToken(token) {
    return Common.getDataGraphQl(
      `{
                userInformation(param: {tokenUser: "${token}", languageId:"${languageId}"}) {
                    item {
                        id
                        totalPoint
                        memberName
                        firstName
                        lastName
                        userName
                        email
                        companyName,
                        companySite,
                        communication,
                        languageId,
                        dateOfBirth
                        timeZone,
                        active
                        avatar
                        mobile
                        merchantId
                        name,
                        allType,
                        expiredDate,
                        images {
                          id
                          path
                          name
                        }
                      }
                }
              }`
    );
  },
};
