import { gql } from "urql";

export const QueryMemberShips = gql`
  query ProfessionalMemberships($languageId: String!, $merchantId: String!, $tokenUser: String,$targetId: String ) {
    data: professionalMemberships(
      param: { tokenUser: $tokenUser, languageId: $languageId, merchantId: $merchantId,targetId: $targetId}
    ) {
      items {
        id
        type
        membershipNo
        from
        to
        institute
        
      }
    }
  }
`;
export const QueryMemberShip = gql`
  query ProfessionalMemberships($languageId: String!, $merchantId: String!,$id: String ) {
     professionalMembership(
      param: {  languageId: $languageId, merchantId: $merchantId,id: $id}
    ) {
    
        id
        type
        membershipNo
        from
        to
        institute
        

    }
  }
`;
