import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Select, Row, Empty, Divider, Modal, Tag } from "antd";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined } from "@ant-design/icons";
import DNModal from "../DnStyles/Modal";
import _ from "lodash";
import { exec, useDNStyles } from "../../../../Utils/dn";
import { QueryCompanyAddress } from "./gql";
import { getCommonStorage } from "../../../../Config/gql";
import { v4 } from "uuid";
import { useQuery } from "urql";
import { types, industries } from "./data";
import { filterArrayWithArrayNum, findValue } from "./ultils";
import {useTranslation} from "react-i18next"

interface ICompany {
  
  id: string;
  name: string;
  companyIndustries: number[];
  companyType: number;
}
interface IFormData {
  type: number[];
  name: string;
  industry: number[];
}
const WorkCompany = () => {
  const {t } = useTranslation()
  const [workCompanyForm] = Form.useForm();
  const [id] = useState(v4());
  const [editId, setEditId] = useState<string>("");
  const { Option } = Select;
  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });
  const [queryCompanyAddress, refetchCompanyAddresses] = useQuery({
    query: QueryCompanyAddress,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 16,
      };
    })(),
    requestPolicy: "network-only",
  });

  function _onFinish(formData: IFormData) {
    setState({ loading: true });

    exec(
      "Address",
      state.isEdit ? "UpdateCompanyAddress" : "CreateCompanyAddress",
      {
        Id: state.isEdit ? editId : v4(),
        Type: formData.type,
        Name: formData.name,
        Industries: formData.industry,
      },
      ["actionBy", "languageId", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchCompanyAddresses();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  }
  const _onClickEdit = (company: ICompany) => {
    setEditId(company.id);
    workCompanyForm.setFieldsValue({
      name: company.name,
      industry: company.companyIndustries,
      type: company.companyType,
    });

    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec(
          "Address",
          "DeleteAddress",
          {
            Id: id,
          },
          ["actionBy"]
        ).finally(refetchCompanyAddresses);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };

  const companies = queryCompanyAddress.data?.data?.items;

  return (
    <Fragment>
      <BasePage title={t("My Company")} hideTitle={true}>
        <Card
          title={t("My Company")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                workCompanyForm.resetFields();
                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={queryCompanyAddress.fetching}>
          {companies && companies?.length === 0 && (
            <Empty
              description={
                <span>
                  {("No Company")}{" "}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {companies?.length > 0 &&
            companies?.map((company: ICompany, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Name")}:
                      </Col>
                      <Col span={20}>{company.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Type")}:
                      </Col>
                      <Col span={20}>{findValue(types, company.companyType).name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Company Type")}:
                      </Col>
                      <Col span={20}>
                        {filterArrayWithArrayNum(industries, company.companyIndustries).map(
                          (industry: any) => (
                            <Tag key={industry.value}>{industry.name}</Tag>
                          )
                        )}{" "}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Button type={"link"} onClick={() => _onClickEdit(company)}>
                        {t("Edit")}
                      </Button>
                      <Button type={"link"} danger={true} onClick={() => _remove(company.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
      </BasePage>
      <DNModal
        title={state.isEdit ? t("Edit") : t("New Company") }
        visible={state.isModalVisible}
        onCancel={() => {
          setState({ isModalVisible: false });
        }}
        okButtonProps={{
          form: id,
          htmlType: "submit",
        }}
        confirmLoading={state.loading}>
        <Form id={id} form={workCompanyForm} onFinish={_onFinish} layout={"vertical"}>
          <Form.Item name={"name"} rules={[{ required: true, message: "Vui lòng nhập Tên" }]}>
            <Input placeholder={t("Company Name")} />
          </Form.Item>
          <Form.Item name={"type"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Select placeholder={t("Type")}>
              {types.map((type, i) => (
                <Option key={i} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"industry"}
            rules={[
              { required: true, message: "Vui lòng nhập" },
              { max: 3, type: "array", message: "Tối đa 3 loại hình" },
            ]}>
            <Select mode="multiple" placeholder={t("Industry")}>
              {industries.map((industry, i) => (
                <Option key={i} value={industry.value}>
                  {industry.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </DNModal>
    </Fragment>
  );
};

export default WorkCompany;
