import { gql } from "urql";

export const QUERY_SKIP_PURCHASE_PRODUCTS = gql`
  query QueryProducts(
    $type: Int
    $keyword: String
    $limit: Int = 15
    $offset: Int = 0
    $published: Boolean = true
    $merchantId: String
    $order: String = "desc"
    $sort: String = "createdDate"
    $languageId: String!
    $categoryIds: [String] = []
    $minStudents: Int = 0
    $maxStudents: Int = 10000000
    $tokenUser: String
  ) {
    skippurchasedproducts(
      param: {
        type: $type
        keyword: $keyword
        limit: $limit
        offset: $offset
        published: $published
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        categoryIds: $categoryIds
        fromTotal: $minStudents
        toTotal: $maxStudents
        tokenUser: $tokenUser
      }
    ) {
      totalCount
      items {
        id
        name
        subDescription
        discounts {
          friday
          monday
          from
          monday
          resultType
          resultValue
          saturday
          sunday
          thursday
          to
          tuesday
          wednesday
          membershipIds
          membershipNames
        }
        originalPrice
        price
        ratingId
        images {
          id
          path
          displayOrder
          isFeatured
        }
        categories {
          id
          name
          type
        }
        authors {
          id
          firstName
          lastName
          name
        }
        attributeValues {
          id
          attributeId
          displayOrder
          imagePath
          name
          value
        }
        createdBy
        createdDate
        quantityRating
        quantityStudent
        periodCourse
        merchantLogo
        averageRating
      }
    }
  }
`;
export const QUERY_PRODUCTS = gql`
  query QueryProducts(
    $type: Int
    $keyword: String
    $limit: Int = 15
    $offset: Int = 0
    $published: Boolean = true
    $merchantId: String
    $order: String = "desc"
    $sort: String = "createdDate"
    $languageId: String!
    $categoryIds: [String] = []
  ) {
    products(
      param: {
        type: $type
        keyword: $keyword
        limit: $limit
        offset: $offset
        published: $published
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        categoryIds: $categoryIds
      }
    ) {
      totalCount
      items {
        id
        sku
        name
        quantity
        subDescription
        exchangePoint
        price
        categories {
          id
          name
        }
        images {
          path
          id
          isFeatured
        }
        attributeValues {
          id
          name
          value
        }

        discounts {
          friday
          monday
          from
          monday
          resultType
          resultValue
          saturday
          sunday
          thursday
          to
          tuesday
          wednesday
          membershipIds
          membershipNames
        }
      }
    }
  }
`;

export const QUERY_PRODUCT_CATEGORIES = gql`
  query QueryProductCategories($type: Int = 3, $languageId: String!, $merchantId: String!) {
    categories(
      param: {
        type: $type
        published: true
        limit: 1000
        offset: 0
        order: "desc"
        sort: "displayOrder"
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      items {
        id
        name
        type
        parentId
      }
    }
  }
`;
export const QUERY_PRODUCT = gql`
  query QueryProduct($id: String!, $languageId: String!, $merchantId: String!) {
    product(param: { id: $id, languageId: $languageId, merchantId: $merchantId }) {
      id
      sku
      name
      description
      subDescription
      price
      courseId
      ratingId
      authors {
        id
        name
      }
      images {
        id
        path
        isFeatured
      }
      categories {
        id
        name
      }
      createdDate
    }
  }
`;
export const QUERY_COURSE_PREVIEW = gql`
  query QueryCoursePreview($id: String!, $languageId: String!, $merchantId: String!) {
    usercoursepreview(param: { id: $id, languageId: $languageId, merchantId: $merchantId }) {
      id
      type
      name
      subDescription
      type
      period
      countLesson
      courseLessons {
        id
        name
        duration
        locked
        conditionValue
        slides {
          id
          name
        }
        videos {
          id
          name
          contentLength
          contentType
          typeStorage
          seen
          path
        }
        quizs {
          id
          seen
          score
          totalScore
          scoreDisplay
          name
        }
      }
      images {
        id
        path
        displayOrder
        isFeatured
      }
      createdDate
    }
  }
`;
export const QUERY_RATE = gql`
  query QueryRate($id: String!, $targetId: String!, $merchantId: String!) {
    ratingmember(param: { id: $id, targetId: $targetId, merchantId: $merchantId }) {
      id
      maxScore
      ratingTransactions {
        id
        comment
        score
        createdDate
        targetId
        userName
        userAllType
        userImagePath
      }
    }
  }
`;
export const QUERY_RELATE_COURSE_PRODUCTS = gql`
  query QueryProducts(
    $type: Int
    $keyword: String
    $limit: Int = 15
    $offset: Int = 0
    $published: Boolean = true
    $merchantId: String
    $order: String = "desc"
    $sort: String = "createdDate"
    $languageId: String!
    $categoryIds: [String] = []
    $minStudents: Int = 0
    $maxStudents: Int = 10000000
    $tokenUser: String
  ) {
    relatecourseproducts(
      param: {
        type: $type
        keyword: $keyword
        limit: $limit
        offset: $offset
        published: $published
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
        categoryIds: $categoryIds
        fromTotal: $minStudents
        toTotal: $maxStudents
        tokenUser: $tokenUser
      }
    ) {
      totalCount
      items {
        id
        name
        subDescription
        discounts {
          friday
          monday
          from
          monday
          resultType
          resultValue
          saturday
          sunday
          thursday
          to
          tuesday
          wednesday
          membershipIds
          membershipNames
        }
        originalPrice
        price
        ratingId
        images {
          id
          path
          displayOrder
          isFeatured
        }
        categories {
          id
          name
          type
        }
        authors {
          id
          firstName
          lastName
          name
        }
        attributeValues {
          id
          attributeId
          displayOrder
          imagePath
          name
          value
        }
        createdBy
        createdDate
        quantityRating
        quantityStudent
        periodCourse
        merchantLogo
        averageRating
        discounts {
          friday
          monday
          from
          monday
          resultType
          resultValue
          saturday
          sunday
          thursday
          to
          tuesday
          wednesday
          membershipIds
          membershipNames
        }
      }
    }
  }
`;
