import styled from "styled-components";
import size from "../../../../../../Utils/size";
import { primaryColor } from "../../../../../../Utils/themeConfig";

export const SlideSlipRoot = styled.div`
  display: none;

  ${size.md} {
    display: block;
  }

  .ant-carousel .slick-dots li button {
    height: 4px;
    border-radius: 2px;
  }

  .ant-carousel .slick-dots li {
    height: 4px;
    width: 4px;
  }

  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }

  .dx-next,
  .dx-prev {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0px 0px 15px #0a2a381a;
    border: 0;
    color: var(--primary-text-color);
    position: absolute;
    top: calc(50% - 20px);

    :hover {
      width: 60px;
      height: 60px;
      top: calc(50% - 30px);
    }
  }

  .dx-next {
    right: -5px;

    :hover {
      right: -15px;
    }
  }

  .dx-prev {
    left: -5px;

    :hover {
      left: -15px;
    }
  }
`;
export const SideSlipContainer = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 64px;
  box-shadow: 0px 3px 15px #0000001a;
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .ant-carousel .slick-dots li button {
    background: #c9d0e0;
    opacity: 1;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${primaryColor};
  }
`;
export const PreviewCourseInfo = styled.div`
  display: table;
  width: 100%;
  margin-bottom: 8px;

  p {
    display: table-cell;
    color: #999fae;
    font-size: 14px;

    span {
      color: var(--primary-text-color);
      font-weight: bold;
    }

    .fa-star {
      color: #fec414;
    }
  }

  p:not(:first-child) {
    text-align: center;
  }

  p:last-child {
    text-align: right;
  }
`;
export const ProductItem = styled.div`
  .course-2 {
    box-shadow: 0px 3px 15px #0000001a;
    background: #ffffff;

    .info .title {
      font-size: 16px;
    }

    .info {
      padding: 16px;

      .dx-author {
        font-size: 14px;
        color: #999fae;
        margin-bottom: 30px;
      }
    }
  }

  .image span:first-child {
    display: inline-block;
    min-height: 150px;
    height: 150px;
    width: 100%;
    background: #c3c3c3;
  }

  .dx-hours {
    position: absolute;
    background: #000000ba;
    color: #ffffff;
    font-size: 12px;
    bottom: 0;
    right: 0;
    border-radius: 4px 0px 0px 0px;
    padding: 3px 8px;
  }

  .dx-brand {
    position: absolute;
    background: #ffffff;
    color: #ffffff;
    font-size: 12px;
    bottom: 0;
    left: 0;
    border-top-right-radius: 4px;
    height: 26px;
    overflow: hidden;
    padding: 6px 6px;

    img {
      height: 100%;
      width: auto !important;
      display: block;
    }
  }

  .dx-enroll {
    display: none;
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: absolute;
    bottom: -80px;
    left: 0;
    width: calc(100% - 30px);
    background: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 15px;
    box-shadow: 0px 3px 15px #0000001a;
    clip-path: inset(0px -10px -10px -10px);

    button {
      width: 100%;
      background: ${primaryColor};
      border-color: ${primaryColor};
    }
  }

  :hover {
    z-index: 555;

    .dx-enroll {
      display: block;
    }
  }

  .dx-rank {
    position: absolute;
    top: 0;
    left: 15px;
    padding: 2px 8px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 14px;
    max-width: 60%;
  }

  .dx-price {
    font-size: 16px;
    position: absolute;
    bottom: 0;
    left: 30px;
    width: calc(100% - 60px);
  }

  .dx-rank-pink {
    background: #ff385c;
    color: #ffffff;
  }

  .dx-rank-blue {
    background: #316fea;
    color: #ffffff;
  }
`;
export const PaginationSection = styled.div`
  .pagination li a {
    font-size: 14px;
    height: 36px;
    width: 36px;
    color: var(--primary-text-color);
  }

  .pagination li a.active {
    background: var(--primary-color);
    color: #fbf8f6;
    height: 36px;
    width: 36px;
  }

  .pagination li a:not(.prev):not(.next) {
    height: 36px;
    width: 36px;
  }

  .pagination li a.navigation-button {
    box-shadow: 0px 0px 15px #0a2a381a;
    color: var(--primary-text-color);
  }

  .pagination li a.navigation-button.disabled {
    color: var(--primary-text-color);
    pointer-events: none;
    color: #eff0f1;
  }
`;
export const FilterValuesContainer = styled.div`
  display: none;
  span {
    color: var(--primary-text-color);
    margin: 0;
    padding: 6px 16px;
    display: inline-block;
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    margin-left: 15px;
    margin-bottom: 32px;
    margin-top: -32px;
    cursor: pointer;
    font-size: 14px;
  }

  ${size.md} {
    display: block;
  }
`;
export const LoadingItemContainer = styled.div`
  margin: 0 15px 15px 15px;

  .ant-skeleton-title {
    display: none;
  }

  .ant-skeleton-paragraph {
    margin-top: 0 !important;
  }

  .ant-skeleton-paragraph li:last-child {
    width: 100% !important;
  }
`;
export const SearchItemContainer = styled.div`
  margin-bottom: 15px;

  input {
    background: #f5f5f5;
    border-radius: 8px;
    min-height: 54px;
  }
`;
export const FilterItemContainer = styled.div`
  list-style: none;
  padding: 0;
  margin-bottom: 10px;
  margin-left: -22px;
  overflow: auto;
  max-height: 300px;

  li {
    font-size: 16px;
    margin: 15px;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }

    .ant-checkbox-inner::after {
      left: 36%;
    }

    .ant-checkbox {
      margin-right: 6px;
    }
  }

  li:first-child {
    margin-top: 0;
  }

  .ant-tree-treenode {
    margin: 4px;
    margin-left: 0;
    font-size: 16px;
  }

  .ant-tree-treenode:first-child {
    margin-top: 0;
  }

  .ant-tree-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .ant-tree-checkbox-inner::after {
    left: 36%;
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    left: 50%;
  }

  .ant-tree .ant-tree-node-content-wrapper {
    padding: 6px 4px 0 4px;
  }

  .ant-tree .ant-tree-treenode.filter-node .ant-tree-title {
    color: var(--primary-text-color);
    font-weight: bold;
  }
`;
export const ExpandButton = styled.div`
  font-size: small;
  position: absolute;
  right: 0;
  top: 2px;
  height: 100%;

  .dx-btn {
    padding: 5px;
    cursor: pointer;
  }

  .dx-btn:hover {
    color: ${primaryColor};
  }
`;
export const SideWidgetDivider = styled.div`
  border-top: 1px solid #eff0f1;
  clear: both;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const SideSlipItem = styled.div``;
export const HorizontalProductItem = styled.div`
  display: table;
  height: 100%;
  width: 100%;
  padding: 30px 25px;
  position: relative;

  .dx-cell {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    text-align: left;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      max-height: 200px;
    }

    .dx-content {
      margin-right: 30px;
    }

    .dx-title {
      font-size: 22px;
    }

    .dx-desc {
      font-size: 14px;
      color: #999fae;
    }

    .dx-author {
      display: table;

      .dx-avatar {
        display: table-cell;
        vertical-align: middle;
        width: 33px;
        height: 33px;
      }

      .dx-name {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
        color: var(--primary-text-color);

        p {
          margin: 0;
        }

        p:first-child {
          color: var(--primary-text-color);
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
        }

        p:last-child {
          color: #999fae;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }

    .dx-rating {
      margin-top: 16px;
      width: auto;

      p {
        padding-right: 20px;
      }
    }

    .dx-price2 {
      color: var(--primary-text-color);
      font-size: 16px;
      margin-top: 16px;
      font-weight: bold;
    }
  }

  .dx-cell:first-child {
    width: 60%;
    padding-top: 12px;
  }

  .dx-cell:last-child {
    width: 40%;
  }

  .dx-rank {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
  }

  .dx-rank-pink {
    background: #ff385c;
    color: #ffffff;
  }

  .dx-rank-blue {
    background: #316fea;
    color: #ffffff;
  }
`;
export const CarouselImageInfo = styled.div`
  height: 26px;
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: -26px;

  div {
    display: table-cell;
    height: 26px;
    vertical-align: top;
    width: 50%;
    padding: 0;
    margin: 0;
  }

  div:last-child {
    text-align: right;
  }

  .dx-hours {
    background: #000000ba;
    color: #ffffff;
    font-size: 12px;
    border-radius: 8px 0px 8px 0px;
    padding: 3px 8px;
    display: inline-block;
    margin-top: 2px;
  }

  .dx-brand {
    background: #ffffff;
    color: #ffffff;
    font-size: 12px;
    border-top-right-radius: 8px;
    height: 26px;
    overflow: hidden;
    padding: 6px 6px;
    display: inline-block;

    img {
      height: 100%;
      width: auto !important;
      display: inline-block;
    }
  }
`;
