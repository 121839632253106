import { styled } from "../../../../../../Utils/stitches.config";

export const StyledContainer = styled("div", {});
export const StyledVideoContainer = styled("div", {
  position: "relative",
});
export const StyledVideoOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  padding: 24,
  ".ant-card-body": {
    height: "calc(100% - 60px)",
    overflow: "auto",
  },
});
export const StyledQuizContainer = styled("span", {
  display: "inline-block",
  textAlign: "left",
  height: "100%",
});
export const StyledSliderContainer = styled("div", {
  marginTop: 12,
});
export const StyledSlider = styled("div", {
  height: 30,
  position: "relative",
  background: "#EFF0F1",
  cursor: "pointer",
});
export const StyledSliderFill = styled("div", {
  position: "absolute",
  background: "#EA8024",
  top: 0,
  left: 0,
  height: "100%",
  pointerEvents: "none",
  transition: "all 0.5s",
});
export const StyledSliderDivider = styled("div", {
  pointerEvents: "none",
  position: "absolute",
  top: 0,
  height: "100%",
  borderLeft: "1px solid #fff",
  "span:first-child": {
    display: "inline-block",
    borderRadius: "50%",
    background: "#fff",
    width: 16,
    height: 16,
    fontSize: 12,
    lineHeight: "16px",
    color: "#EA8024",
    textAlign: "center",
    marginTop: 7,
    marginLeft: -9,
    fontWeight: "bold",
    border: "1px solid #fff",
  },
  variants: {
    mirai: {
      yes: {
        borderLeft: "1px solid #EA8024",
      },
    },
  },
});
export const StyledCursor = styled("div", {
  position: "absolute",
  top: 0,
  height: "100%",
  left: 0,
  borderLeft: "1px solid #000",
  pointerEvents: "none",
});
