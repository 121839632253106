import { useEffect, useState } from "react";
import { useSetState, useUnmount } from "react-use";
import _ from "lodash";

export function useDiscount(product: any) {
  const [hasDiscounts, setHasDiscounts]: any = useState(false);
  const [timerId, setTimerId]: any = useState(-1);
  const [priceInfo, setPriceInfo]: any = useSetState({
    price: product?.price || 0,
    oldPrice: product?.price || 0,
    percent: 0,
    cash: 0,
  });
  const [label, setLabel]: [string | null, any] = useState(null);

  useUnmount(() => {
    clearInterval(timerId);
  });
  useEffect(() => {
    const discounts = product.discounts;

    if (!discounts) return;

    clearInterval(timerId);

    const newTimerId = setInterval(() => {
      let tempPrice = product.price;
      let percent = 0;
      let cash = 0;

      _.forEach(discounts, (discount: any) => {
        const from = new Date(discount.from).getTime();
        const to = new Date(discount.to).getTime();

        if (from <= Date.now() && Date.now() <= to) {
          if (discount.resultType == 0) {
            tempPrice = tempPrice - (tempPrice * discount.resultValue) / 100;
            percent += parseInt(discount.resultValue);
          } else {
            tempPrice = tempPrice - discount.resultValue;
            cash += parseInt(discount.resultValue);
          }
        }
      });

      if (tempPrice !== priceInfo.price) {
        setPriceInfo({
          price: tempPrice,
          percent,
          cash,
        });
        setHasDiscounts(percent > 0 || cash > 0);
      }
    }, 1000);

    setTimerId(newTimerId);
  }, [product, priceInfo]);
  useEffect(() => {
    if (!hasDiscounts) return;

    const discounts = product.discounts;

    if (!discounts) return;

    let discountNames: string[] = [];

    _.forEach(discounts, (discount) => {
      _.forEach(discount.membershipNames, (name) => {
        if (!discountNames.includes(name)) discountNames.push(name);
      });
    });

    if (discountNames.length > 0) {
      setLabel(discountNames.join(", "));
    }
  }, [hasDiscounts]);

  return [hasDiscounts, priceInfo, label];
}
