import { notification } from "antd";
import Axios from "axios";
import Swal from "sweetalert2";
import { getCommonStorage } from "../../Config/gql";
import Common, { sweetAlertLoading } from "../../Utils/Common";
import { exec } from "../../Utils/dn";
import { getCartAction } from "../Actions/Cart";

export const getCart = async () => {
  let data = [];
  const res = await Axios.post(
    "https://es.izzi.asia/shoppingcarts/shoppingcart/_search?&size=100",
    {
      _source: {},
      query: {
        query: {
          bool: {
            must: [
              {
                match_phrase: { inventoryId: getCommonStorage().merchantId },
              },
              {
                match_phrase: { customerId: getCommonStorage().userId },
              },
            ],
          },
        },
      },
      sort: [],
    },
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Basic ${window.btoa("amara:dSPKMcdQkG5X97b")}`,
      },
    }
  );
  if (localStorage.getItem("shoppingCartId")) {
    if (
      !res.data.hits.hits.find((hit) => hit._source.id === localStorage.getItem("shoppingCartId"))
    ) {
      localStorage.removeItem("shoppingCartId");
    } else {
      data =
        res.data.hits.hits.find((hit) => hit._source.id === localStorage.getItem("shoppingCartId"))
          ._source.shoppingCartItems || [];
    }
  } else {
    data = [];
  }

  return data;
};
export const handleCheckout = (id) => {
  const OrderId = Common.guid();
  exec("Sale", "CheckoutShoppingCart", {
    Id: id,
    OrderId,
    ModifiedDate: Common.getCurrentDateTime(),
    ModifiedBy: getCommonStorage().userId,
  }).then((data) => {
    if (data.data.Success) {
      window.location.href =
        "https://" +
        window.Merchant.data.code +
        ".izzipayment.com?id=" +
        OrderId +
        "&urlReturn=https://" +
        encodeURI(window.location.href).replace("https://", "") +
        "&tokenId=" +
        localStorage.getItem(window.config.localStorageTokenId);
    }
  });
};
export const addItemToCart = ({ productId, dispatch, t }) => {
  const { merchantId, userId } = getCommonStorage();

  if (!localStorage.getItem("shoppingCartId")) {
    const Id = Common.guid();
    localStorage.setItem("shoppingCartId", Id);
    notification.success({
      message: t("Add item to cart success"),
      placement: "topRight",
      top: 50,
      duration: 3,
      rtl: true,
    });
    exec("ShoppingCart", "CreateShoppingCart", {
      Id,
      MerchantId: merchantId,
      TargetId: merchantId,
      InventoryId: merchantId,
      ShoppingCartItems: [
        {
          Id: Common.guid(),
          TargetId: productId,
          Quantity: 1,
        },
      ],
      CustomerId: userId,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: userId,
    }).then(() => getCart().then((data) => dispatch(getCartAction(data))));
  } else {
    notification.success({
      message: t("Add item to cart success"),
      placement: "topRight",
      top: 50,
      duration: 3,
      rtl: true,
    });
    exec("ShoppingCart", "AddItemToShoppingCart", {
      Id: localStorage.getItem("shoppingCartId"),
      ShoppingCartItem: {
        Id: Common.guid(),
        TargetId: productId,
        quantity: 1,
      },
      ModifiedDate: Common.getCurrentDateTime(),
      ModifiedBy: userId,
    }).then(() => getCart().then((data) => dispatch(getCartAction(data))));
  }
};
export const addItemToWishList = (id, history) => {
  exec(
    "User",
    "AddProductWishListUser",
    {
      Id: getCommonStorage().userId,
      ProductId: id,
    },
    ["actionBy"]
  ).then(() => history.push("/wishlist"));
};
