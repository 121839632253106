import PropTypes from "prop-types";
import { useQuery } from "urql";
import { queryVideoQuizs } from "./gql";
import { getCommonStorage } from "../../../../../../Config/gql";
import YouTube from "react-youtube";
import {
  StyledContainer,
  StyledCursor,
  StyledSlider,
  StyledSliderContainer,
  StyledSliderDivider,
  StyledSliderFill,
  StyledVideoContainer,
} from "./styledComponents";
import React, { useCallback, useEffect, useState } from "react";
import { useMeasure, useMount, useSetState } from "react-use";
import { message, Modal } from "antd";
import _ from "lodash";
import { CountDown, CountDownState, duration2Text, RenderQuestions } from "../QuizDetail";
import { StyledLayout, StyledStartButton } from "../QuizDetail/styledComponents";
import Configuration from "../../../../../../Config/Config";
import Common from "../../../../../../Utils/Common";
import { CourseListState } from "../../../../../Router/Layout/maxcoach/CourseHeader/CourseList";
import { unlockCourseLessonUserAction } from "../../../../../Actions/Course";
import Swal from "sweetalert2";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import { parse } from "path";

export default function VideoQuizs(props) {
  const [fetchQuiz] = useQuery({
    query: queryVideoQuizs,
    variables: (() => {
      const { merchantId, languageId,tokenId } = getCommonStorage();

      return {
        merchantId,
        languageId,
        id: props.id,
        tokenUser: tokenId
      };
    })(),
    pause: !props.id,
  });
  const [containerRef, containerInfo] = useMeasure();
  const [showQuestion, setShowQuestion] = useState(false);
  const [player, setPlayer] = useState(null);
  const [answers, setAnswers] = useState({});
  const [state, setState] = useSetState({});

  const _updateScore = (score) => {
    const currentQuiz = fetchQuiz.data?.quiz;

    currentQuiz.options = JSON.parse(currentQuiz.option);

    if (CourseListState.userCourse?.type === Configuration.courseType.conditionQuiz) {
      let userCourse = _.cloneDeep(CourseListState.userCourse);
      let lesson = CourseListState.selected.lesson;
      // let item = CourseListState.selected.item;
      let foundIndex = userCourse.courseLessons.findIndex((x) => x.id === lesson.id);

      if (foundIndex < userCourse.courseLessons.length - 1) {
        if (userCourse.courseLessons[foundIndex + 1].locked === false) {
          if ((score / parseFloat(currentQuiz.totalScore)) * 100.0 > +lesson.conditionValue) {
            // if (score >= +lesson.conditionValue) {
            unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
            userCourse.courseLessons[foundIndex + 1].locked = true;
            CourseListState.userCourse = userCourse;
            Modal.success({
              content: "Bạn đã mở khóa bài học tiếp theo thành công.",
            });
          } else {
            Modal.warning({
              content: "Chưa đủ điểm để mở khóa bài học kế tiếp.",
            });
          }
        }
      }
    }
  };
  const _start = () => {
    const currentQuiz = fetchQuiz.data?.quiz;

    currentQuiz.options = JSON.parse(currentQuiz.option);

    Modal.confirm({
      title: `Thời gian làm bài ${duration2Text(currentQuiz?.duration)}. Xác nhận bắt đầu làm bài?`,
      okText: "Bắt đầu",
      cancelText: "Chưa sẵn sàng",
      onOk() {
        let uuid = Common.guid();
        setState({ uuid, loading: true });
        message.loading({
          content: "Đang bắt đầu...",
          key: "start",
        });
        setAnswers({});
        player.seekTo(0);
        player.playVideo();

        Common.sendSyncCommand(
          CourseListState.currentIsLastQuiz ? "StartCertificateQuiz" : "StartQuiz",
          "CustomerRelationshipManagement",
          {
            Id: uuid,
            QuizId: props.id,
            UserId: localStorage.getItem(Configuration.userId),
            CommandInformation: navigator.userAgent,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: localStorage.getItem(Configuration.userId),
            CourseId: CourseListState.userCourse?.id,
          },
          () => {
            CountDownState.seconds = currentQuiz?.duration;
            CountDownState.pause = false;
            setState({ started: true, answers: {}, done: false, loading: false });
            message.success({ key: "start", content: "Bắt đầu tính thời gian!" });
          },
          () => {
            Swal.fire("Có lỗi xảy ra!").then((result) => {
              if (result.value) {
                window.location.reload();
              }
            });
          }
        );
      },
      onCancel() {
        player.stopVideo();
      },
    });
  };
  const _done = (force) => {
    if (state.done) return;

    const currentQuiz = fetchQuiz.data?.quiz;

    currentQuiz.options = JSON.parse(currentQuiz.option);

    async function done() {
      CountDownState.pause = true;
      setState({ done: true, loading: true });
      message.loading({
        key: "done",
        content: "Đang nộp bài...",
      });
      player.stopVideo();

      let finalAnswers = [];

      _.forEach(currentQuiz.questions, (question) => {
        let item = {
          id: question.id,
          answers: [],
          files:[]
        };
        let userAnswer = answers[question.id];

        if (["single", "multiple"].includes(question.type)) {
          _.forEach(question.answers, (answer) => {
            if (
              answer.id === userAnswer ||
              (Array.isArray(userAnswer) && userAnswer.includes(answer.id))
            ) {
              item.answers.push({
                id: answer.id,
                name: "",
              });
            }
          });
        } else if (question.type === "input") {
          _.forEach(userAnswer, (answer, id) => {
            item.answers.push({
              id,
              name: answer.text,
            });
            item.files = answer.files;
          });
        } else if (question.type === "fill") {
          _.forEach(userAnswer, (userAnswer, id) => {
            if (Array.isArray(userAnswer)) {
              // data: _fill lorem__section_ [undefined, "fill text", "fill text", undefined, "fill text"]
              if (userAnswer.filter((v) => v !== undefined).length > 0) {
                let splitedName = question.name.split("_");
                let stringBuilder = [];

                splitedName.forEach((v, i) => {
                  if (userAnswer[i] !== undefined) {
                    stringBuilder.push(`_{{${userAnswer[i]}}}_`);
                  }
                  stringBuilder.push(v);
                });

                item.answers.push({
                  id,
                  name: stringBuilder.join(""),
                });
              }
            }
          });
        } else if (question.type === "matching") {
          // data: {[questionId]: {[prefix]: suffix}}
          _.forEach(userAnswer, (suffix, prefix) => {
            if (!suffix) suffix = "";

            item.answers.push({
              id: question.id,
              name: [prefix, suffix].join("_"),
            });
          });
          _.forEach(question.answers, (answer) => {
            let [prefix] = answer.name.split("_");
            if (userAnswer && !userAnswer[prefix]) {
              item.answers.push({
                id: question.id,
                name: [prefix, ""].join("_"),
              });
            }
          });
        }

        finalAnswers.push(item);
      });

      const data = {
        Id: state.uuid,
        UserId: localStorage.getItem(Configuration.userId),
        UserDeviceToken: "",
        AnswerQuestions: finalAnswers,
        CommandInformation: navigator.userAgent,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: localStorage.getItem(Configuration.userId),
      };

      if (CourseListState.currentIsLastQuiz) {
        data.CourseId = CourseListState.userCourse.id;
      }

      Common.sendSyncCommand(
        CourseListState.currentIsLastQuiz ? "FinishCertificateQuiz" : "FinishQuiz",
        "CustomerRelationshipManagement",
        data,
        (data) => {
          message.success({ key: "done", content: "Nộp bài thành công." });

          if (currentQuiz.options?.ShowScore) {
            _updateScore(+data.Message);
            Modal.success({
              title: "Bạn đã nộp bài thành công",
              content: `Điểm của bạn là: ${+data.Message}`,
              okText: "Xác nhận",
            });
          } else {
            Modal.success({
              title: "Bạn đã nộp bài thành công",
              content: `Điểm của bạn sẽ được giảng viên thông báo sau.`,
              okText: "Xác nhận",
            });
          }

          if (CourseListState.currentIsLastQuiz && currentQuiz.options?.ShowScore) {
            CourseListState.reloadCourse = true;
          }

          setState({ started: false, review: true, loading: false });
        },
        () => {
          setState({ done: false, started: false, loading: false });
          message.error({ key: "done", content: "Có lỗi xảy ra." });
        }
      );
    }

    if (force) {
      done().catch(console.error);
    } else {
      Modal.confirm({
        content: "Bạn có muốn nộp bài ngay bây giờ không?",
        okText: "Xác nhận",
        cancelText: "Quay lại làm bài",
        onOk() {
          return new Promise((rel, rej) => {
            done().then(rel).catch(rej);
          }).catch((e) => console.error(e));
        },
        onCancel() {},
      });
    }
  };
  const _startEndQuiz = () => {
    const currentQuiz = fetchQuiz.data?.quiz;

    currentQuiz.options = JSON.parse(currentQuiz.option);

    if (!currentQuiz || state.loading) return;
    if (state.started) {
      _done();
    } else {
      _start();
    }
  };

  useMount(() => {
    document.body.style.overflowY = "hidden";
  });
  useEffect(() => {
    if (player) {
      // event.target.playVideo();
      console.log(player.getDuration());
    }
  }, [player]);

  const quiz = fetchQuiz.data?.quiz;
  const url = quiz?.subDescription;
  const youtubeId = url ? url.split("v=")[1].split("&")[0] : undefined;
  const renderWidth = containerInfo.width;
  const renderHeight = containerInfo.width / (16 / 9);

  return (
    <>
      <StyledContainer ref={containerRef} className={"right-section"}>
        {!player && "Loading..."}
        {youtubeId && (
          <StyledVideoContainer hidden={!player}>
            <YouTube
              videoId={youtubeId}
              opts={{
                width: renderWidth,
                height: renderHeight,
                playerVars: {
                  controls: 0,
                  fs: 0,
                  disablekb: 1,
                  modestbranding: 0,
                  rel: 0,
                  origin: window.location.origin,
                },
              }}
              onReady={(event) => {
                setPlayer(event.target);
              }}
              onError={() => {
                alert("Something went wrong!");
              }}
              onPause={() => {}}
              onStateChange={(state) => {
                if (state.data !== 2) {
                  setShowQuestion(null);
                }
              }}
              onPlay={() => {
                if (!state.started && !state.loading) {
                  player.stopVideo();

                  _start();
                }
              }}
            />
            {player && (
              <Bar
                player={player}
                points={quiz.questions.map((v) => v.time)}
                onQuiz={(index) => {
                  const question = quiz.questions[index];

                  setShowQuestion(question);
                }}
              />
            )}
            {showQuestion && player && (
              <div className="flex absolute top-0 left-0 items-center w-full h-full">
                <div className="w-full text-center">
                  <button
                    className="p-2 px-4 rounded border-0 text-white"
                    style={{
                      background: "var(--primary-color)",
                    }}
                    onClick={() => {
                      player.playVideo();
                    }}>
                    Tiếp tục Video
                  </button>
                </div>
              </div>
            )}
          </StyledVideoContainer>
        )}
      </StyledContainer>
      {quiz && (
        <RenderQuestions
          questions={[showQuestion].filter((v) => !!v)}
          answers={answers}
          onAnswers={(newAnswers) =>
            setAnswers({
              ...answers,
              ...newAnswers,
            })
          }
          length={quiz.questions.length}
          started={state.started}
          done={state.done}
        />
      )}
      {quiz && player && (
        <div
          className={"right-section"}
          style={{
            background: "unset",
            boxShadow: "unset",
            padding: 0,
            position: "sticky",
            bottom: 0,
            zIndex: 99,
            textAlign: "center",
          }}>
          <StyledLayout align="center">
            <StyledStartButton onClick={_startEndQuiz}>
              <span hidden={state.loading}>
                {!(state.done || state.started) && "Bắt đầu"}
                {state.done && "Làm lại"}
                {!(state.done || !state.started) && (
                  <React.Fragment>
                    <span>
                      {CountDownState.seconds > 0 ? "Nộp bài" : "Đã hết thời gian làm bài"}
                    </span>
                    <CountDown
                      seconds={quiz.duration}
                      onDone={() => state.started && _done(true)}
                    />
                  </React.Fragment>
                )}
              </span>
              <span hidden={!state.loading}>
                <ThreeDotsLoader width={60} height={22} fill={"#FFFFFF"} />
              </span>
            </StyledStartButton>
          </StyledLayout>
        </div>
      )}
    </>
  );
}

VideoQuizs.propTypes = {
  id: PropTypes.string.isRequired,
};

function Bar({ player, points, onQuiz }) {
  const [value, setValue] = useState(0);
  const [showCursor, setShowCursor] = useState(false);
  const [cursorInfo, setCursorInfo] = useSetState({
    x: 0,
  });
  const [delayTime, setDelayTime] = useState(0);

  const _seekTo = useCallback(
    _.debounce((v) => {
      player.seekTo(v);
    }, 275),
    [player]
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (player.getPlayerState() !== 1) return;

      if (Date.now() >= delayTime) {
        const currentTime = player.getCurrentTime();
        let show = false;

        for (let i = points.length - 1; i >= 0; i--) {
          if (currentTime >= points[i] && points[i] > value) {
            onQuiz && onQuiz(i);
            player.pauseVideo();
            show = true;
            break;
          }
        }

        setValue(currentTime);
      }
    }, 33);

    return () => {
      clearInterval(timer);
    };
  }, [delayTime, value]);

  const maxValue = player.getDuration();
  const currentPercent = (value * 100) / maxValue;

  return (
    <StyledSliderContainer>
      <StyledSlider
        onMouseEnter={() => {
          setShowCursor(true);
        }}
        onMouseLeave={() => {
          setShowCursor(false);
        }}
        onMouseMove={(e) => {
          const { x } = getRelativeMouse(e);

          setCursorInfo({ x });
        }}
        onClick={(e) => {
          const {
            percent: { x },
          } = getRelativeMouse(e);
          const value = Math.round((player.getDuration() * x) / 100);

          setValue(value);
          _seekTo(value);
          setDelayTime(Date.now() + 1000);
          player.playVideo();
        }}>
        <StyledSliderFill
          style={{
            width: currentPercent + "%",
          }}
        />
        {points.map((point, i) => {
          return (
            <QuizPoint key={i} value={point} fillValue={value} maxValue={maxValue}>
              {i + 1}
            </QuizPoint>
          );
        })}
        <StyledCursor
          hidden={!showCursor}
          style={{
            left: cursorInfo.x,
          }}
        />
      </StyledSlider>
    </StyledSliderContainer>
  );
}

function QuizPoint({ children, value, maxValue, fillValue }) {
  return (
    <StyledSliderDivider
      style={{
        left: (value * 100) / maxValue + "%",
      }}
      mirai={value > fillValue ? "yes" : undefined}>
      <span>{children}</span>
    </StyledSliderDivider>
  );
}

function getRelativeMouse(e) {
  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  return {
    x,
    y,
    percent: {
      x: (x * 100) / rect.width,
    },
  };
}
