import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Common from "../../../../Utils/Common";
import { BasePage } from "../../ComponentCommon/BasePage";
import { addItemToCart } from "../../../Services/CartService";
import { deleteItemWishListAction, getWishListAction } from "../../../Actions/Cart";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import { getCommonStorage } from "../../../../Config/gql";
import { exec } from "../../../../Utils/dn";

const Wishlist = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [refetch, setRefetch] = useState(false);
  const wishList: any = useSelector((state: any) => state.Cart.wishList);
  useEffect(() => {
    QueryGraphQL.getWishList()
      .then((data) => dispatch(getWishListAction(data.wishlistproducts.items)))
      .finally(() => setRefetch(false));
  }, [refetch]);
  const removeItemFromWishList = (id: string) => {
    exec(
      "User",
      "RemoveProductWishListUser",
      {
        Id: getCommonStorage().userId,
        ProductId: id,
      },
      ["actionBy"]
    ).then(() => setRefetch(true));
  };
  return (
    <BasePage title="WishList">
      {wishList.length > 0 ? (
        <div className="wishlist-section section section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cart-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="pro-thumbnail">{t("Image")}</th>
                        <th className="pro-title">{t("Product")}</th>
                        <th className="pro-price">{t("Price")}</th>

                        <th className="pro-addtocart">Add to cart</th>
                        <th className="pro-remove">{t("Remove")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wishList.length > 0 &&
                        wishList.map((item: any) => {
                          return (
                            <tr key={item.id}>
                              <td className="pro-thumbnail">
                                <a href="#">
                                  <img src={Common.getImageThumbnail(item.images)} alt="Product" />
                                </a>
                              </td>
                              <td className="pro-title">
                                <a href="#">{item.name}</a>
                              </td>
                              <td className="pro-price">
                                <span>{Common.formatMoney(item.price)}</span>
                              </td>

                              <td className="pro-addtocart">
                                <button
                                  onClick={() => addItemToCart({ productId: item.id, dispatch, t })}
                                  className={`btn btn-primary btn-hover-secondary`}>
                                  Add to cart
                                </button>
                              </td>
                              <td className="pro-remove">
                                <button
                                  className="border-none"
                                  onClick={() =>
                                    Swal.fire({
                                      allowOutsideClick: false,
                                      text: t("Are you sure you want to delete this product ?"),
                                      icon: "warning",
                                      showCancelButton: true,
                                      cancelButtonText: "Cancel",
                                    }).then((data) => {
                                      if (data.isDismissed) {
                                        Swal.close();
                                      } else {
                                        removeItemFromWishList(item.id);
                                      }
                                    })
                                  }>
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section section-padding-bottom">
          <div className="container">
            <div className="cart-empty-content">
              <span className="icon">
                <i className="fal fa-shopping-cart"></i>
              </span>
              <h3 className="title">Your wishlist is currently empty.</h3>
              <p>You may check out all the available products and add some in the shop.</p>
              <Link to="/product-basic" className="btn btn-primary btn-hover-secondary">
                Return to shop
              </Link>
            </div>
          </div>
        </div>
      )}
    </BasePage>
  );
};

export default Wishlist;
