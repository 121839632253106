import Common from "../Utils/Common";
import Config from "./Config";

export function getCommonStorage() {
  return {
    merchantId: localStorage.getItem(Config.merchantId),
    languageId: /* localStorage.getItem(Config.languageId) */Common.getCurrentLanguageId(),
    tokenId: localStorage.getItem(window.config.localStorageTokenId),
    userId: localStorage.getItem(Config.userId),
    tokenUser: localStorage.getItem(window.config.localStorageTokenId),
    createdBy: localStorage.getItem(Config.createdBy)
  };
}
