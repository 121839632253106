/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAction, updateInformationUserAction } from "../../../Actions/User";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useSignUpForm from "../../ComponentCommon/CustomHook/CustomHooks";
import validate from "../../ComponentCommon/CustomHook/Validate";
import Configuration from "../../../../Config/Config";
import $ from "jquery";
import Swal from "sweetalert2";

function Index() {
  const onSubmitInfor = () => {
    updateInformationUserAction(inputs);
  };
  const dispatch = useDispatch();
  var user = useSelector((state) => state.User.user);
  const { inputs, errors, handleInputChange, handleSubmit } = useSignUpForm(
    user,
    onSubmitInfor,
    validate
  );
  const [avatar, setAvatar] = useState(Configuration.imageAvatarDefault);
  useEffect(() => {
    dispatch(getUserAction(localStorage.getItem(Configuration.userId)));
  }, []);

  useEffect(() => {
    if (inputs.dateOfBirth != undefined) {
      setDateOfBirth(new Date(inputs.dateOfBirth));
    }
    console.log(user);
    setAvatar(Configuration.imageUrl + user.avatar);
  }, [user]);

  useEffect(() => {
    if (inputs.dateOfBirth != undefined) {
      setDateOfBirth(new Date(inputs.dateOfBirth));
    }
  }, [inputs]);

  const [dateOfBirth, setDateOfBirth] = useState(new Date());

  useEffect(() => {
    inputs.dateOfBirth = dateOfBirth;
  }, [dateOfBirth]);

  const dateOfBirthOnChange = (value) => {
    setDateOfBirth(value);
  };
  const saveAvatar = (event) => {
    event.preventDefault();

    if (file == null) {
      Swal.fire("Cần chọn ảnh để upload.");
      return;
    }

    const formData = new FormData();
    formData.append("token", localStorage.getItem(window.config.localStorageTokenId));
    formData.append("file", file);
    var ajaxRequest = $.ajax({
      type: "POST",
      url: window.config.urlApi + "/Image/UploadAvatar",
      contentType: false,
      processData: false,
      data: formData,
    });

    ajaxRequest.done((response, textStatus) => {
      //Data: response.Data [{Id:"234234", Path:"/kjahs/asdasd"}]
      console.log(response);
      if (response.Success) {
        Swal.fire("Cập nhật thành công");
      } else {
        Swal.fire("Cập nhật thất bại");
      }
    });
  };
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);
  const handleChange = (event) => {
    setPreview(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
    // this.setState({
    //     file: event.target.files[0],
    //     preview:
    // })
  };
  return (
    <div className="section section-padding-bottom section-padding-top">
      <div className="container">
        <div className="page-content-inner">
          <h2>Cài đặt</h2>

          <div uk-grid="true">
            <div className="uk-width-2-5@m uk-flex-last@m">
              <div className="uk-card-default rounded text-center p-4">
                <div className="uk-position-relative my-4">
                  <div
                    className="user-profile-photo  m-auto"
                    onClick={() => {
                      window.$('input[name="file"]').click();
                    }}>
                    {preview == null ? <img src={avatar} alt /> : <img src={preview} alt />}
                  </div>

                  <div className="uk-position-center">
                    <div uk-form-custom="true">
                      <input name="file" type="file" onChange={handleChange} />
                      <span className="uk-link icon-feather-camera icon-medium text-white"> </span>
                    </div>
                  </div>
                </div>
                <a
                  className="btn btn-default grey m-auto mb-3"
                  onClick={(event) => saveAvatar(event)}>
                  Lưu
                </a>
              </div>
            </div>

            <div className="uk-width-expand@m">
              <div className="uk-card-default rounded">
                <div className="p-3">
                  <h5 className="mb-0"> Thông tin cá nhân </h5>
                </div>
                <hr className="m-0" />
                <form
                  onSubmit={handleSubmit}
                  className="uk-child-width-1-2@s uk-grid-small p-4"
                  uk-grid="true">
                  <div>
                    <h5 className="uk-text-bold mb-2"> Họ </h5>
                    <input
                      type="text"
                      name="firstName"
                      className="uk-input"
                      defaultValue={inputs.firstName}
                      onChange={handleInputChange}
                      placeholder="Họ"
                    />
                    {errors.firstName && <label className="error">{errors.firstName}</label>}
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Tên </h5>
                    <input
                      type="text"
                      name="lastName"
                      className="uk-input"
                      defaultValue={inputs.lastName}
                      onChange={handleInputChange}
                      placeholder="Tên"
                    />
                    {errors.lastName && <label className="error">{errors.lastName}</label>}
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2">Email</h5>
                    <input
                      type="text"
                      name="email"
                      className="uk-input"
                      defaultValue={inputs.email}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Số điện thoại </h5>
                    <input
                      type="text"
                      name="mobile"
                      className="uk-input"
                      defaultValue={inputs.mobile}
                      onChange={handleInputChange}
                      placeholder="Số điện thoại"
                    />
                    {errors.mobile && <label className="error">{errors.mobile}</label>}
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Tên công ty </h5>
                    <input
                      type="text"
                      name="companyName"
                      defaultValue={inputs.companyName}
                      onChange={handleInputChange}
                      className="uk-input"
                    />
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Website công ty </h5>
                    <input
                      type="text"
                      name="companySite"
                      className="uk-input"
                      defaultValue={inputs.companySite}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Ngày sinh </h5>
                    <DatePicker
                      className="uk-input"
                      selected={dateOfBirth}
                      onChange={dateOfBirthOnChange}
                    />
                  </div>
                  <div className="uk-flex uk-flex-right p-4">
                    <button className="btn btn-default grey">Lưu</button>
                  </div>
                </form>
              </div>

              <div className="uk-card-default rounded mt-4">
                <div className="p-3">
                  <h5 className="mb-0"> Thông tin tài khoản </h5>
                </div>
                <hr className="m-0" />
                <form
                  id="formUpdateAccount"
                  className="uk-child-width-1-2@s uk-grid-small p-4"
                  uk-grid="true">
                  <div>
                    <h5 className="uk-text-bold mb-2">Tài khoản </h5>
                    <input
                      type="text"
                      name="firstName"
                      className="uk-input"
                      disabled
                      defaultValue={inputs.userName}
                    />
                  </div>

                  <div>
                    <h5 className="uk-text-bold mb-2">Email</h5>
                    <input
                      type="text"
                      name="email"
                      className="uk-input"
                      defaultValue={inputs.email}
                      disabled
                    />
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Ngôn Ngữ </h5>
                    {/* <select name="language" className="uk-input">
                                    {

                                        allLanguage.map((item, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <option value={item.id} selected={item.id == inputs.languageId}>{item.name}</option>
                                                </Fragment>
                                            )
                                        }
                                        )
                                    }
                                </select> */}
                  </div>

                  <div>
                    <h5 className="uk-text-bold mb-2"> Khu Vực </h5>
                    <select className="uk-input" name="timeZone">
                      {Configuration.timeZone.map((item, i) => (
                        <option
                          key={i}
                          selected={item.offset == inputs.timeZone}
                          value={item.offset}>
                          {item.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <h5 className="uk-text-bold mb-2"> Trao đổi </h5>
                    <label className="mr-2 mb-0 uk-inline">
                      <input
                        value={1}
                        name="communication_email"
                        style={{ display: "inline-block" }}
                        className="uk-checkbox"
                        type="checkbox"
                        defaultChecked={inputs.communication == 1}
                      />
                      <span className="checkmark uk-text-small"> Email </span>
                    </label>
                    <label className="mr-2 mb-0 uk-inline">
                      <input
                        value={2}
                        name="communication_sms"
                        style={{ display: "inline-block" }}
                        className="uk-checkbox"
                        type="checkbox"
                        defaultChecked={inputs.communication === 2}
                      />
                      <span className="checkmark uk-text-small"> SMS </span>
                    </label>
                    <label className="mr-2 mb-0 uk-inline">
                      <input
                        value={4}
                        name="communication_phone"
                        style={{ display: "inline-block" }}
                        className="uk-checkbox"
                        type="checkbox"
                        defaultChecked={inputs.communication === 4}
                      />
                      <span className="checkmark uk-text-small"> Phone </span>
                    </label>
                  </div>
                </form>
                <div className="uk-flex uk-flex-right p-4">
                  <button className="btn btn-default grey">Lưu</button>
                </div>
              </div>
              {/* <div className="uk-card-default rounded mt-4">
                            <div className="p-3">
                                <h5 className="mb-0">Đổi mật khẩu</h5>
                            </div>
                            <hr className="m-0" />
                            <ChangePassword></ChangePassword>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
