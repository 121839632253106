import { Badge, Dropdown } from "antd";
import { BellOutlined } from "@ant-design/icons";
import { StyledNotifications } from "../styled";
import React from "react";
import { StyledNotificationList, StyledNotificationListSeen } from "./styled";
import moment from "moment";
import Common from "../../../../../../Utils/Common";
import Configuration from "../../../../../../Config/Config";
import { useHistory } from "react-router-dom";

type INotification = {
  id: string;
  allStatus: number;
  createdDate: string;
  message: string;
  urlReturn?: string;
};

export const Notifications = ({
  fetchNotifications,
  refetchNotifications,
}: {
  fetchNotifications: any;
  refetchNotifications: () => any;
}) => {
  const history = useHistory();

  const _readNotification = (item: INotification) => {
    const objData = {
      Id: item.id,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: localStorage.getItem(Configuration.userId),
    };
    Common.sendAsyncCommand(
      "UserReadNotification",
      "NotificationTransaction",
      objData,
      () => {
        if (item.urlReturn) {
          if (item.urlReturn.startsWith("http")) {
            window.location.href = item.urlReturn;
          } else if (item.urlReturn.startsWith("/")) {
            history.push(item.urlReturn);
            refetchNotifications();
          } else {
            refetchNotifications();
          }
        } else {
          refetchNotifications();
        }
      },
      () => {
        alert("Something went wrong!");
      }
    );
  };

  const items: Array<INotification> =
    fetchNotifications?.data?.notificationtransactions?.items || [];
  const numberOfNewNotifications = items.filter((v: INotification) => v.allStatus === 1).length;

  return (
    <Dropdown
      overlay={
        <StyledNotificationList>
          {items.concat(items).map((v, i) => {
            return (
              <StyledNotificationList.Item key={i} onClick={() => _readNotification(v)}>
                {v.message}
                <StyledNotificationList.Item.Date seen={v.allStatus === 1 ? "no" : "yes"}>
                  {moment(v.createdDate).fromNow()}
                </StyledNotificationList.Item.Date>
                {v.allStatus === 1 && <StyledNotificationListSeen />}
              </StyledNotificationList.Item>
            );
          })}
        </StyledNotificationList>
      }
      trigger={["click"]}>
      <StyledNotifications>
        <Badge dot={numberOfNewNotifications > 0}>
          <BellOutlined />
        </Badge>
      </StyledNotifications>
    </Dropdown>
  );
};
