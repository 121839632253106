import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
const tokenId = localStorage.getItem(window.config.localStorageTokenId);
export const getDataQuizVideo = {
    getDetailQuiz(idQuiz) {
        return Common.getDataGraphQl(
            `
        {
            quiz(param: {id: "${idQuiz}",
            type:2,
             merchantId: "${Common.getCookie(Configuration.merchantId)}",
             tokenUser:"${tokenId}"
            }
             ) {
                id
                name
                duration
                subDescription
                description
                categories {
                  id
                }
                images {
                  id
                }
                option
                questions {
                  id
                  name
                  time
                  point
                  subDescription
                  description
                  type
                  displayOrder
                  answers {
                    id
                    name
                    isCorrect
                    displayOrder
                  }
                }
            }
          }`
        );
    },
};
