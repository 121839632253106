/* eslint-disable */
import Configuration from "../Config/Config";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import { getCommonStorage } from "../Config/gql";
import _ from "lodash";

export const languageMapping = {
  vn: "838aef56-78bb-11e6-b5a6-00155d582814",
  en: "e3509252-c42d-4ae5-9779-d4805a687a8e",
};

export const sweetAlertLoading = (loadingContent, callback) => {
  Swal.fire({
    title: "Please Wait !",
    html: loadingContent, // add html attribute if you want or remove
    icon: "info",
    allowOutsideClick: false,
    onBeforeOpen: () => {
      Swal.showLoading();
    },
  }).then(callback);
};
export async function checkEmailExist(email,isFocussingEmailField = false,defaultEmail = "") {
  if(isFocussingEmailField) return
  if(email === defaultEmail) return
  const body = {
    Field: "email",
    Value: email,
    MerchantId: getCommonStorage().merchantId,
  };

  return axios.post(window.config.urlApi + "/User/CheckUserExist", body);
}

export const postData = {
  sendCommand(domain, commandName, body) {
    const dataSend = {
      CommandName: commandName,
      Domain: domain,
      Content: JSON.stringify(body),
      TimeOutSecond: 20,
    };

    const api_url = Configuration.urlApi + "/Command/SendSync";
    return axios.post(api_url, dataSend);
  },
};
export const Common = {
  getCurrentLanguageId() {
    const languageId =
      languageMapping[localStorage.getItem("i18nextLng")] ||
      "838aef56-78bb-11e6-b5a6-00155d582814";
    return languageId;
  },
  formatOrderStatus(allStatus) {
    let status = "";
    if ((1 & allStatus) === 1) {
      status = "Đã đặt hàng";
    }
    if ((32 & allStatus) === 32) {
      status = "Đang xử lý";
    }
    if ((256 & allStatus) === 256) {
      status = "Qúa hạn xử lý";
    }
    if ((32768 & allStatus) === 32768) {
      status = "Đã huỷ";
    }
    if ((1048576 & allStatus) === 1048576) {
      status = "Đã hoàn thành";
    }
    return status;
  },
  formatName: function (str, kyTu) {
    if (!str) str = "";

    if (str.length < kyTu) {
      return str;
    }
    str = str || "";
    var temp = str.substring(0, kyTu);
    temp = temp.substring(0, temp.lastIndexOf(" "));
    return temp + "...";
  },
  formatMoneyIntl: function (money) {
    return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(money);
  },
  formatMoney: function (param, c, d, t) {
    var n = param,
      c = isNaN((c = Math.abs(c))) ? 2 : c,
      d = d == undefined ? "," : d,
      t = t == undefined ? "." : t,
      s = n < 0 ? "-" : "",
      i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "") +
      "đ"
    );
  },
  getCurrentDateTime: function () {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var hour = d.getHours();
    var min = d.getMinutes();
    var sec = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-") + " " + hour + ":" + min + ":" + sec;
  },
  startTimer(duration, $selector, callBackFinish) {
    var timer = duration,
      minutes,
      seconds;
    return setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      $selector.text(minutes + ":" + seconds);
      if (--timer < 0) {
        // clearInterval(time);
        // callBackFinish();
        // return;
      }
    }, 1000);
  },
  validForm($form, arrayName, command) {
    /**
     * su dung jquery
     *  */
    $().ready(function () {
      var form = $form;
      let objRules = {};
      let objMessage = {};
      $.each(arrayName, function (i, val) {
        // if (val.includes("-")) {
        //     val = val.split("-")[1]
        // }

        switch (val) {
          case "name":
            objRules[val] = {
              required: true,
              maxlength: 30,
              digits: false,
            };
            objMessage[val] = {
              required: "Không được để trống!",
              maxlength: "Nhập tên quá dài!",
              digits: "Tên không được chứa ký tự số!",
            };
            break;
          case "mobile":
            objRules[val] = {
              maxlength: 10,
              required: true,
              number: true,
            };
            objMessage[val] = {
              required: "Không được để trống!",
              minlength: "Nhập ít nhất 10 ký tự số điện thoại!",
              number: "Chỉ nhập số!",
            };
            break;
          case "email":
            objRules[val] = {
              required: true,
              email: true,
            };
            objMessage[val] = {
              required: "Không được để trống!",
              email: "Nhập sai định dạng Email",
            };
            break;
          case "currentPassword":
            objRules[val] = {
              required: true,
            };
            objMessage[val] = {
              required: "Không được để trống!",
            };
            break;
          case "newPassword":
            objRules[val] = {
              required: true,
              minlength: 5,
              maxlength: 30,
            };
            objMessage[val] = {
              required: "Không được để trống!",
              maxlength: "Nhập tên quá dài!",
              minlength: "Nhập mật khẩu trên 5 ký tự",
            };
            break;
          case "rePassword":
            objRules[val] = {
              equalTo: "#newPassword",
              minlength: 5,
              required: true,
            };
            objMessage[val] = {
              equalTo: "Chưa khớp mật khẩu",
              minlength: "Nhập mật khẩu trên 5 ký tự",
              required: "Không được để trống!",
            };
            break;

          default:
            if (val.includes("-")) {
              val = val.split("-")[0];
              objRules[val] = {
                required: true,
                maxlength: 100,
              };
              objMessage[val] = {
                required: "Không được để trống!",
                maxlength: "Nhập quá nhiều kí tự!",
              };
            }

            break;
        }
      });
      console.log(objRules);
      console.log(objMessage);
      form.validate({
        rules: objRules,
        messages: objMessage,
      });

      if (form.valid()) {
        command();
      }
    });
  },
  removeComment(str) {
    return str.replace(/<\!--.*?-->/g, "");
  },
  getImageThumbnail(images, resizeMode) {
    resizeMode = resizeMode || "";
    let path = "";
    //let path = Configuration.imageDefault + resizeMode;

    if (images && images.length > 0) {
      $.each(images, function (i, val) {        
        if (val.isFeatured === true && path == "") {
          if (val.path && val.path.startsWith("http")) {
            path = val.path;
          } else {
            path = Configuration.imageUrl + val.path + resizeMode;
          }
        }
      });
    }

    if(path == ""){
      path = Configuration.imageDefault + resizeMode;
    }

    return path;
  },
  formatDateTime(date, format) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    var hour = d.getHours();
    var min = d.getMinutes();
    var sec = d.getSeconds();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (format == undefined) {
      hour = hour < 10 ? "0" + hour.toString() : hour;
      min = min < 10 ? "0" + min.toString() : min;
      sec = sec < 10 ? "0" + sec.toString() : sec;
      return [year, month, day].join("-") + " " + hour + ":" + min + ":" + sec;
    } else {
      switch (format) {
        case "yyyy-mm-dd":
          return [year, month, day].join("-");

        case "dd-mm-yyyy":
          return [day, month, year].join("-");

        case "mm-dd-yyyy":
          return [month, day, year].join("-");

        case "MM":
          return month;
        case "MMM":
          return monthNames[d.getMonth()];
        case "DD":
          return day;
        case "YYYY":
          return year;
        case "mm":
          return min < 10 ? "0" + min.toString() : min;
        case "ss":
          return sec < 10 ? "0" + sec.toString() : sec;
        case "hh":
          return hour < 10 ? "0" + hour.toString() : hour;
        case "hh-mm-ss":
          return hour + ":" + min + ":" + sec;
        case "dd/mm/yyyy hh:mm":
          hour = hour < 10 ? "0" + hour.toString() : hour;
          min = min < 10 ? "0" + min.toString() : min;
          return [day, month, year].join("/") + " " + hour + ":" + min;
          case "dd/mm/yyyy":
            hour = hour < 10 ? "0" + hour.toString() : hour;
            min = min < 10 ? "0" + min.toString() : min;
            return [day, month, year].join("/");
        default:
          hour = hour < 10 ? "0" + hour.toString() : hour;
          min = min < 10 ? "0" + min.toString() : min;
          return [year, month, day].join("-") + " " + hour + ":" + min;
      }
    }
  },
  checkIfLoggedIn: async function () {
    let tokenId = Common.getCookie(Configuration.userCookie);
    const url_api = Configuration.url_api + "/User/GetUserDataByToken?tokenId=" + tokenId;
    const isLoggedIn = await axios.get(url_api).then((res) => {
      // var dataMerchant = JSON.parse(res.data.Message).MerchantCode;
      // Common.setCookie("merchantCode", dataMerchant);
      // console.log(res)
      return res.data.Success;
    });

    if (!isLoggedIn) {
      window.location.replace("/");
    } else return;
  },
  GetUserDataByToken: function (tokenId, cb) {
    const url_api = Configuration.url_api + `/User/GetUserDataByToken?tokenId=${tokenId}`;
    axios.get(url_api).then((res) => {
      cb(res.data);
    });
  },
  getDataGraphQl(query) {
    return new Promise((rel, rej) => {
      if (window.NProgress) window.NProgress.start();
      axios
        .post(Configuration.urlGraphQl, { query: query })
        .then((res) => {
          if (window.NProgress) window.NProgress.done();
          rel(res.data.data);
        })
        .catch((e) => {
          if (window.NProgress) window.NProgress.done();
          rej(e);
        });
    });
  },
  change_alias(alias) {
    if (alias == null) {
      return "";
    }
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(
      /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
      " "
    );
    str = str.replace(/ + /g, " ");
    str = str.trim();
    return str;
  },
  rewriteUrl(string) {
    return Common.change_alias(string).replace(/\s/g, "-");
  },
  sendCommand(domain, commandName, body) {
    const dataSend = {
      CommandName: commandName,
      Domain: domain,
      Content: JSON.stringify(body),
      TimeOutSecond: 7,
    };

    const api_url = Configuration.url_api + "/Command/SendSync";
    return axios.post(api_url, dataSend);
  },
  getCookie: function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  setCookie: function (cname, cvalue, exdays) {
    exdays = exdays === undefined ? 1000000 : exdays;
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  deleteCookie: function (cname) {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  },
  isGuid(stringToTest) {
    if (stringToTest[0] === "{") {
      stringToTest = stringToTest.substring(1, stringToTest.length - 1);
    }
    var regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(stringToTest);
  },
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  },
  findVariable(myRegex, myString) {
    var match = myRegex.exec(myString);
    var variables = [];
    while (match != null) {
      variables.push(match[0]);
      match = myRegex.exec(myString);
    }
    return variables;
  },
  queryData(index, type, option, handleData) {
    if (index != "") {
      index += "/";
    }

    if (type != "") {
      type += "/";
    }

    var clearDefaultQuery = false;
    var returnData = [];
    var from = 0;
    var size = 10;
    var queries = null;
    var totalQuery = 0;
    var queriesMustNot = null;
    var sorts = null;
    var async = false;
    var languageId = this.getCookie(Configuration.tokenLanguage);
    var includes = ["id", "name", "description", "subDescription", "images"];

    if (option != undefined) {
      if (option.from != undefined) {
        from = option.from;
      }

      if (option.size != undefined) {
        size = option.size;
      }
      if (option.includes != undefined) {
        includes = option.includes;
      }

      if (option.queriesMustNot != undefined) {
        queriesMustNot = option.queriesMustNot;
      }

      if (option.queries != undefined) {
        queries = option.queries;
      }
      if (option.sorts != undefined) {
        sorts = option.sorts;
      }

      if (option.async != undefined) {
        async = option.async;
      }

      if (option.clearDefaultQuery != undefined) {
        clearDefaultQuery = option.clearDefaultQuery;
      }
      if (option.languageId != undefined) {
        languageId = option.languageId;
      }
    }
    var searchObject = {
      _source: {},
      query: {
        bool: {
          must: [
            {
              query_string: {
                default_field: "merchantId",
                query: this.getCookie("merchantId"),
              },
            },
            {
              match: {
                languageId: languageId,
              },
            },
          ],
          must_not: [],
        },
      },
      sort: [],
    };
    searchObject._source.includes = includes;

    if (clearDefaultQuery) {
      searchObject.query.bool.must = [];
    }

    if (queries != null) {
      $.each(queries, function (i, val) {
        searchObject.query.bool.must.push(val);
      });
    }

    if (queriesMustNot != null) {
      $.each(queriesMustNot, function (i, val) {
        searchObject.query.bool.must_not.push(val);
      });
    }
    if (sorts != null) {
      $.each(sorts, function (i, val) {
        searchObject.sort.push(val);
      });
    }

    var xhr = new XMLHttpRequest();
    //Starts the variable xhr as the variable for the request
    xhr.open(
      "POST",
      "https://es.izzi.asia/" + index + type + "_search?from=" + from + "&size=" + size + "",
      async
    );
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Authorization", "Basic " + btoa("amara:dSPKMcdQkG5X97b"));
    //Runs method 'open' which defines the request

    //Sends the request
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var temp = JSON.parse(xhr.responseText);
          totalQuery = temp.hits.total;
          $.each(temp.hits.hits, function (i, val) {
            returnData.push(val._source);
          });

          if (handleData != undefined) {
            handleData(returnData);
          }
        } else {
          console.error(xhr.statusText);
        }
      }
    };
    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };
    xhr.send(JSON.stringify(searchObject));
    return {
      data: returnData,
      total: totalQuery,
    };
  },

  sendSyncCommand(stringCommandName, stringDomain, objData, functionSuccess, functionFail) {
    let dataSend = {
      CommandName: stringCommandName,
      Domain: stringDomain,
      Content: JSON.stringify(objData),
      ModifiedBy: Common.getCookie("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
      TimeOutSecond: 10,
    };
    if (window.themes.current === window.themes.default) window.showLoading();
    $.ajax({
      url: Configuration.urlApi + "/Command/SendSync",
      type: "post",
      contentType: "application/json",
      processData: false,
      dataType: "json",
      data: JSON.stringify(dataSend),
      success: function (res) {
        if (res.Success && functionSuccess !== undefined) {
          functionSuccess(res);
        } else {
          if (functionFail !== undefined) {
            functionFail(res);
          }
        }
        if (window.themes.current === window.themes.default) window.hideLoading();
      },
      error: function (err) {
        if (functionFail !== undefined) {
          functionFail(err);
        }
        if (window.themes.current === window.themes.default) window.hideLoading();
      },
    });
  },
  sendSyncCommandNoLoading(
    stringCommandName,
    stringDomain,
    objData,
    functionSuccess,
    functionFail
  ) {
    let dataSend = {
      CommandName: stringCommandName,
      Domain: stringDomain,
      Content: JSON.stringify(objData),
      ModifiedBy: Common.getCookie("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
      TimeOutSecond: 10,
    };
    //window.showLoading();
    $.ajax({
      url: Configuration.urlApi + "/Command/SendSync",
      type: "post",
      contentType: "application/json",
      processData: false,
      dataType: "json",
      data: JSON.stringify(dataSend),
      success: function (res) {
        if (res.Success && functionSuccess !== undefined) {
          functionSuccess(res);
        } else {
          if (functionFail !== undefined) {
            functionFail(res);
          }
        }
        // window.hideLoading();
      },
      error: function (err) {
        if (functionFail !== undefined) {
          functionFail(err);
        }
        // window.hideLoading();
      },
    });
  },

  sendAsyncCommand(stringCommandName, stringDomain, objData, functionSuccess, functionFail) {
    let dataSend = {
      CommandName: stringCommandName,
      Domain: stringDomain,
      Content: JSON.stringify(objData),
      ModifiedBy: Common.getCookie("userId"),
      ModifiedDate: Common.formatDateTime(new Date()),
      TimeOutSecond: 10,
    };
    //window.showLoading();
    $.ajax({
      url: Configuration.urlApi + "/Command/Send",
      type: "post",
      contentType: "application/json",
      processData: false,
      dataType: "json",
      data: JSON.stringify(dataSend),
      success: function (res) {
        if (res.Success && functionSuccess !== undefined) {
          functionSuccess(res);
        } else {
          if (functionFail !== undefined) {
            functionFail(res);
          }
        }
        //window.hideLoading();
      },
      error: function (err) {
        if (functionFail !== undefined) {
          functionFail(err);
        }
        //window.hideLoading();
      },
    });
  },
  queryDataMerchant: function (code) {
    var returnData = [];
    var searchObject = {
      _source: {},
      query: {
        match: {
          code: {
            query: code,
          },
        },
      },
      sort: [],
    };

    var xhr = new XMLHttpRequest();
    //Starts the variable xhr as the variable for the request
    xhr.open("POST", "https://es.izzi.asia/merchants/merchant/_search?&size=1", false);
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Authorization", "Basic " + btoa("amara:dSPKMcdQkG5X97b"));
    //Runs method 'open' which defines the request

    //Sends the request
    xhr.onload = function (e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var temp = JSON.parse(xhr.responseText);
          $.each(temp.hits.hits, function (i, val) {
            returnData.push(val._source);
          });

          // if (handleData != undefined) {
          //     handleData(returnData);
          // }
        } else {
          console.error(xhr.statusText);
        }
      }
    };
    xhr.onerror = function (e) {
      console.error(xhr.statusText);
    };
    xhr.send(JSON.stringify(searchObject));
    console.log(returnData[0]);
    return returnData[0];
  },
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  },
  isEmptyObj(obj) {
    return Object.keys(obj).length === 0;
  },
};

export default Common;
