import React, { useState } from "react";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { createOrderAction } from "../../../Actions/Order";
import { useDiscount } from "../Product/maxcoach/Products/useDiscount";

export const ModalQuickView = ({ product }) => {
  const [quantity, setQuantity] = useState(1);
  const [hasDiscounts, priceInfo] = useDiscount(product);
  const [submitting, setSubmitting] = useState(false);

  const _createOrder = (productId, quantity) => {
    const items = [
      {
        Id: Common.guid(),
        TargetId: productId,
        quantity: quantity,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };

  return (
    <div
      className="modal fade quick-view-modal-container"
      id="quick-view-modal-container"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-xl-12 col-lg-12">
              <div className="row max-mb-n30">
                {/* Product Images Start */}
                <div className="col-lg-6 col-12 max-mb-30">
                  <div className="single-product-image">
                    <img
                      src={Common.getImageThumbnail(
                        product.images,
                        "?mode=crop&width=570&height=570"
                      )}
                      alt="book"
                    />
                    {hasDiscounts && (
                      <div className="product-badges">
                        <span className="onsale">
                          {priceInfo.percent
                            ? `-${priceInfo.percent}%`
                            : `-${Common.formatMoney(priceInfo.cash, 0)}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {/* Product Images End */}
                {/* Product Summery Start */}
                <div className="col-lg-6 col-12 overflow-hidden max-mb-30">
                  <div className="single-product-content">
                    <h3 className="title">{product.name}</h3>
                    <span className="prices">
                      <span className="price-new">
                        {hasDiscounts
                          ? Common.formatMoney(priceInfo.price, 0)
                          : Common.formatMoney(product.price, 0)}
                      </span>
                      {hasDiscounts ? (
                        <span className="price-old">{Common.formatMoney(product.price, 0)}</span>
                      ) : null}
                    </span>
                    <div className="product-description">
                      <p>{product.subDescription}</p>
                    </div>
                    <div className="quantity-button-wrapper">
                      <div className="quantity">
                        <label>Số lượng</label>
                        <input
                          type="number"
                          value={quantity}
                          onChange={(e) => setQuantity(parseInt(e.target.value))}
                          defaultValue={1}
                          min={1}
                        />
                      </div>
                      {/* <p className="stock in-stock">99 in stock</p> */}
                    </div>
                    <div className="product-action">
                      <button
                        className={`btn btn-primary btn-hover-secondary ${
                          submitting ? "disabled" : ""
                        }`}
                        onClick={() => {
                          setSubmitting(true);
                          _createOrder(product.id, quantity);
                        }}>
                        Mua ngay
                      </button>
                      {/* <button className="btn btn-wishlist btn-box">
                            <i className="fal fa-heart" />
                          </button>
                          <button className="btn btn-compare btn-box">
                            <i className="fal fa-random" />
                          </button> */}
                    </div>
                    <div className="product-meta">
                      <div className="meta-item">
                        <h6>Sku:</h6>
                        <div className="meta-content">
                          <span className="sku">{product.sku}</span>
                        </div>
                      </div>
                      <div className="meta-item">
                        <h6>Category:</h6>
                        <div className="meta-content">
                          {(product.categories || []).map((category) => (
                            <a rel="tag">{category.name}</a>
                          ))}
                          {(product.categories || []).length === 0 ? "N/A" : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Product Summery End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
