import React, { useState } from "react";
import { getDocHeight, getDocWidth } from "../../../../Utils/utils";
import { v4 } from "uuid";

export function SafeHtmlBox(props: {
  style?: object;
  srcDoc: string;
  title?: string;
  autoWidth?: boolean;
  autoHeight?: boolean;
}) {
  const [id] = useState(v4());

  return (
    <iframe
      id={id}
      title={props.title || "Title"}
      sandbox={
        "allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups allow-pointer-lock"
      }
      srcDoc={props.srcDoc}
      style={{
        width: "100%",
        ...(props.style || {}),
      }}
      onLoad={() => {
        const ifrm: any = document.getElementById(id);
        const doc = ifrm.contentDocument ? ifrm.contentDocument : ifrm.contentWindow.document;

        if (props.autoHeight) {
          ifrm.style.height = getDocHeight(doc) + "px";
        }

        if (props.autoWidth) {
          ifrm.style.width = getDocWidth(doc) + "px";
        }
      }}
    />
  );
}
