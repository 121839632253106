/* eslint-disable */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { QueryGraphQL } from "../../../Queries/GraphQl";

const DetailAnswerQuiz = forwardRef((props, ref) => {
    const [modal, setModal] = useState(false);
    const [idQuiz, setIdQuiz] = useState(null);
    const [dataQuiz, setDataQuiz] = useState({ questions: [] });
    // const [modal, setModal] = useState(false);
    useImperativeHandle(ref, () => ({
        openModal(cell) {
            console.log(cell);
            setIdQuiz(cell);
            // console.log(id);
            toggle();
        },
    }));
    const {
        // id,setIdQuiz
        className,
    } = props;

    useEffect(() => {
        if (idQuiz != null) {
            QueryGraphQL.getDetailQuiz(idQuiz).then((data) => {
                if (data.quiz != null) {
                    setDataQuiz(data.quiz);
                }
            });
        }
    }, [idQuiz]);
    const toggle = () => setModal(!modal);
    console.log(dataQuiz);
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{dataQuiz.name}</ModalHeader>
                <ModalBody>
                    {dataQuiz.questions.map((item, i) => {
                        const dataAnswer = item.answers.filter((e) => e.isCorrect);
                        let textAnswer = "";
                        if (dataAnswer.length > 0) {
                            textAnswer = `Câu trả lời: ${dataAnswer[0].name || ""}`;
                            if (item.type === Math.pow(2, 4)) {
                                // sua lai config type answer ve object
                                let tempAns = dataAnswer[0].name.replace(/_{{/g, '"');
                                tempAns = tempAns.replace(/}}_/g, '" ');
                                textAnswer = `Câu trả lời: ${tempAns}`;
                            } else if (item.type === Math.pow(2, 5)) {
                                let text = "";
                                dataAnswer.map((item, i) => {
                                    text += item.name.split("_").join("-") + (i != dataAnswer.length - 1 ? "," : "");
                                });
                                textAnswer = `Câu trả lời: ${text}`;
                            } else if (item.type === Math.pow(2, 2)) {
                                let text = "";
                                dataAnswer.map((item, i) => {
                                    text += item.name + (i != dataAnswer.length - 1 ? "," : "");
                                });
                                textAnswer = `Câu trả lời: ${text}`;
                            }
                        }

                        return (
                            <div className="mb-4">
                                <p className="mb-2 font-weight-bold">
                                    Câu {i + 1}: {item.name} ({item.point} điểm)
                                </p>
                                <span className="text-success">{textAnswer}</span>
                            </div>
                        );
                    })}
                </ModalBody>
            </Modal>
        </div>
    );
});

export default DetailAnswerQuiz;
