import React, { Fragment, useState } from "react";
import { useMount, useSetState } from "react-use";
import { QueryGraphQL } from "../../../../../Queries/GraphQl";
import "../../../Course/maxcoach/UserCourseDetails/styles.less";
import { Divider } from "antd";
import { Observer } from "../../../../../../Utils/Ob";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import $ from "jquery";
import { Carousel } from "react-responsive-carousel";
import MCC from "../../../../ComponentCommon/MiddleContent";
import Configuration from "../../../../../../Config/Config";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import PdfSlider from "../../../../ComponentCommon/PdfSlider/PdfSlider";

function MaxcoachSlideDetail(props) {
  const [state, setState] = useSetState({
    article: {
      name: "",
      subDescription: "",
      description: "",
      createdDate: "",
      files: [],
      images: [],
    },
  });

  useMount(() => {
    window.showPageLoading();
    QueryGraphQL.getArticleById(props.match.params.id)
      .then((data) => {
        setState({
          article: data.article,
        });
      })
      .finally(() => window.hidePageLoading());
  });

  return (
    <div id={"dongsec"} style={{ padding: 8, height: "calc(100vh - 85px)", position: "relative" }}>
      <div className={"right-section hover video"}>
        <Fragment>
          <LightgalleryProvider
            onAfterSlide={() => {
              $(".side-nav").hide();
            }}
            onCloseAfter={() => {
              $(".side-nav").show();
            }}>
            <h3 className="course-item-title question-title">{state.article.name}</h3>
            <InfoDivider
              info={
                state.article.files.length > 0
                  ? "Slide"
                  : state.article.images.length > 0 && state.article.images?.length + " ảnh"
              }
            />
            {state.article.images.length > 0 && (
              <Carousel
                showArrows={true}
                renderItem={(children) => {
                  return (
                    <MCC>
                      <span className={"slide-image"}>{children}</span>
                    </MCC>
                  );
                }}
                renderThumbs={() => {
                  return state.article.images.map((p) => {
                    let src = Configuration.imageUrl + p.path;
                    return (
                      <span className={"slide-thumb"}>
                        <MCC
                          style={{
                            width: "100%",
                            height: "100%",
                          }}>
                          <img
                            src={src}
                            alt={"slide"}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </MCC>
                      </span>
                    );
                  });
                }}>
                {state.article.images.map((p, i) => {
                  let src = Configuration.imageUrl + p.path;
                  return (
                    <LightgalleryItem src={src} key={i}>
                      <img
                        src={src}
                        alt={"slide"}
                        style={{
                          height: "auto",
                          width: "auto",
                        }}
                      />
                    </LightgalleryItem>
                  );
                })}
              </Carousel>
            )}
            {state.article.files.length > 0 && (
              <PdfSlider path={Configuration.imageUrl + state.article.files[0].path} />
            )}
          </LightgalleryProvider>
        </Fragment>
      </div>
    </div>
  );
}

export default Observer(MaxcoachSlideDetail);

function InfoDivider(props) {
  return (
    <div style={{ position: "relative" }}>
      <Divider />
      <div className={"divider-text"}>{props.info}</div>
    </div>
  );
}
