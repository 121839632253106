/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { STATE_VIDEO } from './ConstStateVideo';

function getParam(param, url) {
    var url_string = url; //window.location.href
    console.log(url);

    return new URL(url_string).searchParams.get(param);
}
export default function Player(props) {
    let { getPlayer, onStatePlayerChange, paramUrl } = props;
    const dispatch = useDispatch();
    const QuizVideo = useSelector((store) => store.QuizVideo);
    console.log(QuizVideo);
    useEffect(() => {
        function onPlayerReady(event, player) {
            dispatch.QuizVideo.updatePlayer(player);
            getPlayer(player);
            event.target.playVideo();
        }
        function loadVideo() {
            // the Player object is created uniquely based on the id in props
            var player = new window.YT.Player(`player`, {
                videoId: `${getParam("v", paramUrl)}`,
                playerVars: { autoplay: 0, controls: 0, autohide: 1, wmode: "opaque", showinfo: 0, rel: 0, playlist: 0, widget_referrer: 0 },
                events: {
                    onReady: (event) => {
                        onPlayerReady(event, player);
                    },
                    onStateChange: () => onStatePlayerChange && onStatePlayerChange(),
                },
            });
        }
        if (!window.YT) {
            // If not, load the script asynchronously
            const tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            // onYouTubeIframeAPIReady will load the video after the script is loaded
            window.onYouTubeIframeAPIReady = loadVideo;
            const firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
            // If script is already there, load the video directly
            loadVideo();
        }
    }, []);
    return <div id="player"></div>;
}
