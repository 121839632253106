import { COURSE } from "../../Config/Constants/CourseConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";

const initialState = {
    courses: [],
    usercourses: [],
    usercourse: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST(COURSE.GET_COURSE):
            return {
                ...state,
            };
        case FAILURE(COURSE.GET_COURSE):
            return {
                ...state,
            };
        case SUCCESS(COURSE.GET_COURSE):
            return {
                ...state,
                courses: action.payload.courses,
            };

        case REQUEST(COURSE.GET_USER_COURSES):
            return {
                ...state,
            };
        case FAILURE(COURSE.GET_USER_COURSES):
            return {
                ...state,
            };
        case SUCCESS(COURSE.GET_USER_COURSES):
            console.log(action.payload.usercourses)
            return {
                ...state,
                usercourses: action.payload.usercourses,
            };

        case REQUEST(COURSE.GET_USER_COURSE_REVIEW):
            return {
                ...state,
            };
        case FAILURE(COURSE.GET_USER_COURSE_REVIEW):
            return {
                ...state,
            };
        case SUCCESS(COURSE.GET_USER_COURSE_REVIEW):
            return {
                ...state,
                usercourse: action.payload.usercoursepreview,
            };

        case REQUEST(COURSE.GET_USER_COURSE):
            return {
                ...state,
            };
        case FAILURE(COURSE.GET_USER_COURSE):
            return {
                ...state,
            };
        case SUCCESS(COURSE.GET_USER_COURSE):
            return {
                ...state,
                usercourse: action.payload.usercourse,
            };
        case REQUEST(COURSE.GET_SEEN_LESSON_ITEM):
            return {
                ...state,
            };
        case FAILURE(COURSE.GET_SEEN_LESSON_ITEM):
            return {
                ...state,
            };
        case SUCCESS(COURSE.GET_SEEN_LESSON_ITEM):
            return {
                ...state,
                usercourse: action.payload.usercourse,
            };
        case REQUEST(COURSE.UNLOCK_LESSON_USER):
            return {
                ...state,
            };
        case FAILURE(COURSE.UNLOCK_LESSON_USER):
            return {
                ...state,
            };
        case SUCCESS(COURSE.UNLOCK_LESSON_USER):
            return {
                ...state,
            };
        default:
            return state;
    }
}
