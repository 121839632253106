import React, { useEffect } from "react";
import * as ecs from "../../../../Course/maxcoach/UserCourse/ecs";
import Breadcrumb from "../../../../../ComponentCommon/maxcoach/Breadcrumb";
import { Tabs } from "antd";
import { Container, Empty, NotificationItem } from "./styled";
import { useSetState } from "react-use";
import { useQuery } from "urql";
import { QUERY_NOTIFICATIONS } from "./gql";
import { getCommonStorage } from "../../../../../../../Config/gql";
import moment from "moment";
import { RenderPagination } from "../../../../Product/maxcoach/Products";
import Common from "../../../../../../../Utils/Common";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

function NotificationsListPage(props) {
  const {t} = useTranslation()
  const [state, setState] = useSetState({
    limit: 20,
    currentPage: 0,
  });
  const [fetchNotifications, refetchNotifications] = useQuery({
    query: QUERY_NOTIFICATIONS,
    variables: (() => {
      const { tokenId: tokenUser, merchantId } = getCommonStorage();

      return {
        tokenUser,
        merchantId,
        offset: state.currentPage * state.limit,
        limit: state.limit,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const history = useHistory();
  const user = useSelector((state) => state.User.user);

  const _readNotification = (item) => {
   
    const objData = {
      Id: item.id,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: user.id,
    };
    Common.sendAsyncCommand(
      "UserReadNotification",
      "NotificationTransaction",
      objData,
      () => {
        if (item.urlReturn) {
          if (item.urlReturn.startsWith("http")) {
            window.location.href = item.urlReturn;
          } else if (item.urlReturn.startsWith("/")) {
            history.push(item.urlReturn);
          }
        }
        refetchNotifications();
      },
      () => {
        alert("Something went wrong!");
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.currentPage]);

  const pageTitle = t("Notifications");
  const notifications = fetchNotifications.data?.notificationtransactions?.items || [];
  const totalCount = fetchNotifications.data?.notificationtransactions?.totalCount || 0;
  const loading = fetchNotifications.fetching;
  const offset = state.currentPage * state.limit;

  return (
    <React.Fragment>
      <div className="section page-title-section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{pageTitle}</h1>
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className={"section section-padding-bottom"}>
        <div className="container">
          <Container>
            <span className={"dx-total"}>
              {offset + 1} - {Math.min(offset + 20, totalCount)} {t("of")} {totalCount} {t("messages")}
            </span>
            <Tabs defaultActiveKey="inbox">
              <TabPane tab={t("Inbox")} key="inbox">
                {!loading && notifications.length === 0 && (
                  <Empty>{t("There are no messages in your Inbox")}.</Empty>
                )}
                {!loading && (
                  <>
                    {notifications.map((notification, i) => {
                      return (
                        <NotificationItem
                          key={i}
                          status={notification.allStatus === 1 ? "unseen" : "seen"}
                          onClick={() => _readNotification(notification)}>
                          <div className={"dx-message"}>
                            {notification.allStatus === 1 && <span className={"dx-unseen"} />}
                            <span>{notification.message}</span>
                          </div>
                          <div className={"dx-description"}>
                            {moment(notification.createdDate).format("YYYY-MM-DD HH:mm")}
                          </div>
                        </NotificationItem>
                      );
                    })}
                    <div className="dx-pagination">
                      <RenderPagination
                        currentPage={state.currentPage}
                        totalItem={totalCount}
                        itemPerPage={state.limit}
                        handlePageChange={(pageIndex) => setState({ currentPage: pageIndex })}
                      />
                    </div>
                  </>
                )}
              </TabPane>
              <TabPane tab={t("Archive")} key="archive">
                <Empty>{t("There are no messages in your Archive")}.</Empty>
              </TabPane>
            </Tabs>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NotificationsListPage;
