import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Select, Row, Empty, Modal, Divider, Popover } from "antd";
import { BasePage } from "../../../../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined } from "@ant-design/icons";
import DNModal from "../../../../DnStyles/Modal";
import { getYears } from "../../../../Education/data";
import { getExpertises } from "./data";
import _ from "lodash";

import { v4 } from "uuid";

/* import { QueryBoardExperiences } from "./gql"; */
import { exec } from "../../../../../../../Utils/dn";
import { getCommonStorage } from "../../../../../../../Config/gql";

import { useQuery } from "urql";
import { useTranslation } from "react-i18next";
import { QueryQualification, QueryQualifications } from "./gql";
import { ContentPopupLanguage } from "../../../../../ComponentCommon/PopoverContentLanguage";

interface IFormModalProps {
  state: {
    isEdit: boolean;
    isModalVisible: boolean;
    loading: boolean;
  };
  setState: any;
  editLanguageId: string;
  editId: string;
  refetchQualifications: any;
}

const getExpertise = (num: number) => {
  return getExpertises().find((value: any) => value.value === num);
};

const FormModal = ({
  state,
  setState,
  editId,
  refetchQualifications,
  editLanguageId,
}: IFormModalProps) => {
  const { t } = useTranslation();
  const [id] = useState(v4());
  const [form] = Form.useForm();
  const { Option } = Select;

  const [QualificationData] = useQuery({
    query: QueryQualification,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        languageId: editLanguageId,
        merchantId: merchantId,
        id: editId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (qualification: any) => {
    const { userId, languageId } = getCommonStorage();
    setState({
      loading: true,
    });

    exec(
      "Qualification",
      state.isEdit ? "UpdateInformationQualification" : "CreateQualification",
      {
        Id: state.isEdit ? editId : v4(),
        Name: qualification.name,
        Institute: qualification.institute,
        Expertise: qualification.expertise,
        YearCompleted: qualification.yearCompleted.toString() + "-01-01",
        LanguageId: editId ? editLanguageId : languageId,
        UserId: userId,
      },
      ["actionBy", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        refetchQualifications();
        setState({ loading: false });
      });
  };

  useEffect(() => {
    if (QualificationData.data?.qualification) {
      console.log("run 102");
      const qualification = QualificationData.data.qualification;
      form.setFieldsValue({
        institute: qualification.institute,
        name: qualification.name,
        yearCompleted: Number(qualification.yearCompleted.slice(0, 4)),
        expertise: getExpertise(qualification.expertise)?.value,
      });
    }
  }, [QualificationData.data?.qualification]);

  return (
    <DNModal
      title={state.isEdit ? t("Edit") : t("Qualification")}
      visible={state.isModalVisible}
      onCancel={() => {
        setState({ isModalVisible: false });
      }}
      okButtonProps={{
        form: id,
        htmlType: "submit",
      }}
      confirmLoading={state.loading}>
      <Form form={form} onFinish={_onFinish} id={id} layout={"vertical"}>
        <Form.Item name={"name"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Name")} />
        </Form.Item>
        <Form.Item name={"institute"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("institute.qualification")} />
        </Form.Item>

        <Form.Item name={"expertise"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Expertise")}>
            {getExpertises().map((v: any) => (
              <Option key={v.value} value={v.value}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"yearCompleted"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("Year Completed")}>
            {getYears().map((v) => (
              <Option key={v.value} value={v.value}>
                {v.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </DNModal>
  );
};

const Qualification = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editLanguageId, setEditLanguageId] = useState("");

  const [editId, setEditId] = useState("");

  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const [QualificationDatas, refetchQualifications] = useQuery({
    query: QueryQualifications,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onClickEdit = (qualification: any, lang: string) => {
    setEditId(qualification.id);
    setEditLanguageId(lang);

    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec(
          "Qualification",
          "DeleteQualification",
          {
            Id: id,
          },
          ["actionBy"]
        ).finally(refetchQualifications);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };

  const qualifications = QualificationDatas.data?.data?.items || [];

  return (
    <Fragment>
      <BasePage title={t("Professional Qualification")} hideTitle={true}>
        <Card
          title={t("Professional Qualification")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                form.resetFields();

                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={QualificationDatas.fetching}>
          {qualifications.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Qualification")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {qualifications.length > 0 &&
            qualifications.map((qualification: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("Name")}:
                      </Col>
                      <Col span={20}>{qualification.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("institute.qualification")}:
                      </Col>
                      <Col span={20}>{qualification.institute}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Expertise")}
                      </Col>
                      <Col span={20}>{getExpertise(qualification.expertise)?.name}</Col>
                    </Row>

                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Year Completed")}
                      </Col>
                      <Col span={20}>{qualification.yearCompleted.slice(0, 4)}</Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Popover
                        placement="bottom"
                        trigger="hover"
                       
                        content={<ContentPopupLanguage edit={_onClickEdit} data={qualification} />}>
                        <Button type={"link"}>{t("Edit")}</Button>
                      </Popover>
                      <Button type={"link"} danger={true} onClick={() => _remove(qualification.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
        {state.isModalVisible && (
          <FormModal
            editLanguageId={editLanguageId}
            editId={editId}
            setState={setState}
            state={state}
            refetchQualifications={refetchQualifications}
          />
        )}
      </BasePage>
    </Fragment>
  );
};

export default Qualification;
