export const getOrganization  = () => {
    const data = [
      {value:"Default", name: "ACCA" },
      {value:"Default", name: "AICB" },
      {value:"Default", name: "Bar Council" },
      {value:"Default", name: "CIM/IMM" },
      {value:"Default", name: "CIMA" },
      {value:"Default", name: "CPA Australia" },
      {value:"Default", name: "CTIM" },
      {value:"Default", name: "ICAEW" },
      {value:"Default", name: "IEM/IEEE" },
      {value:"Default", name: "Internal Auditors Malaysia (IIAM)" },
      {value:"Default", name: "MAICSA" },
      {value:"Default", name: "Malaysia Franchise Association" },
      {value:"Default", name: "Malaysia Retail Association" },
      {value:"Default", name: "MIA" },
      {value:"Default", name: "MICPA" },
      {value:"Default", name: "MIHRM" },
      {value:"Default", name: "MIM" },
    
    ];
    let num = 1;
    for (let i = 0; i < data.length; ++i) {
      if (i === 0) {
        num = 1;
      } else {
        num *= 2;
      }
      data[i] = { ...data[i], value: num };
    }
    return data;
  };
  