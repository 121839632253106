export const filterArrayWithArrayNum = ( mainArr: any,numArr: number[])=>{
   
    return mainArr.filter((e: any)=>numArr.includes(e.value))
}

export const findValue = (mainArr: any, num: number)=>{
  
    return mainArr.find((e: any)=>num === e.value)
}
export const calculateTotal = (numArr: number[])=>{
 
    const tempArr = numArr.reduce((total ,value)=>{
        total += value
        return total
   
    },0)
    return tempArr

}