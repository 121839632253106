import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import { getCommonStorage } from "../../../../../../Config/gql";
import { QUERY_PREVIEW, QUERY_PRODUCT, QUERY_RATING } from "./gql";
import {
  StyledBackButton,
  StyledBody,
  StyledButton,
  StyledBuy,
  StyledBuyInfoItem,
  StyledCollapseContainer,
  StyledCollapseHeader,
  StyledCollapseItem,
  StyledCommentContainer,
  StyledContainer,
  StyledPreviewContainer,
  StyledPreviewItem,
  StyledReviewBox,
  StyledStarCounter,
  StyledTransactionItem,
} from "./styledComponents";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { message, Rate, Tabs } from "antd";
import { Collapse } from "antd";
import { QUERY_SKIP_PURCHASE_PRODUCTS, QUERY_RELATE_COURSE_PRODUCTS } from "../Products/gql";
import Configuration from "../../../../../../Config/Config";
import { useDispatch, useSelector } from "react-redux";
import { RenderItem, RenderPagination, SideWidget } from "../Products";
import { FetchingItemsEffect } from "../../../Course/maxcoach/UserCourse";
import { useMount, useSetState, useUnmount } from "react-use";
import Common from "../../../../../../Utils/Common";
import { createOrderAction } from "../../../../../Actions/Order";
import ShareDialog from "../../../../ComponentCommon/ShareDialog";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import { hintTextColor, primaryTextColor } from "../../../../../../Utils/themeConfig";
import { createRatingTransactionAction, getRatingAction } from "../../../../../Actions/Rating";
import { StyledLayout } from "../../../Quiz/maxcoach/QuizDetail/styledComponents";
import { Observer } from "../../../../../../Utils/Ob";
import _ from "lodash";
import { useDiscount } from "../Products/useDiscount";
import { useTranslation } from "react-i18next";
import * as PropTypes from "prop-types";

const { TabPane } = Tabs;
const { Panel } = Collapse;

function RenderPrice(props) {
 
  const [hasDiscounts, priceInfo] = useDiscount(props.product);

  return (
    <span>
      Price: {!hasDiscounts && <>{Number(props.product.price).toLocaleString("vi-VN")}₫</>}
      {hasDiscounts && (
        <>
          <span className="inline-block">{Number(priceInfo.price).toLocaleString("vi-VN")}₫</span>
          <span className="inline-block ml-2 text-sm line-through opacity-50">
            {Number(priceInfo.oldPrice).toLocaleString("vi-VN")}₫
          </span>
        </>
      )}
    </span>
  );
}

RenderPrice.propTypes = {
  product: PropTypes.any,
};

function MaxcoachProductDetailPage2(props) {
  const {t} = useTranslation()
  const history = useHistory();
  const [fetchProduct] = useQuery({
    query: QUERY_PRODUCT,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        id: props.match.params.id,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId(),
        tokenId: commonStorage.tokenId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const [fetchPreview] = useQuery({
    query: QUERY_PREVIEW,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        id: fetchProduct.data?.product?.courseId,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId()
      };
    })(),
    pause: !fetchProduct.data?.product?.courseId,
    requestPolicy: "network-only",
  });
  const [fetchProducts] = useQuery({
    query: QUERY_RELATE_COURSE_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        type: Configuration.productType.course,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId(),
        offset: 0,
        limit: 4,
        tokenUser: commonStorage.tokenId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const [fetchRating, refetchRating] = useQuery({
    query: QUERY_RATING,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        id: fetchProduct.data?.product?.ratingId,
        merchantId: commonStorage.merchantId,
        targetId: props.match.params.id,
      };
    })(),
    pause: !fetchProduct.data?.product?.ratingId,
    requestPolicy: "cache-and-network",
  });
  const [showShareDialog, setShowShareDialog] = useState(false);

  const _createOrder = () => {
    window.showPageLoading();
    const product = fetchProduct.data?.product;
    const items = [
      {
        Id: Common.guid(),
        TargetId: product.id,
        quantity: 1,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };
  const _startNow = () => {
    const product = fetchProduct.data?.product;

    if (product.hadBought) {
      history.push("/user-course-details/" + product.courseId);
    } else {
      _createOrder();
    }
  };

  useMount(() => {
    window.scrollTo(0, 0);
  });
  /* useEffect(() => {
    const loading = fetchProduct.fetching || fetchPreview.fetching;
    console.log(loading,172)
    if (loading) {
      window.showPageLoading();
    } else {
      window.hidePageLoading();
    }
  }, [fetchProduct.fetching, fetchPreview.fetching]); */
  useUnmount(() => {
    window.resetPageLoading();
  });

  const product = fetchProduct.data?.product;
  const preview = fetchPreview.data?.usercoursepreview;
  const merchant = useSelector((state) => state.Merchant.merchant);
  const courseLessons = preview?.courseLessons;
  const products = fetchProducts.data?.relatecourseproducts?.items || [];
  const showReview = product && product.ratingId && !product.ratingId.startsWith("0000");
  const transactions = fetchRating.data?.ratingmember?.ratingTransactions || [];
  const rateValue = (() => {
    let value = 0.0;

    for (const transaction of transactions) {
      value += parseFloat(transaction.score);
    }

    value = (value / parseFloat(transactions.length)).toFixed(1);

    return value;
  })();

  return (
    <React.Fragment>
      <ShareDialog
        open={showShareDialog}
        quote={product?.name}
        onClose={() => {
          setShowShareDialog(false);
        }}
      />
      <div className="section" hidden={window.isPageLoading()}>
        <div className="container">
          <StyledContainer>
            <div className="row max-mb-n50">
              <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
                <div>
                  <StyledBackButton onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left" />
                    Back
                  </StyledBackButton>
                </div>
                {product && <h1>{product.name}</h1>}
                <StyledPreviewContainer>
                  <StyledPreviewItem direction="end" hidden={!showReview}>
                    {fetchRating.fetching && (
                      <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />
                    )}
                    {fetchRating.data && (
                      <React.Fragment>
                        {rateValue > 0 && (
                          <>
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M9.99994 14.5996L13.4583 16.6913C14.0916 17.0746 14.8666 16.508 14.6999 15.7913L13.7833 11.858L16.8416 9.20798C17.3999 8.72464 17.0999 7.80798 16.3666 7.74964L12.3416 7.40798L10.7666 3.69131C10.4833 3.01631 9.5166 3.01631 9.23327 3.69131L7.65827 7.39964L3.63327 7.74131C2.89994 7.79964 2.59994 8.71631 3.15827 9.19964L6.2166 11.8496L5.29994 15.783C5.13327 16.4996 5.90827 17.0663 6.5416 16.683L9.99994 14.5996Z"
                                fill="#FEC414"
                              />
                            </svg>
                            {rateValue}
                            {product && <span>({product.quantityRating})</span>}
                          </>
                        )}
                        {!(rateValue > 0) && <span>{t("No review yet")}</span>}
                      </React.Fragment>
                    )}
                  </StyledPreviewItem>
                  {product && (
                    <StyledPreviewItem>
                      {product.quantityStudent} <span>{t("students")}</span>
                    </StyledPreviewItem>
                  )}
                  {product && (
                    <StyledPreviewItem direction="start">
                      <span>{t("Released")}:</span>
                      {moment(product.createdDate).format("MMM D, YYYY")}
                    </StyledPreviewItem>
                  )}
                  <br />
                  <StyledPreviewItem direction="start">
                    <span>{t("Created by")}:</span>
                    {merchant.name}
                  </StyledPreviewItem>
                </StyledPreviewContainer>
                <StyledBody>
                  <Tabs defaultActiveKey="1" onChange={() => {}}>
                    <TabPane tab={t("Course Info")} key="1">
                      {product && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.description,
                          }}
                        />
                      )}
                    </TabPane>
                    <TabPane tab={t("Syllabus")} key="2">
                      <div>
                        <b>{t("Outline")}</b>
                      </div>
                      <StyledCollapseContainer>
                        <Collapse
                          bordered={false}
                          expandIconPosition={"right"}
                          expandIcon={({ isActive }) => {
                            return (
                              <span>
                                <i className={"fas fa-chevron-" + (isActive ? "up" : "down")} />
                              </span>
                            );
                          }}>
                          {(courseLessons || []).map((lesson, i) => {
                            const quizs = (lesson?.quizs || []).map((v) => {
                              v.__typename = "quiz";
                              return v;
                            });
                            const slides = (lesson?.slides || []).map((v) => {
                              v.__typename = "slide";
                              return v;
                            });
                            const videos = (lesson?.videos || []).map((v) => {
                              v.__typename = "video";
                              return v;
                            });
                            const items = quizs.concat(slides).concat(videos);
                            const description = [];

                            if (quizs.length) {
                              description.push(quizs.length + " quizzes");
                            }
                            if (slides.length) {
                              description.push(slides.length + " slides");
                            }
                            if (videos.length) {
                              description.push(videos.length + " videos");
                            }

                            return (
                              <Panel
                                header={
                                  <StyledCollapseHeader>
                                    <div>{lesson.name}</div>
                                    <div>{description.join(" | ")}</div>
                                  </StyledCollapseHeader>
                                }
                                key={i + ""}>
                                <ul style={{ listStyle: "none" }}>
                                  {items.map((item, i) => {
                                    return (
                                      <StyledCollapseItem key={"item" + i}>
                                        {item.__typename === "quiz" && (
                                          <i className="far fa-question-square" />
                                        )}
                                        {item.__typename === "slide" && (
                                          <i className="far fa-presentation" />
                                        )}
                                        {item.__typename === "video" && (
                                          <i className="far fa-play" />
                                        )}
                                        <span>{item.name}</span>
                                      </StyledCollapseItem>
                                    );
                                  })}
                                </ul>
                              </Panel>
                            );
                          })}
                        </Collapse>
                      </StyledCollapseContainer>
                    </TabPane>
                    {showReview && (
                      <TabPane tab="Review" key="3">
                        <ReviewScene
                          {...props}
                          product={product}
                          fetchRating={fetchRating}
                          refetchRating={refetchRating}
                        />
                        {/*No review yet*/}
                      </TabPane>
                    )}
                  </Tabs>
                </StyledBody>
              </div>
              <div className="col-lg-4 col-12 order-lg-1 max-mb-50">
                <StyledBuy>
                  {product && (
                    <>
                      <img
                        src={Common.getImageThumbnail(
                          product.images,
                          "?mode=crop&width=1280&height=720"
                        )}
                        alt={"Thumbnail"}
                      />
                      <button onClick={_startNow}>{t("Start Now")}</button>
                      <StyledBuyInfoItem style={{ marginTop: 16, border: 0 }}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M18.8889 0H1.11111C0.5 0 0 0.5 0 1.11111V18.8889C0 19.5 0.5 20 1.11111 20H18.8889C19.5 20 20 19.5 20 18.8889V1.11111C20 0.5 19.5 0 18.8889 0ZM6.66667 4.44444H4.44444V6.66667H6.66667V4.44444ZM14.4444 6.66667H10C9.38889 6.66667 8.88889 6.16667 8.88889 5.55556C8.88889 4.94444 9.38889 4.44444 10 4.44444H14.4444C15.0556 4.44444 15.5556 4.94444 15.5556 5.55556C15.5556 6.16667 15.0556 6.66667 14.4444 6.66667ZM14.4444 11.1111H10C9.38889 11.1111 8.88889 10.6111 8.88889 10C8.88889 9.38889 9.38889 8.88889 10 8.88889H14.4444C15.0556 8.88889 15.5556 9.38889 15.5556 10C15.5556 10.6111 15.0556 11.1111 14.4444 11.1111ZM10 15.5556H14.4444C15.0556 15.5556 15.5556 15.0556 15.5556 14.4444C15.5556 13.8333 15.0556 13.3333 14.4444 13.3333H10C9.38889 13.3333 8.88889 13.8333 8.88889 14.4444C8.88889 15.0556 9.38889 15.5556 10 15.5556ZM4.44444 8.88889H6.66667V11.1111H4.44444V8.88889ZM6.66667 13.3333H4.44444V15.5556H6.66667V13.3333ZM2.22222 17.7778H17.7778V2.22222H2.22222V17.7778Z"
                            fill="#2B2C57"
                          />
                        </svg>
                        <span>{t("Lessons")}: {(courseLessons || []).length} {t("lessons")}</span>
                      </StyledBuyInfoItem>
                      <StyledBuyInfoItem>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" fill="white" />
                          <path
                            d="M12.18 11.2667C10.818 10.8733 10.38 10.4667 10.38 9.83333C10.38 9.10667 10.986 8.6 12 8.6C12.852 8.6 13.278 8.96 13.434 9.53333C13.506 9.8 13.704 10 13.956 10H14.136C14.532 10 14.814 9.56667 14.676 9.15333C14.424 8.36667 13.836 7.71333 12.9 7.46V7C12.9 6.44667 12.498 6 12 6C11.502 6 11.1 6.44667 11.1 7V7.44C9.936 7.72 9 8.56 9 9.84667C9 11.3867 10.146 12.1533 11.82 12.6C13.32 13 13.62 13.5867 13.62 14.2067C13.62 14.6667 13.326 15.4 12 15.4C11.01 15.4 10.5 15.0067 10.302 14.4467C10.212 14.1867 10.008 14 9.762 14H9.594C9.192 14 8.91 14.4533 9.06 14.8667C9.402 15.7933 10.2 16.34 11.1 16.5533V17C11.1 17.5533 11.502 18 12 18C12.498 18 12.9 17.5533 12.9 17V16.5667C14.07 16.32 15 15.5667 15 14.2C15 12.3067 13.542 11.66 12.18 11.2667Z"
                            fill="#2B2C57"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.57999 20 4 16.42 4 12C4 7.58 7.57999 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                            fill="#2B2C57"
                          />
                        </svg>
                        {product && <RenderPrice key={product.id} product={product} />}
                      </StyledBuyInfoItem>
                      <StyledBuyInfoItem onClick={() => setShowShareDialog(true)}>
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M15.0709 14.1364C14.3415 14.1396 13.6396 14.4154 13.103 14.9095L5.94424 10.7428C5.9996 10.5124 6.0299 10.2768 6.0346 10.04C6.0299 9.80311 5.9996 9.56747 5.94424 9.33713L13.0227 5.21054C13.5295 5.67959 14.1824 5.95979 14.8715 6.004C15.5606 6.04822 16.2439 5.85375 16.8065 5.45331C17.3691 5.05288 17.7766 4.4709 17.9605 3.8053C18.1444 3.13969 18.0934 2.43106 17.8162 1.79862C17.5389 1.16617 17.0523 0.648502 16.4382 0.332691C15.8242 0.0168792 15.12 -0.0778042 14.4443 0.0645681C13.7686 0.20694 13.1626 0.577681 12.7281 1.11442C12.2937 1.65117 12.0573 2.32116 12.0588 3.0117C12.0635 3.24855 12.0938 3.48419 12.1492 3.71452L5.07073 7.84112C4.642 7.43973 4.10529 7.17243 3.52662 7.07211C2.94796 6.9718 2.35259 7.04283 1.81377 7.27648C1.27496 7.51013 0.816197 7.8962 0.493943 8.38719C0.171689 8.87818 0 9.45266 0 10.04C0 10.6273 0.171689 11.2017 0.493943 11.6927C0.816197 12.1837 1.27496 12.5698 1.81377 12.8034C2.35259 13.0371 2.94796 13.1081 3.52662 13.0078C4.10529 12.9075 4.642 12.6402 5.07073 12.2388L12.2195 16.4156C12.1674 16.6293 12.1405 16.8483 12.1391 17.0682C12.1391 17.6481 12.3111 18.2149 12.6332 18.697C12.9554 19.1792 13.4133 19.5549 13.949 19.7768C14.4847 19.9987 15.0742 20.0568 15.6429 19.9437C16.2116 19.8305 16.734 19.5513 17.144 19.1413C17.554 18.7313 17.8333 18.2089 17.9464 17.6402C18.0595 17.0715 18.0015 16.482 17.7796 15.9463C17.5577 15.4106 17.1819 14.9527 16.6997 14.6305C16.2176 14.3084 15.6508 14.1364 15.0709 14.1364Z"
                            fill="#2B2C57"
                          />
                        </svg>
                        <span>{t("Share this course")}</span>
                      </StyledBuyInfoItem>
                    </>
                  )}
                </StyledBuy>
              </div>
            </div>
          </StyledContainer>
        </div>
      </div>
      <div className={"section section-padding-bottom"} hidden={window.isPageLoading()}>
        <div className={"container"}>
          <SideWidget title={t("Related Courses")} style={{ marginTop: 64 }}>
            <FetchingItemsEffect hidden={!fetchProducts.fetching} />
            <div
              className="row row-cols-lg-4 row-cols-md-2 row-cols-1 max-mb-n30"
              hidden={fetchProducts.fetching}>
              {products.map((item, i) => (
                <RenderItem item={item} key={i} index={i} type={"product"} />
              ))}
            </div>
          </SideWidget>
        </div>
      </div>
    </React.Fragment>
  );
}

function ReviewScene({ product, match, refetchRating, fetchRating }) {
  const {t} = useTranslation()
  const [vote, setVote] = useState(0);
  const [error, setError] = useState(false);
  const [comment, setComment] = useState("");
  const [sending, setSending] = useState(false);
  const user = useSelector((state) => state.User.user);
  const [pageState, setPageState] = useSetState({
    currentPage: 0,
    rowsPerPage: 5,
  });

  const _updateVote = () => {
    if (!product) return;
    refetchRating();
  };
  const _submitVote = () => {
    setSending(true);
    createRatingTransactionAction({
      Id: Common.guid(),
      RatingId: product.ratingId,
      TargetId: localStorage.getItem(Configuration.userId),
      RelationId: match.params.id,
      Score: vote,
      Comment: comment,
      CreatedDate: Common.formatDateTime(new Date()),
      CreatedBy: localStorage.getItem(Configuration.userId),
      onDone(error) {
        setSending(false);
        if (error) {
          message.error("Failure to Vote. Please try again!");
        } else {
          _updateVote();
          setComment("");
          setVote(0);
          message.success(
            "Review Sent! Thank you for submitting course review. We will share this with the appropriate team."
          );
        }
      },
    });
  };

  useEffect(() => {
    _updateVote();
  }, [product]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageState.currentPage]);

  const loading = fetchRating.fetching;
  const transactions = fetchRating.data?.ratingmember?.ratingTransactions || [];
  const voted = _.findIndex(transactions, (v) => v.targetId === user.id) !== -1;
  const minItemIndex = pageState.currentPage * pageState.rowsPerPage;
  const maxItemIndex = minItemIndex + pageState.rowsPerPage;

  return (
    <StyledReviewBox
      onSubmit={(e) => {
        e.preventDefault();

        if (vote < 1) {
          setError(true);
          return;
        }

        _submitVote();
      }}>
      {loading && <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />}
      {!loading && (
        <StyledCommentContainer>
          {transactions.map((transaction, index) => {
            if (index < minItemIndex || index >= maxItemIndex) return null;

            return (
              <StyledTransactionItem key={index}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: 60 }}>
                        <img
                          src={Configuration.imageUrl + transaction.userImagePath}
                          alt={"Avatar"}
                        />
                      </td>
                      <td>
                        <div>
                          <b>{transaction.userName}</b>
                        </div>
                        <div style={{ marginTop: -4, position: "relative" }}>
                          <Rate
                            value={transaction.score}
                            style={{ color: "#FEC414", fontSize: 14 }}
                          />
                          <b
                            style={{
                              display: "inline-block",
                              marginLeft: 8,
                              position: "relative",
                              bottom: -2,
                            }}>
                            {parseFloat(transaction.score).toFixed(1)}
                          </b>
                          <div
                            style={{
                              position: "absolute",
                              top: 4,
                              right: 0,
                              color: hintTextColor,
                            }}>
                            {moment(transaction.createdDate).format("MMM D, YYYY")}
                          </div>
                        </div>
                        <div>{transaction.comment}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </StyledTransactionItem>
            );
          })}
          <RenderPagination
            currentPage={pageState.currentPage}
            totalItem={transactions.length}
            itemPerPage={pageState.rowsPerPage}
            handlePageChange={(pageIndex) => setPageState({ currentPage: pageIndex })}
          />
        </StyledCommentContainer>
      )}
      <div hidden={voted}>
        <StyledStarCounter>
          <label>{t("How would you rate this course?")}</label>
          <Rate
            value={vote}
            style={{ color: "#FEC414", fontSize: 30 }}
            onChange={(value) => {
              setVote(value);
              setError(false);
            }}
          />
          <span hidden={vote < 1} className={"StyledStarCounter-value"}>
            {parseFloat(vote).toFixed(1)}
          </span>
          <span hidden={!error} className={"StyledStarCounter-value"} style={{ color: "red" }}>
            Required
          </span>
        </StyledStarCounter>
        <label>Your review:</label>
        <textarea value={comment} required={true} onChange={(e) => setComment(e.target.value)} />
        <StyledLayout align="right" style={{ marginTop: 24 }}>
          <StyledButton variant="secondary" type={"submit"}>
            {sending ? (
              <ThreeDotsLoader width={40} height={20} fill={"#FFFFFF"} />
            ) : (
              t("Submit Review")
            )}
          </StyledButton>
        </StyledLayout>
      </div>
    </StyledReviewBox>
  );
}

export default Observer(MaxcoachProductDetailPage2);
