/* eslint-disable */
import { RATING } from "../../Config/Constants/RatingConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    rating: {},
};

export const reducer = persistReducer(
    {
        storage,
        key: "rating",
        blacklist: ["rating", "isLoading"],
    },
    (state = initialState, action) => {
        switch (action.type) {
            case REQUEST(RATING.GET_RATING):
                return {
                    ...state,
                    isLoading: true,
                };
            case FAILURE(RATING.GET_RATING):
                return {
                    ...state,
                    isLoading: false,
                };
            case SUCCESS(RATING.GET_RATING):
                return {
                    ...state,

                    rating: action.payload.ratingmember,
                    isLoading: false,
                };
            case REQUEST(RATING.CREATE_RATINGTRANSACTION):
                return {
                    ...state,

                    isLoading: true,
                };
            case FAILURE(RATING.CREATE_RATINGTRANSACTION):
                return {
                    ...state,
                    isLoading: false,
                };
            case SUCCESS(RATING.CREATE_RATINGTRANSACTION):
                return {
                    ...state,
                    isLoading: false,
                };
            default:
                return state;
        }
    }
);
