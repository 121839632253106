import React, { useEffect, useState } from "react";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useQuery } from "urql";
import { getCommonStorage } from "../../../../Config/gql";
import { queryForm } from "../../ComponentCommon/BasePage/gql";
import { SafeHtmlBox } from "../../ComponentCommon/SafeHtmlBox";
import Common from "../../../../Utils/Common";
import { setHeaderTranparentAction } from "../../../Actions/Layout";
import { connect } from "react-redux";
import { Observer } from "../../../../Utils/Ob";
import Configuration from "../../../../Config/Config";
import { notification } from "antd";
import { StyledSurveyContainer } from "./styled";



const bootstrap = `
<!-- Latest compiled and minified CSS -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">
<!-- Optional theme -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<!-- Latest compiled and minified JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js" integrity="sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa" crossorigin="anonymous"></script>
<script src="/js/surveyEvents.js"></script>
`;

function SurveyPage(props: any) {
  const { id } = props.match.params;
  const [fetchForm] = useQuery({
    query: queryForm,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        merchantId,
        id,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const handleEvent = (e: any) => {
      // @ts-ignore
      window.showPageLoading();

      Common.sendSyncCommand(
        "SubmitForm",
        "CustomerRelationshipManagement",
        {
          Id: id,
          Data: JSON.stringify(e.detail),
          ModifiedBy: localStorage.getItem(Configuration.userId),
          ModifiedDate: Common.formatDateTime(new Date()),
        },
        () => {
          // @ts-ignore
          window.hidePageLoading();
          notification.success({
            message: "Survey has been submitted.",
          });
          setSubmitted(true);
        },
        () => {
          // @ts-ignore
          window.hidePageLoading();
          alert("Something went wrong!");
        }
      );
    };

    window.document.addEventListener("submitSurvey", handleEvent, false);

    return () => {
      window.document.removeEventListener("submitSurvey", handleEvent);
    };
  }, [id]);

  const form = fetchForm.data?.form;
  const htmlForm = `<form>${form?.description || ""}</form>`;

  return (
    <BasePage
      title={form?.name || "Survey"}
      description={
        submitted ? <span style={{ color: "green" }}>Survey has been submitted.</span> : undefined
      }>
      <StyledSurveyContainer submitted={submitted ? "yes" : "no"}>
        <SafeHtmlBox autoHeight={true} srcDoc={`${htmlForm}${bootstrap}`} />
      </StyledSurveyContainer>
    </BasePage>
  );
}

function mapStateToProps(state: any) {
  return {
    infoUser: state.User.user,
  };
}

const mapDispatchToProps = {
  setHeaderTranparentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Observer(SurveyPage));
