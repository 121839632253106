/* eslint-disable */
import React, { Component } from "react";
import $ from "jquery";
import Common from "../../../../../Utils/Common";
import { connect } from "react-redux";
import "./ChangePassword.css";
import Swal from "sweetalert2";
import { setHeaderTranparentAction } from "../../../../Actions/Layout";

const { themes } = window;

class ChangePassword extends Component {
  changePassword = (event) => {
    let arrayInputValidate = ["currentPassword", "newPassword", "rePassword"];
    let $formChangePass = $("#formChangePass");
    let dataObject = {
      Id: this.props.infoUser.id,
      OldPassword: $("#formChangePass input[name='currentPassword']").val(),
      Password: $("#formChangePass input[name='newPassword']").val(),
      ModifiedBy: this.props.infoUser.id,
      ModifiedDate: Common.formatDateTime(new Date()),
    };
    let funCommand = () =>
      Common.sendSyncCommand(
        "ChangePasswordUser",
        "User",
        dataObject,
        () => Swal.fire("Cập nhật thành công"),
        () => Swal.fire("Cập nhật thất bại")
      );
    Common.validForm($formChangePass, arrayInputValidate, funCommand);
  };
  render() {
    this.props.setHeaderTranparentAction(false);
    // console.log(this.props.infoUser)
    return (
      <div className="uk-width-1-3@m uk-width-1-2@s m-auto">
        {themes.switch({
          [themes.default]: <div />,
          [themes.maxcoach]: <div style={{ height: 120, width: "100%" }} />,
        })}
        <form className="p-0" id="formChangePass" method="post" action="javascript;">
          <h2 className="uk-text-bold uk-text-center"> Đổi mật khẩu</h2>
          {/* <p className="text-muted text-center mb-lg-5"> Fill blank to log Dashboard</p> */}
          <div className="uk-form-label mt-4">Mật khẩu cũ</div>
          <div className="uk-position-relative">
            <span className="uk-form-icon">
              <i className="icon-feather-user" />
            </span>
            <input
              className="uk-input uk-form-width-large"
              name="currentPassword"
              type="password"
            />
          </div>
          <div className="uk-form-label mt-4">Mật khẩu mới</div>
          <div className="uk-position-relative">
            <span className="uk-form-icon">
              <i className="icon-feather-lock" />
            </span>
            <input
              className="uk-input uk-form-width-large"
              name="newPassword"
              id="newPassword"
              type="password"
            />
          </div>
          <div className="uk-form-label mt-4">Nhập lại mật khẩu mới</div>
          <div className="uk-position-relative uk-margin-bottom">
            <span className="uk-form-icon">
              <i className="icon-feather-lock" />
            </span>
            <input
              className="uk-input uk-form-width-large"
              name="rePassword"
              id="rePassword"
              type="password"
            />
          </div>
        </form>
        {themes.switch({
          [themes.default]: (
            <button
              className="btn btn-default grey  uk-margin"
              onClick={() => this.changePassword()}>
              Đổi mật khẩu
            </button>
          ),
          [themes.maxcoach]: (
            <button className="btn btn-primary" onClick={() => this.changePassword()}>
              Đổi mật khẩu
            </button>
          ),
        })}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    infoUser: state.Book.infoUser,
  };
}
const mapDispatchToProps = {
  // loadAllArticle,
  // loadAllCategoryArticle,
  // updateInfoUser,
  setHeaderTranparentAction,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
