import { getCommonStorage } from "../../Config/gql";
export const uploadFile = async (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const commonStorage = getCommonStorage();
    formData.append("token", commonStorage.tokenId);

    formData.append("UploadedFile", file);

    var request = new XMLHttpRequest();
    request.open("POST", "https://apicommand.izzi.asia/File/UploadFile");
    request.send(formData);
    request.onload = function () {
      var jsonResponse = JSON.parse(request.responseText);
      resolve({
        success: jsonResponse.Success,
        path:
          Array.isArray(jsonResponse.Data) && jsonResponse.Data.length > 0
            ? jsonResponse.Data[0].Path
            : null,
        fileId:
          Array.isArray(jsonResponse.Data) && jsonResponse.Data.length > 0
            ? jsonResponse.Data[0].Id
            : null,
        message: jsonResponse.Message,
      });
    };
  });
};

export const uploadFileContest = async (file, contestCode, contestId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const commonStorage = getCommonStorage();
    formData.append("token", commonStorage.tokenId);
    formData.append("contestCode", contestCode);
    formData.append("contestId", contestId);

    formData.append("UploadedFile", file);

    var request = new XMLHttpRequest();
    request.open("POST", "https://apirestful.izzi.asia/File/UploadFileContest");
    request.send(formData);
    request.onload = function () {
      var jsonResponse = JSON.parse(request.responseText);
      resolve({
        success: jsonResponse.Success,
        path: jsonResponse.Data.Path,
        fileId: jsonResponse.Data.Id,
        name: jsonResponse.Data.Name,
        message: jsonResponse.Data.Message || jsonResponse.Message,
      });
    };
  });
};
