/* eslint-disable */
import { QUIZ } from "../../Config/Constants/QuizConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";

const initialState = {
    quizTransactions: [],
    score: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST(QUIZ.GET_MEMBER_QUIZ_RESULT):
        case REQUEST(QUIZ.GET_MEMBER_QUIZ_DETAIL):
            return {
                ...state,
            };
        case FAILURE(QUIZ.GET_MEMBER_QUIZ_RESULT):
        case FAILURE(QUIZ.GET_MEMBER_QUIZ_DETAIL):
            return {
                ...state,
            };
        case SUCCESS(QUIZ.GET_QUIZ_TRANSACTION):
            return {
                ...state,
                quizTransactions: action.payload.quizTransactions,
            };
        case SUCCESS(QUIZ.GET_MEMBER_QUIZ_DETAIL):
            return {
                ...state,
                quiztransactionquestions: action.payload.quiztransactionquestions,
            };
        case QUIZ.SELECT_MEMBER_QUIZ_DETAIL:
            return {
                ...state,
                selectedQuiz: action.payload,
            };
        case QUIZ.CLEAR_MEMBER_QUIZ_DETAIL:
            return {
                ...state,
                selectedQuiz: "",
            };
        case QUIZ.SET_SCORE:
            return {
                ...state,
                score: action.score,
            };
        default:
            return state;
    }
}
