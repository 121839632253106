export const degrees = [
  { value: 1, name: "Doctorate (PHD)" },
  { value: 2, name: "Master Degree" },
  { value: 4, name: "Bachelor Degree" },
  { value: 8, name: "Diploma" },
];
export const getYears = ()=>{
    const years = []
    for(let i = 2022;i> 1920;--i)
    {
        years.push({value:i,name:i})
    }
    return years
}