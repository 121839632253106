import React, { useEffect, useState } from "react";
import { Observer } from "../../../../../../../Utils/Ob";
import Breadcrumb from "../../../../../ComponentCommon/maxcoach/Breadcrumb";
import { Link, useHistory } from "react-router-dom";
import { useLocation, useMeasure, useMount, useSetState } from "react-use";
import { QueryGraphQL } from "../../../../../../Queries/GraphQl";
import { message, Tooltip, Modal, Divider, Spin } from "antd";
import Configuration from "../../../../../../../Config/Config";
import { getUserCourses } from "../../../../../../Services/CourseService";
import { getQuizTransactions, getQuizTransaction } from "../../../../../../Services/QuizService";
import moment from "moment";
import { RenderPagination } from "../../../../Product/maxcoach/Products";
import ThreeDotsLoader from "../../../../../ComponentCommon/ThreeDotsLoader";
import { infoTextColor, primaryTextColor } from "../../../../../../../Utils/themeConfig";
import {
  StyledCertificatesSection,
  StyledInfo,
  StyledLoadingBox,
  StyledProfileImageContainer,
  StyledTag,
} from "./styledComponents";
import { useQuery } from "urql";
import { queryCertificates, queryContestEntries, queryPurchase } from "./gql";
import { getCommonStorage } from "../../../../../../../Config/gql";
import PopupCertificate from "../../../../../ComponentCommon/PopupCertificate";
import { StyledMemberName, StyledMemberPoint } from "./styled";
import EntryList from "../../../../Contest/EntryList";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    user: null,
    courses: null,
    quizzes: null,
  });
  const [avatarRef, avatarRefInfo] = useMeasure();
  const location = useLocation();

  useMount(() => {
    if (localStorage.getItem(window.config.localStorageTokenId) !== null) {
      window.showPageLoading();
      QueryGraphQL.getDataByToken(localStorage.getItem(window.config.localStorageTokenId))
        .then((data) => {
          if (data.userInformation.item != null) {
            setState({
              user: data.userInformation.item,
            });
          }
        })
        .catch(() => message.error("Có lỗi xảy ra"))
        .finally(() => window.hidePageLoading());
    }
  });

  if (!state.user) return null;

  const pageTitle = t("Profile");
  const quizzesFirst = location.search === "?tab=quizzes";

  return (
    <React.Fragment>
      <div
        className="page-title-section section section-padding-top-0"
        style={{ paddingBottom: 120 }}>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className="profile-section section section-padding-bottom">
        <div className="container">
          <div className="row align-items-lg-center">
            <div className="col-xl-5 col-md-6">
              <StyledProfileImageContainer className="profile-image">
                <img
                  ref={avatarRef}
                  src={Configuration.imageUrl + state.user.avatar}
                  alt="profile"
                  style={{
                    width: 254,
                    height: avatarRefInfo.width,
                    borderRadius: "50%",
                    overflow: "hidden",
                    objectFit: "cover",
                    transition: "unset",
                  }}
                />
              </StyledProfileImageContainer>
            </div>
            <div className="col-xl-6 col-md-6 offset-xl-1">
              <div className="profile-info">
                <StyledInfo>
                  <img
                    src={Configuration.imageUrl + state.user.avatar}
                    alt="profile"
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      overflow: "hidden",
                      objectFit: "cover",
                      transition: "unset",
                    }}
                  />
                  <div data-section={"info"}>
                    <h3 className="profile-name">
                      {[state.user.lastName, state.user.firstName].filter((v) => !!v).join(" ")}
                      
                    </h3>
                    <h3>
                    {state.user.memberName && (
                        <StyledMemberName>({state.user.memberName})</StyledMemberName>
                      )}
                    </h3>
                    <div className="author-career">/{state.user.userName}</div>
                    <StyledMemberPoint>
                      <span>{state.user.totalPoint}</span> {t("point")}
                    </StyledMemberPoint>
                  </div>
                </StyledInfo>
                {/*<p className="author-bio">*/}
                {/*  Maggie is a brilliant educator, whose life was spent for computer science and love*/}
                {/*  of nature. Being a female, she encountered a lot of obstacles and was forbidden to*/}
                {/*  work in this field by her family. With a true spirit and talented gift, she was*/}
                {/*  able to succeed and set an example for others.*/}
                {/*</p>*/}
                <h5 className="profile-contact-title">{t("Contact")}</h5>
                <span className="contact-info-text">
                  <span className="phone">
                    {t("Phone Number")}: <strong>{state.user.mobile || "No data"}</strong>
                  </span>
                  <br />
                  <span className="email">
                    Email: <strong>{state.user.email || "No data"}</strong>
                  </span>
                </span>
                <ul className="author-social-networks">
                  <li className="item">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} className={"social-link"}>
                      <i className="fab fa-twitter social-link-icon" />
                    </Link>
                  </li>
                  <li className="item">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} className={"social-link"}>
                      <i className="fab fa-facebook-f social-link-icon" />
                    </Link>
                  </li>
                  <li className="item">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} className={"social-link"}>
                      <i className="fab fa-instagram social-link-icon" />
                    </Link>
                  </li>
                  <li className="item">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} className={"social-link"}>
                      <i className="fab fa-pinterest social-link-icon" />
                    </Link>
                  </li>
                  <li className="item">
                    <Link to={"/"} onClick={(e) => e.preventDefault()} className={"social-link"}>
                      <i className="fab fa-youtube social-link-icon" />
                    </Link>
                  </li>
                </ul>
                <Link to={"/profile-edit"} className={"btn btn-primary"} style={{ marginTop: 20 }}>
                  {t("Edit Profile")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id={"quizzes-point"} />
      <div className="learn-press-profile-section section section-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="learn-press-profile-nav">
                <ul className="nav">
                  <li>
                    <a
                      className={quizzesFirst ? undefined : "active"}
                      data-toggle="tab"
                      href="#dashboard">
                      {t("Statistical")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#courses">
                      {t("Courses")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#cerfiticates">
                      {t("Certificates")}
                    </a>
                  </li>
                  <li>
                    <a
                      className={quizzesFirst ? "active" : undefined}
                      data-toggle="tab"
                      href="#quizzes">
                      {t("Quizzes")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#purchase">
                      {t("My Purchase")}
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" href="#contest">
                      {t("Contest")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tab-content lp-profile-content">
                <div
                  id="dashboard"
                  className={
                    "learn-press-profile-dashboard tab-pane fade" +
                    (quizzesFirst ? "" : " show active")
                  }>
                  <RenderDashboard
                    user={state.user}
                    onCourses={(courses) => setState({ courses })}
                    onQuizzes={(quizzes) => setState({ quizzes })}
                  />
                </div>
                <div id="courses" className="profile-content-courses tab-pane fade">
                  <RenderCourses courses={state.courses} />
                </div>
                <div
                  id="quizzes"
                  className={
                    "profile-content-quizzes tab-pane fade" + (quizzesFirst ? " show active" : "")
                  }>
                  <RenderQuizzes quizzes={state.quizzes} />
                </div>
                <div id="cerfiticates" className="profile-content-courses tab-pane fade">
                  <RenderCertificates />
                </div>
                <div id="purchase" className="profile-content-courses tab-pane fade">
                  <RenderPurchase />
                </div>
                <div id="contest" className="profile-content-courses tab-pane fade">
                  <RenderContest />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export const Status = (num) => {
  num = parseInt(num);
  const mapping = {
    1: "Created",
    33: "Process",
    32801: "Failed",
    1048609: "Paid",
  };

  return <StyledTag type={mapping[num]}>{mapping[num]}</StyledTag>;
};

function RenderPurchase() {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    limit: 10,
    page: 0,
  });
  const [fetchPurchase] = useQuery({
    query: queryPurchase,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return {
        merchantId: merchantId,
        languageId: languageId,
        tokenUser: tokenId,
        limit: state.limit,
        offset: state.page * state.limit,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  const history = useHistory();

  const orders = fetchPurchase.data?.userorders?.items || [];
  const total = fetchPurchase.data?.userorders?.totalCount || 0;

  return (
    <StyledCertificatesSection>
      <table className="lp-list-table table">
        <thead>
          <tr>
            <th>{t("Code")}</th>
            <th>{t("Course")}</th>
            <th>{t("Total")}</th>
            <th>{t("Purchase Date")}</th>
            <th>{t("Payment Method")}</th>
            <th>{t("Status")}</th>
            <th>{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, i) => {
            return (
              <tr key={i}>
                <td>#{order.code}</td>
                <td>
                  {(order.orderLines || []).map((line, j) => {
                    return <div key={"l" + j}>{line.targetName}</div>;
                  })}
                </td>
                <td>{Number(order.grandTotal).toLocaleString("vi-VN")}</td>
                <td>{moment(order.createdDate).format("MMM DD, YYYY")}</td>
                <td>{order.paymentMethodName}</td>
                <td>{Status(order.allStatus)}</td>
                <td>
                  <Link
                    to={"/purchase-detail/" + order.id}
                    style={{ color: infoTextColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("/purchase-detail/" + order.id, {
                        state: order,
                      });
                    }}>
                    {t("View")}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <RenderPagination
        currentPage={state.page}
        totalItem={total}
        itemPerPage={state.limit}
        handlePageChange={(pageIndex) => setState({ page: pageIndex })}
      />
    </StyledCertificatesSection>
  );
}

function RenderContest() {
  // get data here
  const [state, setState] = useSetState({
    limit: 10,
    page: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTranscripts, setModalTranscripts] = useState(null);

  const [fetchContestEntries] = useQuery({
    query: queryContestEntries,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return {
        merchantId: merchantId,
        languageId: languageId,
        tokenUser: tokenId,
        limit: state.limit,
        offset: state.page * state.limit,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  console.log(fetchContestEntries.data);
  const contestEntries = fetchContestEntries.data?.contestentries?.items || [];
  const total = fetchContestEntries.data?.contestentries?.totalCount || 0;
  //console.log(total);

  // let groupedTranscripts = null;
  // if (modalTranscripts) {
  //   groupedTranscripts = {};
  //   modalTranscripts.forEach((transcript) => {
  //     if (!groupedTranscripts[transcript.criteriaCategoryName]) {
  //       groupedTranscripts[transcript.criteriaCategoryName] = [
  //         {
  //           ...transcript,
  //         },
  //       ];
  //     } else {
  //       groupedTranscripts[transcript.criteriaCategoryName].push({
  //         ...transcript,
  //       });
  //     }
  //   });
  // }
  return (
    <StyledCertificatesSection>
      <Spin spinning={fetchContestEntries.fetching}>
        {/* <Modal
        width={"70%"}
        title={"Contest transcripts"}
        visible={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          setModalTranscripts(null);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setModalTranscripts(null);
        }}>
        <React.Fragment>
          <table className="lp-list-table table">
            <thead>
              <tr>
                <th colspan="2">Tiêu chí</th>
                <th>Điểm tối da</th>
                <th>Điểm thực tế</th>
                <th>Nhận xét</th>
              </tr>
            </thead>
            <tbody>
              {groupedTranscripts &&
                Object.keys(groupedTranscripts).map((criteriaCategoryName) =>
                  groupedTranscripts[criteriaCategoryName].map((transcript, i) => (
                    <tr>
                      {i === 0 && (
                        <td rowSpan={groupedTranscripts[criteriaCategoryName].length}>
                          {criteriaCategoryName}
                        </td>
                      )}
                      <td>{transcript.criteriaName}</td>
                      <td>{transcript.maxMark}</td>
                      <td>{transcript.mark}</td>
                      <td>{transcript.description}</td>
                    </tr>
                  ))
                )}
              {modalTranscripts && (
                <tr>
                  <td>Tổng điểm</td>
                  <td></td>
                  <td>{modalTranscripts.reduce((pre, cur) => {
                    return pre + cur.maxMark
                  }, 0)}</td>
                  <td
                    style={{
                      backgroundColor: "#111111",
                      color: "white",
                    }}>
                    {modalTranscripts.reduce((pre, cur) => pre + cur.mark, 0)}
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </React.Fragment>
      </Modal>
      <table className="lp-list-table table">
        <thead>
          <tr>
            <th>Stt</th>
            <th>Cuộc thi</th>
            <th>Trạng thái</th>
            <th>Điểm cuối cùng</th>
          </tr>
        </thead>
        <tbody>
          {contestEntries.map((entry, i) => (
            <tr>
              <td>{i + 1}</td>
              <td>
                <a
                  href="/abcde"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                    setModalTranscripts(entry.transcripts);
                  }}>
                  {entry.contest?.name || "N/A"}
                </a>
              </td>
              <td>
                <span
                  className={`lp-label ${
                    entry.status === "Closed" ? "label-fail" : "label-passed"
                  }`}>
                  {entry.status}
                </span>
              </td>
              <td>{entry.finalMark}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
        <EntryList entries={contestEntries} />
        <RenderPagination
          currentPage={state.page}
          totalItem={total}
          itemPerPage={state.limit}
          handlePageChange={(pageIndex) => setState({ page: pageIndex })}
        />
      </Spin>
    </StyledCertificatesSection>
  );
}

function RenderQuizzes(props) {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    filter: null,
    openModal: false,
    loadingQuiz: false,
    questions: [],
    limit: 10,
    currentPage: 0,
  });
  const location = useLocation();

  const _getStatus = (num) => {
    if (num === 1) return <span className="lp-label label-in-progress">Started</span>;
    if (num === 4) return <span className="lp-label label-completed">Waiting</span>;
    if (num === 256) return <span className="lp-label label-passed">Completed</span>;
    return "";
  };
  const _openQuizTransactionDetail = (id) => {
    setState({ openModal: true, loadingQuiz: true });
    getQuizTransaction(id).then((data) => {
      console.log(data, "quiz detail");
      setState({ loadingQuiz: false, questions: data.quizTransaction?.quizTransactionQuestions || [] });
    });
  };

  useEffect(() => {
    setState({ currentPage: 0 });
  }, [state.filter]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.currentPage]);
  useEffect(() => {
    const quizzesFirst = location.search === "?tab=quizzes";

    if (quizzesFirst) {
      document.getElementById("quizzes-point")?.scrollIntoView();
    }
  }, [props.quizzes]);

  const quizes = (props.quizzes || []).filter((quiz) => {
    return !(state.filter !== null && quiz.status !== state.filter);
  });
  const totalCount = quizes.length;
  const minItemIndex = state.currentPage * state.limit;
  const maxItemIndex = minItemIndex + state.limit;

  return (
    <React.Fragment>
      <Modal
        title={"Quiz Detail"}
        visible={state.openModal}
        onOk={() => setState({ openModal: false })}
        onCancel={() => setState({ openModal: false })}>
        <React.Fragment>
          {state.loadingQuiz && (
            <StyledLoadingBox>
              <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />
            </StyledLoadingBox>
          )}
          {!state.loadingQuiz && (
            <React.Fragment>
              {state.questions.map((question, i) => {
                return (
                  <React.Fragment key={i}>
                    <p>
                      <b>
                        {t("Question")} {i + 1}: {question.name}
                      </b>
                    </p>
                    <p>
                      Answer:{" "}
                      {question.quizTransactionAnswers
                        .filter((v) => v.isCorrect)
                        .map((v) => v.name)
                        .map((v) => {
                          return v
                            .split("_{{")
                            .filter((v) => v.length)
                            .map((v) => v.split("}}_").filter((v) => v.length)[0])
                            .map((v) => v.trim())
                            .filter((v) => v.length)
                            .join(", ");
                        })
                        .join(", ")}
                    </p>
                    {i !== state.questions.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          )}
        </React.Fragment>
      </Modal>
      {!props.quizzes && (
        <div className="learn-press-subtab-content">
          <StyledLoadingBox>
            <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />
          </StyledLoadingBox>
        </div>
      )}
      {quizes.length > 0 && (
        <>
          <div className="learn-press-subtab-content">
            <ul className="lp-sub-menu nav justify-content-center">
              <li onClick={() => setState({ filter: null })}>
                <Link to={"/"} onClick={(e) => e.preventDefault()}>
                  {t("All")}
                </Link>
              </li>
              <li onClick={() => setState({ filter: 256 })}>
                <Link to={"/"} onClick={(e) => e.preventDefault()}>
                  {t("Completed")}
                </Link>
              </li>
              <li onClick={() => setState({ filter: 4 })}>
                <Link to={"/"} onClick={(e) => e.preventDefault()}>
                  {t("Waiting")}
                </Link>
              </li>
              <li onClick={() => setState({ filter: 1 })}>
                <Link to={"/"} onClick={(e) => e.preventDefault()}>
                  {t("Started")}
                </Link>
              </li>
            </ul>
            <div className="row">
              <div className="col-12 scroll">
                <table className="lp-list-table table">
                  <thead>
                    <tr>
                      <th className="column-course">{t("Name")}</th>
                      <th className="column-date">{t("Start Date")}</th>
                      <th className="column-date">{t("End Date")}</th>
                      <th className="column-date">{t("Status")}</th>
                      <th className="column-passing-grade">{t("Score")}</th>
                      <th className="column-status" style={{ whiteSpace: "nowrap" }}>
                        {t("Detail")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizes.map((quiz, i) => {
                      if (state.filter !== null && quiz.status !== state.filter) return null;

                      if (i < minItemIndex || i >= maxItemIndex) return null;

                      return (
                        <tr key={i}>
                          <td className="column-course">{quiz.quizName}</td>
                          <td className="column-date">
                            {quiz.startTime
                              ? moment(quiz.startTime).format("YYYY-MM-DD HH:mm:ss")
                              : ""}
                          </td>
                          <td className="column-date">
                            {quiz.endTime ? moment(quiz.endTime).format("YYYY-MM-DD HH:mm:ss") : ""}
                          </td>
                          <td className="column-passing-grade">{_getStatus(quiz.status)}</td>
                          <td className="column-status">
                            {quiz.score}/{quiz.point}
                          </td>
                          <td
                            className="column-status"
                            onClick={() => _openQuizTransactionDetail(quiz.id)}>
                            <Link
                              to={"/"}
                              onClick={(e) => e.preventDefault()}
                              style={{ whiteSpace: "nowrap" }}>
                              {t("View")}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <RenderPagination
            currentPage={state.currentPage}
            totalItem={totalCount}
            itemPerPage={state.limit}
            handlePageChange={(pageIndex) => setState({ currentPage: pageIndex })}
          />
        </>
      )}
    </React.Fragment>
  );
}

function RenderCourses(props) {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    filter: null,
  });

  return (
    <React.Fragment>
      {!props.courses && (
        <div className="learn-press-subtab-content">
          <StyledLoadingBox>
            <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />
          </StyledLoadingBox>
        </div>
      )}
      {props.courses && props.courses.length > 0 && (
        <div className="learn-press-subtab-content">
          <ul className="lp-sub-menu nav justify-content-center">
            <li onClick={() => setState({ filter: null })}>
              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                {t("All")}
              </Link>
            </li>
            <li onClick={() => setState({ filter: 1 })}>
              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                {t("Completed")}
              </Link>
            </li>
            <li onClick={() => setState({ filter: 0 })}>
              <Link to={"/"} onClick={(e) => e.preventDefault()}>
                {t("In-Progress")}
              </Link>
            </li>
          </ul>
          <div className="row">
            <div className="col-12 scroll">
              <table className="lp-list-table table">
                <thead>
                  <tr>
                    <th className="column-course">{t("Name")}</th>
                    <th className="column-date">{t("Created Date")}</th>
                    <th className="column-passing-grade">{t("Duration")}</th>
                    <th className="column-status">{t("Process")}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.courses.map((course, i) => {
                    if (state.filter === 1) {
                      if (+course.percent !== 100) return null;
                    } else if (state.filter === 0) {
                      if (+course.percent === 100) return null;
                    }

                    return (
                      <tr key={i}>
                        <td className="column-course">{course.name}</td>
                        <td className="column-date">
                          {moment(course.createdDate).format("YYYY-MM-DD")}
                        </td>
                        <td className="column-passing-grade">
                          {course.period} {t("hours")}
                        </td>
                        <td className="column-status">
                          <span className="result-percent">{course.percent}%</span>
                          {course.percent === 100 && (
                            <span className="lp-label label-completed">{t("Completed")}</span>
                          )}
                          {course.percent !== 100 && (
                            <span className="lp-label label-in-progress">{t("In-Progress")}</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function RenderDashboard(props) {
  const { t } = useTranslation();
  const [state, setState] = useSetState({
    loading: false,
    courses: [],
    quizzes: [],
  });

  useMount(() => {
    setState({ loading: true });

    Promise.all([
      getUserCourses(0, 10000),
      getQuizTransactions("", 9999, 0, "desc", "startTime", null),
    ])
      .then(([res, res1]) => {
        
        setState({
          courses: res?.usercourses?.items || [],
          quizzes: res1?.quizTransactions?.items || [],
        });

        props.onCourses && props.onCourses(res?.usercourses?.items || []);
        props.onQuizzes && props.onQuizzes(res1?.quizTransactions?.items || []);

        console.log(res?.usercourses?.items, "res?.usercourses?.items");
        console.log(res1.quizTransactions?.items, "res1.quizTransactions");
      })
      .catch(() => message.error("Something went wrong!"))
      .finally(() => {
        setState({ loading: false });
      });
  });

  let progressCourses = state.courses.filter((v) => v.percent < 100);
  let successQuizzes = state.quizzes.filter((v) => (v.status & 256) === 256);
  let progressQuizzes = state.quizzes.filter((v) => (v.status & 256) !== 256);

  return (
    <React.Fragment>
      {state.loading && (
        <StyledLoadingBox>
          <ThreeDotsLoader width={40} height={20} fill={primaryTextColor} />
        </StyledLoadingBox>
      )}
      {!state.loading && (
        <React.Fragment>
          <div className="profile-progress-status">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="status-box success courses-completed">
                  <div className="status-number">
                    {formatNumberOfCourses(state.courses.filter((v) => v.percent === 100).length)}
                  </div>
                  <br />
                  <h6 className="status-text">
                    {t("Courses")}&nbsp;{t("Completed")}
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="status-box warning courses-in-progress">
                  <div className="status-number">
                    {formatNumberOfCourses(progressCourses.length)}
                  </div>
                  <h6 className="status-text">
                    {t("Courses")}&nbsp;{t("In-Progress")}
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="status-box info quizzes-completed">
                  <div className="status-number">
                    {formatNumberOfCourses(successQuizzes.length)}
                  </div>
                  <h6 className="status-text">
                    {t("Quizzes")}&nbsp;{t("Completed")}
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="status-box error courses-completed">
                  <div className="status-number">
                    {formatNumberOfCourses(progressQuizzes.length)}
                  </div>
                  <h6 className="status-text">
                    {t("Quizzes")}&nbsp;{t("In-Progress")}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {progressCourses.length > 0 && (
            <div className="profile-courses-progress">
              <h3 className="title">
                {t("In-Progress")} {t("Courses")}
              </h3>
              <div className="maxcoach-progress style-01">
                {progressCourses.map((course, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className={"progress-charts wow move-up" + (i > 0 ? " mt-50" : "")}>
                        <h6 className="title">{course.name}</h6>
                        <div className="progress">
                          <div
                            className="progress-bar wow fadeInLeft"
                            data-wow-duration="0.5s"
                            data-wow-delay=".3s"
                            role="progressbar"
                            style={{ width: Math.max(course.percent, 10) + "%" }}
                            aria-valuenow={course.percent}
                            aria-valuemin="0"
                            aria-valuemax="100">
                            <span className="percent-label">{course.percent}%</span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function RenderCertificates() {
  const { t } = useTranslation();
  const [certificate, setCertificate] = useState(null);
  const [fetchCertificates] = useQuery({
    query: queryCertificates,
    variables: (() => {
      const { merchantId, languageId, tokenId } = getCommonStorage();

      return { merchantId, languageId, tokenUser: tokenId };
    })(),
  });

  const exists = {};
  const certificates = (fetchCertificates.data?.articleusercertificates?.items || []).filter(
    (v) => {
      let hasExists = false;

      (v.courses || []).forEach((course) => {
        if (exists[course.id]) {
          hasExists = true;
        }

        exists[course.id] = true;
      });

      return !hasExists;
    }
  );

  return (
    <StyledCertificatesSection>
      <PopupCertificate
        show={!!certificate}
        certificate={certificate}
        onCancel={() => setCertificate(null)}
      />
      <table className="lp-list-table table">
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("Description")}</th>
            <th>{t("Created Date")}</th>
            <th>{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {certificates.map((certificate, i) => {
            return (
              <tr key={i}>
                <td>{certificate.name}</td>
                <td>{certificate.subDescription}</td>
                <td>{moment(certificate.createdDate).format("MMM DD, YYYY")}</td>
                <td>
                  <Link
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      setCertificate(certificate);
                    }}
                    style={{ whiteSpace: "nowrap" }}>
                    {t("View")}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </StyledCertificatesSection>
  );
}

export default Observer(Profile);

function formatNumberOfCourses(num) {
  num = num + "";
  let realValue = num;

  if (num.length < 2) num = ("0" + num).slice(-2);

  if (num.length > 2) {
    num = (
      <span style={{ display: "inline-block", position: "relative" }}>
        <span>99</span>
        <span style={{ position: "absolute", top: 8, right: -20, fontSize: 36 }}>+</span>
      </span>
    );
  }

  return (
    <Tooltip title={realValue}>
      <span style={{ cursor: "pointer" }}>{num}</span>
    </Tooltip>
  );
}
