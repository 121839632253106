/* eslint-disable */
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { Link } from "react-router-dom";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

class Index extends Component {
    state = {
        offset: 0,
        totalItem: null,
        itemPerPage: 12,
        arrayItemPerPage: [],
        currentCategory: "",
        categories: [],
        popularArticle: [],
    };
    componentDidMount() {
        QueryGraphQL.pagingFreeCourseArticles(7, this.state.offset, 10).then((data) => {
            if (data.freecoursearticles.items.length) {
                this.setState({
                    popularArticle: data.freecoursearticles.items.slice(0, 10),
                });
            }
        });

        QueryGraphQL.getCourse(0, 100000).then((data) => {
            if (data.courses.items != null && data.courses.items.length) {
                this.setState({
                    categories: data.courses.items,
                    currentCategory: data.courses.items.slice(0, 1)[0].id,
                });
                console.log(this.state.categories);
                QueryGraphQL.pagingArticles(7, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            totalItem: data.articles.totalCount,
                            arrayItemPerPage: data.articles.items,
                            popularArticle: data.articles.items.slice(0, 3),
                        });
                    }
                });
            }
        });
    }
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.pagingArticles(7, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            arrayItemPerPage: data.articles.items,
                            totalItem: data.articles.totalCount,
                        });
                    } else {
                        this.setState({
                            arrayItemPerPage: [],
                            totalItem: 0,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };
    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.pagingArticles(7, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                if (data.articles.items.length) {
                    this.setState({
                        arrayItemPerPage: data.articles.items,
                        totalItem: data.articles.totalCount,
                    });
                }
            });
        });
    };
    render() {
        let { totalItem } = this.state;

        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div className="container">
                <h1>Slide</h1>
                <div className="section-small">
                    <div className="grid-slider-header">
                        <div>
                            <h4 className="uk-text-truncate"> Các slide mới nhất </h4>
                        </div>
                    </div>
                    <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                        {this.state.popularArticle.map((item, i) => (
                            <div key={i}>
                                <Link to={`/slide/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                    <div className="course-card">
                                        <div className="course-card-thumbnail ">
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                            {/* <span className="play-button-trigger" /> */}
                                        </div>
                                        <div className="course-card-body">
                                            <div className="course-card-info">
                                                <div>
                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                </div>
                                                <div>
                                                    <i className="icon-feather-bookmark icon-small" />
                                                </div>
                                            </div>
                                            <h4>{item.name}</h4>
                                            <p>{item.subDescription}</p>
                                            {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                <h4> Các slide dạy học</h4>
                <nav className="responsive-tab  style-2" uk-filter="target: .js-filter">
                    <ul>
                        {this.state.categories.map((item, i) => (
                            <Fragment key={i}>
                                <li
                                    onClick={() => this.changeCategory(item.id)}
                                    uk-filter-control={`[data-color='${item.id}']`}
                                    className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                    <a href="#">{item.name}</a>
                                </li>
                            </Fragment>
                        ))}
                    </ul>
                </nav>
                <div className="section-small">
                    <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                        {this.state.arrayItemPerPage.map((item, i) => (
                            <div key={i}>
                                <Link to={`/slide/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                    <div className="course-card">
                                        <div className="course-card-thumbnail ">
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                            {/* <span className="play-button-trigger" /> */}
                                        </div>
                                        <div className="course-card-body">
                                            <div className="course-card-info">
                                                <div>
                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                </div>
                                                <div>
                                                    <i className="icon-feather-bookmark icon-small" />
                                                </div>
                                            </div>
                                            <h4>{item.name}</h4>
                                            <p>{item.subDescription}</p>
                                            {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>

                <ul className="uk-pagination uk-flex-center uk-margin-medium">
                    {(() => {
                        let tempArray = [];
                        for (let index = 0; index < totalPage; index++) {
                            tempArray.push(
                                <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                    <span>{index + 1}</span>
                                </li>
                            );
                        }
                        return <Fragment>{tempArray}</Fragment>;
                    })()}
                </ul>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setBreadcrumbAction,
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
