import React from "react";
import Common from "../../../../../../Utils/Common";
import GoogleMapReact from 'google-map-react';
import Configuration from "../../../../../../Config/Config";
import { createOrderAction } from "../../../../../Actions/Order";

const Marker = ({ text }) => <div>{text}</div>

const EventAbout = ({description, date, address, products}) => {

  const _createOrder = (productId) => {
    const items = [
      {
        Id: Common.guid(),
        TargetId: productId,
        quantity: 1,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  }

  return (
    <div className="about-event-section section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Section Title Start */}
            <div className="section-title text-center" data-aos="fade-up">
              <h2 className="title fz-48 heading-color">Thông tin</h2>
              <div className="event-entry-meta">
                <div className="meta-item">
                  <i className="meta-icon fal fa-map-marker-alt" />
                  <span>{address ? address.name : "Online"}</span>
                </div>
                <div className="meta-item">
                  <i className="meta-icon fal fa-calendar" />
                  <span>{Common.formatDateTime(date, "dd-mm-yyyy hh:mm")}</span>
                </div>
                {/*<div className="meta-item">*/}
                {/*  <i className="meta-icon fal fa-clock" />*/}
                {/*  <span>2:00 pm - 4:00 pm</span>*/}
                {/*</div>*/}
              </div>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
            {/* Event Details Wrapper Start */}
            {address ? (
              <div className="event-details-wrapper">
                <div className="map-area">
                  <GoogleMapReact
                    style={{
                      height: "270px"
                    }}
                    bootstrapURLKeys={{ key: Configuration.GOOGLE_MAP_KEY }}
                    // defaultCenter={{lat: 59.95, lng: 30.33}}
                    defaultCenter={{
                      lat: address.latitude,
                      lng: address.longitude
                    }}
                    defaultZoom={11}
                  >
                    <Marker
                      lat={address.latitude}
                      lng={address.longitude}
                      text={address.name}
                    />
                  </GoogleMapReact>
                  {/*<iframe className="event-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd" />*/}
                  <div className="entry-location-address">
                    <span className="fal fa-map-marker-alt" />
                    {" "}{address.name}
                  </div>
                </div>
              </div>
            ) : null}

            {/* Event Details Wrapper End */}
          </div>
          <div className="col-lg-4 col-12 order-lg-2 max-mb-50">
            {products.map(product => (
              <div className="sidebar-widget-wrapper pr-0 pl-30 pl-md-0 pl-sm-0 pl-xs-0 mb-3">
                <div className="sidebar-widget">
                  <div className="sidebar-widget-content">
                    <div className="sidebar-entry-event">
                      <div className="entry-register">
                        <ul className="entry-event-info">
                          <li className="meta-price">
                      <span className="meta-label">
                        <i className="meta-icon far fa-money-bill-wave" />
                        Cost:
                      </span>
                            <span className="meta-value">
                        <span className="event-price">{Common.formatMoney(product.price, 0)}
                        </span>
                      </span>
                          </li>
                          <li className="booking_slot">
                      <span className="meta-label">
                        <i className="meta-icon far fa-user-friends" />
                        Tên:			</span>
                            <span className="meta-value">{product.name}</span>
                          </li>

                        </ul>

                        {/*<p className="event-register-message">You must <a href="login-register.html">login</a> before register event.</p>*/}
                      </div>
                      <a href="/" className="btn btn-primary btn-hover-secondary btn-width-100" onClick={(e) => {
                        e.preventDefault()
                        _createOrder(product.id);
                      }}>Mua ngay
                      </a>
                      {/*<ul className="author-social-networks event-social">*/}
                      {/*  <li className="item">*/}
                      {/*    <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-facebook-f social-link-icon" /> </a>*/}
                      {/*  </li>*/}
                      {/*  <li className="item">*/}
                      {/*    <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-twitter social-link-icon" /> </a>*/}
                      {/*  </li>*/}
                      {/*  <li className="item">*/}
                      {/*    <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-linkedin social-link-icon" /> </a>*/}
                      {/*  </li>*/}
                      {/*  <li className="item">*/}
                      {/*    <a href="JavaScript:Void(0);" target="_blank" className="social-link"> <i className="fab fa-tumblr-square social-link-icon" /> </a>*/}
                      {/*  </li>*/}
                      {/*</ul>*/}
                    </div>
                  </div>
                </div>
              </div>
            ))}


          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="event-content" dangerouslySetInnerHTML={{
              __html: description
            }}>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EventAbout;
