import React from "react";
import {
  StyledContainer,
  StyledFormBody,
  StyledFormBox,
  StyledFormFooter,
  StyledFormHeader,
  StyledLogoBox,
} from "./styledComponents";
import { useMeasure, useMount, useSetState } from "react-use";
import { Checkbox, Input } from "antd";
import { Link } from "react-router-dom";
import { StyledButton } from "../../../Product/maxcoach/ProductDetail/styledComponents";
import Common from "../../../../../../Utils/Common";
import axios from "axios";
import Configuration from "../../../../../../Config/Config";
import Swal from "sweetalert2";
import ThreeDotsLoader from "../../../../ComponentCommon/ThreeDotsLoader";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { getCommonStorage } from "../../../../../../Config/gql";

function MaxcoachForgotPasswordPage(props) {
  const [state, setState] = useSetState({
    email: "",
    password: "",
    loading: false,
  });
  const [containerRef, containerInfo] = useMeasure();

  const _recovery = (e) => {
    e.preventDefault();
    if (state.loading) return;

    setState({ loading: true });

    const id = Common.guid();

    Common.sendSyncCommand(
      "RecoveryPasswordByEmail",
      "CustomerRelationshipManagement",
      {
        Id: id,
        Domain: window.Merchant.data.code,
        Email: state.email,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: id,
      },
      () => {
        Swal.fire("New password has been sent to your email.");
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setState({ loading: false });
      },
      () => {
        Swal.fire("Lấy lại mật khẩu thất bại!");
        setState({ loading: false });
      }
    );
  };

  useMount(() => {
    const { tokenId } = getCommonStorage();

    if (tokenId) {
      window.location.href = "/";
    }
  });

  const merchant = window.Merchant.data;
  const logoDark = JSON.parse(merchant.configuration)["DarkLogoPath"];  
  const logo = Common.getImageThumbnail(merchant.images, "?mode=crop&width=180");
  const offset = window.innerHeight - containerInfo.height;

  return (
    <StyledContainer
      ref={containerRef}
      style={{
        paddingTop: Math.max(0, offset / 4),
      }}>
      <StyledLogoBox>
        <MCC>
         <img src={(logoDark != undefined && logoDark != "") ? logoDark  + "?mode=crop&width=180": logo } alt="Logo" />
        </MCC>
      </StyledLogoBox>
      <StyledFormBox onSubmit={_recovery}>
        <StyledFormHeader>Forgot Password</StyledFormHeader>
        <StyledFormBody>
          <label>Email</label>
          <Input
            value={state.email}
            onChange={(event) => setState({ email: event.target.value })}
            required={true}
            type={"email"}
            placeholder={"Your email"}
          />
          <StyledButton
            variant={"primary"}
            display={"block"}
            style={{ marginTop: 32 }}
            type={"submit"}>
            {state.loading && <ThreeDotsLoader width={40} height={18} fill={"#FFFFFF"} />}
            {!state.loading && "Recovery"}
          </StyledButton>
        </StyledFormBody>
        <StyledFormFooter>
          <Link to={"/login"}>Back to login</Link>
        </StyledFormFooter>
      </StyledFormBox>
    </StyledContainer>
  );
}

export default MaxcoachForgotPasswordPage;
