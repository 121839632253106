import { styled } from "../../../../../Utils/stitches.config";
import { primaryColor } from "../../../../../Utils/themeConfig";

export const ProfileSection = styled("div", {
  background: "#FFFFFF",
  boxShadow: "0px 3px 15px #0000001A",
  padding: 8,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: 8,
  overflow: "hidden",
  fontSize: 14,
  color: "var(--primary-text-color)",
  ul: {
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  li: {
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    cursor: "pointer",
    ":hover": {
      background: "#EFF0F1",
    },
    ".dx-label": {
      position: "relative",
    },
  },
  ".dx-name": {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "19px",
    marginBottom: 5,
  },
  ".dx-email": {
    color: "#999FAE",
    lineHeight: "18px",
  },
  ".dx-logout": {
    color: "#E8364E",
  },
  ".dx-divider": {
    padding: 0,
    margin: 0,
    borderTop: "1px solid #EFF0F1",
  },
});

export const NotificationDot = styled("span", {
  position: "absolute",
  width: 8,
  height: 8,
  borderRadius: "50%",
  background: "#E8364E",
  right: -12,
  top: 0,
  variants: {
    type: {
      avatar: {
        right: 0,
      },
      number: {
        color: "#ffffff",
        background: "#EA8024",
        fontSize: 8,
        lineHeight: "10px",
        width: "auto",
        height: "auto",
        padding: "2px 4px",
        top: -4,
        right: -8,
      },
    },
  },
});

export const LanguageIcon = styled("div",{
    marginTop:"3px",
    position: "relative",
    zIndex: 4,
    width: "28px",
    height: "28px",
    overflow: "hidden",
    margin: "0 auto",
    "& :hover":{
      cursor: "pointer"
    }
})
export const Avatar = styled("span", {
  position: "relative",
  width: 32,
  height: 32,
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
    overflow: "hidden",
    border: "1px solid " + primaryColor,
  },
});

export const StyledNotifications = styled("span", {
  display: "inline-block",
  width: 60,
  textAlign: "center",
  position: "relative",
  cursor: "pointer",
  ".ant-badge": {
    fontSize: 20,
  },
});
export const DisableMenuItem = styled("li",{
  pointerEvents: "none",
  opacity: 0.5
})
