import { styled } from "../../../../Utils/stitches.config";
import { borderInputColor, hintTextColor } from "../../../../Utils/themeConfig";

export const StyledContainer = styled("div", {
  input: {
    width: "100%",
    border: 0,
    padding: 0,
    margin: 0,
  },
  label: {
    color: hintTextColor,
    fontSize: 12,
    lineHeight: "15px",
    padding: 0,
    margin: 0,
  },
});
export const StyledInputContainer = styled("div", {
  border: "1px solid " + borderInputColor,
  padding: "4px 14px",
  borderRadius: 8,
  position: "relative",
  paddingRight: 60,
});
export const StyledCopyButton = styled("button", {
  background: "unset",
  border: 0,
  margin: 0,
  position: "absolute",
  top: 8,
  right: 10,
});
export const StyledButtonsContainer = styled("div", {
  textAlign: "center",
  paddingBottom: 32,
  paddingTop: 10,
  "button:not(:first-child)": {
    marginLeft: 16,
  },
});
