import { Cart } from "../../Config/Constants/Cart";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Swal from "sweetalert2";

const initialState = {
  cart: [],
  wishList: JSON.parse(localStorage.getItem("wishList")) || [] ,

};

export const reducer = persistReducer(
  {
    storage,
    key: "cart",
    blacklist:["cart"]
  },
  (state = initialState, action) => {
    switch (action.type) {
      case Cart.GET_CART: {
        return {
          ...state,
          cart: action.payload,
        };
      }
      case Cart.UPDATE_ITEM_CART: {
        console.log(action.payload, "25");
        let tempCart = [];
        if (action.payload.type === "inc") {
          tempCart = state.cart.map((item) => {
            if (item.id === action.payload.id) {
              return { ...item, quantity: item.quantity + 1 };
            }
            return { ...item };
          });
        }
        if (action.payload.type === "dec") {
          tempCart = state.cart.map((item) => {
            if (item.id === action.payload.id) {
              if (item.quantity === 1) {
                return { ...item, quantity: 1 };
              }
              return { ...item, quantity: item.quantity - 1 };
            }
            return { ...item };
          });
        }
        return {
          ...state,
          cart: [...tempCart],
        };
      }
      case Cart.GET_WISHLIST:{
        return {...state,wishList:[...action.payload]}
      }
      case Cart.ADD_ITEM_TO_WISHLIST:{
        
        if(state.wishList.length > 0 && state.wishList.find(item=>item.id === action.payload.id)){
          Swal.fire({
            icon:"error",
            text:"This item already on wishlist"
          })
          return {...state}
          
        }
        const tempWishList = [...state.wishList,action.payload.data]
    
        action.payload.navigate.push("/wishlist")
        return {...state,wishList:tempWishList}
      }
      case Cart.DELETE_ITEM_WISHLIST:{
        const tempWishList = state.wishList.filter(item=>item.id !== action.payload)
      
        return {...state,wishList:tempWishList}
      }
      default: {
        return { ...state };
      }
    }
  }
);
