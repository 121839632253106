import { styled } from "../../../../Utils/stitches.config";

export const StyledPiPContainer = styled("div", {
  position: "fixed",
  bottom: 16,
  right: 16,
  width: 240,
  height: 180,
  background: "#000",
  borderRadius: 4,
  overflow: "hidden",
  zIndex: 999,
  canvas: {
    width: "100%",
    height: "100%",
    transform: "rotateY(180deg)",
    pointerEvents: "none",
  },
  "&:hover": {
    // width: parseInt((window.innerWidth * 40) / 100),
    // height: (parseInt((window.innerWidth * 40) / 100) * 9) / 16,
  },
});
