import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "urql";
import { QUERY_USER } from "./gql";
import { getCommonStorage } from "../../../../../../../Config/gql";
import * as ecs from "../../../../Course/maxcoach/UserCourse/ecs";
import Breadcrumb from "../../../../../ComponentCommon/maxcoach/Breadcrumb";
import { ChangePasswordSection, Container, FooterSection, HawkEye, Title } from "./styled";
import PropTypes from "prop-types";
import Configuration from "../../../../../../../Config/Config";
import MCC from "../../../../../ComponentCommon/MiddleContent";
import Common, { checkEmailExist, languageMapping } from "../../../../../../../Utils/Common";
import moment from "moment";
import { Observer } from "../../../../../../../Utils/Ob";
import { useSetState, useUnmount } from "react-use";
import { Input, Button, DatePicker, message, Modal, Spin, Tooltip, Select } from "antd";
import $ from "jquery";
import { SpinIcon } from "../../../../../ComponentCommon/PageLoading";
import "./style.less";
import AttributeValueForm from "../../../../Attribute/Components/AttributeValueForm";
import {
  handleAttributeFormSubmit,
  getFormAttributeValues,
} from "../../../../../../Services/AttributeService";

import { useTranslation } from "react-i18next";
import _ from "lodash";


const langConvert = {
  vn:"Tiếng việt",
  en:"Tiếng anh"
}

const getLanguageList = () => {
  const temp = [];
  for (let i in languageMapping) {
    temp.push({
      label: langConvert[i],
      value: languageMapping[i],
    });
  }
  return temp;
};

function MaxcoachProfileEditPage() {
  const { t } = useTranslation();
  const [emailValidate, setEmailValidate] = useState({
    isFocussingEmailField: false,
    defaultEmail: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const [languageList] = useState(getLanguageList());
  const [fetchUser, refetchUser] = useQuery({
    query: QUERY_USER,
    requestPolicy: "network-only",
    variables: (() => {
      const { merchantId, userId } = getCommonStorage();

      return {
        userId,
        merchantId,
      };
    })(),
  });
  const [state, setState] = useSetState({
    lastName: undefined,
    firstName: undefined,
    email: undefined,
    mobile: undefined,
    companyName: undefined,
    companySite: undefined,
    dateOfBirth: undefined,
    showModal: false,
    currentPassword: undefined,
    languageId:undefined,
    newPassword: undefined,
    confirmPassword: undefined,
    changingPassword: false,
  });
  const [showState, setShowState] = useSetState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  // const [attributeFormValues, setAttributeFormValues] = useState();
  const [attributeErrors, setAttributeErrors] = useState([]);
  const [currentAttributeValues, setCurrentAttributeValues] = useState({
    attributeVarcharValue: [],
    attributeTextValue: [],
    attributeIntValue: [],
    attributeDecimalValue: [],
    attributeDateTimeValue: [],
  });

  const _save = () => {
    const user = fetchUser.data.user;
    window.showPageLoading();

    // const {
    //   relationAttributeValues,
    //   isFormValid,
    //   errorMessages
    // } = handleAttributeFormSubmit(user.id, attributeFormValues, currentAttributeValues);

    // if (!isFormValid) {
    //   setAttributeErrors(errorMessages);
    //   return;
    // }

    Common.sendSyncCommand(
      "UpdateMemberElearnInformationUser",
      "User",
      {
        Id: user.id,
        FirstName: state.firstName,
        LastName: state.lastName,
        CompanyName: state.companyName,
        CompanySite: state.companySite,
        Mobile: state.mobile,
        Email: state.email,
        //   DateOfBirth: state.dateOfBirth,
        ImageId: null,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: user.id,
        LanguageId: state.languageId || languageMapping["vn"]
        //attributeValues: relationAttributeValues,
      },
      () => {
        message.success("Account info update was successful.");
        window.hidePageLoading();
        refetchUser();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      () => {
        message.error("Something went wrong!");
        window.hidePageLoading();
      }
    );
  };
  const _onSelectAvatar = (e) => {
    const files = e.target.files;

    if (files.length) {
      const file = files[0];

      const formData = new FormData();
      formData.append("token", localStorage.getItem(window.config.localStorageTokenId));
      formData.append("file", file);
      const ajaxRequest = $.ajax({
        type: "POST",
        url: window.config.urlApi + "/Image/UploadAvatar",
        contentType: false,
        processData: false,
        data: formData,
      });
      window.showPageLoading();
      ajaxRequest.done((response) => {
        if (response.Success) {
          message.success("Your file upload was successful.");
          window.hidePageLoading();
          window.location.reload();
        } else {
          message.error("Maximum file size exceeded!");
          window.hidePageLoading();
        }
      });
    }
  };
  const _onChangePassword = () => {
    const user = fetchUser.data.user;
    const { currentPassword, newPassword, confirmPassword } = state;
    const filledAllFields =
      [currentPassword, newPassword, confirmPassword].filter((v) => !!v).length === 3;

    if (!filledAllFields) {
      return message.error("Please fill in all the required fields!");
    }

    if (newPassword !== confirmPassword) {
      return message.error("New Password and Confirm Password does not match!");
    }

    setState({ changingPassword: true });

    Common.sendSyncCommand(
      "ChangePasswordUser",
      "User",
      {
        Id: user.id,
        OldPassword: currentPassword,
        Password: newPassword,
        ModifiedBy: user.id,
        ModifiedDate: Common.formatDateTime(new Date()),
      },
      () => {
        message.success("Your password has been changed successfully.");
        setState({ changingPassword: false, showModal: false });
      },
      (e) => {
        message.error(e.Message);
        setState({ changingPassword: false });
      }
    );
  };

  useEffect(() => {
    if (fetchUser.fetching) {
      window.showPageLoading();
    } else {
      window.hidePageLoading();
    }
  }, [fetchUser.fetching]);
  useUnmount(() => {
    window.resetPageLoading();
  });
  useEffect(() => {
    const user = fetchUser.data?.user;

    if (!user) return;

    const keys = Object.keys(state);
    const newState = {};

    for (let k of keys) {
      if (k in user) newState[k] = user[k];
    }
    console.log(newState,"232")
    setEmailValidate({ defaultEmail: user.email });
    setState(newState);
    // getFormAttributeValues({ targetId: user.id }).then((res) => {
    //   const {
    //     attributeTextValues,
    //     attributeVarcharValues,
    //     attributeDecimalValues,
    //     attributeIntValues,
    //     attributeDateTimeValues
    //   } = res.formattributevalues;

    //   setCurrentAttributeValues({
    //     attributeVarcharValue: attributeVarcharValues,
    //     attributeTextValue: attributeTextValues,
    //     attributeIntValue: attributeIntValues,
    //     attributeDecimalValue: attributeDecimalValues,
    //     attributeDateTimeValue: attributeDateTimeValues
    //   });
    // });
  }, [fetchUser.data]);

  const user = fetchUser.data?.user;
  const avatar = (() => {
    if (user?.images?.length > 0) {
      return Common.getImageThumbnail(user.images, "?mode=crop&width=300&height=300");
    }

    return Configuration.imageAvatarDefault;
  })();
  const fullName = [user?.lastName, user?.firstName].filter((v) => !!v).join(" ");
  const debounceCheckEmailExist = useCallback(
    _.debounce(
      (email) =>
        checkEmailExist(
          email,
          emailValidate.isFocussingEmailField,
          emailValidate.defaultEmail
        ).then((res) => {
          if (res?.data?.Success) {
            setErrors({
              email: t("Email existed , please choose other email"),
            });
          } else {
            setErrors({
              email: "",
            });
          }
        }),
      2000
    )
  );
  const handleOnChange = (e) => {
    setState({ email: e.target.value });
    setEmailValidate({ isFocussingEmailField: true });

    const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
    if (!reg.test(e.target.value)) {
      setErrors({
        email: t("Email invalidate"),
      });
      return;
    }
    setErrors({
      email: "",
    });

    debounceCheckEmailExist(e.target.value);
  };
  return (
    <BaseLayout pageTitle={"My Account"}>
      {user && (
        <Container id={"profile-edit-page"}>
          <div className={"row"}>
            <div className={"col-lg-4 col-12 dx-left-side"}>
              <Tooltip title={"Maximum file size is 512 kb"}>
                <span onClick={() => document.getElementById("choose-avatar").click()}>
                  <img className={"dx-avatar"} src={avatar} alt={"Avatar"} />
                  <div className={"dx-capture"}>
                    <MCC>
                      <i className="fas fa-camera" />
                    </MCC>
                  </div>
                  <Input
                    id={"choose-avatar"}
                    type={"file"}
                    style={{ display: "none" }}
                    onChange={_onSelectAvatar}
                  />
                </span>
              </Tooltip>
              <h3>{fullName}</h3>
              <p>
                {t("Member Since")} {moment(user.createdDate).format("D MMM YYYY")}
              </p>
            </div>
            <div className={"col-lg-8 col-12 dx-right-side"}>
              <h3>{t("General Information")}</h3>
              <div className={"row"}>
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Name")}</label>
                  <Input
                    value={state.lastName}
                    onChange={(e) => setState({ lastName: e.target.value })}
                  />
                </div>
                <div className={"col-lg-6 col-12"}>
                  <label>{t("First Name")}</label>
                  <Input
                    value={state.firstName}
                    onChange={(e) => setState({ firstName: e.target.value })}
                  />
                </div>
                <div className={"col-lg-6 col-12"}>
                  <label>Email</label>
                  <Input
                    type={"text"}
                    value={state.email}
                    onBlur={() => setEmailValidate({ isFocussingEmailField: false })}
                    onChange={handleOnChange}
                  />
                  <p className="font-bold text-red-600">{errors.email}</p>
                </div>
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Phone Number")}</label>
                  <Input
                    type={"number"}
                    value={state.mobile}
                    onChange={(e) => setState({ mobile: e.target.value })}
                  />
                </div>
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Company Name")}</label>
                  <Input
                    value={state.companyName}
                    onChange={(e) => setState({ companyName: e.target.value })}
                  />
                </div>
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Company Website")}</label>
                  <Input
                    type={"url"}
                    value={state.companySite}
                    onChange={(e) => setState({ companySite: e.target.value })}
                  />
                </div>
                {/* <div className={"col-lg-6 col-12"}>
                  <label>Date of Birth</label>
                  <DatePicker
                    value={moment(state.dateOfBirth || new Date())}
                    onChange={(_v, v) => {
                      setState({ dateOfBirth: v });
                    }}
                  />
                </div> */}
                <div className={"col-lg-6 col-12"}>
                  <label>{t("Language")}</label>
                  <Select onChange={(e)=>setState({languageId:e})} value={state.languageId} style={{ width: "100%" }} placeholder={t("Choose language for account")}>
                    {languageList.map((lang) => {
                      return (
                        <Select.Option value={lang.value} key={lang.value}>
                          {lang.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <div className={"col-lg-6"} />
                <div className={"col-12"}>
                  {/* <AttributeValueForm
                target={Configuration.attributeConfiguration.target.USER}
                targetType = {1}
                attributeErrors={attributeErrors}
                //attributeSelecteds={attributeSelecteds}
                currentAttributeValues={currentAttributeValues}
                //control = {control}
                //attributeErrors={attributeErrors}
                onChange={(formData) => { setAttributeFormValues(formData) }}
              >
              </AttributeValueForm> */}
                </div>
                <div className={"col-lg-6 col-6"}>
                  <Button className={"btn-default"} onClick={() => setState({ showModal: true })}>
                    {t("Change Password")}
                  </Button>
                </div>
                <div className={"col-lg-6 col-6"} style={{ textAlign: "right" }}>
                  <Button
                    disabled={!Object.values(errors).includes("")}
                    className={"btn-primary"}
                    onClick={_save}>
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      <Modal
        title={<Title>{t("Change Password")}</Title>}
        visible={state.showModal}
        onOk={_onChangePassword}
        okText={"Confirm"}
        confirmLoading={state.changingPassword}
        onCancel={() => setState({ showModal: false })}
        footer={
          <FooterSection>
            <Spin spinning={state.changingPassword} indicator={SpinIcon}>
              <button className={"btn btn-primary"} onClick={_onChangePassword}>
                {t("Confirm")}
              </button>
            </Spin>
          </FooterSection>
        }>
        <ChangePasswordSection>
          <div className={"row"}>
            <div className={"col-12"}>
              <label>{t("Current Password")}</label>
              <Input
                type={showState.currentPassword ? "text" : "password"}
                value={state.currentPassword}
                onChange={(e) => setState({ currentPassword: e.target.value })}
                suffix={
                  <HawkEye
                    onClick={() => setShowState({ currentPassword: !showState.currentPassword })}>
                    <i className={showState.currentPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                  </HawkEye>
                }
              />
            </div>
            <div className={"dx-divider"} />
            <div className={"col-12"}>
              <label>{t("New Password")}</label>
              <Input
                type={showState.newPassword ? "text" : "password"}
                value={state.newPassword}
                onChange={(e) => setState({ newPassword: e.target.value })}
                suffix={
                  <HawkEye onClick={() => setShowState({ newPassword: !showState.newPassword })}>
                    <i className={showState.newPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                  </HawkEye>
                }
              />
            </div>
            <div className={"col-12"}>
              <label>{t("Confirm Password")}</label>
              <Input
                type={showState.confirmPassword ? "text" : "password"}
                value={state.confirmPassword}
                onChange={(e) => setState({ confirmPassword: e.target.value })}
                suffix={
                  <HawkEye
                    onClick={() => setShowState({ confirmPassword: !showState.confirmPassword })}>
                    <i className={showState.confirmPassword ? "fas fa-eye-slash" : "fas fa-eye"} />
                  </HawkEye>
                }
              />
            </div>
          </div>
        </ChangePasswordSection>
      </Modal>
    </BaseLayout>
  );
}

export default Observer(MaxcoachProfileEditPage);

export function BaseLayout(props) {
  const { pageTitle } = props;

  return (
    <>
      <div className="section page-title-section">
        <div className="page-title">
          <div className="container">
            <ecs.PageTitleSection>
              <h1 className="title">{useTranslation().t(pageTitle)}</h1>
            </ecs.PageTitleSection>
          </div>
        </div>
        <Breadcrumb items={[{ name: "Home", href: "/" }, { name: pageTitle }]} />
      </div>
      <div className={"section section-padding-bottom"}>
        <div className={"container"}>{props.children}</div>
      </div>
    </>
  );
}

BaseLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};
