/* eslint-disable */
import React, { useEffect, Fragment, useState } from "react";
import "./styles.less";
import { useMount, useSetState, useUnmount } from "react-use";
import { getUserCourse } from "../../../../../Services/CourseService";
import { Observer } from "../../../../../../Utils/Ob";
import { Col, message, Row, Empty, Divider, Modal, Select, Card } from "antd";
import CourseList, {
  CourseListState,
} from "../../../../../Router/Layout/maxcoach/CourseHeader/CourseList";
import { CourseHeaderState } from "../../../../../Router/Layout/maxcoach/CourseHeader";
import MCC from "../../../../ComponentCommon/MiddleContent";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import $ from "jquery";
import Configuration from "../../../../../../Config/Config";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import VimeoPlayer from "../../../Video/VimeoPlayer";
import MaxQuizDetail from "../../../Quiz/maxcoach/QuizDetail";
import VideoQuizs from "../../../Quiz/maxcoach/VideoQuizs";
import LoadingSpinner from "../../../../ComponentCommon/LoadingSpinner";
import { isPreviewCourse } from "../../../../../../Utils/utils";
import PdfSlider from "../../../../ComponentCommon/PdfSlider/PdfSlider";

import _ from "lodash";
import { StyledQuizSelect } from "./styled";

function UserCourseDetails(props) {
  console.log("run",28)
  
  const [state, setState] = useSetState({
    course: null,
    loading: false,
 
  });

  const _fixScroll = () => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "hidden";
  };
  const _reloadCourse = () => {
    _fixScroll();

    CourseListState.userCourse = undefined;
    setState({ loading: true, course: null });
    getUserCourse(props.match.params.id)
      .then((res) => {
        if (res.usercourse && isPreviewCourse()) {
          res.usercourse.courseLessons = _.map(res.usercourse.courseLessons, (courseLesson) => {
            _.forEach(courseLesson, (lessonItem, k) => {
              if (Array.isArray(lessonItem)) {
                courseLesson[k] = _.map(lessonItem, (v2) => {
                  if ("seen" in v2) {
                    v2.seen = true;
                  }

                  return v2;
                });
              }
            });

            if ("locked" in courseLesson) {
              courseLesson.locked = true;
            }

            return courseLesson;
          });
        }

        setState({ course: res.usercourse });        
        
        CourseListState.userCourse = res.usercourse;        
        CourseHeaderState.showDrawer = true;

        if (CourseListState.currentIsLastQuiz) {
          if (res.usercourse.certificate) {
            Modal.success({
              title: "You've received a certificate.",
              okText: "OK",
            });
          } else {
            Modal.error({
              title: "You are not eligible to receive a certificate!",
              okText: "OK",
            });
          }
        }
      })
      .catch(() => {
        message.error("Có lỗi xảy ra!").catch(console.error);
      })
      .finally(() => {
        setState({ loading: false });
        CourseListState.reloadingCourse = false;
      });
  };

  useMount(() => {
    localStorage.setItem("currentCourse", props.match.params.id);
    _reloadCourse();
  });
  useUnmount(() => {
    document.body.style.overflowY = "auto";
    window.resetPageLoading();
  });
  useEffect(() => {
    if (!state.course) return;
    CourseHeaderState.title = state.course.name;
  }, [state.course]);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "hidden";
  }, [CourseListState.selected]);
  useEffect(() => {
    if (!CourseListState.renderLastQuiz) return;

    CourseListState.renderLastQuiz = false;
    
    if (CourseListState.userCourse?.quizs) {
      console.log(CourseListState.userCourse?.quizs[0])
      CourseListState.selected = {
        ...CourseListState.selected,
        item: CourseListState.userCourse.quizs?.length === 1 ? CourseListState.userCourse?.quizs[0] : CourseListState.userCourse?.quizs.find(quiz=>quiz.id === CourseHeaderState.selectedQuizId) ,
        itemType: "quizz",
      };
      message.info("Complete the final quiz to receive the Certificate");
      CourseListState.currentIsLastQuiz = true;
    } else {
      CourseListState.reloadCourse = true;
    }
  }, [CourseListState.renderLastQuiz]);
  useEffect(() => {
    if (CourseListState.reloadingCourse) {
      CourseListState.reloadCourse = false;
      return;
    }

    if (CourseListState.reloadCourse) {
      CourseListState.reloadingCourse = true;
      CourseListState.reloadCourse = false;
      _reloadCourse();
    }
  }, [CourseListState.reloadCourse, CourseListState.reloadingCourse]);
  useEffect(() => {
    if (CourseListState.reloadingCourse) {
      window.showPageLoading();
    } else {
      window.hidePageLoading();
      _fixScroll();
    }
  }, [CourseListState.reloadingCourse]);
  
  _fixScroll();
  const { showMenu } = CourseHeaderState;
  const lesson = CourseListState.selected;
 
  return (
    <div id={"dongsec"} style={{ padding: 8, height: "calc(100vh - 85px)", position: "relative" }}>
      <Modal onCancel={()=>CourseHeaderState.isSelectQuizModalOpen = false} footer={null} title="Chọn quiz" visible={CourseHeaderState.isSelectQuizModalOpen}>
       
          {CourseListState.userCourse?.quizs?.map((quiz)=><Card hoverable style={{marginBottom:"12px"}} onClick={()=>{
              CourseHeaderState.selectedQuizId = quiz.id
              CourseListState.renderLastQuiz = true;
              CourseHeaderState.isSelectQuizModalOpen = false
          }}>{quiz.name}</Card>)}
       
      </Modal>
      <Row gutter={[16, 0]}>
        <Col
          xxl={showMenu ? 4 : 0}
          xl={showMenu ? 6 : 0}
          lg={showMenu ? 8 : 0}
          md={0}
          sm={0}
          xs={0}
          style={{ position: "relative" }}>
          <div className={"left-side hide-scroll"}>
            <CourseList />
          </div>
        </Col>
        <Col
          xxl={showMenu ? 20 : 24}
          xl={showMenu ? 18 : 24}
          lg={showMenu ? 16 : 24}
          md={24}
          sm={24}
          xs={24}
          style={{ position: "relative" }}>
          <div className={["right-side", lesson?.itemType || "undefined"].join(" ")}>
            {!state.loading && !CourseListState.selected && (
              <Fragment>
                <MCC>
                  <Empty description={"Hãy chọn bài học để bắt đầu"} />
                </MCC>
              </Fragment>
            )}
            {lesson?.itemType === "quizz" && (
              <Fragment>
                <MaxQuizDetail
                  key={[lesson.lesson.id, lesson.item.id].join()}
                  id={lesson.item.id}
                />
              </Fragment>
            )}
            {lesson?.itemType === "videoQuizs" && (
              <VideoQuizs key={[lesson.lesson.id, lesson.item.id].join()} id={lesson.item.id} />
            )}
            {!["quizz", "videoQuizs"].includes(lesson?.itemType) && (
              <div
                className={"right-section hover " + lesson?.itemType}
                hidden={!state.loading && !CourseListState.selected}>
                {state.loading && <LoadingSpinner />}
                {!state.loading && lesson && (
                  <Fragment>
                    {lesson.itemType === "slide" && (
                      <Fragment>
                        <LightgalleryProvider
                          onAfterSlide={() => {
                            $(".side-nav").hide();
                          }}
                          onCloseAfter={() => {
                            $(".side-nav").show();
                          }}>
                          {/*<h3 className="course-item-title question-title">{lesson.item.name}</h3>*/}
                          {/*<InfoDivider info={lesson.item.images?.length + " ảnh"} />*/}
                          {lesson.item.images?.length > 0 && (
                            <Carousel
                              showArrows={true}
                              renderItem={(children) => {
                                return (
                                  <MCC>
                                    <span className={"slide-image"}>{children}</span>
                                  </MCC>
                                );
                              }}
                              renderThumbs={() => {
                                return lesson.item.images.map((p) => {
                                  let src = Configuration.imageUrl + p.path;
                                  return (
                                    <span className={"slide-thumb"}>
                                      <MCC
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}>
                                        <img
                                          src={src}
                                          alt={"slide"}
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </MCC>
                                    </span>
                                  );
                                });
                              }}>
                              {lesson.item.images?.map((p, i) => {
                                let src = Configuration.imageUrl + p.path;
                                return (
                                  <LightgalleryItem src={src} key={i}>
                                    <img
                                      src={src}
                                      alt={"slide"}
                                      style={{
                                        height: "auto",
                                        width: "auto",
                                      }}
                                    />
                                  </LightgalleryItem>
                                );
                              })}
                            </Carousel>
                          )}
                          {/* <SinglePage path={pdf} /> */}
                          {lesson.item.files?.length > 0 && (
                            <PdfSlider path={Configuration.imageUrl + lesson.item.files[0].path} />
                          )}
                        </LightgalleryProvider>
                      </Fragment>
                    )}
                    {lesson.itemType === "article" && (
                      
                      <div onPaste={(e)=>{
                        e.preventDefault()
                        return false;
                      }} onCopy={(e)=>{
                        e.preventDefault()
                        return false;
                      }}
                        dangerouslySetInnerHTML={{
                          __html: lesson.item.description,
                        }}
                      />
                    )}
                    {lesson.itemType === "video" && (
                      <Fragment>
                        {/*<h3 className="course-item-title question-title">{lesson.item.name}</h3>*/}
                        {/*<InfoDivider info={"N/A phút"} />*/}
                        <div className="videoWrapper">
                          {lesson.item.typeStorage === 4 ? (
                            <iframe
                              title={"youtube"}
                              key={[lesson.lesson.id, lesson.item.id].join()}
                              src={`https://www.youtube.com/embed/${lesson.item.path}?enablejsapi=1&rel=0&modestbranding=1`}
                              frameBorder={0}
                              allowFullScreen
                              width="560"
                              height="349"
                            />
                          ) : (
                            <VimeoPlayer
                              key={[lesson.lesson.id, lesson.item.id].join()}
                              options={{
                                id: lesson.item.path.split("/")[
                                  lesson.item.path.split("/").length - 1
                                ],
                                width: "100%",
                              }}
                            />
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

function InfoDivider(props) {
  return (
    <div style={{ position: "relative" }}>
      <Divider />
      <div className={"divider-text"}>{props.info}</div>
    </div>
  );
}

export default Observer(UserCourseDetails);
