/* eslint-disable */
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserCourseAction, seenLessonItemAction, unlockCourseLessonUserAction } from "../../../Actions/Course";

import {getUserCourse} from "../../../Services/CourseService"
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Configuration from "../../../../Config/Config";
import VimeoPlayer from "../Video/VimeoPlayer";
import { Link } from "react-router-dom";
import QuizDetail from "../Quiz/QuizDetail";
import $ from "jquery";
import Swal from "sweetalert2";

const PhotoItem = ({ image, group, index }) => (
    <div style={{ padding: "5px", flex: "1 0 50%", border: "solid 1px" }}>
        <LightgalleryItem group={group} src={image}>
            <img id={"itemGallery" + index} src={image} />
        </LightgalleryItem>
    </div>
);

function Index(props) {
    const dispatch = useDispatch();
    const tempUserCourse = useSelector((state) => state.Course.usercourse);
    const score = useSelector((state) => state.Quiz.score);
    console.log(tempUserCourse);
    const [information, toggleInformation] = useState(false);

    const [description, setDescription] = useState();
    const [userCourse, setCourse] = useState({});
    const [watchContent, setwatchContent] = useState({});
    useEffect(() => {
        getUserCourse(props.match.params.id).then(res => 
            {
                setCourse(res.usercourse);
                
            }
            
            )
        //dispatch(getUserCourseAction(props.match.params.id))
        document.body.classList.add('course-watch-page');
    }, [])
   

    useEffect(() => {
        if (userCourse.type == Configuration.courseType.conditionQuiz) {
            var foundIndex = userCourse.courseLessons.findIndex((x) => x.id == watchContent.id);
            if (foundIndex < userCourse.courseLessons.length - 1) {
                if (userCourse.courseLessons[foundIndex + 1].locked == false) {
                    var currentLesson = userCourse.courseLessons.find((x) => x.id == watchContent.id);

                    var currentQuiz = currentLesson.quizs.find((e) => e.id == watchContent.itemId);

                    if (
                        currentLesson != undefined &&
                        currentQuiz != undefined &&
                        (parseFloat(score) / parseFloat(currentQuiz.totalScore)) * 100 > currentLesson.conditionValue
                    ) {
                        unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
                        userCourse.courseLessons[foundIndex + 1].locked = true;
                        setCourse({ ...userCourse });
                        Swal.fire("Bạn đã mở khóa bài học tiếp theo thành công.").then((result) => {
                            window.location.reload();
                        });
                    }
                } else {
                    window.location.reload();
                }
            }
        }
    }, [score]);

    //console.log(tempUserCourse);

    const watch = (id, itemId, type, itemType) => {
        switch (itemType) {
            case "slide":
                toggleInformation(true);
                break;
            case "video":
                toggleInformation(true);
                break;
            case "quiz":
                toggleInformation(false);
                break;
        }

        setDescription(userCourse.courseLessons.find((e) => e.id === id).description);

        setwatchContent({ id, itemId, type, itemType });
        var temp = userCourse.courseLessons.find((e) => e.id === id);
        if (!temp.locked) {
            switch (userCourse.type) {
                case Configuration.courseType.timeTable:
                    Swal.fire("Chưa đến thời gian mở bài. Mời xem lịch để biết thêm chi tiết.");
                    break;
                case Configuration.courseType.conditionQuiz:
                    Swal.fire("Bạn cần hoàn thành quiz để mở bài tiếp theo.");
                    break;
                case Configuration.courseType.sequence:
                    Swal.fire("Bạn cần xem tất cả nội dung bài học để mở bài tiếp theo.");
                    break;
            }
            return;
        }

        if (
            $("#li_" + itemId)
                .attr("class")
                .indexOf("watched") < 0
        ) {
            seenLessonItemAction(itemId, type);
        }

        $("#li_" + itemId).attr("class", "watched uk-active");

        $(".course-content-inner").find("li").removeClass();
        $("#content_" + id + itemId).attr("class", "uk-active");

        userCourse.courseLessons.forEach((lesson, index) => {
            if (lesson.id === id) {
                switch (itemType) {
                    case "slide":
                        userCourse.courseLessons[index].slides.forEach((slide, indexSlide) => {
                            if (slide.id == itemId) {
                                userCourse.courseLessons[index].slides[indexSlide].seen = true;
                            }
                        });
                        break;
                    case "video":
                        userCourse.courseLessons[index].videos.forEach((video, indexVideo) => {
                            if (video.id == itemId) {
                                userCourse.courseLessons[index].videos[indexVideo].seen = true;
                            }
                        });
                        break;
                    case "quiz":
                        userCourse.courseLessons[index].quizs.forEach((quiz, indexQuiz) => {
                            if (quiz.id == itemId) {
                                userCourse.courseLessons[index].quizs[indexQuiz].seen = true;
                            }
                        });
                        break;
                }
            }
        });

        switch (userCourse.type) {
            case Configuration.courseType.conditionQuiz:
              

                var foundIndex = userCourse.courseLessons.findIndex((x) => x.id == id);
                if (foundIndex < userCourse.courseLessons.length - 1) {
                    if (userCourse.courseLessons[foundIndex + 1].locked == false) {
                        var flag = true;
                        var tempLesson = userCourse.courseLessons.find((e) => e.id == id);
                        if (tempLesson.quizs.length == 0) {
                            if (tempLesson.slides.filter((e) => e.seen == false).length > 0) {
                                flag = false;
                            }
    
                            if (tempLesson.videos.filter((e) => e.seen == false).length > 0) {
                                flag = false;
                            }
    
                            if (flag) {
                                unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
                                userCourse.courseLessons[foundIndex + 1].locked = true;
                                setCourse({ ...userCourse });
                            }
                        }                        
                    }
                }
                //Swal.fire("Bạn cần hoàn thành quiz để mở bài tiếp theo.")
                break;
            case Configuration.courseType.sequence:
                var foundIndex = userCourse.courseLessons.findIndex((x) => x.id == id);
                if (foundIndex < userCourse.courseLessons.length - 1) {
                    if (userCourse.courseLessons[foundIndex + 1].locked == false) {
                        var flag = true;
                        var tempLesson = userCourse.courseLessons.find((e) => e.id == id);
                        if (tempLesson.slides.filter((e) => e.seen == false).length > 0) {
                            flag = false;
                        }

                        if (tempLesson.videos.filter((e) => e.seen == false).length > 0) {
                            flag = false;
                        }

                        if (tempLesson.quizs.filter((e) => e.seen == false).length > 0) {
                            flag = false;
                        }

                        if (flag) {
                            unlockCourseLessonUserAction(userCourse.courseLessons[foundIndex + 1].id);
                            userCourse.courseLessons[foundIndex + 1].locked = true;
                            setCourse({ ...userCourse });
                        }
                    }
                }
                break;
        }
    };
    return (
        //document.body.classList.add('course-watch-page');
        <div className="course-layouts">
            <div className="course-content bg-dark">
                <div className="course-header">
                    <a href="#" className="btn-back" uk-toggle="target: .course-layouts; cls: course-sidebar-collapse">
                        <i className="icon-feather-chevron-left" />
                    </a>
                    <h4 className="text-white"> {userCourse.name}</h4>
                    <div>
                        <a href="javascript:;">
                            <i className="icon-feather-help-circle btns" />{" "}
                        </a>
                        <div uk-drop="pos: bottom-right;mode : click">
                            <div className="uk-card-default p-4">
                                <h4> {userCourse.subDescription} </h4>
                                <p className="mt-2 mb-0">{userCourse.description}</p>
                            </div>
                        </div>
                        <a hred="#">
                            <i className="icon-feather-more-vertical btns" />
                        </a>
                        <div className="dropdown-option-nav " uk-dropdown="pos: bottom-right ;mode : click">
                            <ul>
                                <li>
                                    <Link to="/usercourse">
                                        <i className="icon-feather-bookmark" />
                                        Các khóa học
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/quiztransaction">
                                        <i className="icon-material-outline-assessment" />
                                        Kết quả kiểm tra
                                    </Link>
                                </li>

                                {/* <li><a href="#">
                                    <i className="icon-feather-share-2" />
                                    Share With Friend </a></li> */}
                                <li>
                                    <a href="#" id="night-mode" className="btn-night-mode">
                                        <i className="icon-line-awesome-lightbulb-o" /> Chế độ tối
                                        <label className="btn-night-mode-switch">
                                            <div className="uk-switch-button" />
                                        </label>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="course-content-inner bg-white" style={{ overflowY: "auto" }}>
                    <div className="uk-width-4-4@m">
                        <ul id="contentSlide" className="uk-switcher bg-white">
                            {userCourse.courseLessons != undefined &&
                                userCourse.courseLessons.map((item, i) => (
                                    <Fragment>
                                        {item.slides.map((slide) => (
                                            <li id={"content_" + item.id + slide.id}>
                                                <div className="video-responsive">
                                                    <LightgalleryProvider
                                                        onAfterSlide={() => {
                                                            $(".side-nav").hide();
                                                            // console.log("onAfterSlide");
                                                        }}
                                                        onCloseAfter={() => {
                                                            $(".side-nav").show();
                                                        }}>
                                                        <h1 style={{ textAlign: "center" }}>{slide.name}</h1>
                                                        <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                                                            {slide.images.map((p, idx) => (
                                                                <PhotoItem index={idx} key={idx} image={Configuration.imageUrl + p.path} />
                                                            ))}
                                                        </div>
                                                    </LightgalleryProvider>
                                                </div>
                                            </li>
                                        ))}

                                        {item.videos.map((video) => (
                                            <li id={"content_" + item.id + video.id}>
                                                {/* to autoplay video uk-video="automute: true" */}
                                                <div className="embed-video">
                                                    {video.typeStorage == 4 ? (
                                                        <iframe
                                                            src={`https://www.youtube.com/embed/${video.path}?enablejsapi=1`}
                                                            frameBorder={0}
                                                            uk-video={i == 0 ? "automute: true" : ""}
                                                            allowFullScreen
                                                            uk-responsive="true"
                                                        />
                                                    ) : (
                                                        <VimeoPlayer
                                                            options={{
                                                                id: video.path.split("/")[video.path.split("/").length - 1],
                                                                width: "100%",
                                                            }}
                                                        />
                                                    )}
                                                    {/* <iframe src="https://www.youtube.com/embed/9gTw2EDkaDQ" frameBorder={0} uk-video="automute: true" allowFullScreen uk-responsive /> */}
                                                </div>
                                            </li>
                                        ))}
                                        {item.quizs.map((quiz) => (
                                            <li id={"content_" + item.id + quiz.id}>
                                                <div className="page-content">
                                                    <QuizDetail id={quiz.id} />
                                                </div>
                                            </li>
                                        ))}
                                    </Fragment>
                                ))}
                        </ul>
                        {information && (
                            <Fragment>
                                <div className="subnav bg-white">
                                    <ul
                                        className="uk-child-width-expand mb-0 uk-tab"
                                        uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
                                        uk-tab>
                                        <li className="uk-active">
                                            <a href="#" aria-expanded="false">
                                                {" "}
                                                <i className="uil-file-alt" />
                                                <span>
                                                    <b> Thông tin</b>
                                                </span>{" "}
                                            </a>
                                        </li>
                                        {/* <li className><a href="#" aria-expanded="true"> <i className="icon-feather-edit" />
                                            <span><b> Ghi chú</b></span></a>
                                        </li>
                                        {/* <li className><a href="#" aria-expanded="false"> <i className="uil-comment-lines" />
                                            <span> FAQ</span></a>
                                        </li>
                                        <li className><a href="#" aria-expanded="false"> <i className="uil-envelope-info" />
                                            <span> Announcement</span></a>
                                        </li> 
                                        <li className><a href="#" aria-expanded="false"> <i className="uil-star" />
                                            <span><b> Đánh giá</b></span></a>
                                        </li> */}
                                    </ul>
                                </div>
                                {/* video description contents */}
                                <ul id="course-intro-tab" className="uk-switcher bg-white">
                                    <li>
                                        <div className="p-lg-5 p-3" dangerouslySetInnerHTML={{ __html: description }}></div>
                                    </li>
                                    {/* course Curriculum*/}
                                    <li>
                                        <div className="p-lg-5 p-3">
                                            <h4> Course Curriculum </h4>
                                            <ul className="course-curriculum" uk-accordion="multiple: true">
                                                <li className="uk-open">
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Html Introduction <span className="duration">02:42 </span>{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        {/* course-video-list */}
                                                        <ul className="course-curriculum-list">
                                                            <li>
                                                                {" "}
                                                                What is HTML <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                What is a Web page? <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Your First Web Page{" "}
                                                                <a href="#trailer-modal" uk-toggle>
                                                                    {" "}
                                                                    Preview
                                                                </a>{" "}
                                                                <span> 23 min </span>
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Brain Streak <span> 23 min </span>{" "}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Your First webpage <span className="duration">02:12 </span>{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        {/* course-video-list */}
                                                        <ul className="course-curriculum-list">
                                                            <li>
                                                                {" "}
                                                                Headings <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Paragraphs <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Emphasis and Strong Tag{" "}
                                                                <a href="#trailer-modal" uk-toggle>
                                                                    Preview
                                                                </a>{" "}
                                                                <span> 23 min</span>
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Brain Streak <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Live Preview Feature <span> 23 min </span>{" "}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Some Special Tags <span className="duration">02:12 </span>{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        {/* course-video-list */}
                                                        <ul className="course-curriculum-list">
                                                            <li>
                                                                {" "}
                                                                The paragraph tag <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                The break tag{" "}
                                                                <a href="#trailer-modal" uk-toggle>
                                                                    {" "}
                                                                    Preview{" "}
                                                                </a>
                                                                <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Headings in HTML <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Bold, Italics Underline <span> 23 min </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        HTML Advanced level <span className="duration">02:12 </span>{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        {/* course-video-list */}
                                                        <ul className="course-curriculum-list">
                                                            <li>
                                                                {" "}
                                                                Something to Ponder<span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Tables <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                HTML Entities{" "}
                                                                <a href="#trailer-modal" uk-toggle>
                                                                    {" "}
                                                                    Preview
                                                                </a>
                                                                <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                HTML Iframes <span> 23 min </span>{" "}
                                                            </li>
                                                            <li>
                                                                {" "}
                                                                Some important things <span> 23 min </span>{" "}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* course Faq*/}
                                    <li>
                                        <div className="p-lg-5 p-3">
                                            <h4 className="my-4"> Course Faq</h4>
                                            <ul className="course-faq" uk-accordion>
                                                <li className="uk-open">
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Html Introduction{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        <p>
                                                            {" "}
                                                            The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
                                                            development environment. By the end of this guide, you`ll know how to set up and develop C++
                                                            Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new C++ class
                                                            to it, compile the project, and add an instance of a new class to your level. By the time you reach
                                                            the end of this guide, you`ll be able to see your programmed Actor floating above a table in the
                                                            level.{" "}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Your First webpage
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        <p>
                                                            {" "}
                                                            The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
                                                            development environment. By the end of this guide, you`ll know how to set up and develop C++
                                                            Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new C++ class
                                                            to it, compile the project, and add an instance of a new class to your level. By the time you reach
                                                            the end of this guide, you`ll be able to see your programmed Actor floating above a table in the
                                                            level.{" "}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Some Special Tags{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        <p>
                                                            {" "}
                                                            The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
                                                            development environment. By the end of this guide, you`ll know how to set up and develop C++
                                                            Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new C++ class
                                                            to it, compile the project, and add an instance of a new class to your level. By the time you reach
                                                            the end of this guide, you`ll be able to see your programmed Actor floating above a table in the
                                                            level.{" "}
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <a className="uk-accordion-title" href="#">
                                                        {" "}
                                                        Html Introduction{" "}
                                                    </a>
                                                    <div className="uk-accordion-content">
                                                        <p>
                                                            {" "}
                                                            The primary goal of this quick start guide is to introduce you to Unreal Engine 4`s (UE4)
                                                            development environment. By the end of this guide, you`ll know how to set up and develop C++
                                                            Projects in UE4. This guide shows you how to create a new Unreal Engine project, add a new C++ class
                                                            to it, compile the project, and add an instance of a new class to your level. By the time you reach
                                                            the end of this guide, you`ll be able to see your programmed Actor floating above a table in the
                                                            level.{" "}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* course Announcement*/}
                                </ul>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className="course-sidebar">
                <div className="course-sidebar-title">
                    <h3> Mục lục </h3>
                </div>
                <div className="course-sidebar-container" data-simplebar="true">
                    <ul className="course-video-list-section" uk-accordion="true">
                        {userCourse.courseLessons != undefined &&
                            userCourse.courseLessons.map((item, i) => (
                                <li>
                                    <a className="uk-accordion-title" href="#">
                                        {" "}
                                        {item.name}
                                    </a>
                                    <div className="uk-accordion-content">
                                        <ul
                                            className={item.locked ? "course-video-list highlight-watched" : "course-video-list"}
                                            uk-switcher={"connect: #contentSlide, animation: uk-animation-slide-right-small, uk-animation-slide-left-medium"}>
                                            {item.relations.map((relation) => {
                                                switch (relation.relationEntity) {
                                                    case 1:
                                                        var slide = item.slides.find((e) => e.id == relation.relationId);
                                                        return (
                                                            <li
                                                                id={"li_" + slide.id}
                                                                className={slide.seen ? "watched" : ""}
                                                                onClick={() => watch(item.id, slide.id, 300, "slide")}>
                                                                {" "}
                                                                <a href="#">
                                                                    {" "}
                                                                    {slide.name} <span> {slide.images.length} ảnh </span>{" "}
                                                                </a>{" "}
                                                            </li>
                                                        );
                                                    case 2:
                                                        var video = item.videos.find((e) => e.id == relation.relationId);
                                                        return (
                                                            <li
                                                                id={"li_" + video.id}
                                                                className={video.seen ? "watched" : ""}
                                                                onClick={() => watch(item.id, video.id, 300, "video")}>
                                                                {" "}
                                                                <a href="#">
                                                                    {" "}
                                                                    {video.name} <span> - phút </span>{" "}
                                                                </a>{" "}
                                                            </li>
                                                        );
                                                    case 3:
                                                        var quiz = item.quizs.find((e) => e.id == relation.relationId);
                                                        return (
                                                            <li
                                                                id={"li_" + quiz.id}
                                                                className={quiz.seen ? "watched" : ""}
                                                                onClick={() => watch(item.id, quiz.id, 1500, "quiz")}>
                                                                {" "}
                                                                <a href="#">
                                                                    {" "}
                                                                    {quiz.name}{" "}
                                                                    {userCourse.type == 16 && <b>[{quiz.totalScore * (item.conditionValue / 100)} pass]</b>}{" "}
                                                                    {quiz.scoreDisplay ? (
                                                                        <span>
                                                                            {" "}
                                                                            {quiz.score}/{quiz.totalScore} điểm{" "}
                                                                        </span>
                                                                    ) : (
                                                                        <span> {quiz.totalScore} điểm </span>
                                                                    )}
                                                                </a>{" "}
                                                            </li>
                                                        );
                                                }
                                            })}

                                            {/* {item.slides.map((slide) =>
                                            <li id={"li_" + slide.id} className={slide.seen ? "watched" : ""} onClick={() => watch(item.id, slide.id, 300, 'slide')}> <a href="#"> {slide.name} <span> {slide.images.length} ảnh </span> </a> </li>

                                        )}
                                        {item.videos.map((video) =>
                                            <li id={"li_" + video.id} className={video.seen ? "watched" : ""} onClick={() => watch(item.id, video.id, 300, 'video')}> <a href="#"> {video.name} <span> - phút </span> </a> </li>

                                        )}

                                        {item.quizs.map((quiz) =>
                                            <li id={"li_" + quiz.id} className={quiz.seen ? "watched" : ""} onClick={() => watch(item.id, quiz.id, 1500, 'quiz')}> <a href="#"> {quiz.name} {userCourse.type == 16 && <b>[{quiz.totalScore * (item.conditionValue / 100)} pass]</b>} {quiz.scoreDisplay ? <span> {quiz.score}/{quiz.totalScore} điểm </span> : <span> {quiz.totalScore} điểm </span>}</a> </li>
                                        )} */}
                                        </ul>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Index;
