/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                name: "",
                subDescription: "",
                description: "",
                files: [],
                images: [],
                authors: [],
            },
        };
    }

    componentDidMount() {
        var self = this;
        QueryGraphQL.getArticleById(this.props.match.params.id).then((data) => {
            this.setState({
                article: data.article,
            });
            console.log(this.state.article);
            self.props.setBreadcrumbAction(data.article.name);
        });
    }

    render() {
        this.props.setHeaderTranparentAction(false);
        return (
            <Fragment>
                <div className="page-content-inner">
                    <div className="container-small">
                        <div className="uk-grid-large mt-lg-3" uk-grid="true">
                            <div className="uk-width-1-3@m uk-width-1-2@s">
                                <div uk-sticky="offset: 70 ;bottom: true ;media @s">
                                    <div uk-lightbox>
                                        {this.state.article.files.length > 0 ? (
                                            <Link
                                                //to={`/bookRead/${item.path}}`} params={{ path: item.path }}
                                                to={{
                                                    pathname: "/bookRead",
                                                    state: {
                                                        path: this.state.article.files[0].path,
                                                    },
                                                }}>
                                                <img
                                                    className="uk-box-shadow-xlarge"
                                                    alt={this.state.article.name}
                                                    src={Common.getImageThumbnail(this.state.article.images, Configuration.imageFormatBook)}
                                                />
                                            </Link>
                                        ) : (
                                            <a href="javascript:;">
                                                <img
                                                    className="uk-box-shadow-xlarge"
                                                    alt={this.state.article.name}
                                                    src={Common.getImageThumbnail(this.state.article.images, Configuration.imageFormatBook)}
                                                />
                                            </a>
                                        )}

                                        {/* <a href="../assets/images/book/book-description.jpg" data-caption="Caption 2"> </a>
                                        <a href="../assets/images/book/html5.jpg" data-caption="Caption 3"> </a> */}
                                    </div>
                                    <ul className="uk-list uk-list-divider text-small mt-lg-6">
                                        <li>
                                            {" "}
                                            Tác giả{" "}
                                            {this.state.article.authors.map((item, i) => (
                                                <a href="javascript:;">{item.name}</a>
                                            ))}
                                        </li>
                                        <li>
                                            {" "}
                                            Ngày phát hành{" "}
                                            {this.state.article.createdDate ? Common.formatDateTime(this.state.article.createdDate, "dd-mm-yyyy") : ""}
                                        </li>
                                        {this.state.article.files.map((item, i) => (
                                            <li>
                                                <Link
                                                    //to={`/bookRead/${item.path}}`} params={{ path: item.path }}
                                                    to={{
                                                        pathname: "/bookRead",
                                                        state: {
                                                            path: item.path,
                                                        },
                                                    }}
                                                    className="btn btn-info"
                                                    style={{ width: "100%" }}>
                                                    <i className="icon-feather-book-open mr-2" /> Đọc {item.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>

                                    {/* <button className="button grey">
                                        <i className="icon-feather-shopping-cart mr-2" /> Mua
            </button> */}
                                </div>
                            </div>
                            <div className="uk-width-2-3@m uk-width-1-2@s">
                                <h2>{this.state.article.name}</h2>
                                <hr className="mt-0" />
                                <p dangerouslySetInnerHTML={{ __html: this.state.article.description }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setHeaderTranparentAction,
    setBreadcrumbAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
