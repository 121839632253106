import { gql } from "urql";

export const QueryCompanyAddress = gql`
  query Addresses($languageId: String!, $merchantId: String!, $tokenUser: String, $type: Int!) {
    data: useraddresses(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        type: $type
      }
    ) {
      items {
        id
        name
        companyType
        companyIndustries
      }
      message
      success
      totalCount
    }
  }
`;
export const QueryWorkExperiences = gql`
  query WorkExperiences(
    $languageId: String!
    $merchantId: String!
    $tokenUser: String
    $type: Int!
    $targetId: String!
  ) {
    data: workexperiences(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        type: $type
        targetId: $targetId
      }
    ) {
      items {
        name
        id
        functionals
        globalExperiences
        rolesResponsibility
        position
        userReferees
        fromDateTime
        toDateTime
        companies {
          id
          name
        }
      }
      message
      success
      totalCount
    }
  }
`;
export const QueryWorkExperience = gql`
  query WorkExperience($languageId: String!, $merchantId: String!, $id: String) {
    workexperience(param: { languageId: $languageId, merchantId: $merchantId, id: $id }) {
      name
      id
      functionals
      globalExperiences
      rolesResponsibility
      position
      userReferees
      fromDateTime
      toDateTime
      companies {
        id
        name
      }
    }
  }
`;
