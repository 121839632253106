/* eslint-disable */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Common from "../../../../Utils/Common";
import Swal from "sweetalert2";
import $ from "jquery";
import { updateInfoUser } from "../../../Reducers/Book";

import Configuration from "../../../../Config/Config";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

function Affiliate() {
    const user = useSelector((state) => state.Book.infoUser);
    const merchant = useSelector((state) => state.Merchant.merchant);
    //const merchant = useSelector(state => state.Book.infoUser);
    console.log(merchant);

    const [link, setLink] = useState("");
    const [linkHref, setLinkHref] = useState("");
    const [linkBitLy, setLLinkBitLy] = useState("");
    const dispatch = useDispatch();
    dispatch(setHeaderTranparentAction(false));
    const register = () => {
        let dataObject = {
            Id: user.id,
            ModifiedBy: user.id,
            ModifiedDate: Common.formatDateTime(new Date()),
        };
        Common.sendSyncCommand(
            "RegisterAffiliateUser",
            "User",
            dataObject,
            () => {
                Swal.fire("Cập nhật thành công");
                user.allType += 4;
                dispatch(updateInfoUser(user));
                window.location.reload();
            },
            () => Swal.fire("Cập nhật thất bại")
        );
    };

    const handleSubmit = (event, value) => {
        event.preventDefault();

        if (link.indexOf(window.location.hostname.split(".")[0]) < 0) {
            Swal.fire("Link chia sẻ cần thuộc domain chia sẻ.");

            return;
        }
        $("#formShareLink").show();
        var temp = link + `?refId=${user.id}`;
        setLinkHref(temp);
        $.getJSON(
            "https://api-ssl.bitly.com/v3/shorten?",
            {
                access_token: window.config.bitLy.accessToken,
                longUrl: temp,
            },
            function (response) {
                setLLinkBitLy(response.data.url);
            }
        );
    };

    const copyLink = (id) => {
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        window.document.execCommand("copy");
    };

    return (
        <div className="page-content-inner">
            <h2>Affiliate</h2>
            {merchant.affiliate != undefined && (
                <div uk-grid="true">
                    <div className="uk-width-expand@m">
                        <div className="uk-card-default rounded">
                            <div className="p-3">
                                Chương trình liên kết giới thiệu học viên. Bạn thân mến, Affiliate là chương trình được {merchant.name} thực hiện với mong muốn
                                giúp các bạn học viên có thể giới thiệu các khóa học của {merchant.name} đến những người bạn, người quen hoặc bất cứ ai khác.
                                Đồng thời, là lời cảm ơn đến các bạn vì đã giúp {merchant.name} lan tỏa giá trị đến nhiều bạn khác hơn !
                                <br />
                                Với mỗi học viên mới được giới thiệu thành công, bạn sẽ được hưởng {JSON.parse(merchant.affiliate).Commission}% hoa hồng tính
                                trên học phí mà học viên mới đóng. Hệ thống sẽ thực hiện đối soát vào ngày {JSON.parse(merchant.affiliate).ReconcileDate} háng
                                tháng
                            </div>
                            <hr className="m-0" />
                            <div className="uk-flex uk-flex-right p-4">
                                {(Configuration.userType.affiliate & user.allType) == Configuration.userType.affiliate ? (
                                    // {Configuration.userType.affiliate == 1
                                    <b className="m-auto">Bạn đã là đối tác affiliate</b>
                                ) : (
                                    <button className="btn btn-default grey m-auto" onClick={() => register()}>
                                        Tôi đồng ý với các điều kiện trên
                                    </button>
                                )}
                            </div>
                        </div>
                        {(Configuration.userType.affiliate & user.allType) == Configuration.userType.affiliate && (
                            <div className="uk-card-default rounded mt-4">
                                <div className="p-3">
                                    <h5 className="mb-0"> Tạo liên kết giới thiệu </h5>
                                </div>
                                <hr className="m-0" />
                                <form className="uk-grid-small p-4" onSubmit={handleSubmit}>
                                    {/* <p className="text-muted text-center mb-lg-5"> Fill blank to log Dashboard</p> */}
                                    <div className="uk-text-bold mb-2">Nhập link</div>
                                    <div className="uk-position-relative">
                                        <input className="uk-input uk-form-width-large" onChange={(e) => setLink(e.target.value)} type="text" />
                                    </div>
                                    <div className="uk-flex uk-flex-right p-4">
                                        <button className="btn btn-default grey">Tạo link</button>
                                    </div>
                                </form>

                                <hr className="m-0" />
                                <form className="uk-grid-small p-4" id="formShareLink" style={{ display: "none" }}>
                                    {/* <p className="text-muted text-center mb-lg-5"> Fill blank to log Dashboard</p> */}
                                    {/* <div className="uk-text-bold mb-2">Link giới thiệu website <a href="javascript:;">CopyLink</a></div>
                                <div className="uk-position-relative">
                                    <input className="uk-input uk-form-width-large" id="linkDomain" type="text" />
                                </div> */}
                                    <div className="uk-text-bold mb-2">
                                        Link đầy đủ{" "}
                                        <a href="javascript:;" onClick={() => copyLink("LinkHref")}>
                                            CopyLink
                                        </a>
                                    </div>
                                    <div className="uk-position-relative">
                                        <input className="uk-input uk-form-width-large" id="LinkHref" value={linkHref} type="text" />
                                    </div>
                                    <div className="uk-text-bold mb-2">
                                        Link rút gọn{" "}
                                        <a href="javascript:;" onClick={() => copyLink("linkBitLy")}>
                                            CopyLink
                                        </a>
                                    </div>
                                    <div className="uk-position-relative">
                                        <input className="uk-input uk-form-width-large" id="linkBitLy" value={linkBitLy} type="text" />
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Affiliate;
