import { Card, Tag } from "antd";
import { Table } from "antd";
import moment from "moment";

function EntryList(props: { entries: any[]; title?: any }) {
  const columns = [
    // {
    //   title: "Thành viên",
    //   dataIndex: "user",
    //   key: "member",
    //   render: (v: any) => {
    //     return v.name;
    //   },
    // },
    {
      title: "Ngày nộp bài",
      dataIndex: "entryDate",
      key: "entryDate",
      width: "25%",
      render: (v: any) => {
        return moment(v).format("HH:mm DD/MM/YYYY");
      },
    },
    {
      title: "Tên file",
      dataIndex: "file",
      key: "file",
      width: "45%",
      render: (v: any) => {
        return v.name;
      },
    },
    {
      title: "Điểm",
      width: "15%",
      dataIndex: "finalMark",
      key: "finalMark",
    },
    {
      title: "Kết quả",
      width: "15%",
      dataIndex: "isPassed",
      key: "isPassed",
      render: (v: any, record: any) => {
        return v ? (
          <Tag color="#87d068">{"Vượt qua"}</Tag>
        ) : record.contest && record.contest.status == "Closed" ? (
          <Tag color="#f50">{"Loại"}</Tag>
        ) : record.status && record.status == "Created" && record.contest && record.contest.status == "OnGoing" ? (
          <Tag color="#108ee9">{"Đã nộp bài"}</Tag>
        ) : (
          <Tag color="#ed9a71">{"Đang chấm"}</Tag>
        );
      },
    },
  ];

  const buildTranscriptTable = (transcripts: any) => {
    const rowSpan: any[] = [];
    let tempName = "";
    for (let index = 0; index < transcripts.length; index++) {
      const item = transcripts[index];
      if (item.criteriaCategoryName !== tempName) {
        tempName = item.criteriaCategoryName;
        const tempCount = transcripts.filter(
          (e: any) => e.criteriaCategoryName == item.criteriaCategoryName
        ).length;
        rowSpan.push({ index: index, count: tempCount });
      } else {
        rowSpan.push({ index: index, count: 0 });
      }
    }

    const columns = [
      {
        title: "Tiêu chí",
        dataIndex: "criteriaCategoryName",
        key: "criteriaCategoryName",
        colSpan: 2,
        render: (value: any, row: any, index: Number) => {
          const tempData = rowSpan && rowSpan.find((e) => e.index == index);
          const temp = tempData && tempData.count;
          return {
            children: <span>{value}</span>,
            props: {
              rowSpan: temp || 0,
            },
          };
        },
      },
      {
        title: "",
        colSpan: 0,
        dataIndex: "criteriaName",
        key: "criteriaName",
        //render: renderContent,
      },
      {
        title: "Điểm tối đa",
        dataIndex: "maxMark",
        key: "maxMark",
        //render: renderContent,
      },
      {
        title: "Điểm thực tế",
        dataIndex: "mark",
        key: "mark",
        //render: renderContent,
      },
      {
        title: "Đánh giá",
        dataIndex: "remark",
        key: "remark",
        //render: renderContent,
      },
    ];
    return (
      <div>
        <Table columns={columns} dataSource={transcripts} bordered pagination={false} />
      </div>
    );
  };

  const sortedEntries =
    props.entries &&
    props.entries.map((item) => {
      return { ...item, key: item.id };
    });

  return (
    <Card title={props.title || ""} size="small" style={{ marginBottom: "8px", width: "auto" }}>
      <Table
        dataSource={sortedEntries}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => buildTranscriptTable(record.transcripts),
          rowExpandable: (record) => record.transcripts && record.transcripts.length > 0,
        }}
        pagination={false}
      />
    </Card>
  );
}

export default EntryList;
