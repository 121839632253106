import { gql } from "urql";

export const QUERY_FORM_ATTRIBUTE_VALUES = gql`
  query QueryProducts($languageId: String!, $targetId: String!) {
    formattributevalues(param: { languageId: $languageId, targetId: $targetId }) {
      targetId
      attributeTextValues {
        id
        attributeId
        value
        imagePath
        displayOrder
        isDefaultValue
      }
      attributeIntValues {
        id
        attributeId
        value
        imagePath
        displayOrder
        isDefaultValue
      }
      attributeDecimalValues {
        id
        attributeId
        value
        imagePath
        displayOrder
        isDefaultValue
      }
      attributeDateTimeValues {
        id
        attributeId
        value
        imagePath
        displayOrder
        isDefaultValue
      }
      attributeVarcharValues {
        id
        attributeId
        value
        imagePath
        displayOrder
        isDefaultValue
      }
    }
  }
`;
