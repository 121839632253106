import React from "react";
import { styled } from "../../../../Utils/stitches.config";
import MCC from "../MiddleContent";

const FasterSpin = styled("div", {
  textAlign: "center",
  height: 200,
  ".fa-spin": {
    // animationDuration: "0.5s",
  },
});

export default function LoadingSpinner(props) {
  return (
    <FasterSpin {...props}>
      <MCC>
        <i className="fas fa-spinner-third fa-spin fa-2x" />
      </MCC>
    </FasterSpin>
  );
}
