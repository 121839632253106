/* eslint-disable */
import React, { Component } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/vi";
import { connect } from "react-redux";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {
                name: "",
                subDescription: "",
                description: "",
                files: [],
                images: [],
                authors: [],
                categories: [],
            },
        };
    }

    componentDidMount() {
        var self = this;
        QueryGraphQL.getArticleMemberById(this.props.match.params.id).then((data) => {
            this.setState({
                article: data.articlemember,
            });

            this.props.setBreadcrumbAction(data.articlemember.name);
        });
    }

    render() {
        var temp = Common.getImageThumbnail(this.state.article.images, "?mode=crop&width=1833&height=500");
        this.props.setHeaderTranparentAction(false);
        return (
            <div className="container">
                {/* <div className="blog-article-single" style={{
                    backgroundImage: `url('${temp}')`
                }} uk-img="true">
                    <span className="item-tag"> <i className="icon-feather-arrow-left"> </i> </span>
                    <div className="container-small">
                        <p className="blog-article-meta mb-3">
                            {this.state.article.categories.map((item, i) => {
                                if (i == this.state.article.categories.length - 1)
                                    return <a href="javascript:;">{item.name}</a>
                                else {
                                    return <a href="javascript:;">{item.name}&nbsp;&nbsp;|</a>
                                }




                            })}
                        </p>
                        <h1> {this.state.article.name} </h1>
                        {
                            this.state.article.authors.map((item, i) =>
                                <div className="blog-article-auther">
                                    <img src="../assets/images/avatars/avatar-3.jpg" alt="" />
                                    <span className="blog-auther-user-name"> {item.name} </span>
                                </div>
                            )}

                    </div>
                </div>
                <div className="container p-0">
                    <div className="container-xsmall blog-article-content-read">
                        <div dangerouslySetInnerHTML={{ __html: this.state.article.description }}></div>                    
                    </div>
                </div> */}

                <div uk-grid="true">
                    <div className="uk-width-4-4@m">
                        <div className="blog-post single-post">
                            {/* Blog Post Thumbnail */}
                            <div className="blog-post-thumbnail">
                                <div className="blog-post-thumbnail-inner">
                                    {this.state.article.categories.map((item, i) => {
                                        if (i == this.state.article.categories.length - 1) return <span className="blog-item-tag">{item.name}</span>;
                                        else {
                                            return <span className="blog-item-tag">{item.name}&nbsp;&nbsp;|</span>;
                                        }
                                    })}

                                    <img src={temp} alt={this.state.article.name} />
                                </div>
                            </div>
                            {/* Blog Post Content */}
                            <div className="blog-post-content">
                                <h2> {this.state.article.name} </h2>
                                <div className="mb-2">
                                    <a href="#" className="blog-post-info">
                                        <Moment locale="vi" fromNow>
                                            {this.state.article.createdDate}
                                        </Moment>
                                    </a>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.state.article.description }}></div>
                            </div>
                        </div>
                        <h4>Tác giả </h4>

                        <div className="uk-card-default rounded px-3 pb-md-3" uk-toggle="cls: uk-flex uk-flex-between@m uk-flex-middle; mode: media; media: @m">
                            {this.state.article.authors.map((item, i) => (
                                // <div className="blog-article-auther">
                                //     <img src="../assets/images/avatars/avatar-3.jpg" alt="" />
                                //     <span className="blog-auther-user-name"> {item.name} </span>
                                // </div>

                                <div className="user-details-card">
                                    <div className="user-details-card-avatar">
                                        <img src="../assets/images/avatars/avatar-3.jpg" alt="" />
                                    </div>
                                    <div className="user-details-card-name">
                                        {item.name}{" "}
                                        <span>
                                            {" "}
                                            Giảng viên{" "}
                                            <span>
                                                {" "}
                                                <Moment locale="vi" fromNow>
                                                    {item.createdDate}
                                                </Moment>{" "}
                                            </span>{" "}
                                        </span>
                                    </div>
                                </div>
                            ))}

                            <div>
                                <strong className="mx-3 uk-visible@s"> Share on </strong>
                                <a href="#" className="btn btn-facebook  rounded-circle btn-icon-only transition-3d-hover">
                                    <span className="btn-inner--icon">
                                        <i className="icon-brand-facebook-f" />
                                    </span>
                                </a>
                                <a href="#" className="btn btn-twitter rounded-circle btn-icon-only transition-3d-hover">
                                    <span className="btn-inner--icon">
                                        <i className="icon-brand-twitter" />
                                    </span>
                                </a>
                                <a href="#" className="btn btn-google-plus rounded-circle btn-icon-only transition-3d-hover">
                                    <span className="btn-inner--icon">
                                        <i className="icon-brand-google-plus-g" />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle
    setBreadcrumbAction,
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Details);
