import { useState } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";

const PdfSlider = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { path } = props;

  return (
    <div className="mt-16 text-center">
      <Document
        className=" h-3/4 shadow-md overflow-y-auto overflow-x-hidden"
        file={path}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="mt-8">
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button
          className="rounded-lg"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}>
          Previous
        </button>
        <button
          className="rounded-lg ml-6"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}>
          Next
        </button>
      </div>
    </div>
  );
};
export default PdfSlider;
