/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";
const languageId = localStorage.getItem(Config.languageId);
const merchantId = localStorage.getItem(Config.merchantId);
const userId = localStorage.getItem(Config.userId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);

export const getEvents = (keyword, sort, order, limit, offset) => {
    var query = `
        {
            events(param:{limit:${limit},offset:${offset},order:"${order}",sort:"${sort}"
            ,merchantId:"${merchantId}", keyword:"${keyword}", languageId:"${languageId}"})
            {
              items{
                id,
                name,
                startDateTime,
                endDateTime,
                eventSchedules {
                    id,
                    eventId,
                    name,
                    startDateTime,
                    endDateTime,
                }
                images {
                    id,
                    name,
                    path
                },
                published,
                createdDate
              },
              totalCount,
              success
            }
        }`;
    return Common.getDataGraphQl(query);
};
