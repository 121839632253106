/* eslint-disable */
import React from "react";

import { useSelector } from "react-redux";
import Notification from "./components/Notification";
import Email from "./components/Email";
import UserBox from "./components/UserBox";
import { Link } from "react-router-dom";

function Header() {
    const breadcrumb = useSelector((state) => state.Layout.breadcrumb);
    const headerTranparent = useSelector((state) => state.Layout.headerTranparent);
    if (localStorage.getItem(window.config.localStorageTokenId)) {
        var arrayUrl = null;
        if (window.location.href.indexOf("?") > 0) {
            arrayUrl = window.location.href.substring(0, window.location.href.indexOf("?")).split("/");
        } else {
            arrayUrl = window.location.href.split("/");
        }
        var temp = [{ name: "Home", href: "/" }];

        if (arrayUrl.length > 2) {
            temp.push({
                name: arrayUrl[3].charAt(0).toUpperCase() + arrayUrl[3].slice(1),
                href: "/" + arrayUrl[3],
            });
        }
        if (arrayUrl.length > 4) {
            temp.push({
                //name: arrayUrl[5], href: '/' + arrayUrl[3] + '/' + arrayUrl[4] + '/' + arrayUrl[5]
                name: breadcrumb,
                href: "/" + arrayUrl[3] + "/" + arrayUrl[4] + "/" + arrayUrl[5],
            });
        }
        return (
            <header
                className={headerTranparent ? "header header-transparent uk-light" : "header"}
                uk-sticky={headerTranparent ? "top:20 ; cls-active:header-sticky ; cls-inactive: uk-light" : "top:20 ; cls-active:header-sticky"}>
                <div className="container">
                    <nav uk-navbar="true">
                        {/* left Side Content */}
                        <div className="uk-navbar-left">
                            <span className="btn-mobile" uk-toggle="target: #wrapper ; cls: mobile-active" />
                            {/* logo */}
                            <Link to="/" className="logo">
                                <img src="../assets/images/logo-dark.svg" alt="" />
                                <span> Courseplus</span>
                            </Link>
                            {/* breadcrumbs */}
                            <nav id="breadcrumbs" className="breadcrumbs-tour-guide">
                                <ul>
                                    {temp.map((item, i) => (
                                        <li key={i}>
                                            <a href={i != temp.length - 1 ? item.href : "javascript:;"}> {item.name} </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        {/*  Right Side Content   */}
                        <div className="uk-navbar-right">
                            <div className="header-widget">
                                <span className="icon-feather-x icon-small uk-hidden@s" uk-toggle="target: .header-widget ; cls: is-active">
                                    {" "}
                                </span>
                                {/* {this.props.infoUser.email} */}
                                <Link
                                    to="/usercourse"
                                    className="header-widget-icon"
                                    uk-tooltip="title: Khóa học của tôi ; pos: bottom ;offset:21"
                                    title=""
                                    aria-expanded="false">
                                    <i className="uil-youtube-alt" />
                                </Link>

                                <Notification />
                                <Email />
                                <UserBox />
                            </div>
                            {/* icon search*/}
                            <a className="uk-navbar-toggle uk-hidden@s" uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="#">
                                <i className="uil-search icon-small" />
                            </a>
                            {/* User icons */}
                            <a href="#" className="uil-user icon-small uk-hidden@s" uk-toggle="target: .header-widget ; cls: is-active"></a>
                        </div>
                        {/* End Right Side Content / End */}
                    </nav>
                </div>
                {/* container  / End */}
            </header>
        );
    } else {
        return <div></div>;
    }
}

export default Header;
