import { styled } from "../../../../Utils/stitches.config";

export const Table = styled("table", {
  borderCollapse: 0,
  borderSpacing: 0,
  width: "100%",
  tableLayout: "fixed",
 
  th: {
    borderLeft: "1px solid #eee",
    borderRight: "1px solid #eee",
  },
  td: {
    padding: "20px",
    border: "1px solid #eee",
    verticalAlign: "middle",
    textAlign: "start",
    img:{
        width:"100%",
    }
  },
  tr: {
    td: {
      "&:first-child": {
        textAlign:"center",
        padding: "12px",
        fontWeight:"bold",
        textTransform:"upperCase",
        backgroundColor:"#F8F8F8"
      },
    },
  },
});
export const FooterCompareModal = styled("div", {
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "0px",
  opacity:0,
  visibility:"hidden",
  zIndex: 2002,
  display:"flex",
  alignItems:"center",
  backgroundColor: "black",
  transition: "ease-in 0.3s",
  variants: {
    visible: {
      true: {
        height: "74px",
        opacity:1,
        visibility:'visible'
      },
    },
  },
  ".footer-content__wrapper":{
    position:"absolute",
    right:14,
   
    

  }
});

export const AddToCartBtn = styled("button", {
  backgroundColor: "rgb(32 173 150 / 81%)",
  border: "none",
  borderRadius: "4px",
  color: "#fff",
  fontWeight: "bold",
  padding: "8px 0",
  width: "100%",
  "&:hover": {
    backgroundColor: "#3F3A64",
  },
});
export const CloseIconBtn = styled("div", {
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 20,
  padding: "16px",
  
  backgroundColor: "#eee",
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: "#ccc",
  },
  cursor: "pointer",
});
