import styled from "styled-components";
import size from "../../../../../../Utils/size";

export const PageTitleSection = styled.div`
  p {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;
    color: var(--primary-text-color);
    padding: 0 8px;
  }

  h1.title {
    font-size: 40px;
    line-height: 49px;
    color: var(--primary-text-color);
  }
`;
export const AnalyticsSection = styled.div`
  color: var(--primary-text-color);
  padding-left: 16px;

  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin-bottom: 16px;
  }

  .dx-table {
    display: table;
    width: 100%;
    margin-left: -16px;
    max-width: 100%;
  }

  .dx-cell {
    display: inline-table;
    width: 100%;
    margin-bottom: 16px;

    .dx-container {
      background: rgb(231, 232, 247);
      border-radius: 8px;
      text-align: center;
      padding: 20px 10px;
    }

    .dx-bg-green {
      background: rgb(225, 239, 231);

      .dx-icon {
        span {
          background: #9dd7ae;
        }
      }
    }

    .dx-bg-pink {
      background: rgb(251, 232, 230);

      .dx-icon {
        span {
          background: #efada7;
        }
      }
    }

    .dx-bg-blue {
      background: rgb(226, 247, 254);

      .dx-icon {
        span {
          background: #aee1fa;
        }
      }
    }
  }

  .dx-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--primary-text-color);
    white-space: nowrap;
  }

  .dx-analytics {
    font-size: 18px;
    line-height: 22px;
    margin-top: 16px;

    span {
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
    }
  }

  .dx-icon {
    display: inline-block;
    margin-right: 8px;
    position: relative;
    width: 32px;
    height: 32px;

    span {
      background: #b2b6e7;
      border-radius: 50%;
      padding: 6px;
      padding: 4px;
      width: 32px;
      height: 32px;
      display: inline-block;
      position: absolute;
      top: 4px;
      left: -4px;
    }

    i {
      z-index: 1;
      position: absolute;
      top: 12px;
      right: 0;
      font-size: 24px;
    }
  }

  .dx-cell {
    .dx-container {
      margin-left: 12px;
    }
  }

  ${size.md} {
    .dx-cell {
      width: 50%;
    }
  }

  ${size.xl} {
    .dx-cell {
      width: 25%;
    }
  }
`;
