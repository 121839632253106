import { USER } from "../../Config/Constants/UserConstant";
import { getUser, updateInformationUser } from "../Services/UserService";

export const getUserAction = (id) => ({
    type: USER.GET_USER,
    payload: getUser(id),
});

export const updateInformationUserAction = (values) => ({
    type: USER.GET_UPDATE_INFORMATION,
    payload: updateInformationUser(values),
});
