/* eslint-disable */
import React, { useState, useEffect } from "react";

const useSignUpForm = (defaultValues, callback, validate) => {
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [inputs, setInputs] = useState(defaultValues);
    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        setErrors(validate(inputs));
        setIsSubmitting(true);
        //callback();
    };

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    useEffect(() => {
        setInputs(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        setErrors(validate(inputs));
    }, [inputs]);

    const handleInputChange = (event) => {
        event.persist();
        setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    };
    return {
        handleSubmit,
        handleInputChange,
        errors,
        inputs,
    };
};
export default useSignUpForm;
