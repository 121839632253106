import { useEffect } from "react";
import { useDebounce, useMeasure } from "react-use";
import { UseMeasureRect } from "react-use/lib/useMeasure";

import axios from "axios";
import Configuration from "../Config/Config";
import Common from "./Common";
import { getCommonStorage } from "../Config/gql";

export function useDNStyles() {
  useEffect(dnStyles);
}

export function dnStyles() {
  const elements = document.getElementsByTagName("body");

  const getClasses = (element: HTMLElement) => {
    const classes = elements[0].getAttribute("class") || "";

    return classes
      .split(" ")
      .filter((v) => v !== "dn-styles")
      .join(" ");
  };

  if (elements.length) {
    elements[0].setAttribute("class", (getClasses(elements[0]) + " dn-styles").trim());
  }

  return () => {
    if (elements.length) {
      elements[0].setAttribute("class", getClasses(elements[0]));
    }
  };
}

export function useElementSize(fn: (rect: UseMeasureRect) => void): any {
  const [ref, refInfo] = useMeasure();

  useDebounce(() => fn(refInfo), 33, [refInfo]);

  return ref;
}

export function useSearchHandle(fn: () => any, deps: Array<any>, debounce: number = 300) {
  useDebounce(fn, debounce, deps);
}

export function exec(
  domain: string,
  name: string,
  data: {
    [key: string]: any;
  },
  includes?: Array<"tokenUser" | "merchantId" | "languageId" | "actionBy">
) {
  const { userId, tokenUser, merchantId, languageId } = getCommonStorage();

  if (includes) {
    if (includes.includes("tokenUser")) {
      data["TokenUser"] = tokenUser;
    }

    if (includes.includes("merchantId")) {
      data["MerchantId"] = merchantId;
    }

    if (includes.includes("languageId")) {
      data["LanguageId"] = languageId;
    }

    if (includes.includes("actionBy")) {
      data["ActionBy"] = userId;
    }
  }

  return axios.post(Configuration.urlApi + "/Command/SendSync", {
    CommandName: name,
    Domain: domain,
    Content: JSON.stringify(data),
    ModifiedDate: Common.getCurrentDateTime(),
    ModifiedBy: userId,
    TimeOutSecond: 30,
  });
}
