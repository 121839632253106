/* eslint-disable */
import React from "react";
import { useState, useEffect } from "react";
import { Container, Card, CardBody, Col, Row } from "reactstrap";
import Common from "../../../../Utils/Common";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersAction } from "../../../Actions/Order";
import ToolkitProvider, { ColumnToggle, Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { selectFilter, textFilter, Comparator } from "react-bootstrap-table2-filter";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

function Index() {
    const getStatus = (status) => {
        if ((status & 1048576) == 1048576) {
            return <b className="text-success">Thành công</b>;
        }

        if ((status & 32768) == 32768) {
            return <b className="text-danger">Thất bại</b>;
        }

        if ((status & 32) == 32) {
            return <b className="text-warning">Đang xử lý</b>;
        }
        if ((status & 1) == 1) {
            return <b className="text-primary">Mới tạo</b>;
        }
        return <b className="text-default">-</b>;
    };
    const selectOptions = [
        { value: 1, label: "Đã đặt hàng" },
        { value: 33, label: "Đang xử lý" },
        { value: 32801, label: "Đã hủy" },
        { value: 1048609, label: "Đã hoàn thành" },
    ];

    const { ToggleList } = ColumnToggle;
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Hiển thị {from} đến {to} trên {size} kết quả.
        </span>
    );
    const [options, getPage] = useState({ search: "", allStatus: null, offset: 0, sort: "createdDate", order: "desc", limit: 10 });

    const dispatch = useDispatch();
    dispatch(setHeaderTranparentAction(false));
    const order = useSelector((state) => state.Order.orders);

    options.totalSize = order.totalCount;
    options.showTotal = true;
    options.paginationTotalRenderer = customTotal;
    options.sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "Tất cả",
            value: order.totalCount,
        },
    ];

    useEffect(() => {
        dispatch(getOrdersAction(options.search, options.allStatus, options.sort, options.order, options.limit, options.offset));
    }, [options]);

    var handleSort = (sort, order) => {
        options.sort = sort;
        options.order = order;
    };

    var handleTableChange = (type, { page, sizePerPage, sort, order, searchText, filters }) => {
        console.log(filters);

        const offset = (page - 1) * sizePerPage;

        var allStatus = null;

        if (filters.allStatus != undefined) {
            allStatus = filters.allStatus.filterVal;
        }
        var search = "";
        if (filters.code != undefined) {
            search = filters.code.filterVal;
        }
        getPage({ search: search, allStatus: allStatus, offset: offset, limit: sizePerPage, sort: options.sort, order: options.order });
    };

    const orderLineColumns = [
        {
            dataField: "targetName",
            text: "Tên",
            headerAlign: "center",
            align: "left",
            sort: true,
            formatter: (cell, row) => <b>{cell}</b>,
        },
        {
            dataField: "originalPrice",
            text: "Giá",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },

        {
            dataField: "price",
            text: "Giá thực tế",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
        {
            dataField: "quantity",
            text: "Sl",
            align: "right",
            headerAlign: "center",
            //filter: numberFilter()
        },
        {
            dataField: "total",
            text: "Tổng tiền",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
    ];

    const expandRow = {
        renderer: (row) => (
            <Container>
                <Row>
                    <Col>
                        <p>
                            Mã đơn: <b>{row.code}</b>
                        </p>
                        <p>
                            Tổng tiền: <b>{Common.formatMoney(row.grandTotal, 0, 3)}</b>
                        </p>
                        <p>Ngày tạo: {Common.formatDateTime(row.createdDate)}</p>
                        <p>
                            {row.paymentMethodName}: {Common.formatMoney(row.paymentAmount, 0, 3)}
                        </p>
                    </Col>
                    <Col>
                        <p>{row.note}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="id"
                            wrapperClasses="table-responsive"
                            data={row.orderLines}
                            columns={orderLineColumns}
                            striped
                            hover
                            condensed
                        />
                    </Col>
                    {console.log(row.orderLines)}
                </Row>
            </Container>
        ),
    };
    const columns = [
        {
            dataField: "code",
            text: "Mã đơn hàng",
            headerAlign: "center",
            align: "center",
            sort: true,
            formatter: (cell, row) => <b>{cell}</b>,
            onSort: handleSort,
            filter: textFilter({
                //delay: 1000, // default is 500ms
                comparator: Comparator.LIKE,
                placeholder: "Nhập mã đơn hàng",
            }),
        },
        {
            dataField: "createdDate",
            text: "Ngày mua",
            align: "center",
            headerAlign: (column, colIndex) => "center",
            formatter: (cell, row) => Common.formatDateTime(cell),
            sort: true,
            onSort: handleSort,
            // filter: dateFilter({
            //     delay: 400,
            //     //placeholder: 'custom placeholder',
            //     withoutEmptyComparatorOption: true,
            //     comparators: [Comparator.EQ],
            //     // style: { display: 'inline-grid' },
            //     // className: 'custom-datefilter-class',
            //     comparatorStyle: { display: 'none' },
            //     // comparatorClassName: 'custom-comparator-class',
            //     // dateStyle: { backgroundColor: 'cadetblue', margin: '0px' },
            //     // dateClassName: 'custom-date-class'
            // })
        },
        //
        {
            dataField: "allStatus",
            text: "Trạng thái",
            align: "center",
            formatter: (cell) => getStatus(cell),
            headerAlign: "center",
            filter: selectFilter({
                placeholder: "Lựa chọn trạng thái",
                options: selectOptions,
            }),
        },
        {
            dataField: "orderLineArrStr",
            text: "Sản phẩm",
            align: "right",
            formatter: (cell, row) => row.orderLines.length,
            headerAlign: (column, colIndex) => "center",
        },
        {
            dataField: "subTotal",
            text: "Tạm tính",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
        ,
        {
            dataField: "giftCodeAmount",
            text: "Khuyến mãi",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
        {
            dataField: "grandTotal",
            text: "Tổng tiền",
            align: "right",
            formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
    ];

    const { SearchBar } = Search;

    return (
        <div className="container">
            <Card>
                <CardBody>
                    {/* <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        search
                        columnToggle
                    >{
                            props => (<div>
                                <SearchBar {...props.searchProps} />
                                <hr />
                                <ToggleList {...props.columnToggleProps} />
                                <hr />
                                <BootstrapTable
                                    {...props.baseProps}
                                    wrapperClasses="table-responsive"
                                    remote
                                    keyField="id"
                                    data={order.items}
                                    columns={columns}
                                    pagination={paginationFactory(options)}
                                    onTableChange={handleTableChange}
                                    striped
                                    hover
                                    condensed
                                    filter={filterFactory()}
                                />
                            </div>)
                        }

                    </ToolkitProvider> */}
                    {order.items ? (
                        <ToolkitProvider keyField="id" columns={columns} search columnToggle>
                            {(props) => (
                                <div>
                                    Lựa chọn cột hiển thị: <ToggleList {...props.columnToggleProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        wrapperClasses="table-responsive"
                                        remote
                                        keyField="id"
                                        data={order.items}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        onTableChange={handleTableChange}
                                        striped
                                        hover
                                        condensed
                                        filter={filterFactory()}
                                        expandRow={expandRow}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <h3 className="kt-portlet__head-title">Hiện chưa có đơn hàng nào</h3>
                    )}
                </CardBody>
            </Card>
        </div>
    );
}

export default Index;
