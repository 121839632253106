import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  Row,
  Empty,
  DatePicker,
  Checkbox,
  Modal,
  Divider,
  Typography,
  Popover,
} from "antd";
import { BasePage } from "../../../../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { PlusOutlined } from "@ant-design/icons";
import DNModal from "../../../../DnStyles/Modal";

/* import {getExpertise} from "./data" */
import _ from "lodash";
import moment from "moment";
import { v4 } from "uuid";
import { getOrganization } from "./data";
/* import { QueryBoardExperiences } from "./gql"; */
import { exec } from "../../../../../../../Utils/dn";
import { getCommonStorage } from "../../../../../../../Config/gql";

import { useQuery } from "urql";
import { useTranslation } from "react-i18next";
import { QueryMemberShip, QueryMemberShips } from "./gql";
import { ContentPopupLanguage } from "../../../../../ComponentCommon/PopoverContentLanguage";

interface IFormModalProps {
  state: {
    isEdit: boolean;
    isModalVisible: boolean;
    loading: boolean;
  };
  setState: any;
  editLanguageId: string;
  editId: string;
  refetchMemberships: any;
}

const getMemberShip = (num: number) => {
  return getOrganization().find((value: any) => value.value === num);
};

const FormModal = ({
  state,
  setState,
  editId,
  refetchMemberships,
  editLanguageId,
}: IFormModalProps) => {
  const { t } = useTranslation();
  const [id] = useState(v4());
  const [form] = Form.useForm();
  const [isEndDateDisable, setIsEndDateDisable] = useState(false);
  const [startDate, setStartDate] = useState();

  const [MembershipData] = useQuery({
    query: QueryMemberShip,
    variables: (() => {
      const { merchantId } = getCommonStorage();

      return {
        languageId: editLanguageId,
        merchantId: merchantId,

        id: editId,
      };
    })(),
    requestPolicy: "network-only",
  });
  const _onFinish = (membership: any) => {
    const { userId, languageId } = getCommonStorage();
    setState({
      loading: true,
    });

    exec(
      "ProfessionalMembership",
      state.isEdit ? "UpdateInformationProfessionalMembership" : "CreateProfessionalMembership",
      {
        Id: state.isEdit ? editId : v4(),
        Type: membership.type,
        Institute: membership.institute,
        UserId: userId,
        LanguageId: editId ? editLanguageId : languageId,
        From: membership.startDate.format("YYYY-MM-DD"),
        To: isEndDateDisable ? null : membership.endDate.format("YYYY-MM-DD"),
        MemberShipNo: membership.membershipNo,
      },
      ["actionBy", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchMemberships();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };
  useEffect(() => {
    if (MembershipData.data?.professionalMembership) {
      console.log("run 102");
      const memberShip = MembershipData.data.professionalMembership;
      setIsEndDateDisable(memberShip.to ? false : true);
      form.setFieldsValue({
        type: memberShip.type,
        institute: getMemberShip(memberShip.institute)?.value,
        startDate: moment(memberShip.from, "YYYY-MM-DD"),
        endDate: memberShip.to ? moment(memberShip.to, "YYYY-MM-DD") : null,
        membershipNo: memberShip.membershipNo,
      });
    }
  }, [MembershipData.data?.professionalMembership]);

  return (
    <DNModal
      afterClose={() => {
        if (state.isEdit) {
          form.resetFields();
          setIsEndDateDisable(false);
        }
      }}
      title={state.isEdit ? t("Edit") : t("Add")}
      visible={state.isModalVisible}
      onCancel={() => {
        setState({ isModalVisible: false });
      }}
      okButtonProps={{
        form: id,
        htmlType: "submit",
      }}
      confirmLoading={state.loading}>
      <Form form={form} onFinish={_onFinish} id={id} layout={"vertical"}>
        <Form.Item name={"institute"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Select placeholder={t("institute.membership")}>
            {getOrganization().map((v: any) => (
              <Select.Option key={v.value} value={v.value}>
                {v.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={"type"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Membership Type")} />
        </Form.Item>
        <Form.Item name={"membershipNo"} rules={[{ required: true, message: "Vui lòng nhập" }]}>
          <Input placeholder={t("Membership No")} />
        </Form.Item>
        <Row gutter={16}>
          <Col span={7}>
            <Form.Item rules={[{ required: true, message: "Vui lòng chọn" }]} name="startDate">
              <DatePicker
                onChange={(e: any) => {
                  form.setFieldsValue({
                    endDate: e,
                  });
                  setStartDate(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              rules={[
                {
                  required: isEndDateDisable ? false : true,
                  message: isEndDateDisable ? "" : "Vui lòng chọn",
                },
              ]}
              name="endDate">
              <DatePicker
                disabledDate={(current) => {
                  return current < startDate!;
                }}
                disabled={isEndDateDisable ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Checkbox
              style={{ marginTop: "4px" }}
              checked={isEndDateDisable}
              onChange={() => setIsEndDateDisable(!isEndDateDisable)}
            />
            <Typography.Text className="ml-1">{t("Current")}</Typography.Text>
          </Col>
        </Row>
      </Form>
    </DNModal>
  );
};

const Education = () => {
  const { t } = useTranslation();

  const [editId, setEditId] = useState("");

  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });
  const [editLanguageId, setEditLanguageId] = useState("");

  const [MembershipDatas, refetchMemberships] = useQuery({
    query: QueryMemberShips,
    variables: (() => {
      const { tokenUser, languageId, merchantId, userId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,

        targetId: userId,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onClickEdit = (memberShip: any, lang: string) => {
    setEditId(memberShip.id);
    setEditLanguageId(lang);

    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa  khỏi danh sách?",
      onOk() {
        exec(
          "ProfessionalMembership",
          "DeleteProfessionalMembership",
          {
            Id: id,
          },
          ["actionBy"]
        ).finally(refetchMemberships);
      },
      okText: t("Confirm"),
      cancelText: t("Back"),
      centered: true,
    });
  };

  const memberShips = MembershipDatas.data?.data?.items || [];

  return (
    <Fragment>
      <BasePage title={t("Professional Membership")} hideTitle={true}>
        <Card
          title={t("Professional Membership")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                setEditId("");

                setState({ isModalVisible: true, isEdit: false });
              }}>
              {t("Add")}
            </Button>
          }
          loading={MembershipDatas.fetching}>
          {memberShips.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Professional Membership")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {memberShips.length > 0 &&
            memberShips.map((memberShip: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12}>
                      <Col span={4} className={"text-left"}>
                        {t("institute.membership")}:
                      </Col>
                      <Col span={20}>{getMemberShip(memberShip.institute)?.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Membership Type")}:
                      </Col>
                      <Col span={20}>{memberShip.type}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Membership No")}:
                      </Col>
                      <Col span={20}>{memberShip.membershipNo}</Col>
                    </Row>

                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("year.Join")}
                      </Col>
                      <Col span={20}>
                        {moment(memberShip.from).format("YYYY-MM-DD")} --
                        {memberShip.to ? moment(memberShip.to).format("YYYY-MM-DD") : t("Current")}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Popover
                        placement="bottom"
                        trigger="hover"
                        content={<ContentPopupLanguage edit={_onClickEdit} data={memberShip} />}>
                        <Button type={"link"}>{t("Edit")}</Button>
                      </Popover>
                      <Button type={"link"} danger={true} onClick={() => _remove(memberShip.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
        {state.isModalVisible && (
          <FormModal
            editLanguageId={editLanguageId}
            editId={editId}
            setState={setState}
            state={state}
            refetchMemberships={refetchMemberships}
          />
        )}
      </BasePage>
    </Fragment>
  );
};

export default Education;
