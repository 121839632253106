/* eslint-disable */
import React, { useState, useEffect } from "react";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
//import { getProductsAction } from "../../../Actions/Product";
import { getProducts } from "../../../Services/ProductService";
import Paginator from "react-hooks-paginator";
import { setHeaderTranparentAction } from "../../../Actions/Layout";
// import 'bootstrap/dist/css/bootstrap.min.css';

function Index() {
    const [option, getPage] = useState({
        search: "",
        offset: 0,
        sort: "createdDate",
        order: "desc",
        limit: 16,
        type: Configuration.productType.course,
    });
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const dispatch = useDispatch();
    dispatch(setHeaderTranparentAction(false));
    const [products, setProducts] = useState();
    useEffect(() => {
        getProducts(option.search, option.sort, option.order, option.limit, option.offset, option.type).then((res) => setProducts(res.products.items));
    }, [option]);

    useEffect(() => {
        if (products != undefined) {
            setTotalRecords(products.totalCount);
        }
    }, [products]);

    useEffect(() => {
        var offset = (currentPage - 1) * option.limit;
        getPage({
            search: "",
            offset: offset,
            sort: "createdDate",
            order: "desc",
            limit: option.limit,
            type: Configuration.productType.course,
        });
    }, [currentPage]);

    return (
        <div className="container">
            <h4> Các khóa học</h4>
            <div className="section-small">
                <div className="uk-child-width-1-4@m uk-child-width-1-3@s course-card-grid" uk-grid="true">
                    {products != undefined &&
                        products.map((item, i) => (
                            <div key={i}>
                                <Link to={`/product/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                    <div className="course-card">
                                        <div className="course-card-thumbnail ">
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                            {/* <span className="play-button-trigger" /> */}
                                        </div>
                                        <div className="course-card-body">
                                            <div className="course-card-info">
                                                <div>
                                                    <span className="catagroy">{Common.rewriteUrl(item.name)}</span>
                                                </div>
                                                <div>
                                                    <i className="icon-feather-bookmark icon-small" />
                                                </div>
                                            </div>
                                            <h4>{item.name}</h4>
                                            <p>{item.subDescription}</p>
                                            {/* <div className="course-card-footer">
                                                    <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                    <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                </div> */}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
            <Paginator
                pageContainerClass="uk-pagination uk-flex-center uk-margin-medium"
                pageActiveClass="uk-active"
                pageItemClass=""
                pageLinkClass=""
                totalRecords={totalRecords || 0}
                pageLimit={option.limit}
                pageNeighbours={1}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
}
export default Index;
