import { styled, size } from "../../../../../../Utils/stitches.config";

export const FetchingItemsBox = styled("div", {
  ".ant-skeleton-content .ant-skeleton-title": {
    marginTop: 0,
  },

  variants: {
    height: {
      default: {
        ".d-skeleton-image .ant-skeleton-title": {
          height: "150px !important",
        },
      },
      high: {
        ".d-skeleton-image .ant-skeleton-title": {
          height: "250px !important",
        },
      },
    },
  },
});

FetchingItemsBox.defaultProps = {
  height: "default",
};

export const EmptyBox = styled("div", {
  textAlign: "center",
  p: {
    fontSize: 18,
    lineHeight: "22px",
    color: "var(--primary-text-color)",
    marginBottom: 25,
  },
  button: {
    background: "var(--primary-color)",
    color: "#FFFFFF",
    fontSize: 16,
    lineHeight: "20px",
    padding: "17px 45px",
    border: "1px solid var(--primary-color)",
    borderRadius: 8,
    ":hover": {
      background: "#EA8024",
      borderColor: "#EA8024",
    },
  },
});
export const ItemBox = styled("div", {});
export const CourseProgressBar = styled("div", {
  height: 6,
  width: "100%",
  borderRadius: 3,
  background: "#EFF0F1",
  marginBottom: 15,
  position: "relative",
  label: {
    position: "absolute",
    color: "#999FAE",
    fontSize: 14,
    lineHeight: "17px",
    top: -25,
    whiteSpace: "nowrap",
    span: {
      color: "var(--primary-text-color)",
      fontWeight: 600,
    },
  },
});
export const CourseProgressBarValue = styled("div", {
  height: 6,
  width: "100%",
  borderRadius: 3,
  background: "#EA8024",
  position: "absolute",
  top: 0,
  left: 0,
});
export const TimeLeft = styled("div", {
  display: "table",
  marginTop: 46,
  width: "100%",
  label: {
    display: "table-cell",
    fontSize: 16,
    lineHeight: "22px",
    whiteSpace: "nowrap",
    paddingRight: 12,
    span: {
      fontWeight: 600,
      color: "var(--primary-text-color)",
    },
    verticalAlign: "middle",
  },
});
export const TimeLeftBar = styled("div", {
  display: "table-cell",
  width: "100%",
  verticalAlign: "middle",
});
export const TimeLeftBarValue = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "50%",
  height: 6,
  borderRadius: 3,
  background: "#EA8024",
});
export const TimeLeftBarBackground = styled("div", {
  height: 6,
  borderRadius: 3,
  background: "#EFF0F1",
  width: "100%",
  display: "inline-block",
  overflow: "hidden",
  position: "relative",
});
export const SideWidgetDescription = styled("div", {
  fontSize: 16,
  lineHeight: "19px",
  marginTop: -2,
  marginBottom: 24,
  color: "var(--primary-text-color)",
  span: {
    fontWeight: 600,
  },
});
export const SideSortSection = styled("div", {
  display: "inline-block",
  textAlign: "left",
  whiteSpace: "nowrap",
  fontSize: 16,
  color: "var(--primary-text-color)",
  width: "100%",
  marginBottom: 20,
  [size.md]: {
    width: "unset",
    marginBottom: "unset",
  },
  ".ant-select": {
    fontSize: 16,
  },
  ".dx-label": {
    color: "#999FAE",
  },
  ".ant-select-arrow": {
    color: "var(--primary-text-color)",
  },
  ".ant-select-selection-item": {
    color: "var(--primary-text-color)",
    fontWeight: "bold",
  },
});
