import { COURSE } from "../../Config/Constants/CourseConstant";
import { getCourse, getUserCourse, seenLessonItem, getUserCourses, getUserCoursePreview, unlockCourseLessonUser } from "../Services/CourseService";

export const getCourseAction = () => ({
    type: COURSE.GET_COURSE,
    payload: getCourse(),
});

export const getUserCoursesAction = (offset, limit) => ({
    type: COURSE.GET_USER_COURSES,
    payload: getUserCourses(offset, limit),
});

export const getUserCourseAction = (id) => ({
    type: COURSE.GET_USER_COURSE,
    payload: getUserCourse(id),
});

export const getUserCoursePreviewAction = (id) => ({
    type: COURSE.GET_USER_COURSE_REVIEW,
    payload: getUserCoursePreview(id),
});

export const seenLessonItemAction = (relationId, relationEntity) => ({
    type: COURSE.GET_SEEN_LESSON_ITEM,
    payload: seenLessonItem(relationId, relationEntity),
});

export const unlockCourseLessonUserAction = (courseLessonId) => ({
    type: COURSE.UNLOCK_LESSON_USER,
    payload: unlockCourseLessonUser(courseLessonId),
});
