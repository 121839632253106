/* eslint-disable */
import { QUIZ } from "../../Config/Constants/QuizConstant";
import { getQuizTransactions, getQuizResultDetail } from "../Services/QuizService";

export const getQuizTransactionsAction = (keyword, limit, offset, order, sort, status) => ({
    type: QUIZ.GET_QUIZ_TRANSACTION,
    payload: getQuizTransactions(keyword, limit, offset, order, sort, status),
});

export const setScoreAction = (score) => ({
    type: QUIZ.SET_SCORE,
    score,
});

export const getMemberQuizDetailAction = (quizId) => ({
    type: QUIZ.GET_MEMBER_QUIZ_DETAIL,
    payload: getQuizResultDetail(quizId),
});

export const selectQuizDetail = (selectedQuiz) => ({
    type: QUIZ.SELECT_MEMBER_QUIZ_DETAIL,
    payload: selectedQuiz,
});

export const clearSelectedQuizDetail = () => ({
    type: QUIZ.CLEAR_MEMBER_QUIZ_DETAIL,
    payload: "",
});
