/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Common from "../../../../Utils/Common";
import Swal from "sweetalert2";
import { connect } from "react-redux";

class SubcribeCategory extends Component {
    constructor() {
        super();
        this.state = {
            categories: [],
            pickedCategories: [],
        };
    }
    componentDidMount = () => {
        this.load();
    };
    load = () => {
        QueryGraphQL.getAllSubcriberCategoryByType(2).then((data) => {
            if (data.allsubcribecategories != null && data.allsubcribecategories.items.length) {
                this.setState({
                    categories: data.allsubcribecategories.items,
                });
                console.log(this.state.categories);
                QueryGraphQL.getSubcribeCategories().then((data) => {
                    if (data.subcribecategories.items.length) {
                        console.log(data.subcribecategories.items);
                        this.setState(
                            {
                                pickedCategories: data.subcribecategories.items.map((e) => ({ value: e.id, label: e.name })),
                            },
                            function () {
                                console.log(this.state.pickedCategories);
                            }
                        );
                    }
                });
            }
        });
    };

    submitFormHandler = (event, value) => {
        //alert('A name was submitted: ' + value);
        console.log(this.state.pickedCategories);
        event.preventDefault();
        var data = {
            Id: this.props.infoUser.id,
            CategoryIds: this.state.pickedCategories != undefined ? this.state.pickedCategories.map((e) => e.value) : null,
        };
        Common.sendSyncCommand(
            "SubcribeCategoriesArticleByUser",
            "User",
            data,
            () => {
                Swal.fire("Thông tin chuyên mục đã được cập nhật.");
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            },
            () => {
                Swal.fire("Thông tin chuyên mục cập nhật thất bại!");
            }
        );
    };

    render() {
        return (
            <div className="uk-card-default rounded">
                <div className="p-4 text-center">
                    <h4 className="uk-text-bold"> Theo dõi </h4>
                    <p>
                        {" "}
                        Các chuyên mục bạn đang theo dõi xem tại{" "}
                        <Link to="/blog">
                            <b>đây</b>
                        </Link>
                    </p>
                    <form className="mt-3" onSubmit={this.submitFormHandler}>
                        <Select
                            isMulti
                            name="authors"
                            options={this.state.categories.map((category) => ({
                                value: category.id,
                                label: category.name,
                            }))}
                            value={this.state.pickedCategories}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(categories) => {
                                return this.setState({
                                    pickedCategories: categories,
                                });
                            }}
                        />
                        <input type="submit" value="Xác nhận" className="btn btn-default  btn-block mt-3" />
                    </form>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        infoUser: state.Book.infoUser,
    };
}
const mapDispatchToProps = {
    // loadAllArticle,
    // loadAllCategoryArticle,
    // updateInfoUser,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(SubcribeCategory);
