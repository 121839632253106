import { Fragment, useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Empty, Modal, Divider } from "antd";
import { BasePage } from "../../../../../ComponentCommon/BasePage";
import { useSetState } from "react-use";
import { useTranslation } from "react-i18next";
import PlusOutlined from "@ant-design/icons";
import DNModal from "../../../../DnStyles/Modal";

import PlaceSearch from "./PlaceSearch";
import _ from "lodash";

import { v4 } from "uuid";

import { QueryAddress } from "./gql";
import { exec } from "../../../../../../../Utils/dn";
import { getCommonStorage } from "../../../../../../../Config/gql";

import { useQuery } from "urql";

import axios from "axios";
import Configuration from "../../../../../../../Config/Config";

type IFormData = {
  name: string;
  mobile: string;
  detailAddress: string;
};
interface IAdress {
  id: string;
  name: string;
  detailAddress: string;
  latitude: number;
  longitude: number;
  mobile: string;
}
const Contact = () => {
  const {t} = useTranslation()
  const [form] = Form.useForm();
  const [id] = useState(v4());
  const [editId, setEditId] = useState<string>("");
  const [location, setLocation] = useState<{
    lat: number | undefined;
    lng: number | undefined;
  }>({
    lat: undefined,
    lng: undefined,
  });

  const [state, setState] = useSetState<{
    loading: boolean;
    isModalVisible: boolean;
    isEdit: boolean;
    btnSetDefaultLoading: string | undefined;
  }>({
    loading: false,
    isModalVisible: false,
    isEdit: false,
    btnSetDefaultLoading: undefined,
  });

  const [queryAddress, refetchAddresses] = useQuery({
    query: QueryAddress,
    variables: (() => {
      const { tokenUser, languageId, merchantId } = getCommonStorage();

      return {
        tokenUser: tokenUser,
        languageId: languageId,
        merchantId: merchantId,
        type: 1 << 3,
      };
    })(),
    requestPolicy: "network-only",
  });

  const _onFinish = (address: IFormData) => {
    setState({
      loading: true,
    });

    exec(
      "address",
      state.isEdit ? "UpdateUserAddress" : "CreateUserAddress",
      {
        Id: state.isEdit ? editId : id,
        Name: address.name,
        Latitude: location.lat,
        Longitude: location.lng,
        DetailAddress: address.detailAddress,
        Mobile: address.mobile,
      },
      ["actionBy", "languageId", "merchantId"]
    )
      .then(({ data }) => {
        if (data.Success) {
          refetchAddresses();
          setState({ isModalVisible: false });
        } else {
          Modal.error({
            title: data.Message,
            centered: true,
          });
        }
      })
      .finally(() => {
        setState({ loading: false });
      });
  };
  const handleSetData = async (lat: number, lng: number) => {
    try {
      const { data } = await axios.get("https://rsapi.goong.io/Geocode", {
        params: {
          latlng: `${lat},${lng}`,
          api_key: Configuration.GOONG_MAP_KEY,
        },
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const _onClickEdit = (address: IAdress) => {
    setEditId(address.id);

    setLocation({
      lat: address.latitude,
      lng: address.longitude,
    });
    handleSetData(address.latitude, address.longitude).then((response) =>
      form.setFieldsValue({ addresses: response.results[0].formatted_address })
    );
    form.setFieldsValue({
      name: address.name,
      mobile: address.mobile,
      detailAddress: address.detailAddress,
    });
    setState({ isModalVisible: true, isEdit: true });
  };
  const _remove = (id: string) => {
    Modal.confirm({
      title: "Xác nhận xóa địa chỉ này khỏi danh sách?",
      onOk() {
        exec(
          "address",
          "DeleteAddresses",
          {
            Ids: [id],
            Id: v4(),
          },
          ["actionBy"]
        ).finally(refetchAddresses);
      },
      okText: "Đồng ý",
      cancelText: "Trở lại",
      centered: true,
    });
  };
  useEffect(() => {}, [location]);
  const addresses = queryAddress.data?.data?.items || [];

  return (
    <Fragment>
      <BasePage title={t("Contact Information")} hideTitle={true}>
        <Card
          title={t("Contact Information")}
          extra={
            <Button
              type={"primary"}
              icon={<PlusOutlined />}
              onClick={() => {
                form.resetFields();
                setLocation({
                  lat: undefined,
                  lng: undefined,
                });
                setState({ isModalVisible: true, isEdit: false });
              }}>
                  {t("Add")}
            </Button>
          }
          loading={queryAddress.fetching}>
          {addresses.length === 0 && (
            <Empty
              description={
                <span>
                  {t("No Contact Information")}
                  <a
                    href={"#"}
                    style={{ color: "var(--primary-color)", textDecoration: "underline" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({ isModalVisible: true, isEdit: false });
                    }}>
                    {t("Add")}
                  </a>
                </span>
              }
            />
          )}
          {addresses.length > 0 &&
            addresses.map((address: any, i: number) => (
              <Fragment key={i}>
                {i > 0 && <Divider />}
                <Row gutter={12}>
                  <Col span={18}>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Name")}:
                      </Col>
                      <Col span={20}>{address.name}</Col>
                    </Row>
                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Detail Address")}
                      </Col>
                      <Col span={20}>{address.detailAddress}</Col>
                    </Row>

                    <Row gutter={12} style={{ marginTop: "1em" }}>
                      <Col span={4} className={"text-left"}>
                        {t("Phone Number")}
                      </Col>
                      <Col span={20}>{address.mobile}</Col>
                    </Row>
                  </Col>
                  <Col span={6} style={{ textAlign: "right" }}>
                    <div>
                      <Button type={"link"} onClick={() => _onClickEdit(address)}>
                        {t("Edit")}
                      </Button>
                      <Button type={"link"} danger={true} onClick={() => _remove(address.id)}>
                        {t("Delete")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Fragment>
            ))}
        </Card>
      </BasePage>
      <DNModal
        title={state.isEdit ? t("Edit") : t("Contact")}
        visible={state.isModalVisible}
        onCancel={() => {
          setState({ isModalVisible: false });
        }}
        okButtonProps={{
          form: id,
          htmlType: "submit",
        }}
        confirmLoading={state.loading}>
        <Form form={form} onFinish={_onFinish} id={id} layout={"vertical"}>
          <Form.Item rules={[{ required: true, message: "Vui lòng nhập" }]} name="addresses">
            <PlaceSearch
              placeholder={t("Address")}
              onPlace={(e: any) => {
                setLocation({
                  lat: e.geometry.location.lat,
                  lng: e.geometry.location.lng,
                });
              }}
            />
          </Form.Item>
          <Form.Item name="detailAddress" rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Input placeholder={t("Detail Address")} />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true, message: "Vui lòng nhập" }]}>
            <Input placeholder={t("Full name")} />
          </Form.Item>
          <Form.Item
            name={"mobile"}
            rules={[
              {
                required: true,
                pattern: new RegExp("(84|0[3|5|7|8|9])+([0-9]{8})"),
                message: "Số điện thoại không đúng định dạng , Ví dụ : 0346556026",
              },
            ]}>
            <Input type="number" placeholder={t("Phone Number")} />
          </Form.Item>
        </Form>
      </DNModal>
    </Fragment>
  );
};

export default Contact;
