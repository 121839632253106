export default function validate(values) {
    let errors = {};
    if (!values.firstName) {
        errors.firstName = "Họ không được để trống!";
    }
    if (!values.lastName) {
        errors.lastName = "Tên không được bỏ trống!";
    }
    if (!values.mobile) {
        errors.mobile = "Số điện thoại không được bỏ trống!";
    } else if (!/^\d{10}$/.test(values.mobile)) {
        errors.mobile = "Số điện thoại không đúng định dạng!";
    }
    // else if (!/\S+@\S+\.\S+/.test(values.firstName)) {
    //     errors.firstName = 'Email address is invalid';
    // }
    // if (!values.password) {
    //     errors.password = 'Password is required';
    // } else if (values.password.length < 8) {
    //     errors.password = 'Password must be 8 or more characters';
    // }
    return errors;
}
