import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Configuration from "../../../../../Config/Config";
import Common from "../../../../../Utils/Common";

function Footer() {
  const {t}=useTranslation()
  const merchant = window.Merchant.data;
  var footerColumn1 = '';
  var footerColumn2 = '';
  var footerColumn3 = '';
  var footerColumn4 = '';
  
  var pageId = '';
  if(merchant.izziMember != undefined){
      var temp = JSON.parse(merchant.izziMember);      
      footerColumn1 = temp.FooterColumn1;
      footerColumn2 = temp.FooterColumn2;
      footerColumn3 = temp.FooterColumn3;
      footerColumn4 = temp.FooterColumn4;
              
  }
  if(merchant.messenger != undefined){
    var temp = JSON.parse(merchant.messenger);
    pageId = temp.PageId;
  }
  let address =
    "Công ty cổ phần siêu thị và xuất nhập khẩu thương mại Việt Nam<br> Mã số thuế: 0101286228 - Sở KH&ĐT Hà Nội Cấp  <br>Trung tâm thương mại Tràng Tiền, 24 Hai Bà Trưng, Tràng Tiền, Hoàn Kiếm, Thành phố Hà Nội";
  let tel = "18007295";
  let email = "contact@conexmart.com.vn";

  if (merchant.code.toLowerCase() === "geetonline") {
    address = "20th Floor, Thaiholdings Tower, 210 Tran Quang Khai, Hoan Kiem, Hanoi";
    email = "contact@geetonline.vn";
    tel = "+299 889 9992";
  }

  const logoDark = JSON.parse(merchant.configuration)["DarkLogoPath"];
  const logo = Common.getImageThumbnail(merchant.images, "?mode=crop&width=180");

  return (
    <React.Fragment>
      <div className="footer-section section" style={{ marginBottom: -60 }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12 max-mb-50">
              <div className="footer-widget">
                <div className="footer-widget-content mb-20">
                  <img src={(logoDark != undefined && logoDark != "") ? logoDark  + "?mode=crop&width=180": logo} alt={logo} />  
                </div>
                <div dangerouslySetInnerHTML={{ __html: footerColumn1 }} />
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12 max-mb-50">
              <div className="footer-widget">
                <h4 className="footer-widget-title"> {t("About")} {merchant.code.toUpperCase()}</h4>
                <div className="footer-widget-content">
                <div dangerouslySetInnerHTML={{ __html: footerColumn2 }} />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12 max-mb-50">
              <div className="footer-widget">
                <h4 className="footer-widget-title">{t("Introduction")}</h4>
                <div className="footer-widget-content">
                <div dangerouslySetInnerHTML={{ __html: footerColumn3 }} />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12 max-mb-50">
              <div className="footer-widget">
                <h4 className="footer-widget-title">{t("Contact Information")}</h4>
                <div className="footer-widget-content">
                  <div className="content">
                  <div dangerouslySetInnerHTML={{ __html: footerColumn4 }} />
                  </div>
                  <div className="footer-social-inline">
                  <a href={"https://www.facebook.com/" + pageId} binding-btnhref="" rel="noreferrer" target="_blank">
                  <i className="fab fa-facebook-square" />
                    </a>
                    {/* <Link to={"/"} onClick={(e) => e.preventDefault()}>
                      <i className="fab fa-facebook-square" />
                    </Link> */}
                    {/* <Link to={"/"} onClick={(e) => e.preventDefault()}>
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link to={"/"} onClick={(e) => e.preventDefault()}>
                      <i className="fab fa-instagram" />
                    </Link>
                    <Link to={"/"} onClick={(e) => e.preventDefault()}>
                      <i className="fab fa-linkedin" />
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row max-mt-20">
            <div className="col">
              <p className="copyright">
                &copy; {new Date().getFullYear()} <a href={window.Merchant.urlReturn}>{merchant.code.toUpperCase()}</a>. All
                rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
