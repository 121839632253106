/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";

export const getUserSchedules = () => {
    const merchantId = localStorage.getItem(Config.merchantId);
    const userId = localStorage.getItem(Config.userId);
    const tokenId = localStorage.getItem(window.config.localStorageTokenId);

    var query = `
    {
      userschedules(param:{          
          merchantId:"${merchantId}"
        ,tokenUser:"${tokenId}"
       })
        {
              items {
                id,
                name,
                createdBy,
                targetId,
                allStatus,
                triggerDateTime,
                createdDate                
              },
             
              totalCount
        }
      }
    `;
    return Common.getDataGraphQl(query);
};
