/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import $ from "jquery";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setHeaderTranparentAction } from "../../../Actions/Layout";

class Index extends Component {
    state = {
        latestArticle: [],
        allArticleByCate: [],
        popularArticle: [],
        toplatestArticle: 9,

        offset: 0,
        totalItem: null,
        itemPerPage: 10,
        arrayItemPerPage: [],
        currentCategory: "",
        categories: [],
    };
    componentDidMount() {
        console.log(this.props.allCategoryArticle);
        QueryGraphQL.pagingArticles(5, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
            if (data.articles.items.length) {
                this.setState({
                    totalItem: data.articles.totalCount,
                    arrayItemPerPage: data.articles.items,
                    popularArticle: data.articles.items.slice(0, 3),
                });
            }
        });

        QueryGraphQL.getNewestArticle(5, this.state.toplatestArticle).then((data) => {
            this.setState({
                latestArticle: data.articles.items,
            });
        });

        QueryGraphQL.getCategoryByType(8).then((data) => {
            if (data.categories.items.length) {
                this.setState({
                    categories: data.categories.items,
                    currentCategory: data.categories.items.slice(0, 1)[0].id,
                });
                QueryGraphQL.pagingArticles(5, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            totalItem: data.articles.totalCount,
                            arrayItemPerPage: data.articles.items,
                            popularArticle: data.articles.items.slice(0, 3),
                        });
                    }
                });
            }
        });
    }
    paginate = (indexPage) => {
        this.setState(
            {
                offset: this.state.itemPerPage * indexPage,
            },
            () => {
                QueryGraphQL.pagingArticles(5, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            arrayItemPerPage: data.articles.items,
                            totalItem: data.articles.totalCount,
                        });
                    }
                });
            }
        );
        $.each($(".uk-pagination li"), function (i, val) {
            $(this).removeClass("uk-active");
        });
        $("#page" + indexPage).addClass("uk-active");
    };
    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.pagingArticles(5, this.state.offset, this.state.itemPerPage, this.state.currentCategory).then((data) => {
                if (data.articles.items.length) {
                    this.setState({
                        arrayItemPerPage: data.articles.items,
                        totalItem: data.articles.totalCount,
                    });
                } else {
                    this.setState({
                        arrayItemPerPage: [],
                        totalItem: 0,
                    });
                }
            });
        });
    };
    render() {
        let { totalItem, popularArticle, latestArticle } = this.state;
        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(false);
        return (
            <div>
                <div className="container">
                    <h1> Sách </h1>
                    <div className="mt-lg-5" uk-grid="true">
                        <div className="uk-width-3-4@m">
                            <h4> Sách nổi bật </h4>
                            <div className="uk-position-relative" tabIndex={-1} uk-slider="autoplay: true">
                                <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                    {latestArticle.map((item, i) => (
                                        <li key={i}>
                                            <Link to={"/book/" + item.id + "/" + Common.rewriteUrl(item.name)}>
                                                <div className="book-card">
                                                    <div className="book-cover">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBook)} alt={item.name} />
                                                    </div>
                                                    <div className="book-content">
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                                <div className="uk-flex uk-flex-center mt-2">
                                    <ul className="uk-slider-nav uk-dotnav" />
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-4@m">
                            <h4> Sách phổ biến </h4>
                            <div id="book-popular">
                                {popularArticle.map((item, i) => (
                                    <div key={i} className="book-popular-card">
                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBook)} alt={item.name} className="cover-img" />
                                        <div className="book-details">
                                            <Link to={`/book/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                <h4>{item.name}</h4>
                                            </Link>
                                            <p>{item.subDescription} </p>
                                        </div>
                                        <a href="#">
                                            {" "}
                                            <i className="icon-feather-bookmark icon-small" />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div uk-filter="target: .js-filter">
                        <h3> Danh mục sách</h3>
                        <nav className="responsive-tab style-2 ">
                            <ul>
                                {this.state.categories.map(
                                    (item, i) => (
                                        <Fragment key={i}>
                                            <li
                                                onClick={() => this.changeCategory(item.id)}
                                                uk-filter-control={`[data-color='${item.id}']`}
                                                className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                                <a href="#">{item.name}</a>
                                            </li>
                                        </Fragment>
                                    )
                                    // <li><a href="#">CSS</a></li>
                                    // <li><a href="#">HTML</a></li>
                                    // <li><a href="#">Coding</a></li>
                                )}
                            </ul>
                        </nav>
                        <div className="section-small">
                            <div className="uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2" uk-grid="true">
                                {this.state.arrayItemPerPage.map((item, i) => (
                                    <div key={i} data-color={item.id} className={""}>
                                        <Link to={`/book/${item.id}/${Common.rewriteUrl(item.name)}`} className="uk-text-bold">
                                            <img
                                                src={Common.getImageThumbnail(item.images, Configuration.imageFormatBook)}
                                                alt={item.name}
                                                className="mb-2 w-100 shadow rounded"
                                            />
                                            {item.name}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* pagination*/}
                        <ul className="uk-pagination uk-flex-center uk-margin-medium">
                            {(() => {
                                let tempArray = [];
                                for (let index = 0; index < totalPage; index++) {
                                    tempArray.push(
                                        <li onClick={() => this.paginate(index)} id={"page" + index} key={index} className={index === 0 ? "uk-active" : ""}>
                                            <span>{index + 1}</span>
                                        </li>
                                    );
                                }
                                return <Fragment>{tempArray}</Fragment>;
                            })()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        allArticle: state.Book.allArticle,
        allCategoryArticle: state.Book.allCategoryArticle,
    };
}
const mapDispatchToProps = {
    setHeaderTranparentAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
