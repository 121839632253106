import { createStyled } from "@stitches/react";
import sizeList from "./size";

export const { styled, css } = createStyled({
  prefix: "dx",
  tokens: {},
  breakpoints: {},
  utils: {},
});
export const size = sizeList;
