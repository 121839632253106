/* eslint-disable */
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import "moment-timezone";
import "moment/locale/vi";
import { getRatingAction, createRatingTransactionAction } from "../../../Actions/Rating";
import Rating from "react-rating";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";

function Index(props) {
    const user = useSelector((state) => state.Book.infoUser);
    var avatar =
        user.avatar == null
            ? Configuration.imageAvatarDefault + "?mode=crop&width=50&height=50"
            : Configuration.imageUrl + user.avatar + "?mode=crop&width=50&height=50";
    const [showAll, setShowAll] = useState(5);
    const dispatch = useDispatch();
    const [rating, setRating] = useState({});
    const [stars, setStars] = useState([1, 2, 3, 4, 5]);
    const [averageScore, setAverageScore] = useState(0);
    const tempRating = useSelector((state) => state.Rating.rating);
    const [vote, setVote] = useState(null);
    const createRating = (value) => {
        setVote(value);
    };
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = (values) => {
        if (vote == null) {
            Swal.fire("Cần chọn đánh giá.");
            return;
        }
        var objData = {
            Id: Common.guid(),
            RatingId: props.ratingId,
            TargetId: localStorage.getItem(Configuration.userId),
            RelationId: props.id,
            Score: vote,
            Comment: values.comment,

            CreatedDate: Common.formatDateTime(new Date()),
            CreatedBy: localStorage.getItem(Configuration.userId),
        };
        createRatingTransactionAction(objData);
        setTimeout(() => {
            dispatch(getRatingAction(props.ratingId, props.id));
        }, 1000);
    };
    useEffect(() => {
        if (props.ratingId != null) {
            dispatch(getRatingAction(props.ratingId, props.id));
        }
    }, [props]);
    useEffect(() => {
        if (tempRating.id != undefined) {
            setRating(tempRating);
        }
    }, [tempRating]);

    useEffect(() => {
        if (rating.ratingTransactions != undefined) {
            var total = 0;
            rating.ratingTransactions.forEach((element) => {
                total += element.score;
            });
            if (total != 0) {
                props.setAverageScore((total / rating.ratingTransactions.length).toFixed(1));
                setAverageScore((total / rating.ratingTransactions.length).toFixed(1));
            }

            var temp = [];
            for (var i = rating.maxScore; i > 0; i--) {
                temp.push(i);
            }
            setStars(temp);
            props.setStars(temp);
        }
    }, [rating]);
    return (
        <Fragment>
            <div className="review-summary">
                <h4 className="review-summary-title"> Phản hồi của học viên </h4>
                <div className="review-summary-container">
                    <div className="review-summary-avg">
                        <div className="avg-number">{averageScore}</div>
                        <div className="review-star">
                            <div className="star-rating">
                                {stars.map((star, indexStar) => {
                                    if (indexStar < averageScore) {
                                        return <span className="star" />;
                                    } else {
                                        return <span className="star empty" />;
                                    }
                                })}
                            </div>
                        </div>
                        <span>Đánh giá khóa học</span>
                    </div>
                    <div className="review-summary-rating">
                        {stars.map((star, i) => {
                            if (rating.ratingTransactions != undefined && rating.ratingTransactions.length > 0) {
                                var tempRatingTransactions = rating.ratingTransactions.filter((e) => e.score == star);
                                var tempAverageRatingTransaction =
                                    (parseFloat(tempRatingTransactions.length) / parseFloat(rating.ratingTransactions.length)) * 100;

                                var starEnables = new Array(star);
                                var starDisables = new Array(i);
                                return (
                                    <div className="review-summary-rating-wrap">
                                        <div className="review-bars">
                                            <div className="full_bar">
                                                <div className="bar_filler" style={{ width: tempAverageRatingTransaction + "%" }} />
                                            </div>
                                        </div>
                                        <div className="review-stars">
                                            <div className="star-rating">
                                                {starEnables.map((starEnable) => (
                                                    <span className="star" />
                                                ))}
                                                {starDisables.map((starDisable) => (
                                                    <span className="star empty" />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="review-avgs">{tempAverageRatingTransaction.toFixed(0)} %</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className="comments">
                <h4>Nhận xét {rating.ratingTransactions != undefined && <span className="comments-amount"> ({rating.ratingTransactions.length}) </span>} </h4>
                <ul>
                    {rating.ratingTransactions != undefined &&
                        rating.ratingTransactions.slice(0, showAll).map((item, i) => (
                            <li>
                                <div className="comments-avatar">
                                    <img
                                        src={
                                            item.userImagePath != null
                                                ? Configuration.imageUrl + item.userImagePath + "?mode=crop&width=50&height=50"
                                                : Configuration.imageAvatarDefault + "?mode=crop&width=50&height=50"
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="comment-content">
                                    <div className="comment-by">
                                        {item.userName}
                                        <span>Học viên</span>
                                        <div className="comment-stars">
                                            <div className="star-rating">
                                                {/* {getStars(item.score)} */}
                                                {stars.map((star, indexStar) => {
                                                    if (indexStar < item.score) {
                                                        return <span className="star" />;
                                                    } else {
                                                        return <span className="star empty" />;
                                                    }
                                                })}
                                                {/* <p dangerouslySetInnerHTML={{
                                                                            __html: getStars(item.score)
                                                                        }}></p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{item.comment}</p>
                                    {/* <div className="comment-footer">
                                                            <span> Was this review helpful? </span>
                                                            <button> Yes </button>
                                                            <button> No </button>
                                                            <a href="#"> Report</a>
                                                        </div> */}
                                </div>
                            </li>
                        ))}
                </ul>
                {rating.ratingTransactions != undefined && rating.ratingTransactions.length > 5 && showAll == 5 && (
                    <div style={{ textAlign: "center" }}>
                        <a href="javascript:;" onClick={() => setShowAll(100000)} style={{ margin: "0 auto" }}>
                            <b>Xem hết</b>
                        </a>
                    </div>
                )}
            </div>
            <div className="comments">
                <h3>Gửi đánh giá </h3>
                <ul>
                    <li>
                        <div className="comments-avatar">
                            <img src={avatar} alt="" />
                        </div>
                        <div className="comment-content">
                            <form className="uk-grid-small" uk-grid="true" onSubmit={handleSubmit(onSubmit)}>
                                <div className="uk-width-1-2@s">
                                    {rating.maxScore != undefined && (
                                        <Rating
                                            stop={rating.maxScore}
                                            onClick={(value) => createRating(value)}
                                            emptySymbol={[
                                                "fa fa-star-o fa-2x low",
                                                "fa fa-star-o fa-2x low",
                                                "fa fa-star-o fa-2x medium",
                                                "fa fa-star-o fa-2x medium",
                                                "fa fa-star-o fa-2x high",
                                                "fa fa-star-o fa-2x high",
                                            ]}
                                            fullSymbol={[
                                                "fa fa-star fa-2x low",
                                                "fa fa-star fa-2x low",
                                                "fa fa-star fa-2x medium",
                                                "fa fa-star fa-2x medium",
                                                "fa fa-star fa-2x high",
                                                "fa fa-star fa-2x high",
                                            ]}
                                        />
                                    )}
                                </div>
                                <div className="uk-width-1-1@s">
                                    <label className="uk-form-label">Bình luận</label>
                                    <textarea
                                        ref={register({
                                            required: "Cần nhập bình luận",
                                        })}
                                        className="uk-textarea"
                                        name="comment"
                                        placeholder="Nhập bình luận của bạn tại đây...."
                                        style={{ height: "160px" }}
                                        defaultValue={""}
                                    />
                                    {errors.comment && errors.comment.message}
                                </div>
                                <div className="uk-grid-margin">
                                    <input type="submit" defaultValue="Gửi" value="Gửi" className="btn btn-default" />
                                </div>
                            </form>
                        </div>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
}

export default Index;
