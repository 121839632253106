import React from "react";
import { BasePage } from "../../ComponentCommon/BasePage";
import { useQuery } from "urql";
import { QUERY_PRODUCTS } from "../Product/maxcoach/Products/gql";
import { getCommonStorage } from "../../../../Config/gql";
import { QUERY_EVENTS } from "./gql";
import Common from "../../../../Utils/Common";
import { RenderPagination } from "../Product/maxcoach/Products";
import { useSetState } from "react-use";
import { Link } from "react-router-dom";

const Event = () => {
  const [pageState, setPageState] = useSetState({
    current: 0,
    limit: 6,
  });

  const [fetchEvents] = useQuery({
    query: QUERY_EVENTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";

      return {
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
        tokenId: commonStorage.tokenId,
        limit: pageState.limit,
        offset: pageState.limit * pageState.current,
        sort,
        order,
      };
    })(),
    requestPolicy: "cache-and-network",
  })

  const total = fetchEvents.data?.events?.totalCount || 0;
  const isEmpty = total === 0;

  return (
    <BasePage title={"Sự kiện"}>
      <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
        {fetchEvents.data? (
          fetchEvents.data.events.items.map(event => (
            <div className="col max-mb-30" >
              <div className="event">
                <div className="thumbnail">
                  <a href="event-details.html" className="image"><img src={Common.getImageThumbnail(event.images, "?Mode=crop&width=480&height=298")} alt="Event" /></a>
                  <div className="event-overlay-background" />
                  <div className="event-overlay-content">
                    <Link to={`/event/${event.id}`} className={"btn btn-md btn-light btn-hover-light theme-color"}>
                      Chi tiết
                    </Link>
                    {/*<a className="btn btn-md btn-light btn-hover-light theme-color" href="event-details.html">Get ticket</a>*/}
                  </div>
                </div>
                <div className="info">
                  <span className="date">{Common.formatDateTime(event.startDateTime, "dd/mm/yyyy hh:mm")}</span>
                  <h3 className="title"><a href={`/event/${event.id}`}>{event.name}</a></h3>
                  <ul className="event-location">
                    <li><i className="far fa-map-marker-alt" />{event.addresses && event.addresses.length > 0 ? event.addresses[0].name : "Online"}</li>
                  </ul>
                </div>
              </div>
            </div>
          ))
        ) : null}

      </div>
      <div className="row max-mt-50" hidden={isEmpty || fetchEvents.fetching}>
        <div className="col">
          <RenderPagination
            currentPage={pageState.current}
            totalItem={total}
            itemPerPage={pageState.limit}
            handlePageChange={(v) => {
              setPageState({ current: v });
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    </BasePage>
  );
};

export default Event;
