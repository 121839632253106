/* eslint-disable */
import React, { useState, useEffect } from "react";
import Common from "../../../../Utils/Common";
import Configuration from "../../../../Config/Config";
import { Label, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";
import { getProduct } from "../../../Services/ProductService";
import { createOrder } from "../../../Services/OrderService";
import { getMerchantByCode } from "../../../Services/MerchantService";
import { setHeaderTranparentAction } from "../../../Actions/Layout";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

// import 'bootstrap/dist/css/bootstrap.min.css';
const merchantIzziLearn = "5459bd9b-7440-ae4f-d86e-b81499cb128c";
const merchantPackageProductId = "d9fae849-3fb5-979a-12f6-ebcc92ee0497";
const evoucherProductId = "75a5f406-87d8-40d3-e3df-6310ee899c08";

function Index() {
    const { handleSubmit, register, errors, setValue, reset, getValues } = useForm();

    const dispatch = useDispatch();
    const [product, setProduct] = useState(null);
    dispatch(setHeaderTranparentAction(false));

    useEffect(() => {
        getProduct(merchantPackageProductId, merchantIzziLearn).then((res) => setProduct(res.product));
    }, []);

    const onSubmit = (values) => {
        var temp = getMerchantByCode(values.domain);
        if (temp != undefined) {
            Swal.fire("Lỗi!", "Domain của bạn đã tồn tại: " + values.domain, "danger");
            return;
        }
        var items = [
            {
                Id: Common.guid(),
                TargetId: merchantPackageProductId,
                quantity: 3,
            },
            {
                Id: Common.guid(),
                TargetId: evoucherProductId,
                quantity: 1,
            },
        ];

        var data = {
            Id: Common.guid(),
            MerchantId: localStorage.getItem(Configuration.merchantId),
            TargetId: localStorage.getItem(Configuration.merchantId),
            CustomerId: localStorage.getItem(Configuration.userId),
            Note: values.domain,
            OrderItems: items,
            CreatedDate: Common.getCurrentDateTime(),
            CreatedBy: localStorage.getItem(Configuration.userId),
        };
        console.log(data);
        //return;
        createOrder(data);
    };

    return (
        <div className="page-content-inner">
            <h2> Nâng cấp gói vàng </h2>
            {/* <ul className="uk-subnav pricing-swicher my-4" uk-switcher="connect: #change-plan ;animation: uk-animation-slide-top-medium, uk-animation-scale-up">
                <li><a href="#">Hàng tháng</a></li>
                <li><a href="#">Hàng năm <span>Tiết kiệm 60%</span> </a></li>
            </ul> */}
            {/* Pricing Plans Container */}
            <div className="pricing-plans-container">
                {product != null && (
                    <form style={{ margin: "auto", width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                        <div className="pricing-plan">
                            <h3>{product.name}</h3>
                            <p>{product.subDescription}</p>
                            <div className="pricing-plan-label">
                                <strong>
                                    299.000đ
                                    {/* {Common.formatMoney(product.price, 0, 3)} */}
                                </strong>
                                {/* / 3 tháng */}
                            </div>
                            <div className="pricing-plan-features">
                                <strong>Đặc điểm của gói {product.name}</strong>
                                <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                <hr></hr>
                                <h3>Thông tin thanh toán</h3>
                                <FormGroup>
                                    <Label>Tên domain</Label>
                                    <input
                                        className="form-control"
                                        name="domain"
                                        ref={register({
                                            required: "Tên domain không được để trống.",
                                        })}
                                        placeholder="Tên domain mà bạn muốn tạo."
                                    />
                                    {errors.domain && <b style={{ color: "red" }}>{errors.domain.message}</b>}
                                </FormGroup>
                            </div>
                            <button type="submit" className="btns">
                                Mua ngay
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}
export default Index;
