import { styled } from "../../../../Utils/stitches.config";

export const StyledContainer = styled("div", {
  background: "#ffffff",
  borderRadius: 8,
  padding: 16,
  zIndex: 1001,
  position: "relative",
  ".ck.ck-editor__editable_inline": {
    border: 0,
  },
});
export const StyledCertificateBox = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  background: "rgba(0, 0, 0, .5)",
  width: "100%",
  height: "100vh",
  maxHeight: "100vh",
  // overflow: "auto",
  zIndex: 1000,
  iframe: {
    border: 0,
    transition: "all 0.5s",
    boxShadow: "0 0 14px 0 rgba(255, 255, 255, 0.2)",
    background: "#fff",
  },
  ".iframe-box": {
    textAlign: "left",
    // width: "100%",
    display: "inline-block",
  },
  ".btn-close": {
    position: "absolute",
    top: 0,
    right: 0,
  },
});
