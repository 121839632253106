import { EVENT } from "../../Config/Constants/EventConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    event: [],
};

export const reducer = persistReducer(
    {
        storage,
        key: "event",
        blacklist: ["events", "isLoading"],
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(EVENT.GET_EVENT):
                return {
                    ...state,

                    isLoading: true,
                };
            case FAILURE(EVENT.GET_EVENT):
                return {
                    ...state,

                    isLoading: false,
                };
            case SUCCESS(EVENT.GET_EVENT):
                return {
                    ...state,

                    event: action.payload.events,
                    isLoading: false,
                };

            default:
                return state;
        }
    }
);
