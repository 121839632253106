/* eslint-disable */
import React, { Component, Fragment } from "react";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Configuration from "../../../../Config/Config";
import Common from "../../../../Utils/Common";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SubcribeBox from "../../ComponentCommon/Box/SubcribeCategory";
import "intro.js/introjs.css";
import introJs from "intro.js";
import { Steps, Hints } from "intro.js-react";
import { updateNotification, setHeaderTranparent } from "../../../Reducers/Book";

import { setHeaderTranparentAction } from "../../../Actions/Layout";
import { getUserCoursesAction } from "../../../Actions/Course";
import {getUserCourses} from "../../../Services/CourseService"

class Index extends Component {
    state = {
        izziMember: {
            Description: ``,
            //ImagePath: 'http://demo.foxthemes.net/courseplusv3.3/assets/images/home-hero.png'
            ImagePath: "",
        },
        newestArticles: [],
        videos: [],
        latestArticle: [],
        allArticleByCate: [],
        popularArticle: [],
        toplatestArticle: 9,
        userCourse:[],
        offset: 0,
        totalItem: null,
        itemPerPage: 4,
        arrayItemPerPage: [],
        currentCategory: "",
        categories: [],
        courses: [],
        blogs: [],
        blogCategories: [],
        currentBlogCategory: "",
        newestProducts: [],
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".user-tour-guide",
                intro: "Profile, nơi bạn dễ dàng theo dõi các khóa học của mình và cài đặt tài khoản",
            },
            {
                element: ".notify-tour-guide",
                intro: "Notification, nơi mọi thông báo của bạn được lưu trữ",
            },
            {
                element: ".breadcrumbs-tour-guide",
                intro: "Link, cho bạn biết đường dẫn hiện tại",
            },
            {
                element: ".home-tour-guide",
                intro: "Home, nhanh chóng quay về trang chủ bạn nhé",
            },
            {
                element: ".course-tour-guide",
                intro: "Course, nơi bạn quản lý các khóa học thú vị của bạn và izzi",
            },
            {
                element: ".library-tour-guide",
                intro: "Library, kho học liệu vô tận của izzi dành cho bạn",
            },
            {
                element: ".blog-tour-guide",
                intro: "Blog, các trang blog mà bạn đang theo dõi",
            },
            {
                element: ".author-tour-guide",
                intro: "Author, các tác giả nổi tiếng mà bạn đang theo dõi",
            },
            {
                element: ".product-tour-guide",
                intro: "Products, danh mục khóa học vô tận của izzi dành cho bạn",
            },
            {
                element: ".theme-tour-guide",
                intro: "Themes, đổi izzi thành style của bạn nhé",
            },
            {
                element: ".mycourse-tour-guide",
                intro: "Các khóa học bạn đã đăng ký",
            },
            {
                element: ".sharecourse-tour-guide",
                intro: "Học liệu bạn được chia sẻ",
            },
            {
                element: ".newestcourse-tour-guide",
                intro: "Các khóa học mới nhất của chúng tôi",
            },
            {
                element: ".book-tour-guide",
                intro: "Tài liệu tham khảo",
            },
            {
                element: ".blogs-tour-guide",
                intro: "Blog mới",
            },
        ],
        // hintsEnabled: true,
        // hints: [
        //     {
        //         element: ".uk-animation-slide-left-medium",
        //         hint: "Các thành điều hướng",
        //         hintPosition: "middle-right"
        //     },
        //     {
        //         element: ".shareCourse",
        //         hint: "Các thành điều hướng",
        //         hintPosition: "middle-right"
        //     }
        // ]
    };

    componentDidMount() {
       
        getUserCourses(0,10000).then(res =>
            {
                this.setState({
                    userCourse: res.usercourses.items,
                })

                console.log(res)     
            }
           );

         //this.props.getUserCoursesAction(0, 10000);
        QueryGraphQL.getMerchant().then((data) => {
            console.log(data);
            if (data.merchant.izziMember != null) {
                this.setState({
                    izziMember: JSON.parse(data.merchant.izziMember),
                });
            }

            console.log(this.state.izziMember);
        });

        QueryGraphQL.getNewestProduct(Configuration.productType.course, 4).then((data) => {
            this.setState({
                newestProducts: data.products.items,
            });
        });
        QueryGraphQL.getNewestArticle(Configuration.articleType.default, 5).then((data) => {
            this.setState({
                newestArticles: data.articles.items,
            });
            console.log(this.state.newestArticles);
        });
        // QueryGraphQL.pagingFreeCourseArticles(Configuration.articleType.video, this.state.offset, 4).then(data => {
        //     if (data.freecoursearticles.items.length) {
        //         this.setState({
        //             videos: data.freecoursearticles.items

        //         })
        //     }
        // })
        QueryGraphQL.getCourse(0, 10000).then((data) => {
            console.log(data);
            try {
                if (data.courses != undefined && data.courses.items.length) {
                    this.setState({
                        courses: data.courses.items,
                    });
                }
            } catch {}
        });

        QueryGraphQL.getCategoryByType(Configuration.categoryType.book).then((data) => {
            if (data.categories.items.length) {
                this.setState({
                    categories: data.categories.items,
                    currentCategory: data.categories.items.slice(0, 1)[0].id,
                });
                QueryGraphQL.pagingArticles(Configuration.articleType.book, this.state.offset, 5, this.state.currentCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            totalItem: data.articles.totalCount,
                            arrayItemPerPage: data.articles.items,
                        });
                    }
                });
            }
        });

        QueryGraphQL.getCategoryByType(Configuration.categoryType.article).then((data) => {
            if (data.categories.items.length) {
                this.setState({
                    blogCategories: data.categories.items,
                    currentBlogCategory: data.categories.items.slice(0, 1)[0].id,
                });
                QueryGraphQL.pagingArticles(0, this.state.offset, this.state.itemPerPage, this.state.currentBlogCategory).then((data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            blogs: data.articles.items,
                        });
                    }
                });
            }
        });

        introJs().start();
    }
    changeCategory = (id) => {
        this.setState({ currentCategory: id }, () => {
            QueryGraphQL.pagingArticles(Configuration.articleType.book, this.state.offset, 10, this.state.currentCategory).then((data) => {
                if (data.articles.items.length) {
                    this.setState({
                        arrayItemPerPage: data.articles.items,
                        totalItem: data.articles.totalCount,
                    });
                } else {
                    this.setState({
                        arrayItemPerPage: [],
                        totalItem: 0,
                    });
                }
            });
        });
    };
    changeBlogCategory = (id) => {
        this.setState({ currentBlogCategory: id }, () => {
            QueryGraphQL.pagingArticles(Configuration.articleType.default, this.state.offset, this.state.itemPerPage, this.state.currentBlogCategory).then(
                (data) => {
                    if (data.articles.items.length) {
                        this.setState({
                            blogs: data.articles.items,
                        });
                    } else {
                        this.setState({
                            blogs: [],
                            totalItem: 0,
                        });
                    }
                }
            );
        });
    };
    onExit = () => {
        this.setState(() => ({ stepsEnabled: false }));
    };
    toggleSteps = () => {
        this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
    };
    // addHint = () => {
    //     const newHint = {
    //         element: ".alive",
    //         hint: "Alive hint",
    //         hintPosition: "middle-right"
    //     };

    //     this.setState(prevState => ({ hints: [...prevState.hints, newHint] }));
    // };
    render() {
        const { stepsEnabled, steps, initialStep, hintsEnabled, hints } = this.state;
        let { totalItem, popularArticle, latestArticle } = this.state;
        var totalPage = totalItem / this.state.itemPerPage;
        this.props.setHeaderTranparentAction(true);
        return (
            <div>
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                    nextLabel="Tiếp"
                    prevLabel="Trở lại"
                    skipLabel="Đóng"
                    doneLabel="Xong"
                />
                <Hints enabled={hintsEnabled} hints={hints || ""} />
                <div
                    className="home-hero"
                    data-src={this.state.izziMember.imagePath}
                    uk-img="true"
                    style={{
                        backgroundImage: `url(${this.state.izziMember.ImagePath})`,
                        height: "40vh",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}>
                    <div className="uk-width-1-1">
                        <div className="page-content-inner uk-position-z-index" dangerouslySetInnerHTML={{ __html: this.state.izziMember.Description }}></div>
                    </div>
                </div>
                <div onClick={this.toggleSteps} style={{ position: "fixed", right: "0px", top: "50%", zIndex: 10 }}>
                    <div
                        style={{
                            width: "45px",
                            height: "40px",
                            backgroundColor: "rgb(62, 65, 109)",
                            borderRadius: "17px 0 0 17px",
                            cursor: "pointer",
                            display: "inline-block",
                        }}>
                        <i
                            className="icon-material-outline-contact-support"
                            style={{ color: "white", fontSize: "25px", lineHeight: "42px", marginLeft: "11px" }}
                        />
                    </div>
                </div>
                {/* <BrowserNotifications /> */}
                {/* <ProductMerchant /> */}
                <div className="container">
                    <div className="section-small mycourse-tour-guide">
                        <div className="course-grid-slider" uk-slider="finite: true">
                            <div className="grid-slider-header">
                                <div>
                                    <h4 className="uk-text-truncate text-uppercase"> Khóa Học Của Tôi </h4>
                                </div>
                                <div className="grid-slider-header-link">
                                    {/* <a href="courses.html" className="button transparent uk-visible@m"> View all </a> */}
                                    <a href="#" className="slide-nav-prev" uk-slider-item="previous" />
                                    <a href="#" className="slide-nav-next" uk-slider-item="next" />
                                </div>
                            </div>
                            <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                {this.state.userCourse.map((item, i) => (
                                        <li key={i}>
                                            <Link to={`/usercoursedetails/${item.id}`}>
                                                <div className="course-card">
                                                    <div className="course-card-thumbnail ">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatCourses)} />
                                                        {/* <span className="play-button-trigger" /> */}
                                                    </div>
                                                    <div className="course-card-body">
                                                        <div className="course-card-info">
                                                            <div>
                                                                {item.categories.length > 0
                                                                    ? item.categories.map((category, index) => (
                                                                          <span className="catagroy">{category.name}</span>
                                                                      ))
                                                                    : "-"}
                                                            </div>
                                                            <div>
                                                                <i className="icon-feather-bookmark icon-small" />
                                                            </div>
                                                        </div>
                                                        <h4>{item.name}</h4>
                                                        <p>{Common.formatName(item.subDescription, 100)}</p>
                                                        <div className="course-progressbar mt-3">
                                                            <div className="course-progressbar-filler" style={{ width: item.percent + "%" }}></div>
                                                        </div>
                                                        <div className="course-card-footer">
                                                            <h5>
                                                                {" "}
                                                                <i className="icon-feather-film" /> {item.countLesson} Bài học
                                                            </h5>
                                                            <h5>
                                                                {" "}
                                                                <i className="icon-feather-clock" /> {item.period} Giờ{" "}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                               }
                            </ul>

                            {/* <button className="btn btn-info" onClick={this.toggleSteps}>
                            <i className="icon-feather-book-open mr-2"></i> Hướng dẫn
                                </button> */}
                        </div>
                    </div>
                    <div className="section-small sharecourse-tour-guide">
                        {this.state.courses.length > 0 && (
                            <div className="course-grid-slider" uk-slider="finite: true">
                                <div className="grid-slider-header">
                                    <div>
                                        <h4 className="uk-text-truncate text-uppercase"> Learning materials </h4>
                                    </div>
                                    <div className="grid-slider-header-link">
                                        {/* <a href="courses.html" className="button transparent uk-visible@m"> View all </a> */}
                                        <a href="#" className="slide-nav-prev" uk-slider-item="previous" />
                                        <a href="#" className="slide-nav-next" uk-slider-item="next" />
                                    </div>
                                </div>
                                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                    {this.state.courses.map((item, i) => (
                                        <li key={i}>
                                            <a href={`/course/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                <div className="course-card">
                                                    <div className="course-card-thumbnail ">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatCourse)} />
                                                        <span className="play-button-trigger" />
                                                    </div>
                                                    <div className="course-card-body">
                                                        <div className="course-card-info">
                                                            <div>
                                                                <span className="catagroy">Học liệu</span>
                                                            </div>
                                                            <div>
                                                                <i className="icon-feather-bookmark icon-small" />
                                                            </div>
                                                        </div>
                                                        <h4>{item.name} </h4>
                                                        <p> {item.subDescription} </p>
                                                        <div className="course-card-footer">
                                                            <h5>
                                                                {" "}
                                                                <i className="icon-feather-film" /> - Lectures{" "}
                                                            </h5>
                                                            <h5>
                                                                {" "}
                                                                <i className="icon-feather-clock" /> - Hours{" "}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="section-small newestcourse-tour-guide">
                        {this.state.newestProducts.length > 0 && (
                            <div className="course-grid-slider uk-slider uk-slider-container" uk-slider="finite: true">
                                <div className="course-grid-slider">
                                    <div className="grid-slider-header">
                                        <div>
                                            <h4 className="uk-text-truncate text-uppercase"> Các khóa học mới nhất </h4>
                                        </div>
                                        <div className="grid-slider-header-link">
                                            <a href="/product" className="button transparent uk-visible@m">
                                                {" "}
                                                Xem tất cả{" "}
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                    {this.state.newestProducts.map((item, i) => (
                                        <li key={i}>
                                            <a href={`/product/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                <div className="course-card">
                                                    <div className="course-card-thumbnail ">
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatSlide)} />
                                                        {/* <span className="play-button-trigger" /> */}
                                                    </div>
                                                    <div className="course-card-body">
                                                        <div className="course-card-info">
                                                            <div>
                                                                <span className="catagroy">Khóa học</span>
                                                            </div>
                                                            <div>
                                                                <i className="icon-feather-bookmark icon-small" />
                                                            </div>
                                                        </div>
                                                        <h4>{item.name}</h4>
                                                        <p>{item.subDescription}</p>
                                                        {/* <div className="course-card-footer">
                                                         <h5> <i className="icon-feather-film" /> 12 Lectures </h5>
                                                         <h5> <i className="icon-feather-clock" /> 64 Hours </h5>
                                                     </div> */}
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* {this.state.videos.length > 0 &&
                        <div className="section-small pt-2 video-tour-guide">
                            <div className="course-grid-slider" uk-slider="finite: true">
                                <div className="grid-slider-header">
                                    <div>
                                        <h4 className="uk-text-truncate text-uppercase"> Các video mới nhất </h4>
                                    </div>
                                </div>
                                <ul className="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid">
                                    {this.state.videos.map((item, i) =>
                                        <li>
                                            <Link to={`/video/${item.id}/${Common.rewriteUrl(item.name)}`}>
                                                <div className="course-card episode-card animate-this">
                                                    <div className="course-card-thumbnail ">
                                                        <span className="item-tag">{item.files.length}</span>
                                                        <span className="duration">2:39</span>
                                                        <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatVideo)} alt={item.name} />
                                                        <span className="play-button-trigger" />
                                                    </div>
                                                    <div className="course-card-body">
                                                        <h4 className="mb-0"> {item.name} </h4>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    } */}

                    {this.state.categories.length > 0 && (
                        <div uk-filter="target: .js-filter" className="pt-2 book-tour-guide">
                            <h4 className={"uk-text-truncate text-uppercase"}> Tài liệu tham khảo</h4>
                            <nav className="responsive-tab style-2 ">
                                <ul>
                                    {this.state.categories.map((item, i) => (
                                        <Fragment key={i}>
                                            <li
                                                onClick={() => this.changeCategory(item.id)}
                                                uk-filter-control={`[data-color='${item.id}']`}
                                                className={item.id === this.state.currentCategory ? "uk-active" : ""}>
                                                <a href="#">{item.name}</a>
                                            </li>
                                        </Fragment>
                                    ))}
                                </ul>
                            </nav>
                            <div className="section-small">
                                <div className="uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2" uk-grid="true">
                                    {this.state.arrayItemPerPage.map((item, i) => (
                                        <div key={i} data-color={item.id} className={""}>
                                            <Link to={`/book/${item.id}/${Common.rewriteUrl(item.name)}`} className="uk-text-bold">
                                                <img
                                                    src={Common.getImageThumbnail(item.images, Configuration.imageFormatBook)}
                                                    alt={item.name}
                                                    className="mb-2 w-100 shadow rounded"
                                                />
                                                {item.name}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    <h3 className="mt-6 mb-0 text-uppercase"> Blog</h3>
                    <div className="section-header mb-lg-3 blogs-tour-guide">
                        <div className="section-header-left">
                            <nav className="responsive-tab style-2">
                                <ul>
                                    {this.state.blogCategories.map(
                                        (item, i) => (
                                            <Fragment key={i}>
                                                <li
                                                    onClick={() => this.changeBlogCategory(item.id)}
                                                    uk-filter-control={`[data-color='${item.id}']`}
                                                    className={item.id === this.state.currentBlogCategory ? "uk-active" : ""}>
                                                    <a href="javascript:;">{item.name}</a>
                                                </li>
                                            </Fragment>
                                        )
                                        // <li><a href="#">CSS</a></li>
                                        // <li><a href="#">HTML</a></li>
                                        // <li><a href="#">Coding</a></li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="uk-grid-large" uk-grid="true">
                        <div className="uk-width-expand">
                            {this.state.blogs.map((item, i) => (
                                <a href={"/blog/" + item.id + "/" + Common.rewriteUrl(item.name)} className="blog-post">
                                    {/* Blog Post Thumbnail */}
                                    <div className="blog-post-thumbnail">
                                        <div className="blog-post-thumbnail-inner">
                                            <span className="blog-item-tag">Tips</span>
                                            <img src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogList)} alt="" />
                                        </div>
                                    </div>
                                    {/* Blog Post Content */}
                                    <div className="blog-post-content">
                                        <span className="blog-post-date">{Common.formatDateTime(item.createdDate, "dd-mm-yyyy")}</span>
                                        <h3>{item.name}</h3>
                                        <p>{item.subDescription}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                        <div className="uk-width-1-3@s">
                            <SubcribeBox />
                            <div className="uk-card-default rounded mt-4">
                                <ul className="uk-tab-bottom uk-child-width-expand uk-tab" uk-switcher="animation: uk-animation-fade">
                                    <li>
                                        <a href="#">Mới nhất</a>
                                    </li>
                                    <li>
                                        <a href="#">Phổ biến</a>
                                    </li>
                                </ul>
                                <ul className="uk-switcher uk-margin">
                                    {/* tab 1 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                    {/* tab 2 */}
                                    <li>
                                        <div className="p-4 pt-0">
                                            {this.state.newestArticles.map((item, i) => (
                                                <div className="uk-grid-small" uk-grid="true">
                                                    <div className="uk-width-expand">
                                                        <p> {item.name} </p>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <img
                                                            src={Common.getImageThumbnail(item.images, Configuration.imageFormatBlogRelate)}
                                                            alt=""
                                                            className="rounded-sm"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <h4 className="mt-4"> Tags </h4>
                            <div uk-margin>
                                <a href="#" className="button small soft-grey">#BAA IT</a>
                                <a href="#" className="button small soft-grey">#BAA Business</a>
                                <a href="#" className="button small soft-grey">#BAA Banking</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        notifications: state.Notification.notifications,
        //userCourse: state.Course.usercourses,
    };
}
const mapDispatchToProps = {
    setHeaderTranparent,
    updateNotification,
    setHeaderTranparentAction,
    getUserCoursesAction,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(Index);
