const size = {
  xs: "@media (max-width: 576px)",
  sm: "@media (min-width: 576px)",
  md: "@media (min-width: 768px)",
  lg: "@media (min-width: 992px)",
  xl: "@media (min-width: 1200px)",
  xxl: "@media (min-width: 1600px)",
};

export default size;
