import { styled, size } from "../../../../Utils/stitches.config";

export const Background = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: 5,
  overflow: "hidden",
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  ".dx-info": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
  },
});
export const Bottom = styled("div", {
  textAlign: "center",
  paddingBottom: 20,
  [size.md]: {
    paddingBottom: 0,
  },
  a: {
    display: "inline-block",
  },
  "a:not(:first-child)": {
    marginLeft: 20,
  },
});
