/* eslint-disable */
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";

export const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const location = useLocation();

  if (location.search) {
    const query = queryString.parse(location.search);
    const autoToken = query.token || query.tokenId;

    if (autoToken) {
      const currentToken = localStorage.getItem(window.config.localStorageTokenId);

      if (autoToken !== currentToken) {
        localStorage.setItem(window.config.localStorageTokenId, autoToken);
        localStorage.removeItem("userId");
        window.location.reload();
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(window.config.localStorageTokenId) ? (
          //localStorage.getItem(window.config.localStorageTokenId)
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};
