import { styled, size } from "../../../../../Utils/stitches.config";
import { primaryColor } from "../../../../../Utils/themeConfig";

export const StyledHeader = styled("div", {
  height: 85,
  boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.1)",
  ".title-container": {
    display: "inline-block",
    color: "var(--primary-text-color)",
    position: "relative",
    bottom: -2,
    h2: {
      color: "var(--primary-text-color)",
      fontSize: 22,
      lineHeight: "27px",
      padding: 0,
      margin: 0,
      marginTop: 8,
      svg: {
        marginTop: -4,
        marginLeft: 4,
      },
    },
    a: {
      color: "#316FEA",
      fontSize: 16,
      lineHeight: "19px",
      i: {
        display: "inline-block",
        marginRight: 8,
      },
    },
  },
  ".share-this-course": {
    background: "unset",
    border: 0,
    fontSize: 16,
    lineHeight: "19px",
    i: {
      display: "inline-block",
      marginRight: 8,
    },
    "&:hover": {
      color: primaryColor,
    },
  },
  ".menu-button": {
    display: "inline-block",
    [size.lg]: {
      // display: "none",
    },
  },
});
