/* eslint-disable */
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Common from "../../../../Utils/Common";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { getQuizTransactions } from "../../../Services/QuizService";
import ToolkitProvider, { ColumnToggle, Search } from "react-bootstrap-table2-toolkit";
import filterFactory, { selectFilter, textFilter, Comparator } from "react-bootstrap-table2-filter";
import { setHeaderTranparentAction } from "../../../Actions/Layout";
import DetailAnswerQuiz from "./DetailAnswerQuiz";

function Index() {
    const childRef = useRef();
    // const [idQuiz, setIdQuiz] = useState(null);
    const getStatus = (status) => {
        if ((status & 256) == 256) {
            return <b className="text-success">Hoàn thành</b>;
        }

        // if ((status & 16) == 16) {
        //     return <b className="text-danger">Hoàn thành</b>
        // }

        if ((status & 4) == 4) {
            return <b className="text-warning">Đang chờ chấm điểm</b>;
        }
        if ((status & 1) == 1) {
            return <b className="text-primary">Bắt đầu</b>;
        }
        return <b className="text-default">-</b>;
    };

    const { ToggleList } = ColumnToggle;
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Hiển thị {from} đến {to} trên {size} kết quả.
        </span>
    );
    const [options, getPage] = useState({ search: "", offset: 0, sort: "startTime", order: "desc", limit: 10, status: null });

    const dispatch = useDispatch();
    dispatch(setHeaderTranparentAction(false));
    const order = useSelector((state) => state.Quiz.quizTransactions);

    const [quizTransactions, setQuizTransactions] = useState();
    options.totalSize = order.totalCount;
    options.paginationTotalRenderer = customTotal;
    options.sizePerPageList = [
        {
            text: "10",
            value: 10,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
        {
            text: "Tất cả",
            value: order.totalCount,
        },
    ];

    const selectOptions = [
        { value: 1, label: "Bắt dầu" },
        // { value: 16, label: 'Hoàn thành' },
        { value: 4, label: "Đang chờ chấm điểm" },
        { value: 256, label: "Hoàn thành" },
    ];

    useEffect(() => {
        getQuizTransactions(options.search, options.limit, options.offset, options.order, options.sort, options.status).then((res) => {
            
            setQuizTransactions(res.quizTransactions);
        });
    }, [options]);

    var handleSort = (sort, order) => {
        options.sort = sort;
        options.order = order;
    };

    var handleTableChange = (type, { page, sizePerPage, sort, order, searchText, filters }) => {
        console.log(filters);

        const offset = (page - 1) * sizePerPage;

        var status = null;

        if (filters.status != undefined) {
            status = filters.status.filterVal;
        }
        var search = "";
        if (filters.quizName != undefined) {
            search = filters.quizName.filterVal;
        }
        getPage({ search: search, status: status, offset: offset, limit: sizePerPage, sort: options.sort, order: options.order });
    };

    const columns = [
        {
            dataField: "quizName",
            text: "Tên",
            headerAlign: "center",
            align: "left",
            sort: true,
            formatter: (cell, row) => <b>{cell}</b>,
            onSort: handleSort,
            filter: textFilter({
                //delay: 1000, // default is 500ms
                comparator: Comparator.LIKE,
                placeholder: "Tên bài kiểm tra",
            }),
        },
        {
            dataField: "startTime",
            text: "Bắt dầu",
            align: "center",
            headerAlign: (column, colIndex) => "center",
            formatter: (cell, row) => Common.formatDateTime(cell),
            sort: true,
            onSort: handleSort,
        },
        {
            dataField: "endTime",
            text: "Kết thúc",
            align: "center",
            headerAlign: (column, colIndex) => "center",
            formatter: (cell, row) => (cell != null ? Common.formatDateTime(cell) : "-"),
            sort: true,
            onSort: handleSort,
        },
        {
            dataField: "status",
            text: "Trạng thái",
            align: "center",
            formatter: (cell) => getStatus(cell),
            headerAlign: "center",
            filter: selectFilter({
                placeholder: "Lựa chọn trạng thái",
                options: selectOptions,
            }),
        },
        {
            dataField: "score",
            text: "Điểm",
            align: "right",
            formatter: (cell, row) => cell + "/" + row.point,
            headerAlign: "center",
            //filter: numberFilter()
        },
        {
            // dataField: '',
            text: "Chi tiết",
            dataField: "quizId",
            align: "center",
            formatter: (cell, row) => {
                // setIdQuiz(cell);
                console.log(cell);
                return (
                    <a className="text-primary" id={cell} onClick={() => childRef.current.openModal(cell)}>
                        Chi tiết
                    </a>
                );
            },
            // formatter: (cell) => Common.formatMoney(cell, 0, 3),
            headerAlign: "center",
            //filter: numberFilter()
        },
    ];

    const { SearchBar } = Search;

    return (
        <div className="container">
            <Card>
                <CardBody>
                    {quizTransactions != undefined ? (
                        <ToolkitProvider keyField="id" columns={columns} search columnToggle>
                            {(props) => (
                                <div>
                                    Lựa chọn cột hiển thị: <ToggleList {...props.columnToggleProps} />
                                    <hr />
                                    <BootstrapTable
                                        {...props.baseProps}
                                        wrapperClasses="table-responsive"
                                        remote
                                        keyField="id"
                                        data={quizTransactions.items}
                                        columns={columns}
                                        pagination={paginationFactory(options)}
                                        onTableChange={handleTableChange}
                                        striped
                                        hover
                                        condensed
                                        filter={filterFactory()}
                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    ) : (
                        <h3 className="kt-portlet__head-title">Hiện chưa có đơn hàng nào</h3>
                    )}
                </CardBody>
                <DetailAnswerQuiz ref={childRef}></DetailAnswerQuiz>
            </Card>
        </div>
    );
}

export default Index;
