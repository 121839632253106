/* eslint-disable */
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const MERCHANT = {
    SET_INFOR: "merchant/SET_INFOR",
};
export const setInforAction = (item) => ({
    type: MERCHANT.SET_INFOR,
    item: item,
});

const initialState = {
    merchant: {},
};

export const reducer = persistReducer(
    {
        storage,
        key: "merchant",
        blacklist: ["merchants", "isLoading", "cancelOrderResult"],
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case MERCHANT.SET_INFOR:
                return {
                    ...state,
                    merchant: action.item,
                };
            default:
                return state;
        }
    }
);
