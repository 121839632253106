import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StyledCertificateBox } from "./styledComponents";
import "./style.less";
import { useUnmount } from "react-use";
import MCC from "../MiddleContent";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Configuration from "../../../../Config/Config";

function PopupCertificate({ show, certificate, onCancel }) {
  const [height, setHeight] = useState(-1);
  const [width, setWidth] = useState(-1);

  useEffect(() => {
    if (show) {
      // document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";

      setHeight(-1);
      setWidth(-1);
    }
  }, [show]);
  useUnmount(() => {
    document.body.style.overflowY = "auto";
  });

  return (
    <>
      <StyledCertificateBox
        onClick={onCancel}
        hidden={!show}
        className={"animate__animated animate__fadeIn animate__faster"}>
        <span className={"btn-close"}>
          <Button type={"link"} size={"large"} onClick={onCancel} style={{ color: "#fff" }}>
            <CloseOutlined />
          </Button>
        </span>
        <MCC>
          {show && certificate.files.length > 0 ? (
            <img src={Configuration.imageUrl + certificate.files[0].path} alt="img" />
          ) : (
            <span className={"iframe-box"} style={{ opacity: width > 0 && height > 0 ? 1 : 0 }}>
              <iframe
                id={"js-certificate-iframe"}
                className={"animate__animated animate__fadeInUp animate__faster"}
                title={"Certificate"}
                sandbox={"allow-same-origin"}
                key={certificate?.description || ""}
                srcDoc={certificate?.description || ""}
                style={{
                  width: width > 0 ? width : undefined,
                  height: height > 0 ? height : undefined,
                  opacity: 0,
                }}
                onLoad={() => {
                  const ifrm = document.getElementById("js-certificate-iframe");
                  const doc = ifrm.contentDocument
                    ? ifrm.contentDocument
                    : ifrm.contentWindow.document;

                  setHeight(getDocHeight(doc));
                  setWidth(getDocWidth(doc));
                }}
              />
            </span>
          )}
        </MCC>
      </StyledCertificateBox>
    </>
  );
}
PopupCertificate.propTypes = {
  show: PropTypes.bool.isRequired,
  certificate: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
};
PopupCertificate.defaultProps = {
  onCancel: () => {},
};

export default PopupCertificate;

function getDocHeight(doc) {
  doc = doc || document;
  // stackoverflow.com/questions/1145850/
  const body = doc.body;
  const html = doc.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
}

function getDocWidth(doc) {
  doc = doc || document;
  const body = doc.body;
  const html = doc.documentElement;

  return Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );
}
