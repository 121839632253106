/* eslint-disable */
import { SCHEDULE } from "../../Config/Constants/ScheduleConstant";
import { REQUEST, SUCCESS, FAILURE } from "./ActionType";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    schedule: [],
};

export const reducer = persistReducer(
    {
        storage,
        key: "schedule",
        blacklist: ["schedules", "isLoading"],
    },
    (state = initialState, action) => {
        // export default function reducer(state = initialState, action) {
        switch (action.type) {
            case REQUEST(SCHEDULE.GET_SCHEDULE):
                return {
                    ...state,
                    isLoading: true,
                };
            case FAILURE(SCHEDULE.GET_SCHEDULE):
                return {
                    ...state,

                    isLoading: false,
                };
            case SUCCESS(SCHEDULE.GET_SCHEDULE):
                return {
                    ...state,
                    schedule: action.payload.userschedules,
                    isLoading: false,
                };

            default:
                return state;
        }
    }
);
