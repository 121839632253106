/* eslint-disable */
import React ,{Suspense} from "react";
import ReactDOM from "react-dom";
import AppMain from "./App/Router/Router";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import Header from './App/Components/Layout/Header/Header';
import Common from "./Utils/Common";
import Configuration from "./Config/Config";
// import "./index.css";
import App from "./App";
import { init } from "@rematch/core";
import "./tailwind.css";

import { registerServiceWorker } from "./register-sw";
import thunk from "redux-thunk";
import rootReducer from "./App/Reducers";
import models from "./App/Models";
import './i18n';

require("dotenv").config();

const store = init({
  models: models,
  redux: {
    reducers: rootReducer,
    middlewares: [thunk],
  },
});
const rootElement = document.getElementById("root");
// var merchantId = "";
// var urlLink = window.location.hostname;
// var merchantCode = "";
// if (urlLink.includes("localhost")) {
//     merchantCode = window.config.merchantLocal;
// } else {
//     let splitUrl = urlLink.split(".");
//     if (splitUrl[0] === "www") {
//         merchantCode = splitUrl[1];
//     } else {
//         merchantCode = splitUrl[0];
//     }
// }
// const dataMerchant = Common.queryDataMerchant(merchantCode);
// merchantId = dataMerchant.id;
//
//
// Common.setCookie(Configuration.merchantId, merchantId);
// Common.setCookie(Configuration.languageId, Configuration.allLanguage[0].Vi);

store.subscribe(() => console.log(store.getState()));
registerServiceWorker();
// const renderApp = Component => {
ReactDOM.render(
  <Suspense fallback="loading">

  <Provider store={store}>
    <App />
    <BrowserRouter>
      <AppMain />
      {/* <Header></Header>
            <Aside></Aside>
            <div className="page-content">
                <AppMain />
                <Footer></Footer>
            </div> */}
    </BrowserRouter>
    ,
  </Provider>
  </Suspense>,
  rootElement
);
// };
// renderApp(AppMain);
// if (module.hot) {
//     module.hot.accept('./App/Router/Router', () => {
//         const NextApp = require('./App/Router/Router').default;
//         renderApp(NextApp);
//     });
// }

// ReactDOM.render(<AppMain />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
