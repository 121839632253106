import { RATING } from "../../Config/Constants/RatingConstant";
import { getRating, createRatingTransaction } from "../Services/RatingService";

export const getRatingAction = (id, targetId) => ({
    type: RATING.GET_RATING,
    payload: getRating(id, targetId),
});
export const createRatingTransactionAction = (values) => ({
    type: RATING.CREATE_RATINGTRANSACTION,
    payload: createRatingTransaction(values),
});
