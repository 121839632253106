import React from "react";
import { useState } from "react";
import { useQuery } from "urql";
import Configuration from "../../../../Config/Config";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import { createOrderAction } from "../../../Actions/Order";
import { BasePage } from "../../ComponentCommon/BasePage";
import { QUERY_PRODUCT } from "../Product/maxcoach/ProductDetail/gql";
import { useDiscount } from "../Product/maxcoach/Products/useDiscount";
import { QUERY_FORM_ATTRIBUTE_VALUES } from "./gql";

export default function DetailProductExchangePoint(props: any) {
  const id = props.match.params.id;
  const [quantity, setQuantity] = useState(1);
  const [submitting, setSubmitting] = useState(false);

  const [fetchProductResult] = useQuery({
    query: QUERY_PRODUCT,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        id,
        merchantId: commonStorage.merchantId,
        languageId: commonStorage.languageId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const [fetchFormAttributeValue] = useQuery({
    query: QUERY_FORM_ATTRIBUTE_VALUES,
    variables: (() => {
      const commonStorage = getCommonStorage();

      return {
        targetId: id,
        languageId: commonStorage.languageId,
      };
    })(),
    requestPolicy: "cache-and-network",
  });
  console.log(fetchFormAttributeValue.data);

  const productDetail = fetchProductResult.data?.product || {};

  const [hasDiscounts, priceInfo] = useDiscount(productDetail);

  const _createOrder = (productId: string, quantity: number) => {
    const items = [
      {
        Id: Common.guid(),
        TargetId: productId,
        quantity: quantity,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };

  return (
    <BasePage title={productDetail.name}>
      <div className="section">
        <div className="container">
          <div className="row max-mb-n50">
            <div className="col-lg-8 col-12 order-lg-1 max-mb-50">
              {/* Course Details Wrapper Start */}
              <div className="course-details-wrapper">
                <div className="course-nav-tab">
                  <ul
                    className="nav"
                    style={{
                      marginBottom: 20,
                    }}>
                    <li>
                      <a className="active" data-toggle="tab" href="#overview">
                        Overview
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div id="overview" className="tab-pane fade show active">
                    <img
                      className="w-100 mb-3"
                      src={Common.getImageThumbnail(productDetail.images)}
                      alt="image"
                    />
                    <div
                      className="course-overview"
                      dangerouslySetInnerHTML={{
                        __html: productDetail.description,
                      }}></div>
                  </div>
                </div>
              </div>
              {/* Course Details Wrapper End */}
            </div>
            <div className="col-lg-4 col-12 order-lg-2 max-mb-50" id="sticky-sidebar">
              <div className="sidebar-widget-wrapper pr-0">
                <div className="sidebar-widget">
                  <div className="sidebar-widget-content">
                    <div className="sidebar-entry-course-info">
                      <div className="course-price">
                        <span className="meta-label">
                          <i className="meta-icon far fa-money-bill-wave" />
                          Price
                        </span>
                        <span className="meta-value">
                          <span className="price">
                            {Common.formatMoneyIntl(productDetail.price)}
                          </span>
                        </span>
                      </div>
                      <div className="course-meta">
                        <div className="course-instructor">
                          <span className="meta-label">
                            <i className="far fa-chalkboard-teacher" />
                            Điểm
                          </span>
                          <span className="meta-value">{productDetail.exchangePoint} (Điểm)</span>
                        </div>
                        {productDetail.attributeValues != null && productDetail.attributeValues.map((attributeValue: any) => (
                          <div key={attributeValue.id} className="course-duration">
                            <span className="meta-label">
                              <i className="far" />
                              {attributeValue.name}
                            </span>
                            <span className="meta-value">{attributeValue.value}</span>
                          </div>
                        ))}
                      </div>
                      <div className="lp-course-buttons">
                        <button
                          className={`btn btn-primary btn-hover-secondary btn-width-100 ${
                            submitting ? "disabled" : ""
                          }`}
                          onClick={() => {
                            setSubmitting(true);
                            _createOrder(id, quantity);
                          }}>
                          Mua
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
}
