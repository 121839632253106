/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Player from "@vimeo/player";

function VimeoPlayer(props) {
  const { options } = props;
  const playerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const player = new Player(playerRef.current, options);
    setIsLoading(true);
    player.ready().finally(() => {
      setIsLoading(false);
    });
    player.on("play", function () {
      console.log("played the video!");
    });
  }, []);

  return (
    <div style={{ width: "100%", textAlign: "center" }} ref={playerRef}>
      {isLoading ? <div>Loading...</div> : null}
    </div>
  );
}

VimeoPlayer.propTypes = {
  options: PropTypes.objectOf({
    id: PropTypes.number,
  }),
};

export default VimeoPlayer;
