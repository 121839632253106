import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasePage } from "../../ComponentCommon/BasePage";
import { Hr } from "../Cv/components";
import { connect } from "react-redux";
import { getUser } from "../../../Services/UserService";
import { getCommonStorage } from "../../../../Config/gql";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import AttributeInfo from "./Components/AttributeInfo";

const BoardOpening = (props: any) => {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();
  const { userId } = getCommonStorage();
  useEffect(() => {
    QueryGraphQL.getBoardOpeningArticle(userId).then((res) => setArticles(res.articles.items));
  }, []);

  return (
    <BasePage title={t("Board Opening")}>
      {/* {articles.length > 0 &&
        articles.map((article: { name: string; id: string; postTime: Date,isFeatured: boolean }) => (
          <AttributeInfo article={article} />
        ))} */}

<Card title={<div style={{ textAlign: "center" }}>Xin cảm ơn Anh/Chị đã quan tâm.<br/> Nội dung này hiện đang được xây dựng & hoàn thiện. Vui lòng email về địa chỉ membership@viod.vn để được hỗ trợ thông tin.
<br/><b>Trân trọng</b></div>}></Card>
    </BasePage>
  );
};
const mapStateToProps = (state: any) => {
  return {
    user: state.User.user,
  };
};
export default connect(mapStateToProps)(BoardOpening);
