import { styled } from "../../../../../../Utils/stitches.config";
import { primaryColor } from "../../../../../../Utils/themeConfig";

export const PopularBooks = styled("div", {
  paddingLeft: 15,
  paddingRight: 15,
});
export const BookItem = styled("li", {
  marginBottom: 20,
  a: {
    background: "#ffffff",
    boxShadow: "0px 3px 15px #0000001a",
    display: "block",
    borderRadius: 5,
    overflow: "hidden",
    height: "100%",
  },
  h5: {
    fontSize: 16,
    padding: 16,
    lineHeight: "20px",
  },
  ".book-cover": {
    textAlign: "center",
  },
});
export const BookItemBox = styled("div", {
  cursor: "pointer",
  ".dx-container": {
    boxShadow: "0px 3px 15px #0000001a",
    background: "#fff",
    marginTop: 40,
    borderRadius: 5,
    ".dx-table": {
      display: "table",
      width: "100%",
      height: "100%",
    },
  },
  ".dx-thumbnail, .dx-info": {
    display: "table-cell",
    verticalAlign: "top",
  },
  ".dx-thumbnail": {
    width: "35%",
    img: {
      width: "100%",
      objectFit: "cover",
      position: "relative",
      top: -40,
      left: 20,
      boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.3)",
      borderRadius: 3,
      background: "#fff",
    },
  },
  ".dx-info": {
    width: "65%",
    h3: {
      fontSize: 16,
      color: "var(--primary-text-color)",
      margin: 0,
      ":hover": {
        color: primaryColor,
      },
    },
    p: {
      margin: 0,
    },
    ".dx-categories": {
      fontSize: 14,
      color: "#999fae",
      paddingTop: 4,
    },
    ".dx-description": {
      fontSize: 14,
      color: "var(--primary-text-color)",
    },
    ".dx-info-container": {
      padding: 20,
      paddingLeft: 40,
    },
  },
  ".ant-rate": {
    color: "rgb(254, 149, 39)",
    fontSize: 14,
  },
  ".dx-rate": {
    marginTop: 6,
    marginBottom: 12,
  },
  ".dx-special": {
    padding: 20,
    margin: 0,
    marginTop: -40,
  },
  hr: {
    borderColor: "rgb(240, 240, 240)",
    borderWidth: 1,
    margin: 0,
    padding: 0,
  },
  ".dx-see-book": {
    padding: "10px 20px",
    textAlign: "right",
  },
});
export const FileItem = styled("div", {
  padding: "10px 15px",
  border: "1px solid #f0f0f0",
  cursor: "pointer",
  "&:not(:first-child)": {
    borderTop: 0,
  },
  "&:first-child": {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  "&:last-child": {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  "&:hover": {
    color: primaryColor,
  },
});
