import { styled } from "../../../../../../Utils/stitches.config";

export const StyledNotificationList: any = styled("div", {
  width: "100vw",
  maxWidth: 340,
  maxHeight: "80vh",
  background: "#fff",
  overflowY: "auto",
  boxShadow: "0 4px 14px 0 rgba(0,0,0,0.1)",
  borderRadius: 8,
});

StyledNotificationList.Item = styled("div", {
  padding: "8px 16px",
  cursor: "pointer",
  position: "relative",
  "&:hover": {
    background: "#efefef",
  },
});

StyledNotificationList.Item.Date = styled("div", {
  marginTop: 4,
  variants: {
    seen: {
      yes: {
        opacity: 0.6,
      },
      no: {
        color: "rgb(49, 111, 234)",
        fontWeight: "bold",
      },
    },
  },
});

export const StyledNotificationListSeen = styled("div", {
  width: 10,
  height: 10,
  borderRadius: "50%",
  position: "absolute",
  top: "calc(50% - 5px)",
  right: 10,
  background: "rgb(49, 111, 234)",
});
