import { gql } from "urql";

export const queryVideoQuizs = gql`
  query VideoQuizs($id: String = "", $merchantId: String = "", $languageId: String = "", $tokenUser: String="") {
    quiz(param: { id: $id, merchantId: $merchantId, languageId: $languageId, tokenUser: $tokenUser }) {
      id
      name
      duration
      subDescription
      description
      option
      categories {
        id
        displayOrder
      }
      images {
        id
        name
        path
        displayOrder
        isFeatured
      }
      questions {
        id
        name
        time
        point
        images {
          id
          name
          path
          displayOrder
        }
        subDescription
        description
        type
        displayOrder
        answers {
          id
          name
          images {
            id
            name
            path
            displayOrder
          }
          isCorrect
          displayOrder
        }
      }
    }
  }
`;
