import { styled } from "../../../../../../../Utils/stitches.config";

export const Container = styled("div", {
  position: "relative",
  paddingLeft: 15,
  paddingRight: 15,
  ".ant-tabs-tab-btn": {
    color: "#999FAE",
    fontSize: 16,
    lineHeight: "20px",
  },
  ".ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn": {
    color: "var(--primary-text-color)",
    fontWeight: 600,
  },
  ".ant-tabs-ink-bar": {
    display: "none",
  },
  ".ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav": {
    margin: 0,
  },
  ".dx-total": {
    display: "inline-block",
    position: "absolute",
    right: 15,
    top: 10,
    fontSize: 16,
    lineHeight: "20px",
  },
  ".dx-pagination": {
    marginTop: 50,
    marginBottom: 20,
  },
});
export const Empty = styled("div", {
  textAlign: "center",
  marginTop: 80,
  color: "var(--primary-text-color)",
  fontSize: 16,
  lineHeight: "20px",
});
export const NotificationItem = styled("div", {
  padding: "24px 36px",
  borderBottom: "1px solid #EFF0F1",
  ".dx-message": {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "bold",
    color: "var(--primary-text-color)",
    position: "relative",
    ".dx-unseen": {
      width: 8,
      height: 8,
      position: "absolute",
      left: -12,
      top: 4,
      background: "#E8364E",
      borderRadius: "50%",
    },
  },
  ".dx-description": {
    color: "#999FAE",
    fontSize: 14,
    lineHeight: "17px",
    marginTop: 14,
  },
  ":hover": {
    background: "rgba(234, 128, 36, 0.1)",
    cursor: "pointer",
  },

  variants: {
    status: {
      seen: {
        background: "#F8F8F8",
      },
    },
  },
});
