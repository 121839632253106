/* eslint-disable */
import React, { Component, Fragment } from "react";
import Configuration from "../../../../Config/Config";
import { FormGroup, Input } from "reactstrap";
import $ from "jquery";
import Common from "../../../../Utils/Common";
import "./index.css";
import { QueryGraphQL } from "../../../Queries/GraphQl";
import Swal from "sweetalert2";

import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { setScoreAction } from "../../../Actions/Quiz";
import { connect } from "react-redux";
import { setHeaderTranparentAction, setBreadcrumbAction } from "../../../Actions/Layout";

let secondsRemaining;
let intervalHandle;

function tick(callBackTimesUp) {
  // grab the h1
  let timeDisplay = document.getElementById("time");
  if (!$("#time").length) return;
  // turn the seconds into mm:ss
  let min = Math.floor(secondsRemaining / 60);
  let sec = secondsRemaining - min * 60;

  //add a leading zero (as a string value) if seconds less than 10
  if (sec < 10) {
    sec = "0" + sec;
  }

  // concatenate with colon
  let message = min.toString() + ":" + sec;

  // now change the display
  timeDisplay.innerHTML = message;

  // stop is down to zero
  if (secondsRemaining === 0) {
    callBackTimesUp();
    clearInterval(intervalHandle);
  }

  //subtract from seconds remaining
  secondsRemaining--;
}
function startCountdown(minutes, callBackTimesUp) {
  // check if not a number
  if (isNaN(minutes)) {
    return; // stops function if true
  }
  secondsRemaining = minutes * 60;
  intervalHandle = setInterval(function () {
    tick(callBackTimesUp);
  }, 1000);
}

// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols,HtmlUnknownTarget
class QuizDetail extends Component {
  constructor() {
    super();
    this.state = {
      blocking: false,
      infoQuiz: {},
      isRender: false,
    };
    this.guidId = "";
    this.backgroundQuiz =
      "https://static.izzi.asia/images/2020/3/27/2003272288_shutterstock663203059Custom.jpg";
  }

  drop = (event) => {
    event.preventDefault();
    let data = event.dataTransfer.getData("text");
    if (event.target.id.includes("targetItem")) {
      if ($(event.target).find("*").length) {
        $("#repositoryAnswer").append($(event.target).find("*")[0]);
        $(event.target).find("*").remove();
        event.target.appendChild(document.getElementById(data));
      } else {
        event.target.appendChild(document.getElementById(data));
      }
    } else if (event.target.id === "repositoryAnswer") {
      event.target.appendChild(document.getElementById(data));
    }
  };
  drag = (event) => {
    event.dataTransfer.setData("text", event.target.id);
  };
  allowDrop = (event) => {
    event.preventDefault();
  };
  startQuiz() {
    this.guidId = Common.guid();
    let id;
    if (this.props.id !== undefined) {
      id = this.props.id;
    } else {
      id = this.props.match.params.id;
    }

    let dataStartQuiz = {
      Id: this.guidId,
      QuizId: id,
      UserId: localStorage.getItem(Configuration.userId),
      CommandInformation: navigator.userAgent,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: localStorage.getItem(Configuration.userId),
    };
    Common.sendSyncCommand(
      "StartQuiz",
      "CustomerRelationshipManagement",
      dataStartQuiz,
      () => console.log("thanh cong"),
      () => {
        Swal.fire("Có lỗi xảy ra!").then((result) => {
          if (result.value) {
            window.location.href = "/quiz";
          }
        });
      }
    );
  }
  sendQuiz = () => {
    this.setState({ blocking: true });
    let arrayQuestion = [];
    $.each($("#allQuestion>div"), function (i, val) {
      let arrayAnswer = [];
      let typeQuestion = parseInt($(this).attr("data-typequestion"));

      switch (typeQuestion) {
        case Math.pow(2, 0):
        case Math.pow(2, 1):
        case Math.pow(2, 2):
          $.each($(this).find("input"), function (i, val) {
            if ($(this).prop("checked")) {
              arrayAnswer.push({
                id: $(this).attr("id"),
                name: "",
              });
            }
          });
          arrayQuestion.push({
            id: $(this).attr("id"),
            answers: arrayAnswer,
          });
          break;

        case Math.pow(2, 3):
          let $domShotAnswer = $(this).find("textarea");
          arrayAnswer.push({
            id: $domShotAnswer.attr("id"),
            name: $domShotAnswer.val(),
          });
          arrayQuestion.push({
            id: $(this).attr("id"),
            answers: arrayAnswer,
          });
          break;

        case Math.pow(2, 4):
          let tempName = "";
          $.each($(this).find(".form-check").find("*"), function (i, val) {
            if ($(this)[0].tagName.toLowerCase() === "span") {
              let tempValue = $(this).text().trim();
              tempName += tempValue;
            } else {
              let tempValue = $(this).val().trim();
              tempName += "_{{" + tempValue + "}}_";
            }
          });
          let tempId = $(this).find(".form-check").attr("id");
          arrayAnswer.push({
            id: tempId,
            name: tempName,
          });
          arrayQuestion.push({
            id: $(this).attr("id"),
            answers: arrayAnswer,
          });
          break;
        case Math.pow(2, 5):
          let subName;
          $.each($(this).find(".form-group"), function (i, val) {
            subName = $(this).find(".textQuestion").text() + "_";
            subName += $(this).find(".textAnswer").text() || "";
            arrayAnswer.push({
              id: $(this).attr("id"),
              name: subName,
            });
          });
          arrayQuestion.push({
            id: $(this).attr("id"),
            answers: arrayAnswer,
          });
          break;
        default:
          break;
      }
    });
    let data = {
      // idQuiz: {
      Id: this.guidId, //this.props.match.params.id
      UserId: localStorage.getItem(Configuration.userId),
      UserDeviceToken: "",
      AnswerQuestions: arrayQuestion,
      CommandInformation: navigator.userAgent,
      ModifiedDate: Common.formatDateTime(new Date()),
      ModifiedBy: localStorage.getItem(Configuration.userId),
      // }
    };

    Common.sendSyncCommand(
      "FinishQuiz",
      "CustomerRelationshipManagement",
      data,
      (data) => {
        this.setState({ blocking: false });
        this.returnCallBack(data);
      },
      () => {
        this.setState({ blocking: false });
        Swal.fire("Có lỗi xảy ra!");
      }
    );
  };

  returnCallBack = (data) => {
    console.log(data);
    let option = JSON.parse(this.state.infoQuiz.option);
    if (option.ShowScore) {
      if (this.props.id !== undefined) {
        Swal.fire("Bạn đã hoàn thành bài quiz. Điểm của bạn là: " + data.Message).then((result) => {
          if (result.value) {
            this.props.setScoreAction(parseInt(data.Message));
            // $("#allQuestion").html("");
            // $("#time").html("");
            // $("#startTimer").show();
            //this.state.isRender = true;
            //setScoreAction(100)
            //alert(this.props.score);
            // var tempTest = useSelector(state => state.Quiz.score);
            // alert(tempTest);
            //window.location.reload();
          }
        });
      } else {
        Swal.fire("Bạn đã hoàn thành bài quiz. Điểm của bạn là: " + data.Message).then((result) => {
          if (result.value) {
            window.location.href = "/quiz";
          }
        });
      }
    } else {
      Swal.fire("Giảng viên sẽ thông báo điểm sau!").then((result) => {
        if (result.value) {
          window.location.href = "/quiz";
        }
      });
    }
  };
  componentDidMount() {
    let id = this.props.id !== undefined ? this.props.id : this.props.match.params.id;
    let seft = this;

    QueryGraphQL.getDetailQuiz(id).then((data) => {
      if (data.quiz != null) this.setState({ infoQuiz: data.quiz });
      seft.props.setBreadcrumbAction(data.quiz.name);
    });
  }
  componentDidUpdate() {
    //get height repository answer and hard fix it
    if ($("#repositoryAnswer").length) {
      let tempHeight = $("#repositoryAnswer")[0].offsetHeight;
      $("#repositoryAnswer").css("height", tempHeight);
    }
  }
  componentWillUnmount() {
    clearInterval(intervalHandle);
  }
  backAnswer = (idTarget) => {
    if ($("#" + idTarget).children().length) {
      let $domInTarget = $("#" + idTarget).children();
      $("#" + idTarget)
        .children()
        .remove();
      $("#repositoryAnswer").append($domInTarget);
    }
  };
  startTimer = () => {
    let id;
    if (this.props.id !== undefined) {
      id = this.props.id;
    } else {
      id = this.props.match.params.id;
    }

    QueryGraphQL.getDetailQuiz(id).then((data) => {
      let infoQuiz = data.quiz;
      this.setState(
        {
          infoQuiz: infoQuiz,
          isRender: true,
        },
        () => {
          $("#startTimer").hide();
          startCountdown(infoQuiz.duration / 60, this.sendQuiz);
          this.startQuiz();
        }
      );
    });
  };
  openModal = () => {
    Swal.fire({
      title: "Chú ý!",
      text: "Bạn có muốn nộp bài?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Nộp!",
      cancelButtonText: "Thoát!",
    }).then((result) => {
      if (result.value) {
        this.sendQuiz();
      }
    });
  };
  render() {
    this.props.setHeaderTranparentAction(false);
    let arrayRender = [];
    let arrayQuestion = this.state.infoQuiz.questions || [];
    arrayQuestion.forEach((element) => {
      if (element.answers.length) {
        element.answers.sort((a, b) => a.displayOrder - b.displayOrder);
      }
    });
    if (this.state.infoQuiz.option !== undefined) {
      let option = JSON.parse(this.state.infoQuiz.option);
      if (!option.RandomDisplayOrderQuestion) {
        arrayQuestion.sort((a, b) => a.displayOrder - b.displayOrder);
      }
    }
    arrayQuestion.forEach((element, i) => {
      let arrayAnswer = element.answers;

      if (arrayAnswer.length > 0) {
        let tempArrayAnswer = [];
        switch (element.type) {
          case Math.pow(2, 0):
            arrayAnswer.forEach((element) => {
              tempArrayAnswer.push(
                <FormGroup check>
                  <Input
                    type="radio"
                    name={"inputQuestion" + i}
                    id={element.id}
                    defaultChecked={false}
                  />
                  <h6>{element.name}</h6>
                </FormGroup>
              );
            });
            break;
          case Math.pow(2, 1):
            arrayAnswer.forEach((element) => {
              let ComponentImgQuestion = null;
              if (element.images.length) {
                ComponentImgQuestion = (
                  <img
                    className={"mb-2"}
                    style={{ width: "50%" }}
                    src={Configuration.imageUrl + element.images[0].path}
                    alt={"component"}
                  />
                );
              }
              tempArrayAnswer.push(
                <FormGroup check>
                  <Input
                    type="radio"
                    name={"inputQuestion" + i}
                    id={element.id}
                    defaultChecked={false}
                  />
                  <h6>{element.name}</h6>
                  {ComponentImgQuestion}
                </FormGroup>
              );
            });
            break;
          case Math.pow(2, 2):
            arrayAnswer.forEach((element) => {
              let ComponentImgQuestion = null;
              if (element.images.length) {
                ComponentImgQuestion = (
                  <img
                    className={"mb-2"}
                    style={{ width: "15%" }}
                    src={Configuration.imageUrl + element.images[0].path}
                    alt={"component"}
                  />
                );
              }
              tempArrayAnswer.push(
                <FormGroup check>
                  <Input
                    type="checkbox"
                    placeholder="Enter text"
                    name={"inputQuestion" + i}
                    id={element.id}
                    defaultChecked={false}
                  />
                  <h6>{element.name}</h6>
                  {ComponentImgQuestion}
                </FormGroup>
              );
            });
            break;
          case Math.pow(2, 3):
            arrayAnswer.forEach((element) => {
              tempArrayAnswer.push(
                <FormGroup>
                  <Input
                    type="textarea"
                    placeholder="Enter text"
                    name={"inputQuestion" + i}
                    id={element.id}
                    defaultChecked={false}
                  />
                  {/* <h6>{element.name}</h6> */}
                </FormGroup>
              );
            });
            break;

          case Math.pow(2, 4):
            let tempArray = [];
            let arrayquestion = element.name.split("_");
            let tempArr = [...arrayquestion];
            for (let index = 1; index < tempArr.length; index++) {
              tempArr.splice(index, 0, "_");
              index = index + 1;
            }
            $.each(tempArr, function (i, val) {
              if (val !== "_") {
                tempArray.push(<span>{val}&nbsp;</span>);
              } else {
                tempArray.push(
                  <input type="text" className="form-control mr-2" style={{ width: "20%" }} />
                );
              }
            });

            tempArrayAnswer.push(
              <FormGroup check id={arrayAnswer[0].id} className="pl-0">
                {tempArray}
              </FormGroup>
            );
            break;
          case Math.pow(2, 5):
            let subArrayAnswer = [];
            let repositoryAnswer = [];
            arrayAnswer.forEach((e, i) => {
              let tempVal = e.name.split("_")[0];
              subArrayAnswer.push(
                <div className="mb-2 mr-sm-2 mb-sm-0 form-group mt-4" id={e.id}>
                  <div
                    className="textQuestion p-2"
                    style={{ minWidth: "130px", background: "#ebebef" }}>
                    {tempVal}
                  </div>
                  <div
                    id={"targetItem" + i}
                    onDragOver={(event) => this.allowDrop(event)}
                    onDrop={(event) => this.drop(event)}
                    style={{ width: "202px", minHeight: "40px", border: "1px dashed black" }}
                    className="ml-3 hoverTargetItem"
                  />
                  <i
                    className="pe-7s-close-circle ml-2 text-danger"
                    style={{ cursor: "pointer", display: "block" }}
                    onClick={() => this.backAnswer("targetItem" + i)}
                  />
                </div>
              );
            });
            arrayAnswer.forEach((e, i) => {
              let tempVal = e.name.split("_")[1];
              repositoryAnswer.push(
                <li
                  className="list-group-item mx-auto"
                  id={"answer" + i}
                  draggable={true}
                  onDragStart={(event) => this.drag(event)}
                  style={{ width: "200px", height: "auto" }}>
                  <div className="todo-indicator bg-warning" />
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading textAnswer">{tempVal}</div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            });
            tempArrayAnswer.push(
              <FormGroup check className="pl-0">
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-inline">{subArrayAnswer}</div>
                  </div>

                  <div className="col-md-5">
                    <div
                      id="repositoryAnswer"
                      className="pt-4 pb-4 "
                      onDrop={(event) => this.drop(event)}
                      onDragOver={(event) => this.allowDrop(event)}
                      style={{
                        height: "auto",
                        width: "210px",
                        border: "1px solid #989393",
                        background: "#989393",
                      }}>
                      {repositoryAnswer}
                    </div>
                  </div>
                </div>
              </FormGroup>
            );
            break;
          default:
            break;
        }
        let point = arrayQuestion[i].point;
        let tempNameQuestion =
          element.type === Math.pow(2, 4) ? "Điền vào chỗ trống" : element.name;
        let ComponentImgQuestion = null;
        if (element.images.length) {
          ComponentImgQuestion = (
            <div style={{ width: "100%" }}>
              <img
                className={"mb-2"}
                style={{ height: "400px", margin: "0 auto", display: "block" }}
                src={Configuration.imageUrl + element.images[0].path}
                alt={"component"}
              />
            </div>
          );
        }
        arrayRender.push(
          <div className="mb-4" key={i} id={element.id} data-typeQuestion={element.type}>
            <p className="mb-2">
              Câu {i + 1}: {tempNameQuestion} ({point} điểm)
            </p>
            {ComponentImgQuestion}
            {tempArrayAnswer}
          </div>
        );
      } else {
        Swal.fire(`Bạn chưa tạo câu trả lời của câu hỏi số:${i + 1}`).then((result) => {
          if (result.value) {
            window.location.href = "/quiz";
          }
        });
      }
    });
    // if (this.state.infoQuiz != {}) {
    let imagePath =
      this.state.infoQuiz.images !== undefined
        ? Common.getImageThumbnail(this.state.infoQuiz.images, "?mode=crop&width=1800&height=480")
        : "https://static.izzi.asia/images/quiz-banner.png?mode=crop&width=1800&height=480";
    let scoreQuiz = 0;
    let totalQuestion =
      this.state.infoQuiz.questions !== undefined ? this.state.infoQuiz.questions.length : 0;
    let tempArray =
      this.state.infoQuiz.questions !== undefined
        ? this.state.infoQuiz.questions.map((e) => (scoreQuiz += e.point))
        : [];
    // }
    // var nameCategory = this.state.infoQuiz.categories.map(e => {

    // })
    return (
      <Fragment>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <div
            className="blog-article-single"
            style={{ backgroundImage: `url(${imagePath})` }}
            uk-img="true">
            <div className="container-small">
              <p className="blog-article-meta mb-3" />
              <h1> {this.state.infoQuiz.name} </h1>
              <div className="blog-article-auther">
                <img src="../assets/images/avatars/avatar-3.jpg" alt="" />
                <span className="blog-auther-user-name"> {this.state.infoQuiz.subDescription}</span>
                <span className="blog-auther-user-name">
                  Thời gian: {this.state.infoQuiz.duration / 60} phút
                </span>
                <span className="blog-auther-user-name">Số câu hỏi: {totalQuestion} </span>
                <span className="blog-auther-user-name">Tổng điểm: {scoreQuiz} </span>
              </div>
            </div>
          </div>
          <div className="container p-0">
            <div
              className="container-xsmall blog-article-content-read"
              style={{ maxWidth: "1000px", border: "1px solid #b7adad" }}>
              <button
                type="submit"
                onClick={() => this.startTimer()}
                id="startTimer"
                className="btn btn-default grey mx-auto"
                style={{ display: "block" }}>
                Bắt đầu
              </button>
              {this.state.isRender ? (
                <div className="mt-lg-11 container-small">
                  <div className="text-center my-4">
                    <h1 className="uk-heading-line text-center">
                      <span id="time" />
                    </h1>
                  </div>
                  <article className="uk-article">
                    <FormGroup>
                      <div id="allQuestion">{arrayRender}</div>
                      {/* <ModalCallBack action={() => this.sendQuiz()}></ModalCallBack> */}
                      <button
                        type="submit"
                        onClick={() => this.openModal()}
                        className="btn btn-default grey d-flex justify-content-center">
                        Gửi
                      </button>
                    </FormGroup>
                  </article>
                </div>
              ) : null}
            </div>
          </div>
        </BlockUi>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    //infoUser: state.Book.infoUser
    score: state.Quiz.score,
  };
}
const mapDispatchToProps = {
  // loadAllArticle,
  // loadAllCategoryArticle,
  setScoreAction,
  setHeaderTranparentAction,
  setBreadcrumbAction,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(QuizDetail);
