import { BasePage } from "../../ComponentCommon/BasePage";
import React, { useEffect, useState } from "react";
import Configuration from "../../../../Config/Config";
import { useQuery } from "urql";
import { QUERY_PRODUCTS } from "../Product/maxcoach/Products/gql";
import { getCommonStorage } from "../../../../Config/gql";
import Common from "../../../../Utils/Common";
import { Select, Modal, Form, Row, Col } from "antd";
import { createOrderAction } from "../../../Actions/Order";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
//import "./index.css";
export default function ProductPurchasePage() {
  const [t] = useTranslation()
  const [productQuantity, setProductQuantity] = useState<number>(3);
  /*   const listQuantity = [ 3]; */
  const [productId, setProductId] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [fetchProductsResult,reFetchProductsResult] = useQuery({
    query: QUERY_PRODUCTS,
    variables: (() => {
      const commonStorage = getCommonStorage();
      let order = "desc";
      let sort = "createdDate";

      return {
        type: Configuration.productType.memberPackage,
        merchantId: commonStorage.merchantId,
        languageId: Common.getCurrentLanguageId(),
        offset: 0,
        limit: 50,
        // categoryIds: state.categoryIds.concat(state.filterByCourseTypes),
        sort,
        order,
      };
    })(),
    requestPolicy: "cache-and-network",
  });

  const _createOrder = () => {
    console.log(typeof productQuantity);
    const items = [
      {
        Id: Common.guid(),
        TargetId: productId,
        Quantity: productQuantity,
      },
    ];
    const data = {
      Id: Common.guid(),
      MerchantId: localStorage.getItem(Configuration.merchantId),
      TargetId: localStorage.getItem(Configuration.merchantId),
      CustomerId: localStorage.getItem(Configuration.userId),
      Note: "",
      OrderItems: items,
      CreatedDate: Common.getCurrentDateTime(),
      CreatedBy: localStorage.getItem(Configuration.userId),
    };

    createOrderAction(data);
  };
  const renderAttribute = ()=>{
    const attributes = [];
  var nameLength = 0;
  const products = fetchProductsResult.data?.products?.items;
  if (products && products.length > 0) {
    for (let i = 0; i < products[0].attributeValues.length; i++) {
      const attributeValue = [];
      for (let product of products) {
        attributeValue.push(product.attributeValues[i]);
      }
      attributes.push(attributeValue);
      console.log(attributeValue,"73")
      if (attributeValue[0].name && nameLength < attributeValue[0].name.length) {
        nameLength = attributeValue[0].name.length;
      }
      console.log(nameLength);
    }
  }
  return {attributes,nameLength}
  }
  
  
  return (
    <BasePage title={"Membership Levels"}>
      <div className="overflow-x-auto">

      <table className="membership-price-table table table-striped table-bordered d-md-table d-sm-table d-table">
          <thead>
            <tr>
              <th className="headcol" />
              {(fetchProductsResult.data?.products?.items || []).map((product: any): any => (
                <th className="long">
                  <div className="price">
                    <span className="amount">{product.name}</span>
                  </div>
                  <div className="title">{`${Common.formatMoney(product.price, 0, 3)}/${t("year.Year")}`}</div>
                  <div className="desc">{product.subDescription || ""}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderAttribute().attributes.map((attribute) => (
              <tr>
                <td width={renderAttribute().nameLength * 11} className="desc headcol">
                  {attribute[0].name}
                </td>
                {attribute.map((col) => (
                  <td dangerouslySetInnerHTML={{ __html: col.value }} className="long"></td>
                ))}
              </tr>
            ))}

            {/*<td className="check">*/}
            {/*  <i className="fa fas fa-check" />*/}
            {/*</td>*/}
          </tbody>
          <tfoot>
            <tr>
              <td />

              {(fetchProductsResult.data?.products?.items || []).map((product: any): any => (
                <td>
                  <a
                    className="btn btn-light btn-hover-secondary"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      setProductId(product.id);
                      setVisible(true);
                    }}>
                    {t("Register Now")}
                  </a>
                  <Modal
                    width={550}
                    title="Vui lòng chọn số năm đăng kí thành viên - Please pick the membership term"
                    onCancel={() => setVisible(false)}
                    onOk={_createOrder}
                    visible={visible}>
                    <Row gutter={4}>
                      <Col span={12}>
                        <span>Chọn số năm - Term(years)</span>
                      </Col>
                      <Col span={12}>
                        <Select
                          defaultValue={3}
                          style={{ width: "100%" }}
                          onChange={(e: any) => setProductQuantity(+e)}>
                          <Select.Option key={3} value={3}>
                            3
                          </Select.Option>
                        </Select>
                      </Col>
                    </Row>
                  </Modal>
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    </BasePage>
  );
}
