/* eslint-disable */
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { getEventsAction } from "../../../Actions/Event";
import { getUserSchedulesAction } from "../../../Actions/Schedule";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
//import 'moment/locale/vi';
import { setHeaderTranparentAction } from "../../../Actions/Layout";
import "react-big-calendar/lib/css/react-big-calendar.css";
//moment.locale("vi");
const localizer = momentLocalizer(moment);

// Calendar.momentLocalizer(moment);

// const MyCalendar = props => (
//     <div>
//         <Calendar
//             localizer={localizer}
//             // events={myEventsList}
//             startAccessor="start"
//             endAccessor="end"
//             style={{ height: 500 }}
//         />
//     </div>
// )
const handleSelect = ({ start, end }) => {
    const title = window.prompt("Tên sự kiện");
    if (title)
        this.setState({
            events: [
                ...this.state.events,
                {
                    start,
                    end,
                    title,
                },
            ],
        });
};
var events = [];

function Index() {
    const dispatch = useDispatch();
    dispatch(setHeaderTranparentAction(false));
    const event = useSelector((state) => state.Event.event);

    const schedule = useSelector((state) => state.Schedule.schedule);
    console.log(schedule);

    useEffect(() => {
        dispatch(getEventsAction("", "createdDate", "desc", 1000, 0));
        dispatch(getUserSchedulesAction());
    }, []);
    console.log(event);
    if (event.items != undefined && schedule.items != undefined) {
        events = event.items
            .map((e) => {
                var obj = {
                    start: new Date(e.startDateTime),
                    end: new Date(e.endDateTime),
                    title: e.name,
                };
                return obj;
            })
            .concat(
                schedule.items.map((e) => {
                    var obj = {
                        start: new Date(e.triggerDateTime),
                        end: new Date(e.triggerDateTime),
                        title: e.name,
                    };
                    return obj;
                })
            );

        console.log(events);
    }

    return (
        <div className="container">
            <Card>
                <CardBody>
                    {/* <ExampleControlSlot.Entry waitForOutlet>
                        <strong>
                            Click an event to see more info, or drag the mouse over the calendar
                            to select a date/time range.
          </strong>
                    </ExampleControlSlot.Entry> */}
                    {event.items != undefined && (
                        <Calendar
                            selectable
                            localizer={localizer}
                            style={{ height: 1000 }}
                            // toolbar={false}
                            events={
                                //tempEvents
                                events
                                // event.items.map(e => {
                                //     var obj = {
                                //         id: e.id,
                                //         start: e.startDateTime,
                                //         end: e.endDateTime,
                                //         title: e.name,
                                //     }
                                //     return obj;
                                // })
                                // [{
                                //     start: new Date(2020, 6, 18),
                                //     end: new Date(2020, 6, 20),
                                //     title: 'helo',
                                // }]
                            }
                            //defaultView={Views.WEEK}
                            scrollToTime={new Date(1970, 1, 1, 6)}
                            defaultDate={new Date()}
                            onSelectSlot={handleSelect}
                            onSelectEvent={(event) => alert(event.start)}
                            // components={{
                            //     event: EventComponent
                            // }}
                            dayLayoutAlgorithm="no-overlap"
                            // startAccessor="start"
                            // endAccessor="end"
                            //   step={60}
                            //    views={allViews}
                            // view={this.state.view}
                            // onView={() => { }}
                            //date={this.state.date}
                            //onNavigate={date => this.setState({ date })}
                        />
                    )}
                </CardBody>
            </Card>
        </div>
    );
}
export default Index;

class EventComponent extends React.Component {
    render() {
        return <h1>here we go!</h1>;
    }
}
