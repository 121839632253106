/* eslint-disable */
import Common from "../../Utils/Common";
import Config from "../../Config/Config";
import Swal from "sweetalert2";

const merchantId = localStorage.getItem(Config.merchantId);
const userId = localStorage.getItem(Config.userId);
const tokenId = localStorage.getItem(window.config.localStorageTokenId);
const languageId = Common.getCurrentLanguageId()



export const updateInformationUser = (values) => {
    console.log(values);
    let objData = {
        Id: values.id,
        FirstName: values.firstName,
        LastName: values.lastName,
        CompanyName: values.companyName,
        CompanySite: values.companySite,
        Mobile: values.mobile,
        Email: values.email,
        DateOfBirth: values.dateOfBirth,
        ImageId: null,
        ModifiedDate: Common.formatDateTime(new Date()),
        ModifiedBy: values.id,
    };
    console.log(objData);
    Common.sendSyncCommand(
        "UpdateMemberElearnInformationUser",
        "User",
        objData,
        () => Swal.fire("Cập nhật thành công"),
        () => Swal.fire("Cập nhật thất bại")
    );
};

export const UserService = {
    

    getUserById: async function (id) {
        return await Common.getDataGraphQl(`
    {
      user(param:{
          id:"${id}"
        ,merchantId:"${merchantId}"
       })
        {
              id,
              userName,
              name,
              email,
              mobile,
              allType,
              firstName,
              lastName,
              dateOfBirth,
              companyName,  
              companySite,
              subDescription,
              description,
              createdDate,
              images {
                  id,
                  path,
                  displayOrder,
                  isFeatured,
              },
                categories {
                    id,
                    name
                  }

        }
      }
    `);
    },
};

export const getMembershipMenuNavigations = ({languageId}) => {

    var query = `
    {
      membershipmenunavigations(param:{        
        tokenUser:"${tokenId}"
        ,merchantId:"${merchantId}"
        ,languageId:"${languageId}"
       })
       {
        items {
          id
          name
          navigation
        }
      }
    }
    `;
    return Common.getDataGraphQl(query);
};

export const getUser = (id) => {
  var query = `
  {
    user(param:{
        id:"${id}"
      ,merchantId:"${merchantId}"
     })
      {
            id,
            userName,
            name,
            email,
            mobile,
            allType,
            firstName,
            lastName,
            dateOfBirth,
            companyName,  
            companySite,
            subDescription,
            description,
            createdDate,
            images {
                id,
                path,
                displayOrder,
                isFeatured,
            },
              categories {
                  id,
                  name
                }

      }
    }
  `;
  return Common.getDataGraphQl(query);
};
