/* eslint-disable */
import React, { Fragment } from "react";
import Common from "../../../../../Utils/Common";
import { QueryGraphQL } from "../../../../Queries/GraphQl";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/vi";
import Swal from "sweetalert2";
import { connect } from "react-redux";

import { updateNotification } from "../../../../Reducers/Notification";

class Notification extends React.Component {
    state = {
        offset: 0,
        itemPerPage: 50,
        items: [],
    };
    componentDidMount() {
        var self = this;
        QueryGraphQL.pagingNotificationTransactions(this.state.offset, this.state.itemPerPage).then((data) => {
            if (data.notificationtransactions.items != undefined && data.notificationtransactions.items.length) {
                this.setState({
                    items: data.notificationtransactions.items,
                });
            }
        });
    }

    readNotification(index, item) {
        item.allStatus = 16;
        const { items } = this.state;
        items.splice(index, 1, item);
        this.setState({ items: [...items] });

        let objData = {
            Id: item.id,
            ModifiedDate: Common.formatDateTime(new Date()),
            ModifiedBy: this.props.infoUser.id,
        };
        Common.sendAsyncCommand(
            "UserReadNotification",
            "NotificationTransaction",
            objData,
            () => {
                window.location.href = item.urlReturn;
            },
            () => {
                Swal.fire("Lỗi khi đọc thông báo!");
            }
        );
    }

    render() {
        var temp = this.props.notifications.concat(this.state.items);

        console.log(temp);
        return (
            <Fragment>
                <a href="#" className="header-widget-icon notify-tour-guide" uk-tooltip="title: Thông báo ; pos: bottom ;offset:21">
                    <i className="uil-bell" />
                    <span>{temp.filter((e) => e.allStatus === 1).length}</span>
                </a>
                {/* notificiation dropdown */}
                <div uk-dropdown="pos: top-right;mode:click ; animation: uk-animation-slide-bottom-small" className="dropdown-notifications">
                    {/* notivication header */}
                    <div className="dropdown-notifications-headline">
                        <h4>Thông báo </h4>
                        <a href="#">
                            <i className="icon-feather-settings" uk-tooltip="title: Notifications settings ; pos: left" />
                        </a>
                    </div>
                    {/* notification contents */}
                    <div className="dropdown-notifications-content" data-simplebar>
                        {/* notiviation list className=notifications-not-read*/}
                        <ul>
                            {temp.map((item, i) => (
                                <li className={item.allStatus == 1 ? "notifications-not-read" : ""}>
                                    <a href="javascript:;" onClick={() => this.readNotification(i, item)}>
                                        <span className="notification-icon btn btn-soft-primary disabled">
                                            <i className="icon-feather-message-circle" />
                                        </span>
                                        <span className="notification-text">
                                            {item.message}
                                            <br />{" "}
                                            <span className="time-ago">
                                                {" "}
                                                <Moment locale="vi" fromNow>
                                                    {item.createdDate}
                                                </Moment>{" "}
                                            </span>
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        infoUser: state.Book.infoUser,
        notifications: state.Notification.notifications,
    };
}
const mapDispatchToProps = {
    updateNotification,
};
// export default <AppMain />;
export default connect(mapStateToProps, mapDispatchToProps)(Notification);
