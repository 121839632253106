import { LAYOUT } from "../../Config/Constants/LayoutConstant";

export const setBreadcrumbAction = (breadcrumb) => ({
    type: LAYOUT.SET_BREADCRUMB,
    breadcrumb,
});

export const setHeaderTranparentAction = (headerTranparent) => ({
    type: LAYOUT.SET_HEADER_TRANPARENT,
    headerTranparent,
});

export const setLanguageIdAction = (languageId)=>({
    type:LAYOUT.SET_LANGUAGE_ID,
    languageId
})
