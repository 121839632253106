import { ORDER } from "../../Config/Constants/OrderConstant";
import { getOrders, getAffiliateOrders, getOrderDetail, createOrder, cancelOrder } from "../Services/OrderService";

export const getOrdersAction = (textSearch, allStatus, sort, order, limt, offset) => ({
    type: ORDER.GET_ORDER,
    payload: getOrders(textSearch, allStatus, sort, order, limt, offset),
});

export const getAffiliateOrdersAction = (textSearch, allStatus, sort, order, limt, offset) => ({
    type: ORDER.GET_AFFILIATE_ORDER,
    payload: getAffiliateOrders(textSearch, allStatus, sort, order, limt, offset),
});

export const getOrderDetailAction = (orderId) => ({
    type: ORDER.GET_ORDER_DETAIL,
    payload: getOrderDetail(orderId),
});

export const cancelOrderAction = (cancelOrderDTO) => ({
    type: ORDER.CANCEL_ORDER,
    payload: cancelOrder(cancelOrderDTO),
});

export const createOrderAction = (obj) => ({
    type: ORDER.CREATE_ORDER,
    payload: createOrder(obj),
});

export const clearOrderMessageAction = () => ({
    type: ORDER.CLEAR_ORDER_INFO_MESS,
    payload: {},
});
