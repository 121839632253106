export const QuizVideo = {
    state: {
        isOpenQuestion: false,
        dataUserDo: [],
        player: {},
    },
    reducers: {
        updateDataUserDo(state, data) {
            console.log(data);
            return {
                ...state,
                dataUserDo: data,
            };
        },
        updatePlayer(state, data) {
            return {
                ...state,
                player: data,
            };
        },
        toggleTooltipQuestion(state, data) {
            let flag;
            if (data !== undefined) {
                flag = data;
            } else flag = !state.isOpenQuestion;
            return {
                ...state,
                isOpenQuestion: flag,
            };
        },
    },
};
