import { gql } from "urql";

export const QueryBoardExperiences = gql`
  query WorkExperiences(
    $languageId: String!
    $merchantId: String!
    $tokenUser: String
    $type: Int!
    $targetId: String!
  ) {
    data: workexperiences(
      param: {
        tokenUser: $tokenUser
        languageId: $languageId
        merchantId: $merchantId
        type: $type
        targetId: $targetId
      }
    ) {
      items {
        name
        id
        position
        committeeRoles

        fromDateTime
        toDateTime

        companies {
          name
          __typename
        }
      }
      message
      success
      totalCount
    }
  }
`;
export const QueryAttribute = gql`
  query Attributes(
    $languageId: String
    $merchantId: String
    $type: Int = 3
    $published: Boolean = true
    $subType: Int = 1
    $offset: Int = 0
    $order: String = "asc"
    $sort: String = "displayOrder"
    $limit: Int = 100000
    $keyword: String
  ) {
    attributes(
      param: {
        type: $type
        published: $published
        subType: $subType
        keyword: $keyword
        limit: $limit
        offset: $offset
        order: $order
        sort: $sort
        languageId: $languageId
        merchantId: $merchantId
      }
    ) {
      totalCount
      message
      success
      items {
        attributeValues {
          id
          attributeId
          value
          imagePath
          displayOrder
        }
        attributeTextValues {
          id
          attributeId
          value
          imagePath
          displayOrder
          isDefaultValue
        }
        attributeIntValues {
          id
          attributeId
          value
          imagePath
          displayOrder
          isDefaultValue
        }
        attributeDecimalValues {
          id
          attributeId
          value
          imagePath
          displayOrder
          isDefaultValue
        }
        attributeDateTimeValues {
          id
          attributeId
          value
          imagePath
          displayOrder
          isDefaultValue
        }
        attributeVarcharValues {
          id
          attributeId
          value
          imagePath
          displayOrder
          isDefaultValue
        }
        name
        type
        subType
        id
        published
        createdDate
        displayType
        dataType
        displayOrder
        isRequired
      }
    }
  }
`;
export const QueryCertificates = gql`
  query Certificates($merchantId: String!, $languageId: String!, $tokenUser: String!) {
    articleusercertificates(
      param: { merchantId: $merchantId, languageId: $languageId, tokenUser: $tokenUser }
    ) {
      totalCount
      items {
        files{
          id
          name
          path
        }
        id
        name
        subDescription
        description
        createdDate
       
      }
    }
  }
`;
