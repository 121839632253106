import queryString from "query-string";

export function isPreviewCourse() {
  if (window.location.search) {
    const query = queryString.parse(window.location.search);

    return !!query.token || !!query.tokenId;
  }

  return false;
}

export function getDocHeight(doc) {
  doc = doc || document;
  // stackoverflow.com/questions/1145850/
  const body = doc.body;
  const html = doc.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
}

export function getDocWidth(doc) {
  doc = doc || document;
  const body = doc.body;
  const html = doc.documentElement;

  return Math.max(
    body.scrollWidth,
    body.offsetWidth,
    html.clientWidth,
    html.scrollWidth,
    html.offsetWidth
  );
}
