import styled from "styled-components";

export const Container = styled.section`
  clear: both;

  .sidebar-entry-course-info {
    padding: 20px 20px 0px;
  }

  .sidebar-entry-course-info .course-meta {
    margin-bottom: 2px;
  }

  .lp-course-buttons {
    margin-top: 0;
  }
`;
export const Description = styled.div``;
export const Thumbnail = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 8px;
`;
